define("pconsole/routes/product/devices/create-group", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model() {
      return Ember.RSVP.hash({
        group: this.store.createRecord('group', {
          product: this.modelFor('product').product
        })
      });
    },

    activate() {
      const createGroupController = this.controllerFor('product.devices.createGroup');
      createGroupController.set('createGroupRouteActive', true);
    },

    deactivate() {
      const createGroupController = this.controllerFor('product.devices.createGroup');
      createGroupController.set('createGroupRouteActive', false);
    },

    actions: {
      willTransition: function willTransition() {
        const group = this.get('controller.model').group;

        if (group && group.get('hasDirtyAttributes')) {
          if (group.get('id')) {
            group.rollbackAttributes();
          } else {
            group.deleteRecord();
          }
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});