define("pconsole/components/asset-tracker-map", ["exports", "react", "prop-types", "use-error-boundary", "pconsole/utils/average-group-color", "pconsole/config/environment", "pconsole/components/asset-tracker-map/circles-layer", "pconsole/components/asset-tracker-map/highlighted-device-layer", "pconsole/components/asset-tracker-map/line-pattern-layer", "pconsole/components/asset-tracker-popover", "pconsole/components/asset-tracker-map/updated-devices-layers", "styled-components", "react-mapbox-gl", "mapbox-gl", "@sentry/browser"], function (_exports, _react, _propTypes, _useErrorBoundary, _averageGroupColor, _environment, _circlesLayer, _highlightedDeviceLayer, _linePatternLayer, _assetTrackerPopover, _updatedDevicesLayers, _styledComponents, _reactMapboxGl, _mapboxGl, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Plots asset tracker device locations on a map.
   *
   * @example
   * ```jsx
   * <AssetTrackerMap center={center} devices={devices} fitBounds={true} onBoundsChange={e => handleBoundsChange(e)} />
   * ```
   * @returns {ReactElement} ReactElement
   */
  const AssetTrackerMap = props => {
    const {
      activeDevice = null,
      bounds = null,
      devices = [],
      fitBounds = false,
      showAreaSearchButton = false,
      cluster = false,
      noZoom = false,
      deviceCount = 1,
      deviceUpdates = null,
      highlightedDevice = '',
      metrics,
      containerStyle = {
        width: '100%',
        height: 'calc(100vh - 13.1rem)'
      },
      onBoundsChange = () => {},
      onFeatureMouseEnter = () => {},
      onFeatureMouseExit = () => {},
      onSearchThisArea = () => {},
      setVisiblePoints = () => {}
    } = props;
    const {
      ErrorBoundary,
      didCatch
      /*, error */

    } = (0, _useErrorBoundary.default)();
    const center = (0, _react.useRef)(props.center);
    const zoom = (0, _react.useRef)();
    const [updatedDevices, setUpdatedDevices] = (0, _react.useState)([]);
    const [hoveredDevice, setHoveredDevice] = (0, _react.useState)();
    const [hoveredFeatureIndex, setHoveredFeatureIndex] = (0, _react.useState)();
    const [sourceOpts, setSourceOpts] = (0, _react.useState)({});
    const [foldedPointsOpts, setFoldedPointsOpts] = (0, _react.useState)({});
    const handleBoundsChange = (0, _react.useCallback)((map, e) => {
      const bounds = map.getBounds();
      const newCenter = bounds.getCenter();
      const newZoom = map.getZoom();
      const circlesLayer = map.getLayer('circles');

      if (cluster && circlesLayer) {
        // Skip if the circles layer is not rendered yet
        // Filter the rendered layers for those outside of clusters
        const visiblePoints = map.queryRenderedFeatures(null, {
          filter: ['!', ['has', 'point_count']],
          layers: ['circles']
        });
        setVisiblePoints(visiblePoints);
      }

      if (!e.fitboundUpdate) {
        metrics.trackEvent('map moved');
      }

      onBoundsChange(bounds, e.fitboundUpdate);
      center.current = [newCenter.lng, newCenter.lat];
      zoom.current = newZoom;
    }, [metrics, onBoundsChange]);
    const transaction = Sentry.startTransaction({
      name: 'map-render'
    });
    const span = transaction.startChild({
      op: 'onRender',
      description: "map rendered"
    });
    (0, _react.useEffect)(() => {
      if (deviceUpdates) {
        setUpdatedDevices(devices.filter(d => deviceUpdates.includes(d.id)));
      }
    }, [devices, deviceUpdates]);
    (0, _react.useEffect)(() => {
      setSourceOpts({
        type: 'geojson',
        cluster,
        clusterMaxZoom: 14,
        clusterRadius: 50,
        data: {
          type: 'FeatureCollection',
          features: devices.map((device, idx) => ({
            type: 'Feature',
            id: idx,
            geometry: device.geometry.type === 'LineString' ? device.foldedGeometry : device.geometry,
            properties: {
              device,
              id: device.id,
              color: (0, _averageGroupColor.default)(device.groups),
              isMuted: activeDevice && activeDevice !== device.id || undefined,
              isHighlighted: [activeDevice, highlightedDevice].includes(device.id) || undefined
            }
          })).sort((a, b) => {
            // When a device is highlighted, bring it to the top
            if (a.properties.isHighlighted && !b.properties.isHighlighted) {
              return 1;
            }

            if (b.properties.isHighlighted && !a.properties.isHighlighted) {
              return -1;
            }

            return 0;
          })
        }
      });
      const opts = {
        tolerance: 0,
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      };

      if (devices.some(d => d.geometry.type === 'LineString' && Boolean(d.foldedCounts))) {
        opts.data.features = devices.reduce((foldedFeatures, device) => {
          if (!device.foldedCounts) {
            return foldedFeatures;
          } // Create points for each fold


          const features = device.foldedCounts.map((count, i) => count > 0 && {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: device.foldedGeometry.coordinates[i]
            },
            properties: {
              // Number of points folded is folds + 1
              count: device.foldedCounts[i] + 1,
              isHighlighted: [activeDevice, highlightedDevice].includes(device.id) || undefined
            }
          }).filter(Boolean);
          foldedFeatures.push(...features);
          return foldedFeatures;
        }, []);
      }

      setFoldedPointsOpts(opts);
    }, [activeDevice, devices, highlightedDevice]);
    let mapBounds = bounds;
    let devicesForFitBounds = devices; // Use active device for bounds when given

    if (!mapBounds && activeDevice) {
      devicesForFitBounds = [devices.find(d => d.id === activeDevice)].compact();
    } // Fit bounds when enabled and no bounds are given


    if (fitBounds && !mapBounds && devicesForFitBounds.length) {
      const coords = [];

      for (const device of devicesForFitBounds) {
        if (device.geometry.type === 'LineString') {
          for (const lineStringCoords of device.geometry.coordinates) {
            coords.push(lineStringCoords);
          }
        } else {
          coords.push(device.geometry.coordinates);
        }
      }

      mapBounds = coords.reduce((lngLatBounds, coord) => lngLatBounds.extend(coord), new _mapboxGl.LngLatBounds());
    }

    let clusterPoints = [(deviceCount || 1) * 0.3, (deviceCount || 1) * 0.4, (deviceCount || 1) * 0.5, (deviceCount || 1) * 0.6];
    const clusterLayer = {
      id: 'clusters',
      type: 'circle',
      sourceId: 'devices',
      filter: ['has', 'point_count'],
      // Property added by Mapbox with clusters
      paint: {
        'circle-color': ['step', ['get', 'point_count'], '#00a3b2', // Default color, success-graph-color
        clusterPoints[0], '#007580', // info-color
        clusterPoints[1], '#01466c', //
        clusterPoints[2], '#00334f', // midnight-500
        clusterPoints[3], '#002438'],
        'circle-radius': ['step', ['get', 'point_count'], 16, // Default,
        clusterPoints[0], 20, // First step and value
        clusterPoints[1], 32, // Second step and value
        clusterPoints[2], 40, // Third step and value
        clusterPoints[3], 44 // Last step and value
        ]
      }
    };
    const clusterCountLayer = {
      id: 'cluster-count',
      type: 'symbol',
      sourceId: 'devices',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 16
      },
      paint: {
        'text-color': '#f5f6fa' // Primary text color

      }
    };
    return _react.default.createElement(MapContainer, null, didCatch ? _react.default.createElement(MapError, {
      center: center.current,
      zoom: zoom.current
    }, _react.default.createElement('div', null, _react.default.createElement('h2', null, 'We can\'t show that map right now'), _react.default.createElement('p', null, 'Try reloading the page.'))) : _react.default.createElement(ErrorBoundary, null, _react.default.createElement(Map, {
      center: props.center,
      containerStyle: containerStyle,
      fitBounds: mapBounds,
      fitBoundsOptions: {
        duration: !fitBounds && bounds ? 0 : 5000,
        maxZoom: 15,
        padding: !fitBounds && bounds ? 0 : 150
      },
      style: 'mapbox://styles/mapbox/streets-v11',
      onMoveEnd: handleBoundsChange,
      onRender: () => {
        span.finish();
        transaction.finish();
      }
    }, _react.default.createElement(_reactMapboxGl.Source, {
      id: 'devices',
      geoJsonSource: sourceOpts
    }), _react.default.createElement(_reactMapboxGl.Source, {
      id: 'foldedPoints',
      geoJsonSource: foldedPointsOpts
    }), _react.default.createElement(_updatedDevicesLayers.default, {
      devices: updatedDevices
    }), _react.default.createElement(_highlightedDeviceLayer.default, {
      devices: devices,
      highlightedDevice: highlightedDevice
    }), _react.default.createElement(_reactMapboxGl.Layer, {
      type: 'line',
      sourceId: 'devices',
      paint: {
        'line-color': ['case', ['has', 'isHighlighted'], '#008dbf', ['boolean', ['get', 'isMuted'], false], '#b1b1b1', ['has', 'color'], ['get', 'color'], '#008dbf'],
        'line-width': ['case', ['has', 'isHighlighted'], 10, 8]
      }
    }), _react.default.createElement(_linePatternLayer.default, {
      sourceId: 'devices'
    }), !activeDevice && _react.default.createElement(_reactMapboxGl.Layer, clusterLayer), !activeDevice && _react.default.createElement(_reactMapboxGl.Layer, clusterCountLayer), _react.default.createElement(_circlesLayer.default, {
      id: 'circles',
      devices: devices,
      activeDevice: activeDevice,
      sourceId: 'devices',
      noAccuracy: true,
      onMouseEnter: (device, featureIndex) => {
        setHoveredDevice(device);
        setHoveredFeatureIndex(featureIndex);
        onFeatureMouseEnter(device);
      },
      onMouseLeave: () => {
        setHoveredDevice(null);
        setHoveredFeatureIndex(0);
        onFeatureMouseExit();
      }
    }), _react.default.createElement(_reactMapboxGl.Layer, {
      type: 'symbol',
      sourceId: 'foldedPoints',
      images: [['folded-count', foldedCountImage], ['folded-count-inverted', foldedCountInvertedImage]],
      layout: {
        'icon-image': ['case', ['has', 'isHighlighted'], 'folded-count-inverted', 'folded-count'],
        'icon-size': ['step', ['get', 'count'], 0.5, 100, 0.75, 1000, 1],
        'text-field': '{count}'
      },
      paint: {
        'text-color': ['case', ['has', 'isHighlighted'], '#ffffff', '#00334f']
      }
    }), !noZoom && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactMapboxGl.ScaleControl, {
      measurement: navigator.language === 'en-US' ? 'mi' : 'km',
      style: {
        bottom: '3.2rem'
      }
    }), _react.default.createElement(_reactMapboxGl.ZoomControl, null)), hoveredDevice && _react.default.createElement(_assetTrackerPopover.default, {
      device: hoveredDevice,
      metadataIndex: hoveredFeatureIndex,
      isActive: hoveredDevice.id === activeDevice
    }), _react.default.createElement(_reactMapboxGl.MapContext.Consumer, null, map => {
      if (showAreaSearchButton) {
        return _react.default.createElement(SearchThisAreaButton, {
          onClick: e => {
            onSearchThisArea(map.getBounds().toArray(), e);
            metrics.trackEvent('area searched');
          },
          type: 'button'
        }, _react.default.createElement('i', {
          className: 'ion-search'
        }), ' Search this area');
      }
    }))));
  };

  AssetTrackerMap.propTypes = {
    activeDevice: _propTypes.default.string,
    containerStyle: _propTypes.default.object,
    bounds: _propTypes.default.array,
    center: _propTypes.default.array,
    devices: _propTypes.default.array,
    fitBounds: _propTypes.default.bool,
    cluster: _propTypes.default.bool,
    noZoom: _propTypes.default.bool,
    deviceCount: _propTypes.default.number,
    deviceUpdates: _propTypes.default.array,
    highlightedDevice: _propTypes.default.string,
    metrics: _propTypes.default.object.isRequired,
    showAreaSearchButton: _propTypes.default.bool,
    onBoundsChange: _propTypes.default.func,
    onFeatureMouseEnter: _propTypes.default.func,
    onFeatureMouseExit: _propTypes.default.func,
    onSearchThisArea: _propTypes.default.func,
    setVisiblePoints: _propTypes.default.func
  };
  const {
    accessToken
  } = _environment.default.mapbox; // eslint-disable-next-line new-cap

  const Map = (0, _reactMapboxGl.default)({
    accessToken
  });
  const foldedCountImage = new Image(64, 64);
  foldedCountImage.src = 'data:image/svg+xml,%3Csvg%20width%3D%2264%22%20height%3D%2264%22%20version%3D%221.1%22%20viewBox%3D%220%200%2016.933%2016.933%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%228.4667%22%20cy%3D%228.4667%22%20r%3D%228.2101%22%20fill%3D%22%23ffffff%22%20stroke%3D%22%2300334f%22%20stroke-width%3D%22.51313%22%20style%3D%22paint-order%3Anormal%22%2F%3E%3C%2Fsvg%3E';
  const foldedCountInvertedImage = new Image(64, 64);
  foldedCountInvertedImage.src = 'data:image/svg+xml,%3Csvg%20width%3D%2264%22%20height%3D%2264%22%20version%3D%221.1%22%20viewBox%3D%220%200%2016.933%2016.933%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%228.4667%22%20cy%3D%228.4667%22%20r%3D%228.2101%22%20fill%3D%22%23008dbf%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%22.51313%22%20style%3D%22paint-order%3Anormal%22%2F%3E%3C%2Fsvg%3E';

  const MapContainer = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-map__MapContainer",
    componentId: "sc-1tyeuam-0"
  })(["height:100%;width:100%;position:relative;.mapboxgl-popup-content{font-family:var(--base-font);padding:1.6rem;}"]);

  const MapError = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-map__MapError",
    componentId: "sc-1tyeuam-1"
  })(["background-image:url('https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/", ",", ",", "/720x480@2x?access_token=", "');background-size:cover;display:flex;flex-direction:column;align-items:center;justify-content:center;position:absolute;top:0;right:0;bottom:0;left:0;div{backdrop-filter:blur(2px);background-color:var(--quaternary-background-color);border-radius:2px;padding:1.6rem 2.4rem;}h2{margin:0;}"], p => p.center[0], p => p.center[1], p => p.zoom, accessToken);

  const SearchThisAreaButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-map__SearchThisAreaButton",
    componentId: "sc-1tyeuam-2"
  })(["background-color:var(--quaternary-background-color);border:none;border-radius:4px;font-size:1.2rem;left:calc(50% - 9.6rem);padding:0.8rem 1.2rem;position:absolute;text-transform:uppercase;transition:background-color 0.2s;top:10px;i{font-size:2rem;margin-right:0.4rem;vertical-align:-0.32rem;}"]);

  var _default = AssetTrackerMap;
  _exports.default = _default;
});