define("pconsole/initializers/ember-stripe-elements", ["exports", "pconsole/config/environment", "ember-stripe-elements/utils/stripe-mock"], function (_exports, _environment, _stripeMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    const application = arguments[1] || arguments[0];
    let stripeConfig = _environment.default.stripe || {};
    application.register('config:stripe', stripeConfig, {
      instantiate: false
    });
    application.inject('service:stripev3', 'config', 'config:stripe');

    if (!stripeConfig.publishableKey) {
      throw new Ember.Error("stripev3: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js");
    }

    if (typeof FastBoot !== 'undefined' || stripeConfig.mock) {
      window.Stripe = _stripeMock.default;
    }
  }

  var _default = {
    name: 'ember-stripe-elements',
    initialize
  };
  _exports.default = _default;
});