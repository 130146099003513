define("pconsole/adapters/notification", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query) {
      const {
        org,
        serviceAgreementId
      } = query;
      delete query.org;
      delete query.serviceAgreementId;
      const prefix = this.urlPrefix();

      if (org) {
        return "".concat(prefix, "/orgs/").concat(org, "/service_agreements/").concat(serviceAgreementId, "/notifications");
      } else {
        return "".concat(prefix, "/user/service_agreements/").concat(serviceAgreementId, "/notifications");
      }
    }

  });

  _exports.default = _default;
});