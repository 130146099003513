define("pconsole/mixins/delete-integration-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteIntegrationRoute = void 0;
  const deleteIntegrationRoute = {
    setupController(controller) {
      this._super(...arguments);

      controller.set('parentEntity', Ember.get(this, 'parentEntity'));
    },

    renderTemplate() {
      this.render("user.integrations.show.delete", {
        into: 'application',
        outlet: 'modal'
      });
    }

  };
  _exports.deleteIntegrationRoute = deleteIntegrationRoute;
});