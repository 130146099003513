define("pconsole/controllers/product/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Convert a geoJSON-ordered bounds list and output a traditional-ordered string
   * @param {number[]} sw Southwest longitude and latitude
   * @param {number[]} ne Northeast longitude and latitude
   * @return {string} Comma-separated traditional "lat-lng" bounds
   */
  function toLatLngBoundsString([sw = [], ne = []]) {
    const [swLng, swLat] = sw;
    const [neLng, neLat] = ne;

    if (swLat && swLng && neLat && neLng) {
      return "".concat(swLat, ",").concat(swLng, ",").concat(neLat, ",").concat(neLng);
    }
  }

  var _default = Ember.Controller.extend({
    queryParams: ['area', {
      dates: {
        type: 'array'
      }
    }, 'groups', 'id', 'name', 'page', 'perPage'],
    activeDevice: null,
    area: '',
    bounds: null,
    center: [-122.4049525, 37.7890822],
    currentBounds: null,
    dates: null,
    deviceUpdates: null,
    fitBounds: true,
    groups: '',
    highlightedDeviceItem: null,
    highlightedMapFeature: null,
    id: '',
    location: '',
    meta: null,
    name: '',
    page: 1,
    perPage: 20,
    showAreaSearchButton: false,
    product: Ember.computed.alias('model.product'),
    groupOptions: Ember.computed.alias('model.groupOptions'),
    hasDevices: Ember.computed.alias('model.hasDevices'),
    mapableDevices: Ember.computed.filter('devices', function (device) {
      return !!device.geometry;
    }),
    pageSize: Ember.computed.alias('meta.per_page'),
    pages: Ember.computed.alias('meta.total_pages'),
    segment: Ember.inject.service(),
    devices: Ember.computed('model.devices.@each.lastHeard', 'activeDevice', 'timestamps.[]', function () {
      return Ember.get(this, 'model.devices').map(d => d.toObject());
    }),

    init() {
      Ember.set(this, 'currentBounds', []);
      Ember.set(this, 'deviceUpdates', []);
    },

    actions: {
      clear() {
        Ember.setProperties(this, {
          area: '',
          bounds: null,
          dates: null,
          groups: '',
          fitBounds: true,
          id: '',
          location: '',
          name: ''
        });
      },

      boundsChange(lngLatBounds, fitboundUpdate) {
        Ember.set(this, 'fitBounds', false);
        Ember.set(this, 'currentBounds', lngLatBounds.toArray());

        if (!fitboundUpdate) {
          Ember.set(this, 'showAreaSearchButton', true);
        }
      },

      async listItemClick(item
      /*, e*/
      ) {
        const bounds = Ember.get(this, 'bounds');
        await this.transitionToRoute('product.map.device', item.id);

        if (!bounds) {
          Ember.set(this, 'fitBounds', true);
        }
      },

      nextPageClick() {
        this.incrementProperty('page');
      },

      previousPageClick() {
        this.decrementProperty('page');
      },

      searchThisArea(lngLatBounds) {
        Ember.set(this, 'area', toLatLngBoundsString(lngLatBounds));
      },

      search({
        dateRange,
        deviceId: id,
        deviceName: name,
        deviceGroups: groups
      }) {
        Ember.set(this, 'showAreaSearchButton', false);
        this.transitionToRoute({
          queryParams: {
            dates: dateRange && dateRange.map(d => d.toISOString()),
            groups,
            id,
            name
          }
        });
      }

    }
  });

  _exports.default = _default;
});