define("pconsole/templates/components/sandbox-usage-threshold-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ShxIWLIn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  You’ve used \"],[1,[20,[\"alert\",\"usagePercent\"]],false],[0,\" of your free Sandbox’s \"],[1,[20,[\"alert\",\"thresholdCategory\"]],false],[0,\" this month\\n\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"alert\",\"isOver100Percent\"]]],null,{\"statements\":[[0,\"  \"],[6,\"p\"],[7],[0,\"\\n    Please add your devices to an existing paid plan or upgrade to a\\n    Growth or Enterprise Plan to avoid service disruption.\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    If no action is taken, services will automatically be paused\\n    on \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"devicePauseDate\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n    Your service will be automatically\\n    unpaused at the start of the next usage period, \\n    \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"nextBillingPeriodStart\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n    Please check your account email for more information.\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"p\"],[7],[0,\"\\n    Please add your devices to an existing paid plan or upgrade to a\\n    Growth or Enterprise Plan to avoid service disruption.\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    If you exceed the limit before the end of the usage period, your device(s)\\n    will be paused until the start of the next period,\\n    \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"nextBillingPeriodStart\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[4,\"link-to\",[\"user.billing\"],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/sandbox-usage-threshold-alert.hbs"
    }
  });

  _exports.default = _default;
});