define("pconsole/components/usage-sparkline", ["exports", "pconsole/utils/random-id"], function (_exports, _randomId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUsage: 0,
    isLoading: true,
    isProduct: false,
    sim: null,
    tagName: '',
    width: 250,
    height: 60,
    apiAjax: Ember.inject.service(),
    store: Ember.inject.service(),

    _getSimUsageData() {
      const sim = this.get('sim');
      const simID = sim.get('id');
      const url = "/v1/sims/".concat(simID, "/data_usage");
      return this.get('apiAjax').getRequest({
        url
      }).then(result => {
        const {
          usage_by_day
        } = result;
        usage_by_day.forEach(dataPoint => this._addDataPointToStore(dataPoint));
        this.set('isLoading', false);
        return usage_by_day;
      });
    },

    _addDataPointToStore(d) {
      const store = Ember.get(this, 'store');
      store.push({
        data: {
          id: (0, _randomId.default)(),
          type: 'sim-usage-datapoint',
          attributes: {
            date: d.date,
            mbsUsed: d.mbs_used,
            mbsUsedCumulative: d.mbs_used_cumulative
          },
          relationships: {
            sim: {
              data: {
                type: 'sim',
                id: this.get('sim.id')
              }
            }
          }
        }
      });
    },

    didInsertElement() {
      const elementId = 'usage-sparkline-' + this.get('sim.id');
      const d3 = window.d3;
      const width = this.get('width');

      this._getSimUsageData().then(usageData => {
        if (this.get('isDestroyed')) {
          return;
        }

        const currentUsage = d3.max(usageData, function (d) {
          return d.mbs_used_cumulative;
        });
        const currentUsageRoundedUp = Math.ceil(currentUsage);
        this.set('currentUsage', currentUsage);
        this.set('currentUsageRoundedUp', currentUsageRoundedUp);
        this.set('parseDate', d3.time.format('%Y-%m-%d').parse);
        const x = d3.time.scale().range([0, width]);
        const y = d3.scale.linear().range([this.get('height'), 0]);
        const line = d3.svg.line().x(function (d) {
          return x(d.date);
        }).y(function (d) {
          return y(d.mbs_used_cumulative);
        });
        this.set('line', line);
        this.set('x', x);
        this.set('y', y);

        this._drawSparkline(elementId, usageData);
      });
    },

    _drawSparkline(elemId, data) {
      this._parseData(data);

      this._setXandYDomain();

      this._appendSVG(elemId);

      this._drawAxes();

      if (this.get('currentUsage') === 0) {
        this._drawDataPlaceholder();
      } else {
        this._drawRoundedUpMBLine();

        this._drawUsageLine();
      }
    },

    _parseData(data) {
      const parseDate = this.get('parseDate');
      data.forEach(function (d) {
        d.date = parseDate(d.date);
        d.mbs_used = +d.mbs_used;
        d.mbs_used_cumulative = +d.mbs_used_cumulative;
      });
      this.set('data', data);
    },

    _setXandYDomain() {
      const d3 = window.d3;
      const x = this.get('x');
      const y = this.get('y');
      const width = this.get('width');
      const subscription = this.get('sim.subscription');
      let minDate;
      let maxDate;

      if (subscription && subscription.get('currentPeriodStart') && subscription.get('currentPeriodEnd')) {
        minDate = d3.time.day.floor(subscription.get('currentPeriodStart'));
        maxDate = d3.time.day.floor(subscription.get('currentPeriodEnd'));
      } else {
        minDate = d3.min(this.get('data'), function (d) {
          return d.date;
        });
        maxDate = d3.time.month.offset(minDate, 1);
      }

      const currentUsageRoundedUp = this.get('currentUsageRoundedUp');
      const xAxis = d3.svg.axis().scale(x).outerTickSize(0).tickFormat(d3.time.format('%b %-d')).tickValues([minDate, maxDate]).tickPadding(1).orient('bottom');
      const yAxis = d3.svg.axis().scale(y).ticks(currentUsageRoundedUp).innerTickSize(-width).outerTickSize(0).tickPadding(10).orient('left');
      x.domain([minDate, maxDate]);
      y.domain([0, currentUsageRoundedUp]);
      this.set('xAxis', xAxis);
      this.set('yAxis', yAxis);
    },

    _appendSVG(elemId) {
      const width = this.get('width');
      const height = this.get('height');
      const d3 = window.d3;
      const sparkline = d3.select('#' + elemId).append('svg').attr('width', width).attr('height', height).append('g');
      this.set('sparkline', sparkline);
    },

    _drawAxes() {
      const height = this.get('height');
      const xAxis = this.get('xAxis');
      const yAxis = this.get('yAxis');
      const sparkline = this.get('sparkline');
      sparkline.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);
      sparkline.append('g').attr('class', 'y axis').call(yAxis);
    },

    _drawUsageLine() {
      const y = this.get('y');
      const x = this.get('x');
      const line = this.get('line');
      const data = this.get('data');
      const sparkline = this.get('sparkline');
      sparkline.append('path').datum(data).attr('class', 'sparkline').attr('d', line);
      sparkline.append('circle').attr('class', 'sparkcircle').attr('cx', x(data[data.length - 1].date)).attr('cy', y(data[data.length - 1].mbs_used_cumulative)).attr('r', 2.5);
    },

    _drawDataPlaceholder() {
      const sparkline = this.get('sparkline');
      const width = this.get('width');
      const height = this.get('height');
      sparkline.append('rect').attr('class', 'placeholder-rect').attr('x', 0).attr('y', 0).attr('width', width).attr('height', height);
      sparkline.append('text').attr('class', 'no-data-placeholder').attr('x', function () {
        return width / 2;
      }).attr('y', function () {
        return height / 2 + 5;
      }).text('No data used yet.');
    },

    _drawRoundedUpMBLine() {
      const sparkline = this.get('sparkline');
      const currentUsageRoundedUp = this.get('currentUsageRoundedUp');
      const y = this.get('y');
      this.set('graphTopYPos', y(currentUsageRoundedUp));
      sparkline.append('text').attr('class', 'label first-mb-level').text(currentUsageRoundedUp + 'MB').attr('x', -30).attr('y', y(currentUsageRoundedUp) + 4);
    }

  });

  _exports.default = _default;
});