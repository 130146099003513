define("pconsole/serializers/org-user", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      memberships: {
        embedded: 'always'
      }
    },

    normalizeArrayResponse(store, type, payload) {
      const normalized = {
        'org_users': payload
      };
      normalized.org_users.forEach(user => {
        user.id = user.username;
        user.isProductOwner = user.is_product_owner;
        delete user._id;
      });
      return this._super(store, type, normalized);
    },

    normalizeSingleResponse(store, type, payload, id, requestType) {
      const post_payload = {};
      payload.id = payload.username;
      payload.isProductOwner = payload.is_product_owner;
      delete payload._id;
      post_payload.org_user = payload;

      if (requestType === 'deleteRecord') {
        post_payload.org_user = {
          id: id
        };
      }

      return this._super(store, type, post_payload, id, requestType);
    },

    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      delete json.newly_created;
      json.role = json.memberships[0].role;
      Ember.merge(hash, json);
    }

  });

  _exports.default = _default;
});