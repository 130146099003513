define("pconsole/controllers/organization/ledger/show/instance", ["exports", "ember-data", "pconsole/utils/permissions"], function (_exports, _emberData, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    application: Ember.inject.controller(),
    ledgerInstance: Ember.computed.alias('model.ledgerInstance'),
    ledgerDefinition: Ember.computed.alias('model.ledgerDefinition'),
    hasError: false,
    isSaving: false,
    skipClose: false,
    externalUpdate: false,
    disableSave: Ember.computed('ledgerInstance.hasDirtyAttributes', 'hasError', 'isSaving', 'isCreating', 'selectedScope', 'ledgerDefinition.isOwnerScope', function () {
      return _emberData.default.PromiseObject.create({
        promise: (async () => {
          const orgSlug = Ember.get(this, 'routeData.org');
          const isCreating = Ember.get(this, 'isCreating');
          const selectedScope = Ember.get(this, 'selectedScope');
          const isOwnerScope = Ember.get(this, 'ledgerDefinition.isOwnerScope');

          if (isCreating && !selectedScope && !isOwnerScope) {
            return true;
          }

          const ledgerInstance = Ember.get(this, 'ledgerInstance');
          let hasScope = true;

          if (orgSlug) {
            // Only check scope for orgs
            hasScope = await (0, _permissions.checkScope)({
              orgSlug,
              scope: 'ledger.instances:set',
              store: Ember.get(this, 'store')
            });
          }

          const isDirty = isCreating || Ember.get(ledgerInstance, 'hasDirtyAttributes') && !!ledgerInstance.changedAttributes().instanceData;
          return !hasScope || Ember.get(this, 'hasError') || !isDirty || Ember.get(this, 'isSaving');
        })()
      });
    }),

    /* ----- Creating Variables ----- */
    isCreating: false,
    selectedScope: null,
    scopes: Ember.computed('model.products', function () {
      const products = Ember.get(this, 'model.products');
      const selectedProducts = Ember.get(this, 'model.ledgerInstances').mapBy('scope.value');
      return products.filter(product => {
        // Remove products that are already selected
        return !selectedProducts.includes(Number(product.get('id')));
      });
    }),

    /* ------------------------------ */
    close() {
      if (Ember.get(this, 'skipClose')) {
        return;
      }

      const target = Ember.get(this, 'routeData.ledgerRoutes.show');
      this.transitionToRoute(target);
    },

    parseValue(val) {
      // Validate first against empty string to avoid Number('') converting it to 0
      if (val === '') {
        return val;
      } // If the string is these specific values, make them boolean


      if (val === true || val === false || val === 'true' || val === 'false') {
        return JSON.parse(val); // This correctly changes the type
      }

      const parsedNumber = Number(val);

      if (!isNaN(parsedNumber)) {
        // Number always returns NaN if the value is not possibly
        return parsedNumber;
      }

      return val;
    },

    actions: {
      isEditable() {
        return false;
      },

      // Used to disable the JSON editor for DeviceToCloud ledgers
      async refresh() {
        Ember.$('.loading').show();

        try {
          await Ember.get(this, 'ledgerInstance').reload();
          Ember.set(this, 'externalUpdate', true); // This causes a weird interaction but it's necesary to force the form view to reload
        } catch (e) {
          const status = Ember.get(e, 'errors.0.status');
          let message = e.message || 'There was a problem while loading your instance data. Please try again';

          if (status === 410) {
            // Archived ledger or similar error
            Ember.set(this, 'skipClose', true);
            this.transitionToRoute(Ember.get(this, 'routeData.ledgerRoutes').list);
          }

          Ember.get(this, 'notify').error(message);
        } finally {
          Ember.$('.loading').hide();
        }
      },

      selectProductScope(val) {
        Ember.set(this, 'selectedScope', val);
        Ember.set(this, 'ledgerInstance.scope', {
          value: val.id
        });
      },

      selectDeviceScope(val) {
        Ember.set(this, 'selectedScope', val);
        Ember.set(this, 'ledgerInstance.scope', {
          value: val
        });
      },

      closeModal() {
        this.close();
      },

      onDataChange() {
        // Changed event only triggers when the data is a valid JSON (in code mode)
        Ember.set(this, 'hasError', false);
      },

      onDataError() {
        Ember.set(this, 'hasError', true);
      },

      onFormChange(obj) {
        const newObj = {};

        for (let key in obj) {
          newObj[key] = this.parseValue(obj[key]);
        }

        Ember.set(this, 'ledgerInstance.instanceData', newObj);
      },

      async onSaveChanges() {
        Ember.set(this, 'isSaving', true);

        try {
          await Ember.get(this, 'ledgerInstance').save();
          Ember.get(this, 'notify').success('Ledger data successfully saved');
          Ember.set(this, 'hasError', false);
          Ember.set(this, 'isCreating', false);
          this.close();
        } catch (_unused) {
          Ember.get(this, 'notify').error('Sorry, there was a problem while saving your changes. Try again');
        } finally {
          Ember.set(this, 'isSaving', false);
        }
      }

    }
  });

  _exports.default = _default;
});