define("pconsole/routes/product/device/unmark-development", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    breadCrumb: null,
    templateName: 'product/devices/unmark-development',
    controllerName: 'product/devices/unmark-development',

    model() {
      return this.modelFor('product.device').device;
    }

  });

  _exports.default = _default;
});