define("pconsole/serializers/role", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    normalizeArrayResponse(store, type, payload) {
      payload.roles.forEach(role => {
        if (!role.id) {
          role.id = role._id;
          delete role._id;
        }

        role.organization = role.org;
      });
      delete payload.ok;
      return this._super(store, type, payload);
    }

  });

  _exports.default = _default;
});