define("pconsole/helpers/pluralize-word", ["exports", "ember-pluralize/helpers/pluralize"], function (_exports, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(_pluralize.default);

  _exports.default = _default;
});