define("pconsole/templates/product/settings/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vfHWBAHb",
    "block": "{\"symbols\":[\"close\",\"field\"],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title\"],[7],[0,\"Delete Product\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui warning alert\"],[7],[0,\"\\n    Caution! This action cannot be undone. Carefully review the action you are\\n    about to take.\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    This will permanently delete the \"],[1,[20,[\"product\",\"name\"]],false],[0,\" product, firmware, device\\n    groups, oauth clients and customers.\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Please type in the name of this product to confirm.\"],[8],[0,\"\\n\"],[4,\"validated-field\",[[19,0,[]],\"productName\"],null,{\"statements\":[[0,\"    \"],[6,\"label\"],[9,\"for\",\"confirm-product-name\"],[7],[0,\"Name\"],[8],[0,\"\\n    \"],[6,\"input\"],[9,\"id\",\"confirm-product-name\"],[9,\"type\",\"text\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"productName\"]]],null]],[[\"value\"],[\"target.value\"]]],null],[10,\"value\",[18,\"productName\"],null],[7],[8],[0,\"\\n    \"],[1,[19,2,[\"help-text\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[6,\"div\"],[9,\"class\",\"delete-product__buttons\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui button outline secondary\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],[19,1,[]]]],[7],[0,\"Cancel\"],[8],[0,\"\\n    \"],[6,\"button\"],[10,\"class\",[26,[\"ui \",[25,\"if\",[[20,[\"isDeleting\"]],\"loading\",\"\"],null],\" button outline primary\"]]],[9,\"type\",\"button\"],[10,\"disabled\",[20,[\"validations\",\"isTruelyInvalid\"]],null],[3,\"action\",[[19,0,[]],\"delete\",[20,[\"product\"]],[19,1,[]]]],[7],[0,\"\\n      I understand, delete this product\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/settings/delete.hbs"
    }
  });

  _exports.default = _default;
});