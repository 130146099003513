define("pconsole/templates/form-inputs/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u7rV9Qij",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",[[25,\"-input-type\",[[20,[\"type\"]]],null]],[[\"id\",\"invalid\",\"placeholder\",\"name\",\"type\",\"value\",\"readonly\",\"required\",\"autofocus\",\"disabled\",\"size\",\"tabindex\",\"maxlegth\",\"min\",\"max\",\"pattern\",\"accept\",\"autocomplete\",\"autosave\",\"formaction\",\"formenctype\",\"formmethod\",\"formnovalidate\",\"formtarget\",\"height\",\"inputmode\",\"multiple\",\"step\",\"width\",\"form\",\"selectionDirection\",\"spellcheck\"],[[20,[\"inputId\"]],[20,[\"isInvalid\"]],[20,[\"placeholder\"]],[20,[\"name\"]],[20,[\"type\"]],[20,[\"value\"]],[20,[\"readonly\"]],[20,[\"required\"]],[20,[\"autofocus\"]],[20,[\"disabled\"]],[20,[\"size\"]],[20,[\"tabindex\"]],[20,[\"maxlength\"]],[20,[\"min\"]],[20,[\"max\"]],[20,[\"patterm\"]],[20,[\"accept\"]],[20,[\"autocomplete\"]],[20,[\"autosave\"]],[20,[\"formaction\"]],[20,[\"formenctype\"]],[20,[\"formmethod\"]],[20,[\"formnovalidate\"]],[20,[\"formtarget\"]],[20,[\"height\"]],[20,[\"inputmode\"]],[20,[\"multiple\"]],[20,[\"step\"]],[20,[\"width\"]],[20,[\"form\"]],[20,[\"selectionDirection\"]],[20,[\"spellcheck\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/form-inputs/default.hbs"
    }
  });

  _exports.default = _default;
});