define("pconsole/helpers/asset-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assetHint = assetHint;
  _exports.default = void 0;

  function assetHint([val]) {
    switch (val) {
      case 'available':
        return 'This asset is available on the device.';

      case 'outdated':
        return 'This asset is out of date on the device. It will be updated when the device connects to the cloud.';

      case 'missing':
        return 'This assets is missing from the device. It will be delivered when the device connects to the cloud.';
    }
  }

  var _default = Ember.Helper.helper(assetHint);

  _exports.default = _default;
});