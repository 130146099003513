define("pconsole/serializers/access-token", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    normalizeResponse(store, type, payload, id, requestType) {
      if (!payload.id) {
        payload.id = 'current';
      }

      const normalized = {
        'access-token': payload
      };
      return this._super(store, type, normalized, id, requestType);
    }

  });

  _exports.default = _default;
});