define("pconsole/adapters/ledger-definition", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('routeData.org', function () {
      const org = Ember.get(this, 'routeData.org');

      if (org) {
        return "v1/orgs/".concat(org);
      }

      return 'v1';
    }),

    urlForFindRecord(id) {
      return "".concat(this.urlPrefix(), "/ledgers/").concat(id);
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const name = snapshot.record.get('name');
      return "".concat(this.urlPrefix(), "/ledgers/").concat(name);
    },

    pathForType() {
      return 'ledgers';
    }

  });

  _exports.default = _default;
});