define("pconsole/mixins/routes/ledger/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LedgerCreateRoute = void 0;
  const LedgerCreateRoute = {
    templateName: 'organization.ledger.create',
    controllerName: 'organization.ledger.create',
    breadCrumb: {
      title: 'Define new ledger'
    },

    async model(params) {
      const ledgerDefinition = this.store.createRecord('ledger-definition');
      const ledgerDefinitions = await this.store.findAll('ledger-definition');
      const categories = await this.store.findAll('ledger-category', {
        reload: true
      });
      const category = categories.filterBy('slug', params.category)[0];
      return {
        ledgerDefinition,
        ledgerDefinitions,
        category,
        categories
      };
    },

    afterModel(model) {
      const ledgerDefinitions = model.ledgerDefinitions.toArray();
      const ledgerDefinition = model.category.get('ledgerDefinition');
      this.ensureUniqueName({
        ledgerDefinition,
        ledgerDefinitions
      });
      model.ledgerDefinition.setProperties(ledgerDefinition);
    },

    ensureUniqueName({
      ledgerDefinition,
      ledgerDefinitions
    }) {
      let n = 1;
      let {
        name
      } = ledgerDefinition;

      while (ledgerDefinitions.find(ld => ld.get('name') === name)) {
        n++;
        name = "".concat(ledgerDefinition.name, "-").concat(n);
      }

      ledgerDefinition.name = name;
    },

    resetController(controller) {
      Ember.get(controller, 'ledgerDefinition').rollbackAttributes();
      controller.setProperties({
        activeTab: null
      });
    }

  };
  _exports.LedgerCreateRoute = LedgerCreateRoute;
});