define("pconsole/mixins/org-integration-products", ["exports", "lodash/isEqual"], function (_exports, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orgIntegrationProducts = void 0;
  const orgIntegrationProducts = {
    hasProducts: Ember.computed.notEmpty('integration.productIds'),
    initialProducts: null,
    // Set initialProducts when the editor is for an org integration
    integrationObserver: Ember.observer('integration', function () {
      Ember.set(this, 'initialProducts', Ember.get(this, 'integration.productIds').toArray());
    }),
    dirtyProducts: Ember.computed('integration.productIds', 'parentEntity', function () {
      const parentEntity = Ember.get(this, 'parentEntity'); // Only organization integrations have productIds

      if (parentEntity !== 'organization') {
        return false;
      }

      const products = Ember.get(this, 'integration.productIds');
      const newIds = new Set(products.map(({
        id
      }) => Number(id)));
      const oldIds = new Set(Ember.get(this, 'initialProducts').map(({
        id
      }) => Number(id)));
      const equalToIinitial = (0, _isEqual.default)(newIds, oldIds); //Edited back to initial state

      return !equalToIinitial;
    }),
    validProducts: Ember.computed('hasProducts', function () {
      const organization = Ember.get(this, 'integration.organization.content');
      return organization ? Ember.get(this, 'hasProducts') : true;
    }),
    disableForm: Ember.computed('isInvalid', 'integration.hasDirtyAttributes', 'validProducts', 'dirtyProducts', function () {
      const isInvalid = Ember.get(this, 'isInvalid'); // Set by FormMixin, baesd on validations

      const isDirty = Ember.get(this, 'integration.hasDirtyAttributes') || Ember.get(this, 'dirtyProducts');
      const validProducts = Ember.get(this, 'validProducts');
      return isInvalid || !isDirty || !validProducts;
    }),
    actions: {
      selectProducts(products) {
        Ember.set(this, 'integration.productIds', products);
      }

    }
  };
  _exports.orgIntegrationProducts = orgIntegrationProducts;
});