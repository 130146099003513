define("pconsole/controllers/product/settings/delete", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    productName: (0, _emberCpValidations.validator)('confirmation-to-lower', {
      message: "Product name doesn't match",
      on: 'product.name'
    })
  });

  var _default = Ember.Controller.extend(Validations, {
    isDeleting: false,
    product: Ember.computed.readOnly('model'),
    productName: '',
    redirect: false,
    notify: Ember.inject.service(),
    actions: {
      closeModal() {
        const redirect = Ember.get(this, 'redirect');

        if (redirect) {
          const product = Ember.get(this, 'product');
          const orgId = product.belongsTo('organization').id();
          let transition;

          if (orgId) {
            transition = this.transitionToRoute('organization.products', orgId);
          } else {
            transition = this.transitionToRoute('user.devices');
          }

          transition.followRedirects().then(() => {
            Ember.get(this, 'notify').success('Product deleted successfully');
          });
        } else {
          this.transitionToRoute('product.settings');
        }
      },

      async delete(product, close) {
        try {
          Ember.set(this, 'isDeleting', true);
          await this.validate();

          if (Ember.get(this, 'validations.isTruelyValid')) {
            await product.destroyRecord();
            Ember.set(this, 'redirect', true);
            close();
          }
        } catch (ex) {
          Ember.get(this, 'notify').error("Sorry, there was a problem deleting ".concat(Ember.get(product, 'name')));
        } finally {
          Ember.set(this, 'isDeleting', false);
        }
      }

    }
  });

  _exports.default = _default;
});