define("pconsole/controllers/product/team/invite-team-member", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, {
    notify: Ember.inject.service(),
    productTeamController: Ember.inject.controller('product.team'),
    validations: {
      'model.username': {
        presence: true,
        format: {
          with: /.+@.+\..{2,4}/,
          message: 'must be valid'
        }
      }
    },
    actions: {
      closeModal() {
        this.transitionToRoute('product.team');
      },

      updateProductUserRole(product, user, event) {
        const roleId = event.target.value;
        const role = this.store.peekRecord('role', roleId);
        user.set('role', role);
      }

    },

    save() {
      Ember.$('.loading').show();
      return this.model.save().then(user => {
        Ember.$('.loading').hide();
        const username = user.get('username'); // We have to manually push the new team member to the product team
        // to keep the productTeam list up to date. See the note in routes/product/team-new.js
        // for more explanation.

        const teamController = this.get('productTeamController');
        teamController.get('productTeam').pushObject(user);
        this.get('notify').success(username + ' has been invited to your team successfully!', {
          closeAfter: 4000
        });
        this.send('closeModal');
      }, response => {
        Ember.$('.loading').hide();
        const error = response.errors[0];
        const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
        this.get('notify').alert(errorMsg);
      });
    }

  });

  _exports.default = _default;
});