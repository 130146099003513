define("pconsole/components/asset-tracker-pagination-control", ["exports", "react", "prop-types", "styled-components"], function (_exports, _react, _propTypes, _styledComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AssetTrackerPaginationControl = ({
    count,
    metrics,
    page,
    pages,
    pageSize,
    perPage,
    onPerPageChange = () => {},
    onPreviousPageClick = () => {},
    onNextPageClick = () => {}
  }) => _react.default.createElement(PaginationControl, null, _react.default.createElement('div', null, 'Showing results', ' ', _react.default.createElement('span', {
    'data-test': 'page-range'
  }, "".concat(page * pageSize - pageSize + 1, " - ").concat(Math.min(page * pageSize, count))), ' ', 'of', ' ', _react.default.createElement('span', {
    'data-test': 'count'
  }, count)), _react.default.createElement(PageSizes, null, 'Per page:', ' ', [10, 20, 50, 100].map(pageSizeIncrement => _react.default.createElement(PageSizeButton, {
    key: pageSizeIncrement,
    active: perPage === pageSizeIncrement,
    disabled: perPage === pageSizeIncrement,
    'data-test': 'sizes',
    onClick: () => onPerPageChange(pageSizeIncrement)
  }, pageSizeIncrement))), _react.default.createElement(PageButton, {
    previous: true,
    disabled: page === 1,
    onClick: e => {
      onPreviousPageClick(e);
      metrics.trackEvent('page decremented');
    },
    'data-test': 'prev'
  }, 'Previous'), _react.default.createElement(PageButton, {
    next: true,
    disabled: page === pages,
    onClick: e => {
      onNextPageClick(e);
      metrics.trackEvent('page incremented');
    },
    'data-test': 'next'
  }, 'Next'));

  const PaginationControl = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-pagination-control__PaginationControl",
    componentId: "sc-2xpyhd-0"
  })(["align-items:center;border-top:1px solid var(--primary-border-color);color:var(--tertiary-text-color);display:grid;grid-template-columns:1fr 1fr min-content min-content;padding:0.8rem 1.6rem;"]);

  const PageButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-pagination-control__PageButton",
    componentId: "sc-2xpyhd-1"
  })(["background:none;border:none;font-size:0;min-height:30px;min-width:30px;transition:text-shadow 0.2s ease-in,transform 0.2s ease-in;&:disabled{color:var(--disabled-text-color);};&:hover:enabled{transform:translateY(-1px);}&::before{content:\"", "\";font-family:var(--icons-font);font-size:1.6rem;}"], props => props.previous ? "\f124" : "\f125");

  const PageSizes = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-pagination-control__PageSizes",
    componentId: "sc-2xpyhd-2"
  })(["margin:0 1.6rem;text-align:right;"]);

  const PageSizeButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-pagination-control__PageSizeButton",
    componentId: "sc-2xpyhd-3"
  })(["color:", ";background-color:transparent;border:none;padding:0 0.2rem;text-decoration:", ";transition:color 0.2s ease-in,text-shadow 0.2s ease-in,transform 0.2s ease-in;&:hover:enabled,&:focus:enabled{transform:translateY(-0.5px);}"], props => props.active ? 'var(--online-color)' : 'var(--primary-text-color)', props => props.active ? 'none' : 'underline');

  AssetTrackerPaginationControl.propTypes = {
    count: _propTypes.default.number.isRequired,
    metrics: _propTypes.default.object.isRequired,
    page: _propTypes.default.number.isRequired,
    pages: _propTypes.default.number.isRequired,
    pageSize: _propTypes.default.number.isRequired,
    perPage: _propTypes.default.number,
    onPerPageChange: _propTypes.default.func,
    onPreviousPageClick: _propTypes.default.func,
    onNextPageClick: _propTypes.default.func
  };
  var _default = AssetTrackerPaginationControl;
  _exports.default = _default;
});