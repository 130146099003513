define("pconsole/services/api-ajax", ["exports", "pconsole/config/environment", "@sentry/browser"], function (_exports, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),

    init() {
      Ember.$(() => {
        if (!Sentry) {
          return;
        }

        Ember.$(document).ajaxError((event, jqXHR, ajaxSettings, thrownError) => {
          // Add request info to Sentry context
          Sentry.addBreadcrumb({
            type: 'error',
            level: 'error',
            category: 'error.api-ajax',
            message: thrownError || jqXHR.statusText,
            data: {
              type: ajaxSettings.type,
              url: ajaxSettings.url,
              data: ajaxSettings.data,
              status: jqXHR.status,
              response: jqXHR.responseText
            }
          });
        });
      });
    },

    request(opts) {
      const token = Ember.get(this, 'session.data.authenticated.access_token');

      const options = _objectSpread({}, opts, {
        url: opts.url.startsWith('/') ? _environment.default.APP.API_ENDPOINT + opts.url : opts.url,
        crossDomain: true,
        headers: _objectSpread({
          'X-API-Version': _environment.default.APP.API_VERSION
        }, opts.headers, {
          'Authorization': token ? "Bearer ".concat(token) : undefined
        }),
        statusCode: {
          401: () => {
            if (this.get('session.isAuthenticated')) {
              this.get('session').invalidate();
            }
          }
        }
      });

      return Ember.$.ajax(options);
    },

    getRequest(opts) {
      return this.request(Object.assign(opts, {
        type: 'GET'
      }));
    },

    headRequest(opts) {
      return this.request(Object.assign(opts, {
        type: 'HEAD'
      }));
    },

    postRequest(opts) {
      return this.request(Object.assign(opts, {
        type: 'POST'
      }));
    },

    putRequest(opts) {
      return this.request(Object.assign(opts, {
        type: 'PUT'
      }));
    }

  });

  _exports.default = _default;
});