define("pconsole/components/logic-function-status-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['logic-function-status-indicator'],
    text: Ember.computed('logicFunction.enabled', function () {
      return Ember.get(this, 'logicFunction.enabled') ? 'Deployed' : 'Disabled';
    }),
    title: Ember.computed('logicFunction.enabled', function () {
      return Ember.get(this, 'logicFunction.enabled') ? 'Logic Function enabled' : 'Logic Function disabled';
    })
  });

  _exports.default = _default;
});