define("pconsole/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Customer = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    activationCode: _emberData.default.attr('string'),
    devices: _emberData.default.hasMany('device', {
      async: false
    })
  });

  Customer.reopenClass({
    FIXTURES: [{
      id: '2osrgu9834',
      fullName: 'Jeff Eiden',
      username: 'jeff.m.eiden@gmail.com',
      activationCode: '123456',
      devices: ['2389usdfjhu3489hfgfg']
    }, {
      id: '1osrgu9834',
      fullName: 'Samuel Jackson',
      username: 'sam.jackson@gmail.com',
      activationCode: '1128956',
      devices: ['1589usdfjhu3489hfgfg']
    }, {
      id: '3osrgu9834',
      fullName: 'John Travolta',
      username: 'johny.t@hotmail.com',
      activationCode: '9209183',
      devices: ['5379usdfjhu3489hfgfg']
    }, {
      id: '4osrgu9834',
      fullName: 'Uma Thurman',
      username: 'umaumakuma@killbill.com',
      activationCode: '2392388',
      devices: ['57389usdfjhu3489hfgfg']
    }, {
      id: '5osrgu9834',
      fullName: 'Quentin Tarantino',
      username: 'quentint@django.com',
      activationCode: '9219034',
      devices: ['8321usdfjhu3489hfgfg']
    }]
  });
  var _default = Customer;
  _exports.default = _default;
});