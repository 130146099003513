define("pconsole/models/subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Subscription = _emberData.default.Model.extend({
    start: _emberData.default.attr('date'),
    currentPeriodStart: _emberData.default.attr('date'),
    currentPeriodEnd: _emberData.default.attr('date'),
    currentMonthlyPeriodStart: _emberData.default.attr('date'),
    currentMonthlyPeriodEnd: _emberData.default.attr('date'),
    type: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    billDayOfMonth: _emberData.default.attr('number'),
    lastBillingAttemptStatus: _emberData.default.attr('string'),
    lastBillingAttemptAt: _emberData.default.attr('date'),
    seatCount: _emberData.default.attr('number'),
    outboundEventCount: _emberData.default.attr('number'),
    deviceCount: _emberData.default.attr('number'),
    billedThroughAccount: _emberData.default.attr('boolean'),
    perDeviceCostCents: _emberData.default.attr('number'),
    upcomingInvoiceEstimatedCostCents: _emberData.default.attr('number'),
    plan: _emberData.default.belongsTo('plan', {
      async: true
    }),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    sims: _emberData.default.hasMany('sim', {
      async: true
    }),
    product: _emberData.default.belongsTo('product', {
      async: true
    }),
    numberOfDaysLeftInBillingPeriod: Ember.computed('currentPeriodStart', 'currentPeriodEnd', function () {
      const moment = window.moment;
      const now = moment();
      const end = moment(this.get('currentPeriodEnd'));
      return end.diff(now, 'days');
    }),
    isPastDue: Ember.computed('status', function () {
      const status = this.get('status');
      return status === 'past_due';
    }),
    isActive: Ember.computed('status', function () {
      const status = this.get('status');
      return status === 'active' || status === 'past_due';
    })
  });

  var _default = Subscription;
  _exports.default = _default;
});