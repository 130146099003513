define("pconsole/templates/product/devices/unmark-development", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "caDyZWjO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[6,\"i\"],[9,\"class\",\"im-development-device-icon\"],[7],[8],[0,\"Unmark as Development Device\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[6,\"strong\"],[7],[0,\"Unmarking a device as a development device will return it to the pool of eligible product devices to receive automatic firmware updates\"],[8],[0,\". Non-development devices will be targeted to receive released product firmware or any firmware version it has been locked to run (\"],[6,\"a\"],[9,\"class\",\"learn-more\"],[9,\"href\",\"https://docs.particle.io/tutorials/product-tools/development-devices/\"],[9,\"target\",\"_blank\"],[7],[0,\"learn more\"],[8],[0,\").\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Would you like to unmark this device as a development device?\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary cancel-btn\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, cancel\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"unmarkDevelopment\"]],[7],[0,\"Yes, unmark as development device\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"helper-message\"],[7],[0,\"This action may result in an immediate over-the-air firmware update of this device\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/unmark-development.hbs"
    }
  });

  _exports.default = _default;
});