define("pconsole/helpers/first-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.first5 = first5;
  _exports.default = void 0;

  function first5(args) {
    const str = args[0];

    if (str.length <= 5) {
      return str;
    }

    return new Ember.Handlebars.SafeString("".concat(str.substring(0, 5), "<span class=\"safe dots\">...</span>"));
  }

  var _default = Ember.Helper.helper(first5);

  _exports.default = _default;
});