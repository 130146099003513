define("pconsole/templates/user/sims/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DgBZPUc2",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"bread-crumbs\",null,[[\"tagName\",\"linkable\"],[\"ul\",true]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"iccid-scanner single-scan\"],[7],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[0,\"Enter SIM ICCID\"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"type-iccids\"],[7],[0,\"\\n\"],[4,\"form-wrapper\",null,[[\"autoFocus\"],[false]],{\"statements\":[[0,\"        \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"placeholder\"],[\"ICCID\",\"ICCID\",\"8888888888888888888\"]]],false],[0,\"\\n        \"],[1,[25,\"form-submission\",null,[[\"cancel\",\"saveText\",\"disabled\"],[false,\"Add SIM Card\",[20,[\"isInvalid\"]]]]],false],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sim-instructions-wrapper\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"img-wrapper\"],[7],[0,\"\\n            \"],[6,\"img\"],[9,\"class\",\"sim-img\"],[9,\"src\",\"/assets/sim-card.png\"],[7],[8],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"sim-instructions\"],[7],[0,\"You can find your ICCID number on the back of your Particle SIM card, below the barcode\"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/user/sims/import.hbs"
    }
  });

  _exports.default = _default;
});