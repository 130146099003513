define("pconsole/routes/user/networks/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,

    model(params) {
      return Ember.RSVP.hash({
        networks: this.findPaged('network', params),
        networkTypes: this.store.findAll('networkType')
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', null);
        controller.set('perPage', null);
      }
    }

  });

  _exports.default = _default;
});