define("pconsole/routes/user", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserRoute = Ember.Route.extend(_routeMixin.default, {
    breadCrumb: null,
    perPage: 25,
    session: Ember.inject.service(),

    beforeModel(transition) {
      const routeData = this.get('routeData'); // ember-simple-auth validate auth or redirect to login

      this.get('session').requireAuthentication(transition, 'login');
      routeData.assignRouteData(transition);

      this._super(transition);
    },

    model(params) {
      return Ember.RSVP.hash({
        user: this.modelFor('application').user,
        networks: this.findPaged('network', params)
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('user', model);
    },

    serialize(model) {
      return {
        username: model.get('username')
      };
    },

    deactivate() {
      const devices = this.store.peekAll('device');
      devices.forEach(d => {
        d.unloadRecord();
      });
    }

  });
  var _default = UserRoute;
  _exports.default = _default;
});