define("pconsole/routes/product/devices/deny-quarantined-device", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return this.store.find('device', params.device_id);
    }

  });

  _exports.default = _default;
});