define("pconsole/routes/product/firmware/upload", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    viewName: 'product/firmware/upload',

    model() {
      const newFirmware = this.store.createRecord('product-firmware');
      return this.store.findAll('product-firmware').then(fws => {
        const lastVersion = fws.sortBy('version').get('lastObject');
        const maxVersion = +(lastVersion.get('version') || 0);
        newFirmware.set('version', maxVersion + 1);
        return Ember.RSVP.hash({
          model: newFirmware,
          product: this.modelFor('product').product
        });
      });
    },

    setupController(controller, model) {
      controller.setProperties(model);
    }

  });

  _exports.default = _default;
});