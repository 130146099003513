define("pconsole/adapters/customer", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('routeData.product', function () {
      return 'v1/products/' + this.get('routeData.product');
    })
  });

  _exports.default = _default;
});