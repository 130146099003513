define("pconsole/adapters/device-configuration", ["exports", "pconsole/adapters/fleet-configuration"], function (_exports, _fleetConfiguration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fleetConfiguration.default.extend({
    notify: Ember.inject.service(),

    handleResponse(status) {
      if (status === 202) {
        Ember.get(this, 'notify').info('Device configuration will be updated when it comes online');
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});