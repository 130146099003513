define("pconsole/templates/components/logic-run-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fkYok/CE",
    "block": "{\"symbols\":[\"log\",\"part\",\"index\"],\"statements\":[[6,\"div\"],[9,\"class\",\"logic-run-log\"],[7],[0,\"\\n\"],[6,\"pre\"],[9,\"class\",\"logic-run-log__container\"],[7],[4,\"each\",[[20,[\"runLog\",\"logs\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"showTimestamps\"]]],null,{\"statements\":[[6,\"span\"],[9,\"class\",\"logic-run-log__timestamp\"],[7],[1,[25,\"formatted-date\",[[19,1,[\"timestamp\"]],\"h:mm:ss.SSS A\"],null],false],[0,\": \"],[8]],\"parameters\":[]},null],[6,\"span\"],[9,\"class\",\"logic-run-log__message\"],[7],[4,\"each\",[[19,1,[\"args\"]]],null,{\"statements\":[[4,\"if\",[[19,3,[]]],null,{\"statements\":[[0,\" \"]],\"parameters\":[]},null],[1,[25,\"stringify-json\",[[19,2,[]]],null],false]],\"parameters\":[2,3]},null],[8],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"unless\",[[20,[\"runLog\",\"err\"]]],null,{\"statements\":[[6,\"span\"],[9,\"class\",\"logic-run-log__message\"],[7],[0,\"(No output)\"],[8]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[20,[\"runLog\",\"err\"]]],null,{\"statements\":[[6,\"div\"],[9,\"class\",\"logic-run-log__error\"],[7],[6,\"span\"],[7],[1,[20,[\"runLog\",\"err\"]],false],[8],[8]],\"parameters\":[]},null],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"runLog\",\"logs\"]]],null,{\"statements\":[[0,\"  \"],[6,\"label\"],[9,\"class\",\"logic-run-log__checkbox\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showTimestamps\"]]]]],false],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"Show timestamps\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/logic-run-log.hbs"
    }
  });

  _exports.default = _default;
});