define("pconsole/adapters/plan", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindAll(modelName) {
      return this.buildURL(modelName) + '?billable_area=platform';
    }

  });

  _exports.default = _default;
});