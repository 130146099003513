define("pconsole/helpers/large-bytes-shortener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.largeBytesShortener = largeBytesShortener;
  _exports.default = void 0;

  function largeBytesShortener([value]) {
    const oneKilo = 1024;
    const oneMega = 1024 * oneKilo;
    const oneGiga = 1024 * oneMega;

    if (typeof value === 'number') {
      if (value < oneKilo) {
        return value;
      } else if (value >= oneKilo && value < oneMega) {
        return Math.floor(value / oneKilo * 10) / 10 + 'k';
      } else if (value >= oneMega && value < oneGiga) {
        return Math.floor(value / oneMega * 10) / 10 + 'M';
      } else if (value >= oneGiga) {
        return Math.floor(value / oneGiga * 10) / 10 + 'G';
      }
    } else {
      return value;
    }
  }

  var _default = Ember.Helper.helper(largeBytesShortener);

  _exports.default = _default;
});