define("pconsole/mixins/device-variables-functions-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),

    _error(message) {
      this.get('notify').alert(message);
    },

    _constructUrl() {
      const product = this.get('routeData.product');
      let url = '/v1/';

      if (product) {
        url += "products/".concat(product, "/");
      }

      url += "devices/".concat(this.get('device.id'));
      return url;
    },

    _parseResponse(response, field) {
      const ret = {
        result: null
      };

      if (response && typeof response[field] !== 'undefined' && response[field] !== null) {
        const result = response[field];
        ret.result = result.toString();
      }

      return ret;
    },

    actions: {
      afterCopy() {
        this.get('notify').info("Copied the value to the clipboard successfully!");
      }

    }
  });

  _exports.default = _default;
});