define("pconsole/models/org-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    newlyCreated: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    organization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    invited: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    memberships: _emberData.default.hasMany('membership', {
      async: false
    }),
    role: Ember.computed('memberships', function () {
      const DEFAULT_ROLE_NAME = 'Member';

      if (this.get('memberships.length')) {
        return this.get('memberships.firstObject.role');
      } else {
        // We can peek because the roles have already been loaded
        return this.store.peekAll('role').filter(role => {
          // The default role should be Member
          return role.get('name') === DEFAULT_ROLE_NAME && !role.belongsTo('organization').id() && !role.belongsTo('product').id();
        }).get('firstObject');
      }
    }),
    isProductOwner: _emberData.default.attr('boolean', {
      defaultValue: false
    }),

    setRoleForOrganization(role) {
      const scopes = role.get('scopes');
      let membership = this.get('memberships').get('firstObject'); // The user doesn't have a membership yet, probably first time assigned a role

      if (!membership) {
        membership = this.store.createRecord('membership', {
          role,
          scopes
        });
        this.get('memberships').pushObject(membership);
      } else {
        membership.role = role;
        membership.scopes = scopes;
        this.notifyPropertyChange('memberships');
      }
    }

  });

  _exports.default = _default;
});