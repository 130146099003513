define("pconsole/components/graph-chart", ["exports", "d3", "moment"], function (_exports, _d, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    isLoading: true,
    url: Ember.computed('name', 'filter', function () {
      const product = this.get('routeData.product');
      const name = this.get('name');
      const filter = this.get('filter');
      let params = '';
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          params += "".concat(key, "=").concat(filter[key], "&");
        }
      });
      return "/v1/products/".concat(product, "/metrics/").concat(name, "?").concat(params);
    }),

    /* ──────────────────────────────── Private Methods ──────────────────────────────── */
    _setupGraph() {
      const svgEl = this.$('.c-graph-chart svg')[0];

      const svg = _d.default.select(svgEl);

      const parentWidth = svg.node().parentNode.offsetWidth - 20;
      const margin = {
        top: 10,
        right: 10,
        bottom: this.get('bottom') || 20,
        left: 40
      };
      const width = parentWidth - margin.left - margin.right;
      let heightTotal = this.get('height') || 200;

      if (this.get('parentHeight')) {
        const boxPadding = 22;
        /* verticall padding + border = 2 * (10 + 1) */

        heightTotal = svg.node().parentNode.parentNode.parentNode.offsetHeight - boxPadding;
      }

      const height = heightTotal - margin.top - margin.bottom;
      svg.selectAll('*').remove();
      svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
      this.setProperties({
        svg,
        width,
        height,
        margin
      });

      this._drawAxes();
    },

    /* ──────────────── Axes ──────────────── */
    _drawAxes() {
      const svg = this.get('svg');
      const width = this.get('width');
      const height = this.get('height');
      const margin = this.get('margin');

      const x = _d.default.scaleTime().domain([(0, _moment.default)().subtract(1, 'hour'), (0, _moment.default)()]).range([0, width]);

      const xAxis = svg.append('g').classed('__axis --x', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top + height, ")")).call(_d.default.axisBottom(x).tickSizeOuter(0));

      const y = _d.default.scaleLinear().domain([0, 100]).range([height, 0]);

      const yAxis = svg.append('g').classed('__axis --y', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).call(_d.default.axisLeft(y).ticks(6));
      const yGrid = svg.append('g').classed('__grid', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).call(_d.default.axisRight(y).tickSizeOuter(0).tickSizeInner(width).ticks(6).tickFormat(''));
      this.setProperties({
        x,
        xAxis,
        y,
        yAxis,
        yGrid
      });
    },

    _updateAxes() {
      const data = this.get('data');
      const name = this.get('name');
      const width = this.get('width');
      const x = this.get('x');
      const xAxis = this.get('xAxis');
      const y = this.get('y');
      const yAxis = this.get('yAxis');
      const yGrid = this.get('yGrid');
      const start_date = this.get('filter.start_date');
      const bucket_size = this.get('filter.bucket_size') * 1000;
      const type = this.get('type');
      const timeDomain = getTimeDomain(data, type, bucket_size);
      const maxDomain = getMaxDomain(data, name);

      if (!data.length) {
        x.domain([(0, _moment.default)(start_date), (0, _moment.default)()]);
        y.domain([0, 100]);
      } else {
        x.domain(timeDomain);
        y.domain([0, maxDomain || 1]);
      }

      xAxis.transition(_d.default.transition().duration(300)).call(_d.default.axisBottom(x).tickSizeOuter(0).ticks(8));
      const yAxisTicks = y.ticks().filter(t => Number.isInteger(t));

      const yAxisGenerator = _d.default.axisLeft(y).tickSizeInner(4).ticks(6).tickFormat(_d.default.format('~s')).tickValues(yAxisTicks);

      yAxis.transition(_d.default.transition().duration(300)).call(yAxisGenerator);
      yGrid.transition(_d.default.transition().duration(300)).call(_d.default.axisRight(y).tickSizeOuter(0).tickSizeInner(width).ticks(6).tickFormat(_d.default.format('~s')).tickValues(yAxisTicks).tickFormat(''));
    },

    _updateGraph() {
      const type = this.get('type');
      const data = this.get('data');

      this._drawEmptyState();

      if (data.length) {
        switch (type) {
          case 'area':
            this._drawArea();

            break;

          case 'stacked-bar':
            this._drawStackedBar();

            break;
        }
      }
    },

    /* ──────────────── Area ──────────────── */
    _drawArea() {
      const svg = this.get('svg');
      const name = this.get('name');
      const data = this.get('data');
      const margin = this.get('margin');
      const x = this.get('x');
      const y = this.get('y');
      const vital = getVital(name);
      const curveStyle = _d.default.curveMonotoneX;

      const area = _d.default.area().defined(d => d[vital] || d[vital] === 0).curve(curveStyle).x(d => x(d.time)).y0(y(0)).y1(d => y(d[vital]));

      const area0 = _d.default.area().curve(curveStyle).x(d => x(d.time)).y0(y(0)).y1(y(0));

      const line = _d.default.line().defined(d => d[vital] || d[vital] === 0).curve(curveStyle).x(d => x(d.time)).y(d => y(d[vital]));

      const line0 = _d.default.line().defined(d => d[vital] || d[vital] === 0).curve(curveStyle).x(d => x(d.time)).y(y(0));

      let areaEl = this.get('area-element');

      if (!areaEl) {
        areaEl = svg.append('path').classed('__area', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"));
        this.set('area-element', areaEl);
      }

      let lineEl = this.get('line-element');

      if (!lineEl) {
        lineEl = svg.append('path').classed('__line', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"));
        this.set('line-element', lineEl);
      }

      areaEl.attr('d', area0(data)).transition(_d.default.transition().duration(300)).attr('d', area(data));
      lineEl.attr('d', line0(data)).transition(_d.default.transition().duration(300)).attr('d', line(data));
      let focus = this.get('focus-element');

      if (!focus) {
        focus = svg.append('g').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).style('display', 'none');
        focus.append('circle').attr('class', 'y').style('fill', 'var(--primary-text-color)').style('stroke', 'var(--success-graph-color)').style('stroke-width', '2').attr('r', 4);
        this.set('focus', focus);
      }

      const bisectDate = _d.default.bisector(function (d) {
        return d.time;
      }).left;

      const dateFormatter = _d.default.timeFormat('%b %e at %H:%M');

      const tooltip = _d.default.select('.tooltip--main');

      const bucket_size = this.get('filter.bucket_size') * 1000;
      svg.on('mouseover', function () {
        tooltip.style('display', 'inline-block');
        focus.style('display', null);
      }).on('mouseout', function () {
        tooltip.style('display', 'none');
        focus.style('display', 'none');
      }).on('mousemove', function () {
        const x0 = x.invert(_d.default.mouse(this)[0] - margin.left);
        const i = bisectDate(data, x0, 1);
        const d0 = data[i - 1] || {};
        const d1 = data[i] || {};
        const d = x0 - d0.time > d1.time - x0 ? d1 : d0;

        _d.default.select('.tooltip--date').html("".concat(dateFormatter(d.time), " \u2013 ").concat(dateFormatter(new Date(d.time.getTime() + bucket_size))));

        _d.default.select('.tooltip--vitals').selectAll('*').remove();

        switch (name) {
          case 'events':
            focus.select('circle.y').attr('transform', 'translate(' + x(d.time) + ',' + y(d.received) + ')');

            _d.default.select('.tooltip--vitals').append('p').html("<i class=\"circle count\"></i>Received: <b>".concat(Math.round(d.received), "</b>"));

            break;

          case 'online':
            focus.select('circle.y').attr('transform', 'translate(' + x(d.time) + ',' + y(d.count) + ')');

            _d.default.select('.tooltip--vitals').append('p').html("<i class=\"circle count\"></i>Online: <b>".concat(Math.round(d.count), "</b>"));

            break;

          default:
        }

        let left = _d.default.event.pageX + 16;
        left = left < window.innerWidth - 220 ? left : window.innerWidth - 220;
        tooltip.style('left', left + 'px').style('top', _d.default.event.pageY + 'px');
      });
    },

    /* ──────────────── Stacked-Bar ──────────────── */
    _drawStackedBar() {
      const svg = this.get('svg');
      const data = this.get('data');
      const name = this.get('name');
      const margin = this.get('margin');
      const x = this.get('x');
      const y = this.get('y');
      const series = getSeries(data, name);
      const colors = getColors(name);
      const bucket_size = this.get('filter.bucket_size') * 1000;
      const dx = x(bucket_size) - x(new Date(0));
      const bucketMargin = 4;
      let stackedBarEl = this.get('stacked-bar-element');

      if (!stackedBarEl) {
        stackedBarEl = svg.append('g').classed('__stacked-bar', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"));
        this.set('stacked-bar-element', stackedBarEl);
      }

      stackedBarEl.selectAll('g').data(series).join('g').classed('--group', true).attr('fill', d => colors[d.key]).selectAll('rect').data(d => d).join('rect').attr('x', d => x(d.data.time) + bucketMargin / 2).attr('y', d => y(d[1])).attr('height', d => y(d[0]) - y(d[1])).attr('width', dx - bucketMargin);

      const bisectDate = _d.default.bisector(function (d) {
        return d.time;
      }).left;

      const dateFormatter = _d.default.timeFormat('%b %e at %H:%M');

      const tooltip = _d.default.select('.tooltip--main');

      svg.on('mouseover', function () {
        tooltip.style('display', 'inline-block');
      }).on('mouseout', function () {
        tooltip.style('display', 'none');
      }).on('mousemove', function () {
        const x0 = x.invert(_d.default.mouse(this)[0] - margin.left);
        const i = bisectDate(data, x0, 1);
        const d0 = data[i - 1] || {};
        const d1 = data[i] || {};
        const d = x0 - d0.time > d1.time - x0 ? d1 : d0;

        _d.default.select('.tooltip--date').html("".concat(dateFormatter(d.time), " \u2013 ").concat(dateFormatter(new Date(d.time.getTime() + bucket_size))));

        _d.default.select('.tooltip--vitals').selectAll('*').remove();

        switch (name) {
          case 'events':
            _d.default.select('.tooltip--vitals').append('p').html("<i class=\"circle received\"></i>Received: <b>".concat(Math.round(d.received), "</b>"));

            break;

          case 'integrations':
            _d.default.select('.tooltip--vitals').append('p').html("<i class=\"circle success\"></i>Success: <b>".concat(Math.round(d.success), "</b>")).append('p').html("<i class=\"circle failure\"></i>Failure: <b>".concat(Math.round(d.failure), "</b>")).append('p').html("<i class=\"circle skipped\"></i>Skipped: <b>".concat(Math.round(d.sleep), "</b>"));

            break;

          case 'functions':
          case 'variables':
            _d.default.select('.tooltip--vitals').append('p').html("<i class=\"circle success\"></i>Success: <b>".concat(Math.round(d.success), "</b>")).append('p').html("<i class=\"circle failure\"></i>Failure: <b>".concat(Math.round(d.failure), "</b>")).append('p').html("<i class=\"circle offline\"></i>Offline: <b>".concat(Math.round(d.offline), "</b>"));

            break;

          default:
            _d.default.select('.tooltip--vitals').append('p').html("<i class=\"circle success\"></i>Success: <b>".concat(Math.round(d.success), "</b>")).append('p').html("<i class=\"circle failure\"></i>Failure: <b>".concat(Math.round(d.failure), "</b>"));

        }

        let left = _d.default.event.pageX + 16;
        left = left < window.innerWidth - 220 ? left : window.innerWidth - 220;
        tooltip.style('left', left + 'px').style('top', _d.default.event.pageY + 'px');
      });
    },

    /* ──────────────── Legend ──────────────── */
    _drawLegend() {
      const name = this.get('name');
      const componentEl = this.$('.c-graph-chart')[0];

      const component = _d.default.select(componentEl);

      const legend = getLegend(name);
      component.append('div').classed('__legend', true).selectAll('.__item').data(legend).join('div').classed('__item', true).html(d => {
        return "<i class=\"__circle\" style=\"background: ".concat(d.color, "\"></i>").concat(d.label);
      });
    },

    /* ──────────────── Empty State ──────────────── */
    _drawEmptyState() {
      const data = this.get('data');
      const margin = this.get('margin');
      const componentEl = this.$('.c-graph-chart')[0];

      const component = _d.default.select(componentEl);

      if (!data.length) {
        let emptyState = this.get('empty-state-element');

        if (!emptyState) {
          emptyState = component.append('div').classed('__empty-state', true).style('left', margin.left + 8 + 'px')
          /* wrapper div has 10px padding, but sometimes artifacts extend for couple pixels horizontally */
          .style('right', margin.right + 6 + 'px').style('bottom', margin.bottom + 10 + 'px').style('top', margin.top + 10 + 'px').html("No data for this time period!");
          this.set('empty-state-element', emptyState);
        }
      } else {
        const emptyState = this.get('empty-state-element');

        if (emptyState) {
          emptyState.remove();
        }

        this.set('empty-state-element', null);
      }
    },

    /* ──────────────── Handle Resize ──────────────── */
    _handleResize() {
      this.set('height', null);
      this.set('area-element', null);
      this.set('empty-state-element', null);
      this.set('line-element', null);
      this.set('stacked-bar-element', null);

      this._setupGraph();

      this._updateAxes();

      this._updateGraph();
    },

    /* ──────────────── Fetch Data ──────────────── */
    async _getData() {
      const name = this.get('name');
      this.set('isLoading', true);
      const demo = this.get('demo');

      if (demo) {
        const data = generateDemoData(name, this.get('filter'));
        data.shift();
        this.set('data', data);

        this._updateAxes();

        this._updateGraph();

        this.set('isLoading', false);
        return;
      }

      try {
        const response = await this.get('apiAjax').getRequest({
          url: this.get('url')
        });
        const data = parseResponse(response, name);
        data.shift();
        this.set('data', data);

        this._updateAxes();

        this._updateGraph();
      } catch (error) {
        if (!this.isDestroyed) {
          console.error(error);
          this.get('notify').error("There was a problem while drawing the ".concat(name, " graph."));
        }
      } finally {
        if (!this.isDestroyed) {
          this.set('isLoading', false);
        }
      }
    },

    /* ════════════════════════════════ Lifecycle Hooks ════════════════════════════════ */
    didReceiveAttrs() {
      this._super(...arguments);

      const url = this.get('url');

      if (url) {
        Ember.run.scheduleOnce('afterRender', this, this._getData);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._setupGraph();

      switch (this.get('type')) {
        case 'stacked-bar':
          this._drawLegend();

          break;
      }

      const resizeHandler = this._handleResize.bind(this);

      this.set('resizeHandler', resizeHandler);
      window.addEventListener('resize', resizeHandler);
    },

    willDestroyElement() {
      window.removeEventListener('resize', this.get('resizeHandler'));

      this._super(...arguments);
    },

    /* ════════════════════════════════ Actions ════════════════════════════════ */
    actions: {}
  });
  /* ╔══════════════════════════════════╗
     ║ Graphs name-based customizations ║
     ╚══════════════════════════════════╝ */


  _exports.default = _default;

  function parseResponse(response, name) {
    switch (name) {
      case 'events':
        return response.events.map(d => {
          return {
            time: new Date(d.time),
            received: toNumber(d.received)
          };
        });

      case 'integrations':
        return response.integrations.map(d => {
          return {
            time: new Date(d.time),
            success: toNumber(d.success),
            failure: toNumber(d.failure),
            sleep: toNumber(d.sleep)
          };
        });

      case 'functions':
        return response.functions.map(d => {
          return {
            time: new Date(d.time),
            success: toNumber(d.success),
            failure: toNumber(d.failure),
            offline: toNumber(d.offline)
          };
        });

      case 'variables':
        return response.variables.map(d => {
          return {
            time: new Date(d.time),
            success: toNumber(d.success),
            failure: toNumber(d.failure),
            offline: toNumber(d.offline)
          };
        });

      case 'online':
        return response.online.map(d => {
          return {
            time: new Date(d.time),
            count: toNumber(d.count)
          };
        });
    }
  }

  function generateDemoData(name, filter) {
    const start_date = filter.start_date;
    const end_date = filter.end_date || (0, _moment.default)();
    const bucket_size = filter.bucket_size;
    const data = [];
    let d = (0, _moment.default)(start_date);
    let received;

    while (d <= end_date) {
      d = d.add(bucket_size, 'seconds');

      switch (name) {
        case 'events':
          if (data.length) {
            received = data[data.length - 1].received + rand(-2, 2);
            received = received < 0 ? 0 : received;
          } else {
            received = rand(5, 15);
          }

          data.push({
            time: new Date(d),
            received
          });
          break;

        case 'integrations':
          data.push({
            time: new Date(d),
            success: rand(5, 10),
            failure: rand(0, 10),
            sleep: rand(0, 10)
          });
          break;

        case 'functions':
          data.push({
            time: new Date(d),
            success: rand(5, 10),
            failure: rand(0, 10),
            offline: rand(0, 10)
          });
          break;

        case 'variables':
          data.push({
            time: new Date(d),
            success: rand(5, 10),
            failure: rand(0, 10),
            offline: rand(0, 10)
          });
          break;

        case 'online':
          data.push({
            time: new Date(d),
            count: rand(5, 15)
          });
      }
    }

    const response = {};

    switch (name) {
      case 'events':
        response.events = data;
        break;

      case 'integrations':
        response.integrations = data;
        break;

      case 'functions':
        response.functions = data;
        break;

      case 'variables':
        response.variables = data;
        break;

      case 'online':
        response.online = data;
        break;
    }

    return parseResponse(response, name);
  }

  function getVital(name) {
    switch (name) {
      case 'events':
        return 'received';

      case 'online':
      default:
        return 'count';
    }
  }

  function getTimeDomain(data, type, bucket_size) {
    switch (type) {
      case 'stacked-bar':
        return [_d.default.min(data, d => d.time), _d.default.max(data, d => new Date(d.time.getTime() + bucket_size))];

      case 'area':
      default:
        return [_d.default.min(data, d => d.time), _d.default.max(data, d => d.time)];
    }
  }

  function getMaxDomain(data, name) {
    switch (name) {
      case 'events':
        return _d.default.max(data, d => d.received * 1.02);

      case 'integrations':
        return _d.default.max(data, d => (d.success + d.failure + d.sleep) * 1.02);

      case 'functions':
      case 'variables':
        return _d.default.max(data, d => (d.success + d.failure + d.offline) * 1.02);

      case 'online':
      default:
        return _d.default.max(data, d => d.count * 1.02);
    }
  }

  function getSeries(data, name) {
    switch (name) {
      case 'events':
        return _d.default.stack().keys(['received'])(data);

      case 'integrations':
        return _d.default.stack().keys(['success', 'failure', 'sleep'])(data);

      case 'functions':
      case 'variables':
        return _d.default.stack().keys(['success', 'failure', 'offline'])(data);
    }
  }

  function getColors(name) {
    switch (name) {
      case 'integrations':
        return {
          success: 'var(--success-graph-color)',
          failure: 'var(--danger-color)',
          sleep: 'var(--timeout-color)'
        };

      case 'functions':
      case 'variables':
        return {
          success: 'var(--success-graph-color)',
          failure: 'var(--danger-color)',
          offline: 'var(--timeout-color)'
        };

      default:
        return {
          received: 'var(--success-graph-color)',
          success: 'var(--success-graph-color)',
          failure: 'var(--danger-color)',
          offline: 'var(--timeout-color)',
          sleep: 'var(--timeout-color)'
        };
    }
  }

  function getLegend(name) {
    switch (name) {
      case 'integrations':
        return [{
          color: 'var(--success-graph-color)',
          label: 'Success'
        }, {
          color: 'var(--danger-color)',
          label: 'Failure'
        }, {
          color: 'var(--timeout-color)',
          label: 'Skipped'
        }];

      case 'functions':
      case 'variables':
        return [{
          color: 'var(--success-graph-color)',
          label: 'Success'
        }, {
          color: 'var(--danger-color)',
          label: 'Failure'
        }, {
          color: 'var(--timeout-color)',
          label: 'Offline'
        }];
    }
  }
  /* ———————————————————————————————— Random Generator ———————————————————————————————— */


  function rand(...args) {
    if (args.length === 1) {
      const arg = args[0];

      if (Array.isArray(arg)) {
        return arg[rand(0, arg.length - 1)];
        /* rand([1,2,3]) → 1..3 */
      } else {
        return rand(0, arg - 1);
        /* rand(5) → 0..4 */
      }
    } else if (args.length === 2) {
      const min = args[0];
      const max = args[1];
      return Math.floor(Math.random() * (max - min + 1)) + min;
      /* rand(1, 5) → 1..5 */
    }

    return 0;
  }

  function toNumber(n) {
    return n || n === 0 ? Number(n) : null;
  }
});