define("pconsole/helpers/large-number-shortener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.largeNumberShortener = largeNumberShortener;
  _exports.default = void 0;

  function largeNumberShortener([value]) {
    const one_thousand = 1000;
    const one_million = 1000000;
    const one_billion = 1000000000;

    if (typeof value === 'number') {
      if (value < one_thousand) {
        return value;
      } else if (value >= one_thousand && value < one_million) {
        return Math.floor(value / 100) / 10 + 'K';
      } else if (value >= one_million && value < one_billion) {
        //New naming: M instead of MM
        return Math.floor(value / 100000) / 10 + 'M';
      } else if (value >= one_billion) {
        return Math.floor(value / 100000000) / 10 + 'B';
      }
    } else {
      return value;
    }
  }

  var _default = Ember.Helper.helper(largeNumberShortener);

  _exports.default = _default;
});