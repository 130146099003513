define("pconsole/mixins/object-filter-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filteredQueryParams: Ember.computed('queryParams', function () {
      const queryParams = this.get('queryParams'); // 'page' and 'perPage' are used for pagination, and not
      // relevant for searching and filtering

      return queryParams.without('page').without('perPage').without('sortAttr').without('sortDir');
    }),
    actions: {
      triggerFilter(chosenQueryParam, searchValue) {
        const filteredQueryParams = this.get('filteredQueryParams'); // Reset all other query params to keep things simple and ensure
        // we are dealing with one attribute at any given time.

        filteredQueryParams.forEach(qp => this.set(qp, undefined));
        this.set(chosenQueryParam, searchValue);
      }

    }
  });

  _exports.default = _default;
});