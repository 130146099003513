define("pconsole/controllers/product/devices/unclaim-device", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, {
    apiAjax: Ember.inject.service(),
    application: Ember.inject.controller(),
    notify: Ember.inject.service(),
    url: Ember.computed('model', 'routeData.product', function () {
      const device = this.get('model');
      const isProduct = this.get('routeData.product');

      if (isProduct) {
        const productSlug = device.get('product.slug');
        return '/v1/products/' + productSlug + '/devices/' + device.get('id') + '/owner';
      } else {
        return '/v1/devices/' + device.get('id');
      }
    }),
    actions: {
      closeModal() {
        this.send('handleTransition');
      },

      handleTransition(unclaiming) {
        const isProduct = this.get('routeData.product');
        const device = this.get('model');

        if (isProduct) {
          this.transitionToRoute(this.get('previousRoute'));
        } else {
          this.transitionToRoute('user.devices');
        }

        if (unclaiming) {
          if (isProduct) {
            device.set('owner', null);
          } else {
            device.unloadRecord();
          }
        }
      },

      async unclaimDevice() {
        const notify = this.get('notify');
        const $loading = Ember.$('.loading');
        $loading.show();

        try {
          await this.get('apiAjax').request({
            url: this.get('url'),
            type: 'DELETE'
          });
          this.send('handleTransition', true);
          notify.success('The device has been successfully unclaimed.');
          const currentDeveloperServiceAgreement = Ember.get(this, 'application.currentDeveloperServiceAgreement');

          if (currentDeveloperServiceAgreement) {
            await currentDeveloperServiceAgreement.reload();
          }
        } catch (err) {
          notify.error('There was a problem unclaiming the device.');
          throw err;
        } finally {
          $loading.hide();
        }
      }

    }
  });

  _exports.default = _default;
});