define("pconsole/routes/organization/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OrgTeamRoute = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        roles: this.store.findAll('role'),
        team: this.store.query('team', {
          orgId: this.modelFor('organization').organization.get('id')
        })
      });
    }

  });
  var _default = OrgTeamRoute;
  _exports.default = _default;
});