define("pconsole/components/eventlogs/pause-button.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PauseButton = ({
    paused,
    onClick
  }) => _react.default.createElement("div", {
    className: "hint--top",
    "data-hint": "Pause the event stream"
  }, _react.default.createElement("button", {
    className: "ui button outline secondary",
    onClick: onClick
  }, paused ? _react.default.createElement("i", {
    className: "icon ion-play"
  }) : _react.default.createElement("i", {
    className: "icon ion-pause"
  })));

  var _default = PauseButton;
  _exports.default = _default;
});