define("pconsole/controllers/product/devices/deny-quarantined-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    apiAjax: Ember.inject.service(),
    actions: {
      closeModal() {
        this.transitionToRoute('product.devices');
      },

      denyDevice() {
        const routeData = this.get('routeData');
        const product = routeData.product;
        const device = this.get('model');
        const deviceID = device.get('id');
        Ember.$('.loading').show();
        this.get('apiAjax').request({
          url: '/v1/products/' + product + '/devices/' + deviceID,
          type: 'DELETE',
          data: JSON.stringify({
            deny: true
          }),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json'
        }).then(() => {
          Ember.$('.loading').hide();
          this.transitionToRoute('product.devices').then(() => {
            this.send('refreshModel');
            this.get('notify').success('The device was successfully denied from this product');
          });
        }, () => {
          Ember.$('.loading').hide();
          this.get('notify').error('There was a problem denying the device from this product. Please try again later.');
        });
      }

    }
  });

  _exports.default = _default;
});