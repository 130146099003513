define("pconsole/routes/organization/billing", ["exports", "pconsole/utils/platforms"], function (_exports, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    usageAlerts: Ember.inject.service(),

    model() {
      const applicationController = this.controllerFor('application'); // hide usage alerts for this service agreement because they are
      // shown on this page as well

      const usageAlerts = Ember.get(this, 'usageAlerts');
      usageAlerts.markShownForServiceAgreement(Ember.get(applicationController, 'currentServiceAgreement'));
      const {
        organization,
        products
      } = this.modelFor('organization');
      const maintenanceMode = Ember.get(organization, 'maintenanceMode');

      if (maintenanceMode) {
        throw new Error('Your billing info is not available at this time. Please check back in a few moments.');
      }

      const productsByType = products.reduce((accumulator, product) => {
        const platformId = product.belongsTo('platform').id();
        const productObject = {
          id: product.id,
          name: Ember.get(product, 'name'),
          slug: Ember.get(product, 'slug')
        };

        switch ((0, _platforms.connectivityTypeForPlatform)(platformId)) {
          case 'cellular':
            accumulator.cellular.push(productObject);
            break;

          case 'wifi':
            accumulator.wifi.push(productObject);
            break;
        }

        return accumulator;
      }, {
        assetTracker: [],
        cellular: [],
        wifi: []
      });
      return {
        organization,
        productsByType,
        serviceAgreement: Ember.get(applicationController, 'currentServiceAgreement')
      };
    }

  });

  _exports.default = _default;
});