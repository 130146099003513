define("pconsole/templates/product/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UPWCfh7q",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"asset-tracker-page location-page\"],[7],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[4,\"if\",[[20,[\"product\",\"platform\",\"isAssetTracker\"]]],null,{\"statements\":[[4,\"link-to\",[\"product.location.index.config\"],[[\"class\"],[\"asset-tracker-page__config-button\"]],{\"statements\":[[0,\"      \"],[6,\"i\"],[9,\"class\",\"ion-gear-b\"],[7],[8],[0,\" Configure Fleet\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[6,\"div\"],[9,\"class\",\"asset-tracker-page__map\"],[7],[0,\"\\n    \"],[1,[25,\"asset-tracker-map-v2\",null,[[\"activeDevice\",\"devices\",\"visibleDevices\",\"fitBounds\",\"forceBounds\",\"deviceUpdates\",\"highlightedDevice\",\"onDeviceClick\",\"onFeatureMouseEnter\",\"onFeatureMouseExit\",\"deviceCount\",\"setVisiblePoints\"],[[20,[\"activeDevice\"]],[20,[\"mapableDevices\"]],[20,[\"visibleDevices\"]],[20,[\"fitBounds\"]],[20,[\"forceBounds\"]],[20,[\"deviceUpdates\"]],[20,[\"highlightedMapFeature\"]],[25,\"action\",[[19,0,[]],\"onDeviceClick\"],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"highlightedDeviceItem\"]]],null]],[[\"value\"],[\"id\"]]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"highlightedDeviceItem\"]]],null],\"\"],null],[20,[\"count\"]],[25,\"action\",[[19,0,[]],\"setVisiblePoints\"],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/location.hbs"
    }
  });

  _exports.default = _default;
});