define("pconsole/controllers/organization/ledger/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ledgerDefinition: Ember.computed.readOnly('model.ledgerDefinition'),
    tabs: [{
      id: 'general',
      title: 'General'
    }, {
      id: 'instances',
      title: 'Instances'
    }],
    hideCreate: Ember.computed.or('ledgerDefinition.isOwnerScope', 'ledgerDefinition.isDeviceToCloud'),
    actions: {}
  });

  _exports.default = _default;
});