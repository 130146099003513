define("pconsole/helpers/formatted-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formattedDate = formattedDate;
  _exports.default = void 0;

  function formattedDate([ISODate, dateFormat, timeZone]) {
    const moment = window.moment;
    const type = typeof dateFormat;
    const localDate = timeZone === 'utc' ? moment.utc(ISODate) : moment(ISODate);
    return type === 'string' ? localDate.format(dateFormat) : localDate.calendar();
  }

  var _default = Ember.Helper.helper(formattedDate);

  _exports.default = _default;
});