define("pconsole/templates/product/configure/completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dIcDkJ4+",
    "block": "{\"symbols\":[],\"statements\":[[2,\" TODO (carlos h): Not used, remove \"],[0,\"\\n\"],[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[6,\"i\"],[9,\"class\",\"ion-checkmark-circled\"],[7],[8],[0,\" You're all done\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"High fives all around! You have finished configuring your product. If you are close to starting a manufacturing run, please \"],[6,\"a\"],[9,\"href\",\"mailto:sales@particle.io\"],[7],[0,\"contact us\"],[8],[0,\" so we can work with you to do a final review of your product.\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"If you haven't done so already, your next step should be to import devices to your product. This will allow you to start flashing firmware to your fleet. Or, you can review your answers to the configuration questions.\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"btn-group\"],[7],[0,\"\\n    \"],[4,\"link-to\",[\"product.devices.importDevices\"],[[\"class\"],[\"ui button outline primary\"]],{\"statements\":[[0,\"Import Devices\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"link-to\",[\"product.configure\"],[[\"class\"],[\"ui button outline secondary\"]],{\"statements\":[[0,\"Review my answers\"]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/configure/completed.hbs"
    }
  });

  _exports.default = _default;
});