define("pconsole/adapters/service-agreement", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord(id, modelName, snapshot) {
      const prefix = this.urlPrefix();
      const ownerType = snapshot.attr('ownerType');

      if (ownerType === 'organization') {
        const org = snapshot.attr('ownerSlug');
        return "".concat(prefix, "/orgs/").concat(org, "/service_agreements/").concat(id);
      } else {
        return "".concat(prefix, "/user/service_agreements/").concat(id);
      }
    },

    urlForQuery(query) {
      const prefix = this.urlPrefix();

      if (query && query.org) {
        const {
          org
        } = query;
        delete query.org;
        return "".concat(prefix, "/orgs/").concat(org, "/service_agreements");
      } else {
        return "".concat(prefix, "/user/service_agreements");
      }
    }

  });

  _exports.default = _default;
});