define("pconsole/components/asset-tracker-v2-device-components", ["exports", "react", "react-mapbox-gl", "pconsole/components/asset-tracker-map/circles-layer", "pconsole/components/asset-tracker-map/line-pattern-layer"], function (_exports, _react, _reactMapboxGl, _circlesLayer, _linePatternLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AssetTrackerV2DeviceComponents = ({
    activeDevice,
    device,
    deviceCount = 1,
    highlightedDevice,
    onDeviceClick,
    onMouseEnter,
    onMouseLeave
  }) => {
    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_circlesLayer.default, {
      id: 'circles',
      devices: [device],
      activeDevice: activeDevice,
      sourceId: 'device',
      onDeviceClick: onDeviceClick,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave
    }), _react.default.createElement(_reactMapboxGl.Layer, {
      type: 'line',
      sourceId: 'device',
      paint: {
        'line-color': ['case', ['has', 'isHighlighted'], '#008dbf', ['has', 'color'], ['get', 'color'], '#008dbf'],
        'line-width': ['case', ['has', 'isHighlighted'], 10, 8]
      }
    }), _react.default.createElement(_linePatternLayer.default, {
      sourceId: 'device'
    }), _react.default.createElement(_reactMapboxGl.Layer, {
      type: 'symbol',
      sourceId: 'foldedPoints',
      images: [['folded-count', foldedCountImage], ['folded-count-inverted', foldedCountInvertedImage]],
      layout: {
        'icon-image': ['case', ['has', 'isHighlighted'], 'folded-count-inverted', 'folded-count'],
        'icon-size': ['step', ['get', 'count'], 0.5, 100, 0.75, 1000, 1],
        'text-field': '{count}'
      },
      paint: {
        'text-color': ['case', ['has', 'isHighlighted'], '#ffffff', '#00334f']
      }
    }));
  };

  const foldedCountImage = new Image(64, 64);
  foldedCountImage.src = 'data:image/svg+xml,%3Csvg%20width%3D%2264%22%20height%3D%2264%22%20version%3D%221.1%22%20viewBox%3D%220%200%2016.933%2016.933%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%228.4667%22%20cy%3D%228.4667%22%20r%3D%228.2101%22%20fill%3D%22%23ffffff%22%20stroke%3D%22%2300334f%22%20stroke-width%3D%22.51313%22%20style%3D%22paint-order%3Anormal%22%2F%3E%3C%2Fsvg%3E';
  const foldedCountInvertedImage = new Image(64, 64);
  foldedCountInvertedImage.src = 'data:image/svg+xml,%3Csvg%20width%3D%2264%22%20height%3D%2264%22%20version%3D%221.1%22%20viewBox%3D%220%200%2016.933%2016.933%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%228.4667%22%20cy%3D%228.4667%22%20r%3D%228.2101%22%20fill%3D%22%23008dbf%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%22.51313%22%20style%3D%22paint-order%3Anormal%22%2F%3E%3C%2Fsvg%3E';
  var _default = AssetTrackerV2DeviceComponents;
  _exports.default = _default;
});