define("pconsole/helpers/cents-to-dollars-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.centsToDollarsRounded = centsToDollarsRounded;
  _exports.default = void 0;

  function centsToDollarsRounded([value]) {
    if (typeof value === 'number') {
      const dollars = value ? Math.round(value / 100) : '0';
      return '$' + dollars;
    } else {
      return value;
    }
  }

  var _default = Ember.Helper.helper(centsToDollarsRounded);

  _exports.default = _default;
});