define("pconsole/components/analytics-track", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    segment: Ember.inject.service(),
    trackEnabled: true,

    mouseEnter() {
      if (this.get('trackEnabled') && this.get('on') === 'mouseEnter') {
        this.get('segment').trackEvent(this.get('trackName'), {
          tool: {
            name: 'console'
          }
        });
      }
    }

  });

  _exports.default = _default;
});