define("pconsole/mixins/routes/logic-templates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LogicTemplatesShowRoute = void 0;
  const LogicTemplatesShowRoute = {
    templateName: 'organization.logic.templates.show',
    controllerName: 'organization.logic.templates.show',
    breadCrumb: {
      title: ''
    },

    async model({
      template_slug
    }) {
      const template = await this.store.find('logic-template', template_slug);
      return template;
    },

    afterModel(model) {
      const name = Ember.get(model, 'name');
      Ember.set(this.breadCrumb, 'title', name);
    }

  };
  _exports.LogicTemplatesShowRoute = LogicTemplatesShowRoute;
});