define("pconsole/routes/organization/logic-functions/create", ["exports", "pconsole/mixins/routes/logic-functions/create", "pconsole/mixins/block-transition-route"], function (_exports, _create, _blockTransitionRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_create.LogicFunctionsCreateRoute, _blockTransitionRoute.BlockTransitionRoute, {
    owner: 'organization',
    dirtyKey: 'currentModel.logicFunction.hasDirtyAttributes'
  });

  _exports.default = _default;
});