define("pconsole/adapters/product-configuration", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(type) {
      let url = [];
      const host = this.get('host');
      const prefix = this.urlPrefix();

      if (type) {
        url.push(this.pathForType(type));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      }

      return url;
    },

    pathForType() {
      return 'config';
    },

    namespace: Ember.computed('routeData.product', function () {
      return 'v1/products/' + this.get('routeData.product');
    })
  });

  _exports.default = _default;
});