define("pconsole/serializers/new-device", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,

    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      const payload = {};

      if (json.import_method === 'many') {
        payload.file = json.file;
      } else {
        payload.id = json.identifier;
      }

      Ember.merge(hash, payload);
    },

    normalizeSaveResponse(store, typeClass, payload, id, requestType) {
      payload.nonmember_device_ids = payload.nonmemberDeviceIds;
      payload.invalid_device_ids = payload.invalidDeviceIds;

      if (id) {
        payload.id = id;
      } else {
        payload.id = 1;
      }

      delete payload.nonmemberDeviceIds;
      delete payload.invalidDeviceIds;
      const normalized = {
        'new_device': payload
      };
      return this.normalizeSingleResponse(store, typeClass, normalized, id, requestType);
    }

  });

  _exports.default = _default;
});