define("pconsole/models/product", ["exports", "ember-data", "pconsole/mixins/device-type", "pconsole/utils/platforms"], function (_exports, _emberData, _deviceType, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Product = _emberData.default.Model.extend(_deviceType.default, {
    description: _emberData.default.attr('string'),
    deviceCount: _emberData.default.attr('number'),
    enterprise: _emberData.default.attr('boolean'),
    groupsEnableOverride: _emberData.default.attr('boolean'),
    hardwareVersion: _emberData.default.attr('string'),
    latestFirmwareVersion: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    org: _emberData.default.attr('string'),
    quarantine: _emberData.default.attr('boolean'),
    settings: _emberData.default.attr(),
    slug: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    user: _emberData.default.attr('string'),
    deviceProtection: _emberData.default.attr(),
    devices: _emberData.default.hasMany('device', {
      async: false
    }),
    groups: _emberData.default.hasMany('group'),
    organization: _emberData.default.belongsTo('organization', {
      async: true
    }),
    platform: _emberData.default.belongsTo('platform'),
    subscription: _emberData.default.belongsTo('subscription', {
      async: true
    }),
    groupsDisabled: Ember.computed.not('groupsEnabled'),
    session: Ember.inject.service(),
    connectivityType: Ember.computed('platform', function () {
      const platform = this.belongsTo('platform').id();
      return (0, _platforms.connectivityTypeForPlatform)(platform);
    }),
    connectivityTypeName: Ember.computed('platform', function () {
      const platform = this.belongsTo('platform').id();
      return (0, _platforms.connectivityTypeNameForPlatform)(platform);
    }),
    groupsEnabled: Ember.computed('subscription.plan.groupLimit', function () {
      return this.get('groupsEnableOverride') || !(this.get('subscription.plan.groupLimit') === 0);
    }),
    isCellular: Ember.computed('platform.id', function () {
      const id = this.get('platform.id');
      return (0, _platforms.isCellularPlatform)(id);
    }),
    isEnterprise: Ember.computed('subscription.plan', 'enterprise', function () {
      return _emberData.default.PromiseObject.create({
        promise: (async () => {
          let enterprisePlan = false;
          const subscription = await this.get('subscription');

          if (subscription) {
            const plan = await subscription.get('plan');

            if (plan) {
              enterprisePlan = plan.get('slug') === 'PlatformEnterprisePlan';
            }
          }

          return this.get('enterprise') || enterprisePlan;
        })()
      });
    }),
    owner: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: (async () => {
          const subscription = await this.get('subscription');

          if (subscription) {
            return subscription.get('user');
          }
        })()
      });
    })
  });

  var _default = Product;
  _exports.default = _default;
});