define("pconsole/adapters/team", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const teamAdapter = _application.default.extend({
    namespace: Ember.computed('routeData.product', 'routeData.org', function () {
      const product = this.get('routeData.product');
      const org = this.get('routeData.org');

      if (product) {
        return 'v1/products/' + this.get('routeData.product');
      } else if (org) {
        return 'v1/orgs/' + this.get('routeData.org');
      }
    }),

    pathForType() {
      return 'team';
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      // Override the default Ember data behavior to just send an object with a role, like this:
      // { role: 000000000000000004 }
      const data = {
        role: snapshot.record.get('role.id')
      };
      const url = this.buildURL(snapshot.modelName, snapshot.record.get('id'), snapshot);
      return this.ajax(url, 'PUT', {
        data: data
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      const teamMember = snapshot.record;
      const data = {
        username: teamMember.get('username'),
        role: teamMember.get('role.id')
      };
      const url = this.buildURL(snapshot.modelName, null, snapshot);
      return this.ajax(url, 'POST', {
        data: data
      });
    },

    // This is used to fetch the inhereted organization team
    // when on the product team page.
    urlForQuery(query, modelName) {
      const orgId = query.orgId;

      if (orgId) {
        return "".concat(this.get('host'), "/v1/orgs/").concat(orgId, "/team");
      } else {
        return this._super(query, modelName);
      }
    },

    // Leave off query parameter when making the request
    query(store, type, query) {
      const url = this.buildURL(type.modelName, null, null, 'query', query);
      return this.ajax(url, 'GET');
    }

  });

  var _default = teamAdapter;
  _exports.default = _default;
});