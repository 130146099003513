define("pconsole/routes/user/clients/delete", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    templateName: 'product.clients.delete',
    controllerName: 'product.clients.delete',

    model(params) {
      return this.store.find('client', params.client_id);
    }

  });

  _exports.default = _default;
});