define("pconsole/routes/user/sims/release", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return Ember.RSVP.hash({
        sim: this.store.find('sim', params.iccid),
        sims: this.store.peekAll('sim')
      });
    }

  });

  _exports.default = _default;
});