define("pconsole/mixins/webhook-default-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    defaultFields: Ember.computed('webhook.noDefaults', 'parentEntity', function () {
      const noDefaults = this.get('webhook.noDefaults');
      const hasProduct = this.get('parentEntity') !== 'user'; // Org and Product entities are "product"

      if (noDefaults) {
        return {};
      }

      const fields = {
        event: '{{{PARTICLE_EVENT_NAME}}}',
        data: '{{{PARTICLE_EVENT_VALUE}}}',
        coreid: '{{{PARTICLE_DEVICE_ID}}}',
        published_at: '{{{PARTICLE_PUBLISHED_AT}}}'
      };

      if (hasProduct) {
        Object.assign(fields, {
          userid: '{{{PRODUCT_USER_ID}}}',
          fw_version: '{{{PRODUCT_VERSION}}}',
          public: '{{{PARTICLE_EVENT_PUBLIC}}}'
        });
      }

      return fields;
    }),
    queryParametersDefaultFields: Ember.computed('defaultFields', 'webhook.isDataFormatQueryParameters', function () {
      if (!this.get('webhook.isDataFormatQueryParameters')) {
        return {};
      }

      return this.get('defaultFields');
    }),
    jsonDefaultFields: Ember.computed('defaultFields', function () {
      return JSON.stringify(this.get('defaultFields'), true, 2);
    }),
    headersDefaultFields: Ember.computed('webhook.dataFormat', function () {
      const dataFormat = this.get('webhook.dataFormat');

      if (dataFormat === 'JSON') {
        return {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        };
      } else if (dataFormat === 'Web Form') {
        return {
          'Content-Type': 'application/x-www-form-urlencoded'
        };
      }
    })
  });

  _exports.default = _default;
});