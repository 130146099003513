define("pconsole/mixins/azure-iot-default-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    defaultFields: Ember.computed('parentEntity', function () {
      const hasProduct = this.get('parentEntity') !== 'user'; // Org and Product are considered product

      const fields = {
        event: '{{{PARTICLE_EVENT_NAME}}}',
        data: '{{{PARTICLE_EVENT_VALUE}}}',
        device_id: '{{{PARTICLE_DEVICE_ID}}}',
        published_at: '{{{PARTICLE_PUBLISHED_AT}}}'
      };

      if (hasProduct) {
        Object.assign(fields, {
          fw_version: '{{{PRODUCT_VERSION}}}'
        });
      }

      return fields;
    })
  });

  _exports.default = _default;
});