define("pconsole/controllers/product/devices/create-group", ["exports", "pconsole/mixins/create-or-edit-group"], function (_exports, _createOrEditGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_createOrEditGroup.default, {
    group: Ember.computed.alias('model.group')
  });

  _exports.default = _default;
});