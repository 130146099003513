define("pconsole/helpers/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trim = trim;
  _exports.default = void 0;

  function trim([str]) {
    return str && str.trim();
  }

  var _default = Ember.Helper.helper(trim);

  _exports.default = _default;
});