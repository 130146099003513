define("pconsole/routes/product/sims/release", ["exports", "pconsole/mixins/sim/route", "pconsole/mixins/modal-route"], function (_exports, _route, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _route.default, {
    // This displays the loading spinner
    // while the decently long data usage
    // query is sent to the server
    beforeModel() {
      Ember.$('.loading').show();
    },

    afterModel() {
      Ember.$('.loading').hide();
    },

    model(params) {
      return Ember.RSVP.hash({
        sim: this.store.find('sim', params.iccid),
        dataUsage: this.store.query('simUsageDatapoint', {
          iccid: params.iccid
        }),
        sims: this.modelFor('product.sims').sims
      });
    }

  });

  _exports.default = _default;
});