define("pconsole/templates/organization/enterprise-resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BmoPLISg",
    "block": "{\"symbols\":[\"section\",\"resource\"],\"statements\":[[6,\"div\"],[9,\"id\",\"enterprise-resources\"],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[0,\"Enterprise Resources\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"sections\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[7],[0,\"\\n      \"],[6,\"i\"],[10,\"class\",[26,[\"im-\",[19,1,[\"icon\"]]]]],[7],[8],[0,\"\\n      \"],[1,[19,1,[\"title\"]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"resources\"],[7],[0,\"\\n\"],[4,\"each\",[[25,\"get\",[[20,[\"filteredSections\"]],[19,1,[\"id\"]]],null]],null,{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"class\",[26,[\"resource \",[25,\"if\",[[19,2,[\"subtext\"]],\"with-subtext\",\"\"],null]]]],[9,\"target\",\"_blank\"],[10,\"href\",[19,2,[\"link\"]],null],[7],[0,\"\\n          \"],[1,[19,2,[\"text\"]],false],[0,\"\\n\"],[4,\"if\",[[19,2,[\"subtext\"]]],null,{\"statements\":[[0,\"            \"],[6,\"span\"],[9,\"class\",\"subtext\"],[7],[1,[19,2,[\"subtext\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[6,\"div\"],[9,\"class\",\"community\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[0,\"Particle Community\"],[8],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"Join the discourse about Particle devices, the Particle cloud, and all things related to connected devices in the IoT era.\"],[8],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"im-community\"],[7],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"href\",\"https://community.particle.io/\"],[9,\"target\",\"_blank\"],[9,\"class\",\"ui button outline primary\"],[7],[0,\"Connect with the community\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/enterprise-resources.hbs"
    }
  });

  _exports.default = _default;
});