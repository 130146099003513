define("pconsole/components/expanded-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    expandableRow: Ember.inject.service(),
    tagName: 'tr',
    classNames: ['expanded'],
    shouldRender: false,
    listen: function () {
      this.get('expandableRow').on('expand', this, 'determineExpandRow');
    }.on('init'),

    determineExpandRow(expandEvent) {
      const rowIdToExpand = expandEvent.rowId;
      const thisRowId = this.get('dataId');
      this.set('shouldRender', rowIdToExpand === thisRowId);
    },

    // remember to remove what you bind upon component destruction
    cleanup: function () {
      this.get('expandableRow').off('expand', this, 'determineExpandRow');
    }.on('willDestroyElement')
  });

  _exports.default = _default;
});