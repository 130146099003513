define("pconsole/mixins/routes/ledger/show/delete-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DeleteLedgerInstanceRoute = void 0;
  const DeleteLedgerInstanceRoute = Ember.Mixin.create({
    templateName: 'organization/ledger/show/delete-instance',
    controllerName: 'organization/ledger/show/delete-instance',
    breadCrumb: null,

    async model({
      scope_value
    }) {
      const ledgerInstance = this.store.peekRecord('ledger-instance', scope_value) || (await this.store.findRecord('ledger-instance', scope_value));
      return {
        ledgerInstance
      };
    }

  });
  _exports.DeleteLedgerInstanceRoute = DeleteLedgerInstanceRoute;
});