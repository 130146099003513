define("pconsole/templates/components/write-only-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7y3nFnEO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"input-wrapper \",[25,\"if\",[[25,\"not\",[[20,[\"isEditing\"]]],null],\"disabled\"],null]]]],[7],[0,\"\\n  \"],[11,1],[0,\" \\n\"],[8],[0,\"\\n\"],[6,\"button\"],[9,\"class\",\"ui button primary outline\"],[3,\"action\",[[19,0,[]],\"toggleIsEditing\"]],[7],[0,\"\\n  \"],[6,\"i\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"isEditing\"]],\"ion-close\",\"ion-edit\"],null]]]],[7],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/write-only-input.hbs"
    }
  });

  _exports.default = _default;
});