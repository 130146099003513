define("pconsole/controllers/organization/insights/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dashboards: Ember.computed.alias('model.dashboards'),
    loading: true,
    error: false,
    actions: {
      openDashboard(dashboard) {
        this.transitionToRoute('organization.insights.report', Ember.get(dashboard, 'id'));
      }

    }
  });

  _exports.default = _default;
});