define("pconsole/controllers/product/devices/remove-product-device", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, {
    isDeleting: false,
    application: Ember.inject.controller(),
    notify: Ember.inject.service(),
    actions: {
      closeModal() {
        if (!this.get('isDeleting')) {
          this.transitionToRoute(this.get('previousRoute'));
        }
      },

      deleteDevice() {
        const device = this.get('model');
        Ember.$('.loading').show();
        this.set('isDeleting', true);
        device.destroyRecord().catch(response => {
          const error = response.errors[0];
          const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
          this.get('notify').alert(errorMsg);
        }).then(() => {
          // TODO: Destroying a product device record is essentially a soft delete and leaves it in a bad state.
          // When the record is read on /devices, Ember Data throws `Error: Attempted to handle event "pushedData"
          // on <device record> while in state root.deleted.saved.` Unloading the record is a workaround until user
          // devices and product devices are disambiguated. Better handling for this is added in
          // https://github.com/emberjs/data/pull/5408.
          device.unloadRecord();
        }).then(() => {
          const currentDeveloperServiceAgreement = Ember.get(this, 'application.currentDeveloperServiceAgreement');

          if (currentDeveloperServiceAgreement) {
            return currentDeveloperServiceAgreement.reload();
          }
        }).then(() => {
          this.transitionToRoute('product.devices').then(() => {
            this.get('notify').success('The device has been successfully removed');
          });
        }).finally(() => {
          this.set('isDeleting', false);
          Ember.$('.loading').hide();
        });
      }

    }
  });

  _exports.default = _default;
});