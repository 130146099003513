define("pconsole/components/key-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    help: '',
    includeHeader: true,
    onChange: null,
    keyValueArray: null,
    object: null,
    property: null,
    staticValues: {},

    init() {
      this._super();

      let keyValueArray = [];
      const object = Ember.get(this, 'object');
      const property = Ember.get(this, 'property');
      const propertyValue = object && property && Ember.get(object, property);

      if (propertyValue) {
        keyValueArray = [];
        Object.keys(propertyValue).forEach(key => {
          keyValueArray.push({
            key: key,
            val: propertyValue[key]
          });
        });
      }

      if (keyValueArray.length === 0) {
        keyValueArray.push({
          key: '',
          val: ''
        });
      }

      this.set('keyValueArray', keyValueArray);
    },

    keyValueObserver: Ember.observer('keyValueArray.@each.key', 'keyValueArray.@each.val', function () {
      const callback = this.get('onChange');
      const prop = this.get('property');
      const keyValueArray = this.get('keyValueArray');
      const object = this.get('object');
      const keyValObj = {};
      keyValueArray.forEach(pair => {
        keyValObj[pair.key] = pair.val;
      });

      if (JSON.stringify(keyValObj) === '{"":""}') {
        object.set(prop, undefined);
      } else if (typeof callback === 'function') {
        callback(keyValObj);
      } else {
        object.set(prop, keyValObj);
      }
    }),
    actions: {
      addRow() {
        const keyValueArray = Ember.get(this, 'keyValueArray');
        keyValueArray.pushObject({
          key: '',
          val: ''
        });
      },

      removeRow(pair) {
        const keyValueArray = Ember.get(this, 'keyValueArray');

        if (keyValueArray.length !== 1) {
          keyValueArray.removeObject(pair);
        }
      }

    }
  });

  _exports.default = _default;
});