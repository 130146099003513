define("pconsole/helpers/file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fileSize = fileSize;
  _exports.default = void 0;

  function fileSize(params) {
    const bytes = +params[0];

    if (bytes < 1024) {
      return bytes + ' bytes';
    }

    const kb = bytes / 1024.0;

    if (kb < 1024) {
      return kb.toFixed(1) + ' KB';
    }

    const mb = kb / 1024.0;

    if (mb < 1024) {
      return mb.toFixed(1) + ' MB';
    }

    const gb = mb / 1024.0;
    return gb.toFixed(1) + ' GB';
  }

  var _default = Ember.Helper.helper(fileSize);

  _exports.default = _default;
});