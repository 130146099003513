define("pconsole/mixins/routes/ledger/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LedgerShowRoute = void 0;
  const LedgerShowRoute = {
    templateName: 'organization.ledger.show',
    controllerName: 'organization.ledger.show',
    breadCrumb: {
      title: 'View detail',
      linkable: false
    },

    async model({
      ledger_name
    }) {
      await this.store.findAll('ledger-instance');
      const ledgerDefinition = await this.store.find('ledger-definition', ledger_name);
      return {
        ledgerDefinition
      };
    },

    deactivate() {
      this.store.peekAll('ledgerInstance').forEach(li => li.unloadRecord());
    }

  };
  _exports.LedgerShowRoute = LedgerShowRoute;
});