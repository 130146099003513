define("pconsole/serializers/subscription", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    session: Ember.inject.service(),

    normalize(typeClass, hash) {
      if (hash.plan) {
        hash.plan_id = hash.plan.id;
        delete hash.plan;
      }

      return this._super(...arguments);
    },

    serializeAttribute(snapshot, json, key, attributes) {
      delete json.card_number;
      delete json.card_cvc;
      delete json.card_exp_month;
      delete json.card_exp_year;
      delete json.card_last4;

      this._super(snapshot, json, key, attributes);
    },

    normalizeSingleResponse(store, primaryModelClass, payload) {
      const username = Ember.get(this, 'session.data.authenticated.username');
      payload.subscription.user_id = username;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});