define("pconsole/lib/hooks", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useStateCallback = useStateCallback;
  _exports.useInput = _exports.useEventListener = void 0;

  /**
   * Custom hook to create an event handler
   *
   * @param {string} eventName Event name
   * @param {function} handler Event handler function
   * @param {[HTMLElement]} element Element
   * @param {object} options Handler options
   * @see https://usehooks.com/useEventListener/
   * @license https://github.com/donavon/use-event-listener/blob/develop/LICENSE MIT
   */
  const useEventListener = (eventName, handler, element = window, options = {}) => {
    const savedHandler = (0, _react.useRef)();
    const {
      capture,
      passive,
      once
    } = options;
    (0, _react.useEffect)(() => {
      savedHandler.current = handler;
    }, [handler]);
    (0, _react.useEffect)(() => {
      const isSupported = element && element.addEventListener;

      if (!isSupported) {
        return;
      }

      const eventListener = event => savedHandler.current(event);

      const opts = {
        capture,
        passive,
        once
      };
      element.addEventListener(eventName, eventListener, opts);
      return () => {
        element.removeEventListener(eventName, eventListener, opts);
      };
    }, [eventName, element, capture, passive, once]);
  };
  /**
   * Custom hook to manage input state
   *
   * @param  {*} initialValue Initial value
   * @return {object} Hash of input value, reset helper, and props to spread onto the input
   * @see https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
   */


  _exports.useEventListener = useEventListener;

  const useInput = initialValue => {
    const [value, setValue] = (0, _react.useState)(initialValue);
    return {
      value,
      setValue,
      reset: () => setValue(''),
      bind: {
        value,
        onChange: ({
          target,
          value
        }) => setValue(typeof value === 'undefined' ? target.value : value)
      }
    };
  };
  /**
   * Like useState, but takes a callback to be called when state changes
   * @see https://stackoverflow.com/a/61842546/727672
   * @return {array} An array with state in the first element and a function that updates the state in the second
   */


  _exports.useInput = useInput;

  function useStateCallback(initialState) {
    const [state, setState] = (0, _react.useState)(initialState);
    const cbRef = (0, _react.useRef)(null);

    const setStateCallback = (state, cb) => {
      cbRef.current = cb;
      setState(state);
    };

    (0, _react.useEffect)(() => {
      // cbRef.current is `null` on initial render; only execute on state update
      if (cbRef.current) {
        cbRef.current(state); // Reset callback after execution

        cbRef.current = null;
      }
    }, [state]);
    return [state, setStateCallback];
  }
});