define("pconsole/templates/product/team/remove-team-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sg87TeYm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Remove Team Member\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you would like to remove \"],[6,\"strong\"],[7],[1,[20,[\"teamMember\",\"username\"]],false],[8],[0,\" from your team? They will lose access to the \"],[1,[20,[\"product\",\"name\"]],false],[0,\" Product.\"],[8],[0,\"\\n  \"],[6,\"a\"],[9,\"class\",\"remove-user-btn ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deleteUser\"]],[7],[0,\"Yes, remove team member\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/team/remove-team-member.hbs"
    }
  });

  _exports.default = _default;
});