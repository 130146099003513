define("pconsole/templates/product/devices/edit-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uo2RFdoS",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[10,\"class\",[26,[\"title modal-title \",[25,\"if\",[[20,[\"editableGroup\"]],\"\",\"no-border\"],null]]]],[7],[0,\"Edit Group\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"editableGroup\"]]],null,{\"statements\":[[0,\"    \"],[12,\"partials/create-or-edit-group\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"centered border margin-bottom-30\"],[7],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"options\",\"placeholder\",\"onchange\",\"class\",\"renderInPlace\",\"closeOnSelect\",\"searchField\"],[[20,[\"groups\"]],\"Which group do you want to edit?\",[25,\"action\",[[19,0,[]],\"chooseGroup\"],null],\"power-select full-border batch-edit\",true,false,\"name\"]],{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"group-info\"],[7],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"group-color\"],[10,\"style\",[19,1,[\"colorSafe\"]],null],[7],[8],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"group-name\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/edit-group.hbs"
    }
  });

  _exports.default = _default;
});