define("pconsole/serializers/ledger-definition", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (hash) {
        hash.id = hash.name;
      }

      return this._super(typeClass, hash);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'ledgers' || key === 'ledger') {
        return 'ledger-definition';
      }

      return key;
    },

    payloadKeyFromModelName() {
      return 'ledger';
    }

  });

  _exports.default = _default;
});