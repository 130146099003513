define("pconsole/components/validated-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validated field
   *
   * @example
   * ```hbs
   * {{#validated-field user "firstName" as |field|}}
   *   <input type="text" />
   *   {{#field.help-text}}
   *     Given name
   *   {{/field.help-text}}
   * {{/validated-field}}
   * ```
   */
  var _default = Ember.Component.extend({
    classNameBindings: ['showErrorClass:has-error'],
    classNames: ['ui', 'field'],
    didFocus: false,
    field: '',
    model: null,
    validation: null,
    hasContent: Ember.computed('model', 'field', function () {
      const field = Ember.get(this, 'field');
      const model = Ember.get(this, 'model');
      return Ember.isPresent(Ember.get(model, field));
    }),
    hasError: Ember.computed.and('showValidations', 'validation.isInvalid'),
    notValidating: Ember.computed.not('validation.isValidating').readOnly(),
    showErrorClass: Ember.computed.and('notValidating', 'hasError'),
    showValidations: Ember.computed.or('hasContent', 'didFocus'),

    didReceiveAttrs() {
      this._super(...arguments);

      const field = Ember.get(this, 'field');
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly("model.validations.attrs.".concat(field))); // Signal dependent props when `validation` is redefined

      this.notifyPropertyChange('validation');
    },

    focusOut() {
      this._super(...arguments);

      Ember.set(this, 'didFocus', true);
    }

  }).reopenClass({
    positionalParams: ['model', 'field']
  });

  _exports.default = _default;
});