define("pconsole/templates/quarantined-devices-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uFWlDRa6",
    "block": "{\"symbols\":[],\"statements\":[[6,\"ul\"],[7],[0,\"\\n  \"],[6,\"li\"],[7],[0,\"Claiming by a customer of this organization\"],[8],[0,\"\\n  \"],[6,\"li\"],[7],[0,\"Receiving over-the-air (OTA) firmware updates from this product\"],[8],[0,\"\\n  \"],[6,\"li\"],[7],[0,\"Publishing events that will appear in this product's event stream\"],[8],[0,\"\\n  \"],[6,\"li\"],[7],[0,\"Triggering product webhooks\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/quarantined-devices-permissions.hbs"
    }
  });

  _exports.default = _default;
});