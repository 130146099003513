define("pconsole/mixins/routes/ledger/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LedgerCategoriesRoute = void 0;
  const LedgerCategoriesRoute = {
    templateName: 'organization.ledger.categories',
    controllerName: 'organization.ledger.categories',
    breadCrumb: {
      title: 'Create new Ledger'
    },

    async model() {
      const categories = await this.store.findAll('ledger-category', {
        reload: true
      });
      return {
        categories
      };
    }

  };
  _exports.LedgerCategoriesRoute = LedgerCategoriesRoute;
});