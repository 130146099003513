define("pconsole/models/platform", ["exports", "ember-data", "pconsole/utils/platforms"], function (_exports, _emberData, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Platform = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    productEligible: _emberData.default.attr('boolean'),
    orderInProductDeviceTypeList: _emberData.default.attr('number'),
    isAssetTracker: Ember.computed('id', function () {
      const id = this.get('id');
      return (0, _platforms.isAssetTrackerPlatform)(id);
    })
  });

  const FIXTURES = (0, _platforms.getPlatformFixtures)();
  Platform.reopenClass({
    FIXTURES
  });
  var _default = Platform;
  _exports.default = _default;
});