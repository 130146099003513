define("pconsole/components/abbreviated-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    classNames: ['abbreviated-id'],
    expandId: false,
    resizeHandler: function () {
      this.get('resizeService').on('didResize', () => {
        const isExpanded = this.get('expandId'); // The size of the $xlarge screen

        if (isExpanded && window.innerWidth < 1200) {
          this.send('toggleExpand');
        }
      });
    }.on('init'),
    actions: {
      afterCopy() {
        this.get('notify').info("Copied the full ".concat(this.get('idName'), " to clipboard successfully!"));
      },

      toggleExpand() {
        this.toggleProperty('expandId');
      }

    }
  });

  _exports.default = _default;
});