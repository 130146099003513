define("pconsole/components/asset-tracker-device", ["exports", "react", "prop-types", "styled-components", "formatcoords", "pconsole/utils/date", "pconsole/utils/array", "pconsole/components/device-groups-tag-list"], function (_exports, _react, _propTypes, _styledComponents, _formatcoords, _date, _array, _deviceGroupsTagList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Shows asset tracker device details.
   */
  const AssetTrackerDevice = ({
    device,
    devicePageUrl,
    onExportClick = () => {}
  }) => {
    const isHistoric = device.geometry.type === 'LineString';
    const coordinates = isHistoric ? device.geometry.coordinates.slice(-1)[0] : device.geometry.coordinates;
    const lastKnownProperties = device.properties && device.properties.slice(-1)[0];
    const [lng, lat, altitude] = coordinates;

    function renderTime() {
      if (device.timestamps.length > 1) {
        const startDate = new Date(device.timestamps[0]);
        const endDate = new Date(device.timestamps.slice(-1));
        return _react.default.createElement(_react.Fragment, null, _react.default.createElement('dt', null, 'Start'), _react.default.createElement('dd', null, (0, _date.formatDate)(startDate)), _react.default.createElement('dt', null, 'End'), _react.default.createElement('dd', null, (0, _date.formatDate)(endDate)));
      } else {
        const lastUpdateDate = new Date(device.timestamps[0]);
        return _react.default.createElement(_react.Fragment, null, _react.default.createElement('dt', null, 'Last update'), _react.default.createElement('dd', null, (0, _date.formatDate)(lastUpdateDate)));
      }
    }

    function renderProperty(key, label, format = v => v, defaultValue) {
      if (lastKnownProperties && lastKnownProperties.hasOwnProperty(key)) {
        return _react.default.createElement(_react.Fragment, null, _react.default.createElement('dt', null, label), _react.default.createElement('dd', null, format(lastKnownProperties[key])));
      }

      if (defaultValue) {
        return _react.default.createElement(_react.Fragment, null, _react.default.createElement('dt', null, label), _react.default.createElement('dd', null, defaultValue));
      }
    }

    function renderCustomData() {
      if (isHistoric && device.properties) {
        // Create a list of property key value pairs by timestamp, i.e.
        // [ timestamp, [[key, [value]] ]
        const properties = device.properties.map((p, i) => [device.timestamps[i], Object.entries(p).filter(([key]) => !particleProperties.includes(key))]).reverse();

        if (!properties.some(p => p[1].length > 0)) {
          return _react.default.createElement('p', null, 'None');
        }

        return _react.default.createElement(PropertiesTable, null, _react.default.createElement('thead', null, _react.default.createElement('tr', null, _react.default.createElement('th', null, 'Timestamp'), _react.default.createElement('th', null, 'Name'), _react.default.createElement('th', null, 'Value'))), _react.default.createElement('tbody', null, properties.map(([timestamp, propertyEntries], i) => _react.default.createElement('tr', {
          key: "".concat(timestamp).concat(i)
        }, _react.default.createElement('td', null, (0, _date.formatDate)(new Date(timestamp))), _react.default.createElement('td', null, (0, _array.intersperse)(propertyEntries.map(([key]) => key), j => _react.default.createElement('br', {
          key: j
        }))), _react.default.createElement('td', null, (0, _array.intersperse)(propertyEntries.map(([, value]) => value.toString()), k => _react.default.createElement('br', {
          key: k
        })))))));
      } else {
        const properties = lastKnownProperties && Object.keys(lastKnownProperties).filter(key => !particleProperties.includes(key));

        if (!properties || !properties.length) {
          return _react.default.createElement('p', null, 'None');
        }

        return _react.default.createElement(PropertiesList, null, properties.map(key => renderProperty(key, key, v => v.toString())));
      }
    }

    return _react.default.createElement(Device, {
      historic: isHistoric
    }, _react.default.createElement(Content, null, _react.default.createElement(Heading, null, device.deviceName), _react.default.createElement(PropertiesList, null, _react.default.createElement('dt', null, 'Device ID'), _react.default.createElement('dd', null, device.id), renderTime()), _react.default.createElement('hr', null), _react.default.createElement(SubHeading, null, 'Particle Data'), _react.default.createElement(PropertiesList, null, renderProperty('cell', 'Cellular signal', v => "".concat(v, "%")), renderProperty('batt', 'Battery charge', v => "".concat(v, "%")), _react.default.createElement('dt', null, 'Groups'), _react.default.createElement('dd', null, _react.default.createElement(_deviceGroupsTagList.default, {
      right: true,
      groups: device.groups
    })), _react.default.createElement('dt', null, 'GPS coordinates'), _react.default.createElement('dd', null, (0, _formatcoords.default)(lng, lat, true).format('FFf', {
      decimalPlaces: 2,
      latLonSeparator: '\n'
    })), renderProperty('h_acc', 'GPS accuracy', v => "".concat(v, " meters"), 'N/A'), _react.default.createElement('dt', null, 'Altitude'), _react.default.createElement('dd', null, altitude ? "".concat(altitude, " meters") : "N/A"), renderProperty('hd', 'Heading', v => "".concat(v, "\xB0")), renderProperty('speed', 'Speed', v => "".concat(v, " mph"))), _react.default.createElement('hr', null), _react.default.createElement(SubHeading, null, 'Custom Data'), renderCustomData()), _react.default.createElement(Extra, null, isHistoric && _react.default.createElement(ExportButton, {
      type: 'button',
      onClick: e => onExportClick(e)
    }, 'Export Historical Location Data'), _react.default.createElement(ViewDevicePageButton, {
      href: devicePageUrl,
      type: 'button',
      className: 'ui button outline primary'
    }, 'View device page')));
  };

  const particleProperties = ['batt', 'cell', 'gps_src', 'h_acc', 'hd', 'lck', 'speed', 'v_acc'];

  const Device = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-device__Device",
    componentId: "dfiat5-0"
  })(["display:grid;grid-template-rows:", ";height:calc(100vh - 13.1rem);max-height:calc(100vh - 13.1rem);hr{border:none;border-top:1px solid var(--primary-border-color);margin-bottom:2.4rem;margin-top:2.4rem;}"], props => props.historic ? '1fr 12.8rem' : '1fr 8.8rem');

  const Content = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-device__Content",
    componentId: "dfiat5-1"
  })(["overflow:auto;> *{margin-left:1.6rem;margin-right:1.6rem;}"]);

  const Heading = _styledComponents.default.h2.withConfig({
    displayName: "asset-tracker-device__Heading",
    componentId: "dfiat5-2"
  })(["font-size:2.4rem;font-weight:700;margin-bottom:1.6rem;margin-top:1.6rem;"]);

  const SubHeading = _styledComponents.default.h3.withConfig({
    displayName: "asset-tracker-device__SubHeading",
    componentId: "dfiat5-3"
  })(["font-size:1.6rem;font-weight:700;margin-bottom:1.6rem;margin-top:1.6rem;"]);

  const PropertiesList = _styledComponents.default.dl.withConfig({
    displayName: "asset-tracker-device__PropertiesList",
    componentId: "dfiat5-4"
  })(["display:grid;grid-template-columns:min-content 1fr;grid-gap:1.2rem 0.8rem;margin-bottom:1.6rem;margin-top:0;dt{color:var(--tertiary-text-color);}dd{margin:0;text-align:right;white-space:pre-wrap;}"]);

  const PropertiesTable = _styledComponents.default.table.withConfig({
    displayName: "asset-tracker-device__PropertiesTable",
    componentId: "dfiat5-5"
  })(["width:calc(100% - 3.2rem);thead{background-color:transparent;border:none;margin:0;padding:0;}th{padding:0;}td,td:first-of-type{font-weight:300;padding:0.8rem 0;vertical-align:top;}"]);

  const Extra = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-device__Extra",
    componentId: "dfiat5-6"
  })(["border-top:1px solid var(--primary-border-color);margin:0;padding:1.6rem;text-align:center;"]);

  const ExportButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-device__ExportButton",
    componentId: "dfiat5-7"
  })(["background-color:transparent;border:none;color:var(--secondary-text-color);margin:0.8rem 0;"]);

  const ViewDevicePageButton = _styledComponents.default.a.withConfig({
    displayName: "asset-tracker-device__ViewDevicePageButton",
    componentId: "dfiat5-8"
  })(["border-radius:3px;font-weight:600;margin:0 1.6rem;padding:1.6rem;width:calc(100% - 3.2rem);"]);

  AssetTrackerDevice.propTypes = {
    device: _propTypes.default.object.isRequired,
    devicePageUrl: _propTypes.default.string,
    onExportClick: _propTypes.default.func
  };
  var _default = AssetTrackerDevice;
  _exports.default = _default;
});