define("pconsole/helpers/clean-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cleanURL = cleanURL;
  _exports.default = void 0;

  function cleanURL([str]) {
    return str.replace(/.*?:\/\//g, '');
  }

  var _default = Ember.Helper.helper(cleanURL);

  _exports.default = _default;
});