define("pconsole/templates/user/ledger/index/tos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3v1Ne8Nw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Terms of service\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    By using this feature you agree to Particle's latest \"],[6,\"a\"],[9,\"href\",\"https://www.particle.io/legal/terms-of-service/\"],[9,\"target\",\"_black\"],[7],[0,\"Terms\\n    of Service.\"],[8],[0,\" If you do not agree to these terms you can still use the\\n    rest of the platform but access to this feature will be restricted.\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Do you agree to Particle's \"],[6,\"b\"],[7],[6,\"a\"],[9,\"href\",\"https://www.particle.io/legal/terms-of-service/\"],[9,\"target\",\"_black\"],[7],[0,\"Terms of Service?\"],[8],[8],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, go back\"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"acceptTOS\"]],[7],[0,\"\\n      Yes, I agree\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/user/ledger/index/tos.hbs"
    }
  });

  _exports.default = _default;
});