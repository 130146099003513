define("pconsole/templates/partials/integration/org-integration-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ec9jDs/n",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[\"toast\"],[[\"type\",\"__ANGLE_ATTRS__\"],[\"info\",[25,\"hash\",null,[[\"class\"],[[25,\"concat\",[[20,[\"class\"]],\" integration-toast integration-toast--info\"],null]]]]]],{\"statements\":[[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"Edit integration for all products\"],[8],[0,\"\\n\"],[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[7],[0,\"\\n      Changes made here will apply to all products where this integration is being executed.\\n\"],[4,\"if\",[[20,[\"isEditing\"]]],null,{\"statements\":[[0,\"        For adjustments specific to an individual product, go to the integration tab for the product you want to adjust and separate this integration from the group.\\n\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[20,[\"separateActions\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"integration-toast__actions\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"organization.integrations.show\",[20,[\"integration\",\"product\",\"organization\",\"slug\"]],[20,[\"integration\",\"orgIntegrationId\"]]],[[\"class\"],[\"ui button filled primary\"]],{\"statements\":[[0,\"        Go to integration details\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"product.integrations.edit\",[20,[\"integration\",\"id\"]]],[[\"class\"],[\"ui button filled primary\"]],{\"statements\":[[0,\"        Separate integration\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/integration/org-integration-toast.hbs"
    }
  });

  _exports.default = _default;
});