define("pconsole/components/eventlogs/events.react", ["exports", "react", "pconsole/components/eventlogs/event-loader.react", "pconsole/components/eventlogs/event-table.react", "pconsole/components/eventlogs/events-util"], function (_exports, _react, _eventLoader, _eventTable, _eventsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Events = ({
    events = [],
    selectedEvent,
    onClick,
    fields,
    unpause
  }) => {
    events = events.filter(event => event);
    const noEvents = !events || events && events.length < 1;
    let loader = null;

    if (noEvents) {
      events = _eventsUtil.default.generateBlankEvents(20);
      loader = _react.default.createElement(_eventLoader.default, null);
    }

    const renderedEvents = _eventsUtil.default.getParsedEvents(events, selectedEvent);

    return _react.default.createElement('div', {
      className: '_eventlogsnew__content__events__container'
    }, _react.default.createElement(_eventTable.default, {
      events: events,
      renderedEvents: renderedEvents,
      onClick: onClick,
      fields: fields,
      unpause: unpause
    }), loader);
  };

  var _default = Events;
  _exports.default = _default;
});