define("pconsole/mixins/new-integration-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newIntegrationRoute = void 0;
  const newIntegrationRoute = {
    queryParams: {
      template: {
        refreshMode: false
      }
    },

    getTemplateJSON(template) {
      if (!template) {
        return {
          template: {}
        };
      }

      return template.toJSON({
        includeId: true
      });
    },

    async model({
      template: templateId
    }) {
      const devices = await this.store.findAll('device');
      const template = this.store.peekRecord('integrationTemplate', templateId || 'webhook');
      const integration = this.createIntegration(this.getTemplateJSON(template));
      return {
        integration,
        template,
        devices
      };
    },

    // Set the correct breadcrumb and controller
    afterModel(model) {
      const integrationType = model.integration.get('integrationType');
      const breadCrumb = {
        title: Ember.get(model, 'integration.fullTemplate.name'),
        linkable: false
      };
      const type = Ember.get(integrationType, 'emberRouteName');
      Ember.set(this, 'controllerName', "user.integrations.".concat(type, ".create"));
      Ember.set(this, 'breadCrumb', breadCrumb);
    },

    setupController(controller, model) {
      this._super(...arguments); // At this point `controller` is already the one from controllerName


      Ember.set(controller, 'integration', model.integration);
      Ember.set(controller, 'model', model);
      Ember.set(controller, 'parentEntity', Ember.get(this, 'parentEntity'));
      const expanded = Ember.get(model.template, 'expandAdvanced');
      Ember.set(controller, 'showAdvanced', expanded);
      Ember.set(controller, 'displaySimpleForm', Ember.get(controller, 'hasSimpleForm'));
    },

    renderTemplate() {
      const type = this.currentModel.integration.get('integrationType.emberRouteName');
      this.render("user.integrations.".concat(type, ".create"));
    },

    deactivate() {
      const controllerName = Ember.get(this, 'controllerName');
      const controller = this.controllerFor(controllerName);
      Ember.set(controller, 'displayInstructions', true);
      Ember.set(controller, 'showAdvanced', false);
    },

    actions: {
      willTransition: function willTransition() {
        const controller = this.get('controller');
        const newIntegration = controller.get('integration');

        if (newIntegration && !newIntegration.get('isDeleted')) {
          if (newIntegration.get('isNew')) {
            newIntegration.deleteRecord();
          } else if (newIntegration.get('hasDirtyAttributes')) {
            newIntegration.rollbackAttributes();
          } // For Azure IoT Hub, we don't want to keep the policy
          // key hanging around in the front end. We'll set it to null.


          if (newIntegration.get('policyKey')) {
            newIntegration.set('policyKey', undefined);
          }
        }

        return true;
      }
    }
  };
  _exports.newIntegrationRoute = newIntegrationRoute;
});