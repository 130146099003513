define("pconsole/controllers/product/devices/batch/edit-groups", ["exports", "pconsole/mixins/device-grouping-power-select"], function (_exports, _deviceGroupingPowerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_deviceGroupingPowerSelect.default, {
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    planGroupsDisabled: Ember.computed('product.groupsEnabled', function () {
      return !this.get('product.groupsEnabled');
    }),
    groupsToAdd: [],
    groupsToRemove: [],
    groups: Ember.computed.alias('model.groups'),
    product: Ember.computed.alias('model.product'),
    // The batch-edit power select component views any group that either _some_ or
    // _all_ devices belong to as selected. This is what shows the checkbox in the UI.
    // For groups that _some_ devices belong to, we use a data-driven CSS property to override
    // the checbox to a minus icon (signaling partial selection).
    selectedGroups: Ember.computed.filter('groups', function (g) {
      return g.get('selected') !== 'none';
    }).property('groups.@each.selected'),
    // This sorting method helps ensure that selected groups appear at the top of
    // the list for easy searchability.
    sortedOptions: Ember.computed.sort('groups', function (a, b) {
      const selected1 = a.get('selected');
      const selected2 = b.get('selected');

      if (selected1 === selected2) {
        return 0;
      } else if (selected1 === 'all' || selected1 === 'some' && selected2 === 'none') {
        return -1;
      } else {
        return 1;
      }
    }),
    finalGroupChanges: Ember.computed('groupsToAdd.@each', 'groupsToRemove.@each', function () {
      const groupsToAdd = this.get('groupsToAdd');
      const groupsToRemove = this.get('groupsToRemove'); // This puts the group changes in the format that the API expects,
      // when passed in the body of the batch-update endpoint

      const finalGroupChanges = {
        add: groupsToAdd.mapBy('name'),
        remove: groupsToRemove.mapBy('name')
      };
      return finalGroupChanges;
    }),
    disableBtn: Ember.computed('finalGroupChanges', function () {
      const finalGroupChanges = this.get('finalGroupChanges');
      const add = finalGroupChanges.add;
      const remove = finalGroupChanges.remove;
      return add.length === 0 && remove.length === 0;
    }),

    addGroup(group) {
      const groupsToAdd = this.get('groupsToAdd');
      const groupsToRemove = this.get('groupsToRemove');
      group.set('selected', 'all');

      if (groupsToRemove.includes(group)) {
        groupsToRemove.popObject(group); // We need to handle "partial" groups a bit differently.
        // If clicked, they should always be either added/removed
        // from all selected devices

        if (group.get('partiallySelectedGroupClicked')) {
          groupsToAdd.pushObject(group);
        }
      } else {
        groupsToAdd.pushObject(group);
      }
    },

    removeGroup(group) {
      const groupsToAdd = this.get('groupsToAdd');
      const groupsToRemove = this.get('groupsToRemove');
      group.set('selected', 'none');

      if (groupsToAdd.includes(group)) {
        groupsToAdd.popObject(group); // We need to handle "partial" groups a bit differently.
        // If clicked, they should always be either added/removed
        // from all selected devices

        if (group.get('partiallySelectedGroupClicked')) {
          groupsToRemove.pushObject(group);
        }
      } else {
        groupsToRemove.pushObject(group);
      }
    },

    actions: {
      updateDeviceGroups() {
        const routeData = this.get('routeData');
        const product = routeData.product;
        const devices = this.get('selectedDevices');
        const finalGroupChanges = this.get('finalGroupChanges');
        Ember.$('.loading').show();
        return this.get('apiAjax').request({
          url: "/v1/products/".concat(product, "/devices"),
          type: 'PUT',
          data: {
            action: 'groups',
            devices: devices.mapBy('id'),
            metadata: finalGroupChanges
          }
        }).then(() => {
          Ember.$('.loading').hide();
          this.transitionToRoute('product.devices').then(() => {
            devices.setEach('isChecked', false);
            this.send('refreshModel');
            this.get('notify').success("Groups updated succcessfully for ".concat(devices.length, " devices."));
          });
        }, () => {
          Ember.$('.loading').hide();
          this.get('notify').error('There was a problem updating these devices. Please try again later.');
        });
      },

      closeModal() {
        this.transitionToRoute('product.devices');
      },

      chooseGroup(groups, selectApi) {
        const newGroups = groups;
        const oldGroups = selectApi.selected;
        let clickedGroup; // A group was clicked that some or all devices belong to.
        // For partially selected groups clicked, we target _all_ devices
        // to now have this group. For groups where all devices already belong,
        // we remove this group from all devices.

        if (newGroups.length < oldGroups.length) {
          clickedGroup = oldGroups.reject(g => {
            return newGroups.contains(g);
          })[0];

          if (clickedGroup.get('selected') === 'some') {
            clickedGroup.set('partiallySelectedGroupClicked', true);
            this.addGroup(clickedGroup);
          } else {
            this.removeGroup(clickedGroup);
          }
        } else {
          // A group that is not currently associated with any devices was selected.
          // Clicking a group like this means the user wants to add the group to all devices
          clickedGroup = newGroups.reject(g => {
            return oldGroups.contains(g);
          })[0];
          this.addGroup(clickedGroup);
        }

        selectApi.actions.search('');
      },

      handleEnter(dropdown) {
        // Reset the search
        dropdown.actions.search(''); // Create the group using the product endpoint

        const store = this.get('store');
        const groups = this.get('groups');
        const newGroupName = dropdown.searchText;
        const group = store.createRecord('group', {
          id: newGroupName,
          name: newGroupName,
          selected: 'all',
          product: groups.get('firstObject.product')
        });
        group.save().then(() => {
          this.addGroup(group);
        }, response => {
          const error = response.errors[0];
          const errorMsg = error ? error.detail : 'Please try again later.';
          this.get('notify').alert('Your group could not be created. ' + errorMsg);
        });
      },

      // I was noticing a clash between the logic running "on close" of the power select
      // and submitting the form to update groups. This prevents the dropdown from closing
      // once it's opened.
      onclose() {
        return false;
      }

    }
  });

  _exports.default = _default;
});