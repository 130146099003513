define("pconsole/templates/product/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hYzWrZUu",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"asset-tracker-page\"],[7],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[4,\"if\",[[20,[\"product\",\"platform\",\"isAssetTracker\"]]],null,{\"statements\":[[4,\"link-to\",[\"product.map.index.config\"],[[\"class\"],[\"asset-tracker-page__config-button\"]],{\"statements\":[[0,\"      \"],[6,\"i\"],[9,\"class\",\"ion-gear-b\"],[7],[8],[0,\" Configure Fleet\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[6,\"div\"],[9,\"class\",\"asset-tracker-page__map\"],[7],[0,\"\\n    \"],[4,\"asset-tracker-map\",null,[[\"activeDevice\",\"bounds\",\"center\",\"devices\",\"fitBounds\",\"deviceUpdates\",\"highlightedDevice\",\"metrics\",\"showAreaSearchButton\",\"onFeatureMouseEnter\",\"onFeatureMouseExit\",\"onBoundsChange\",\"onSearchThisArea\"],[[20,[\"activeDevice\"]],[20,[\"bounds\"]],[20,[\"center\"]],[20,[\"mapableDevices\"]],[20,[\"fitBounds\"]],[20,[\"deviceUpdates\"]],[20,[\"highlightedMapFeature\"]],[20,[\"segment\"]],[20,[\"showAreaSearchButton\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"highlightedDeviceItem\"]]],null]],[[\"value\"],[\"id\"]]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"highlightedDeviceItem\"]]],null],\"\"],null],[25,\"action\",[[19,0,[]],\"boundsChange\"],null],[25,\"action\",[[19,0,[]],\"searchThisArea\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/map.hbs"
    }
  });

  _exports.default = _default;
});