define("pconsole/components/highlighted-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      window.Prism.highlightElement(this.$('code').get(0));
    }

  });

  _exports.default = _default;
});