define("pconsole/components/eventlogs/prettify-raw-buttons.react", ["exports", "react", "react-copy-to-clipboard"], function (_exports, _react, _reactCopyToClipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PrettifyAndRawButtons = ({
    isJSON,
    prettifyJSON,
    data,
    onPrettifyClick,
    onRawClick,
    successNotify
  }) => _react.default.createElement('div', {
    className: '_eventlogsnew__content__right__buttons'
  }, isJSON && _react.default.createElement('div', null, _react.default.createElement('button', {
    className: "ui button outline ".concat(prettifyJSON ? 'primary' : 'secondary'),
    onClick: onPrettifyClick
  }, _react.default.createElement('span', null, 'Pretty')), _react.default.createElement('button', {
    className: "ui button outline ".concat(!prettifyJSON ? 'primary' : 'secondary'),
    onClick: onRawClick
  }, _react.default.createElement('span', null, 'Raw'))), data && _react.default.createElement(_reactCopyToClipboard.default, {
    text: data,
    onCopy: successNotify
  }, _react.default.createElement('button', {
    className: 'ui button outline secondary'
  }, _react.default.createElement('i', {
    className: 'ion-clipboard'
  }))));

  var _default = PrettifyAndRawButtons;
  _exports.default = _default;
});