define("pconsole/mixins/grouped-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupedIntegrationNotification = void 0;
  const groupedIntegrationNotification = {
    queryParams: ['showGrouped'],
    showGrouped: false,
    actions: {
      closeNotif() {
        Ember.$('.modal').modal('hide');
        Ember.set(this, 'showGrouped', null);
      }

    }
  };
  _exports.groupedIntegrationNotification = groupedIntegrationNotification;
});