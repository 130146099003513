define("pconsole/templates/organization/insights/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R/FwaGCh",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"particle-insights particle-insights__report\"],[7],[0,\"\\n  \"],[1,[25,\"bread-crumbs\",null,[[\"tagName\",\"linkable\"],[\"ul\",true]]],false],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[0,\"\\n    \"],[1,[20,[\"model\",\"dashboard\",\"name\"]],false],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"beta-tag\"],[7],[0,\"\\n      \"],[6,\"span\"],[7],[0,\"Beta\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"component\",[\"toast\"],[[\"type\",\"subject\",\"message\",\"__ANGLE_ATTRS__\"],[\"error\",\"Oops! Something is not quite right\",\"We are sorry, but it seems like Insights is experiencing technical difficulties. We recommend refreshing the page or returning at a later time.\",[25,\"hash\",null,[[\"class\"],[\"device-content__at-mark-dev-message margin-bottom-half\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[26,[\"loading loading--full-height \",[25,\"if\",[[20,[\"loading\"]],\"\",\"loading--hidden\"],null]]]],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"icon ion-loading-c\"],[7],[8],[0,\"\\n      \"],[6,\"p\"],[7],[0,\"\\n        Loading your data. This might take up to 2 minutes, depending on the volume of\\n        data associated with your organization.\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[1,[25,\"component\",[\"toast\"],[[\"type\",\"subject\",\"message\",\"__ANGLE_ATTRS__\"],[\"info\",\"\",\"The data in this dashboard refreshes once a day.\",[25,\"hash\",null,[[\"class\"],[\"particle-insights__info-toast\"]]]]]],false],[0,\"\\n    \"],[6,\"iframe\"],[9,\"class\",\"particle-insights__dashboard\"],[9,\"width\",\"100%\"],[9,\"height\",\"700\"],[9,\"frameborder\",\"0\"],[10,\"src\",[20,[\"model\",\"url\"]],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/insights/report.hbs"
    }
  });

  _exports.default = _default;
});