define("pconsole/controllers/product/sims/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    productSimsController: Ember.inject.controller('product.sims'),
    iccid: Ember.computed.alias('productSimsController.iccid'),
    actions: {
      executeSearch(iccid) {
        this.get('productSimsController').send('executeSearch', iccid);
      }

    }
  });

  _exports.default = _default;
});