define("pconsole/routes/organization/integrations/show", ["exports", "pconsole/mixins/show-integration-route"], function (_exports, _showIntegrationRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_showIntegrationRoute.showIntegrationRoute, {
    parentEntity: 'organization'
  });

  _exports.default = _default;
});