define("pconsole/models/device-location", ["exports", "ember-data/model", "ember-data/attr", "@turf/distance", "ember-data/relationships"], function (_exports, _model, _attr, _distance, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _model.default.extend({
    deviceName: (0, _attr.default)('string'),
    geometry: (0, _attr.default)(),
    gpsLock: (0, _attr.default)('boolean'),
    lastHeard: (0, _attr.default)('date'),
    online: (0, _attr.default)('boolean'),
    properties: (0, _attr.default)(),
    timestamps: (0, _attr.default)(),
    horizontalAccuracy: (0, _attr.default)('number'),
    horizontalAccuracies: (0, _attr.default)(),
    groups: (0, _relationships.hasMany)(),
    product: (0, _relationships.belongsTo)(),
    handleDidUpdate: function () {
      this.notifyPropertyChange('geometry');
    }.on('didUpdate'),
    hasLocation: Ember.computed('geometry', function () {
      return !!Ember.get(this, 'geometry.coordinates');
    }),
    // eslint-disable-next-line max-statements
    folded: Ember.computed('geometry', 'geometry.coordinates.[]', 'timestamps.[]', 'properties.[]', 'horizontalAccuracies.[]', function () {
      const foldedDistance = 25; // meters

      const geometry = Ember.get(this, 'geometry');
      const properties = Ember.get(this, 'properties');
      const timestamps = Ember.get(this, 'timestamps');
      const accuracies = Ember.get(this, 'horizontalAccuracies'); // Fold up LineString coordinates and timestamps

      if (geometry && geometry.type === 'LineString') {
        const {
          coordinates
        } = geometry;
        const foldedCoordinates = [];
        const foldedCounts = [];
        const foldedProperties = [];
        const foldedAccuracies = [];
        const foldedTimestamps = [];
        let count = 0;
        let prevCoord = coordinates[0];
        let sumCoord = [0, 0, 0];
        let prevProps = properties && properties[0] || undefined;
        let prevAcc = accuracies && accuracies[0] || undefined;
        let start = timestamps[0];
        let end;

        const accumulateCoord = coord => {
          sumCoord[0] += coord[0];
          sumCoord[1] += coord[1];
          sumCoord[2] += coord[2];
        };

        const pushFoldedCoord = () => {
          const [lngSum, latSum, altSum] = sumCoord;
          const averageCoord = [lngSum / (count + 1), latSum / (count + 1)];

          if (!Number.isNaN(altSum)) {
            averageCoord.push(altSum / (count + 1));
          }

          foldedCoordinates.push(averageCoord);
          foldedCounts.push(count);
          foldedProperties.push(prevProps);
          foldedAccuracies.push(prevAcc);

          if (end) {
            foldedTimestamps.push({
              start,
              end
            });
          } else {
            foldedTimestamps.push({
              start
            });
          }
        };

        accumulateCoord(coordinates[0]); // Starts with second item

        for (let i = 1; i < coordinates.length; i++) {
          const coord = coordinates[i];
          const distance = (0, _distance.default)(coord, prevCoord) * 1000;

          if (distance < foldedDistance) {
            end = timestamps[i];
            count++;
          } else {
            pushFoldedCoord();
            prevCoord = coord;
            sumCoord = [0, 0, 0];
            prevProps = properties && properties[i] || undefined;
            prevAcc = accuracies && accuracies[i] || undefined;
            start = timestamps[i];
            end = null;
            count = 0;
          }

          accumulateCoord(coord);
        }

        pushFoldedCoord();
        return {
          counts: foldedCounts,
          geometry: {
            coordinates: foldedCoordinates,
            type: 'LineString'
          },
          properties: foldedProperties.some(Boolean) ? foldedProperties : undefined,
          accuracies: foldedAccuracies.some(Boolean) ? foldedAccuracies : undefined,
          timestamps: foldedTimestamps
        };
      }
    }),

    toObject() {
      const device = this.toJSON({
        includeId: true
      });
      const folded = Ember.get(this, 'folded'); // Ember's toJSON doesn't include relationships in the output; transform
      // each associated record

      const groupsRecordArray = this.hasMany('groups').value();
      const groups = groupsRecordArray && groupsRecordArray.toArray().map(g => g.toJSON()) || null;

      if (folded) {
        device.foldedCounts = folded.counts;
        device.foldedGeometry = folded.geometry;
        device.foldedProperties = folded.properties;
        device.foldedAccuracies = folded.accuracies;
        device.foldedTimestamps = folded.timestamps;
      }

      return _objectSpread({}, device, {
        groups,
        hasLocation: Ember.get(this, 'hasLocation')
      });
    }

  });

  _exports.default = _default;
});