define("pconsole/controllers/user/home", ["exports", "pconsole/mixins/top-products-mixin"], function (_exports, _topProductsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_topProductsMixin.topProductsMixin, {
    segment: Ember.inject.service(),
    parentEntity: 'user',
    links: [{
      id: 'store',
      label: 'Go to the Particle store',
      href: 'https://store.particle.io/'
    }, {
      id: 'setup',
      label: 'Setup a new device',
      href: 'https://setup.particle.io/'
    }, {
      id: 'docs',
      label: 'Go to docs',
      href: 'https://docs.particle.io/'
    }, {
      id: 'community',
      label: 'Visit the community',
      href: 'https://community.particle.io/'
    }, {
      id: 'workbench',
      label: 'Get Particle Workbench',
      href: 'https://marketplace.visualstudio.com/items?itemName=particle.particle-vscode-pack'
    }],
    news: [{
      title: 'Faster than light!',
      shortDescription: 'After 10 years of accelerating IoT development, Particle is making a quantum leap in speed and power for the next frontier of edge computing.',
      button: {
        label: 'Sign up to be first in line',
        href: 'https://www.particle.io/ftl'
      }
    }, {
      title: 'Integrations gallery',
      shortDescription: 'Quickly integrate with over 20 cloud services shown in the integration gallery.',
      button: {
        label: 'Try it now',
        href: 'user.integrations.gallery',
        internal: true
      },
      link: {
        label: 'Go to docs',
        href: 'https://docs.particle.io/integrations/introduction'
      }
    }, {
      title: 'Introducing the M-Series',
      shortDescription: 'Particle is launching a line of multi-radio devices, supporting Wi-Fi, LTE cellular, satellite, and LoRaWAN.',
      button: {
        label: 'Get more information',
        href: 'https://www.particle.io/blog/connect-anywhere-introducing-the-m-series/'
      }
    }],
    gettingStarted: [{
      title: 'Get a device',
      shortDescription: 'We have a wide array of devices to get you started with the Particle ecosystem.',
      button: {
        label: 'Go to the Store',
        href: 'https://store.particle.io',
        trackName: 'blank-sandbox-click-store'
      }
    }, {
      title: 'Set up a new device',
      shortDescription: 'Get your Particle devices up and running straight from your browser.',
      button: {
        label: 'Go to Setup',
        href: 'https://setup.particle.io',
        trackName: 'blank-sandbox-click-setup'
      }
    }],
    blankSandboxReported: false,
    blankSandbox: Ember.computed('model.products', 'model.organizations', function () {
      const hasProducts = Ember.get(this, 'model.products.length');
      const hasOrganizations = Ember.get(this, 'model.organization.length');
      return !hasProducts && !hasOrganizations;
    }),
    // only once per load of the Console
    reportBlankSandbox: function reportBlankSandbox() {
      if (Ember.get(this, 'blankSandbox') && !Ember.get(this, 'blankSandboxReported')) {
        Ember.get(this, 'segment').trackEvent('blank-sandbox-shown');
        Ember.set(this, 'blankSandboxReported', true);
      }
    },
    actions: {
      newsClick(button) {
        if (button.internal) {
          this.transitionToRoute(button.href);
        } else {
          window.open(button.href, '_blank');
        }
      },

      gettingStartedClick(button) {
        Ember.get(this, 'segment').trackEvent(button.trackName);
        window.open(button.href, '_blank');
      }

    }
  });

  _exports.default = _default;
});