define("pconsole/components/asset-tracker-map/updated-devices-layers", ["exports", "react", "prop-types", "pconsole/utils/average-group-color", "pconsole/lib/updated-point-image", "react-mapbox-gl"], function (_exports, _react, _propTypes, _averageGroupColor, _updatedPointImage, _reactMapboxGl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UpdatedDevicesLayers = ({
    devices,
    activeDevice
  }) => {
    const updateSourceOpts = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: devices.map(({
          foldedGeometry,
          geometry,
          id
        }) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: foldedGeometry ? foldedGeometry.coordinates.slice(-1)[0] : geometry.coordinates
          },
          properties: {
            id
          }
        }))
      }
    };

    function renderUpdateLayer(device) {
      if (activeDevice && device.id !== activeDevice) {
        return;
      }

      const color = (0, _averageGroupColor.default)(device.groups);
      const data = new _updatedPointImage.default(64, color);
      return _react.default.createElement(_react.Fragment, {
        key: device.id
      }, _react.default.createElement(_reactMapboxGl.Image, {
        id: "updated-point-".concat(device.id),
        data: data
      }), _react.default.createElement(_reactMapboxGl.Layer, {
        type: 'symbol',
        sourceId: 'updates',
        layout: {
          'icon-image': "updated-point-".concat(device.id)
        },
        filter: ['==', 'id', device.id]
      }));
    }

    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_reactMapboxGl.Source, {
      id: 'updates',
      geoJsonSource: updateSourceOpts
    }), devices.map(renderUpdateLayer));
  };

  UpdatedDevicesLayers.propTypes = {
    devices: _propTypes.default.array.isRequired,
    activeDevice: _propTypes.default.string
  };
  var _default = UpdatedDevicesLayers;
  _exports.default = _default;
});