define("pconsole/components/eventlogs/events-util", ["exports", "particle-api-js", "pconsole/config/environment"], function (_exports, _particleApiJs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const moment = window.moment;

  const eventIsQueued = event => {
    return event && event.queued === true;
  };

  const getFormattedEventDate = date => {
    return moment(new Date(date)).format('M/D/YY [at] h:mm:ss a');
  };

  const ParticleClient = new _particleApiJs.default({
    baseUrl: _environment.default.APP.API_ENDPOINT
  });
  var _default = {
    eventIsQueued,
    getFormattedEventDate,
    getFormattedEvent: event => {
      return Object.assign({}, event, {
        published_at: getFormattedEventDate(event.published_at)
      });
    },
    randomEventId: event => {
      return "".concat(event.name, " ").concat(btoa(Math.random()).substring(0, 24));
    },
    eventPassesFiltering: (event, filter) => {
      let ok = true;

      if (event && filter) {
        if (filter.name) {
          if (event.name !== filter.name) {
            ok = false;
          }
        }

        if (filter.deviceId) {
          if (event.coreid !== filter.deviceId) {
            ok = false;
          }
        }

        if (filter.deviceName) {
          if (event.deviceName !== filter.deviceName) {
            ok = false;
          }
        }

        if (filter.data) {
          if (!event.data) {
            ok = false;
          }

          if (event.data && event.data.indexOf(filter.data) === -1) {
            ok = false;
          }
        }

        if (filter.match) {
          ok = false;
          Object.keys(event).forEach(key => {
            if ((event[key] + '').indexOf(filter.match) !== -1) {
              ok = true;
            }
          });
        }

        if (filter.range) {
          if (!event.data) {
            ok = false;
          }

          const data = parseInt(event.data, 10);
          const a = filter.range.split('-')[0];
          const b = filter.range.split('-')[1];

          if (data < a || data > b) {
            ok = false;
          }
        }
      }

      return ok;
    },
    numberOfQueuedEvents: (events = []) => {
      return events.filter(event => event).filter(event => eventIsQueued(event)).length;
    },
    removeEventsFromQueue: events => {
      return events.map(event => Object.assign({}, event, {
        queued: false
      }));
    },
    generateBlankEvents: (length = 20) => {
      return Array.from({
        length
      }, (v, k) => k + 1).map((item, index) => Object.assign({}, {
        id: "blank-event-".concat(index),
        blank: true
      }));
    },
    getDataAsJSON: data => {
      if (!data) {
        return null;
      }

      try {
        const json = JSON.parse(data);
        return typeof json === 'object' ? json : null;
      } catch (e) {
        return null;
      }
    },
    getParsedEvents: (events, selectedEvent) => {
      return events.filter(event => event).filter(event => !eventIsQueued(event)).map(event => Object.assign({}, event, {
        selected: selectedEvent && selectedEvent.id === event.id
      })).sort((a, b) => new Date(b.published_at) - new Date(a.published_at) || b.index - a.index);
    },
    getFields: ({
      device
    }) => {
      let fields = [{
        title: 'Name',
        property: 'name'
      }, {
        title: 'Data',
        property: 'data'
      }, {
        title: 'Device',
        property: 'deviceName',
        secondProperty: 'coreid'
      }, {
        title: 'Published at',
        property: 'published_at'
      }];

      if (device) {
        fields = fields.filter(field => field.title !== 'Device ID');
      }

      return fields;
    },
    fetchDeviceName: (deviceId, product, auth) => {
      return ParticleClient.getDevice({
        deviceId,
        product,
        auth
      }).then(response => {
        return response && response.body && response.body.name;
      }).catch(err => {
        console.error(err);
      });
    }
  };
  _exports.default = _default;
});