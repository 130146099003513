define("pconsole/components/eventlogs/clear-button.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ClearButton = ({
    paused,
    onClick
  }) => _react.default.createElement("div", {
    className: "hint--top",
    "data-hint": "Clear the event list"
  }, _react.default.createElement("button", {
    className: "ui button outline secondary",
    onClick: onClick
  }, _react.default.createElement("i", {
    className: "ion-trash-b"
  })));

  var _default = ClearButton;
  _exports.default = _default;
});