define("pconsole/initializers/segment", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    const application = arguments[1] || arguments[0];
    const {
      segment = {}
    } = _environment.default;
    const {
      environment = 'development'
    } = _environment.default;
    const segmentConfig = {
      segment,
      environment
    };
    application.register('config:segment', segmentConfig, {
      instantiate: false
    });
    application.inject('service:segment', 'config', 'config:segment');
  }

  var _default = {
    name: 'segment',
    initialize: initialize
  };
  _exports.default = _default;
});