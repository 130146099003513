define("pconsole/controllers/product/devices/edit-group", ["exports", "pconsole/mixins/create-or-edit-group"], function (_exports, _createOrEditGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_createOrEditGroup.default, {
    group: Ember.computed.alias('editableGroup'),
    groups: Ember.computed.alias('model.groups'),
    isEditing: true,
    actions: {
      chooseGroup(groups) {
        const group = groups[0];
        this.set('editableGroup', group);
      }

    }
  });

  _exports.default = _default;
});