define("pconsole/models/tos", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TOS = _emberData.default.Model.extend({
    accepted: _emberData.default.attr('boolean'),
    version: _emberData.default.attr('number'),
    date: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user')
  });

  var _default = TOS;
  _exports.default = _default;
});