define("pconsole/mixins/routes/logic-templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LogicTemplatesIndexRoute = void 0;
  const LogicTemplatesIndexRoute = {
    templateName: 'organization.logic.templates',
    controllerName: 'organization.logic.templates.index',
    breadCrumb: {
      title: 'Create new Function from template'
    },

    async model() {
      const templates = await this.store.findAll('logic-template');
      return templates;
    }

  };
  _exports.LogicTemplatesIndexRoute = LogicTemplatesIndexRoute;
});