define("pconsole/templates/organization/ledger/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jh+Ttt7v",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[1,[25,\"bread-crumbs\",null,[[\"tagName\",\"linkable\"],[\"ul\",true]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ledger-categories-page\"],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[0,\"Get started with Ledgers\"],[8],[0,\"\\n  \"],[6,\"h5\"],[9,\"class\",\"dashboard-subtitle\"],[7],[0,\"\\n    Select the type of Ledger that applies best for your use case to learn more about it and start using it.\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"template-list\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"categories\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"gallery-card\",null,[[\"name\",\"shortDescription\",\"buttonText\",\"disabled\",\"disabledText\",\"onSelect\"],[[19,1,[\"name\"]],[19,1,[\"shortDescription\"]],\"Select\",[19,1,[\"disabled\"]],\"Coming soon\",[25,\"action\",[[19,0,[]],\"showCategory\",[19,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"selectedCategory\"]]],null,{\"statements\":[[4,\"fleet-modal\",null,[[\"class\",\"close\"],[\"ledger-category-view no-styles\",\"closeModal\"]],{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"body\"],[7],[0,\"\\n    \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[1,[20,[\"selectedCategory\",\"name\"]],false],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"template-description\"],[7],[0,\"\\n      \"],[1,[25,\"markdown-to-html\",null,[[\"markdown\",\"extensions\"],[[25,\"unbound\",[[20,[\"selectedCategory\",\"description\"]]],null],\"xssfilter\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"selectCategory\"]],[7],[0,\"\\n      Start now\\n    \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/ledger/categories.hbs"
    }
  });

  _exports.default = _default;
});