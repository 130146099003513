define("pconsole/utils/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  const dateOpts = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  function formatDate(date, locale = 'en-us') {
    const dateFormat = new Intl.DateTimeFormat(locale, dateOpts);
    const [{
      value: month
    },, {
      value: day
    },, {
      value: year
    },, {
      value: hour
    },, {
      value: minute
    },, {
      value: hour12
    }] = dateFormat.formatToParts(date);
    return "".concat(month, " ").concat(day, ", ").concat(year, " at ").concat(hour, ":").concat(minute).concat(hour12.toLowerCase());
  }
});