define("pconsole/serializers/sim", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,
    primaryKey: '_id',
    attrs: {
      device: {
        key: 'last_device_id'
      }
    },

    normalizeResponse(store, type, payload, id, requestType) {
      if (payload.sim || payload.sims) {
        return this._super(...arguments);
      } else {
        const normalized = {
          'sim': payload
        };
        return this._super(store, type, normalized, id, requestType);
      }
    }

  });

  _exports.default = _default;
});