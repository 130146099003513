define("pconsole/templates/components/sim/sim-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VeEVqAI+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"sim\",\"stateChangeInProgress\"]]],null,{\"statements\":[[0,\"  \"],[6,\"i\"],[9,\"class\",\"ion-loading-c\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"i\"],[9,\"class\",\"ion-connection-bars\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[6,\"span\"],[9,\"class\",\"status-name\"],[7],[0,\"\\n  \"],[1,[20,[\"sim\",\"friendlyStatus\"]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"sim\",\"stateChangeInProgress\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"hint--top\"],[9,\"data-hint\",\"This SIM is being updated. Please check back later.\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"im-info-icon help-icon\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"sim\",\"isDeactivatedFromUser\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"hint--top\"],[9,\"data-hint\",\"This SIM has been deactivated. Starting next month, devices using this SIM will no longer be considered billable. You can reactivate it at any time to resume cellular data transfer.\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"im-info-icon help-icon\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"sim\",\"isInactive\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"hint--top\"],[9,\"data-hint\",\"This SIM has been imported. It will be activated shortly. Please check back later.\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"im-info-icon help-icon\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/sim/sim-status.hbs"
    }
  });

  _exports.default = _default;
});