define("pconsole/components/non-approved-product-device-table", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lastHandshakeTooltipText: _environment.default.APP.LAST_HANDSHAKE_TOOLTIP_TEXT,
    classNameBindings: ['isQuarantined:quarantined-devices', 'isDenied:denied-devices', 'hideDevices:hidden'],
    classNames: ['toggleable', 'non-approved'],
    isQuarantined: Ember.computed('type', function () {
      return this.get('type') === 'quarantined';
    }),
    isDenied: Ember.computed('type', function () {
      return this.get('type') === 'denied';
    }),
    deviceCount: Ember.computed('devices', function () {
      return this.get('devices.length');
    }),
    actions: {
      toggleShowDevices() {
        this.toggleProperty('hideDevices');
      }

    }
  });

  _exports.default = _default;
});