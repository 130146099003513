define("pconsole/adapters/role", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const roleAdapter = _application.default.extend({
    namespace: Ember.computed('routeData.product', 'routeData.org', function () {
      const product = this.get('routeData.product');
      const org = this.get('routeData.org');

      if (product) {
        return 'v1/products/' + this.get('routeData.product');
      } else if (org) {
        return 'v1/orgs/' + this.get('routeData.org');
      }
    }),

    pathForType() {
      return 'roles';
    }

  });

  var _default = roleAdapter;
  _exports.default = _default;
});