define("pconsole/templates/user/sims/deactivate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lu7zuBUC",
    "block": "{\"symbols\":[],\"statements\":[[12,\"partials/deactivate-sim\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "pconsole/templates/user/sims/deactivate.hbs"
    }
  });

  _exports.default = _default;
});