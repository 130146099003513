define("pconsole/components/validated-field/help-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['help-text'],
    hasError: false,
    message: '',
    tagName: 'p'
  });

  _exports.default = _default;
});