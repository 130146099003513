define("pconsole/controllers/organization/index", ["exports", "pconsole/mixins/top-products-mixin"], function (_exports, _topProductsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_topProductsMixin.topProductsMixin, {
    applicationController: Ember.inject.controller('application'),
    parentEntity: 'organization',
    fullLinks: [{
      id: 'enterprise',
      label: 'Enterprise resources',
      href: 'organization.enterprise-resources',
      internal: true,
      orgType: 'enterprise'
    }, {
      id: 'docs',
      label: 'Go to docs',
      href: 'https://docs.particle.io/',
      orgType: 'all'
    }, {
      id: 'support',
      label: 'Open a support ticket',
      href: 'https://docs.particle.io/troubleshooting/troubleshooting',
      orgType: 'enterprise'
    }, {
      id: 'community',
      label: 'Visit the community',
      href: 'https://community.particle.io/',
      orgType: 'growth'
    }, {
      id: 'sales',
      label: 'Contact sales',
      href: 'https://particle.io/sales',
      orgType: 'all'
    }],
    fullNews: [{
      title: 'Faster than light!',
      orgType: 'all',
      shortDescription: 'After 10 years of accelerating IoT development, Particle is making a quantum leap in speed and power for the next frontier of edge computing.',
      button: {
        label: 'Sign up to be first in line',
        href: 'https://www.particle.io/ftl'
      }
    }, {
      title: 'Lake',
      orgType: 'all',
      shortDescription: 'Capture every event to secure cloud storage.',
      button: {
        label: 'Read more about it',
        href: 'https://www.particle.io/lake/#contact-to-get-lake'
      }
    }, {
      title: 'Insights',
      orgType: 'enterprise',
      shortDescription: 'Quickly determine performance issues or potential security concerns across your account with Insights.',
      button: {
        label: 'Try it now',
        href: 'organization.insights',
        internal: true
      },
      link: {
        label: 'Read more',
        href: 'https://www.particle.io/blog/from-insights-to-action/'
      }
    }, {
      title: 'Integrations gallery',
      orgType: 'all',
      shortDescription: 'Quickly integrate with over 20 cloud services shown in the integration gallery.',
      button: {
        label: 'Try it now',
        href: 'organization.integrations.gallery',
        internal: true
      },
      link: {
        label: 'Go to docs',
        href: 'https://docs.particle.io/integrations/introduction'
      }
    }, {
      title: 'Introducing the M-Series',
      orgType: 'all',
      shortDescription: 'Particle is launching a line of multi-radio devices, supporting Wi-Fi, LTE cellular, satellite, and LoRaWAN.',
      button: {
        label: 'Get more information',
        href: 'https://www.particle.io/blog/connect-anywhere-introducing-the-m-series/'
      }
    }],
    shouldDisplayEnterprise: Ember.computed('applicationController.currentServiceAgreement', function () {
      return !Ember.get(this, 'applicationController.currentServiceAgreement.isGrowth');
    }),
    links: Ember.computed('fullLinks', 'shouldDisplayEnterprise', function () {
      return this.filterItems('fullLinks');
    }),
    news: Ember.computed('fullNews', 'shouldDisplayEnterprise', function () {
      return this.filterItems('fullNews').slice(0, 4);
    }),
    filterItems: function filterItems(listName) {
      const fullList = Ember.get(this, listName);
      const shouldDisplayEnterprise = Ember.get(this, 'shouldDisplayEnterprise');
      return fullList.filter(({
        orgType
      }) => {
        return orgType === 'all' || // Items available to everyone
        orgType === 'enterprise' && shouldDisplayEnterprise || // Items for enterprise only
        orgType === 'growth' && !shouldDisplayEnterprise; // Items for not enterprise only
      });
    },
    actions: {
      newsClick(button) {
        if (button.internal) {
          this.transitionToRoute(button.href);
        } else {
          window.open(button.href, '_blank');
        }
      }

    }
  });

  _exports.default = _default;
});