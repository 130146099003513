define("pconsole/templates/components/change-plan-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zOncw9vv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"newPlan\",\"interval\"]],\"year\"],null]],null,{\"statements\":[[6,\"span\"],[9,\"class\",\"best-value\"],[7],[6,\"i\"],[9,\"class\",\"ion-checkmark\"],[7],[8],[0,\"best value\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"span\"],[9,\"class\",\"plan-amount\"],[7],[1,[25,\"cents-to-dollars-rounded\",[[20,[\"newPlan\",\"monthlyAmount\"]]],null],false],[6,\"span\"],[9,\"class\",\"monthly-marker\"],[7],[0,\"/mo\"],[8],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"interval\"],[7],[0,\"billed \"],[1,[25,\"plan-interval\",[[20,[\"newPlan\",\"interval\"]]],null],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/change-plan-button.hbs"
    }
  });

  _exports.default = _default;
});