define("pconsole/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BXmey0F5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"trianglify-background\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"login-wrapper\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"class\",\"particle-login-logo\"],[9,\"src\",\"/assets/particle-logo-horizontal.png\"],[7],[8],[0,\"\\n      \"],[6,\"form\"],[3,\"action\",[[19,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"for\",\"identification\"],[7],[0,\"Username\"],[8],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"value\",\"type\"],[\"identification\",\"john@example.com\",[20,[\"identification\"]],\"email\"]]],false],[0,\"\\n        \"],[6,\"label\"],[9,\"for\",\"password\"],[7],[0,\"Password\"],[8],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"type\",\"value\"],[\"password\",\"••••••••\",\"password\",[20,[\"password\"]]]]],false],[0,\"\\n        \"],[6,\"label\"],[9,\"for\",\"identification\"],[7],[0,\"OTP token (optional)\"],[8],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"value\"],[\"otp\",\"123456\",[20,[\"otp\"]]]]],false],[0,\"\\n        \"],[6,\"p\"],[7],[0,\"— OR —\"],[8],[0,\"\\n        \"],[6,\"label\"],[9,\"for\",\"token\"],[7],[0,\"Impersonate by access token\"],[8],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"value\",\"autocomplete\"],[\"token\",\"123456\",[20,[\"token\"]],\"off\"]]],false],[0,\"\\n        \"],[6,\"button\"],[9,\"type\",\"submit\"],[9,\"class\",\"ui button filled primary\"],[7],[0,\"Login\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/login.hbs"
    }
  });

  _exports.default = _default;
});