define("pconsole/controllers/organization/billing", ["exports", "fetch", "pconsole/config/environment", "pconsole/helpers/formatted-date"], function (_exports, _fetch, _environment, _formattedDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const tooltipMessages = {
    wifi: {
      enterprise: 'Includes devices that have had at least 1 connection to Particle Cloud. Not counted: devices set up less than 6 months ago with no active use during this period.',
      self_service: 'Includes devices that have had at least 1 connection to Particle Cloud.'
    },
    cellular: {
      enterprise: 'Includes devices with active SIM cards that have had at least 1 connection to Particle Cloud. Not counted: Devices set up less than 6 months ago with no active use during this period.',
      self_service: 'Includes devices with active SIM cards that have had at least 1 connection to Particle Cloud.'
    }
  };
  const alertMessage = [// Manual paused
  {
    eventNamePattern: /usage_activity:manual_pause/,
    title: 'Service Paused',
    message: 'The Particle team has paused the service for your device(s) for lack of payment. Please contact customer support to resolve this issue.'
  }, // Growth usage exceeded
  {
    eventNamePattern: /self_service:usage_activity:.*:threshold:3/,
    title: 'Usage Limit Exceeded',
    message: "You've exceeded your limits for this usage period, but this will not impact your service. An additional block will be added to your plan. Your account will be charged for the new block at the start of the next usage period, $DATE. Please check your account email for more information."
  }];
  const enterpriseUsageExceeded = {
    title: 'Usage Limit Exceeded',
    message: "You've exceeded the usage limits for your tier. This will not immediately impact your service but please contact customer support for more information."
  };

  var _default = Ember.Controller.extend({
    isLoadingBillingPortalLink: false,
    cellularProducts: Ember.computed.readOnly('model.productsByType.cellular'),
    notify: Ember.inject.service(),
    organization: Ember.computed.readOnly('model.organization'),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    serviceAgreement: Ember.computed.readOnly('model.serviceAgreement'),
    store: Ember.inject.service(),
    usageAlerts: Ember.inject.service(),
    wifiProducts: Ember.computed.readOnly('model.productsByType.wifi'),
    allProducts: Ember.computed('wifiProducts', 'cellularProducts', function () {
      return Ember.get(this, 'wifiProducts').concat(Ember.get(this, 'cellularProducts'));
    }),
    alert: Ember.computed('usageAlerts.allAlerts', 'serviceAgreement', function () {
      const allAlerts = Ember.get(this, 'usageAlerts.allAlerts');
      const serviceAgreement = Ember.get(this, 'serviceAgreement');
      return allAlerts.find(alert => Ember.get(alert, 'serviceAgreement.id') === Ember.get(serviceAgreement, 'id'));
    }),
    warning: Ember.computed('alert', function () {
      const alert = Ember.get(this, 'alert');
      const usageThresholdExceeded = Ember.get(this, 'serviceAgreement.currentUsageSummary.usage_threshold_exceeded');
      const warning = alert && alertMessage.find(a => a.eventNamePattern.exec(Ember.get(alert, 'eventName')));

      if (warning) {
        return {
          displayWarning: true,
          title: warning.title,
          message: this.formatMessage(warning.message, alert)
        };
      } else if (usageThresholdExceeded) {
        return {
          displayWarning: true,
          title: enterpriseUsageExceeded.title,
          message: enterpriseUsageExceeded.message
        };
      } else {
        return {
          displayWarning: false,
          title: '',
          message: ''
        };
      }
    }),

    formatMessage(message, alert) {
      return message.replace('$DATE', (0, _formattedDate.formattedDate)([Ember.get(alert, 'nextBillingPeriodStart'), 'MMM D', 'utc']));
    },

    isAdminUser: Ember.computed('serviceAgreement.{currentUsageSummary,pricingTerms}', function () {
      const currentUsageSummary = Ember.get(this, 'serviceAgreement.currentUsageSummary');
      const pricingTerms = Ember.get(this, 'serviceAgreement.pricingTerms');
      return !Ember.isEmpty(currentUsageSummary) && !Ember.isEmpty(pricingTerms);
    }),
    serviceAgreementObject: Ember.computed('serviceAgreement', function () {
      const serviceAgreement = Ember.get(this, 'serviceAgreement');

      if (serviceAgreement) {
        return serviceAgreement.toObject();
      }
    }),
    wifiTooltipMessage: Ember.computed('serviceAgreement.agreementType', function () {
      const agreementType = Ember.get(this, 'serviceAgreement.agreementType');
      return tooltipMessages['wifi'][agreementType];
    }),
    cellularTooltipMessage: Ember.computed('serviceAgreement.agreementType', function () {
      const agreementType = Ember.get(this, 'serviceAgreement.agreementType');
      return tooltipMessages['cellular'][agreementType];
    }),
    actions: {
      getProductHref({
        id: productId
      }) {
        const product = this.store.peekRecord('product', productId);
        return Ember.get(this, 'router').urlFor('product.fleet-health', Ember.get(product, 'slug'));
      },

      async openBillingPortal() {
        const notify = Ember.get(this, 'notify');
        const session = Ember.get(this, 'session');
        const defaultError = 'The Billing Portal is unavailable';
        const accessToken = Ember.get(session, 'data.authenticated.access_token');
        const serviceAgreement = Ember.get(this, 'serviceAgreement.id');
        const org = Ember.get(this, 'organization.slug');
        const url = "".concat(_environment.default.APP.API_ENDPOINT, "/v1/orgs/").concat(org, "/service_agreements/").concat(serviceAgreement, "/billing_portal?access_token=").concat(accessToken);
        const opts = {
          method: 'POST'
        };

        try {
          Ember.set(this, 'isLoadingBillingPortalLink', true);
          const billingPortalResponse = await (0, _fetch.default)(url, opts);
          const billingPortalPayload = await billingPortalResponse.json();

          if (billingPortalResponse.ok) {
            const {
              billing_portal_url: billingPortalUrl
            } = billingPortalPayload;
            window.location.assign(billingPortalUrl);
          } else {
            notify.error(billingPortalPayload.error_description || defaultError);
          }
        } catch (_ex) {
          notify.error(defaultError);
        } finally {
          Ember.set(this, 'isLoadingBillingPortalLink', false);
        }
      }

    }
  });

  _exports.default = _default;
});