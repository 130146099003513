define("pconsole/initializers/event-stream-wrapper", ["exports", "pconsole/config/environment", "particle-api-js", "@sentry/browser"], function (_exports, _environment, _particleApiJs, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = _exports.EventStreamer = void 0;
  const EventStreamer = Ember.Object.extend(Ember.Evented, {
    init() {
      this.particle = new _particleApiJs.default({
        baseUrl: _environment.default.APP.API_ENDPOINT
      });
    },

    setAccessToken(token) {
      this.accessToken = token;
    },

    startListening(opts) {
      this.stopListening();

      const streamPromise = this._openConnection(opts).then(stream => {
        stream.on('error', ex => Sentry.captureException(ex));
        return stream;
      }).catch(console.error);

      this.set('streamPromise', streamPromise);
    },

    stopListening() {
      const streamPromise = this.get('streamPromise');

      if (streamPromise) {
        streamPromise.then(stream => stream === null || stream === void 0 ? void 0 : stream.abort());
        this.off('event');
        this.set('streamPromise', undefined);
      }
    },

    _openConnection(opts) {
      return this.particle.getEventStream(Object.assign({}, opts, {
        auth: this.accessToken
      })).then(stream => {
        stream.on('event', data => {
          if (!data) {
            return;
          }

          this.trigger('event', data);
        });
        return stream;
      });
    }

  });
  _exports.EventStreamer = EventStreamer;

  function initialize(application) {
    const eventStreamer = EventStreamer.create();
    application.register('event-stream:main', eventStreamer, {
      instantiate: false
    }); // Inject the storage factory throughout the app

    application.inject('route', 'eventStream', 'event-stream:main');
    application.inject('controller', 'eventStream', 'event-stream:main');
    application.inject('component', 'eventStream', 'event-stream:main');
    application.inject('adapter', 'eventStream', 'event-stream:main');
  }

  var _default = {
    name: 'event-stream',
    initialize: initialize
  };
  _exports.default = _default;
});