define("pconsole/routes/product/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      this.store.unloadAll('client');
    },

    model() {
      return Ember.RSVP.hash({
        client: this.store.findAll('client'),
        product: this.modelFor('product').product
      });
    },

    setupController(controller, model) {
      controller.set('model', model.client);
      controller.set('product', model.product);
    }

  });

  _exports.default = _default;
});