define("pconsole/adapters/device", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // TODO: namespace isn't intended to be used this way; it's better define associations with
    // links in the serializer
    namespace: Ember.computed('routeData.product', 'routeData.network', function () {
      const isProduct = this.get('routeData.hasProduct');
      const network = this.get('routeData.network');

      if (isProduct) {
        // HIT THE PRODUCT DEVICE ENDPOINT
        return 'v1/products/' + this.get('routeData.product');
      } else if (network) {
        // HIT THE NETWORK DEVICE ENDPOINT
        return 'v1/networks/' + network;
      } else {
        // HIT THE NORMAL DEVICE ENDPOINT
        return 'v1';
      }
    }),
    singleDeviceNamespace: Ember.computed('routeData.product', function () {
      const product = this.get('routeData.product');

      if (product) {
        return 'v1/products/' + product;
      } else {
        return 'v1';
      }
    }),

    urlForFindRecord(id) {
      return [this.get('host'), this.get('singleDeviceNamespace'), 'devices', id].join('/');
    }

  });

  _exports.default = _default;
});