define("pconsole/routes/product/firmware", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        firmwares: this.store.findAll('product-firmware'),
        deviceGroups: this.store.findAll('group'),
        product: this.modelFor('product').product
      });
    },

    activate() {
      const firmwareReleaseController = this.controllerFor('product.firmware.release');
      firmwareReleaseController.set('firmwareRouteActive', true);
    },

    deactivate() {
      const firmwareReleaseController = this.controllerFor('product.firmware.release');
      firmwareReleaseController.set('firmwareRouteActive', false);
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('product', model.product);
    },

    actions: {
      refreshModel() {
        //Refresh the model on the route
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});