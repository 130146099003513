define("pconsole/templates/partials/integration/product-integrations-separate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VwaUj0Iu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"isEditing\"]],[20,[\"integration\",\"isMultiProduct\"]]],null]],null,{\"statements\":[[0,\"  \"],[4,\"component\",[\"toast\"],[[\"type\",\"__ANGLE_ATTRS__\"],[\"info\",[25,\"hash\",null,[[\"class\"],[\"integration-toast integration-toast--info\"]]]]],{\"statements\":[[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"Edit and separate integration\"],[8],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"\\n      In order to separate this integration from the other products, at least one field (such as name) must be changed. Separating an integration that is executing for multiple products will create a distinct integration specifically for \"],[6,\"b\"],[7],[1,[20,[\"integration\",\"product\",\"name\"]],false],[8],[0,\".\\n    \"],[8],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/integration/product-integrations-separate.hbs"
    }
  });

  _exports.default = _default;
});