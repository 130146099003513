define("pconsole/templates/components/usage-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u0OeyPtE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"markShown\"],null]]],{\"statements\":[[0,\"  \"],[1,[25,\"component\",[[20,[\"alert\",\"componentName\"]]],[[\"alert\",\"organizationName\",\"organizationSlug\"],[[20,[\"alert\"]],[20,[\"organizationName\"]],[20,[\"organizationSlug\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/usage-alert.hbs"
    }
  });

  _exports.default = _default;
});