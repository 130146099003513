define("pconsole/routes/product/map", ["exports", "pconsole/config/environment", "@sentry/browser"], function (_exports, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function sampleLiveEventTiming(segment, eventTime, loc, coreId, productId) {
    // Sample the events
    const sample = _environment.default.APP.LOCATION_SAMPLE_PERCENT;

    if (sample && Math.random() > sample) {
      return;
    }

    segment.trackEvent('location received', {
      gps_captured_at: loc && loc.time,
      os_captured_at: eventTime,
      console_captured_at: Math.round(new Date() / 1000)
    }, {
      context: {
        device_id: coreId,
        product_id: productId
      }
    });
  }

  var _default = Ember.Route.extend({
    queryParams: {
      area: {
        refreshModel: true
      },
      dates: {
        refreshModel: true
      },
      groups: {
        refreshModel: true
      },
      id: {
        refreshModel: true
      },
      name: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      }
    },
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    segment: Ember.inject.service(),

    async afterModel(model) {
      // Extract meta
      model.meta = model.devices.meta; // Load groups association

      await model.devices.map(d => d.hasMany('groups').load());
    },

    model({
      area,
      dates,
      id: device_id,
      groups,
      name: device_name,
      page,
      perPage: per_page
    }) {
      let swLng, swLat, neLng, neLat;

      if (area && area.includes(',')) {
        [swLat, swLng, neLat, neLng] = area.split(',');
      }

      return Ember.RSVP.hash({
        devices: this.store.query('device-location', {
          device_id,
          device_name,
          date_range: dates && dates.join(','),
          groups,
          page,
          per_page,
          rect_bl: swLat && swLng && "".concat(swLat, ",").concat(swLng),
          rect_tr: neLat && neLng && "".concat(neLat, ",").concat(neLng)
        }),
        product: this.modelFor('product').product,
        hasDevices: this.store.query('device', {}).then(d => d.meta.total_records > 0),
        groupOptions: this.store.findAll('group').then(groupOptions => groupOptions.toArray().map(g => g.toJSON({
          includeId: true
        })))
      });
    },

    resetController(controller, isExiting, transition) {
      this._super(...arguments);

      Ember.set(controller, 'showAreaSearchButton', false);

      if (isExiting) {
        Ember.setProperties(controller, {
          area: '',
          bounds: null,
          dates: null,
          fitBounds: true,
          groups: '',
          id: '',
          location: '',
          name: '',
          page: 1,
          perPage: 20
        });
        Ember.get(controller, 'eventStream').stopListening();
      } // When no bounds are given and a search is performed, fit bounds


      if (!Ember.get(controller, 'bounds') && transition && transition.targetName === 'product.map.index') {
        Ember.set(controller, 'fitBounds', true);
      }
    },

    setupController(controller, model) {
      this._super(...arguments);

      const {
        area,
        dates
      } = this.paramsFor(this.routeName);
      let end, start, parsedDates;
      Ember.set(controller, 'meta', model.meta); // Parse dates param into date objects

      if (dates) {
        parsedDates = dates.map(d => new Date(d));
        [start, end] = parsedDates;
        Ember.set(controller, 'dates', parsedDates);
      } // Parse area bounds


      if (area && area.includes(',')) {
        const [swLat, swLng, neLat, neLng] = area.split(',');
        Ember.set(controller, 'fitBounds', false);
        Ember.set(controller, 'bounds', [[swLng, swLat], [neLng, neLat]]);
      }

      const product = this.modelFor('product').product.id; // eventStream is injected by the event-stream-wrapper initializer

      const eventStream = Ember.get(this, 'eventStream'); // Set up event stream

      eventStream.startListening({
        name: 'loc',
        product
      });
      eventStream.on('event', event => {
        var _loc;

        const {
          data,
          coreid: coreId
        } = event;
        const devices = Ember.get(controller, 'model.devices');
        const deviceLocation = devices.findBy('id', coreId);
        const deviceUpdates = Ember.get(controller, 'deviceUpdates');
        let loc, time;

        try {
          ({
            loc,
            time
          } = JSON.parse(data)); // Metrics

          sampleLiveEventTiming(this.get('segment'), time, loc, coreId, product);
        } catch (ex) {
          Sentry.captureException(ex);
          return;
        }

        if (!((_loc = loc) === null || _loc === void 0 ? void 0 : _loc.lck)) {
          // Toss out updates with no lock
          return;
        }

        const timestamp = new Date(loc.time * 1000);
        const timestampISO = timestamp.toISOString();
        const isInSearch = deviceLocation && (!start && !end || start <= timestamp && timestamp <= end);

        if (isInSearch) {
          const deviceLocationPayload = deviceLocation.serialize({
            includeId: true
          });

          if (!deviceLocationPayload.geometry) {
            return;
          }

          const {
            alt,
            lat,
            lon
          } = loc,
                properties = _objectWithoutProperties(loc, ["alt", "lat", "lon"]);

          delete properties.time;
          const coord = [lon, lat];

          if (alt) {
            coord.push(alt);
          }

          if (deviceLocationPayload.geometry.type === 'LineString') {
            // Properties is not populated for search
            if (!deviceLocationPayload.properties) {
              deviceLocationPayload.properties = Array(deviceLocationPayload.timestamps.length);
            }

            deviceLocationPayload.geometry.coordinates.push(coord);
            deviceLocationPayload.properties.push(properties);
            deviceLocationPayload.timestamps.push(timestampISO);
          } else {
            Ember.set(deviceLocationPayload, 'geometry.coordinates', coord);
            Ember.set(deviceLocationPayload, 'properties', [properties]);
            Ember.set(deviceLocationPayload, 'timestamps', [timestampISO]);
          }

          deviceLocationPayload.last_heard = timestampISO;
          this.store.pushPayload({
            'device-location': deviceLocationPayload
          }); // Ember Data doesn't trigger didUpdate when data is pushed into the store

          deviceLocation.trigger('didUpdate'); // Trigger a device update animation

          Ember.set(controller, 'deviceUpdates', [...deviceUpdates, coreId]);
          setTimeout(() => Ember.set(controller, 'deviceUpdates', [...deviceUpdates.slice(1)]), 1500);
        }
      });
    },

    actions: {
      didTransition() {
        const {
          product
        } = this.modelFor('product');

        if (!Ember.get(product, 'settings.location.opt_in')) {
          const optInURL = this.router.generate('product.settings', Ember.get(product, 'slug'));
          const message = Ember.get(this, 'notify').warning({
            html: "You must <a href=\"".concat(optInURL, "\">opt in to location storage</a> to see devices on the map."),
            closeAfter: null
          });
          Ember.set(this.controller, 'optInMessage', message);
        }
      },

      loading(transition
      /*, route*/
      ) {
        const controller = this.controllerFor('product.map');
        Ember.set(controller, 'isLoading', true);
        transition.finally(() => {
          Ember.set(controller, 'isLoading', false);
        }); // Prevent transition into loading substate

        return false;
      },

      willTransition() {
        const optInMessage = Ember.get(this.controller, 'optInMessage');

        if (optInMessage) {
          Ember.set(optInMessage, 'visible', false);
        }
      }

    }
  });

  _exports.default = _default;
});