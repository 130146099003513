define("pconsole/mixins/routes/integration-gallery-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationGalleryRoute = void 0;
  const integrationGalleryRoute = {
    templateName: 'user.integrations.gallery',
    controllerName: 'user.integrations.gallery',
    breadCrumb: {
      title: 'New Integration'
    },

    setupController(controller) {
      this._super(...arguments);

      Ember.set(controller, 'parentEntity', Ember.get(this, 'parentEntity'));
    },

    deactivate() {
      Ember.set(this, 'controller.selectTemplate', null);
      Ember.set(this, 'controller.filterValue', null);
      Ember.set(this, 'controller.activeCategory', null);
    }

  };
  _exports.integrationGalleryRoute = integrationGalleryRoute;
});