define("pconsole/components/fleet-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fleet-modal'],

    /**
     * Whether to include a modal-backdrop element. Alternatively, specify
     * 'static' for a backdrop that doesn't close the modal on click.
     * @type {Boolean|String}
     */
    backdrop: true,

    /**
     * Action to call on close. String form is deprecated.
     * @type {Function|String}
     * @returns {void}
     */
    close() {},

    /**
     * Action to call when the modal is opened
     * @type {Function}
     * @returns {void}
     */
    insertAction() {},

    /**
     * Whether to close the modal when escape key is pressed
     * @type {Boolean}
     */
    keyboard: true,

    didInsertElement() {
      const $modal = this.$('.modal');
      this.get('insertAction')();
      $modal.on('hidden.bs.modal', () => {
        // TODO: use closure actions instead
        this.sendAction('close');
      });
      Ember.run.schedule('afterRender', () => {
        $modal.modal('show');
      });
    },

    willDestroyElement() {
      this.$('.modal').off('hidden.bs.modal');
      Ember.$('.modal-backdrop').remove();
    },

    actions: {
      close() {
        this.$('.modal').modal('hide');
      }

    }
  });

  _exports.default = _default;
});