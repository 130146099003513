define("pconsole/templates/product/devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/BdOsXeb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"showDeviceTable\"]]],null,{\"statements\":[[0,\"  \"],[12,\"partials/device-table\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices.hbs"
    }
  });

  _exports.default = _default;
});