define("pconsole/components/product-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui field'],

    /**
     * Unique component id
     * @type {string}
     */
    inputId: null,

    /**
     * Label to render on the input
     * @type {string}
     */
    label: 'Select a product',

    /**
     * Array of products selected
     * @type {Product[]}
     */
    selectedProducts: null,

    /**
     * Array of all products
     * @type {Product[]}
     */
    products: null,

    /**
     * Given action to call when selected products change
     * @type {function}
     * @returns {void}
     */
    onChange() {},

    productNames: Ember.computed.sort('products', function (a, b) {
      const aName = Ember.get(a, 'name');
      const bName = Ember.get(b, 'name');

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      }

      return 0;
    })
  });

  _exports.default = _default;
});