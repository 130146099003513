define("pconsole/templates/components/form-submission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVBZY2jj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"cancel\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[25,\"form-submission-button\",null,[[\"action\",\"text\",\"type\",\"class\"],[[20,[\"cancelAction\"]],[20,[\"cancelText\"]],\"reset\",\"ui button outline secondary\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"delete\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[25,\"form-submission-button\",null,[[\"action\",\"disabled\",\"text\",\"class\"],[[20,[\"deleteAction\"]],[20,[\"deleteDisabled\"]],[20,[\"deleteText\"]],\"ui button outline secondary\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"save\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[25,\"form-submission-button\",null,[[\"action\",\"disabled\",\"text\",\"type\",\"class\"],[[20,[\"saveAction\"]],[20,[\"disabled\"]],[20,[\"saveText\"]],\"submit\",\"ui button outline primary\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/form-submission.hbs"
    }
  });

  _exports.default = _default;
});