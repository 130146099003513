define("pconsole/components/unhandled-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['unhandled-error'],

    /**
     * Given error
     * @type {Object}
     */
    error: null
  });

  _exports.default = _default;
});