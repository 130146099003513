define("pconsole/components/asset-tracker-map/line-pattern-layer", ["exports", "react", "prop-types", "react-mapbox-gl"], function (_exports, _react, _propTypes, _reactMapboxGl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const LinePatternLayer = ({
    sourceId
  }) => _react.default.createElement(_reactMapboxGl.Layer, {
    type: 'line',
    sourceId: sourceId,
    images: ['chev', chevronImage],
    paint: {
      'line-width': ['case', ['has', 'isHighlighted'], 12, 8],
      'line-pattern': 'chev'
    }
  });

  LinePatternLayer.propTypes = {
    sourceId: _propTypes.default.string.isRequired
  };
  const chevronImage = new Image();
  chevronImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAtklEQVQ4jc2TPw5BQRDGx5+IVuMSeq2ISsQBxCE4wevVlFSugjytG9AoXEDx+MkWL8aIZ6fiS75idzO/fLM7K/8jIHcfSIG22vueE+gCG546ADUPYMa7Eg8geGEQV6DlAZSApYHsgEosIIesDGTiAQRXgbMCXD7VlQuY+izzAhIRaar1vDC+vLYwBu4q/hGoxwJGQGYucBj7jFPgZorXnkHqAVtVfAIa3kkMHgB7oOP6TL+RiDwAtelPa8+xdHIAAAAASUVORK5CYII=';
  var _default = LinePatternLayer;
  _exports.default = _default;
});