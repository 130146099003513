define("pconsole/controllers/product/sims/reactivate", ["exports", "pconsole/mixins/sim/reactivate", "pconsole/mixins/permissions"], function (_exports, _reactivate, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_permissions.default, _reactivate.default, {
    scope: 'sims:update',
    sim: Ember.computed.alias('model.sim'),
    nextBillDate: Ember.computed('model.subscription', function () {
      return this.get('model.subscription.currentMonthlyPeriodEnd');
    })
  });

  _exports.default = _default;
});