define("pconsole/helpers/is-defined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDefined = isDefined;
  _exports.default = void 0;

  function isDefined(params) {
    return !!(params[0] || params[0] === 0);
  }

  var _default = Ember.Helper.helper(isDefined);

  _exports.default = _default;
});