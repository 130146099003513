define("pconsole/components/event-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['event.paused:paused', 'event.type', 'event.expandedEvent:expanded-event', 'rawVisible'],
    classNames: ['event-wrapper'],
    deviceId: Ember.computed.alias('event.coreId'),
    rawVisible: false,

    click() {
      this.toggleProperty('rawVisible');
    },

    isApiEvent: Ember.computed('event.coreId', function () {
      const deviceId = this.get('deviceId');
      return deviceId === 'api';
    })
  });

  _exports.default = _default;
});