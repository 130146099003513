define("pconsole/components/eventlogs/event-data.react", ["exports", "react", "pconsole/components/eventlogs/events-util", "pconsole/components/eventlogs/helper-message.react", "pconsole/components/eventlogs/prettify-raw-buttons.react", "react-json-view"], function (_exports, _react, _eventsUtil, _helperMessage, _prettifyRawButtons, _reactJsonView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const RenderData = ({
    jsonData,
    prettifyJSON,
    data
  }) => {
    if (data.startsWith('data:image/')) {
      return _react.default.createElement('img', {
        src: data
      });
    }

    if (data.startsWith('data:audio/')) {
      return _react.default.createElement('audio', {
        controls: true
      }, _react.default.createElement('source', {
        src: data
      }));
    }

    if (jsonData && prettifyJSON) {
      return _react.default.createElement(_reactJsonView.default, {
        src: jsonData,
        name: null,
        displayObjectSize: false,
        displayDataTypes: false
      });
    }

    return data;
  };

  const EventData = ({
    events,
    selectedEvent,
    prettifyJSON,
    showPrettifiedJSON,
    showRawJSON,
    successNotify
  }) => {
    const data = selectedEvent && selectedEvent.data;

    const jsonData = _eventsUtil.default.getDataAsJSON(data);

    const renderedData = jsonData && prettifyJSON ? _react.default.createElement(_reactJsonView.default, {
      src: jsonData,
      name: null,
      displayObjectSize: false,
      displayDataTypes: false
    }) : data;

    const rendered = data && _react.default.createElement('div', {
      className: '_eventlogsnew__content__right__data'
    }, _react.default.createElement(_prettifyRawButtons.default, {
      isJSON: jsonData,
      prettifyJSON: prettifyJSON,
      data: data,
      onPrettifyClick: showPrettifiedJSON,
      onRawClick: showRawJSON,
      successNotify: successNotify
    }), _react.default.createElement(RenderData, {
      jsonData: jsonData,
      prettifyJSON: prettifyJSON,
      data: data
    }));

    return _react.default.createElement('div', {
      className: "_eventlogsnew__content__right ".concat(jsonData || data ? 'padded' : 'centered')
    }, _react.default.createElement(_helperMessage.default, {
      events: events,
      selectedEvent: selectedEvent
    }), rendered);
  };

  var _default = EventData;
  _exports.default = _default;
});