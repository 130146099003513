define("pconsole/adapters/org-user", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const orgUserAdapter = _application.default.extend({
    namespace: Ember.computed('routeData.product', function () {
      return 'v1/products/' + this.get('routeData.product');
    }),

    pathForType() {
      return 'team';
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      // Override the default Ember data behavior to just send an object with a role, like this:
      // { role: 000000000000000004 }
      const data = {
        role: snapshot.record.get('role.id'),
        username: snapshot.record.get('id')
      };
      const url = this.buildURL(snapshot.modelName, snapshot.record.get('id'), snapshot);
      return this.ajax(url, 'PUT', {
        data: data
      });
    }
  });

  var _default = orgUserAdapter;
  _exports.default = _default;
});