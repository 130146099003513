define("pconsole/templates/components/logic-runs-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XPhB63Pt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1],[0,\"\\n\"],[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[6,\"i\"],[9,\"class\",\"ion-loading-c\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"History / Last 30 days\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"isEmpty\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"webhook-attr-desc\"],[7],[0,\"This Logic Function has not run in the last 30 days.\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"webhook-history-graph\",null,[[\"baseWidth\",\"counters\",\"errorKey\",\"sleepKey\",\"successKey\"],[[20,[\"baseWidth\"]],[20,[\"counters\"]],[20,[\"errorKey\"]],[20,[\"sleepKey\"]],[20,[\"successKey\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/logic-runs-graph.hbs"
    }
  });

  _exports.default = _default;
});