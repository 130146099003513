define("pconsole/utils/hash-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(str) {
    let hash = 0,
        i,
        chr;

    if (str.length === 0) {
      return hash;
    }

    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      /* eslint-disable no-bitwise */

      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer

      /* eslint-enable */
    }

    return hash;
  }
});