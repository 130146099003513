define("pconsole/templates/partials/deactivate-sim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F+6jk3m+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Deactivate SIM Card\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure that you want to deactivate data service of the  SIM with an ICCID ending in \"],[6,\"strong\"],[7],[1,[25,\"last-4\",[[20,[\"sim\",\"iccid\"]]],null],false],[8],[0,\"?\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"This will immediately stop any device using this SIM from sending or receiving data via a cellular network, and will disrupt expected device behavior until an active SIM is used.\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deactivateSim\"]],[7],[0,\"Deactivate SIM\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"Don't deactivate\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"helper-message\"],[7],[0,\"Deactivation may take up to 60 seconds. Please be patient.\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/deactivate-sim.hbs"
    }
  });

  _exports.default = _default;
});