define("pconsole/mixins/sim/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    apiAjax: Ember.inject.service(),
    actions: {
      closeModal() {
        const isProduct = this.get('routeData.product');

        if (isProduct) {
          this.transitionToRoute('product.sims');
        } else {
          this.transitionToRoute('user.sims');
        }
      }

    },

    async runForAtLeast(ms = 250, promise) {
      const start = Date.now();
      const data = await promise;
      const end = Date.now();
      const duration = end - start;

      if (duration < ms) {
        await new Promise(resolve => setTimeout(() => resolve(), ms - duration));
      }

      return data;
    },

    updateSim(data, successMessage, friendlyAction) {
      const sim = this.get('sim');
      const product = this.get('routeData.product');
      const iccid = sim.get('iccid');
      const url = product ? "/v1/products/".concat(product, "/sims/").concat(iccid) : "/v1/sims/".concat(iccid);
      const delayNotice = this.get('notify').info('SIM actions may take several minutes. Please be patient.', {
        closeAfter: null
      });
      Ember.$('.loading').show();

      const sendRequest = () => {
        return this.get('apiAjax').request({
          url: url,
          type: 'PUT',
          data: JSON.stringify(data),
          contentType: 'application/json; charset=utf-8'
        }).then((response, textStatus, xhr) => {
          return Promise.resolve([response, textStatus, xhr]);
        });
      };

      return this.runForAtLeast(350, sendRequest()).then(([response,, xhr]) => {
        const isProduct = this.get('routeData.product');
        const transitionRoute = isProduct ? 'product.sims' : 'user.sims';
        return this.transitionToRoute(transitionRoute).then(() => {
          this.send('refreshModel');
          Ember.$('.loading').hide();
          delayNotice.set('visible', false);

          if (xhr.status === 202) {
            this.get('notify').info("".concat(friendlyAction, " your SIM might take some time."), {
              closeAfter: 10000
            });
          } else {
            this.get('notify').success(successMessage);
            return response;
          }
        });
      }).catch(response => {
        Ember.$('.loading').hide();
        delayNotice.set('visible', false);
        sim.transitionTo('loaded.saved');

        if (response.status === 504 || response.status === 408) {
          this.get('notify').warning("".concat(friendlyAction, " your SIM is in progress."), {
            closeAfter: 10000
          });
        } else {
          this.get('notify').error('There was a problem acting on your SIM: ' + response.responseJSON.error);
        }

        return Ember.RSVP.reject(response);
      });
    }

  });

  _exports.default = _default;
});