define("pconsole/routes/product/devices", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductDeviceRoute = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,
    queryParams: {
      deviceId: {
        refreshModel: true,
        replace: true
      },
      deviceName: {
        refreshModel: true,
        replace: true
      },
      serialNumber: {
        refreshModel: true,
        replace: true
      },
      groups: {
        refreshModel: true,
        replace: true
      },
      customerEmail: {
        refreshModel: true,
        replace: true
      },
      sortAttr: {
        refreshModel: true,
        replace: true
      },
      sortDir: {
        refreshModel: true,
        replace: true
      }
    },
    breadCrumb: {
      title: 'Devices'
    },

    model(params) {
      return Ember.RSVP.hash({
        model: this.findPaged('device', params),
        firmwares: this.store.findAll('product-firmware'),
        deviceGroups: this.store.findAll('group'),
        product: this.modelFor('product').product
      });
    },

    afterModel(model, transition) {
      // We need to manually associate the groups with the product
      // currently being viewed (and vice-versa). This is because the association
      // is not made by Ember when the raw objects are returned from
      // the API.
      const groups = model.deviceGroups;
      const product = model.product;
      groups.setEach('product', product);
      product.set('groups', groups);

      this._super(model, transition);
    },

    setupController(controller, model) {
      controller.setProperties(model);
      controller.subscribe();
    },

    activate() {
      const productDevicesController = this.controllerFor('product.devices');
      productDevicesController.set('deviceRouteActive', true);
    },

    deactivate() {
      this.get('eventStream').stopListening();
      const productDevicesController = this.controllerFor('product.devices');
      productDevicesController.set('deviceRouteActive', false);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.resetParams();
        controller.unsubscribe();
      }
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });
  var _default = ProductDeviceRoute;
  _exports.default = _default;
});