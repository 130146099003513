define("pconsole/templates/partials/home/links-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PD37J416",
    "block": "{\"symbols\":[\"link\"],\"statements\":[[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"More from Particle\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"external-links\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"links\"]]],null,{\"statements\":[[4,\"if\",[[19,1,[\"internal\"]]],null,{\"statements\":[[4,\"link-to\",[[19,1,[\"href\"]]],[[\"class\"],[\"ui button outline secondary\"]],{\"statements\":[[0,\"        \"],[1,[19,1,[\"label\"]],false],[0,\" \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-a\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[10,\"href\",[19,1,[\"href\"]],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n        \"],[1,[19,1,[\"label\"]],false],[0,\" \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-a\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/home/links-list.hbs"
    }
  });

  _exports.default = _default;
});