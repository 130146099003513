define("pconsole/components/event-logs-view-container", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    device: null,
    deviceId: Ember.computed('device', function () {
      const device = this.get('device');
      return device ? device.get('id') : null;
    }),
    product: Ember.computed.alias('routeData.product'),
    fullProduct: null,
    showPublishEvent: false,
    authToken: Ember.computed('session', function () {
      return this.get('session.data.authenticated.access_token');
    }),
    streamURL: Ember.computed('session', function () {
      const session = this.get('session');
      const accessToken = session.get('data.authenticated.access_token');
      const apiEndpoint = _environment.default.APP.API_ENDPOINT;
      const product = this.get('product');
      const device = this.get('deviceId');
      let url = apiEndpoint;

      if (product && device) {
        url += "/v1/products/".concat(product, "/devices/").concat(device, "/events");
      } else if (device) {
        url += "/v1/devices/".concat(device, "/events");
      } else if (product) {
        url += "/v1/products/".concat(product, "/events");
      } else {
        url += "/v1/devices/events";
      }

      return "".concat(url, "?access_token=").concat(accessToken);
    }),
    hidePublishEvent: Ember.computed('', function () {
      return Ember.run.bind(this, () => {
        this.set('showPublishEvent', false);
      });
    }),
    successNotify: Ember.computed('', function () {
      return Ember.run.bind(this, () => {
        this.get('notify').success('Event data copied to clipboard!');
      });
    }),
    actions: {
      toggleShowPublishEvent() {
        this.set('showPublishEvent', !this.get('showPublishEvent'));
      },

      hidePublishEvent() {
        this.set('showPublishEvent', false);
      }

    }
  });

  _exports.default = _default;
});