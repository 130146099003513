define("pconsole/app", ["exports", "pconsole/resolver", "ember-load-initializers", "pconsole/config/environment", "pconsole/reopens/link-component"], function (_exports, _resolver, _emberLoadInitializers, _environment, _linkComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-line no-unused-vars
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});