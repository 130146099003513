define("pconsole/controllers/product/devices/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    show2020Billing: false,
    application: Ember.inject.controller(),
    productDevicesController: Ember.inject.controller('product/devices'),
    actions: {
      closeModal() {
        Ember.run.later(this, () => {
          if (this.get('productDevicesController.deviceRouteActive')) {
            this.transitionToRoute('product.devices');
          }
        }, 50);
      }

    }
  });

  _exports.default = _default;
});