define("pconsole/templates/organization/ledger/show/delete-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ijQwPdev",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Delete Ledger instance\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"ledgerDefinition\",\"isOwnerScope\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[7],[0,\"Deleting the Ledger instance will clear the data in the Ledger \"],[1,[20,[\"ledgerDefinition\",\"name\"]],false],[0,\".\"],[8],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"Are you sure you want to delete the data for \"],[6,\"b\"],[7],[0,\"Ledger \"],[1,[20,[\"ledgerDefinition\",\"name\"]],false],[8],[0,\"?\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"p\"],[7],[0,\"Deleting a Ledger instance will clear the data in the Ledger \"],[1,[20,[\"ledgerDefinition\",\"name\"]],false],[0,\" for that \"],[1,[20,[\"ledgerDefinition\",\"scope\"]],false],[0,\".\"],[8],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"Are you sure you want to delete the data for \"],[6,\"b\"],[7],[1,[20,[\"ledgerDefinition\",\"scope\"]],false],[0,\" \"],[1,[20,[\"ledgerInstance\",\"id\"]],false],[8],[0,\"?\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, cancel\"],[8],[0,\"\\n    \"],[6,\"button\"],[10,\"disabled\",[25,\"not\",[[25,\"check-scope\",[\"ledger.instances:remove\",null,[20,[\"store\"]]],null]],null],null],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deleteInstance\"]],[7],[0,\"\\n      Yes, delete\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/ledger/show/delete-instance.hbs"
    }
  });

  _exports.default = _default;
});