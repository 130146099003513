define("pconsole/components/logic-trigger-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['logic-trigger-type'],
    details: Ember.computed('triggers', function () {
      const triggers = Ember.get(this, 'triggers').toArray();

      if (triggers.length === 0) {
        return null;
      }

      const type = triggers[0].get('type');
      return {
        type,
        icon: triggers[0].get('typeIcon'),
        text: triggers[0].get('typeDisplay')
      };
    })
  });

  _exports.default = _default;
});