define("pconsole/controllers/product/sims/import/upload", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "pconsole/mixins/sim/import"], function (_exports, _form, _import) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _form.default, _import.default, {
    apiAjax: Ember.inject.service(),
    product: Ember.computed.alias('model.product'),
    newSims: Ember.computed.alias('model.newSims'),
    validations: {
      'newSims.file': {
        presence: true
      }
    },
    notify: Ember.inject.service(),

    save() {
      return this.importAndActivate({
        country: 'US',
        simFile: this.get('newSims'),
        product: this.get('routeData.product')
      });
    },

    actions: {
      backToFileUpload() {
        this.set('newSims.file', null);
      }

    }
  });

  _exports.default = _default;
});