define("pconsole/components/react-component", ["exports", "ember-cli-react/components/react-component"], function (_exports, _reactComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _reactComponent.default;
    }
  });
});