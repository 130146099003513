define("pconsole/components/device-signal", ["exports", "pconsole/mixins/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, {
    attributeBindings: ['disabled', 'title', 'type'],
    classNameBindings: ['isLoading:device-signal--loading'],
    classNames: ['device-signal', 'edit-btn', 'ui button outline primary'],
    device: null,
    isLoading: false,
    isSignaling: false,
    productId: null,
    scope: 'devices:update',
    tagName: 'button',
    title: 'Animate LED on device. Press again to stop.',
    type: 'button',
    apiAjax: Ember.inject.service(),
    deviceId: Ember.computed.readOnly('device.id'),
    notify: Ember.inject.service(),
    devicePlatform: Ember.computed('device', function () {
      return (this.get('device') ? this.get('device.platform.name') : '').toLowerCase();
    }),
    deviceUrl: Ember.computed('device', function () {
      const deviceId = this.get('deviceId');
      const product = this.get('routeData.product');

      if (!deviceId) {
        return;
      }

      return "/v1/".concat(product ? 'products/' + product + '/' : '', "devices/").concat(deviceId);
    }),
    signalingStatus: Ember.computed('isSignaling', function () {
      return this.get('isSignaling') ? 'Stop Signaling' : 'Signal';
    }),

    async click() {
      this.set('isLoading', true);

      try {
        const {
          signaling
        } = await this.get('apiAjax').putRequest({
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({
            signal: Number(!this.get('isSignaling'))
          }),
          dataType: 'json',
          timeout: 15000,
          url: this.get('deviceUrl')
        });
        this.set('isSignaling', signaling);
      } catch (_ex) {
        const message = {
          html: "This device was unreachable by the Particle cloud. The device may be powered off, or may be having trouble connecting to the Particle Cloud.<br/><br/>Please check out our documentation on <a href=\"https://docs.particle.io/support/troubleshooting/common-issues/".concat(this.get('devicePlatform'), "\" target=\"_blank\">common issues</a> for reasons why this device may be offline."),
          closeAfter: 5000
        };
        this.get('notify').alert(message);
      } finally {
        this.set('isLoading', false);
      }
    }

  });

  _exports.default = _default;
});