define("pconsole/routes/product/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductTeamRoute = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        roles: this.store.findAll('role'),
        // Using the fact that query() returns a snapshot instead of a 'live'
        // view into the Ember data store. If we used findAll() here, then the team
        // members returned by the org team query below (in the afterModel hook) would
        // incorrectly populate the product team with org team members
        productTeam: this.store.query('team', {}),
        product: this.modelFor('product').product
      });
    },

    afterModel(model, transition) {
      const product = model.product; // TODO: Redo this logic once we have the "owner" object available on the product
      // Right now, every product will have an org id (shadow orgs).

      const orgId = product.belongsTo('organization').id();

      if (orgId) {
        return this.store.query('team', {
          orgId: orgId
        }).then(orgTeam => {
          model.orgTeam = orgTeam;
          return this._super(model, transition);
        });
      }
    }

  });
  var _default = ProductTeamRoute;
  _exports.default = _default;
});