define("pconsole/templates/organization/logic-functions/details/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VWDrxV42",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\",\"class\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null],\"logic-function-delete\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Delete Logic Function\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you want to delete the Logic Function \"],[6,\"strong\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"?\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, cancel\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deleteLogiFunction\"]],[7],[0,\"Yes, delete Logic Function\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/logic-functions/details/delete.hbs"
    }
  });

  _exports.default = _default;
});