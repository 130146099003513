define("pconsole/components/color-swatch", ["exports", "react", "prop-types"], function (_exports, _react, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ColorSwatch = ({
    color,
    width = 10,
    height = 10
  }) => {
    const canvasRef = (0, _react.useRef)();
    (0, _react.useEffect)(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, width, height);
    });
    return _react.default.createElement('canvas', {
      ref: canvasRef,
      width: width,
      height: height
    });
  };

  ColorSwatch.propTypes = {
    color: _propTypes.default.string.isRequired,
    height: _propTypes.default.string,
    width: _propTypes.default.string
  };
  var _default = ColorSwatch;
  _exports.default = _default;
});