define("pconsole/models/product-firmware", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    version: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    uploadedOn: _emberData.default.attr('date'),
    size: _emberData.default.attr('number'),
    binary: _emberData.default.attr('file'),
    deviceCount: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    immediate: _emberData.default.attr('boolean'),
    mandatory: _emberData.default.attr('boolean'),
    deviceOsVersion: _emberData.default.attr('string'),
    bundle: _emberData.default.attr(),
    assets: _emberData.default.attr(),
    product: _emberData.default.belongsTo('product', {
      async: false
    }),
    uploadedBy: _emberData.default.attr('string'),
    prettyTitle: Ember.computed('version', 'title', function () {
      return 'v' + this.get('version') + ' - ' + this.get('title');
    }),
    // Firmware releasing attributes
    productDefault: _emberData.default.attr('boolean'),
    groups: _emberData.default.hasMany('group'),
    firstReleasedAt: _emberData.default.attr('date'),
    knownApplicationId: _emberData.default.attr('string'),
    downloadName: Ember.computed('name', 'bundle.name', function () {
      return this.get('bundle.name') || this.get('name');
    }),
    downloadSize: Ember.computed('size', 'bundle.size', function () {
      return this.get('bundle.size') || this.get('size');
    })
  });

  _exports.default = _default;
});