define("pconsole/mixins/include-device-id-in-integration-response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    includeDeviceIDInIntegrationResponse: true,
    includeDeviceIDInIntegrationResponseObserver: Ember.observer('includeDeviceIDInIntegrationResponse', function () {
      const integration = this.get('integration');
      const deviceSpecificResponseTopic = '{{PARTICLE_DEVICE_ID}}/hook-response/{{PARTICLE_EVENT_NAME}}';

      if (this.get('includeDeviceIDInIntegrationResponse')) {
        integration.set('responseTopic', deviceSpecificResponseTopic);
      } else {
        integration.set('responseTopic', undefined);
      }
    })
  });

  _exports.default = _default;
});