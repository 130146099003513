define("pconsole/serializers/sim-usage-datapoint", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (hash) {
        // Set this ID as something that will be the same every time
        // it's fetched from the back-end so Ember does not duplicate
        // records in the store.
        hash.id = this.get('iccid') + hash.date.replace(/-/g, '');
        hash.sim_id = this.get('iccid');
      }

      return this._super(...arguments);
    },

    normalizeResponse(store, type, payload, id, requestType) {
      this.set('iccid', payload.iccid);
      const normalized = {
        'sim_usage_datapoints': payload.usage_by_day
      };
      return this._super(store, type, normalized, id, requestType);
    }

  });

  _exports.default = _default;
});