define("pconsole/templates/partials/integration/org-integration-editing-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EbM5rPd7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"isEditing\"]],[25,\"eq\",[[20,[\"parentEntity\"]],\"organization\"],null]],null]],null,{\"statements\":[[0,\"  \"],[4,\"component\",[\"toast\"],[[\"type\",\"__ANGLE_ATTRS__\"],[\"info\",[25,\"hash\",null,[[\"class\"],[[25,\"concat\",[[20,[\"class\"]],\" integration-toast integration-toast--info\"],null]]]]]],{\"statements\":[[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"Edit integration for all products\"],[8],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"\\n      Changes made here will apply to all products where this integration is being executed. If it is executed across multiple products and you want to adjust it for an individual product, navigate instead to that product’s integrations to separate it from the rest.\\n    \"],[8],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/integration/org-integration-editing-toast.hbs"
    }
  });

  _exports.default = _default;
});