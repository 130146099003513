define("pconsole/controllers/product/team/remove-team-member", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, {
    notify: Ember.inject.service(),
    productTeamController: Ember.inject.controller('product.team'),
    teamMember: Ember.computed.alias('model.teamMember'),
    product: Ember.computed.alias('model.product'),
    actions: {
      closeModal() {
        this.transitionToRoute('product.team');
      },

      deleteUser() {
        Ember.$('.loading').show();
        const teamMember = this.get('teamMember');
        const username = teamMember.get('username');
        teamMember.destroyRecord().then(() => {
          Ember.$('.loading').hide(); // We have to manually pull the removed  member from the product team
          // to keep the productTeam list up to date. See the note in routes/product/team.js
          // for more explanation.

          const teamController = this.get('productTeamController');
          teamController.get('productTeam').removeObject(teamMember);
          this.send('closeModal');
          this.get('notify').success("".concat(username, " has been successfully removed from the team"));
        }, response => {
          Ember.$('.loading').hide();
          const error = response.errors[0];
          const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
          this.get('notify').alert(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});