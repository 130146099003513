define("pconsole/adapters/integration", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('routeData.{product,org}', function () {
      const isProduct = this.get('routeData.hasProduct');
      const hasOrg = this.get('routeData.hasOrg');

      if (isProduct) {
        // HIT THE PRODUCT INTEGRATION ENDPOINT
        return 'v1/products/' + this.get('routeData.product');
      }

      if (hasOrg) {
        // HIT THE ORG INTEGRATION ENDPOINT
        return "v1/orgs/".concat(this.get('routeData.org'));
      } // HIT THE NORMAL INTEGRATION ENDPOINT


      return 'v1';
    }),

    pathForType() {
      return 'integrations';
    }

  });

  _exports.default = _default;
});