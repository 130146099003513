define("pconsole/serializers/team", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      role: {
        embedded: 'always'
      }
    },

    normalizeArrayResponse(store, type, payload) {
      const orgId = this.get('routeData.org');
      payload.team.forEach(user => {
        user.id = user.username; // TODO: Will likely need to come back here to fill in product team associations

        if (orgId) {
          user.organization = orgId;
        }
      });
      delete payload.ok;
      return this._super(store, type, payload);
    },

    normalizeSingleResponse(store, type, payload, id, requestType) {
      // The API changes its response depending on the type of request on a
      // team member. For instance, when inviting a team member, the team member
      // is nested in an 'invited' object. When updating a role, in an 'updated' object.
      // This method normalizes these different responses so Ember Data can understand it.
      const new_payload = {};

      if (requestType === 'deleteRecord') {
        new_payload.team = {
          id: id
        };
      } else if (requestType === 'createRecord') {
        new_payload.team = payload.invited;
        new_payload.team.id = payload.invited.username;
      }

      delete payload.ok;
      delete payload.invited;
      delete payload.removed;
      delete payload.updated;
      return this._super(store, type, new_payload, id, requestType);
    }

  });

  _exports.default = _default;
});