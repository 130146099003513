define("pconsole/templates/product/sims/import/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FyqqBCl9",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"import-file-wrapper\"],[7],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"To add new SIM cards to this product, upload a text file below. The file should contain a single-column list of ICCIDs, with one ICCID on each row.\"],[8],[0,\"\\n\"],[4,\"form-wrapper\",null,null,{\"statements\":[[0,\"    \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"type\",\"accept\"],[\"newSims.file\",\"Devices CSV\",\"file-upload\",\".txt\"]]],false],[0,\"\\n    \"],[1,[25,\"form-submission\",null,[[\"saveText\",\"cancel\",\"disabled\"],[\"Continue\",false,[20,[\"isInvalid\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/sims/import/upload.hbs"
    }
  });

  _exports.default = _default;
});