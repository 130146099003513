define("pconsole/components/eventlogs/event-table.react", ["exports", "react", "pconsole/components/eventlogs/blank-event.react", "pconsole/components/eventlogs/event.react", "pconsole/components/eventlogs/number-of-queued-events.react", "pconsole/components/eventlogs/event-list.react", "pconsole/components/eventlogs/events-util"], function (_exports, _react, _blankEvent, _event, _numberOfQueuedEvents, _eventList, _eventsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _this = void 0;

  const EventTable = ({
    events,
    renderedEvents,
    onClick,
    fields,
    unpause
  }) => {
    const rowRenderer = ({
      key,
      index,
      style
    }) => {
      const event = _eventsUtil.default.getFormattedEvent(renderedEvents[index]);

      return event.blank ? _react.default.createElement(_blankEvent.default, {
        key: key,
        fields: fields
      }) : _react.default.createElement(_event.default, {
        event: event,
        fields: fields,
        onClick: onClick,
        key: key,
        style: style
      });
    };

    const tableHeaders = fields.map(field => _react.default.createElement('th', {
      key: field.title
    }, field.title));
    return _react.default.createElement('table', {
      className: "_eventlogsnew__content__events"
    }, _react.default.createElement('thead', null, _react.default.createElement('tr', null, tableHeaders)), _react.default.createElement('tbody', null, _react.default.createElement(_numberOfQueuedEvents.default, {
      events: events,
      unpause: unpause
    }), _react.default.createElement(_eventList.default, {
      events: renderedEvents,
      rowRenderer: rowRenderer.bind(_this)
    })));
  };

  var _default = EventTable;
  _exports.default = _default;
});