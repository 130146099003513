define("pconsole/models/enterprise-resource", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const EnterpriseResource = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    resources: _emberData.default.attr()
  });

  EnterpriseResource.reopenClass({
    FIXTURES: [{
      id: 1,
      title: 'Guides to get you started',
      icon: 'guides',
      resources: [{
        text: 'Intro to Particle organizations',
        link: 'https://docs.particle.io/scaling/quick-start-guide/organizations'
      }, {
        text: 'Getting started with team access controls',
        link: 'https://docs.particle.io/getting-started/setup/accounts/'
      }, {
        text: 'How to program your first Particle fleet',
        link: 'https://docs.particle.io/getting-started/products/creating-a-product/'
      }, {
        text: 'Overview of manufacturing best practices',
        link: 'https://docs.particle.io/scaling/manufacturing/manufacturing-cellular/'
      }, {
        text: 'How to check your device / fleet health',
        link: 'https://docs.particle.io/getting-started/console/fleet-health/'
      }, {
        text: 'Billing and data consumption 101',
        link: 'https://docs.particle.io/scaling/quick-start-guide/billing/'
      }]
    }, {
      id: 2,
      title: 'Useful tools',
      icon: 'tools',
      resources: [{
        text: 'Regional device compatibility',
        subtext: 'Check which devices work where',
        link: 'https://docs.particle.io/reference/cellular/cellular-carriers/'
      }, {
        text: 'Particle device doctor',
        subtext: 'Troubleshoot your device issues',
        link: 'https://docs.particle.io/tools/doctor/'
      }, {
        text: 'Cloud debug tool',
        subtext: 'Collect debug logs from your devices',
        link: 'https://docs.particle.io/troubleshooting/connectivity/cloud-debug/'
      }]
    }, {
      id: 3,
      title: 'Inventory',
      icon: 'inventory',
      resources: [{
        text: 'Device sample request',
        subtext: 'Request an engineering sample',
        link: 'https://docs.particle.io/troubleshooting/troubleshooting/?p=11779868461851'
      }, {
        text: 'Returns and replacements',
        subtext: 'Return or exchange a device',
        link: 'https://docs.particle.io/scaling/quick-start-guide/returns/'
      }, {
        text: 'Particle wholesale store',
        subtext: 'Order your device accesories',
        link: 'https://wholesale.particle.io/'
      }, {
        text: 'Purchase order',
        subtext: 'How to format and submit a purchase order',
        link: 'https://docs.particle.io/po/'
      }]
    }]
  });
  var _default = EnterpriseResource;
  _exports.default = _default;
});