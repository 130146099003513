define("pconsole/templates/product/devices/delete-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MfzvzXAl",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[10,\"class\",[26,[\"title modal-title \",[25,\"if\",[[20,[\"chosenGroup\"]],\"\",\"no-border\"],null]]]],[7],[0,\"Delete Group\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"chosenGroup\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[7],[0,\"Are you sure you want to delete the group \"],[6,\"strong\"],[7],[1,[20,[\"chosenGroup\",\"name\"]],false],[8],[0,\"?\"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n      \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, Cancel\"],[8],[0,\"\\n      \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deleteGroup\"]],[7],[0,\"Yes, Delete Group\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"helper-message\"],[7],[0,\"This action may result in an OTA firmware update being delivered on reconnect for devices based on their updated group memberships\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"centered border margin-bottom-30\"],[7],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"options\",\"placeholder\",\"onchange\",\"class\",\"renderInPlace\",\"closeOnSelect\",\"searchField\"],[[20,[\"groups\"]],\"Which group do you want to delete?\",[25,\"action\",[[19,0,[]],\"chooseGroup\"],null],\"power-select full-border batch-edit\",true,false,\"name\"]],{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"group-info\"],[7],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"group-color\"],[10,\"style\",[19,1,[\"colorSafe\"]],null],[7],[8],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"group-name\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/delete-group.hbs"
    }
  });

  _exports.default = _default;
});