define("pconsole/models/group", ["exports", "ember-data", "pconsole/mixins/models/clone-record"], function (_exports, _emberData, _cloneRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_cloneRecord.default, {
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    deviceCount: _emberData.default.attr('number'),
    fwVersion: _emberData.default.attr('number'),
    color: _emberData.default.attr('string', {
      defaultValue: '#34495e'
    }),
    product: _emberData.default.belongsTo('product'),
    colorSafe: Ember.computed('color', function () {
      const color = this.get('color');
      return Ember.String.htmlSafe("background: ".concat(color));
    }),
    // Used for batch-editing groups
    // can be set to:
    // - 'all'
    // - 'some'
    // - 'none'
    selected: _emberData.default.attr('string'),
    batchEditHover: Ember.computed('selected', function () {
      const selected = this.get('selected');

      if (selected === 'all') {
        return 'All selected devices belong to this group';
      } else if (selected === 'some') {
        return 'Some of the selected devices belong to this group';
      }
    })
  });

  _exports.default = _default;
});