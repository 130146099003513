define("pconsole/controllers/product/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    route: Ember.computed('routeData.product', function () {
      return this.get('routeData.product') ? 'product.clients' : 'user.clients';
    }),
    createRoute: Ember.computed('route', function () {
      return "".concat(this.get('route'), ".create");
    }),
    editRoute: Ember.computed('route', function () {
      return "".concat(this.get('route'), ".edit");
    }),
    deleteRoute: Ember.computed('route', function () {
      return "".concat(this.get('route'), ".delete");
    }),
    savedClients: Ember.computed.filterBy('model', 'isNew', false),
    product: Ember.computed('routeData.product', function () {
      const productSlug = this.get('routeData.product');
      this.get('store').findAll('product').then(products => {
        return products.findBy('slug', productSlug);
      }).then(product => {
        if (product) {
          this.set('product', product);
        }
      });
    })
  });

  _exports.default = _default;
});