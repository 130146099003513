define("pconsole/controllers/user/integrations/gallery", ["exports", "lodash/kebabCase"], function (_exports, _kebabCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortProps: ['name'],
    parentEntity: 'user',
    selectedTemplate: null,
    filterValue: null,
    categories: Ember.computed('model.templates', 'filterValue', function () {
      const templates = Ember.get(this, 'model.templates');
      const filterValue = Ember.get(this, 'filterValue');
      const filter = new RegExp(filterValue || '', 'i');
      const categories = {};
      templates.forEach(template => {
        const filterIn = filter.test(template.get('name')); // True when the name matches the filterValue

        const isWebhook = template.get('id') === 'webhook';
        const category = template.get('category');
        const categoryId = (0, _kebabCase.default)(category);

        if (filterIn || isWebhook) {
          // Always shows Custom webhook
          // new category
          if (!categories[categoryId]) {
            categories[categoryId] = {
              name: category,
              list: []
            };
          }

          categories[categoryId].list.push(template);
        } // featured integrations


        if (!filterValue && template.get('featured')) {
          if (!categories.featured) {
            categories.featured = {
              name: 'Featured',
              list: []
            };
          }

          categories.featured.list.push(template);
        }
      });
      const sortedCategories = Object.fromEntries(Object.entries(categories).sort(([a], [b]) => {
        if (a === 'featured') {
          return -1;
        } else if (b === 'featured') {
          return 1;
        } else if (a === 'other-services') {
          return 1;
        } else if (b === 'other-services') {
          return -1;
        } else {
          return a.localeCompare(b);
        }
      }));
      return sortedCategories;
    }),
    noTemplateFound: Ember.computed('categories', 'filterValue', function () {
      // categories: computed('model.templates', 'filterValue', function() {
      // const templates = get(this, 'model.templates');
      const filterValue = Ember.get(this, 'filterValue');
      const filter = new RegExp(filterValue || '', 'i');
      const categoryEntries = Object.entries(Ember.get(this, 'categories'));
      const categoryItems = categoryEntries[0][1].list; // Categories is never empty

      const nameMatch = filter.test(categoryItems[0].get('name')); // Only one category found and it has only one item, it means Custom webhook
      // also make sure the user didn't look for custom webhook

      return categoryEntries.length === 1 && categoryItems.length === 1 && !nameMatch;
    }),
    createLink: Ember.computed('parentEntity', function () {
      return "".concat(Ember.get(this, 'parentEntity'), ".integrations.create");
    }),
    actions: {
      selectCategory(id) {
        document.getElementById("".concat(id, "-section")).scrollIntoView();
      },

      selectIntegration(template) {
        Ember.set(this, 'selectedTemplate', template);
      },

      closeModal() {
        Ember.set(this, 'selectedTemplate', null);
      },

      selectTemplate() {
        const templateId = Ember.get(this, 'selectedTemplate.id');
        this.transitionToRoute(Ember.get(this, 'createLink'), {
          queryParams: {
            template: templateId
          }
        });
      },

      resetSearch() {
        Ember.set(this, 'filterValue', null);
      },

      searchItegration(value) {
        Ember.set(this, 'filterValue', value);
      }

    }
  });

  _exports.default = _default;
});