define("pconsole/templates/product/customers/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VcOAeEDi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"modal-title\"],[7],[0,\"Import Customers\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal-stuff\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"To add new customers to this product, choose a file below. We will generate a unique an activation token for each customer.\"],[8],[0,\"\\n    \"],[2,\" TODO Change to a proper button \"],[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"fileUpload ui button outline primary\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"icon ion-document\"],[7],[8],[0,\"\\n      \"],[6,\"span\"],[7],[0,\"Choose File\"],[8],[0,\"\\n      \"],[1,[25,\"customer-file-upload\",null,[[\"class\",\"doneUploading\"],[\"upload\",\"refreshModel\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"id\",\"import-info\"],[7],[0,\"Please choose a tab-delimited text file, where the email address is the first column. Subsequent columns will be ignored.\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/customers/import.hbs"
    }
  });

  _exports.default = _default;
});