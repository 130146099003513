define("pconsole/components/gallery-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['gallery-card'],
    classNameBindings: ['icon:gallery-card--with-icon']
  });

  _exports.default = _default;
});