define("pconsole/helpers/is-equal-or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqualOr = isEqualOr;
  _exports.default = void 0;

  function isEqualOr([leftSide, rightSide, rightSide2]) {
    return leftSide === rightSide || leftSide === rightSide2;
  }

  var _default = Ember.Helper.helper(isEqualOr);

  _exports.default = _default;
});