define("pconsole/routes/user/integrations/gallery", ["exports", "pconsole/mixins/routes/integration-gallery-route"], function (_exports, _integrationGalleryRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_integrationGalleryRoute.integrationGalleryRoute, {
    parentEntity: 'user'
  });

  _exports.default = _default;
});