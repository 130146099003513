define("pconsole/serializers/device", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      sim: {
        key: 'last_iccid'
      },
      groups: {
        serialize: 'records'
      }
    },

    normalize(typeClass, hash) {
      if (hash) {
        if (hash.network && (hash.network.state === 'confirmed' || !hash.network.state)) {
          hash.network_id = hash.network.id;
          hash.network_name = hash.network.name;
        }

        const isProduct = this.get('routeData.hasProduct');

        if (isProduct) {
          if (hash.firmware_version === 65535 || hash.firmware_version === 0) {
            delete hash.firmware_version;
          }
        }

        if (!hash.hasOwnProperty('online') && hash.hasOwnProperty('connected')) {
          hash.online = hash.connected;
          delete hash.connected;
        } // devkits should not load the product to avoid a 404


        if (hash.product_id === hash.platform_id) {
          delete hash.product_id;
        }

        if (!hash.assets) {
          // ensure the assets get cleared in the model in case a device
          // with assets just got OTA updated to a firmware without assets
          hash.assets = null;
        }
      }

      return this._super(...arguments);
    },

    normalizeResponse(store, type, payload, id, requestType) {
      if (payload.device || payload.devices) {
        return this._super(...arguments);
      } else {
        const normalized = {
          'device': payload
        };
        return this._super(store, type, normalized, id, requestType);
      }
    },

    normalizeUpdateRecordResponse(store, type, payload, id, requestType) {
      return this._super(store, type, {}, id, requestType);
    },

    normalizeSaveResponse(store, type, payload, id, requestType) {
      return this._super(store, type, {}, id, requestType);
    },

    serializeAttribute(snapshot, json, key, attributes) {
      // Send only changed attributes to the API
      // Always send the 'flash' attribute so that changing between locked firmware will flash immediately
      if (typeof snapshot.changedAttributes()[key] !== 'undefined' || snapshot.record.get('isNew') || key === 'flash') {
        return this._super(snapshot, json, key, attributes);
      }
    },

    serializeBelongsTo(snapshot, json, relationship) {
      if (typeof snapshot.changedAttributes()[relationship.key] !== 'undefined' || snapshot.record.get('isNew')) {
        return this._super(snapshot, json, relationship);
      } else {
        return undefined;
      }
    },

    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      const isProduct = this.get('routeData.hasProduct');

      if (isProduct && json.groups) {
        json.groups = this.convertGroupsToArrayOfStrings(json.groups);
      } else {
        delete json.groups;
      }

      Ember.merge(hash, json);
    },

    convertGroupsToArrayOfStrings(groups) {
      return groups.map(group => {
        return group.name;
      });
    }

  });

  _exports.default = _default;
});