define("pconsole/components/nav-bar", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['navbar'],
    applicationController: null,
    product: null,
    isOrg: false,
    isProduct: false,
    serviceAgreements: null,
    user: null,
    session: Ember.inject.service(),
    updateProductNameIfChanged: function () {
      if (this.get('product.hasDirtyAttributes') === false) {
        return this.set('productName', this.get('product.name'));
      }
    }.observes('product.hasDirtyAttributes').on('init'),
    actions: {
      editAccount() {
        window.location.replace("".concat(_environment.default.APP.SINGLE_SIGN_ON_URL, "/account-info?redirect=").concat(window.location.href));
      },

      async invalidateSession() {
        if (_environment.default.APP.USE_SINGLE_SIGN_ON) {
          let consoleRootUrl = "".concat(window.location.protocol, "//").concat(window.location.host);

          if (window.location.port) {
            consoleRootUrl += ":".concat(window.location.port);
          }

          window.location.replace("".concat(_environment.default.APP.SINGLE_SIGN_ON_URL, "/logout?redirect=").concat(consoleRootUrl));
        } else {
          await this.get('session').invalidate();
        }
      },

      showModal(name, content) {
        const applicationController = this.get('applicationController');
        applicationController.send('showModal', name, content);
      }

    }
  });

  _exports.default = _default;
});