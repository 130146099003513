define("pconsole/controllers/product/clients/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    actions: {
      closeModal() {
        this.transitionToRoute(this.get('routeData.product') ? 'product.clients' : 'user.clients');
      },

      deleteClient() {
        const client = this.get('model');
        Ember.$('.loading').show();
        client.destroyRecord().then(() => {
          Ember.$('.loading').hide();
          this.send('closeModal');
          this.get('notify').success('Your client was deleted successfully');
        }, () => {
          Ember.$('.loading').hide();
          this.get('notify').alert('There was a problem deleting your OAuth client. Please try again later.');
        });
      }

    }
  });

  _exports.default = _default;
});