define("pconsole/templates/product/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NRSm4M7v",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"loading loading-route\"],[7],[0,\"\\n   \"],[6,\"div\"],[9,\"class\",\"loading-message\"],[7],[0,\"\\n     \"],[6,\"i\"],[9,\"class\",\"icon ion-loading-c loading-icon\"],[7],[8],[0,\"\\n   \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/loading.hbs"
    }
  });

  _exports.default = _default;
});