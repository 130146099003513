define("pconsole/templates/partials/integration/product-integration-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LNEFheap",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"isMultiProduct\"]]],null,{\"statements\":[[0,\"  \"],[4,\"component\",[\"toast\"],[[\"type\",\"__ANGLE_ATTRS__\"],[\"info\",[25,\"hash\",null,[[\"class\"],[\"webhook-info-container integration-toast integration-toast--info\"]]]]],{\"statements\":[[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"This integration is shared by different products\"],[8],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"\\n      Click on \\\"Go to organization integrations\\\" to see full details on execution or to edit this integration for all products. To make changes specific to \"],[6,\"b\"],[7],[1,[20,[\"model\",\"product\",\"name\"]],false],[8],[0,\" without affecting the other products, click \\\"Separate integration\\\" and adjust the properties.\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"integration-toast__actions\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"organization.integrations.show\",[20,[\"model\",\"product\",\"organization\",\"slug\"]],[20,[\"model\",\"orgIntegrationId\"]]],[[\"class\"],[\"ui button filled primary\"]],{\"statements\":[[0,\"        Go to organization integrations\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"product.integrations.edit\",[20,[\"model\",\"id\"]]],[[\"class\"],[\"ui button filled primary\"]],{\"statements\":[[0,\"        Separate integration\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/integration/product-integration-details.hbs"
    }
  });

  _exports.default = _default;
});