define("pconsole/controllers/user/integrations/azure-iot-hub/show", ["exports", "pconsole/mixins/azure-iot-default-fields", "pconsole/mixins/grouped-integration"], function (_exports, _azureIotDefaultFields, _groupedIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_azureIotDefaultFields.default, _groupedIntegration.groupedIntegrationNotification, {
    jsonOrDefault: Ember.computed('model.json', 'defaultFields', function () {
      const json = this.get('model.json');

      if (json) {
        return Ember.typeOf(json) === 'string' ? json : JSON.stringify(json, true, 2);
      } else {
        return JSON.stringify(this.get('defaultFields'), true, 2);
      }
    })
  });

  _exports.default = _default;
});