define("pconsole/services/launch-darkly", ["exports", "pconsole/config/environment", "ldclient-js"], function (_exports, _environment, _ldclientJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Client instance
     * @private
     */
    _client: null,
    _identity: null,
    _identifyPromise: null,
    routeIdentity: Ember.computed('routeData.{org,product}', function () {
      const store = Ember.get(this, 'store');
      const username = Ember.get(this, 'session.data.authenticated.username');
      const identity = {
        key: "user:".concat(username),
        email: username,
        custom: {
          sandbox: true,
          enterprise: false
        }
      };
      const {
        org,
        product
      } = this.routeData;
      let organization;

      if (product) {
        const prod = store.peekAll('product').findBy('slug', product);
        identity.custom.product = [Number(prod.id)];
        organization = Ember.get(prod, 'organization').content;
      }

      if (org) {
        organization = store.peekAll('organization').findBy('slug', org);
      }

      if (organization) {
        identity.custom.organization = Ember.get(organization, 'name');
        identity.custom.enterprise = !!Ember.get(organization, 'enterprise');
        identity.custom.sandbox = false;
      }

      return identity;
    }),

    /**
     * Lazy launch darkly initialization and update of user context
     * @param {object} identity Identity
     * @returns {Promise} Promise that resolves when the context has been updated
     */
    identify() {
      let client = Ember.get(this, '_client');

      const _identity = Ember.get(this, '_identity');

      const identity = Ember.get(this, 'routeIdentity');
      Ember.set(this, '_identity', identity);

      if (!_identity) {
        // Lazy LD initialize
        client = Ember.set(this, '_client', (0, _ldclientJs.initialize)(_environment.default.launchDarkly.id, identity));
        Ember.set(this, '_identifyPromise', client.waitForInitialization());
      } else if (JSON.stringify(_identity) !== JSON.stringify(identity)) {
        Ember.set(this, '_identifyPromise', client.identify(identity));
      }

      return Ember.get(this, '_identifyPromise');
    },

    /**
     * Gets the variation for the given feature flag
     * @param {string} key Key
     * @param {string|boolean} defaultVariation Default variation
     * @returns {string|boolean} Variation
     */
    async variation(key, defaultVariation) {
      await this.identify();
      const client = Ember.get(this, '_client');
      return client.variation(key, defaultVariation);
    }

  });

  _exports.default = _default;
});