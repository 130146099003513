define("pconsole/routes/user/sims/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Import'
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('ICCID', null);
      }
    }

  });

  _exports.default = _default;
});