define("pconsole/components/integration-parameter-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    parameter: null,
    // List of parameters defined by the template,
    webhook: null,
    // The full webhook reference
    displayOnly: null,
    // Set to true to render the variable value inside a span tag
    variable: null,
    variableIndex: null,

    didInsertElement() {
      this._super();

      const variables = Ember.get(this, 'webhook.variables') || [];
      const parameter = Ember.get(this, 'parameter');
      const index = variables.findIndex(({
        templateName
      }) => templateName === parameter.variableName);

      if (index === -1) {
        // If the variable was removed
        Ember.set(this, 'variableIndex', variables.length);
        Ember.set(this, 'variable', {
          type: 'CREATE',
          templateName: Ember.get(parameter, 'variableName'),
          sensitive: Ember.get(parameter, 'sensitive')
        });
      } else {
        Ember.set(this, 'variableIndex', index);
        Ember.set(this, 'variable', _objectSpread({}, variables[index])); // Save a copy that can be edited locally
      }
    },

    isWriteOnly: Ember.computed('parameter', 'variable', function () {
      return Ember.get(this, 'variable.type') === 'REFERENCE' && Ember.get(this, 'parameter.sensitive');
    }),
    // Once the local variable changes, update the overall webhook.variables
    editObserver: Ember.observer('variable.value', 'variableIndex', function () {
      const variables = Ember.get(this, 'webhook.variables') || [];
      const variable = Ember.get(this, 'variable');
      const index = Ember.get(this, 'variableIndex');

      if (index === null) {
        return;
      }

      const newVariables = [...variables.slice(0, index), variable, ...variables.slice(index + 1)];
      Ember.set(this, 'webhook.variables', newVariables);
    }),
    actions: {
      clearEditing(parameter) {
        document.getElementById(parameter.variableName).value = ''; // Clean the HTML input

        Ember.set(this, 'variable.type', 'REFERENCE');
        Ember.set(this, 'variable.value', null);
      },

      editValue(ev) {
        const variable = Ember.get(this, 'variable');

        if (Ember.get(variable, 'type') === 'REFERENCE') {
          Ember.set(this, 'variable.type', 'UPDATE');
        }

        Ember.set(this, 'variable.value', ev.target.value);
      }

    }
  });

  _exports.default = _default;
});