define("pconsole/utils/platforms", ["exports", "@particle/device-constants"], function (_exports, _deviceConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPlatformSpecs = getPlatformSpecs;
  _exports.getPlatformSpecById = getPlatformSpecById;
  _exports.getPlatformIdsByFeature = getPlatformIdsByFeature;
  _exports.getPlatformIdsByConnectivity = getPlatformIdsByConnectivity;
  _exports.getPlatformFixtures = getPlatformFixtures;
  _exports.isCellularPlatform = isCellularPlatform;
  _exports.isAssetTrackerPlatform = isAssetTrackerPlatform;
  _exports.connectivityTypeForPlatform = connectivityTypeForPlatform;
  _exports.connectivityTypeNameForPlatform = connectivityTypeNameForPlatform;
  _exports.connectivityTypeNameMap = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const cellularPlatformIDs = getPlatformIdsByFeature('cellular');
  const assetTrackerPlatformIDs = getPlatformIdsByFeature('trackerServices');
  const connectivityTypeNameMap = {
    'asset_tracker': 'Asset Tracker',
    'cellular': 'Cellular',
    'wifi': 'Wi-Fi'
  }; // b/c ember's bundler recursively adds a `default` property (>_<)
  // likely related to: https://github.com/webpack/webpack/issues/7973

  _exports.connectivityTypeNameMap = connectivityTypeNameMap;

  function getPlatformSpecs() {
    const keys = Object.keys(_deviceConstants.default).filter(k => k !== 'default');
    const specs = {};

    for (const k of keys) {
      specs[k] = _deviceConstants.default[k];
    }

    return specs;
  }

  function getPlatformSpecById(id) {
    const specs = getPlatformSpecs();
    return Object.values(specs).find(p => p.id === id);
  }

  function getPlatformIdsByFeature(feature) {
    const specs = getPlatformSpecs();
    return Object.values(specs).filter(p => p.features.includes(feature)).map(p => p.id).sort((a, b) => a - b);
  }

  function getPlatformIdsByConnectivity(connectivity) {
    const specs = getPlatformSpecs();
    return Object.values(specs).filter(p => p.billingConnectivity === connectivity).map(p => p.id).sort((a, b) => a - b);
  }

  function getPlatformFixtures() {
    const specs = getPlatformSpecs();
    return Object.values(specs).map(p => {
      const {
        id,
        displayName: name,
        productEligible
      } = p;
      return {
        id,
        name,
        productEligible
      };
    }) // `cellular` platforms first, then `wifi`
    // within each category place newer platforms before older
    .sort((a, b) => {
      if (isCellularPlatform(a.id) && isCellularPlatform(b.id)) {
        return b.id - a.id;
      }

      return isCellularPlatform(b.id) ? a.id - b.id : b.id - a.id;
    }).map((p, i) => _objectSpread({}, p, {
      orderInProductDeviceTypeList: i
    }));
  }

  function isCellularPlatform(id) {
    return cellularPlatformIDs.includes(parseInt(id, 10));
  }

  function isAssetTrackerPlatform(id) {
    return assetTrackerPlatformIDs.includes(parseInt(id, 10));
  }

  function connectivityTypeForPlatform(id) {
    const platform = getPlatformSpecById(parseInt(id, 10));
    return platform ? platform.billingConnectivity : undefined;
  }

  function connectivityTypeNameForPlatform(id) {
    const connectivityType = connectivityTypeForPlatform(parseInt(id, 10));
    return connectivityTypeNameMap[connectivityType];
  }
});