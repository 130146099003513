define("pconsole/components/asset-tracker-showing-devices", ["exports", "react", "styled-components"], function (_exports, _react, _styledComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AssetTrackerShowingDevices = ({
    count = 0,
    devicesInCluster = 0,
    visibleDevices = []
  }) => {
    const inViewport = visibleDevices.length + devicesInCluster;
    const allVisible = count === inViewport;
    return _react.default.createElement(ShowingDevicesContainer, null, 'Showing ', _react.default.createElement('span', null, visibleDevices.length, ' out of ', inViewport, ' devices'), !allVisible && _react.default.createElement(DevicesSubTitle, null, _react.default.createElement('img', {
      alt: 'loupe',
      src: '/assets/loupe.svg'
    }), 'Zoom/Pan map to see more results'));
  };

  const ShowingDevicesContainer = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-showing-devices__ShowingDevicesContainer",
    componentId: "sc-5gxkky-0"
  })(["align-items:center;border-top:1px solid var(--primary-border-color);color:var(--tertiary-text-color);padding:15px;span{color:var(--primary-text-color);}"]);

  const DevicesSubTitle = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-showing-devices__DevicesSubTitle",
    componentId: "sc-5gxkky-1"
  })(["display:flex;gap:4px;margin-top:4px;"]);

  var _default = AssetTrackerShowingDevices;
  _exports.default = _default;
});