define("pconsole/mixins/top-products-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.topProductsMixin = void 0;
  const topProductsMixin = {
    sortBy: ['deviceCount:desc'],
    sortedProducts: Ember.computed.sort('model.products', 'sortBy'),
    topProducts: Ember.computed('sortedProducts', function () {
      return Ember.get(this, 'sortedProducts').slice(0, 4);
    })
  };
  _exports.topProductsMixin = topProductsMixin;
});