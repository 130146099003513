define("pconsole/adapters/fleet-configuration", ["exports", "ember-data", "pconsole/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      const productSlug = Ember.get(this, 'routeData.product');
      let deviceId = id;

      if (!productSlug) {
        // eslint-disable-next-line new-cap
        throw (0, _emberData.AdapterError)('Product ID is required');
      }

      if (modelName === 'device-configuration' && snapshot && snapshot.id) {
        deviceId = snapshot.id;
      }

      if (query && query.deviceId) {
        ({
          deviceId
        } = query);
        delete query.deviceId;
      }

      const prefix = this.urlPrefix();
      return "".concat(prefix, "/products/").concat(productSlug, "/config").concat(deviceId ? '/' + deviceId : '');
    },

    async createRecord() {
      // POST is not implemented because IDs are known, map to PUT
      return this.updateRecord(...arguments);
    },

    async queryRecord(store, type, query) {
      const {
        deviceId
      } = query;
      const productSlug = Ember.get(this, 'routeData.product');
      const response = await this._super(...arguments); // Use the device or product ID for now, until configurations have IDs

      response.id = deviceId || productSlug;
      return response;
    },

    async updateRecord(store, type, snapshot) {
      const {
        id: deviceId
      } = snapshot;
      const productSlug = Ember.get(this, 'routeData.product');
      const response = await this._super(...arguments); // Use the device or product ID for now, until configurations have IDs

      response.id = deviceId || productSlug;
      return response;
    }

  });

  _exports.default = _default;
});