define("pconsole/components/device-groups", ["exports", "pconsole/mixins/device-grouping-power-select"], function (_exports, _deviceGroupingPowerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_deviceGroupingPowerSelect.default, {
    /**
     * Given device record
     * @type {Device}
     */
    device: null,

    /**
     * Deprecated named action
     * @type {string}
     */
    filterByGroup: '',

    /**
     * Whether to show edit view
     * @type {boolean}
     */
    isEditing: false,

    /**
     * Given action to call when groups change
     * @type {function}
     * @returns {void}
     */
    onChange() {},

    /**
     * Given action to call when a group is created
     * @type {function}
     * @returns {void}
     */
    onCreate() {},

    /**
     * Given action to call when a group button is clicked
     * @type {function}
     * @returns {void}
     */
    onClick() {},

    /**
     * Whether to show a subset of groups
     * @type {boolean}
     */
    preview: false,

    /**
     * List of groups
     * From the instance in partials/device-table for product/devices
     * Used to show groups that are filtered on first
     * @type {string[]}
     */
    qpGroups: null,
    tagName: '',
    store: Ember.inject.service(),
    deviceGroups: Ember.computed('sortedDeviceGroups', function () {
      const deviceGroups = Ember.get(this, 'sortedDeviceGroups');
      const preview = Ember.get(this, 'preview');
      return preview ? deviceGroups.slice(0, 2) : deviceGroups;
    }),
    leftoverGroups: Ember.computed('device.groups', function () {
      const preview = Ember.get(this, 'preview');
      const deviceGroups = Ember.get(this, 'device.groups');

      if (preview) {
        const leftovers = deviceGroups.slice(2);
        return leftovers.length;
      } else {
        return 0;
      }
    }),
    sortedDeviceGroups: Ember.computed.sort('device.groups', function (a, b) {
      const qpGroups = Ember.get(this, 'qpGroups');
      const aName = Ember.get(a, 'name');
      const bName = Ember.get(b, 'name');

      if (!qpGroups) {
        return this.sortByName(aName, bName);
      } // If our current query params includes a particular group,
      // sort it to be first in the results


      if (qpGroups.includes(aName) && !qpGroups.includes(bName)) {
        return -1;
      } else if (!qpGroups.includes(aName) && qpGroups.includes(bName)) {
        return 1;
      } else {
        // After sorting by query params, sort by name alphabetically
        return this.sortByName(aName, bName);
      }
    }),
    // Put the selected groups on top
    sortedOptions: Ember.computed.sort('device.product.groups', function (a, b) {
      const deviceGroups = Ember.get(this, 'deviceGroups');
      const groupsIncludeA = deviceGroups.includes(a);
      const groupsIncludeB = deviceGroups.includes(b);

      if (groupsIncludeA && groupsIncludeB) {
        return 0;
      } else if (groupsIncludeA) {
        return -1;
      } else {
        return 1;
      }
    }),

    sortByName(aName, bName) {
      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      }

      return 0;
    },

    actions: {
      // Sent from handleKeypress in the mixin
      handleEnter(dropdown) {
        this.onCreate(dropdown.searchText);
      }

    }
  });

  _exports.default = _default;
});