define("pconsole/components/dashboard-link-to", ["exports", "pconsole/mixins/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, {
    route: Ember.computed(function () {
      const isProduct = !!this.get('routeData.product');
      const page = this.get('page');
      const root = isProduct ? 'product' : 'user';
      return root + '.' + page;
    }),
    tagName: '',
    scope: null
  });

  _exports.default = _default;
});