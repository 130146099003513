define("pconsole/templates/components/usage-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aExCdCR8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"renderOverlay\"]]],null,{\"statements\":[[6,\"div\"],[9,\"class\",\"usage-overlay\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[9,\"class\",\"icon ion-loading-c loading-icon\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/usage-overlay.hbs"
    }
  });

  _exports.default = _default;
});