define("pconsole/helpers/plan-amount-in-dollars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planAmountInDollars = planAmountInDollars;
  _exports.default = void 0;

  function planAmountInDollars(plan) {
    const amount = plan[0].get('amount');
    const interval = plan[0].get('interval');
    let dollars;

    if (interval === 'month') {
      dollars = (amount / 100).toFixed(0);
    } else if (interval === 'year') {
      dollars = (amount / 1200).toFixed(0);
    }

    return '$' + dollars;
  }

  var _default = Ember.Helper.helper(planAmountInDollars);

  _exports.default = _default;
});