define("pconsole/templates/components/role-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KU0ZrqcG",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"input-wrapper \",[25,\"if\",[[20,[\"isDisabled\"]],\"disabled\"],null]]]],[7],[0,\"\\n\"],[0,\"    \"],[6,\"div\"],[10,\"class\",[26,[\"select \",[25,\"if\",[[20,[\"small\"]],\"small\"],null]]]],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"label\"],[7],[0,\"\\n        Role\\n        \"],[6,\"div\"],[9,\"class\",\"hint--right\"],[10,\"data-hint\",[18,\"hint\"],null],[7],[0,\"\\n            \"],[6,\"i\"],[9,\"class\",\"im-info-icon help-icon\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"select\"],[10,\"disabled\",[18,\"isDisabled\"],null],[9,\"class\",\"selected\"],[10,\"value\",[26,[[20,[\"model\",\"role\",\"id\"]]]]],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"onUpdate\",[20,[\"product\"]],[20,[\"model\"]]],null],null],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"isProductOwner\"]]],null,{\"statements\":[[0,\"            \"],[6,\"option\"],[9,\"selected\",\"true\"],[7],[0,\"Owner\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"model\",\"isOwner\"]]],null,{\"statements\":[[0,\"            \"],[6,\"option\"],[9,\"selected\",\"true\"],[7],[1,[20,[\"model\",\"role\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[20,[\"rolesNoOwner\"]]],null,{\"statements\":[[0,\"                \"],[6,\"option\"],[10,\"value\",[19,1,[\"id\"]],null],[10,\"selected\",[25,\"eq\",[[19,1,[\"name\"]],[20,[\"model\",\"role\",\"name\"]]],null],null],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/role-dropdown.hbs"
    }
  });

  _exports.default = _default;
});