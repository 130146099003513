define("pconsole/templates/components/form-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ERpNtXU9",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,\"select\"],[10,\"autocomplete\",[26,[[18,\"autocomplete\"]]]],[10,\"class\",[26,[[25,\"if\",[[20,[\"_selection\"]],\"selected\"],null]]]],[3,\"action\",[[19,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"prompt\"]]],null,{\"statements\":[[0,\"    \"],[6,\"option\"],[9,\"disabled\",\"\"],[10,\"selected\",[25,\"is-not\",[[20,[\"_selection\"]]],null],null],[7],[0,\"\\n      \"],[1,[18,\"prompt\"],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n\"],[4,\"each\",[[20,[\"content\"]]],[[\"key\"],[\"@identity\"]],{\"statements\":[[0,\"    \"],[6,\"option\"],[10,\"value\",[26,[[25,\"read-path\",[[19,1,[]],[20,[\"optionValuePath\"]]],null]]]],[10,\"selected\",[25,\"is-equal\",[[19,1,[]],[20,[\"_selection\"]]],null],null],[7],[0,\"\\n      \"],[1,[25,\"read-path\",[[19,1,[]],[20,[\"optionLabelPath\"]]],null],false],[0,\"\\n     \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/form-select.hbs"
    }
  });

  _exports.default = _default;
});