define("pconsole/routes/user/logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserLogsRoute = Ember.Route.extend({
    beforeModel() {
      this.transitionTo('user.events');
    }

  });
  var _default = UserLogsRoute;
  _exports.default = _default;
});