define("pconsole/mixins/create-or-edit-group", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const groupColors = {
    '#34495e': 'wet-asphalt',
    '#8e44ad': 'wisteria',
    '#e74c3c': 'alizarin',
    '#f1c40f': 'sunflower',
    '#2ecc71': 'emerald',
    '#0ec0c7': 'pilot-color'
  };

  var _default = Ember.Mixin.create(_form.default, {
    validations: {
      'group.name': {
        presence: true,
        format: {
          with: /^[a-zA-Z0-9-_]+$/,
          message: 'must only contain letters, numbers, dashes, and underscores'
        }
      }
    },
    notify: Ember.inject.service(),
    productDevicesController: Ember.inject.controller('product.devices'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'colors', groupColors);
    },

    save() {
      Ember.$('.loading').show();
      const group = this.get('group');
      const isEditing = this.get('isEditing');
      group.save().then(() => {
        Ember.$('.loading').hide();
        this.get('notify').success("Your device group was ".concat(isEditing ? 'edited' : 'created', " successfully!"));
        this.transitionToRoute('product.devices');

        if (isEditing) {
          // We trigger a model refresh so we can make sure we show the most up-to-date
          // attributes of a group. For instance, if a group changes color, the devices
          // will show the new group color.
          this.send('refreshModel'); // We also filter devices to the recently edited group to allow the user to see the impact of their change

          const productDevicesController = this.get('productDevicesController');
          productDevicesController.set('groups', group.get('name'));
        }
      }, response => {
        Ember.$('.loading').hide();
        const error = response.errors[0];
        const errorMsg = error ? error.detail : 'Please try again later.';
        this.get('notify').alert('Your group could not be created. ' + errorMsg);
      });
    },

    cancel() {
      this.send('closeModal');
    },

    actions: {
      closeModal() {
        Ember.run.later(this, () => {
          if (this.get('createGroupRouteActive') || this.get('editGroupRouteActive')) {
            this.transitionToRoute('product.devices');
          }
        }, 50);
      },

      chooseColor(color) {
        const group = this.get('group');
        group.set('color', color);
      }

    }
  });

  _exports.default = _default;
});