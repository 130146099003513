define("pconsole/components/object-filter", ["exports", "pconsole/helpers/query-param-friendly-name"], function (_exports, _queryParamFriendlyName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter'],
    queryParams: Ember.computed.alias('parentController.queryParams'),
    filteredQueryParams: Ember.computed.alias('parentController.filteredQueryParams'),
    isGroupsParam: Ember.computed('chosenQueryParam', function () {
      const chosenQueryParam = this.get('chosenQueryParam');
      return chosenQueryParam === 'groups';
    }),
    placeholderText: Ember.computed('chosenQueryParam', function () {
      const chosenQueryParam = this.get('chosenQueryParam');
      return "Filter by ".concat((0, _queryParamFriendlyName.queryParamFriendlyName)([chosenQueryParam]));
    }),
    searchValue: Ember.computed('chosenQueryParam', function () {
      const chosenQueryParam = this.get('chosenQueryParam');
      const queryParamsAndValues = this.get('queryParamsAndValues');
      return queryParamsAndValues[chosenQueryParam];
    }),
    chosenQueryParam: Ember.computed.oneWay('defaultQueryParam'),
    defaultQueryParam: Ember.computed('filteredQueryParams', function () {
      const parentController = this.get('parentController');
      const resetQueryParam = parentController.get('resetQueryParam');
      const filteredQueryParams = this.get('filteredQueryParams');
      const queryParamsAndValues = this.get('queryParamsAndValues'); // Do we have any existing values for query params that should be used?

      let currentQPKey;

      _.forOwn(queryParamsAndValues, (value, key) => {
        if (!value) {
          return;
        }

        currentQPKey = key;
      }); // If we have an existing query param set, use that as the default


      if (currentQPKey) {
        return currentQPKey;
      } else if (resetQueryParam) {
        return resetQueryParam;
      } else {
        // Otherwise just use the first query param as the default
        return filteredQueryParams[0];
      }
    }),

    setDefaultSelectedGroups() {
      const groups = this.get('groups');
      const parentController = this.get('parentController');
      const currentlySelectedGroups = parentController.get('groups');

      if (currentlySelectedGroups) {
        let matchingGroupArray = currentlySelectedGroups.split(',');
        matchingGroupArray.forEach((groupName, index) => {
          const group = groups.findBy('name', groupName);

          if (group) {
            matchingGroupArray[index] = group;
          } else {
            delete matchingGroupArray[index];
          }
        });
        matchingGroupArray = (matchingGroupArray || []).filter(item => item);
        this.set('selectedGroups', matchingGroupArray);
      }
    },

    queryParamsAndValues: Ember.computed('filteredQueryParams', function () {
      const filteredQueryParams = this.get('filteredQueryParams');
      const parentController = this.get('parentController');
      const queryParamsAndValues = {};
      filteredQueryParams.forEach(qp => {
        if (qp === 'groups') {
          this.setDefaultSelectedGroups();
        }

        queryParamsAndValues[qp] = parentController.get(qp);
      });
      return queryParamsAndValues;
    }),
    actions: {
      filterItems() {
        const parentController = this.get('parentController');
        this.sendAction('triggerFilter', this.get('chosenQueryParam'), this.get('searchValue'));
        parentController.set('page', undefined);
      },

      resetSearch() {
        const parentController = this.get('parentController');
        this.set('searchValue', undefined);
        this.sendAction('triggerFilter', this.get('chosenQueryParam'), this.get('searchValue'));
        parentController.set('resetQueryParam', this.get('chosenQueryParam'));
      },

      changeChosenQP(qp) {
        this.set('chosenQueryParam', qp);
      },

      chooseGroup(groups) {
        if (groups.length === 0) {
          this.send('resetSearch');
          this.set('selectedGroups', null);
        } else {
          this.set('selectedGroups', groups);
          this.set('searchValue', groups.mapBy('name').join(','));
          this.send('filterItems');
        }
      }

    }
  });

  _exports.default = _default;
});