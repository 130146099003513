define("pconsole/templates/user/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "50pzMykw",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"unhandled-error\",null,[[\"error\",\"__ANGLE_ATTRS__\"],[[20,[\"model\"]],[25,\"hash\",null,[[\"class\"],[\"margin-top-triple\"]]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/user/error.hbs"
    }
  });

  _exports.default = _default;
});