define("pconsole/helpers/number-with-commas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberWithCommas = numberWithCommas;
  _exports.default = void 0;

  function numberWithCommas([value]) {
    if (typeof value === 'undefined') {
      return null;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  var _default = Ember.Helper.helper(numberWithCommas);

  _exports.default = _default;
});