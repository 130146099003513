define("pconsole/models/integration-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const IntegrationType = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    iconImgPath: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    emberRouteName: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    integrations: _emberData.default.hasMany('integration'),
    beta: _emberData.default.attr('boolean')
  });

  IntegrationType.reopenClass({
    FIXTURES: [{
      id: 'GoogleCloudPubSub',
      name: 'Google Cloud Platform',
      description: 'Tie into an enterprise grade suite of cloud-based data storage and analysis tools.',
      iconImgPath: 'integrations/google-cloud.svg',
      emberRouteName: 'googleCloudPlatform',
      order: 3,
      enabled: true
    }, {
      id: 'AzureIotHub',
      name: 'Azure IoT Hub',
      description: 'Stream Particle device data into the Azure ecosystem.',
      emberRouteName: 'azureIotHub',
      iconImgPath: 'integrations/azure-iot-hub.svg',
      order: 2,
      enabled: true
    }, {
      id: 'GoogleMaps',
      name: 'Google Maps',
      description: 'Geolocate Particle devices via visible Wi-Fi access points or Cellular towers.',
      emberRouteName: 'googleMaps',
      iconImgPath: 'integrations/google-maps.svg',
      order: 1,
      enabled: true
    }, {
      id: 'Webhook',
      name: 'Webhook',
      description: 'Push Particle device data to other web services in real-time.',
      iconImgPath: 'integrations/webhooks.svg',
      emberRouteName: 'webhooks',
      order: 4,
      enabled: true
    }, {
      id: 'Logic',
      enabled: false
      /*
      {
        id: 7,
        name: 'Amazon Redshift',
        description: 'A fast, fully managed, petabyte-scale data warehouse',
        iconImgPath: 'redshift-logo.png',
        enabled: true
      },
      {
        id: 3,
        name: 'Microsoft Azure',
        description: 'Tap into a global network of Microsoft-managed datacenters',
        iconImgPath: 'azure.png',
        enabled: true
      },
      {
        id: 5,
        name: 'ThingSpeak',
        description: 'The open data platform for the Internet of Things',
        iconImgPath: 'thingspeak-logo.png',
        enabled: true
      },
      {
        id: 2,
        name: 'Twilio',
        description: 'Text messaging, VoIP, and voice',
        iconImgPath: 'twilio.png',
        enabled: true
      },
      {
        id: 6,
        name: 'IFTTT',
        description: 'Connect Particle with your favorite apps on the web',
        iconImgPath: 'ifttt-logo.png',
        enabled: true
      },*/

    }]
  });
  var _default = IntegrationType;
  _exports.default = _default;
});