define("pconsole/controllers/user/sandbox-billing", ["exports", "pconsole/helpers/formatted-date"], function (_exports, _formattedDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const alertMessageOptions = [{
    eventNamePattern: /developer:usage_activity:manual_unpause/,
    title: 'Service Active',
    message: 'The Particle team overrode the usage limits for your service until the start of the next usage period, starting $DATE. Your service will not be paused even if you exceed the usage limits until then.'
  }, {
    eventNamePattern: /developer:usage_activity:manual_pause/,
    title: 'Service Paused',
    message: 'The Particle team has paused the service for your device(s). Please check your account email for more information.'
  }, {
    eventNamePattern: /developer:usage_activity:automatic_pause/,
    title: 'Service Paused',
    message: 'Please add your devices to an existing paid plan or upgrade to a Growth or Enterprise plan to reactivate your service immediately. You’ve exceeded your free Sandbox monthly limits. Service will remain paused until the start of the next usage period, $DATE.'
  }, {
    eventNamePattern: /developer:usage_activity:max_messages:threshold:3|developer:usage_activity:max_data:treshold:3/,
    title: 'Usage Limit Exceeded',
    message: 'Please add your devices to an existing paid plan or upgrade to a Growth or Enterprise Plan to avoid service disruption. If no action is taken, services will automatically be paused on $PAUSE_DATE. Your service will be automatically unpaused at the start of the next usage period, $DATE. Please check your account email for more information.'
  }];

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    usageAlerts: Ember.inject.service(),
    products: Ember.computed.readOnly('model.products'),
    serviceAgreement: Ember.computed.readOnly('application.currentDeveloperServiceAgreement'),
    serviceAgreementObject: Ember.computed('serviceAgreement', function () {
      const serviceAgreement = Ember.get(this, 'serviceAgreement');
      return serviceAgreement.toObject();
    }),
    activeOrganizationServiceAgreements: Ember.computed.readOnly('application.organizationServiceAgreements'),
    enterprisePlanOrganizations: Ember.computed('activeOrganizationServiceAgreements', function () {
      const activeOrgs = Ember.get(this, 'activeOrganizationServiceAgreements').mapBy('ownerId');
      const organizations = [];
      activeOrgs.forEach(o => {
        const org = this.store.peekRecord('organization', o);
        organizations.push(org);
      });
      return organizations;
    }),
    alert: Ember.computed('usageAlerts.allAlerts', 'serviceAgreement', function () {
      const allAlerts = Ember.get(this, 'usageAlerts.allAlerts');
      const serviceAgreement = Ember.get(this, 'serviceAgreement');
      return allAlerts.find(alert => Ember.get(alert, 'serviceAgreement.id') === Ember.get(serviceAgreement, 'id'));
    }),
    warning: Ember.computed('alert', function () {
      const alert = Ember.get(this, 'alert');
      const warning = alert && alertMessageOptions.find(a => a.eventNamePattern.exec(Ember.get(alert, 'eventName')));

      if (warning) {
        return {
          displayWarning: true,
          title: warning.title,
          message: this.formatMessage(warning.message, alert)
        };
      } else {
        return {
          displayWarning: false,
          title: '',
          message: ''
        };
      }
    }),

    formatMessage(message, alert) {
      return message.replace('$DATE', (0, _formattedDate.formattedDate)([Ember.get(alert, 'nextBillingPeriodStart'), 'MMM D', 'utc'])).replace('$PAUSE_DATE', (0, _formattedDate.formattedDate)([Ember.get(alert, 'devicePauseDate'), 'MMM D', 'utc']));
    },

    hasPaidService: Ember.computed.notEmpty('enterprisePlanOrganizations'),
    actions: {
      getProductHref({
        id: productId
      }) {
        const product = this.store.peekRecord('product', productId);
        return Ember.get(this, 'router').urlFor('product.fleet-health', Ember.get(product, 'slug'));
      }

    }
  });

  _exports.default = _default;
});