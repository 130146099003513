define("pconsole/templates/components/device-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/zbCwhgT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[6,\"i\"],[9,\"aria-hidden\",\"true\"],[9,\"class\",\"ion-loading-c\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"i\"],[9,\"aria-hidden\",\"true\"],[9,\"class\",\"ion-lightbulb\"],[7],[8],[0,\"\\n  \"],[1,[18,\"signalingStatus\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/device-signal.hbs"
    }
  });

  _exports.default = _default;
});