define("pconsole/routes/user/ledger/index/tos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      // If for some reason the user enters to this route and already has TOS, get them to the list of ledgers
      const {
        user
      } = this.modelFor('user');
      const tosVersion = Ember.get(user, 'tos.version');

      if (tosVersion >= 3) {
        this.router.transitionTo('user.ledger.index');
      }
    },

    model() {
      return this.modelFor('user');
    }

  });

  _exports.default = _default;
});