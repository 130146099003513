define("pconsole/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentPath: '',
    shouldShowUpgradeMessage: false,
    segment: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    usageAlerts: Ember.inject.service(),
    activeServiceAgreements: Ember.computed.filter('model.serviceAgreements', s => s && Ember.get(s, 'isActive')),
    developerServiceAgreements: Ember.computed.filter('activeServiceAgreements', s => s && Ember.get(s, 'isDeveloper')),
    organizationServiceAgreements: Ember.computed.filter('activeServiceAgreements', s => s && Ember.get(s, 'isOrganization')),
    currentDeveloperServiceAgreement: Ember.computed.readOnly('developerServiceAgreements.firstObject'),
    hasDeveloperServiceAgreement: Ember.computed.bool('currentDeveloperServiceAgreement'),
    hasServiceAgreement: Ember.computed.bool('currentServiceAgreement'),
    mainTagClass: Ember.computed('currentPath', function () {
      const currentPath = Ember.get(this, 'currentPath');
      const isFullScreen = currentPath === 'login' || currentPath === 'not-found';
      return isFullScreen ? 'fullscreen' : '';
    }),
    currentServiceAgreement: Ember.computed('routeData.{org,product}', 'organizationServiceAgreements', 'currentDeveloperServiceAgreement', function () {
      // are we on a route for an org or an org product?
      let org;
      const routeOrg = Ember.get(this, 'routeData.org');
      const routeProduct = Ember.get(this, 'routeData.product');

      if (routeOrg) {
        org = this.store.peekAll('organization').filterBy('slug', routeOrg)[0];
      } else if (routeProduct) {
        const product = this.store.peekAll('product').filterBy('slug', routeProduct)[0];
        org = product && Ember.get(product, 'organization');
      } // if so, return the service agreement for that org


      const orgId = org && Ember.get(org, 'id');

      if (orgId) {
        return Ember.get(this, 'organizationServiceAgreements').filterBy('ownerId', orgId)[0];
      } else {
        return Ember.get(this, 'currentDeveloperServiceAgreement');
      }
    }),
    shouldShowUsageAlerts: Ember.computed('router.currentRouteName', 'usageAlerts.alert', function () {
      // Don't show alerts if there isn't one to show
      const alert = Ember.get(this, 'usageAlerts.alert');

      if (!alert) {
        return false;
      }

      const currentRouteName = Ember.get(this, 'router.currentRouteName');
      return (// Hide for user billing page
        !currentRouteName.startsWith('user.billing') && // Hide for org billing page
        !currentRouteName.startsWith('organization.billing') // Hide while loading
        && !currentRouteName.endsWith('loading')
      );
    }),
    updateCurrentPath: Ember.observer('currentPath', function () {
      this.set('currentPath', this.get('currentPath'));
    }),
    actions: {
      trackDeveloperMessageLimitModal() {
        Ember.get(this, 'segment').trackEvent('message limit modal shown');
      },

      trackCloseDeveloperMessageLimitModal() {
        Ember.get(this, 'segment').trackEvent('message limit modal closed');
      },

      trackDeveloperMessageLimitModalCTA() {
        Ember.get(this, 'segment').trackEvent('message limit modal cta clicked');
      }

    }
  });

  _exports.default = _default;
});