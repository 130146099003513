define("pconsole/controllers/organization/logic-functions/details", ["exports", "pconsole/utils/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    logicFunction: Ember.computed.readOnly('model.logicFunction'),
    products: Ember.computed.readOnly('model.products'),
    owner: Ember.computed.readOnly('model.owner'),
    tabs: [{
      id: 'general',
      title: 'General'
    }, {
      id: 'triggers',
      title: 'Triggers'
    }, {
      id: 'runs',
      title: 'Runs'
    }],
    productNames: Ember.computed.sort('products', function (a, b) {
      const aName = Ember.get(a, 'name');
      const bName = Ember.get(b, 'name');

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      }

      return 0;
    }),

    async init() {
      const orgSlug = this.get('routeData.org');
      const showRuns = await (0, _permissions.checkScope)({
        orgSlug,
        scope: 'logic.runs:list',
        store: this.store
      });

      if (!showRuns) {
        Ember.set(this, 'tabs', Ember.get(this, 'tabs').slice(0, 2));
      }
    },

    actions: {
      async toggleEnable() {
        const logicFunction = Ember.get(this, 'logicFunction'); // To avoid overriding potential changes made by the API, reload the function before disabling

        await logicFunction.reload();

        try {
          const newStatus = !Ember.get(logicFunction, 'enabled');
          Ember.set(logicFunction, 'enabled', newStatus);
          await logicFunction.save();
          Ember.get(this, 'notify').success("Your Logic Function  has been ".concat(newStatus ? 'enabled' : 'disabled'));
        } catch (_unused) {
          Ember.get(this, 'notify').alert('Your Logic Function could not be updated. Please try again later');
        }
      }

    }
  });

  _exports.default = _default;
});