define("pconsole/serializers/product-firmware", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,

    normalizeResponse(store, type, payload, id, requestType) {
      let firmwares = [];

      function transformPayload(p) {
        if (p.uploaded_by) {
          p.uploaded_by = p.uploaded_by.username;
        }
      }

      if (Array.isArray(payload)) {
        payload.forEach(transformPayload);
        firmwares = payload;
      } else {
        transformPayload(payload);
        firmwares.push(payload);
      }

      const data = {
        product_firmware: firmwares
      };
      return this._super(store, type, data, id, requestType);
    },

    normalize(typeClass, payload) {
      payload.id = payload.version; // TODO: After we change the API to use 'intelligent' instead of 'immediate',
      // we should come back and change the Console's model definition to use the 'intelligent' attribute
      // instead of 'immediate'

      if (payload.intelligent) {
        payload.immediate = payload.intelligent;
      }

      return this._super(...arguments);
    },

    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      Ember.merge(hash, json);
    }

  });

  _exports.default = _default;
});