define("pconsole/routes/product/fleet-health", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const modelForProduct = this.modelFor('product');
      return modelForProduct.product;
    },

    setupController(controller) {
      this._super(...arguments);

      controller.onSetupController();
    },

    resetController(controller, isExiting, transition) {
      this._super(controller, isExiting, transition);

      if (isExiting) {
        controller.onExitController();
      }
    }

  });

  _exports.default = _default;
});