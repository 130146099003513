define("pconsole/components/asset-tracker-datetime-range-picker", ["exports", "react", "moment", "prop-types", "styled-components", "react-advanced-datetimerange-picker"], function (_exports, _react, _moment, _propTypes, _styledComponents, _reactAdvancedDatetimerangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  /**
   * AT date-time picker
   *
   * @param {Object} props Props
   * @param {Moment} props.start Start date
   * @param {Moment} props.end End date
   * @returns {Function} Component
   */
  const AssetTrackerDatetimeRangePicker = props => {
    const pickerRef = (0, _react.useRef)();
    const now = (0, _moment.default)();
    const last24Hours = [now.clone().subtract(24, 'hours').startOf('minute'), now.clone()]; // Use a magic number to represent "no date"

    const lastKnown = [(0, _moment.default)('1969-07-20'), (0, _moment.default)('1969-07-20')];
    const ranges = {
      'Last Known': lastKnown,
      'Last 15 Minutes': [now.clone().subtract(15, 'minutes').startOf('minute'), now.clone()],
      'Last Hour': [now.clone().subtract(1, 'hours').startOf('minute'), now.clone()],
      'Last 24 Hours': last24Hours,
      'Last 3 Days': [now.clone().subtract(3, 'days').startOf('day'), now.clone().endOf('day')],
      'Last 7 Days': [now.clone().subtract(7, 'days').startOf('day'), now.clone().endOf('day')],
      'Last 30 Days': [now.clone().subtract(30, 'days').startOf('day'), now.clone().endOf('day')],
      'Last 90 Days': [now.clone().subtract(90, 'days').startOf('day'), now.clone().endOf('day')]
    };
    const local = Object.assign({
      apply: 'Search',
      format: 'MM-DD-YYYY HH:mm',
      sundayFirst: true
    }, props.local);
    const start = props.start || last24Hours[0];
    const end = props.end || last24Hours[1];
    return _react.default.createElement(ImparterOfStyles, {
      onKeyDown: handleEnterKey
    }, _react.default.createElement(_reactAdvancedDatetimerangePicker.default, _extends({}, props, {
      darkMode: true,
      ref: pickerRef,
      noMobileMode: true,
      local: local,
      ranges: ranges,
      rangeCallback: i => {
        if (i === 0) {
          // Apply the "Last Known" option immediately after the selection is made
          setTimeout(() => pickerRef.current.container.querySelector('.applyButton').click(), 0);
        }
      },
      start: start,
      end: end,
      twelveHoursClock: true,
      style: {
        customRangeButtons: {
          backgroundColor: 'transparent',
          color: 'var(--primary-text-color)'
        },
        customRangeSelected: {
          backgroundColor: 'transparent',
          color: 'var(--secondary-text-color)'
        },
        hoverCell: {
          backgroundColor: 'var(--online-color)'
        },
        toDate: {
          backgroundColor: 'var(--online-color)'
        },
        fromDate: {
          backgroundColor: 'var(--online-color)'
        }
      }
    })));
  };

  AssetTrackerDatetimeRangePicker.propTypes = {
    applyCallback: _propTypes.default.func,
    local: _propTypes.default.object,
    start: _propTypes.default.object,
    end: _propTypes.default.object
  };

  function handleEnterKey(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      const el = document.querySelector('.applyButton[role="button"]');

      if (el) {
        el.click();
      }
    }
  }

  const ImparterOfStyles = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-datetime-range-picker__ImparterOfStyles",
    componentId: "qauni1-0"
  })(["input:disabled{background-color:var(--disabled-background-color);border-color:var(--primary-border-color);color:var(--disabled-text-color);}select{display:unset;margin:0;width:unset;}.dot{display:none;}"]);

  var _default = AssetTrackerDatetimeRangePicker;
  _exports.default = _default;
});