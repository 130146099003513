define("pconsole/adapters/client", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    namespace: Ember.computed('routeData.product', function () {
      const product = this.get('routeData.product');
      return 'v1' + (product ? "/products/".concat(product) : '');
    })
  });

  _exports.default = _default;
});