define("pconsole/models/choice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Choice = _emberData.default.Model.extend({
    value: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    question: _emberData.default.belongsTo('question', {
      inverse: 'choices',
      async: false
    }),
    disabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    reason: _emberData.default.attr('string'),
    disabledValue: Ember.computed('disabled', 'question.disabled', function () {
      return this.get('disabled') || this.get('question.disabled');
    }),
    isChosen: Ember.computed('question.answer', 'value', function () {
      return this.get('question.answer') === this.get('value');
    })
  });

  Choice.reopenClass({
    FIXTURES: [{
      id: 'type-Consumer',
      value: 'Consumer',
      description: ''
    }, {
      id: 'type-Industrial',
      value: 'Industrial',
      description: ''
    }, {
      id: 'type-Hobbyist',
      value: 'Hobbyist',
      description: ''
    }, {
      id: 'whitelabel-Yes',
      value: 'Yes',
      description: 'I will be whitelabeling'
    }, {
      id: 'whitelabel-No',
      value: 'No',
      description: 'Particle will be visible'
    }, {
      id: 'authentication-Particle',
      value: 'Particle',
      description: 'Particle handles user accounts and auth. This is the simplest scenario to implement, because it’s already built! Customers create accounts in your app, and these are stored in our Cloud. Particle can be invisible here.'
    }, {
      id: 'authentication-oauth',
      value: 'oAuth',
      description: 'You create a user account system on your servers, and customers also create/log into a Particle-based account via OAuth. The customer will create a new account on your servers in your app, and then do a similar thing within the same app on our servers, provided they don’t already have a compatible account. This is most frequently used for “friendly” products where the customer is permitted to use the same login for several products to ease interoperability. This is covered in another question.'
    }, {
      id: 'authentication-org',
      value: 'Two-legged',
      description: 'You create a user account system on your servers, and when the customer registers with you (or deletes their account, or needs to refresh their tokens), your server communicates with ours to create/delete a set of credentials associated with a set of permissions.'
    }, {
      id: 'login-reuse-Yes',
      value: 'Yes',
      description: 'Customers can use their existing accounts'
    }, {
      id: 'login-reuse-No',
      value: 'No',
      description: 'I’d like them to create new accounts'
    }, {
      id: 'data-hosting-Yes',
      value: 'Yes',
      description: 'I’ll be hosting data that interacts with the devices'
    }, {
      id: 'data-hosting-No',
      value: 'No',
      description: 'Just things on the Particle Cloud'
    }, {
      id: 'private-beta-Yes',
      value: 'Yes',
      description: 'Only specific customers that I invite to a private beta should be able to setup and use their device'
    }, {
      id: 'private-beta-No',
      value: 'No',
      description: 'This product is public, and anyone should be able to setup and use their device'
    }, {
      id: 'customer-sharing-Yes',
      value: 'Yes',
      description: 'There are guests/friends/family'
    }, {
      id: 'customer-sharing-No',
      value: 'No',
      description: 'There is just one owner/user'
    }, {
      id: 'sharing-access-Yes',
      value: 'Yes',
      description: 'I’ll need to define what the different roles are'
    }, {
      id: 'sharing-access-No',
      value: 'No',
      description: 'Everyone has the same access permissions including administrative'
    }, {
      id: 'customerAPIAccess-Yes',
      value: 'Yes',
      description: 'Customers should have API access of some level'
    }, {
      id: 'customerAPIAccess-No',
      value: 'No',
      description: 'Customers should not be able to access the device via the API'
    }, {
      id: 'customerReprogrammable-Yes',
      value: 'Yes',
      description: 'Customers should be able to flash new firmware to the device'
    }, {
      id: 'customerReprogrammable-No',
      value: 'No',
      description: 'Only the official firmware is allowed'
    }, {
      id: 'thirdPartyAPIAccess-Yes',
      value: 'Yes',
      description: 'Customers should be able to authorize other apps'
    }, {
      id: 'thirdPartyAPIAccess-No',
      value: 'No',
      description: 'Only the official app and customer-written apps are allowed'
    }, {
      id: 'manufacturingFirmware-Setup',
      value: 'On Setup',
      description: 'Product ID programmed to the device, and full code downloaded when first connected to WiFi<ul><li>Pros: very fast on the line. changes can be made to the firmware that the customer receives during the time between manufacture and arrival, because it’s all in the cloud</li><li>Cons: if the device might never be connected to WiFi and requires code to operate, then this isn’t an option. the product wouldn’t have any code on it when the customer tried to use it. when it’s used the first time, the firmware download may take a minute or more</li></ul></li></ul>'
    }, {
      id: 'manufacturingFirmware-Line',
      value: 'On Manufacturing Line',
      description: 'This is the way non-connected hardware products have always been programmed.<ul><li>Pros: device works as soon as customer gets it, even if it’s never WiFi setup</li><li>Cons: takes longer on the manufacturing line. requires more complicated programming rig?</li></ul>'
    }, {
      id: 'unrecognizedDevice-Approve',
      value: 'Approve',
      description: 'Any eligible unrecognized device will be automatically added to this product and be granted all product privileges when it connects to the cloud. This option is less secure, but may be necessary if you don\'t collect device IDs during manufacturing.'
    }, {
      id: 'unrecognizedDevice-Quarantine',
      value: 'Quarantine',
      description: 'Any unrecognized device will be placed in a quarantined state. In this state, the device will be blocked from all product privileges. Quarantined devices will be highlighted in your console with the option to approve or deny access. This is the most secure and recommmended option.'
    }]
  });
  var _default = Choice;
  _exports.default = _default;
});