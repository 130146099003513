define("pconsole/routes/product/devices/import-devices", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    model() {
      return this.store.createRecord('new-device');
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        Ember.set(controller, 'product', null);
        Ember.set(controller, 'shouldShowBulkDeviceLimitModal', false);
        Ember.set(controller, 'showGrowthPlanSingleDeviceConfirmation', false);
        Ember.set(controller, 'showGrowthPlanBulkDeviceConfirmation', false);
      }
    },

    setupController(controller) {
      this._super(...arguments);

      const {
        product
      } = this.modelFor('product');
      Ember.set(controller, 'product', product);
    }

  });

  _exports.default = _default;
});