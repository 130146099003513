define("pconsole/models/plan-common-feature", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PlanCommonFeature = _emberData.default.Model.extend({
    name: _emberData.default.attr('string')
  });

  PlanCommonFeature.reopenClass({
    FIXTURES: [{
      id: 1,
      name: 'Software development tools'
    }, {
      id: 2,
      name: 'Encrypted communications'
    }, {
      id: 3,
      name: 'REST API'
    }, {
      id: 4,
      name: 'Device management console'
    }, {
      id: 5,
      name: 'Webhooks and integrations'
    }, {
      id: 6,
      name: 'Real-time event firehose'
    }, {
      id: 7,
      name: 'Over-the-air firmware updates'
    }, {
      id: 8,
      name: 'OAuth Client Management'
    }, {
      id: 9,
      name: 'Customer Management'
    }]
  });
  var _default = PlanCommonFeature;
  _exports.default = _default;
});