define("pconsole/initializers/ace-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // The Javascript link worker in Ace editor configures JSHint to ES5
  // Work around to configure it to ES2020
  // https://github.com/ajaxorg/ace/issues/3160
  function initialize() {
    // ace is a global variable
    const originalEdit = ace.edit;

    ace.edit = function (element) {
      const editor = originalEdit.call(ace, element);
      editor.session.on('changeMode', function (e, session) {
        if ('ace/mode/javascript' === session.getMode().$id) {
          if (session.$worker) {
            session.$worker.send('setOptions', [{
              esversion: 2020,
              esnext: false
            }]);
          }
        }
      });
      return editor;
    };
  }

  var _default = {
    name: 'ace-editor',
    initialize
  };
  _exports.default = _default;
});