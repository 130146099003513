define("pconsole/components/asset-tracker-search", ["exports", "react", "pconsole/lib/hooks", "prop-types", "moment", "react-dropdown", "react-dropdown/style.css", "styled-components", "pconsole/components/asset-tracker-datetime-range-picker", "pconsole/components/device-groups-input"], function (_exports, _react, _hooks, _propTypes, _moment, _reactDropdown, _style, _styledComponents, _assetTrackerDatetimeRangePicker, _deviceGroupsInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  /**
   * A form that accepts search inputs to submit to the cloud
   *
   * @example
   * ```jsx
   * <AssetTrackerSearch onSearch={...} />
   * ```
   */
  const AssetTrackerSearch = props => {
    // TODO (@mossymaker): fork ember-cli-react and apply some of the PRs, like rest/spread O.o
    const {
      metrics
    } = props;
    const dateRangeStart = props.dateRange && (0, _moment.default)(props.dateRange[0]);
    const dateRangeEnd = props.dateRange && (0, _moment.default)(props.dateRange[1]);
    const groups = props.groupOptions;
    const deviceGroupsIds = props.deviceGroups && props.deviceGroups.split(',');
    const selectedGroups = groups.filter(g => deviceGroupsIds.includes(g.id));

    const onSearch = props.onSearch || function () {};

    const onClear = props.onClear || function () {};

    const [selectedGroupsValue, setSelectedGroups] = (0, _react.useState)(selectedGroups);
    const [dateRange, setDateRange] = (0, _hooks.useStateCallback)({
      start: dateRangeStart,
      end: dateRangeEnd
    });
    (0, _react.useEffect)(() => {
      setDateRange({
        start: dateRangeStart,
        end: dateRangeEnd
      });
    }, [props.dateRange]);
    (0, _react.useEffect)(() => {
      setSelectedGroups(selectedGroups);

      if (selectedGroups.length > 0 && deviceSearchType !== 'device-groups') {
        bindDeviceSearchType.onChange({
          value: 'device-groups',
          label: 'Groups'
        });
      }
    }, [props.deviceGroups]);
    let initialDeviceSearchType = 'device-id';

    if (props.deviceName) {
      initialDeviceSearchType = 'device-name';
    } else if (props.deviceGroups) {
      initialDeviceSearchType = 'device-groups';
    }

    const {
      value: deviceId,
      setValue: setDeviceId,
      bind: bindDeviceId,
      reset: resetDeviceId
    } = (0, _hooks.useInput)(props.deviceId || '');
    const {
      value: deviceName,
      setValue: setDeviceName,
      bind: bindDeviceName,
      reset: resetDeviceName
    } = (0, _hooks.useInput)(props.deviceName || '');
    const {
      value: deviceSearchType,
      bind: bindDeviceSearchType,
      reset: resetDeviceSearchType
    } = (0, _hooks.useInput)(initialDeviceSearchType);
    const {
      value: location,
      bind: bindLocation,
      reset: resetLocation
    } = (0, _hooks.useInput)(props.location || '');
    const local = {
      'format': 'MM/DD/YYYY HH:mm',
      sundayFirst: true
    };

    const search = params => {
      metrics.trackEvent('search submitted');
      const searchParams = Object.assign({}, {
        dateRange: [dateRange.start, dateRange.end],
        deviceId,
        deviceName,
        deviceGroups: selectedGroupsValue && selectedGroupsValue.map(g => g.id),
        location
      }, params);

      if (!searchParams.dateRange[0]) {
        delete searchParams.dateRange;
      }

      onSearch(searchParams);
    };

    const deviceSearchInput = deviceSearchType => {
      if (deviceSearchType === 'device-id') {
        return _react.default.createElement('input', _extends({
          type: 'search',
          placeholder: 'Search by Device ID'
        }, bindDeviceId));
      } else if (deviceSearchType === 'device-name') {
        return _react.default.createElement('input', _extends({
          type: 'search',
          placeholder: 'Search by Device Name'
        }, bindDeviceName));
      } else {
        return _react.default.createElement(StyledDeviceGroupsInput, {
          groups: groups,
          placeholder: 'Search by Device Groups',
          searchIndices: ['name'],
          selected: selectedGroupsValue,
          onChange: ({
            options
          }) => {
            setSelectedGroups(options);
          }
        });
      }
    };

    const dateRangeInput = () => {
      const formattedDateRangeStart = dateRange.start && dateRange.start.format(local.format) || '';
      const formattedDateRangeEnd = dateRange.end && dateRange.end.format(local.format) || '';
      return _react.default.createElement(_assetTrackerDatetimeRangePicker.default, {
        end: dateRange.end,
        start: dateRange.start,
        local: local,
        applyCallback: (start, end) => {
          // Use a magic number to represent "no date"
          if (start && start.isSame('1969-07-20')) {
            setDateRange({
              start: null,
              end: null
            }, () => search({
              dateRange: [null, null]
            }));
          } else {
            setDateRange({
              start,
              end
            }, () => search({
              dateRange: [start, end]
            }));
          }
        }
      }, _react.default.createElement(DatePickerTrigger, null, _react.default.createElement('input', {
        type: 'text',
        readOnly: true,
        value: formattedDateRangeStart ? "".concat(formattedDateRangeStart, " - ").concat(formattedDateRangeEnd) : 'Last Known'
      }), formattedDateRangeStart && _react.default.createElement(ClearButton, {
        type: 'button',
        onClick: e => {
          e.preventDefault();
          setDateRange({
            start: null,
            end: null
          }, () => search({
            dateRange: [null, null]
          }));
        }
      }, _react.default.createElement('i', {
        className: 'ion-close'
      }))));
    };

    const handleClear = e => {
      setDeviceId('');
      setDeviceName('');
      setDateRange({
        start: null,
        end: null
      });
      setSelectedGroups([]);
      onClear(e);
    };

    return _react.default.createElement(Form, {
      onSubmit: e => {
        e.preventDefault();
        search();
      }
    }, _react.default.createElement(RightLabeledInput, null, deviceSearchInput(deviceSearchType), _react.default.createElement(StyledDropdown, {
      options: deviceSearchOptions,
      value: deviceSearchType,
      onChange: value => {
        resetDeviceId();
        resetDeviceName();
        setSelectedGroups([]);
        bindDeviceSearchType.onChange(value);
      }
    })), !props.hideDateRange && dateRangeInput(), _react.default.createElement('div', null, _react.default.createElement(SearchButton, {
      className: 'ui button outline primary',
      type: 'submit'
    }, 'Search'), _react.default.createElement(ResetButton, {
      className: 'ui button outline primary',
      onClick: e => handleClear(e)
    }, 'Reset')));
  };

  AssetTrackerSearch.propTypes = {
    deviceId: _propTypes.default.string,
    deviceName: _propTypes.default.string,
    deviceGroups: _propTypes.default.string,
    dateRange: _propTypes.default.array,
    groupOptions: _propTypes.default.array,
    metrics: _propTypes.default.object.isRequired,
    onClear: _propTypes.default.func,
    onSearch: _propTypes.default.func,
    hideDateRange: _propTypes.default.bool
  };
  const deviceSearchOptions = [{
    label: 'Device ID',
    value: 'device-id'
  }, {
    label: 'Device Name',
    value: 'device-name'
  }, {
    label: 'Groups',
    value: 'device-groups'
  }];

  const Form = _styledComponents.default.form.withConfig({
    displayName: "asset-tracker-search__Form",
    componentId: "sc-1c0lbs9-0"
  })(["padding:1.6rem;display:grid;grid-template-columns:minmax(36.8rem,1fr) 1fr auto;grid-column-gap:1.6rem;margin-right:19.2rem;"]);

  const ClearButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-search__ClearButton",
    componentId: "sc-1c0lbs9-1"
  })(["background:transparent;border:none;"]);

  const DatePickerTrigger = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-search__DatePickerTrigger",
    componentId: "sc-1c0lbs9-2"
  })(["position:relative;&::before{content:\"\f162\";display:block;font-family:var(--icons-font);font-size:2.8rem;height:100%;left:0;line-height:3.6rem;position:absolute;text-align:center;top:0;width:4.4rem;}input{margin-bottom:0;padding:1.04rem 3.2rem 1.04rem 4.4rem;}", "{height:100%;position:absolute;right:0;top:0;width:3.2rem;}"], ClearButton);

  const StyledDeviceGroupsInput = (0, _styledComponents.default)(_deviceGroupsInput.default).withConfig({
    displayName: "asset-tracker-search__StyledDeviceGroupsInput",
    componentId: "sc-1c0lbs9-3"
  })(["border:none;background:none;canvas{border-radius:2px;}input{border:none;}.PowerSelect__Trigger{height:100%;border-radius:4px;border-top-right-radius:0;border-bottom-right-radius:0;border-right:none;}.PowerSelectMultiple__SelectedOptions{margin-bottom:0;}.PowerSelectMultiple__SelectedOption{background-color:var(--tertiary-background-color);border:1px solid var(--primary-border-color);border-radius:3px;&__Close{color:var(--tertiary-text-color);&:hover{background-color:var(--tertiary-background-color);color:var(--primary-text-color);}}}.PowerSelectMultiple__SelectedOption__Close{border-left:none;}"]);
  const StyledDropdown = (0, _styledComponents.default)(_reactDropdown.default).withConfig({
    displayName: "asset-tracker-search__StyledDropdown",
    componentId: "sc-1c0lbs9-4"
  })([".Dropdown-control{background-color:transparent;color:var(--primary-text-color);border-radius:4px;border-color:var(--primary-border-color);padding-right:30px;transition:none;}.Dropdown-arrow{top:16px;border-color:var(--primary-text-color) transparent transparent;}&.is-open .Dropdown-arrow{border-color:transparent transparent var(--primary-text-color);}.Dropdown-menu{background-color:var(--quaternary-background-color);border:1px solid var(--primary-border-color);}.Dropdown-option,.Dropdown-option.is-selected{background-color:transparent;color:var(--primary-text-color);}.Dropdown-option:hover{color:var(--secondary-text-color);}"]);

  const RightLabeledInput = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-search__RightLabeledInput",
    componentId: "sc-1c0lbs9-5"
  })(["display:grid;grid-template-columns:8fr minmax(14.4rem,4fr);input{border-top-right-radius:0;border-bottom-right-radius:0;border-right:0;margin-bottom:0;padding:0.7rem;}", "{.Dropdown-control{border-top-left-radius:0;border-bottom-left-radius:0;height:100%;line-height:1.5;}}"], StyledDropdown);

  const ResetButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-search__ResetButton",
    componentId: "sc-1c0lbs9-6"
  })(["display:inline-flex !important;height:39px !important;border-radius:4px;margin:0 1.6rem;text-transform:uppercase;padding:1.04rem 1.6rem;"]);

  const SearchButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-search__SearchButton",
    componentId: "sc-1c0lbs9-7"
  })(["border-radius:4px;display:inline-flex !important;height:39px !important;font-size:1.28rem;font-weight:700;letter-spacing:0.096rem;padding:1.04rem 1.6rem;text-transform:uppercase;transition:background 0.2s linear,border 0.2s linear;width:fit-content;i{font-size:1.6rem;margin-right:1.04rem;}"]);

  var _default = AssetTrackerSearch;
  _exports.default = _default;
});