define("pconsole/authenticators/oauth2-n", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "pconsole/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: "".concat(_environment.default.APP.API_ENDPOINT, "/oauth/token"),

    async makeRequest(url, data, headers) {
      let response;

      if (headers.impersonate) {
        // Verify token is valid and get username
        response = await Ember.$.ajax({
          url: "".concat(_environment.default.APP.API_ENDPOINT, "/v1/user"),
          crossDomain: true,
          headers: {
            Authorization: "Bearer ".concat(headers.impersonate)
          }
        });
        return {
          access_token: headers.impersonate,
          username: "IMPERSONATE ".concat(response.username)
        };
      }

      const {
        scope: otp
      } = data,
            authData = _objectWithoutProperties(data, ["scope"]);

      try {
        response = await Ember.$.ajax({
          url,
          type: 'POST',
          data: authData,
          dataType: 'json',
          contentType: 'application/x-www-form-urlencoded',
          crossDomain: true,
          headers: {
            Authorization: "Basic ".concat(btoa(_environment.default.APP.API_CLIENT_ID))
          }
        });
      } catch (error) {
        var _error$responseJSON;

        if ((error === null || error === void 0 ? void 0 : (_error$responseJSON = error.responseJSON) === null || _error$responseJSON === void 0 ? void 0 : _error$responseJSON.error) === 'mfa_required') {
          const mfaData = {
            grant_type: 'urn:custom:mfa-otp',
            otp: otp,
            mfa_token: error.responseJSON.mfa_token
          };
          response = await Ember.$.ajax({
            url,
            type: 'POST',
            data: mfaData,
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded',
            crossDomain: true,
            headers: {
              Authorization: "Basic ".concat(btoa(_environment.default.APP.API_CLIENT_ID))
            }
          });
        } else {
          throw error;
        }
      } // Causes username to be added to session.data.authenticated.username


      response.username = data.username;
      return response;
    }

  });

  _exports.default = _default;
});