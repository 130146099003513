define("pconsole/templates/components/dashboard-link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jnUn8Xm7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"id\"]]],null,{\"statements\":[[4,\"link-to\",[[20,[\"route\"]],[20,[\"id\"]]],[[\"class\",\"scope\",\"product\"],[[20,[\"class\"]],[20,[\"scope\"]],[20,[\"product\"]]]],{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[20,[\"route\"]]],[[\"class\",\"scope\",\"product\"],[[20,[\"class\"]],[20,[\"scope\"]],[20,[\"product\"]]]],{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/dashboard-link-to.hbs"
    }
  });

  _exports.default = _default;
});