define("pconsole/templates/components/device-assets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0lCG34xi",
    "block": "{\"symbols\":[\"asset\"],\"statements\":[[6,\"div\"],[9,\"class\",\"device-assets\"],[7],[0,\"\\n  \"],[6,\"h4\"],[9,\"class\",\"uppercase section-title\"],[7],[0,\"\\n    Assets\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"ul\"],[9,\"class\",\"device-assets__assets\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"assets\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[9,\"class\",\"device-assets__asset\"],[7],[6,\"div\"],[9,\"class\",\"hint hint--top\"],[10,\"data-hint\",[25,\"asset-hint\",[[19,1,[\"status\"]]],null],null],[7],[6,\"i\"],[10,\"class\",[26,[\"icon ion-record device-assets__status__\",[19,1,[\"status\"]]]]],[7],[8],[6,\"span\"],[9,\"class\",\"device-assets__name\"],[7],[1,[19,1,[\"name\"]],false],[8],[8],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/device-assets.hbs"
    }
  });

  _exports.default = _default;
});