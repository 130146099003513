define("pconsole/models/integration-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const IntegrationTemplate = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    shortDescription: _emberData.default.attr('string'),
    longDescription: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    links: _emberData.default.attr(),
    guide: _emberData.default.attr('string'),
    instructions: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    featured: _emberData.default.attr('boolean'),
    tags: _emberData.default.attr('string'),
    template: _emberData.default.attr(),
    simpleForm: _emberData.default.attr(),
    expandAdvanced: _emberData.default.attr('boolean')
  });

  const awsTemplateVariables = [{
    type: 'CREATE',
    templateName: 'AWS_REGION',
    sensitive: false
  }, {
    type: 'CREATE',
    templateName: 'AWS_ACCESS_KEY_ID',
    sensitive: true
  }, {
    type: 'CREATE',
    templateName: 'AWS_SECRET_ACCESS_KEY',
    sensitive: true
  }];
  const awsFormParameters = [{
    name: 'AWS Region',
    placeholder: 'us-east-1',
    variableName: 'AWS_REGION',
    helpText: 'The AWS region where the resource exists'
  }, {
    name: 'AWS Access Key ID',
    placeholder: 'Axxxxxxxxxxxxxxxxxxx',
    variableName: 'AWS_ACCESS_KEY_ID',
    // TODO fix helpText
    helpText: 'The access key ID that can interact with the resource',
    sensitive: true
  }, {
    name: 'AWS Secret Access Key',
    placeholder: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
    variableName: 'AWS_SECRET_ACCESS_KEY',
    helpText: 'The secret access key that can interact with the resource',
    sensitive: true
  }];
  /* eslint-disable quotes */

  IntegrationTemplate.reopenClass({
    FIXTURES: [{
      "id": "airtable",
      "name": "Airtable",
      "shortDescription": "Airtable is a cloud platform that combines a database and spreadsheet functionalities for collaborative work.",
      "longDescription": "Through the integration with Particle, Airtable enables IoT developers to structure, connect, and extend data collected from sensors and devices. This allows for the creation of custom workflow automations and easy visualization of data trends. The integration capabilities include:\n\n- Organizing device data into customizable tables and fields.\n- Linking data across different tables for relational database functionalities.\n- Automating repetitive tasks and data handling through Airtable's scripting block.\n- Utilizing shared views for team collaboration and project management.\n- Connecting IoT device data with other platforms and services using Airtable's rich API ecosystem.",
      "logo": "/assets/integrations/airtable.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://support.airtable.com/hc/en-us"
      }],
      "instructions": "Create a webhook in your Airtable account and insert the URL in the form.\n\nUpdate the names of the fields you want to set in the form.\n\nPublish the data from your device using `Particle.publish(\"data\", String::format(\"{ \\\"field1\\\": \\\"%d\\\", \\\"field2\\\": \\\"%d\\\" }\", value1, value2));` where `field1` and `field2` are the names of the fields in your Airtable.",
      "category": "Data analytics",
      "tags": "database,spreadsheet,collaboration,API,automation",
      "template": {
        "integrationType": "Webhook",
        "event": "airtable-data",
        "url": "https://hooks.airtable.com/workflows/v1/genericWebhook/APP_ID/WEBHOOK_ID",
        "requestType": "POST",
        "form": {
          "field1": "{{{field1}}}",
          "field2": "{{{field2}}}"
        },
        "noDefaults": true
      },
      "expandAdvanced": true
    }, {
      "id": "aws-lambda",
      "name": "AWS Lambda (API Gateway)",
      "shortDescription": "Amazon Web Services Lambda is a serverless computing service that automatically manages the computing resources, enabling users to run code in response to events without provisioning or managing servers.",
      "longDescription": "Integrating Particle with AWS Lambda opens up a new realm of possibilities for IoT developers by enabling serverless event-driven programming. This seamless integration allows for efficient and scalable processing of events from Particle devices, leveraging Lambda's ability to run code in response to triggers without the need for server management.\n\n- **Serverless Event Processing**: AWS Lambda facilitates the immediate processing of events generated by Particle devices, allowing for real-time data handling, transformation, and analysis without the overhead of server provisioning or scaling.\n- **Flexible Application Integration**: This integration enables developers to easily connect Particle devices with other AWS services or third-party applications, orchestrating complex workflows and extending IoT capabilities through serverless functions.",
      "logo": "/assets/integrations/aws-dark.svg",
      "documentation": "https://docs.aws.amazon.com/lambda/",
      "instructions": "1. Ensure your AWS user has the necessary permissions (**`execute-api:Invoke`**).\n2. Set up an API Gateway to act as the trigger for your Lambda function if you haven't already.\n3. Obtain the invoke URL of the API Gateway stage that connects to your Lambda function.\n4. In the webhook configuration, provide the user's key pair for AWS authentication and the API Gateway stage invoke URL.",
      "category": "Cloud services",
      "featured": true,
      "tags": "aws,cloud,lambda,serverless,compute",
      "template": {
        "integrationType": "Webhook",
        "event": "invoke-lambda",
        "url": "{{{LAMBDA_API_GATEWAY_ENDPOINT}}}",
        "requestMethod": "POST",
        'json': {
          "event_id": "{{{PARTICLE_EVENT_ID}}}",
          "event": "{{{PARTICLE_EVENT_NAME}}}",
          "data": "{{{PARTICLE_EVENT_VALUE}}}",
          "device_id": "{{{PARTICLE_DEVICE_ID}}}",
          "product_id": "{{{PRODUCT_ID}}}",
          "fw_version": "{{{PRODUCT_VERSION}}}",
          "published_at": "{{{PARTICLE_PUBLISHED_AT}}}"
        },
        noDefaults: true,
        "amazonToken": {
          "accessKeyId": "{{{AWS_ACCESS_KEY_ID}}}",
          "secretAccessKey": "{{{AWS_SECRET_ACCESS_KEY}}}"
        },
        variables: [{
          type: 'CREATE',
          templateName: 'LAMBDA_API_GATEWAY_ENDPOINT',
          sensitive: false
        }, ...awsTemplateVariables]
      },
      simpleForm: {
        description: 'Configure your Amazon Web Services Lambda through API Gateway',
        showJson: true,
        parameters: [{
          name: 'API Gateway endpoint',
          placeholder: 'https://APIGW_ENDPOINT.execute-api.us-east-1.amazonaws.com/asdf/123',
          variableName: 'LAMBDA_API_GATEWAY_ENDPOINT',
          helpText: 'The invoke URL of the API Gateway stage that connects to your Lambda function'
        }, ...awsFormParameters]
      },
      "expandAdvanced": true
    }, {
      "id": "aws-dynamodb",
      "name": "AWS DynamoDB",
      "shortDescription": "Amazon Web Services DynamoDB is a fully managed NoSQL database service that provides fast and predictable performance with seamless scalability for application data storage.\n",
      "longDescription": "Integrating Particle with AWS DynamoDB offers a streamlined approach for IoT developers to efficiently store and analyze event data from connected devices. This combination harnesses the power of a fully managed NoSQL database for real-time data storage and retrieval, facilitating the development of responsive and insightful IoT applications.\n\n- **Real-time Event Storage**: DynamoDB provides a highly scalable and fast database solution for storing event data generated by Particle devices, enabling immediate data availability for processing and analysis.\n- **Seamless Data Analysis**: The integration allows for the direct application of analytical tools on the stored data, supporting the extraction of meaningful insights through queries and data processing, which can drive informed decision-making and enhance application performance.",
      "logo": "/assets/integrations/aws-dark.svg",
      'links': [{
        'title': 'Documentation',
        'link': 'https://docs.aws.amazon.com/dynamodb/'
      }],
      "instructions": "1. Obtain the name of your DynamoDB table from the AWS Management Console.\n2. Ensure your AWS user has permissions to perform 'PutItem' operations on the table.\n3. In the webhook configuration, insert the ARN in the designated field, provide the AWS user's key pair for authentication, and update the URL with the AWS region of your DynamoDB table.",
      "category": "Data analytics",
      "tags": "aws,cloud,dynamo,dynamodb,storage",
      "template": {
        "integrationType": "Webhook",
        "event": "dynamo-data",
        "url": "https://dynamodb.{{{AWS_REGION}}}.amazonaws.com/",
        "requestMethod": "POST",
        "headers": {
          "X-Amz-Target": "DynamoDB_20120810.PutItem",
          "Content-Type": "application/x-amz-json-1.0"
        },
        "json": {
          "TableName": "{{{DYNAMODB_TABLE_NAME}}}",
          "Item": {
            "id": {
              "S": "{{{PARTICLE_EVENT_ID}}}"
            },
            "name": {
              "S": "{{{PARTICLE_EVENT_NAME}}}"
            },
            "value": {
              "S": "{{{PARTICLE_EVENT_VALUE}}}"
            },
            "device_id": {
              "S": "{{{PARTICLE_DEVICE_ID}}}"
            },
            "product_id": {
              "S": "{{{PRODUCT_ID}}}"
            },
            "published_at": {
              "S": "{{{PARTICLE_PUBLISHED_AT}}}"
            }
          }
        },
        "noDefaults": true,
        "amazonToken": {
          "accessKeyId": "{{{AWS_ACCESS_KEY_ID}}}",
          "secretAccessKey": "{{{AWS_SECRET_ACCESS_KEY}}}"
        },
        variables: [{
          type: 'CREATE',
          templateName: 'DYNAMODB_TABLE_NAME',
          sensitive: false
        }, ...awsTemplateVariables]
      },
      simpleForm: {
        description: 'Configure your Amazon Web Services DynamoDB',
        showJson: true,
        parameters: [{
          name: 'Dynamo Table Name',
          placeholder: 'TableName',
          variableName: 'DYNAMODB_TABLE_NAME',
          helpText: 'The name of the DynamoDB table'
        }, ...awsFormParameters]
      },
      "expandAdvanced": true
    }, {
      "id": "aws-s3",
      "name": "AWS S3",
      "shortDescription": "Amazon Web Services S3 is a cloud-based storage solution that offers scalable and secure data storage, backup, and retrieval capabilities.\n",
      "longDescription": "Integrating Particle with AWS S3 offers an effective solution for IoT applications, focusing on the long-term storage of event data. This integration ensures that data from IoT devices is seamlessly collected and securely stored, ready for in-depth analysis and insights.\n\n- **Scalable and Secure Storage**: AWS S3 provides robust and scalable storage for IoT event data, ensuring high security with encryption and data durability across multiple physical locations.\n- **Data Analysis**: By storing event data in AWS S3, organizations can easily apply data analysis tools such as AWS Athena, enabling efficient querying and analysis of large datasets to derive actionable insights and enhance decision-making processes.\n",
      "logo": "/assets/integrations/aws-dark.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.aws.amazon.com/s3/"
      }],
      "instructions": "1. Ensure your AWS user has the necessary permissions (**`firehose:PutRecord`**) for the Kinesis Firehose stream.\n2. Set up a Kinesis Firehose stream if not already done, and configure it to deliver data to your S3 bucket.\n3. Obtain the name of your Kinesis Firehose stream.\n4. In the webhook configuration, input the user's key pair for AWS authentication, the name of the Firehose stream to route data into your S3 bucket, and update the URL with the AWS region of your S3 bucket.",
      "category": "Data analytics",
      "tags": "aws,cloud,s3,storage",
      "template": {
        "integrationType": "Webhook",
        "event": "s3-data",
        "url": "https://firehose.{{{AWS_REGION}}}.amazonaws.com/",
        "requestMethod": "POST",
        "headers": {
          "X-Amz-Target": "Firehose_20150804.PutRecord",
          "Content-Type": "application/x-amz-json-1.1"
        },
        "json": {
          "DeliveryStreamName": "{{{FIREHOSE_STREAM_NAME}}}",
          "Record": {
            "Data": "{{{PARTICLE_EVENT_BASE64}}}"
          }
        },
        "noDefaults": true,
        "amazonToken": {
          "accessKeyId": "{{{AWS_ACCESS_KEY_ID}}}",
          "secretAccessKey": "{{{AWS_SECRET_ACCESS_KEY}}}"
        },
        variables: [{
          type: 'CREATE',
          templateName: 'FIREHOSE_STREAM_NAME',
          sensitive: false
        }, ...awsTemplateVariables]
      },
      simpleForm: {
        description: 'Configure your Amazon Web Services Firehose Stream to S3',
        showJson: true,
        parameters: [{
          name: 'Firehose Stream Name',
          placeholder: 'StreamName',
          variableName: 'FIREHOSE_STREAM_NAME',
          helpText: 'The name of the Firehose stream to route data into your S3 bucket'
        }, ...awsFormParameters]
      },
      "expandAdvanced": true
    }, {
      "id": "aws-sqs",
      "name": "AWS SQS",
      "shortDescription": "Amazon Web Services SQS is a cloud-based message queuing service that facilitates reliable and scalable message ingest.",
      "longDescription": "Integrating Particle with AWS SQS streamlines event ingest in IoT applications, ensuring scalable and secure handling of device-generated data. Key advantages include:\n\n- **Scalable and Reliable Event Ingest**: Leveraging AWS SQS for event ingest from Particle devices offers a reliable queueing mechanism, ensuring no data loss even during peak traffic, enabling scalable and robust data collection.\n- **Integration with AWS Platform**: Utilizing AWS SQS opens up opportunities for integrating with a wide range of AWS services like Lambda, Step Functions, and DynamoDB.\n\n",
      "logo": "/assets/integrations/aws-dark.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.aws.amazon.com/sqs/"
      }],
      "instructions": "1. Ensure your AWS user has permissions to **`sqs:SendMessage`** for the desired SQS queue.\n2. Create an SQS queue if you do not have one already, and obtain its URL from the AWS Management Console.\n3. In the webhook configuration, provide the queue URL to specify where messages should be sent, the user's key pair for AWS authentication, and update the URL with the AWS region of your SQS queue.",
      "category": "Cloud services",
      "tags": "aws,cloud,sqs,queue",
      "template": {
        "integrationType": "Webhook",
        "event": "sqs-data",
        "url": "https://sqs.{{{AWS_REGION}}}.amazonaws.com",
        "requestMethod": "POST",
        "headers": {
          "X-Amz-Target": "AmazonSQS.SendMessage",
          "Content-Type": "application/x-amz-json-1.0"
        },
        "json": {
          "QueueUrl": "{{{SQS_QUEUE_URL}}}",
          "MessageBody": "{{{PARTICLE_EVENT_JSON}}}"
        },
        "noDefaults": true,
        "amazonToken": {
          "accessKeyId": "{{{AWS_ACCESS_KEY_ID}}}",
          "secretAccessKey": "{{{AWS_SECRET_ACCESS_KEY}}}"
        },
        variables: [{
          type: 'CREATE',
          templateName: 'SQS_QUEUE_URL',
          sensitive: false
        }, ...awsTemplateVariables]
      },
      simpleForm: {
        description: 'Configure your Amazon Web Services SQS',
        parameters: [{
          name: 'SQS Queue URL',
          placeholder: 'https://sqs.us-east-1.amazonaws.com/12345678/my-fake-queue',
          variableName: 'SQS_QUEUE_URL',
          helpText: 'The URL of the SQS queue to route data into'
        }, ...awsFormParameters]
      },
      "expandAdvanced": true
    }, {
      "id": "azure-iot-hub",
      "name": "Azure IoT Hub",
      "shortDescription": "Stream Particle device data into the Microsoft Azure ecosystem through IoT Hub.",
      "longDescription": "Azure IoT Hub's integration with Particle's IoT platform offers enhanced IoT solutions development and management capabilities, including:\n\n- Streamlined data transmission and secure connectivity from Particle devices to Azure IoT Hub, facilitating improved device management and operational efficiency.\n- Advanced analytics and machine learning from Azure to transform IoT data into actionable insights for better predictive maintenance and decision-making processes.",
      "logo": "/assets/integrations/azure-iot-hub.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://learn.microsoft.com/en-us/azure/iot/"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/integrations/community-integrations/azure-iot-hub/"
      }],
      "instructions": "Follow the [guide in the Particle documentation](https://docs.particle.io/integrations/community-integrations/azure-iot-hub/) to set up your Azure IoT Hub integration.",
      "category": "Cloud services",
      "tags": "microsoft,azure,iot",
      "template": {
        "integrationType": "AzureIotHub"
      }
    }, {
      "id": "azure-websites",
      "name": "Azure Websites",
      "shortDescription": "Microsoft Azure Websites provides web hosting solutions for creating, deploying, and managing web applications.",
      "longDescription": "Microsoft Azure Websites enables seamless integration with Particle's IoT ecosystem, allowing for robust web application development and hosting. With this integration, developers can:\n\n* Effortlessly deploy IoT device management web portals hosted on Azure, enhancing the accessibility and control of particle-connected devices.\n* Utilize Azure's industrial-grade cloud infrastructure to monitor and manage IoT data, aiding in real-time decision making and scaled distribution of device-driven insights.\n",
      "logo": "/assets/integrations/azure.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.microsoft.com/en-us/azure/app-service/"
      }],
      "instructions": "Deploy an Azure Web App and obtain the URL. Use the URL in the form to start sending data to Azure Websites.",
      "category": "Cloud services",
      "featured": true,
      "tags": "microsoft,azure,web-apps,app-service,cloud-hosting",
      "template": {
        "integrationType": "Webhook",
        "event": "azure-data",
        "url": "https://YOUR_AZURE_WEBSITE.azurewebsites.net/PATH",
        "requestType": "POST",
        "json": true
      }
    }, {
      "id": "blynk",
      "name": "Blynk",
      "shortDescription": "Blynk is a low-code IoT software platform that offers mobile app builder, management portal, and cloud solutions.",
      "longDescription": "Blynk provides user-friendly mobile apps to interact with Particle devices. Use cases facilitated by this collaboration include:\n\n- Remote monitoring and visualization of sensor data from Particle devices in real-time through Blynk's mobile app or web dashboard.\n- Control of actuators and other connected hardware via intuitive widgets provided by Blynk, allowing for seamless user interactions.\n- Customizable notifications and alerts that can be set up to trigger based on data thresholds or other conditions detected by Particle sensors.\n- Data logging and event history tracking for analytical purposes, helping users understand and optimize their IoT environment.\n- The ability for users to quickly develop production-ready IoT applications and user interfaces without needing extensive programming knowledge.",
      "logo": "/assets/integrations/blynk.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.blynk.io"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://blynk.io/blog/how-to-connect-a-particle-device-to-blynk"
      }, {
        "title": "Blueprint demo project",
        "link": "https://blynk.io/blueprints/connect-particle-hardware-to-blynk"
      }],
      "instructions": "Refer to the [Blynk integration guide](https://blynk.io/blog/how-to-connect-a-particle-device-to-blynk) on how to set up your account.\n\nInsert the server URL with your Blynk server URL and the auth token with your [Blynk auth token](https://docs.blynk.io/en/getting-started/activating-devices/manual-device-activation#getting-auth-token).\n\nYou can modify the keys in the Query section of the webhook to correspond to the names of your Blynk virtual pins (by default V0 to V5).\n\nThe [blueprint project](https://blynk.io/blueprints/connect-particle-hardware-to-blynk) will automatically configure the template in the Blynk platform, including a basic mobile and web dashboards.",
      "category": "IoT solutions",
      "featured": true,
      "tags": "dashboard,remote control,user interface,data logging,notifications",
      "template": {
        "integrationType": "Webhook",
        "event": "blynk_get",
        "url": "https://{{{BLYNK_SERVER_ADDRESS}}}/external/api/batch/update",
        "requestType": "GET",
        "query": {
          "V0": "{{{v0}}}",
          "V1": "{{{v1}}}",
          "V2": "{{{v2}}}",
          "V3": "{{{v3}}}",
          "V4": "{{{v4}}}",
          "V5": "{{{v5}}}",
          "V6": "{{{PARTICLE_PUBLISHED_AT}}}",
          "token": "{{{BLYNK_AUTH_TOKEN}}}"
        },
        "noDefaults": true,
        variables: [{
          type: 'CREATE',
          templateName: 'BLYNK_SERVER_ADDRESS',
          sensitive: false
        }, {
          type: 'CREATE',
          templateName: 'BLYNK_AUTH_TOKEN',
          sensitive: true
        }]
      },
      "expandAdvanced": true,
      simpleForm: {
        description: 'Configure your Blynk integration',
        showQuery: true,
        parameters: [{
          name: 'Blynk Server Address',
          placeholder: 'ny3.blynk.cloud',
          variableName: 'BLYNK_SERVER_ADDRESS',
          helpText: 'The server address shown at the bottom right of your Blynk Console'
        }, {
          name: 'Blynk Auth Token',
          placeholder: 'token',
          variableName: 'BLYNK_AUTH_TOKEN',
          helpText: 'The auth token from your Blynk Console',
          sensitive: true
        }]
      }
    }, {
      "id": "datacake",
      "name": "Datacake",
      "shortDescription": "Datacake offers a low-code IoT platform for device management, visualization, and analytics.",
      "longDescription": "Datacake has a native integration with Particle.\n\nDatacake allows creating custom dashboards to visualize sensor data from Particle devices in real-time, allowing for immediate insight into device performance and environmental metrics. Leveraging Datacake's low-code approach, developers can build custom IoT applications rapidly, without deep technical expertise in backend infrastructure.\n\nKey features enabled by this integration include:\n- Rapid development of IoT applications with minimal coding required.\n- Creation and sharing of custom, real-time dashboards for data monitoring.\n- Flexible rule engine for setting up automated alerts and actions based on sensor data.\n- Integration of Particle data with third-party tools to enhance IoT ecosystems.",
      "logo": "/assets/integrations/datacake.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.datacake.de/integrations/particle/get-started"
      }],
      "instructions": "This integration is pre-configured to send data to Datacake. There is nothing extra to configure in the form.\n\nFollow the [guide to Datacake's native Particle.io integration](https://docs.datacake.de/integrations/particle/get-started) for more information on how to link your Particle account with Datacake.\n",
      "category": "IoT solutions",
      "featured": true,
      "tags": "analytics,visualization,dashboard,low-code,device-management",
      "template": {
        "integrationType": "Webhook",
        "event": "datacake-data",
        "url": "https://api.datacake.co/integrations/particle/",
        "requestType": "POST",
        "json": true
      }
    }, {
      "id": "datadog",
      "name": "Datadog",
      "shortDescription": "Datadog offers cloud-scale monitoring and analytics across systems, applications, and services.",
      "longDescription": "Integrating Particle with Datadog enables real-time visibility into IoT device data and system performance. By connecting Particle's device data streams to Datadog's monitoring platform, users can:\n\n- Analyze and visualize large-scale device deployments\n- Set up sophisticated alerting mechanisms for device metrics and events\n- Correlate Particle device data with other services and applications monitored by Datadog\n- Utilize Datadog's comprehensive analytics tools for IoT incident management and troubleshooting\n- Enhance operations efficiency with predictive analytics based on historical data trends\n- Streamline the process of monitoring device fleet health and performance metrics\n- Implement custom dashboards for at-a-glance insights into the IoT ecosystem\n\nThis integration empowers businesses to proactively manage their IoT environment, ensuring optimal functionality and performance.",
      "logo": "/assets/integrations/datadog.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.datadoghq.com/integrations/"
      }],
      "instructions": "\nCreate an API token in Datadog and put it in the headers field.\n\nCreate a timeseries in Datadog.\n\nPublish data from your device in JSON format with a timestamp in seconds since the epoch and data in floating point.\n\n`Particle.publish(\"data\", String::format(\"{ \\\"time\\\": %d, \\\"data\\\": %f }\", Time.now(), value);`\n",
      "category": "Automation & monitoring",
      "featured": true,
      "tags": "monitoring,analytics,cloud,visualization,alerting",
      "template": {
        "integrationType": "Webhook",
        "event": "datadog-data",
        "url": "https://app.datadoghq.com/api/v2/series",
        "headers": {
          "DD-API-KEY": "{{{DATADOG_API_KEY}}}"
        },
        "json": "{\n    \"series\": [\n        {\n            \"metric\": \"particle.data\",\n            \"points\": [\n                {\n                    \"timestamp\": {{{time}}},\n                    \"value\": {{{data}}}\n                }\n            ],\n            \"type\": \"gauge\"\n        }\n    ]\n}",
        "noDefaults": true,
        variables: [{
          type: 'CREATE',
          templateName: 'DATADOG_API_KEY',
          sensitive: true
        }]
      },
      "expandAdvanced": true,
      simpleForm: {
        description: 'Configure your Datadog integration',
        showJson: true,
        parameters: [{
          name: 'Datadog API Key',
          placeholder: 'API Key',
          variableName: 'DATADOG_API_KEY',
          helpText: 'The API key for your Datadog account',
          sensitive: true
        }]
      }
    }, {
      "id": "firebase",
      "name": "Firebase",
      "shortDescription": "Firebase is a development platform for building mobile and web applications with powerful real-time data synchronization.",
      "longDescription": "Integrating Particle with Firebase allows IoT devices to benefit from Firebase's real-time database services. Here are some use cases for this integration:\n\n- **Real-time Data Streaming:** Stream sensor readings or device status updates directly to a Firebase Real-time Database, enabling live dashboards and instant analytics.\n- **User Authentication:** Utilize Firebase Authentication to manage user access and data permissions for IoT devices.\n- **Device Configuration:** Store and retrieve device configuration parameters in Firebase, allowing real-time updates and remote device management.\n- **Event Logging:** Send event logs to Firebase for audit trails, diagnostic purposes, or to trigger cloud functions.\n- **Notifications:** Use Firebase Cloud Messaging to send notifications or alerts triggered by IoT device sensor readings.\n- **Data Storage:** Keep historical data in Firebase for further analysis or to inform machine learning models.\n- **Serverless Functions:** Integrate with Cloud Functions for Firebase to run backend code in response to device events without managing servers.",
      "logo": "/assets/integrations/firebase.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://firebase.google.com/docs"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://github.com/rickkas7/firebase_tutorial"
      }],
      "instructions": "Follow the instructions in [this tutorial](https://github.com/rickkas7/firebase_tutorial) to set up a Firebase project to work with Particle.\n\nThe project ID will be used at the start of the URL field.\n\nCreate a Firebase access token. It will be inserted into the `auth` field.\n\nYou'll be ready to start sending data to Firebase.",
      "category": "Cloud services",
      "tags": "firebase,realtime,database,authentication,messaging,storage",
      "template": {
        "integrationType": "Webhook",
        "event": "firebase-data",
        "url": "https://{{{FIREBASE_PROJECT_ID}}}.firebaseio.com/{{{PARTICLE_DEVICE_ID}}}/events.json",
        "requestType": "POST",
        "json": {
          "data": "{{{PARTICLE_EVENT_VALUE}}}",
          "ts": "{{{PARTICLE_PUBLISHED_AT}}}"
        },
        "noDefaults": true,
        "query": {
          "auth": "{{{FIREBASE_AUTH_TOKEN}}}"
        },
        variables: [{
          type: 'CREATE',
          templateName: 'FIREBASE_PROJECT_ID',
          sensitive: false
        }, {
          type: 'CREATE',
          templateName: 'FIREBASE_AUTH_TOKEN',
          sensitive: true
        }]
      },
      "expandAdvanced": true,
      simpleForm: {
        description: 'Configure your Firebase integration',
        parameters: [{
          name: 'Firebase Project ID',
          placeholder: 'project-id',
          variableName: 'FIREBASE_PROJECT_ID',
          helpText: 'The Firebase project ID'
        }, {
          name: 'Firebase Auth Token',
          placeholder: 'auth-token',
          variableName: 'FIREBASE_AUTH_TOKEN',
          helpText: 'The Firebase auth token',
          sensitive: true
        }]
      }
    }, {
      "id": "google-maps",
      "name": "Google Maps",
      "shortDescription": "Use the Google Maps Geolocation API to geolocate Particle devices via visible Wi-Fi access points or Cellular towers.",
      "longDescription": "Leveraging the Google Maps Geolocation API, businesses can enhance the functionality of Particle devices by enabling precise geolocation through visible Wi-Fi access points or cellular towers. This integration provides a powerful tool for location-based services and analytics.\n\n- **Accurate Device Localization**: Utilizes the extensive database of Wi-Fi and cellular data within the Google Maps Geolocation API to pinpoint Particle devices' locations with high accuracy.\n- **Location-aware Services**: Enables the development of advanced applications and services that respond dynamically to the geographical position of Particle devices, optimizing operations and user experiences based on location data.",
      "logo": "/assets/integrations/google-maps.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://developers.google.com/maps/documentation/geolocation/overview"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/getting-started/integrations/google-maps/"
      }],
      "instructions": "Follow [this guide](https://docs.particle.io/getting-started/integrations/google-maps/) to set up your Google Maps geolocation integration.",
      "category": "IoT solutions",
      "tags": "geolocation,mapping,data visualization",
      "template": {
        "integrationType": "GoogleMaps"
      }
    }, {
      "id": "google-cloud-pubsub",
      "name": "Google Cloud Platform",
      "shortDescription": "Tie into Google's enterprise grade suite of cloud-based data storage and analysis tools.",
      "longDescription": "Integrating Particle devices with the Google Cloud Pub/Sub platform empowers businesses with a robust suite of cloud-based data storage and analysis tools, designed to scale from small projects to enterprise-grade applications. This connection facilitates seamless data flow between Particle devices and the Google Cloud ecosystem, unlocking new potentials in IoT data management and analytics.\n\n- **Scalable Data Pipeline**: Establishes a secure, scalable pipeline for streaming data from Particle devices to Google Cloud Pub/Sub, enabling real-time processing and analysis.\n- **Advanced Data Analytics and Storage**: Leverages Google Cloud's powerful data analytics and storage capabilities to enhance decision-making, optimize operations, and create innovative services based on insights derived from IoT data.\n\n\n\n\n\n",
      "logo": "/assets/integrations/google-cloud.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://cloud.google.com/"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/integrations/google-cloud-platform/"
      }],
      "instructions": "Follow [this guide](https://docs.particle.io/integrations/google-cloud-platform/) to set up your Google Cloud Platform integration.",
      "category": "Cloud services",
      "tags": "google,cloud,cloud-hosting",
      "template": {
        "integrationType": "GoogleCloudPubSub"
      }
    }, {
      "id": "google-sheets",
      "name": "Google Sheets",
      "shortDescription": "Google Sheets is a cloud-based spreadsheet application that enables real-time collaboration and data analysis.",
      "longDescription": "Google Sheets is a versatile tool for data management and analysis, and when integrated with Particle using Apps Script, it can be used for a variety of use cases:\n\n- **Data Collection**: Store and organize data from Particle devices in a structured manner for further analysis.\n- **Data Visualization**: Create custom charts and graphs to visualize data trends and patterns.\n- **Data Analysis**: Leverage the power of Google Sheets to perform complex calculations and statistical analysis on IoT data.\n- **Real-time Collaboration**: Share and collaborate on data with team members in real-time, enabling collective decision-making.\n- **Automation**: Use Google Apps Script to automate tasks and workflows based on Particle device data.\n- **Integration with other Google Services**: Integrate with other Google services like Google Forms, Google Calendar, and Google Drive to extend functionality and create a comprehensive IoT ecosystem.",
      "logo": "/assets/integrations/google-sheets.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://developers.google.com/apps-script/overview"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/integrations/community-integrations/publish-to-google-sheets"
      }],
      "instructions": "Create a Google Sheets document with a script to receive data from the Particle Cloud by following the instructions in [this guide](https://docs.particle.io/integrations/community-integrations/publish-to-google-sheets).\n\nAfter you publish the script, you will receive a Webhook URL. Use the URL in the form to start sending data to Google Sheets.",
      "category": "Data analytics",
      "tags": "data collection,data visualization,data analysis,real-time collaboration,automation",
      "template": {
        "integrationType": "Webhook",
        "event": "sheet-data",
        "url": "https://script.google.com/macros/s/YOUR_SCRIPT_ID_HERE/exec",
        "requestType": "POST"
      }
    }, {
      "id": "ifttt",
      "name": "IFTTT",
      "shortDescription": "IFTTT connects different web services through simple conditional statements.",
      "longDescription": "IFTTT, or \"If This Then That,\" enables automated interactions between Particle devices and a multitude of web services and applications. \n\nWith IFTTT, users can create applets that trigger actions based on specific conditions set on their Particle devices. Use cases include:\n\n* Automating smart home devices when environmental sensors detect changes.\n* Logging data to spreadsheets for record-keeping when events occur.\n* Receiving notifications on smartphones or other services when predefined events are triggered on Particle devices.\n* Controlling IoT appliances remotely based on inputs such as location, time, or other web services.\n* Integrating with popular platforms for smart device management, social media interaction, and task automation.\n\nThis flexibility broadens the functionality and utility of Particle IoT solutions.",
      "logo": "/assets/integrations/ifttt-square.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://platform.ifttt.com/docs"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/integrations/community-integrations/ifttt/"
      }],
      "instructions": "Follow the instructions in [this guide](https://docs.particle.io/integrations/community-integrations/ifttt/) to set up an IFTTT applet that can be triggered by Particle events.\n\nAfter creating the applet, you will receive a Webhook URL that you need to put in the URL field.",
      "category": "Automation & monitoring",
      "tags": "automation,applets,web-services,smart-home",
      "template": {
        "integrationType": "Webhook",
        "event": "ifttt-trigger",
        "url": "https://maker.ifttt.com/trigger/{{{IFTTT_EVENT_NAME}}}/with/key/{{{IFTTT_KEY}}}",
        "requestType": "POST",
        "json": true,
        variables: [{
          type: 'CREATE',
          templateName: 'IFTTT_KEY',
          sensitive: true
        }, {
          type: 'CREATE',
          templateName: 'IFTTT_EVENT_NAME',
          sensitive: false
        }]
      },
      simpleForm: {
        description: 'Configure your IFTTT integration',
        parameters: [{
          name: 'IFTTT Key',
          placeholder: 'API-KEY-123',
          variableName: 'IFTTT_KEY',
          helpText: 'The key for your IFTTT account',
          sensitive: true
        }, {
          name: 'IFTTT Event Name',
          placeholder: 'EventName',
          variableName: 'IFTTT_EVENT_NAME',
          helpText: 'The name of the event to trigger in IFTTT'
        }]
      }
    }, {
      "id": "influxdata",
      "name": "InfluxData",
      "shortDescription": "InfluxData specializes in time series databases and real-time analytics.",
      "longDescription": "InfluxData's integration with Particle enhances IoT applications with powerful data storage and real-time analysis capabilities. Here are several use cases:\n\n* **Time Series Data Storage**: Collecting and storing high-frequency time series data from Particle devices for historical analysis and trend observation.\n* **Real-Time Event Processing**: Leveraging InfluxData's real-time analytics to process and act upon data as it's generated, enabling responsive IoT systems.\n* **Data Visualization**: Utilizing InfluxData's tools to create dynamic dashboards for data visualization, providing insights into IoT device performance.\n* **Anomaly Detection**: Implementing algorithms to detect unusual patterns or events in data streams, facilitating proactive maintenance and security.\n* **Scalability**: Handling large volumes of data efficiently, ensuring that as the number of Particle-connected devices grows, data processing doesn't become a bottleneck.",
      "logo": "/assets/integrations/influxdata.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.influxdata.com/"
      }],
      "instructions": "Create an account on InfluxData Cloud and create a new bucket.\n\nCreate a new token with write permissions.\n\nReplace the server URL with your InfluxData Cloud server URL and the token with your InfluxData Cloud token.\n\nPublish an event from your device with the name of the measurement and the fields in JSON format.\n\n`Particle.publish(\"data\", String::format(\"my-measurement,field1=%d field2=%d\", value1, value2));`\n",
      "category": "Data analytics",
      "tags": "time series,analytics,database,real-time,visualization",
      "template": {
        "integrationType": "Webhook",
        "event": "timeseries",
        "url": "https://{{{INFLUXDATA_REGION}}}.aws.cloud2.influxdata.com/api/v2/write?org={{{INFLUXDATA_ORG_ID}}}&bucket={{{INFLUXDATA_BUCKET_NAME}}}&precision=s",
        "headers": {
          "Authorization": "Token {{{INFLUXDATA_TOKEN}}}"
        },
        "requestType": "POST",
        "body": "{{{PARTICLE_EVENT_VALUE}}}",
        variables: [{
          type: 'CREATE',
          templateName: 'INFLUXDATA_TOKEN',
          sensitive: true
        }, {
          type: 'CREATE',
          templateName: 'INFLUXDATA_REGION',
          sensitive: false
        }, {
          type: 'CREATE',
          templateName: 'INFLUXDATA_ORG_ID',
          sensitive: false
        }, {
          type: 'CREATE',
          templateName: 'INFLUXDATA_BUCKET_NAME',
          sensitive: false
        }]
      },
      expandAdvanced: true,
      simpleForm: {
        description: 'Configure your InfluxData integration',
        parameters: [{
          name: 'InfluxData Cloud Token',
          placeholder: 'AUTH_TOKEN',
          variableName: 'INFLUXDATA_TOKEN',
          helpText: 'The token for your InfluxData Cloud account',
          sensitive: true
        }, {
          name: 'InfluxData Cloud Region',
          placeholder: 'us-east-1',
          variableName: 'INFLUXDATA_REGION',
          helpText: 'The URL of your InfluxData Cloud server'
        }, {
          name: 'InfluxData Cloud Organization ID',
          placeholder: 'ORG_ID',
          variableName: 'INFLUXDATA_ORG_ID',
          helpText: 'The organization ID for your InfluxData Cloud account'
        }, {
          name: 'InfluxData Cloud Bucket Name',
          placeholder: 'BUCKET_NAME',
          variableName: 'INFLUXDATA_BUCKET_NAME',
          helpText: 'The bucket name for your InfluxData Cloud account'
        }]
      }
    }, {
      "id": "losant",
      "name": "Losant Enterprise IoT Platform",
      "shortDescription": "Losant provides an enterprise IoT platform for building and visualizing real-time Internet of Things solutions.",
      "longDescription": "Losant's integration with Particle allows for the seamless flow of data from Particle devices into the Losant platform. Here's what this enables:\n\n- Creation of comprehensive IoT applications combining Particle devices with Losant's advanced workflows.\n- Real-time data visualization and monitoring through Losant dashboards, enhancing the analysis of data collected from Particle-powered IoT deployments.\n- Utilization of Losant's Edge Compute capabilities to process data on the edge before sending it to Particle's cloud, optimizing bandwidth and response times.\n- Deployment of robust IoT solutions that can scale from prototype to production with Particle's hardware and Losant's back-end infrastructure.\n- Integration of Losant's event-driven architecture to trigger actions based on data received from Particle devices, improving automation and control across IoT systems.\n- Enhancement of IoT security by leveraging Particle's secure device-to-cloud communication within Losant's platform.",
      'logo': '/assets/integrations/losant.svg',
      'links': [{
        'title': 'Documentation',
        'link': 'https://docs.losant.com/'
      }, {
        'title': 'Step-by-step setup guide',
        'link': 'https://www.losant.com/blog/how-to-integrate-particle-with-losant'
      }],
      "instructions": "Follow the [Losant integration guide](https://www.losant.com/blog/how-to-integrate-particle-with-losant) to set up your account and create a webhook to send data to Losant.\n\nThe webhook that will be created will include a unique key in the URL that will be used to route the data to your account in Losant.",
      "category": "IoT solutions",
      "featured": true,
      "tags": "losant,enterprise,visualization,workflows,edge-compute,real-time,monitoring,iot-applications",
      "template": {
        "integrationType": "Webhook",
        "event": "losant-data",
        "url": "https://triggers.losant.com/webhooks/WEBHOOK_ID",
        "requestType": "POST",
        "json": true
      }
    }, {
      "id": "pagerduty",
      "name": "PagerDuty",
      "shortDescription": "PagerDuty is a real-time incident management platform that streamlines incident resolution workflows.",
      "longDescription": "By integrating PagerDuty with Particle devices, businesses can streamline their incident response workflow, allowing IoT devices to automatically trigger alerts on PagerDuty. This ensures timely notification and response to system anomalies or issues.\n\n- **Automated Alerting**: Enables Particle devices to automatically send alerts to PagerDuty, ensuring quick notification of on-call personnel for immediate action.\n- **Incident Management and Analytics**: Facilitates efficient tracking and management of incidents reported by devices, while also providing insights for enhancing service reliability and implementing preventive measures.",
      "logo": "/assets/integrations/pagerduty.svg",
      'links': [{
        'title': 'Documentation',
        'link': 'https://developer.pagerduty.com/docs/'
      }],
      "instructions": "The PagerDuty Events API v2 requires a `routing_key`. [Create an integration on any PagerDuty service](https://support.pagerduty.com/docs/services-and-integrations#section-events-API-v2) in order to get a routing key that will route an event to that service.\n\nFrom the device, publish an event with the an `event_action` of `trigger`, `acknowledge`, or `resolve`.\n\n`Particle.publish(\"alert\", \"{\\\"event_action\\\":\\\"trigger\\\",\\\"summary\\\": \\\"Something's wrong!\\\" }\");`\n",
      "category": "Automation & monitoring",
      "featured": true,
      "tags": "incident management, alerts, on-call, response, analytics",
      "template": {
        "integrationType": "Webhook",
        "event": "alert",
        "url": "https://events.pagerduty.com/v2/enqueue",
        "json": {
          "routing_key": "{{{PAGERDUTY_ROUTING_KEY}}}",
          "event_action": "{{{event_action}}}",
          "payload": {
            "summary": "{{{summary}}}",
            "source": "{{{PARTICLE_DEVICE_ID}}}",
            "severity": "error"
          }
        },
        "noDefaults": true,
        variables: [{
          type: 'CREATE',
          templateName: 'PAGERDUTY_ROUTING_KEY',
          sensitive: true
        }]
      },
      "expandAdvanced": true,
      simpleForm: {
        description: 'Configure your PagerDuty integration',
        showJson: true,
        parameters: [{
          name: 'PagerDuty Routing Key',
          placeholder: 'Routing Key',
          variableName: 'PAGERDUTY_ROUTING_KEY',
          helpText: 'The routing key for your PagerDuty service',
          sensitive: true
        }]
      }
    }, {
      "id": "slack",
      "name": "Slack",
      "shortDescription": "Slack offers a channel-based messaging platform for team collaboration.",
      "longDescription": "Integrating Slack with Particle enables direct communication between IoT devices and team members. Use cases include:\n\n- Sending real-time alerts from IoT sensors to designated Slack channels, facilitating prompt response to critical events.\n- Streamlining workflow by automating device commands directly from Slack messages, without the need for additional interfaces.\n- Monitoring IoT device status updates in Slack, ensuring that all team members are informed of the system's health.\n- Enabling remote troubleshooting through Slack, allowing for quick diagnosis and issue resolution.\n- Collaboration on IoT projects by sharing data visuals and analysis amongst team members within Slack.\n- Utilizing Slack's chatbot functionalities to create an interactive experience with IoT devices for controlling and querying device states.\n- Gathering feedback on device performance and user engagement directly through Slack surveys.",
      "logo": "/assets/integrations/slack.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://api.slack.com"
      }],
      "instructions": "Follow these steps to send a message from a Particle device to Slack.\n* From Slack, click Customize then Configure Apps.\n* From Custom Integration, click Incoming Webhooks.\n* Click Add Configuration.\n* Select a channel, then click Add Incoming Webhook.\n* Copy the Webhook URL and paste it into the Particle webhook form.\n* Publish data from your device using `Particle.publish(\"data\", \"Hello, world!\");`.\n",
      "category": "Communication tools",
      "tags": "messaging, collaboration, alerts, chatbot, notifications",
      "template": {
        "integrationType": "Webhook",
        "event": "message",
        "url": "{{{SLACK_WEBHOOK_URL}}}",
        "requestType": "POST",
        "json": {
          "text": "{{{PARTICLE_EVENT_VALUE}}}"
        },
        "noDefaults": true,
        variables: [{
          type: 'CREATE',
          templateName: 'SLACK_WEBHOOK_URL',
          sensitive: true
        }]
      },
      "expandAdvanced": true,
      simpleForm: {
        description: 'Configure your Slack integration',
        showJson: true,
        parameters: [{
          name: 'Slack Webhook URL',
          placeholder: 'https://hooks.slack.com/services/T01234/B01234/XXXXXXXXXXXXXXXX',
          variableName: 'SLACK_WEBHOOK_URL',
          helpText: 'The Webhook URL for your Slack channel',
          sensitive: true
        }]
      }
    }, {
      "id": "thingspeak",
      "name": "ThingSpeak",
      "shortDescription": "ThingSpeak is an IoT analytics platform service that allows for aggregation, visualization, and analysis of live data streams in the cloud.",
      "longDescription": "Through the integration with Particle, users can harness the capabilities of ThingSpeak to gain insights from IoT devices. The platform provides the following use cases:\n\n- **Data Collection**: Securely collect data from multiple Particle devices to ThingSpeak channels.\n- **Data Processing**: Execute MATLAB code on live data for preprocessing or analysis.\n- **Visualization**: Create customizable, shareable visualizations and dashboards to monitor IoT device states and sensor readings.\n- **Alerts**: Set up real-time alerts based on data thresholds to enable prompt responses.\n- **Device Control**: Use ThingSpeak to send commands to Particle devices, allowing for real-time interaction and control.\n- **Integration with other services**: Share and analyze IoT data with services like MATLAB, and third-party APIs for extended functionality.",
      "logo": "/assets/integrations/thingspeak.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://www.mathworks.com/help/thingspeak/"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/integrations/webhooks/#configure-thingspeak"
      }],
      "instructions": "Follow the instructions in the [Thingspeak documentation](https://www.mathworks.com/help/thingspeak/) to create an account.\n\nCreate a new channel and obtain the Write API Key. Insert the write API Key in the `api_key` field to start sending data to ThingSpeak.\n\n**Writing multiple fields at once**\n\nIf your device publishes events encoded as JSON, you can write multiple fields at once.\n\nFor example, publish an event with the following format: `{ \"temperature\": 32, \"humidity\": 80 }` and in the form configuration, add the fields `field1` set to `{{{temperature}}}` and `field2` set to `{{{humidity}}}`.",
      "category": "Data analytics",
      "featured": true,
      "tags": "analytics,data visualization,data aggregation,real-time,data processing",
      "template": {
        "integrationType": "Webhook",
        "event": "thingspeak-data",
        "url": "https://api.thingspeak.com/update",
        "requestType": "POST",
        "form": {
          "api_key": "{{{THINGSPEAK_WRITE_API_KEY}}}",
          "field1": "{{{PARTICLE_EVENT_VALUE}}}"
        },
        "noDefaults": true,
        variables: [{
          type: 'CREATE',
          templateName: 'THINGSPEAK_WRITE_API_KEY',
          sensitive: true
        }]
      },
      "expandAdvanced": true,
      simpleForm: {
        description: 'Configure your ThingSpeak integration',
        showForm: true,
        parameters: [{
          name: 'ThingSpeak Write API Key',
          placeholder: 'API Key',
          variableName: 'THINGSPEAK_WRITE_API_KEY',
          sensitive: true
        }]
      }
    }, {
      "id": "thingworx",
      "name": "ThingWorx IoT Platform",
      "shortDescription": "ThingWorx is a platform for industrial innovation, offering end-to-end IoT solutions.",
      "longDescription": "ThingWorx integration with Particle enables seamless IoT device management and real-time data collection. This connectivity allows for efficient monitoring and control of connected devices, leading to enhanced operational efficiency and data-driven decision-making. The synergy between ThingWorx and Particle offers:\n\n- **Simplified Device Management**: Quickly onboard, monitor, and manage IoT devices across various locations, ensuring consistent performance and reduced downtime.\n- **Advanced Analytics**: Leverage ThingWorx's robust analytics capabilities to transform data into actionable insights, optimizing processes and predicting maintenance needs.\n- **Real-time Dashboarding and Alerts**: Create custom dashboards to visualize IoT data streams and receive alerts on critical events, enabling immediate response to system irregularities.\n- **Secure Data Integration**: Ensure data security while integrating Particle's IoT data with ThingWorx's platform for a cohesive IoT ecosystem.",
      "logo": "/assets/integrations/thingworx.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://developer.thingworx.com/en/resources"
      }],
      "instructions": "Update the URL with your ThingWorx server and the name of the Thing and Service where to send the data.",
      "category": "IoT solutions",
      "featured": true,
      "tags": "industrial,innovation,analytics,management,monitoring,real-time,dashboard,security",
      "template": {
        "integrationType": "Webhook",
        "event": "thingworx-data",
        "url": "{{{THINGWORKX_URL}}}",
        "requestType": "POST",
        "headers": {
          "appKey": "{{{THINGWORKX_APP_KEY}}}"
        },
        "json": true,
        variables: [{
          type: 'CREATE',
          templateName: 'THINGWORKX_APP_KEY',
          sensitive: true
        }, {
          type: 'CREATE',
          templateName: 'THINGWORKX_URL',
          sensitive: false
        }]
      },
      simpleForm: {
        description: 'Configure your ThingWorx integration',
        parameters: [{
          name: 'ThingWorx App Key',
          placeholder: 'App Key',
          variableName: 'THINGWORKX_APP_KEY',
          helpText: 'The App Key for your ThingWorx account',
          sensitive: true
        }, {
          name: 'ThingWorx URL',
          placeholder: 'https://COMPANY.cloud.thingworx.com/Thingworx/Things/THING_NAME/Services/SERVICE_NAME',
          variableName: 'THINGWORKX_URL',
          helpText: 'The URL for your ThingWorx account'
        }]
      }
    }, {
      "id": "twilio",
      "name": "Twilio",
      "shortDescription": "Twilio provides cloud communications services such as messaging, voice, and video.",
      "longDescription": "Particle's integration with Twilio empowers IoT devices with reliable communication capabilities. Whether you're building smart home applications, industrial monitoring systems, or wearable health devices, Particle and Twilio together enable a multitude of use cases:\n\n- **Instant Alerts** - Send SMS notifications for critical device events.\n- **Remote Control** - Enable voice commands to control IoT devices remotely.\n- **User Verification** - Implement two-factor authentication for secure device access.\n- **Environmental Monitoring** - Dispatch alerts based on sensor data thresholds.\n- **Fleet Management** - Utilize SMS for real-time communication with a fleet of devices.\n- **Health Monitoring** - Securely relay patient data via voice or video communication to healthcare providers.\n- **Smart Agriculture** - Notify farmers about livestock or crop conditions.\n- **Energy Management** - Automate alerts for energy consumption metrics.\n\nThis integration leverages Twilio's APIs to enhance IoT solutions with robust communication features, adding significant value to Particle projects.",
      "logo": "/assets/integrations/twilio.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://www.twilio.com/docs"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/getting-started/machine-learning/doorbell/#webhook-setup"
      }],
      "instructions": "You'll need Twilio account and an SMS-enabled Twilio phone number. Follow the instructions in [this guide](https://docs.particle.io/getting-started/machine-learning/doorbell/#webhook-setup) to set up a Twilio account.\n\nInsert the Twilio phone number in the `From` field and the recipient's phone number in the `To` field. You can also customize the message in the `Body` field.",
      "category": "Communication tools",
      "featured": true,
      "tags": "sms,voice,video,messaging,communications",
      "template": {
        "integrationType": "Webhook",
        "event": "sms",
        "url": "https://api.twilio.com/2010-04-01/Accounts/{{{TWILIO_ACCOUNT_SID}}}/Messages.json",
        "requestType": "POST",
        "auth": {
          "username": "{{{TWILIO_ACCOUNT_USERNAME}}}",
          "password": "{{{TWILIO_ACCOUNT_PASSWORD}}}"
        },
        "form": {
          "From": "YOUR_TWILIO_PHONE_NUMBER",
          "To": "YOUR_RECIPIENT_PHONE_NUMBER",
          "Body": "{{{PARTICLE_EVENT_VALUE}}}"
        },
        noDefaults: true,
        variables: [{
          type: 'CREATE',
          templateName: 'TWILIO_ACCOUNT_USERNAME',
          sensitive: false
        }, {
          type: 'CREATE',
          templateName: 'TWILIO_ACCOUNT_PASSWORD',
          sensitive: true
        }, {
          type: 'CREATE',
          templateName: 'TWILIO_ACCOUNT_SID',
          sensitive: false
        }]
      },
      "expandAdvanced": true,
      simpleForm: {
        description: 'Configure your Twilio integration',
        showForm: true,
        parameters: [{
          name: 'Twilio Account Username',
          placeholder: 'Username',
          variableName: 'TWILIO_ACCOUNT_USERNAME',
          helpText: 'The username for your Twilio account'
        }, {
          name: 'Twilio Account Password',
          placeholder: 'Password',
          variableName: 'TWILIO_ACCOUNT_PASSWORD',
          helpText: 'The password for your Twilio account',
          sensitive: true
        }, {
          name: 'Twilio Account SID',
          placeholder: 'SID',
          variableName: 'TWILIO_ACCOUNT_SID',
          helpText: 'The SID for your Twilio account'
        }]
      }
    }, {
      "id": "ubidots",
      "name": "Ubidots",
      "shortDescription": "Ubidots is a platform that provides advanced analytics and visualization for IoT data.",
      "longDescription": "Ubidots empowers businesses to transform sensor data into actionable insights, driving operational efficiency and innovation. Through the integration with Particle, multiple use scenarios unfold:\n\n- **Real-time Monitoring**: Capture and display data from Particle devices in real-time, allowing for immediate insight and response.\n- **Data Analytics**: Apply Ubidots' analytical tools to interpret complex data streams for predictive maintenance and process optimization.\n- **Custom Dashboards**: Design tailored visualization dashboards that reflect key performance indicators and metrics important to your business operations.\n- **Automated Alerts**: Set up thresholds and receive notifications when Particle device measurements breach critical limits, ensuring swift action.\n- **Workflow Automation**: Integrate with third-party services to trigger actions based on data insights, enhancing efficiency and responsiveness of systems.\n- **Remote Device Management**: Control and update Particle IoT devices remotely, based on the analysis provided by Ubidots.",
      "logo": "/assets/integrations/ubidots.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://help.ubidots.com/en/"
      }],
      "instructions": "Follow the instructions in [this guide](https://help.ubidots.com/en/) to create an account. Generate an an auth token and insert it in the header field.\n\nFor each device that will send data, create a device in Ubidots with the same ID as your Particle device ID.\n\n**Writing multiple variables at once**\n\nIf your device publishes events encoded as JSON, you can write multiple variables at once.\n\nFor example, publish an event with the following format: `{ \"temperature\": 32, \"humidity\": 80 }` and in the JSON template, put `{ \"temperature\": {{{temperature{}}, \"humidity\": {{{humidity}}} }`.",
      "category": "Data analytics",
      "featured": true,
      "tags": "analytics,visualization,dashboards,alerts,automation",
      "template": {
        "integrationType": "Webhook",
        "event": "ubidots-data",
        "url": "https://industrial.api.ubidots.com/api/v1.6/devices/{{{PARTICLE_DEVICE_ID}}}",
        "requestType": "POST",
        "headers": {
          "X-Auth-Token": "YOUR_TOKEN"
        },
        "json": {
          "variable1": "{{{PARTICLE_EVENT_VALUE}}}"
        }
      },
      "expandAdvanced": true
    }, {
      "id": "zapier",
      "name": "Zapier",
      "shortDescription": "Zapier connects your apps and automates workflows with simple condition logic.",
      "longDescription": "\nZapier is a powerful tool for automating tasks between Particle devices and numerous web services, enhancing IoT project efficiency. By integrating Particle with Zapier, users can:\n\n- Automatically trigger actions in over 3,000 apps based on Particle event data.\n- Create conditional workflows that react to device status changes, sensor readings, and custom events.\n- Simplify data collection and analysis by connecting Particle with popular business, productivity, and social media applications.\n",
      "logo": "/assets/integrations/zapier.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://zapier.com/help/create/basics/get-started-with-zapier"
      }, {
        "title": "Step-by-step setup guide",
        "link": "https://docs.particle.io/integrations/community-integrations/zapier/"
      }],
      "instructions": "Create a Zap with a Webhook Trigger.\n\nSelect the \"Catch Hook\" trigger and copy the URL into the form.\n\nCreate the Particle webhook and use the Test button to send a test event. You should see the event in Zapier after clicking Test trigger.",
      "category": "Automation & monitoring",
      "featured": true,
      "tags": "automation, workflow, productivity, apps",
      "template": {
        "integrationType": "Webhook",
        "event": "zapier-trigger",
        "url": "https://hooks.zapier.com/hooks/catch/123456/abcde",
        "requestType": "POST",
        "json": true
      }
    }, {
      "id": "webhook",
      "name": "Custom Webhook",
      "shortDescription": "Push Particle device data to other web services in real-time.",
      "longDescription": "Integrating Particle devices with external web services through custom webhooks enables real-time data push capabilities, opening up a world of possibilities for IoT applications. This approach allows for seamless communication between Particle devices and various online platforms, ensuring that data is instantly available wherever it's needed. Whether it's for monitoring, analytics, or triggering actions across different services, custom webhooks provide a flexible and efficient way to extend the functionality of Particle devices into the broader digital ecosystem.\n",
      "logo": "/assets/integrations/webhooks.svg",
      "links": [{
        "title": "Documentation",
        "link": "https://docs.particle.io/integrations/webhooks/"
      }],
      "instructions": "Integrate with any 3rd party REST service by sending a request to the webhook URL.\n\nFollow the [webhooks guide](https://docs.particle.io/integrations/webhooks/) to customize the request format to match the requirements of the service you are integrating with.\n",
      "category": "Other services",
      "featured": true,
      "tags": "webhook,rest,http,cloud",
      "template": {
        "integrationType": "Webhook",
        "json": true
      }
    }]
  });
  /* eslint-enable */

  var _default = IntegrationTemplate;
  _exports.default = _default;
});