define("pconsole/models/access-token", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AccessToken = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    scopes: _emberData.default.attr()
  });

  var _default = AccessToken;
  _exports.default = _default;
});