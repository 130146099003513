define("pconsole/mixins/modal-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel() {
      // FIXME: use loading router substate to manage spinners
      Ember.$('.loading').show();
    },

    afterModel() {
      // FIXME: use loading router substate to manage spinners
      Ember.$('.loading').hide();
    },

    renderTemplate() {
      this.render({
        into: 'application',
        outlet: 'modal'
      });
    },

    deactivate() {
      this.disconnectOutlet({
        outlet: 'modal',
        parentView: 'application'
      });
    },

    actions: {
      didTransition() {
        this.controller.set('previousRoute', this.controllerFor('application').get('currentRouteName'));
      }

    }
  });

  _exports.default = _default;
});