define("pconsole/routes/organization/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'user.integrations',
    controllerName: 'user.integrations',
    breadCrumb: {
      title: 'Integrations',
      path: 'organization.integrations'
    },

    // These are used at every route under /organization/integrations
    async model() {
      const integrations = await this.store.findAll('integration');
      const types = await this.store.findAll('integrationType');
      const templates = await this.store.findAll('integrationTemplate');
      return {
        integrations,
        templates,
        integrationTypes: types.filter(t => t.get('enabled'))
      };
    },

    deactivate() {
      this.store.peekAll('integration').forEach(i => i.unloadRecord());
    }

  });

  _exports.default = _default;
});