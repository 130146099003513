define("pconsole/routes/product/choose-plan/interval", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return Ember.RSVP.hash({
        planGroup: this.store.findRecord('planGroup', params.plan_group_id),
        card: this.store.find('card', 0),
        product: this.modelFor('product').product
      });
    }

  });

  _exports.default = _default;
});