define("pconsole/models/sim", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Sim = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    stateChangeInProgress: _emberData.default.attr('boolean'),
    product: _emberData.default.belongsTo('product'),
    user: _emberData.default.belongsTo('user', {
      async: false
    }),
    subscription: _emberData.default.belongsTo('subscription', {
      async: false
    }),
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    simUsageDatapoints: _emberData.default.hasMany('sim-usage-datapoint', {
      async: true
    }),
    lastDeviceName: _emberData.default.attr('string'),
    // There are 6 potential statuses of a SIM card:
    // - "never_before_activated": The SIM has never been activated before
    // - "active": The SIM is able to receive and send data
    // - "inactive_user_deactivated": A user chooses to de-activate their SIM from the console
    // - "inactive_invalid_payment_method": SIM has been de-activated due to 3 failed payment attempts
    // - "inactive_subscription_cancelled": subscription has been cancelled manually in Stripe
    friendlyStatus: Ember.computed('status', 'stateChangeInProgress', function () {
      const status = this.get('status');
      const inProgress = this.get('stateChangeInProgress');
      const friendlyStatuses = {
        'active': 'Active',
        'inactive_user_deactivated': 'Deactivated',
        'inactive_invalid_payment_method': 'Deactivated',
        'inactive_subscription_cancelled': 'Deactivated',
        'never_before_activated': 'Inactive'
      };
      return inProgress ? 'Updating' : friendlyStatuses[status];
    }),
    isActive: Ember.computed('status', 'stateChangeInProgress', function () {
      const status = this.get('status');
      const inProgress = this.get('stateChangeInProgress');
      return !inProgress && status === 'active';
    }),
    isInactive: Ember.computed('status', function () {
      const status = this.get('status');
      return status === 'never_before_activated';
    }),
    isDeactivated: Ember.computed('status', function () {
      const status = this.get('status');
      const deactive_states = ['never_before_activated', 'inactive_user_deactivated', 'inactive_invalid_payment_method', 'inactive_subscription_cancelled'];
      return deactive_states.indexOf(status) !== -1;
    }),
    deletePending: _emberData.default.attr('boolean'),
    isDeactivatedFromUser: Ember.computed('status', function () {
      const status = this.get('status');
      return status === 'inactive_user_deactivated';
    }),
    isReactivateable: Ember.computed('isDeactivatedFromUser', function () {
      return this.get('isDeactivatedFromUser');
    }),
    lastDeviceId: Ember.computed('device', function () {
      return this.belongsTo('device').id();
    }),
    currentUsage: Ember.computed('simUsageDatapoints.@each.mbsUsedCumulative', function () {
      const simUsageDatapoints = this.get('simUsageDatapoints');
      const usageValues = simUsageDatapoints.mapBy('mbsUsedCumulative').sort(function (a, b) {
        return b - a;
      });
      return usageValues[0] || 0;
    }),
    iccid: Ember.computed('id', function () {
      return this.get('id');
    })
  });

  var _default = Sim;
  _exports.default = _default;
});