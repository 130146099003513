define("pconsole/controllers/user/sims/import", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "pconsole/mixins/sim/import", "fast-luhn", "ember-validations"], function (_exports, _form, _import, _fastLuhn, _emberValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, _import.default, {
    apiAjax: Ember.inject.service(),
    ICCID: null,
    validations: {
      'ICCID': {
        presence: true,
        length: [19, 20],
        inline: (0, _emberValidations.validator)(function () {
          const iccid = this.model.get('ICCID');

          if (iccid && !(0, _fastLuhn.default)(iccid)) {
            return 'is invalid';
          }
        })
      }
    },

    async save() {
      Ember.$('.loading').show();
      const notify = this.get('notify');
      const iccid = this.get('ICCID');

      try {
        const response = await this.get('apiAjax').request({
          type: 'PUT',
          url: "/v1/sims/".concat(iccid),
          data: {
            action: 'activate'
          }
        });

        if (response.status === 202) {
          notify.info('Activating your SIM might take some time.', {
            closeAfter: 10000
          });
        } else {
          notify.success('Your SIM has been successfully activated.');
        }

        return this.transitionToRoute('user.sims');
      } catch (resp) {
        if (resp.status === 504 || resp.status === 408) {
          notify.warning('Activating your SIM is in progress.');
        } else {
          notify.error("There was a problem acting on your SIM: ".concat(resp.responseText));
        }
      } finally {
        Ember.$('.loading').hide();
      }
    }

  });

  _exports.default = _default;
});