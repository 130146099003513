define("pconsole/components/billing/usage-report", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    serviceAgreement: null,
    isGenerating: false,
    timeRange: 'week',
    timeRanges: [{
      value: 'week',
      name: 'Past week'
    }, {
      value: 'month',
      name: 'Past month'
    }, {
      value: '3months',
      name: 'Past 3 months'
    }, {
      value: 'year',
      name: 'Past year'
    }],
    selectedTimeRange: Ember.computed('timeRange', function () {
      const timeRange = Ember.get(this, 'timeRange');
      return Ember.get(this, 'timeRanges').findBy('value', timeRange);
    }),
    reportType: 'devices',
    reportTypes: [{
      value: 'devices',
      name: 'Daily usage by device'
    }, {
      value: 'products',
      name: 'Daily usage by product'
    }],
    selectedReportType: Ember.computed('reportType', function () {
      const reportType = Ember.get(this, 'reportType');
      return Ember.get(this, 'reportTypes').findBy('value', reportType);
    }),
    actions: {
      async generateUsageReport() {
        const isGenerating = Ember.get(this, 'isGenerating');

        if (isGenerating) {
          return;
        }

        Ember.set(this, 'isGenerating', true);
        const id = Ember.get(this, 'serviceAgreement.id');
        const orgId = Ember.get(this, 'serviceAgreement.organizationId');
        const url = orgId ? "/v1/orgs/".concat(orgId, "/service_agreements/").concat(id, "/usage_reports") : "/v1/user/service_agreements/".concat(id, "/usage_reports");
        const reportType = Ember.get(this, 'reportType');
        const timeRange = Ember.get(this, 'timeRange');
        const datePeriodStart = (0, _moment.default)();

        switch (timeRange) {
          case 'week':
            datePeriodStart.subtract(6, 'days');
            break;

          case 'month':
            datePeriodStart.subtract(1, 'month').add(1, 'day');
            break;

          case '3months':
            datePeriodStart.subtract(3, 'month').add(1, 'day');
            break;

          case 'year':
            datePeriodStart.subtract(1, 'year').add(1, 'day');
            break;
        }

        const datePeriodEnd = (0, _moment.default)();

        try {
          await Ember.get(this, 'apiAjax').request({
            url,
            type: 'POST',
            data: {
              date_period_start: datePeriodStart.format('YYYY-MM-DD'),
              date_period_end: datePeriodEnd.format('YYYY-MM-DD'),
              report_type: reportType
            },
            dataType: 'json'
          });
          Ember.get(this, 'notify').success('The report is being generated. Check your email for the link to download it.');
        } catch (error) {
          const errorDescription = Ember.get(error, 'responseJSON.error_description') || 'Network error';
          Ember.get(this, 'notify').error("The report cannot be generated: ".concat(errorDescription));
        }

        Ember.set(this, 'isGenerating', false);
      }

    }
  });

  _exports.default = _default;
});