define("pconsole/components/customer-file-upload", ["exports", "ember-uploader", "pconsole/config/environment"], function (_exports, _emberUploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CustomUploader = _emberUploader.default.Uploader.extend({
    _ajax(settings) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('session').authorize('authorizer:application', (headerName, headerValue) => {
          settings.headers = {
            [headerName]: headerValue
          };

          this._super(settings).then(resolve, reject);
        });
      });
    }

  });

  var _default = _emberUploader.default.FileField.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    url: Ember.computed(function () {
      return _environment.default.APP.API_ENDPOINT + '/v1/products/' + this.get('routeData.product') + '/activation_codes';
    }),
    filesDidChange: Ember.observer('files', function () {
      Ember.$('.loading').show();
      const uploadUrl = this.get('url');
      const files = this.get('files');
      const uploader = CustomUploader.create({
        url: uploadUrl,
        session: this.get('session')
      });

      if (!Ember.isEmpty(files)) {
        const file = files[0];
        const promise = uploader.upload(file);
        promise.then(() => {
          Ember.$('.loading').hide();
          Ember.$('.modal').modal('hide');
          this.get('notify').success('Customers successfully imported!');
          this.sendAction('doneUploading');
        }, () => {
          Ember.$('.loading').hide();
          Ember.$('.modal').modal('hide');
          this.get('notify').warning('Importing failed! Please try again, or contact support ' + 'if this issue continues. Remember to upload only a ' + 'tab-delimited text file with the email address in ' + 'the first column.', {
            closeAfter: 10000
          });
          this.sendAction('doneUploading');
        });
      }
    })
  });

  _exports.default = _default;
});