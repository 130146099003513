define("pconsole/templates/components/integration-info-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9QOVKba0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[4,\"component\",[\"toast\"],[[\"type\",\"__ANGLE_ATTRS__\"],[\"info\",[25,\"hash\",null,[[\"class\"],[[25,\"concat\",[[20,[\"class\"]],\" integration-toast integration-toast--info\"],null]]]]]],{\"statements\":[[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"This integration is shared by different products\"],[8],[0,\"\\n    \"],[6,\"span\"],[7],[0,\"\\n      Changes made here will apply to all products where this integration is being executed.\\n    \"],[8],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/integration-info-toast.hbs"
    }
  });

  _exports.default = _default;
});