define("pconsole/helpers/query-param-friendly-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryParamFriendlyName = queryParamFriendlyName;
  _exports.default = void 0;

  function queryParamFriendlyName(params) {
    const qp = params[0];
    const friendlyNames = {
      iccid: 'ICCID',
      deviceId: 'Device ID',
      deviceName: 'Device name',
      serialNumber: 'Serial number',
      event: 'Event',
      name: 'Name',
      integrationType: 'Integration type',
      targetUrl: 'Target',
      groups: 'Groups',
      logicTrigger: 'Trigger',
      scope: 'Scope',
      type: 'Type'
    };
    return friendlyNames[qp] || qp;
  }

  var _default = Ember.Helper.helper(queryParamFriendlyName);

  _exports.default = _default;
});