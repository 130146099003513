define("pconsole/templates/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N8Wu0he+",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"side-bar\",null,[[\"applicationController\",\"isProduct\",\"product\"],[[20,[\"applicationController\"]],true,[20,[\"product\"]]]]],false],[0,\"\\n\"],[1,[25,\"nav-bar\",null,[[\"applicationController\",\"product\",\"isProduct\",\"serviceAgreements\"],[[20,[\"applicationController\"]],[20,[\"product\"]],true,[20,[\"applicationController\",\"activeServiceAgreements\"]]]]],false],[0,\"\\n\"],[6,\"section\"],[9,\"class\",\"dashboard\"],[7],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product.hbs"
    }
  });

  _exports.default = _default;
});