define("pconsole/routes/product/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      groups: {
        refreshModel: true
      },
      id: {
        refreshModel: true
      },
      name: {
        refreshModel: true
      }
    },
    notify: Ember.inject.service(),

    model({
      id: device_id,
      groups,
      name: device_name
    }) {
      return Ember.RSVP.hash({
        devices: this.store.query('device-location', {
          endpoint: 'fleet_locations',
          device_id,
          device_name,
          groups
        }),
        product: this.modelFor('product').product,
        hasDevices: this.store.query('device', {}).then(d => d.meta.total_records > 0),
        groupOptions: this.store.findAll('group').then(groupOptions => groupOptions.toArray().map(g => g.toJSON({
          includeId: true
        })))
      });
    },

    async afterModel(model) {
      // Load groups association
      await model.devices.map(d => d.hasMany('groups').load());
    },

    resetController(controller, isExiting) {
      this._super(...arguments);

      if (isExiting) {
        controller.clearController();
      }
    },

    actions: {
      didTransition() {
        const {
          product
        } = this.modelFor('product');

        if (!Ember.get(product, 'settings.location.opt_in')) {
          const optInURL = this.router.generate('product.settings', Ember.get(product, 'slug'));
          const message = Ember.get(this, 'notify').warning({
            html: "You must <a href=\"".concat(optInURL, "\">opt in to location storage</a> to see devices on the map."),
            closeAfter: null
          });
          Ember.set(this.controller, 'optInMessage', message);
        }
      },

      loading(transition
      /*, route*/
      ) {
        const controller = this.controllerFor('product.location');
        Ember.set(controller, 'isLoading', true);
        transition.finally(() => {
          Ember.set(controller, 'isLoading', false);
        }); // Prevent transition into loading substate

        return false;
      },

      willTransition() {
        const optInMessage = Ember.get(this.controller, 'optInMessage');

        if (optInMessage) {
          Ember.set(optInMessage, 'visible', false);
        }
      }

    }
  });

  _exports.default = _default;
});