define("pconsole/components/fleet-usage-sparkline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['usage-sparkline'],
    width: 300,
    height: 70,

    didInsertElement() {
      const elementId = this.elementId;
      const d3 = window.d3;
      const width = this.get('width');
      const usageData = this.get('usageData');
      const currentUsage = d3.max(usageData, function (d) {
        return d.mbs_used_cumulative;
      });
      const currentUsageRoundedUp = Math.ceil(currentUsage);
      let factor = 1;
      let currentUsageUnit = 'MB';

      if (currentUsageRoundedUp > 100) {
        factor = 1024;
        currentUsageUnit = 'GB';
      }

      this.set('currentUsage', currentUsage);
      this.set('currentUsageRoundedUp', currentUsageRoundedUp);
      this.set('factor', factor);
      this.set('currentUsageUnit', currentUsageUnit);
      this.set('parseDate', d3.time.format('%Y-%m-%d').parse);
      const x = d3.time.scale().range([0, width]);
      const y = d3.scale.linear().range([this.get('height'), 0]);
      const line = d3.svg.line().x(function (d) {
        return x(d.date);
      }).y(function (d) {
        return y(d.mbs_used_cumulative / factor);
      });
      this.set('line', line);
      this.set('x', x);
      this.set('y', y);

      this._drawSparkline(elementId, usageData);
    },

    _drawSparkline(elemId, data) {
      const roundedUsage = this.get('currentUsageRoundedUp');

      this._parseData(data);

      this._calculateYAxisTop();

      this._setXandYDomain();

      this._appendSVG(elemId);

      this._drawAxes();

      if (roundedUsage === 0) {
        this._drawDataPlaceholder();
      } else {
        this._drawUsageLine();
      }
    },

    _parseData(data) {
      const parseDate = this.get('parseDate');
      data.forEach(function (d) {
        d.date = parseDate(d.date);
        d.mbs_used = +d.mbs_used;
        d.mbs_used_cumulative = +d.mbs_used_cumulative;
      });
      this.set('data', data);
    },

    _calculateYAxisTop() {
      const currentUsageRoundedUp = this.get('currentUsageRoundedUp');
      const factor = this.get('factor');
      const numberDigits = currentUsageRoundedUp.toString().length - (factor === 1024 ? 3 : 0);
      const yAxisTop = Math.pow(10, numberDigits);
      this.set('yAxisTop', yAxisTop);
      return yAxisTop;
    },

    _setXandYDomain() {
      const d3 = window.d3;
      const x = this.get('x');
      const y = this.get('y');
      const width = this.get('width');
      const subscription = this.get('subscription');
      let minDate;
      let maxDate;

      if (subscription && subscription.get('currentMonthlyPeriodStart') && subscription.get('currentMonthlyPeriodEnd')) {
        minDate = d3.time.day.floor(subscription.get('currentMonthlyPeriodStart'));
        maxDate = d3.time.day.floor(subscription.get('currentMonthlyPeriodEnd'));
      } else {
        minDate = d3.min(this.get('data'), function (d) {
          return d.date;
        });
        maxDate = d3.time.month.offset(minDate, 1);
      }

      const xAxis = d3.svg.axis().scale(x).outerTickSize(0).tickFormat(d3.time.format('%b %-d')).tickValues([minDate, maxDate]).tickPadding(1).orient('bottom');
      const yAxis = d3.svg.axis().scale(y).outerTickSize(0).tickValues([this.get('yAxisTop')]).innerTickSize(-width).tickPadding(5).orient('left');
      x.domain([minDate, maxDate]);
      y.domain([0, this.get('yAxisTop')]);
      this.set('xAxis', xAxis);
      this.set('yAxis', yAxis);
    },

    _appendSVG(elemId) {
      const width = this.get('width');
      const height = this.get('height');
      const d3 = window.d3;
      const sparkline = d3.select('#' + elemId).append('svg').attr('width', width).attr('height', height).append('g');
      this.set('sparkline', sparkline);
    },

    _drawAxes() {
      const height = this.get('height');
      const xAxis = this.get('xAxis');
      const yAxis = this.get('yAxis');
      const sparkline = this.get('sparkline');
      const currentUsageUnit = this.get('currentUsageUnit');
      sparkline.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);
      sparkline.append('g').attr('class', 'y axis').call(yAxis);
      sparkline.append('text').attr('class', 'label').text(currentUsageUnit).attr('transform', 'rotate(-90)').attr('x', -45).attr('y', -5);
    },

    _drawDataPlaceholder() {
      const sparkline = this.get('sparkline');
      const width = this.get('width');
      const height = this.get('height');
      sparkline.append('rect').attr('class', 'placeholder-rect').attr('x', 0).attr('y', 0).attr('width', width).attr('height', height);
      sparkline.append('text').attr('class', 'no-data-placeholder').attr('x', function () {
        return width / 2;
      }).attr('y', function () {
        return height / 2 + 5;
      }).text('No data used yet.');
    },

    _drawUsageLine() {
      const y = this.get('y');
      const x = this.get('x');
      const line = this.get('line');
      const data = this.get('data');
      const sparkline = this.get('sparkline');
      const factor = this.get('factor');
      sparkline.append('path').datum(data).attr('class', 'sparkline').attr('d', line);
      sparkline.append('circle').attr('class', 'sparkcircle').attr('cx', x(data[data.length - 1].date)).attr('cy', y(data[data.length - 1].mbs_used_cumulative / factor)).attr('r', 2.5);
    }

  });

  _exports.default = _default;
});