define("pconsole/routes/user/devices", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserDeviceRoute = Ember.Route.extend(_routeMixin.default, {
    templateName: 'product/devices',
    controllerName: 'product/devices',
    queryParams: {
      dev_kit_only: {
        refreshModel: true
      }
    },

    model(params) {
      return this.findPaged('device', params);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.subscribe();
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.resetParams();
        controller.unsubscribe();
      }
    }

  });
  var _default = UserDeviceRoute;
  _exports.default = _default;
});