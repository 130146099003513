define("pconsole/components/device-setup-status", ["exports", "react", "styled-components", "pconsole/components/toast"], function (_exports, _react, _styledComponents, _toast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Create a toast to show a new device setup status
   * @param {string} [status='active'] - Either 'active' or 'activating'
   * @param {string} [type='wifi'] - Either 'wifi' or 'cellular'
   */
  const DeviceSetupStatus = ({
    status = 'active',
    type = 'wifi'
  }) => {
    let message;

    if (status === 'active') {
      message = _react.default.createElement('div', null, 'Your device is now online (connected to the ', type === 'wifi' ? 'Wi-Fi' : 'cellular', ' network and the Particle Cloud). Get started by ', _react.default.createElement('a', {
        href: 'https://docs.particle.io/firmware/best-practices/firmware-introduction/',
        target: '_blank'
      }, 'flashing your own firmware via OTA'), '\xA0or ', _react.default.createElement('a', {
        href: 'https://docs.particle.io/getting-started/integrations/integrations/',
        target: '_blank'
      }, 'creating your first integration'), '.');
    } else {
      message = _react.default.createElement('div', null, 'Your device is connecting to the ', type === 'wifi' ? 'Wi-Fi' : 'cellular', ' network and the Particle Cloud. Meanwhile, we recommend you read ', _react.default.createElement('a', {
        href: 'https://docs.particle.io/firmware/best-practices/firmware-introduction/',
        target: '_blank'
      }, 'flashing your own firmware via OTA'), '\xA0or ', _react.default.createElement('a', {
        href: 'https://docs.particle.io/getting-started/integrations/integrations/',
        target: '_blank'
      }, 'creating your first integration'), '.');
    }

    return _react.default.createElement(ToastContainer, null, _react.default.createElement(_toast.default, {
      type: status === 'active' ? 'success' : 'info',
      message: message
    }));
  };

  const ToastContainer = _styledComponents.default.div.withConfig({
    displayName: "device-setup-status__ToastContainer",
    componentId: "sc-1414c91-0"
  })(["padding-bottom:1.4rem;> div > div{max-width:100%;}"]);

  var _default = DeviceSetupStatus;
  _exports.default = _default;
});