define("pconsole/components/page-numbers", ["exports", "ember-cli-pagination/components/page-numbers"], function (_exports, _pageNumbers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageNumbers.default.extend({
    classNames: ['page-numbers'],
    actions: {
      pageClicked(number) {
        Ember.$('.dashboard').scrollTop(0);

        this._super(number);
      },

      incrementPage(number) {
        Ember.$('.dashboard').scrollTop(0);

        this._super(number);
      }

    }
  });

  _exports.default = _default;
});