define("pconsole/components/simple-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter simple-filter'],
    searchValue: null,

    /**
     * The parent element must pass it to handle what happens when clearing the search
     */
    resetSearchAction: null,

    /**
     * The parent element must pass it to handle the search input
     */
    searchAction: null,
    actions: {
      // TODO (carlos h): move the debounce logic inside this component
      filterItems(e) {
        Ember.set(this, 'searchValue', e.target.value);
        this.searchAction(e.target.value);
      },

      resetSearch() {
        Ember.set(this, 'searchValue', null);
        this.resetSearchAction();
      }

    }
  });

  _exports.default = _default;
});