define("pconsole/controllers/product/fleet-health", ["exports", "moment", "semver", "lodash/isNumber"], function (_exports, _moment, _semver, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    segment: Ember.inject.service(),
    lastUpdated: new Date().toLocaleString(),

    updateLastUpdated() {
      this.set('lastUpdated', new Date().toLocaleString());
    },

    onlineDevicesNow: '•••',
    totalDevices: '•••',
    simDataUsage: '•••',
    simDataPeriodStart: '•••',
    isCellular: Ember.computed.alias('model.isCellular'),
    productId: Ember.computed.alias('model.id'),

    /* ════════════════════════════════ GETTERS ════════════════════════════════ */
    getFirmwares() {
      const product = this.get('routeData.product');
      const url = "/v1/products/".concat(product, "/firmware");
      return this.get('apiAjax').getRequest({
        url: "".concat(url)
      }).then(response => {
        this.set('firmwares', response);
      }).catch(error => {
        console.error(error);
        this.get('notify').error('There was a problem loading firmwares list.');
      });
    },

    getGroups() {
      const product = this.get('routeData.product');
      const url = "/v1/products/".concat(product, "/groups");
      return this.get('apiAjax').getRequest({
        url: "".concat(url)
      }).then(response => {
        this.set('groups', response.groups);
      }).catch(error => {
        console.error(error);
        this.get('notify').error('There was a problem loading groups list.');
      });
    },

    getDeviceOSVersions() {
      const product = this.get('routeData.product');
      const url = "/v1/products/".concat(product, "/device_os_versions");
      return this.get('apiAjax').getRequest({
        url: "".concat(url)
      }).then(response => {
        const sortedVersions = response.device_os_versions.sort((a, b) => _semver.default.gt(a, b) ? -1 : 1);
        this.set('deviceOSVersions', sortedVersions);
      }).catch(error => {
        console.error(error);
        this.get('notify').error('There was a problem loading device OS list.');
      });
    },

    getSimDataUsage() {
      const product = this.get('routeData.product');
      const url = "/v1/products/".concat(product, "/sims/data_usage");
      return this.get('apiAjax').getRequest({
        url: "".concat(url)
      }).then(response => {
        const simDataUsage = Number(Math.round(response.total_mbs_used + 'e' + 2) + 'e-' + 2);
        this.set('simDataUsage', simDataUsage + ' MB');
        const simDataPeriodStart = (0, _moment.default)(response.usage_by_day[0].date).format('MMMM Do');
        this.set('simDataPeriodStart', simDataPeriodStart);
      }).catch(error => {
        console.error(error);
        this.get('notify').error('There was a problem loading SIM data usage.');
      });
    },

    getFleetSize() {
      const url = this.get('fleetSizeURL');
      return this.get('apiAjax').getRequest({
        url: "".concat(url)
      }).then(response => {
        let totalDevices = '…';

        if (response && response.meta) {
          totalDevices = Number(response.meta.total_records);
        }

        this.set('totalDevices', totalDevices);
      }).catch(error => {
        console.error(error);
        this.get('notify').error('There was a problem loading fleet size.');
      });
    },

    getOnline() {
      const url = this.get('onlineURL');
      return this.get('apiAjax').getRequest({
        url: "".concat(url)
      }).then(response => {
        let onlineDevicesNow = '0';

        if (response && response.online) {
          for (const online of response.online) {
            onlineDevicesNow = (0, _isNumber.default)(online.count) ? Math.round(online.count) : onlineDevicesNow;
          }
        }

        this.set('onlineDevicesNow', onlineDevicesNow);
      }).catch(error => {
        console.error(error);
        this.get('notify').error('There was a problem loading number of devices online now.');
      });
    },

    setBucketSize() {
      const timePeriod = this.get('timePeriod');
      let start_date;
      let bucket_size;
      let hours;

      switch (timePeriod) {
        case 'hour':
          start_date = (0, _moment.default)().subtract(1, 'hour').startOf('minute');
          bucket_size = 60;
          /* 1 minute = 60 intervals */

          break;

        case 'day':
          start_date = (0, _moment.default)().subtract(1, 'day');
          start_date = start_date.minutes() < 30 ? start_date.startOf('hour') : start_date.startOf('hour').add(30, 'minutes');
          bucket_size = 30 * 60;
          /* 30 minutes = 48 intervals */

          break;

        case '2-days':
          start_date = (0, _moment.default)().subtract(2, 'days').startOf('hour');
          bucket_size = 1 * 60 * 60;
          /* 1 hour = 48 intervals */

          break;

        case '7-days':
          start_date = (0, _moment.default)().subtract(7, 'days').startOf('hour');
          hours = Math.floor(start_date.hours() / 3) * 3;
          start_date = start_date.hours(hours);
          bucket_size = 3 * 60 * 60;
          /* 3 hours = 56 intervals */

          break;

        case '30-days':
          start_date = (0, _moment.default)().subtract(30, 'days').startOf('hour');
          hours = Math.floor(start_date.hours() / 12) * 12;
          start_date = start_date.hours(hours);
          bucket_size = 12 * 60 * 60;
          /* 12 hours = 60 intervals */

          break;

        default:
          start_date = (0, _moment.default)().subtract(1, 'hour').startOf('minute');
          bucket_size = 60;

        /* 1 minute = 60 intervals */
      }

      start_date = start_date.toISOString();
      this.setProperties({
        start_date,
        bucket_size
      });
    },

    setURLs() {
      const product = this.get('routeData.product');
      const product_fw = this.get('firmware');
      const device_group = this.get('group');
      const device_os_version = this.get('deviceOSVersion');
      const start_date = this.get('start_date');
      const bucket_size = this.get('bucket_size');
      this.set('filter', {
        start_date,
        bucket_size,
        product_fw,
        device_group,
        device_os_version
      });
      this.set('onlineURL', "/v1/products/".concat(product, "/metrics/online?start_date=").concat((0, _moment.default)().startOf('minute').subtract(30, 'minute').toISOString(), "&bucket_size=60"));
      this.set('fleetSizeURL', "/v1/products/".concat(product, "/devices"));
    },

    trackEngagement(name, value) {
      const product = this.get('routeData.product');

      if (name === 'refresh') {
        this.get('segment').trackEvent('fleet-health-refresh-clicked', {
          product
        });
      } else {
        this.get('segment').trackEvent('fleet-health-filter-set', {
          product,
          filter: name,
          value: value
        });
      }
    },

    /* ════════════════════════════════ HOOKS ════════════════════════════════ */
    onSetupController() {
      this.send('getData');
      this.getFirmwares();
      this.getGroups();
      this.getDeviceOSVersions();
      this.set('onlineDevicesNow', '•••');
      this.set('totalDevices', '•••');
      this.set('simDataUsage', '•••');
      this.set('simDataPeriodStart', '•••');
    },

    onExitController() {
      this.set('timePeriod', null);
      this.set('firmware', null);
      this.set('group', null);
      this.set('deviceOSVersion', null);
    },

    /* ════════════════════════════════ ACTIONS ════════════════════════════════ */
    actions: {
      getData() {
        this.setBucketSize();
        this.setURLs();
        this.getFleetSize();
        this.getOnline();
        this.getSimDataUsage();
        this.updateLastUpdated();
      },

      onTimeSelect(event) {
        this.set('timePeriod', event.target.value);
        this.send('getData');
        this.trackEngagement('time-period', event.target.value);
      },

      onFirmwareSelect(event) {
        this.set('firmware', event.target.value);
        this.send('getData');
        this.trackEngagement('firmware', event.target.value);
      },

      onGroupSelect(event) {
        this.set('group', event.target.value);
        this.send('getData');
        this.trackEngagement('group', event.target.value);
      },

      onDeviceOSVersionSelect(event) {
        this.set('deviceOSVersion', event.target.value);
        this.send('getData');
        this.trackEngagement('device-os-version', event.target.value);
      },

      onRefresh() {
        this.send('getData');
        this.trackEngagement('refresh');
      },

      onBannerClick() {
        const product = this.get('routeData.product');
        this.get('segment').trackEvent('fleet-health-enterprise-contact', {
          product
        });
        const utm_campaign = 'fleet-health-enterprise-contact';
        const utm_source = 'console';
        const utm_medium = 'button';
        const w = window.open("https://www.particle.io/sales?utm_source=".concat(utm_source, "&utm_medium=").concat(utm_medium, "&utm_campaign=").concat(utm_campaign), '_blank');
        w.focus();
      }

    }
  });

  _exports.default = _default;
});