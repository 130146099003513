define("pconsole/components/eventlogs/blank-event.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const BlankEvent = ({
    fields
  }) => _react.default.createElement('tr', {
    className: '_eventlogsnew__content__events__event blank'
  }, fields.map((field, index) => _react.default.createElement('td', {
    key: "blank-".concat(field.title, "-").concat(index)
  })));

  var _default = BlankEvent;
  _exports.default = _default;
});