define("pconsole/components/trianglify-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      const $body = Ember.$('.light-gray-bckd');

      if ($body.length === 1) {
        $body.css('background-color', 'var(--primary-background-color)');
      }
    }

  });

  _exports.default = _default;
});