define("pconsole/utils/permissions", ["exports", "minimatch"], function (_exports, _minimatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasAccessToScope = hasAccessToScope;
  _exports.checkScope = checkScope;

  const scopeReducer = (requestedScope, prev, authorizedScope) => {
    return prev || (0, _minimatch.default)(requestedScope, authorizedScope);
  };

  const missingScopes = (requestedScopes, authorizedScopes) => {
    const missing = [];

    for (const requestedScope of requestedScopes) {
      const authorized = authorizedScopes.reduce(scopeReducer.bind(void 0, requestedScope), false);

      if (!authorized) {
        missing.push(requestedScope);
      }
    }

    return missing;
  };

  function hasAccessToScope({
    requestedScopes,
    authorizedScopes
  }) {
    // FIXME: currently no scopes means access to everything. Remove this
    // special case when all access tokens have been migrated to using scopes
    if (authorizedScopes.length === 0) {
      return true;
    }

    return missingScopes(requestedScopes, authorizedScopes).length === 0;
  }

  async function checkScope({
    scope,
    orgModel,
    orgSlug,
    productModel,
    productId,
    store
  }) {
    const prodId = productId || productModel && productModel.get('id');
    const slug = orgSlug || orgModel && orgModel.get('slug');
    const currentAccessToken = await store.findRecord('access-token', 'current', {
      backgroundReload: false,
      reload: false
    });
    const authorizedScopes = currentAccessToken.get('scopes') || [];
    let domain = 'user';

    if (prodId) {
      domain = "product_".concat(prodId);
    } else if (orgSlug) {
      domain = "org_".concat(slug);
    }

    const requestedScopes = ["".concat(domain, ":").concat(scope)];
    return hasAccessToScope({
      requestedScopes,
      authorizedScopes
    });
  }
});