define("pconsole/helpers/to-lowercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toLowerCase = toLowerCase;
  _exports.default = void 0;

  function toLowerCase([str]) {
    if (!str) {
      return str;
    }

    return str.toLowerCase();
  }

  var _default = Ember.Helper.helper(toLowerCase);

  _exports.default = _default;
});