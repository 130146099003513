define("pconsole/serializers/device-configuration", ["exports", "pconsole/serializers/fleet-configuration"], function (_exports, _fleetConfiguration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fleetConfiguration.default.extend({});

  _exports.default = _default;
});