define("pconsole/mixins/routes/logic-functions/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LogicFunctionsListRoute = void 0;
  const LogicFunctionsListRoute = {
    breadCrumb: {
      title: 'Logic Functions Beta'
    },

    beforeModel() {
      const routes = Ember.get(this, 'routeData.logicRoutes');
      let path = routes.list;
      Ember.set(this, 'breadCrumb.path', path);
    }

  };
  _exports.LogicFunctionsListRoute = LogicFunctionsListRoute;
});