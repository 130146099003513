define("pconsole/templates/components/collapsible-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WqlTUWCX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"collapsible-section-heading\"],[7],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",[26,[\"section-done-checkbox \",[25,\"if\",[[20,[\"section\",\"isDone\"]],\"section-done\"],null]]]],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"ion-checkmark-circled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-b\"],[7],[8],[0,\"\\n  \"],[6,\"h4\"],[9,\"class\",\"collapsible-section-title uppercase\"],[7],[1,[20,[\"section\",\"title\"]],false],[8],[0,\"\\n  \"],[6,\"h6\"],[9,\"class\",\"collapsible-section-subtitle\"],[7],[1,[20,[\"section\",\"description\"]],false],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"isReadOnly\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"section\",\"isDone\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"section\",\"isViewMode\"]]],null,{\"statements\":[[0,\"        \"],[6,\"span\"],[9,\"class\",\"edit\"],[3,\"action\",[[19,0,[]],\"edit\"]],[7],[6,\"i\"],[9,\"class\",\"ion-edit\"],[7],[8],[0,\"Edit\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"span\"],[9,\"class\",\"edit\"],[3,\"action\",[[19,0,[]],\"doneEditing\"]],[7],[6,\"i\"],[9,\"class\",\"ion-checkmark\"],[7],[8],[0,\"Done\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"expanded-section\"],[7],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/collapsible-section.hbs"
    }
  });

  _exports.default = _default;
});