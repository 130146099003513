define("pconsole/controllers/organization/logic-functions/details/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    actions: {
      closeModal() {
        const route = Ember.get(this, 'routeData.logicRoutes').details;
        this.transitionToRoute(route);
      },

      deleteLogiFunction() {
        const path = Ember.get(this, 'routeData.logicRoutes').list;
        const logicFunction = this.get('model');
        Ember.$('.loading').show();
        logicFunction.destroyRecord().then(() => {
          Ember.$('.loading').hide();
          this.send('closeModal');
          this.transitionToRoute(path);
          this.get('notify').success('Your Logic Function was deleted successfully');
        }, () => {
          Ember.$('.loading').hide();
          this.get('notify').alert('There was a problem deleting your Logic Function. Please try again later.');
        });
      }

    }
  });

  _exports.default = _default;
});