define("pconsole/controllers/user/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ORGS_HELPER_STORAGE_KEY = 'showOrgsHelper';

  var _default = Ember.Controller.extend({
    sortProps: ['name'],
    organizations: Ember.computed.readOnly('model.organizations'),
    products: Ember.computed.sort('model.products', 'sortProps'),
    showOrgsHelper: Ember.computed({
      get() {
        const value = JSON.parse(localStorage.getItem(ORGS_HELPER_STORAGE_KEY));
        return value !== false;
      },

      set(key, value) {
        localStorage.setItem(ORGS_HELPER_STORAGE_KEY, JSON.stringify(value));
        return value;
      }

    }),
    actions: {
      dismissOrgsHelper() {
        Ember.set(this, 'showOrgsHelper', false);
      }

    }
  });

  _exports.default = _default;
});