define("pconsole/controllers/organization/products/new", ["exports", "ember-cp-validations", "lodash/trim", "pconsole/utils/platforms"], function (_exports, _emberCpValidations, _trim, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'product.name': (0, _emberCpValidations.validator)('presence', {
      description: 'Product name',
      presence: true
    }),
    'platformId': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Controller.extend(Ember.Evented, Validations, {
    platformId: null,
    product: Ember.computed.alias('model.product'),
    platforms: Ember.computed.alias('model.platforms'),
    notify: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    selectedPlatform: Ember.computed('platformId', 'validPlatforms', function () {
      const platformId = Ember.get(this, 'platformId');
      return Ember.get(this, 'validPlatforms').findBy('id', platformId);
    }),
    hiddenPlatforms: Ember.computed(function () {
      const launchDarkly = this.get('launchDarkly');

      try {
        // https://app.launchdarkly.com/default/production/features/unreleased-platforms-in-console/targeting
        const flag = launchDarkly.variation('unreleased-platforms-in-console', '');
        return flag.split(',').map(p => (0, _trim.default)(p));
      } catch (_ex) {
        // in case of error, default to show all platforms
        return [];
      }
    }),
    validPlatforms: Ember.computed('platforms', 'hiddenPlatforms', function () {
      const hiddenPlatforms = this.get('hiddenPlatforms');
      return this.get('platforms').filter(value => {
        return value.get('productEligible') && !hiddenPlatforms.includes(value.get('name'));
      }).map(value => {
        const id = parseInt(value && value.get('id'), 10);
        let name = '' || value && value.get('name');
        const connectivityName = (0, _platforms.connectivityTypeNameForPlatform)(id);

        if (connectivityName) {
          name = "".concat(name, " (").concat(connectivityName, ")");
        }

        value.set('nameWithConnectivity', name);
        return value;
      }).sortBy('orderInProductDeviceTypeList');
    }),
    setPlatform: Ember.observer('platformId', function () {
      const platformId = this.get('platformId');
      const validPlatforms = this.get('validPlatforms');
      const product = this.get('product');

      if (platformId) {
        product.set('platform', validPlatforms.findBy('id', platformId));
      }
    }),
    actions: {
      closeModal() {
        this.set('platformId', null);

        if (this.get('product.isNew')) {
          this.transitionToRoute('organization.products.index');
        }
      },

      async save(e) {
        const product = Ember.get(this, 'product');
        e.preventDefault();
        Ember.$('.loading').show();

        try {
          await this.validate();

          if (Ember.get(this, 'validations.isTruelyValid')) {
            await product.save(); // The product's subscription isn't available at creation; reload the record to get it.

            await product.reload(); // Load scopes for new product

            await Ember.get(this, 'store').findRecord('access-token', 'current', {
              reload: true
            });
            this.transitionToRoute('product.devices.welcome', Ember.get(product, 'slug'));
          }
        } catch (ex) {
          const error = ex.errors[0];
          const errorMsg = error ? error.detail : "We couldn't create your product. Try again later.";
          Ember.get(this, 'notify').alert(errorMsg);
        } finally {
          Ember.$('.loading').hide();
        }
      }

    }
  });

  _exports.default = _default;
});