define("pconsole/templates/components/label-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kwTNuyG0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"disabled\"]],\"disabled\"],null]]]],[7],[1,[25,\"capitalize-string\",[[20,[\"text\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[20,[\"help\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"hint--top\"],[10,\"data-hint\",[26,[[18,\"help\"]]]],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"icon im-info-icon help-icon\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/label-field.hbs"
    }
  });

  _exports.default = _default;
});