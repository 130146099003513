define("pconsole/routes/product/sims/import/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Import'
    },

    model() {
      return Ember.RSVP.hash({
        newSims: this.store.createRecord('new-sims'),
        product: this.modelFor('product.sims').product
      });
    },

    actions: {
      willTransition() {
        const newSims = this.get('controller.newSims');

        if (newSims && newSims.get('hasDirtyAttributes')) {
          if (newSims.get('id')) {
            newSims.rollbackAttributes();
          } else {
            newSims.deleteRecord();
          }
        }

        this._super();
      }

    }
  });

  _exports.default = _default;
});