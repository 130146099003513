define("pconsole/serializers/user", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      memberships: {
        embedded: 'always'
      },
      tos: {
        embedded: 'always'
      }
    },
    session: Ember.inject.service(),

    normalize(typeClass, hash) {
      hash.id = this.get('session.data.authenticated.username');
      hash.username = hash.id;
      hash.subscriptions = hash.subscription_ids;
      return this._super(...arguments);
    },

    normalizeFindRecordResponse(store, type, payload) {
      const user = payload.user;

      if (!user.tos) {
        user.tos = {
          accepted: false
        };
      }

      user.tos.id = 1;
      return this._super(...arguments);
    },

    normalizeResponse(store, type, payload, id, requestType) {
      if (payload.user) {
        return this._super(...arguments);
      } else {
        const normalized = {
          'user': payload
        };
        return this._super(store, type, normalized, id, requestType);
      }
    },

    normalizeSingleResponse(store, type, payload, id, requestType) {
      if (requestType === 'findRecord') {
        payload.user.sub_ids = payload.user.subscription_ids;
        delete payload.user.subscription_ids;
      }

      return this._super(...arguments);
    },

    serializeAttribute(snapshot, json, key, attributes) {
      // Send only changed attributes to the API
      if (typeof snapshot.changedAttributes()[key] !== 'undefined' || snapshot.record.get('isNew')) {
        return this._super(snapshot, json, key, attributes);
      }
    },

    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      Ember.merge(hash, json);
    }

  });

  _exports.default = _default;
});