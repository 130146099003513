define("pconsole/routes/product/customers", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductCustomerRoute = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,
    queryParams: {
      deviceId: {
        refreshModel: true,
        replace: true
      },
      customerEmail: {
        refreshModel: true,
        replace: true
      },
      sortAttr: {
        refreshModel: true,
        replace: true
      },
      sortDir: {
        refreshModel: true,
        replace: true
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        model: this.findPaged('customer', params),
        productConfig: this.modelFor('product').product.get('config'),
        product: this.modelFor('product').product
      });
    },

    setupController(controller, model) {
      controller.setProperties(model);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', null);
        controller.set('perPage', null);
      }
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });
  var _default = ProductCustomerRoute;
  _exports.default = _default;
});