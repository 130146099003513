define("pconsole/controllers/organization/ledger/index", ["exports", "pconsole/mixins/object-filter-helpers"], function (_exports, _objectFilterHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_objectFilterHelpers.default, {
    queryParams: ['name', 'type', 'scope'],
    sortProps: ['name:asc'],
    sortParams: {
      // Needed for the sort-chevs
      attr: 'name',
      dir: 'asc'
    },
    ledgerDefinitions: Ember.computed.alias('model'),
    filterLedgerDefinitions: Ember.computed('ledgerDefinitions', 'filterName', 'filterValue', function () {
      const ledgerDefinitions = Ember.get(this, 'ledgerDefinitions');
      let filterName = Ember.get(this, 'filterName');
      const filterValue = Ember.get(this, 'filterValue');

      if (!filterValue) {
        return ledgerDefinitions;
      }

      if (filterName === 'type') {
        filterName = 'displayDirection';
      }

      const filter = new RegExp(filterValue, 'i');
      return ledgerDefinitions.filter(definition => filter.test(definition.get(filterName)));
    }),
    sortedLedgerDefinitions: Ember.computed.sort('filterLedgerDefinitions', 'sortProps'),
    noLedgerDefinitions: Ember.computed.empty('ledgerDefinitions'),
    actions: {
      sortInController(sortAttr, direction) {
        this.setProperties({
          sortProps: ["".concat(sortAttr, ":").concat(direction)],
          sortParams: {
            attr: sortAttr,
            dir: direction
          }
        });
      },

      triggerFilter(name, value) {
        Ember.set(this, 'filterName', name);
        Ember.set(this, 'filterValue', value);
      }

    }
  });

  _exports.default = _default;
});