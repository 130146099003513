define("pconsole/components/track-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    segment: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const product = this.get('routeData.product');
      const eventName = this.get('event');
      const eventObj = {};

      if (product) {
        eventObj.product = product;
      }

      this.get('segment').trackEvent(eventName, eventObj);
    }

  });

  _exports.default = _default;
});