define("pconsole/controllers/user/networks/network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['gatewayPage', 'gatewayPerPage', 'nodePage', 'nodePerPage'],
    network: Ember.computed.alias('model.network'),
    gateways: Ember.computed.alias('model.gateways'),
    nodes: Ember.computed.alias('model.nodes'),
    totalGateways: Ember.computed('gateways', function () {
      return this.get('gateways.length');
    }),
    totalNodes: Ember.computed('nodes', function () {
      return this.get('nodes.length');
    }),
    shouldDisplayGatewayPagination: Ember.computed('gateways.totalPages', function () {
      return this.get('gateways.totalPages') > 1;
    }),
    shouldDisplayNodePagination: Ember.computed('nodes.totalPages', function () {
      return this.get('nodes.totalPages') > 1;
    }),
    deviceRoute: Ember.computed('routeData.product', function () {
      return this.get('routeData.product') ? 'product.device' : 'user.device';
    }),
    actions: {
      gatewayPageClicked(page) {
        this.setProperties({
          gatewayPage: page
        });
      },

      nodePageClicked(page) {
        this.setProperties({
          nodePage: page
        });
      }

    }
  });

  _exports.default = _default;
});