define("pconsole/templates/components/delete-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hurfnioY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Delete integration\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n  Are you sure you would like to delete this integration? This would immediately disable the integration\\n\"],[4,\"if\",[[25,\"gt\",[[20,[\"integration\",\"productIds\",\"length\"]],1],null]],null,{\"statements\":[[0,\"    from the \"],[6,\"b\"],[7],[1,[20,[\"integration\",\"productIds\",\"length\"]],false],[0,\" products\"],[8],[0,\" where it is executing\\n\"]],\"parameters\":[]},null],[0,\"  and permenantly remove it from the Particle system.\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, Cancel\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deleteWebhook\"]],[7],[0,\"Yes, Delete Integration\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/delete-integration.hbs"
    }
  });

  _exports.default = _default;
});