define("pconsole/templates/components/key-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TNDvNVc1",
    "block": "{\"symbols\":[\"pair\",\"key\",\"value\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"includeHeader\"]]],null,{\"statements\":[[0,\"  \"],[6,\"h5\"],[9,\"class\",\"header\"],[7],[0,\"\\n    \"],[1,[18,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[20,[\"help\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"hint--top radio\"],[10,\"data-hint\",[26,[[18,\"help\"]]]],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"icon im-info-icon help-icon\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[9,\"class\",\"key-value-pairs\"],[7],[0,\"\\n\"],[4,\"each\",[[25,\"-each-in\",[[20,[\"staticValues\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"key-value-pair\"],[7],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"value\",\"disabled\"],[[19,2,[]],true]]],false],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"ion-chevron-right\"],[7],[8],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"value\",\"disabled\"],[[19,3,[]],true]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[2,3]},null],[4,\"each\",[[20,[\"keyValueArray\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"key-value-pair\"],[7],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"value\"],[[19,1,[\"key\"]]]]],false],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"ion-chevron-right\"],[7],[8],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"value\"],[[19,1,[\"val\"]]]]],false],[0,\"\\n      \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"remove-row\"],[3,\"action\",[[19,0,[]],\"removeRow\",[19,1,[]]]],[7],[6,\"i\"],[9,\"class\",\"ion-close\"],[7],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"add-new-row ui button outline primary\"],[3,\"action\",[[19,0,[]],\"addRow\"]],[7],[0,\"+ Add Row\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,4]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/key-value.hbs"
    }
  });

  _exports.default = _default;
});