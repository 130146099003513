define("pconsole/controllers/product/devices/delete-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    groups: Ember.computed.alias('model.groups'),
    actions: {
      closeModal() {
        this.transitionToRoute('product.devices');
      },

      chooseGroup(groups) {
        const group = groups[0];
        this.set('chosenGroup', group);
      },

      deleteGroup() {
        const chosenGroup = this.get('chosenGroup');
        Ember.$('.loading').show();
        chosenGroup.destroyRecord().then(() => {
          Ember.$('.loading').hide();
          this.send('refreshModel');
          this.get('notify').success('This group has been deleted successfully');
        }, response => {
          Ember.$('.loading').hide();
          const error = response.errors[0];
          const errorMsg = error ? error.detail : 'Please try again later.';
          this.get('notify').alert('Your group could not be created. ' + errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});