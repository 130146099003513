define("pconsole/controllers/organization/enterprise-resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    sections: Ember.computed.readOnly('model.resourceSections'),
    filteredSections: Ember.computed('sections.@each.resources', 'application.currentServiceAgreement.servicePlanSlug', function () {
      const servicePlanSlug = this.get('application.currentServiceAgreement.servicePlanSlug');
      const sections = this.get('sections');
      return sections.reduce((accumulator, section) => {
        const resources = section.get('resources');
        const filteredResources = resources.filter(resource => {
          return !(resource.text === 'Purchase order' && (servicePlanSlug === 'enterprise-plan-2023-pro' || servicePlanSlug === 'enterprise-plan-2024-pro'));
        });
        accumulator[section.id] = filteredResources;
        return accumulator;
      }, {});
    })
  });

  _exports.default = _default;
});