define("pconsole/models/sim-usage-datapoint", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SimUsageDatapoint = _emberData.default.Model.extend({
    date: _emberData.default.attr('date'),
    mbsUsed: _emberData.default.attr('number'),
    mbsUsedCumulative: _emberData.default.attr('number'),
    sim: _emberData.default.belongsTo('sim', {
      async: false
    })
  });

  var _default = SimUsageDatapoint;
  _exports.default = _default;
});