define("pconsole/components/product-sim-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['product-sim-stats', 'subscription-limits-status'],
    classNameBindings: ['is-loading:isLoading'],
    apiAjax: Ember.inject.service(),
    isLoading: true,
    totalDataUsed: 0,
    totalDataUnit: 'MB',
    totalCost: 0,
    totalActiveSimCards: 0,

    _getProductSimsDataUsage() {
      return this.get('apiAjax').getRequest({
        url: "/v1/products/".concat(this.get('product.slug'), "/sims/data_usage")
      });
    },

    didInsertElement() {
      this._getProductSimsDataUsage().then(data => {
        this._bindUsageDataToComponent(data);

        this.set('isLoading', false);
      });
    },

    _bindUsageDataToComponent(data) {
      this.set('totalActiveSimCards', data.total_active_sim_cards);

      if (data.total_mbs_used > 2 * 1024) {
        this.set('totalDataUsed', data.total_mbs_used / 1024);
        this.set('totalDataUnit', 'GB');
      } else {
        this.set('totalDataUsed', data.total_mbs_used);
        this.set('totalDataUnit', 'MB');
      }

      this.set('totalCost', data.total_cost);
      this.set('usageByDay', data.usage_by_day);
    }

  });

  _exports.default = _default;
});