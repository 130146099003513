define("pconsole/components/animated-ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'h2',
    classNames: ['ellipsis'],

    didInsertElement() {
      let ellipsisCount = 0;
      const animationInterval = this.get('animationInterval');
      this.ellipsisInterval = setInterval(() => {
        ellipsisCount++;
        const dots = new Array(ellipsisCount % 4 + 1).join('.');
        this.set('dots', dots);
      }, animationInterval ? animationInterval : 500);
    },

    willDestroyElement() {
      clearInterval(this.ellipsisInterval);
    }

  });

  _exports.default = _default;
});