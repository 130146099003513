define("pconsole/templates/product/devices/remove-product-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mWW+8DmC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Remove Device From Product\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you would like to remove the device \"],[6,\"strong\"],[7],[4,\"if\",[[20,[\"model\",\"name\"]]],null,{\"statements\":[[1,[20,[\"model\",\"name\"]],false]],\"parameters\":[]},{\"statements\":[[1,[20,[\"model\",\"id\"]],false]],\"parameters\":[]}],[8],[0,\" from the product? The device will no longer receive firmware updates as part of this product and will not be visible here.\"],[8],[0,\"\\n  \"],[6,\"a\"],[9,\"class\",\"remove-user-btn ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deleteDevice\"]],[7],[0,\"Yes, remove device\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/remove-product-device.hbs"
    }
  });

  _exports.default = _default;
});