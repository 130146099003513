define("pconsole/templates/components/firmware-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BywuBgpL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"i\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"hovering\"]],\"im-unlock-icon\",\"im-lock-icon\"],null],\" \",[25,\"if\",[[20,[\"pending\"]],\"pending-lock\"],null],\" safe\"]]],[9,\"title\",\"Unlock device firmware\"],[3,\"action\",[[19,0,[]],\"unlock\",[20,[\"device\"]]]],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/firmware-lock.hbs"
    }
  });

  _exports.default = _default;
});