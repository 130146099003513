define("pconsole/routes/product/devices/batch/edit-groups", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model() {
      return Ember.RSVP.hash({
        groups: this.store.findAll('group'),
        product: this.modelFor('product').product
      });
    },

    afterModel(model) {
      const approvedDevices = this.controllerFor('product.devices').get('approvedDevices');
      const selectedDevices = approvedDevices.filterBy('isChecked', true);
      this.set('selectedDevices', selectedDevices);
      this.setSelectedPropertyOnGroups(model.groups);

      this._super(...arguments);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('selectedDevices', this.get('selectedDevices'));
    },

    setSelectedPropertyOnGroups(groups) {
      const devices = this.get('selectedDevices');
      const totalGroupCounts = this.calculateTotalGroupCounts();
      groups.forEach(g => {
        const countForGroup = totalGroupCounts[g.get('name')];

        if (Ember.isNone(countForGroup) || countForGroup === 0) {
          g.set('selected', 'none');
        } else if (countForGroup === devices.length) {
          g.set('selected', 'all');
        } else {
          g.set('selected', 'some');
        }
      });
    },

    calculateTotalGroupCounts() {
      const devices = this.get('selectedDevices');
      const aggregateGroupIds = [];
      devices.forEach(d => {
        const groupIds = d.hasMany('groups').ids();
        groupIds.forEach(id => {
          aggregateGroupIds.pushObject(id);
        });
      });
      const totals = aggregateGroupIds.reduce((counter, group) => {
        counter[group] = counter.hasOwnProperty(group) ? counter[group] + 1 : 1;
        return counter;
      }, {});
      return totals;
    },

    deactivate() {
      // Reset properties on the groups
      this.currentModel.groups.setEach('selected', undefined);
      this.currentModel.groups.setEach('partiallySelectedGroupClicked', undefined); // Reset selected groups

      this.controller.setProperties({
        groupsToAdd: [],
        groupsToRemove: []
      });
    },

    actions: {
      willTransition() {
        // This resets the model every time you load the route
        // to re-sort the groups each time the edit groups modal is displayed
        this.get('controller.model.groups').destroy();
      }

    }
  });

  _exports.default = _default;
});