define("pconsole/models/integration", ["exports", "ember-data", "pconsole/helpers/just-domain-name", "pconsole/helpers/just-topic-name"], function (_exports, _emberData, _justDomainName, _justTopicName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    product: _emberData.default.belongsTo('product', {
      async: true
    }),
    organization: _emberData.default.belongsTo('organization', {
      async: true
    }),
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    event: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    deviceID: _emberData.default.attr('string'),
    responseTopic: _emberData.default.attr('string'),
    errorResponseTopic: _emberData.default.attr('string'),
    integrationType: _emberData.default.belongsTo('integrationType'),
    disabled: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    variables: _emberData.default.attr(),
    // Integration templates
    template: _emberData.default.attr('string'),
    // Instead of template a 'belongsTo' relationship, to avoid complication in serialize
    fullTemplate: Ember.computed('template', function () {
      const templateId = this.get('template');

      if (templateId) {
        return this.store.peekRecord('integrationTemplate', templateId);
      }

      return null;
    }),
    logo: Ember.computed('fullTemplate', 'integrationType', function () {
      const template = this.get('fullTemplate');

      if (template) {
        return template.get('logo');
      }

      return "/assets/".concat(this.get('integrationType.iconImgPath'));
    }),
    typeName: Ember.computed('fullTemplate', 'integrationType', function () {
      const template = this.get('fullTemplate');

      if (template) {
        return template.get('name');
      }

      return this.get('integrationType.name');
    }),
    status: Ember.computed('disabled', {
      get() {
        return this.get('disabled') ? 'Disabled' : 'Enabled';
      },

      set(key, value) {
        this.set('disabled', typeof value === 'undefined' ? undefined : value === 'Disabled');
        return value || 'Enabled';
      }

    }),
    isWebhook: Ember.computed.equal('integrationType.id', 'Webhook'),
    isGoogleCloudPubSub: Ember.computed.equal('integrationType.id', 'GoogleCloudPubSub'),
    isAzureIotHub: Ember.computed.equal('integrationType.id', 'AzureIotHub'),
    isLogic: Ember.computed.equal('integrationType.id', 'Logic'),
    assignDevice: Ember.on('init', Ember.observer('deviceID', function () {
      const deviceID = this.get('deviceID');

      if (deviceID) {
        this.store.find('device', deviceID).then(device => {
          this.set('device', device);
        });
      }
    })),
    targetUrl: Ember.computed('isWebhook', 'isGoogleCloudPubSub', 'isAzureIotHub', 'url', 'topic', 'hubName', 'variables', function () {
      if (this.get('isWebhook')) {
        let url = this.get('url');
        const variables = this.get('variables') || [];

        if (variables.length > 0) {
          variables.forEach(({
            templateName,
            value
          }) => {
            url = url.replace("{{{".concat(templateName, "}}}"), value || '');
          });
        }

        return {
          full: url,
          short: (0, _justDomainName.justDomainName)([url])
        };
      }

      if (this.get('isGoogleCloudPubSub')) {
        const topic = this.get('topic');
        return {
          full: topic,
          short: (0, _justTopicName.justTopicName)([topic])
        };
      }

      if (this.get('isAzureIotHub')) {
        const hub = this.get('hubName');
        return {
          full: hub,
          short: hub
        };
      }

      return {
        full: '',
        short: ''
      };
    }),
    // Org integrations
    orgIntegrationId: _emberData.default.attr('string'),
    orgIntegrationCount: _emberData.default.attr(),
    productIds: _emberData.default.hasMany('product', {
      async: true
    }),
    productIntegrations: _emberData.default.attr(),
    // If the same integration is in multiple products
    isMultiProduct: Ember.computed.gt('orgIntegrationCount', 1),
    // Webhook
    url: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    requestType: _emberData.default.attr('string', {
      defaultValue: 'POST'
    }),
    noDefaults: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    rejectUnauthorized: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    responseTemplate: _emberData.default.attr('string'),
    headers: _emberData.default.attr(),
    form: _emberData.default.attr(),
    json: _emberData.default.attr(),
    query: _emberData.default.attr(),
    body: _emberData.default.attr(),
    auth: _emberData.default.attr(),
    // Webhook to Azure
    azureSasToken: _emberData.default.attr(),
    // contains key_name and key
    // Webhook to Google Cloud
    googleToken: _emberData.default.attr(),
    // contains email, scope, key
    // Webhook to Amazon
    amazonToken: _emberData.default.attr(),
    // contains accessKeyId, secretAccessKey
    logs: _emberData.default.attr(),
    errors: _emberData.default.attr(),
    counters: _emberData.default.attr(),
    dataFormat: null,
    requestTypeOptions: ['POST', 'GET', 'PUT', 'DELETE'],
    todayCounters: _emberData.default.attr(),
    canSendData: Ember.computed('requestType', function () {
      const requestType = this.get('requestType');
      return requestType === 'POST' || requestType === 'PUT' || requestType === 'DELETE';
    }),

    setDataFormatFromFields() {
      const canSendData = this.get('canSendData');
      const body = this.get('body');
      const json = this.get('json');
      let dataFormat;

      if (!canSendData) {
        dataFormat = 'Query Parameters';
      } else {
        if (json) {
          dataFormat = 'JSON';
        } else if (body) {
          dataFormat = 'Custom Body';
        } else {
          dataFormat = 'Web Form';
        }
      }

      this.set('dataFormat', dataFormat);
    },

    dataFormatInitializer: Ember.on('init', function () {
      this.setDataFormatFromFields();
    }),
    isDataFormatQueryParameters: Ember.computed.equal('dataFormat', 'Query Parameters'),
    isDataFormatWebForm: Ember.computed.equal('dataFormat', 'Web Form'),
    isDataFormatJSON: Ember.computed.equal('dataFormat', 'JSON'),
    isDataFormatCustom: Ember.computed.equal('dataFormat', 'Custom Body'),
    // END WEBHOOK
    // GOOGLE CLOUD PUB SUB
    topic: _emberData.default.attr('string'),
    // END GOOGLE CLOUD PUB SUB
    // AZURE IOT HUB
    hubName: _emberData.default.attr('string'),
    policyName: _emberData.default.attr('string'),
    policyKey: _emberData.default.attr('string'),
    // END AZURE IOT HUB
    // GOOGLE MAPS
    apiKey: _emberData.default.attr('string') // END GOOGLE MAPS

  });

  _exports.default = _default;
});