define("pconsole/models/plan", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Plan = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    isVisible: _emberData.default.attr('boolean'),
    interval: _emberData.default.attr('string'),
    trialPeriodDays: _emberData.default.attr('number'),
    billableArea: _emberData.default.attr('string'),
    requireCreditCard: _emberData.default.attr('boolean'),
    planGroup: _emberData.default.belongsTo('planGroup'),
    subscriptions: _emberData.default.hasMany('subscription', {
      async: true
    }),
    // BACK-END PLAN LIMITS
    seatLimit: _emberData.default.attr('number'),
    outboundEventLimit: _emberData.default.attr('number'),
    deviceLimit: _emberData.default.attr('number'),
    groupLimit: _emberData.default.attr('number'),
    monthlyAmount: Ember.computed('amount', 'interval', function () {
      const amount = this.get('amount');
      const interval = this.get('interval');

      if (interval === 'year') {
        return amount / 12;
      } else {
        return amount;
      }
    })
  });

  var _default = Plan;
  _exports.default = _default;
});