define("pconsole/adapters/configuration-metaschema", ["exports", "ember-data", "pconsole/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    headers: Ember.computed(function () {
      const headers = this._super(...arguments);

      return _objectSpread({}, headers, {
        accept: 'application/metaschema+json'
      });
    }),

    buildURL(modelName, _id, snapshot, requestType, query) {
      const productSlug = Ember.get(this, 'routeData.product');
      let deviceId;

      if (!productSlug) {
        // eslint-disable-next-line new-cap
        throw (0, _emberData.AdapterError)('Product ID is required');
      }

      if (query.deviceId) {
        ({
          deviceId
        } = query);
        delete query.deviceId;
      }

      const prefix = this.urlPrefix();
      return "".concat(prefix, "/products/").concat(productSlug, "/config").concat(deviceId ? '/' + deviceId : '');
    }

  });

  _exports.default = _default;
});