define("pconsole/templates/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KiRFiqKg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"hint--top\"],[10,\"data-hint\",[18,\"text\"],null],[7],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"im-info-icon help-icon\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/tool-tip.hbs"
    }
  });

  _exports.default = _default;
});