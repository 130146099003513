define("pconsole/templates/product/devices/unclaim-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iqxIbZPU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Unclaim Device\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you would like to unclaim the device \"],[6,\"strong\"],[7],[4,\"if\",[[20,[\"model\",\"name\"]]],null,{\"statements\":[[1,[20,[\"model\",\"name\"]],false]],\"parameters\":[]},{\"statements\":[[1,[20,[\"model\",\"id\"]],false]],\"parameters\":[]}],[8],[0,\"? The current owner will lose the ability to view or interact with the device via the Particle Cloud, and it will be returned to an unclaimed state.\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Note you should only unclaim a device if you wish to transfer ownership of it to another Particle account or user. \"],[6,\"b\"],[7],[0,\"Unclaiming a device is not an effective troubleshooting tool\"],[8],[0,\" and has no impact on its connection to the Particle Cloud or the firmware running on it.\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you want to unclaim \"],[6,\"strong\"],[7],[4,\"if\",[[20,[\"model\",\"name\"]]],null,{\"statements\":[[1,[20,[\"model\",\"name\"]],false]],\"parameters\":[]},{\"statements\":[[1,[20,[\"model\",\"id\"]],false]],\"parameters\":[]}],[8],[0,\"?\"],[8],[0,\"\\n  \"],[6,\"a\"],[9,\"class\",\"remove-user-btn ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"unclaimDevice\"]],[7],[0,\"Yes, unclaim device\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/unclaim-device.hbs"
    }
  });

  _exports.default = _default;
});