define("pconsole/serializers/client", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse(store, type, payload, id, requestType) {
      delete payload.ok;
      return this._super(store, type, payload, id, requestType);
    },

    normalizeArrayResponse(store, type, payload, id, requestType) {
      const clients = payload.clients;
      clients.forEach(client => {
        if (!client.scopes) {
          client.scopes = ['none'];
        }
      });
      return this._super(store, type, payload, id, requestType);
    },

    normalizeSaveResponse(store, type, payload, id, requestType) {
      return this._super(store, type, payload, id, requestType);
    },

    serialize(snapshot) {
      const record = snapshot.record;
      const json = {
        organization: record.get('organization.slug'),
        name: record.get('name'),
        type: record.get('type'),
        redirect_uri: record.get('redirectURI'),
        scope: record.get('scopes') && record.get('scopes').join && record.get('scopes').join(' ')
      };
      return json;
    },

    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      Ember.merge(hash, json);
    }

  });

  _exports.default = _default;
});