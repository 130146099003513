define("pconsole/controllers/user/integrations/webhooks/show", ["exports", "pconsole/mixins/webhook-default-fields", "pconsole/mixins/grouped-integration"], function (_exports, _webhookDefaultFields, _groupedIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_webhookDefaultFields.default, _groupedIntegration.groupedIntegrationNotification, {
    webhook: Ember.computed.alias('model'),
    formWithDefaultFields: Ember.computed('model.form', 'defaultFields', function () {
      return Ember.assign({}, this.get('defaultFields'), this.get('model.form'));
    }),

    /**** Parametrized form ***/
    simpleForm: Ember.computed.alias('webhook.fullTemplate.simpleForm'),
    hasSimpleForm: Ember.computed.notEmpty('simpleForm'),
    advancedVisible: true,
    initAdvancedView: Ember.observer('hasSimpleForm', function () {
      Ember.set(this, 'advancedVisible', !Ember.get(this, 'hasSimpleForm'));
    }).on('init'),
    actions: {
      toggleAdvancedInfo() {
        this.toggleProperty('advancedVisible');
      }

    },

    /**** END parametrized form ***/
    normalizedJSON: Ember.computed('model.json', 'defaultFields', function () {
      const json = this.get('webhook.json');
      const defaultFields = this.get('defaultFields');
      let normalized = null;

      switch (Ember.typeOf(json)) {
        case 'object':
          normalized = JSON.stringify(Ember.assign({}, defaultFields, json), true, 2);
          break;

        case 'array':
          normalized = JSON.stringify(json, true, 2);
          break;

        case 'boolean':
          normalized = JSON.stringify(defaultFields, true, 2);
          break;

        case 'string':
          normalized = json;
          break;
      }

      return normalized;
    }),
    queryParamsWithDefaultFields: Ember.computed('model.query', 'queryParametersDefaultFields', function () {
      return Ember.assign({}, this.get('queryParametersDefaultFields'), this.get('model.query'));
    }),
    hasQueryParams: Ember.computed('queryParamsWithDefaultFields', function () {
      return Object.keys(this.get('queryParamsWithDefaultFields')).length > 0;
    }),
    headersWithDefaultFields: Ember.computed('model.headers', 'headersDefaultFields', function () {
      return Ember.assign({}, this.get('headersDefaultFields'), this.get('model.headers'));
    }),
    hasHeaders: Ember.computed('headersWithDefaultFields', function () {
      return Object.keys(this.get('headersWithDefaultFields')).length > 0;
    })
  });

  _exports.default = _default;
});