define("pconsole/mixins/show-integration-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showIntegrationRoute = void 0;
  const showIntegrationRoute = {
    queryParams: {
      showGrouped: {
        refreshModel: false,
        replace: true
      }
    },
    activate: function activate() {
      this._super();

      Ember.$('.dashboard').scrollTop(0);
    },
    deactivat: function deactivat() {
      this._super();

      const controllerName = Ember.get(this, 'controllerName');
      const controller = this.controllerFor(controllerName);
      Ember.set(controller, 'advancedVisible', true);
    },
    breadCrumb: {
      title: 'View Integration',
      linkable: false
    },

    model(params) {
      return this.store.findRecord('integration', params.integration_id, {
        reload: true
      });
    },

    afterModel(model) {
      const emberRouteName = Ember.get(model, 'integrationType.emberRouteName');
      Ember.set(this, 'controllerName', "user.integrations.".concat(emberRouteName, ".show"));
    },

    setupController(controller, model) {
      controller.set('parentEntity', Ember.get(this, 'parentEntity'));
      controller.set('model', model);
    },

    renderTemplate() {
      const emberRouteName = this.currentModel.get('integrationType.emberRouteName');
      this.render("user.integrations.".concat(emberRouteName, ".show"));
    }

  };
  _exports.showIntegrationRoute = showIntegrationRoute;
});