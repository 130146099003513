define("pconsole/controllers/product/map/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    device: Ember.computed('model.lastHeard', function () {
      const deviceRecord = Ember.get(this, 'model');
      return deviceRecord.toObject();
    }),
    devicePageUrl: Ember.computed('device.id', function () {
      const id = Ember.get(this, 'device.id');
      return Ember.get(this, 'router').urlFor('product.device', id);
    }),
    actions: {
      export() {
        this.send('exportCSV');
      }

    }
  });

  _exports.default = _default;
});