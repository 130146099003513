define("pconsole/components/eventlogs/suggestions-table.react", ["exports", "react", "pconsole/components/eventlogs/suggestion.react", "pconsole/components/eventlogs/suggestions-util"], function (_exports, _react, _suggestion, _suggestionsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SuggestionsTable = ({
    suggestions,
    onSuggestionClick,
    query
  }) => _react.default.createElement('table', {
    className: '_eventlogsnew__content__suggestions'
  }, _react.default.createElement('thead', null, _react.default.createElement('tr', null, _react.default.createElement('th', null, 'Modifier'), _react.default.createElement('th', null, 'Description'), _react.default.createElement('th', null, 'Example Usage'))), _react.default.createElement('tbody', null, _suggestionsUtil.default.filterVisibleSuggestions(suggestions).map((suggestion, index) => _react.default.createElement(_suggestion.default, {
    key: "suggestion-".concat(index),
    suggestion: suggestion,
    onClick: () => onSuggestionClick(suggestion, query)
  }))));

  var _default = SuggestionsTable;
  _exports.default = _default;
});