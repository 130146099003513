define("pconsole/mixins/save-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    initialOrgIntegrationCount: null,

    save() {
      Ember.$('.loading').show();
      const integration = this.get('integration');
      this.set('initialOrgIntegrationCount', integration.get('orgIntegrationCount') || 1);
      integration.save().then(() => {
        this.createIntegrationSuccess();
      }, response => {
        this.createIntegrationFailure(response);
      });
    },

    createIntegrationSuccess() {
      const parentEntity = this.get('parentEntity');
      const isEditing = this.get('isEditing');
      const diff = this.get('integration.orgIntegrationCount') - this.get('initialOrgIntegrationCount');
      const showGrouped = parentEntity === 'product' && diff > 0;
      const params = {
        queryParams: showGrouped ? {
          showGrouped
        } : null
      };
      this.transitionToRoute("".concat(parentEntity, ".integrations.show"), this.get('integration.id'), params).then(() => {
        Ember.$('.loading').hide();
        this.get('notify').success("Your integration was ".concat(isEditing ? 'edited' : 'created', " successfully!"));
      }).catch(() => {
        Ember.$('.loading').hide();
      });
    },

    createIntegrationFailure(response) {
      Ember.$('.loading').hide();
      const error = response.errors ? response.errors[0] : null;
      const errorMsg = error ? error.detail : "Your integration couldn't be created. Please try again later.";
      this.get('notify').alert(errorMsg);
    }

  });

  _exports.default = _default;
});