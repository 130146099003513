define("pconsole/templates/form-inputs/no-validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+J8zc9Xu",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",null,[[\"id\",\"invalid\",\"placeholder\",\"name\",\"type\",\"value\",\"readonly\",\"required\",\"autofocus\",\"disabled\",\"size\",\"tabindex\",\"maxlegth\",\"min\",\"max\",\"pattern\",\"accept\",\"autocomplete\",\"autosave\",\"formaction\",\"formenctype\",\"formmethod\",\"formnovalidate\",\"formtarget\",\"height\",\"inputmode\",\"multiple\",\"step\",\"width\",\"form\",\"selectionDirection\",\"spellcheck\"],[[20,[\"inputId\"]],[20,[\"isInvalid\"]],[20,[\"placeholder\"]],[20,[\"name\"]],\"text\",[20,[\"value\"]],[20,[\"readonly\"]],[20,[\"required\"]],[20,[\"autofocus\"]],[20,[\"disabled\"]],[20,[\"size\"]],[20,[\"tabindex\"]],[20,[\"maxlength\"]],[20,[\"min\"]],[20,[\"max\"]],[20,[\"patterm\"]],[20,[\"accept\"]],[20,[\"autocomplete\"]],[20,[\"autosave\"]],[20,[\"formaction\"]],[20,[\"formenctype\"]],[20,[\"formmethod\"]],[20,[\"formnovalidate\"]],[20,[\"formtarget\"]],[20,[\"height\"]],[20,[\"inputmode\"]],[20,[\"multiple\"]],[20,[\"step\"]],[20,[\"width\"]],[20,[\"form\"]],[20,[\"selectionDirection\"]],[20,[\"spellcheck\"]]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/form-inputs/no-validations.hbs"
    }
  });

  _exports.default = _default;
});