define("pconsole/models/question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Question = _emberData.default.Model.extend({
    text: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    choices: _emberData.default.hasMany('choice', {
      async: true
    }),
    defaultChoice: _emberData.default.attr('string'),
    section: _emberData.default.belongsTo('section', {
      async: false
    }),
    answer: _emberData.default.attr('string'),
    isDone: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    onProduct: _emberData.default.attr('boolean'),
    sendChangeAction: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    disabled: _emberData.default.attr('boolean'),
    reason: _emberData.default.attr('string'),
    answerValue: Ember.computed('answer', {
      set(key, value) {
        this.set('answer', value);
        this.save();
      },

      get() {
        const answer = this.get('answer');

        if (answer === undefined) {
          const choice = this.get('defaultChoice');
          this.set('answer', choice);
          this.save();
          return choice;
        }

        return answer;
      }

    }),
    actionName: Ember.computed('id', function () {
      return 'question-' + this.get('id');
    }),
    isMultipleChoice: Ember.computed('type', function () {
      return this.get('type') === 'multi';
    }),
    isText: Ember.computed('type', function () {
      return this.get('type') === 'text';
    })
  });

  Question.reopenClass({
    FIXTURES: [{
      id: 'name',
      text: '',
      type: 'text',
      title: 'Product Name',
      onProduct: true,
      isDone: true
    }, {
      id: 'description',
      text: '',
      type: 'text',
      title: 'Brief Description',
      onProduct: true,
      isDone: true
    }, {
      id: 'hardwareVersion',
      text: '',
      type: 'text',
      title: 'Hardware Version',
      onProduct: true,
      isDone: true
    }, {
      id: 'platformId',
      text: '',
      type: 'text',
      title: 'Particle Device Type',
      onProduct: true,
      isDone: true
    }, {
      id: 'type',
      text: '',
      type: 'multi',
      title: 'Product Type',
      onProduct: true,
      defaultChoice: 'Consumer',
      choices: ['type-Consumer', 'type-Industrial', 'type-Hobbyist'],
      isDone: true
    }, {
      id: 'id',
      text: '',
      type: 'text',
      title: 'Product ID (<i class="im-key-icon"></i>)',
      onProduct: true,
      isDone: true
    }, {
      id: 'whitelabel',
      text: '<h4>Will you be whitelabeling the product, i.e. customers will not be aware that it is "Powered by Particle"?</h4><p>We understand that this is your product, and you may want to have our involvement invisible. There are a couple of places where our in/visibility has an impact on what we can actually do, so here are the tradeoffs:</p>' + '<table><thead><tr><th></th><th>Particle</th><th>Whitelabel</th></tr></thead>' + '<tbody><tr><td>user login</td><td>can be reused</td><td>cannot be reused</td></tr>' + '<tr><td>api</td><td>no changes</td><td>custom domains cost extra</td></tr></table>',
      type: 'multi',
      defaultChoice: 'Yes',
      title: 'White Label',
      sendChangeAction: true,
      choices: ['whitelabel-Yes', 'whitelabel-No']
    }, {
      id: 'authentication',
      text: '<h4>User authentication/Logging in with Particle</h4><p>Would you like Particle to manage user authentication? Will you also be using these logins on your own site/servers?</p><p>We recommend that you choose one of three secure scenarios for handling authentication, based on your use case. They’re ordered from simplest to most complex to implement.</p>',
      defaultChoice: 'Particle',
      type: 'multi',
      title: 'Authentication',
      choices: ['authentication-Particle', 'authentication-oauth', 'authentication-org']
    }, {
      id: 'loginReuse',
      text: '<h4>Would you like customers to be able to reuse their login with other manufacturers’ products?</h4><p>A customer may have products from two different manufacturers. This setting allows the customer to use the same login for both products, without signing up for a new account for the second product. You’ll still own your data, and nothing will be shared with the other vendor.</p>',
      type: 'multi',
      title: 'Login Reuse',
      defaultChoice: 'No',
      choices: ['login-reuse-Yes', 'login-reuse-No']
    }, {
      id: 'dataHosting',
      text: '<h4>Will you need to host customer-specific information on your own servers, like recipes, behaviors, profiles?</h4><p>Sometimes a product has lots of settings, or cloud-driven automated behavior that you’d like to have live on your own infrastructure. If this is the case for you, here are some implications and best practices for your architecture, and a possible alternative to make your life simpler.</p>' + '<ul><li>How to: Devices talking directly to your DBs</li><li>How to: Backend controlling devices via ParticleJS... other suggestions/concerns?</li><li>Other ways this could be done...</li></ul>',
      type: 'multi',
      title: 'Data Hosting',
      defaultChoice: 'No',
      choices: ['data-hosting-Yes', 'data-hosting-No']
    }, {
      id: 'privateBeta',
      text: '<h4>Is your product in private beta?</h4><p>In other words, do you want to be able to invite specific customers to use your product, and only allow those customers access to use their devices? This is likely a good idea if you would like to run a controlled test for your product. As a manager of a private beta, you will import a list of customers you would like to participate, and each one will be assigned a 4-character activation code that they will need to claim their device during setup.</p>',
      type: 'multi',
      title: 'Private Beta',
      defaultChoice: 'No',
      choices: ['private-beta-Yes', 'private-beta-No']
    }, {
      id: 'customerSharing',
      text: '<h4>Can customers share access to this products, like with guests/friends/family? Will those other users have limited permissions?</h4><p>Will the device be used wirelessly by more than one person? A common scenario here is a device used by several members of a household, like lights.</p>',
      type: 'multi',
      title: 'Account Sharing',
      defaultChoice: 'No',
      sendChangeAction: true,
      choices: ['customer-sharing-Yes', 'customer-sharing-No']
    }, {
      id: 'sharingAccess',
      text: '<h4>Will there be different permission levels, or will everyone have the same access?</h4>',
      type: 'multi',
      title: 'Multiple Sharing Access Levels',
      defaultChoice: 'No',
      choices: ['sharing-access-Yes', 'sharing-access-No']
    }, {
      id: 'customerAPIAccess',
      text: '<h4>Can a customer access the device via the API? This is also referred to as "developer friendly".</h4><p>If your customers are technically savvy, they may want to write their own software that can communicate with their product. If you allow it, they can build out your community, create new features for you that you hadn’t anticipated, and generally improve your Product without altering the software that’s actually running on the device. This option is also referred to as “developer friendly.”</p>',
      defaultChoice: 'No',
      type: 'multi',
      title: 'Customer API Access',
      sendChangeAction: true,
      choices: ['customerAPIAccess-Yes', 'customerAPIAccess-No']
    }, {
      id: 'customerReprogrammable',
      text: '<h4>Can a customer reprogram their product?</h4><p>If your product is for a technical or hobbyist market, you may want to allow your customer to reprogram the firmware of the device, much like you did when first developing on your Particle device.</p>',
      defaultChoice: 'No',
      type: 'multi',
      title: 'Reprogrammable Devices',
      choices: ['customerReprogrammable-Yes', 'customerReprogrammable-No']
    }, {
      id: 'thirdPartyAPIAccess',
      text: '<h4>Beyond the immediate owner of the device, can the owner give permission to a third party to access the device?</h4><p>A good example would be a remote-control app to control the lights, or coordinate the behavior of several products.</p>',
      defaultChoice: 'No',
      type: 'multi',
      title: 'Third-Party API Access',
      choices: ['thirdPartyAPIAccess-Yes', 'thirdPartyAPIAccess-No']
    }, {
      id: 'manufacturingFirmware',
      text: '<h4>How would you like to program the product when manufacturing?</h4><p>Somehow you’ll your firmware has to get onto your devices, and here are the two recommended options:',
      defaultChoice: 'On Setup',
      type: 'multi',
      title: 'Setup Firmware',
      choices: ['manufacturingFirmware-Setup', 'manufacturingFirmware-Line']
    }, {
      id: 'unrecognizedDevice',
      text: '<h4>How should we handle unrecognized devices?</h4><p>For security reasons, any device that should behave as this product must be explicitly imported/added to the product. This ensures that only approved devices will be granted product privileges, including (but not limited to) receiving product firmware binaries, sending events that appear in this product\'s event stream, and triggering product webhooks.</p><p>It is possible that a device will self-identify with your product\'s ID to the Particle cloud without being previously imported into the product. Most of the time, this happens because someone on your team flashed a device with firmware containing your product\'s ID without the device being imported into the product. However, this could also be an indication that someone is attempting to "spoof" your product.</p><p>By default, Particle will automatically quarantine these devices, blocking product-specific privileges. However, you may choose to automatically approve devices that self-identify with your product ID even if they have not been previously imported. This could be useful if you don\'t have access to the IDs of the devices that are being given your product\'s firmware.</p>',
      defaultChoice: 'Quarantine',
      type: 'multi',
      title: 'Unrecognized Devices',
      choices: ['unrecognizedDevice-Quarantine', 'unrecognizedDevice-Approve']
    }]
  });
  var _default = Question;
  _exports.default = _default;
});