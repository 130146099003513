define("pconsole/routes/organization/team/remove-team-member", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OrganizationRemoveTeamMemberRoute = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return Ember.RSVP.hash({
        teamMember: this.store.peekRecord('team', params.username),
        org: this.modelFor('organization').organization
      });
    }

  });
  var _default = OrganizationRemoveTeamMemberRoute;
  _exports.default = _default;
});