define("pconsole/test-helpers/sync/fill-in-input", ["exports", "ember-easy-form-extensions/test-helpers/sync/fill-in-input"], function (_exports, _fillInInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fillInInput.default;
    }
  });
});