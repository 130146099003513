define("pconsole/routes/organization/insights/index", ["exports", "pconsole/mixins/embed-listeners"], function (_exports, _embedListeners) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_embedListeners.EmbedListeners, {
    apiAjax: Ember.inject.service(),
    controllerRoute: 'organization.insights.index',

    async model() {
      const {
        dashboards
      } = this.modelFor('organization.insights');
      const organization = this.modelFor('organization').organization;
      const {
        url
      } = await Ember.get(this, 'apiAjax').request({
        url: "/v1/orgs/".concat(Ember.get(organization, 'slug'), "/reports/insights"),
        type: 'GET'
      });
      return {
        dashboards,
        url
      };
    }

  });

  _exports.default = _default;
});