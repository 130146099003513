define("pconsole/components/form-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['invalid:aria-invalid', 'required:aria-required'],
    content: null,
    prompt: null,
    optionValuePath: 'id',
    optionLabelPath: 'title',
    selection: null,

    /* eslint new-cap: 0 */
    _selection: Ember.computed('selection', 'value', 'content', 'optionValuePath', {
      get() {
        const content = this.get('content');
        const optionValuePath = this.get('optionValuePath');
        const selection = this.get('selection');
        const value = this.get('value');

        if (selection) {
          return selection;
        }

        if (value === undefined) {
          return null;
        }

        if (optionValuePath === 'content') {
          return value;
        }

        return content.findBy(optionValuePath, value);
      }

    }),

    init() {
      this._super(...arguments);

      if (!this.get('content')) {
        this.set('content', []);
      }
    },

    focusIn() {
      const selectDiv = this.$().parent().parent();
      selectDiv.toggleClass('focused');
    },

    focusOut() {
      const selectDiv = this.$().parent().parent();
      selectDiv.toggleClass('focused');
      this.sendAction('showError');
    },

    actions: {
      change() {
        const select = this.$('select')[0];
        const optionValuePath = this.get('optionValuePath');

        if (select.selectedIndex === -1) {
          return;
        } // decrement index by 1 if we have a prompt


        const hasPrompt = !!this.get('prompt');
        const contentIndex = hasPrompt ? select.selectedIndex - 1 : select.selectedIndex;
        const selection = this.get('content').objectAt(contentIndex);
        let value = null;

        if (selection) {
          if (optionValuePath === 'content') {
            value = selection;
          } else {
            value = Ember.get(selection, optionValuePath);
          }
        }

        this.sendAction('action', value);
      }

    }
  });

  _exports.default = _default;
});