define("pconsole/routes/product/sims/import/scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    apiAjax: Ember.inject.service(),
    breadCrumb: {
      title: 'Import'
    },

    model() {
      return this.modelFor('product.sims').product;
    },

    setupController(controller) {
      this._super(...arguments);

      this.get('apiAjax').request({
        url: '/v1/sims',
        type: 'GET'
      }).then(response => {
        controller.set('userOwnedSims', response.sims.mapBy('_id'));
      });
    },

    actions: {
      willTransition() {
        const controller = this.get('controller');
        controller.setProperties({
          iccidList: [],
          selectedIccids: []
        });
        controller.disableQuagga();
      }

    }
  });

  _exports.default = _default;
});