define("pconsole/components/grid", ["exports", "prop-types", "styled-components", "pconsole/components/grid-column"], function (_exports, _propTypes, _styledComponents, _gridColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Grid = _styledComponents.default.div.withConfig({
    displayName: "grid__Grid",
    componentId: "sc-19qexho-0"
  })(["display:grid;grid-template-columns:repeat(", ",1fr);grid-gap:", ";> .ember-view{display:contents;}"], props => props.columns, props => props.gap);

  Grid.Column = _gridColumn.default;
  Grid.propTypes = {
    columns: _propTypes.default.string.isRequired,
    gap: _propTypes.default.string
  };
  Grid.defaultProps = {
    columns: '12',
    gap: '1.6rem'
  };
  var _default = Grid;
  _exports.default = _default;
});