define("pconsole/components/device-variables", ["exports", "pconsole/mixins/device-variables-functions-helpers", "pconsole/mixins/permissions"], function (_exports, _deviceVariablesFunctionsHelpers, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, _deviceVariablesFunctionsHelpers.default, {
    variables: null,
    scope: 'devices.variable:get',

    _fetchDeviceVariables() {
      this.set('loadingVariables', true);
      return this.get('apiAjax').getRequest({
        url: this._constructUrl()
      }).then(response => response, this._error.bind(this, 'Failed to fetch device variables'));
    },

    _saveDeviceVariables(response) {
      if (this.get('isDestroyed')) {
        return;
      }

      this.set('loadingVariables', false);

      if (response && response.variables) {
        const variables = response.variables;
        let v = {};
        Object.keys(variables).forEach(item => {
          v[item] = {
            name: item,
            type: variables[item],
            result: null
          };
        });

        if (Object.keys(variables).length < 1) {
          v = null;
        }

        this.set('variables', v);
      }
    },

    _updateVariableProperty(name, property, value) {
      const variables = this.get('variables');
      Ember.set(variables[name], property, value);
      this.set('variables', variables);
    },

    _showLoader(variable) {
      this._updateVariableProperty(variable, 'loading', true);
    },

    _hideLoader(variable) {
      this._updateVariableProperty(variable, 'loading', false);
    },

    _getDeviceVariables: function () {
      return this._fetchDeviceVariables().then(this._saveDeviceVariables.bind(this));
    }.on('init'),
    actions: {
      getDeviceVariables() {
        return this._getDeviceVariables();
      },

      fetch(variable) {
        this._updateVariableProperty(variable, 'result', null);

        this._showLoader(variable);

        return this.get('apiAjax').getRequest({
          url: "".concat(this._constructUrl(), "/").concat(variable),
          timeout: 30000
        }).then(response => {
          const result = this._parseResponse(response, 'result');

          this._updateVariableProperty(variable, 'result', result.result);

          this._updateVariableProperty(variable, 'hasResult', typeof result.result !== 'undefined' && result.result !== null);

          this._hideLoader(variable);
        }, () => {
          this._error("Failed to fetch ".concat(variable));

          this._hideLoader(variable);
        });
      }

    }
  });

  _exports.default = _default;
});