define("pconsole/models/team", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    invited: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    noMfa: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    scimProvisioned: _emberData.default.attr('boolean'),
    noPassword: _emberData.default.attr('boolean'),
    role: _emberData.default.belongsTo('role', {
      async: true
    }),
    product: _emberData.default.belongsTo('product'),
    organization: _emberData.default.belongsTo('organization', {
      async: true
    }),
    isOwner: Ember.computed('role', function () {
      return this.get('role.owner');
    }),
    isProgrammatic: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    mfaWarn: Ember.computed('noMfa', function () {
      return this.get('noMfa') ? 'Two-step authentication not enabled' : '';
    }),
    ssoUser: Ember.computed('scimProvisioned', function () {
      return this.get('scimProvisioned') ? 'Enterprise SSO' : '';
    }),
    shouldWarnMfa: Ember.computed('noMfa', 'noPassword', function () {
      const noMfa = this.get('noMfa');
      const noPassword = this.get('noPassword');
      return !noPassword && noMfa;
    })
  });

  _exports.default = _default;
});