define("pconsole/templates/product/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "goe4ygqk",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[0,\"Settings\"],[8],[0,\"\\n\"],[1,[25,\"product-settings\",[[20,[\"product\"]]],[[\"isDeleteEnabled\",\"isOwner\",\"onDeleteClick\"],[[20,[\"isDeleteEnabled\"]],[20,[\"isOwner\"]],[25,\"action\",[[19,0,[]],\"confirmDeleteProduct\"],null]]]],false],[0,\"\\n\"],[1,[18,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/settings.hbs"
    }
  });

  _exports.default = _default;
});