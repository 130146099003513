define("pconsole/routes/organization/integrations/show/delete", ["exports", "pconsole/mixins/modal-route", "pconsole/mixins/delete-integration-route"], function (_exports, _modalRoute, _deleteIntegrationRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _deleteIntegrationRoute.deleteIntegrationRoute, {
    parentEntity: 'organization',

    model() {
      return this.modelFor('organization.integrations.show');
    }

  });

  _exports.default = _default;
});