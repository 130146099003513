define("pconsole/components/feature-toggler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    show: null,
    feature: null,
    launchDarkly: Ember.inject.service(),
    tagName: '',

    async init() {
      this._super(...arguments);

      const feature = this.get('feature');
      const launchDarkly = this.get('launchDarkly');
      let showFeature = false;

      try {
        showFeature = await launchDarkly.variation(feature, false);
      } catch (_ex) {
        console.log("There was a problem toggling the ".concat(feature, " feature"));
      }

      if (!this.isDestroyed) {
        this.set('show', showFeature);
      }
    }

  });

  _exports.default = _default;
});