define("pconsole/adapters/sim-usage-datapoint", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      const isProduct = this.get('routeData.hasProduct');

      if (isProduct) {
        return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/products/").concat(this.get('routeData.product'), "/sims/").concat(query.iccid, "/data_usage");
      } else {
        return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/sims/").concat(query.iccid, "/data_usage");
      }
    },

    query(store, type, query) {
      // This ensures we don't append a query string to the request when we don't need one
      const url = this.buildURL(type.modelName, null, null, 'query', query);
      return this.ajax(url, 'GET');
    }

  });

  _exports.default = _default;
});