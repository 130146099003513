define("pconsole/controllers/product/firmware/upload", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _form.default, {
    notify: Ember.inject.service(),
    validations: {
      'model.version': {
        presence: {
          message: 'is required for your firmware binary'
        },
        numericality: {
          onlyInteger: true,
          greaterThan: 0,
          messages: {
            onlyInteger: 'must be a positive whole number',
            greaterThan: 'must be a positive whole number'
          }
        },
        'existing-model': {
          modelName: 'product-firmware',
          message: 'must not already be in use'
        }
      },
      'model.binary': {
        presence: {
          message: 'is required'
        }
      },
      'model.title': {
        presence: {
          message: 'is required for your firmware binary'
        }
      }
    },
    actions: {
      closeModal() {
        this.transitionToRoute('product.firmware');
      }

    },

    save() {
      return this.model.save().then(() => {
        this.get('notify').success('Your firmware version has been saved successfully!');
        this.transitionToRoute('product.firmware').then(() => {
          this.send('refreshModel');
        });
      }, err => {
        const msg = err && err.errors && _.map(err.errors, 'detail').join('\n') || err;

        if (msg === 'You must compile product firmware against 0.4.1') {
          this.set('firmwareWarningMode', true);
        } else {
          this.get('notify').alert('Your firmware could not be created. ' + msg);
        }
      });
    }

  });

  _exports.default = _default;
});