define("pconsole/templates/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1THfrm8s",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[20,[\"isIntegrationsIndexRoute\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"bread-crumbs\",null,[[\"tagName\",\"linkable\"],[\"ul\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/user/integrations.hbs"
    }
  });

  _exports.default = _default;
});