define("pconsole/models/device", ["exports", "ember-data", "semver", "pconsole/mixins/device-type", "pconsole/utils/platforms", "ember-cp-validations"], function (_exports, _emberData, _semver, _deviceType, _platforms, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'name': (0, _emberCpValidations.validator)('format', {
      regex: /^[\w@.+-]+$/,
      allowBlank: true,
      message: 'Device name can use characters A-Z, a-z, 0-9, @, . (period), _, +, and -'
    })
  });

  var _default = _emberData.default.Model.extend(_deviceType.default, Validations, {
    bsonId: _emberData.default.attr('string'),
    denied: _emberData.default.attr('boolean'),
    desiredFirmwareVersion: _emberData.default.attr('number'),

    /**
     * A development device is an attribute relevant for product devices. If set
     * to true, the device will opt-out of automatic product firmware updates,
     * like locked firmware and released firmware.
     */
    development: _emberData.default.attr('boolean'),
    firmwareProductId: _emberData.default.attr('string'),
    firmwareUpdatesEnabled: _emberData.default.attr('boolean'),
    firmwareUpdatesForced: _emberData.default.attr('boolean'),
    firmwareVersion: _emberData.default.attr('number'),

    /** Only used to flash product firmware immediately. NOT stored in the database. */
    flash: _emberData.default.attr('boolean'),
    iccid: _emberData.default.attr('string'),

    /**
     * Used on the front-end only. If we expand this to multiple
     * objects, we should abstract this into a mixin.
     */
    isChecked: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    imei: _emberData.default.attr('string'),
    lastApp: _emberData.default.attr('string'),
    lastHandshakeAt: _emberData.default.attr('date'),
    lastHeard: _emberData.default.attr('date'),
    lastIpAddress: _emberData.default.attr('string'),
    lifecycleState: _emberData.default.attr('string'),
    networkId: _emberData.default.attr('string'),
    networkName: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    online: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    owner: _emberData.default.attr('string'),
    quarantined: _emberData.default.attr('boolean'),
    serialNumber: _emberData.default.attr('string'),
    showFunctionsAndVariables: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    systemFirmwareVersion: _emberData.default.attr('string'),

    /**
     * This attribute will tell us if there is a pending released firmware version
     * that the device is targeted to receive. Because this logic is complex due
     * to group associations, the API will tell us this value. This value will be
     * null if the device is already running the released firmware in quesion.
     */
    targetedFirmwareReleaseVersion: _emberData.default.attr('number'),
    assets: _emberData.default.attr(),
    deviceProtection: _emberData.default.attr(),
    lorawanPoc: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    events: _emberData.default.hasMany('event', {
      async: true
    }),
    groups: _emberData.default.hasMany('group'),
    platform: _emberData.default.belongsTo('platform'),
    product: _emberData.default.belongsTo('product', {
      async: true
    }),
    sim: _emberData.default.belongsTo('sim', {
      async: true
    }),
    user: _emberData.default.belongsTo('user', {
      async: false
    }),
    isPaused: Ember.computed.equal('lifecycleState', 'paused'),
    isProtected: Ember.computed.equal('deviceProtection.status', 'active'),
    lastIccid: Ember.computed.alias('iccid'),
    canSupportDeviceVitals: Ember.computed('systemFirmwareVersion', function () {
      const systemFirmwareVersion = this.get('systemFirmwareVersion');

      if (!systemFirmwareVersion) {
        return true;
      }

      const systemFirmwareVersionNoRC = systemFirmwareVersion.split('-')[0];
      return _semver.default.gte(systemFirmwareVersionNoRC, '0.8.0');
    }),
    deviceID: Ember.computed('id', function () {
      return this.get('id');
    }),
    inSafeMode: Ember.computed('status', function () {
      return this.get('status') === 'safe-mode';
    }),
    state: Ember.computed('sim', 'lifecycleState', 'online', 'inSafeMode', 'lastHandshakeAt', function () {
      return _emberData.default.PromiseObject.create({
        promise: (async () => {
          let sim;

          try {
            sim = await this.get('sim');
          } catch (_unused) {
            /* Failed to get ICCID or no sim with it */
          }

          const lifecycleState = this.get('lifecycleState');

          if (lifecycleState === 'paused') {
            return 'Paused';
          }

          if (sim) {
            const simStatus = sim.get('status');

            if (simStatus !== 'active') {
              if (sim.get('stateChangeInProgress')) {
                return 'Activating SIM';
              }

              return 'Deactivated SIM';
            }

            if (simStatus === 'active' && sim.get('stateChangeInProgress')) {
              return 'Deactivating SIM';
            }
          }

          const online = this.get('online');
          const inSafeMode = this.get('inSafeMode');

          if (online) {
            if (inSafeMode) {
              return 'Safe Mode';
            }

            return 'Online';
          }

          const lastHandshakeAt = this.get('lastHandshakeAt');

          if (!lastHandshakeAt) {
            return 'Waiting to connect';
          }

          return 'Offline';
        })()
      });
    }),
    isCellular: Ember.computed('platform.id', function () {
      const id = this.get('platform.id');
      return (0, _platforms.isCellularPlatform)(id);
    }),
    isRunningLockedProductFirmware: Ember.computed('desiredFirmwareVersion', 'firmwareVersion', function () {
      const desiredFirmwareVersion = this.get('desiredFirmwareVersion');
      const firmwareVersion = this.get('firmwareVersion');
      const isRunningValidProductFirmware = this.get('isRunningValidProductFirmware');
      return isRunningValidProductFirmware && !!desiredFirmwareVersion && desiredFirmwareVersion === firmwareVersion;
    }),
    isRunningTargetedProductFirmwareVersion: Ember.computed('firmwareVersion', 'targetedProductFirmwareVersion', function () {
      const currentFirmwareVersion = this.get('firmwareVersion');
      const targetedProductFirmwareVersion = this.get('targetedProductFirmwareVersion');
      const isRunningValidProductFirmware = this.get('isRunningValidProductFirmware'); // If the value of targetedProductFirmwareVersion is null, this means the device is
      // already running the firmware it should be running.

      if (!targetedProductFirmwareVersion) {
        return true;
      } else {
        return isRunningValidProductFirmware && currentFirmwareVersion === targetedProductFirmwareVersion;
      }
    }),
    isRunningValidProductFirmware: Ember.computed('firmwareVersion', 'firmwareProductId', 'product', function () {
      const fwVersion = this.get('firmwareVersion');
      const fwProductId = this.get('firmwareProductId');
      const dbProductId = this.belongsTo('product').id();
      return !!fwVersion && fwProductId === dbProductId;
    }),
    targetedProductFirmwareVersion: Ember.computed('desiredFirmwareVersion', 'targetedFirmwareReleaseVersion', function () {
      const desiredVersion = this.get('desiredFirmwareVersion');
      const targetedReleaseVersion = this.get('targetedFirmwareReleaseVersion');

      if (!desiredVersion && !targetedReleaseVersion) {
        return null;
      }

      return parseInt(desiredVersion || targetedReleaseVersion, 10);
    }),

    /* For Boron/B SoM devices with a bad SIM, restrict product firmware upgrades
     * to Device OS versions that don't contain a workaround. */
    productFirmwareUpdateRestricted: Ember.computed('systemFirmwareVersion', 'targetedProductFirmwareVersion', 'platform', 'iccid', function () {
      const currentDeviceOsVersion = this.get('systemFirmwareVersion');
      const targetedProductFirmwareVersion = this.get('targetedProductFirmwareVersion');
      const platform = this.get('platform');
      const iccid = this.get('iccid');

      if (!currentDeviceOsVersion || !targetedProductFirmwareVersion || !platform || !iccid) {
        return false;
      } // Check if device SIM is affected


      const platforms = (0, _platforms.getPlatformSpecs)();
      const affectedPlatformIds = [platforms.bsom.id, platforms.boron.id];

      if (!affectedPlatformIds.includes(parseInt(platform.get('id'), 10))) {
        return false;
      }

      const ATT_SIM_ISSUE_ICCID_PREFIX = '890103';

      if (!iccid.startsWith(ATT_SIM_ISSUE_ICCID_PREFIX)) {
        return false;
      } // Check if product firmware would upgrade to an affected Device OS version


      const firmware = this.store.peekRecord('productFirmware', targetedProductFirmwareVersion);
      const targetDeviceOsVersion = firmware && firmware.get('deviceOsVersion');

      if (!targetDeviceOsVersion) {
        return false;
      } // Don't prevent application OTA that won't cause a system update


      if (_semver.default.lte(targetDeviceOsVersion, currentDeviceOsVersion)) {
        return false;
      } // Check if the target system firmware is affected


      return _semver.default.lt(targetDeviceOsVersion, '2.3.1') || _semver.default.gte(targetDeviceOsVersion, '3.0.0-alpha.1') && _semver.default.lt(targetDeviceOsVersion, '3.3.1') || _semver.default.gte(targetDeviceOsVersion, '4.0.0-alpha.1') && _semver.default.lt(targetDeviceOsVersion, '4.0.1') || _semver.default.gte(targetDeviceOsVersion, '5.0.0-alpha.1') && _semver.default.lt(targetDeviceOsVersion, '5.1.1');
    })
  });

  _exports.default = _default;
});