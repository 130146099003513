define("pconsole/templates/components/logic-product-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wa1AfR6W",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[4,\"if\",[[25,\"is-equal\",[[20,[\"details\"]],null],null]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"class\",\"undefined-attr empty-stats\"],[7],[0,\"none\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"tooltip-item\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"details\",\"showProductIcon\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[9,\"class\",\"icon im-product-icon\"],[7],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"details\",\"affectedProducts\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"tooltip\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"details\",\"affectedProducts\"]]],null,{\"statements\":[[0,\"          \"],[6,\"p\"],[7],[1,[19,1,[]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[6,\"span\"],[7],[1,[20,[\"details\",\"text\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/logic-product-names.hbs"
    }
  });

  _exports.default = _default;
});