define("pconsole/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        integrations: this.store.findAll('integration'),
        // TODO (carlos h): Move to /integrations/index.js
        integrationTypes: this.store.findAll('integrationType').then(types => {
          return types.filter(t => t.get('enabled'));
        }),
        templates: this.store.findAll('integrationTemplate')
      });
    },

    deactivate() {
      const userIntegrations = this.store.peekAll('integration');
      userIntegrations.forEach(i => {
        i.unloadRecord();
      });
    }

  });

  _exports.default = _default;
});