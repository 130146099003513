define("pconsole/templates/components/device-and-customer-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "usVN87Mt",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"device-and-customer-filter top-row-actions\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"showCustomerFilter\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"customer-filter-container\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\",\"enter\"],[\"text\",[20,[\"customerEmailTemp\"]],\"search-filter customer-filter\",\"Filter by customer email\",\"filterItems\"]]],false],[0,\"\\n    \"],[6,\"i\"],[10,\"class\",[26,[\"icon ion-close \",[25,\"if\",[[20,[\"customerEmail\"]],\"\",\"invisible\"],null]]]],[3,\"action\",[[19,0,[]],\"resetCustomers\"]],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"div\"],[9,\"class\",\"button-group\"],[7],[0,\"\\n    \"],[1,[25,\"export-to-csv\",null,[[\"modelName\",\"product\"],[[20,[\"modelName\"]],[20,[\"product\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"isPrivateBeta\"]]],null,{\"statements\":[[4,\"link-to\",[\"product.customers.import\"],[[\"class\"],[\"ui button outline primary\"]],{\"statements\":[[0,\"        \"],[6,\"i\"],[9,\"class\",\"icon ion-upload\"],[7],[8],[6,\"span\"],[7],[0,\"Import\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/device-and-customer-filter.hbs"
    }
  });

  _exports.default = _default;
});