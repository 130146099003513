define("pconsole/controllers/user/networks/index", ["exports", "pconsole/mixins/object-filter-helpers"], function (_exports, _objectFilterHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _objectFilterHelpers.default, {
    networks: Ember.computed.alias('model.networks'),
    totalNetworks: Ember.computed('networks', function () {
      return this.get('networks.length');
    }),
    queryParams: ['page', 'perPage'],
    shouldDisplayPagination: Ember.computed('networks.totalPages', function () {
      return this.get('networks.totalPages') > 1;
    }),
    actions: {
      changePerPage() {
        this.setProperties({
          page: 1
        });
        this.send('refreshModel');
      },

      // https://github.com/mharris717/ember-cli-pagination/issues/146
      pageClicked(page) {
        this.setProperties({
          page: page
        });
      }

    }
  });

  _exports.default = _default;
});