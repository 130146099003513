define("pconsole/components/role-dropdown", ["exports", "pconsole/mixins/permissions", "pconsole/helpers/is-current-user"], function (_exports, _permissions, _isCurrentUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, {
    hint: "\n    A team member's role determines their level of permissions:\n\n    View-only: Read-only access to all information in the account, but cannot take any action.\n    Support: View access plus the ability to perform basic diagnostic and troubleshooting tasks.\n    Developer: Most create, view, update, and deletion abilities, without the ability to take major administrative actions.\n    Administrator: Full administrative access, including team management and irreversible destructive actions.\n  ",
    tagName: '',
    scope: 'teams.users:update',
    rolesNoOwner: Ember.computed('roles', function () {
      const roles = this.get('roles');
      return roles.filter(role => {
        return !role.get('owner');
      });
    }),
    isDisabled: Ember.computed('disabled', 'model', 'session', function () {
      const disabled = this.get('disabled');
      const currentUser = (0, _isCurrentUser.isCurrentUser)([this.get('model'), this.get('session')]);
      const isProductOwner = this.get('model.isProductOwner');
      const isOwner = this.get('model.isOwner');
      return disabled || currentUser || isProductOwner || isOwner;
    }),
    actions: {
      onUpdate(product, user, event) {
        this.sendAction('onUpdate', product, user, event);
      }

    }
  });

  _exports.default = _default;
});