define("pconsole/models/service-agreement", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    agreementType: (0, _model.attr)('string'),
    currentBillingPeriodEnd: (0, _model.attr)('date'),
    currentBillingPeriodStart: (0, _model.attr)('date'),
    currentUsageSummary: (0, _model.attr)(),
    endsOn: (0, _model.attr)('date'),
    name: (0, _model.attr)('string'),
    nextBillingPeriodStart: (0, _model.attr)('date'),
    ownerType: (0, _model.attr)('string'),
    ownerId: (0, _model.attr)('string'),
    ownerSlug: (0, _model.attr)('string'),
    pricingTerms: (0, _model.attr)(),
    servicePlanSlug: (0, _model.attr)('string'),
    startsOn: (0, _model.attr)('date'),
    state: (0, _model.attr)('string'),
    isActive: Ember.computed.equal('state', 'active'),
    isDeveloper: Ember.computed.equal('agreementType', 'developer'),
    isGrowth: Ember.computed.equal('agreementType', 'self_service'),
    isOrganization: Ember.computed.equal('ownerType', 'organization'),
    isTiersPlan: Ember.computed.equal('servicePlanSlug', 'enterprise-tiers-2021-plan'),
    isPackagesPlan: Ember.computed.match('servicePlanSlug', /enterprise-packages-2022-plan|enterprise-plan-2023-pro/),
    isHaasPlan: Ember.computed.match('servicePlanSlug', /enterprise-plan-2024-pro|enterprise-plan-2024-pro-plus|enterprise-plan-2024-custom/),
    devicesPaused: Ember.computed.readOnly('currentUsageSummary.devices_paused'),
    maxDevices: Ember.computed.readOnly('pricingTerms.device.max_devices'),
    maxMessages: Ember.computed.readOnly('pricingTerms.messaging.max_messages'),
    ownedDevices: Ember.computed.readOnly('currentUsageSummary.connectivity.all.owned_devices'),
    organizationId: Ember.computed('isOrganization', 'ownerId', function () {
      const isOrganization = this.get('isOrganization');
      const ownerId = this.get('ownerId');
      return isOrganization && ownerId;
    }),
    notifications: Ember.computed('id', function () {
      const id = this.get('id');
      return this.store.peekAll('notification').filter(n => n.get('resourceId') === id);
    }),
    currentBillingPeriodStartLimited: Ember.computed('currentBillingPeriodStart', 'startsOn', function () {
      const currentBillingPeriodStart = Ember.get(this, 'currentBillingPeriodStart');
      const startsOn = Ember.get(this, 'startsOn');
      return currentBillingPeriodStart && startsOn && (currentBillingPeriodStart < startsOn ? startsOn : currentBillingPeriodStart);
    }),
    hasReachedDeviceLimit: Ember.computed('currentUsageSummary', function () {
      const usage = Ember.get(this, 'currentUsageSummary');
      return Boolean(usage && Ember.get(usage, 'device_limit_reached'));
    }),
    orderInUsageSummary: Ember.computed('agreementType', function () {
      switch (Ember.get(this, 'agreementType')) {
        case 'enterprise':
          return 0;

        case 'self_service':
          return 1;

        case 'developer':
          return 2;

        default:
          return 3;
      }
    }),
    remainingDevices: Ember.computed('maxDevices', 'ownedDevices', function () {
      const maxDevices = Ember.get(this, 'maxDevices');
      const ownedDevices = Ember.get(this, 'ownedDevices');

      if (!maxDevices) {
        return null;
      }

      return Math.max(0, maxDevices - ownedDevices);
    }),
    nameForPageDescription: Ember.computed('name', 'isHaasPlan', function () {
      const isHaasPlan = Ember.get(this, 'isHaasPlan');
      const name = Ember.get(this, 'name');

      if (isHaasPlan) {
        return "Current Commercial Model (".concat(name, ")");
      }

      return name;
    }),

    toObject() {
      const serviceAgreement = {};
      this.eachAttribute(a => serviceAgreement[a] = Ember.get(this, a));
      serviceAgreement.id = this.id;
      return serviceAgreement;
    }

  });

  _exports.default = _default;
});