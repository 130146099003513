define("pconsole/controllers/product/location/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    segment: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      dates: {
        type: 'array'
      }
    },
    dates: null,
    device: Ember.computed('model.lastHeard', 'model.geometry.type', function () {
      const deviceRecord = Ember.get(this, 'model');
      return deviceRecord.toObject();
    }),
    devicePageUrl: Ember.computed('device.id', function () {
      const id = Ember.get(this, 'device.id');
      return Ember.get(this, 'router').urlFor('product.device', id);
    }),
    actions: {
      export() {
        this.send('exportCSV');
      },

      search({
        dateRange
      }) {
        this.transitionToRoute({
          queryParams: {
            dates: dateRange === null || dateRange === void 0 ? void 0 : dateRange.map(d => d.toISOString())
          }
        });
      }

    }
  });

  _exports.default = _default;
});