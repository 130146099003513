define("pconsole/controllers/product/firmware", ["exports", "lodash/debounce", "pconsole/config/environment"], function (_exports, _debounce, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    firmwares: Ember.computed.alias('model.firmwares'),
    savedFirmware: Ember.computed.filterBy('firmwares', 'isNew', false),
    firmwareSorting: ['version:desc'],
    savedSortedFirmware: Ember.computed.sort('savedFirmware', 'firmwareSorting'),
    searchFunc: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'searchFunc', (0, _debounce.default)(e => {
        this.filter(e);
      }, 200));
    },

    downloadPrefix: Ember.computed('routeData.product', function () {
      const product = Ember.get(this, 'routeData.product');
      return _environment.default.APP.API_ENDPOINT + '/v1/products/' + product + '/firmware/';
    }),

    filter(value) {
      const regex = new RegExp(value, 'im');
      Ember.$('.firmware-version').each((_, element) => {
        if (regex.test(element.textContent)) {
          Ember.$(element).show();
        } else {
          Ember.$(element).hide();
        }
      });
    },

    actions: {
      resetSearch() {
        Ember.$('.firmware-version').show();
      }

    }
  });

  _exports.default = _default;
});