define("pconsole/routes/organization/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: null,

    activate() {
      Ember.set(this, 'breadCrumb', {
        title: 'Organization'
      });
    },

    deactivate() {
      Ember.set(this, 'breadCrumb', null);
    }

  });

  _exports.default = _default;
});