define("pconsole/templates/components/manual-unpaused-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6IkhxmSl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  Your \"],[1,[20,[\"alert\",\"serviceAgreement\",\"name\"]],false],[0,\" service is active\\n\"],[8],[0,\"\\n\\n\"],[6,\"p\"],[7],[0,\"\\n  The Particle team overrode the usage limits for your service \\n  until the start of the next usage period, starting\\n  \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"nextBillingPeriodStart\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n  Your service will not be paused even if you exceed the\\n  usage limits until then.\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"organizationSlug\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"organization.billing\",[20,[\"organizationSlug\"]]],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"user.billing\"],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/manual-unpaused-alert.hbs"
    }
  });

  _exports.default = _default;
});