define("pconsole/routes/organization/insights/report", ["exports", "pconsole/mixins/embed-listeners"], function (_exports, _embedListeners) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_embedListeners.EmbedListeners, {
    apiAjax: Ember.inject.service(),
    controllerRoute: 'organization.insights.report',
    iframeClass: 'iframe.particle-insights__dashboard',
    breadCrumb: {},

    async model({
      dashboard_id
    }) {
      const organization = this.modelFor('organization').organization;
      const dashboard = await this.store.find('insights-dashboard', dashboard_id);
      const {
        url
      } = await this.get('apiAjax').request({
        url: "/v1/orgs/".concat(Ember.get(organization, 'slug'), "/reports/").concat(Ember.get(dashboard, 'modeId')),
        type: 'GET'
      });
      return {
        dashboard,
        organization,
        url
      };
    },

    afterModel(model) {
      const name = Ember.get(model, 'dashboard.name');
      Ember.set(this, 'breadCrumb.title', name);
    }

  });

  _exports.default = _default;
});