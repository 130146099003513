define("pconsole/templates/product/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Vl67Ylk",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"events-page\"],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[0,\"Events\"],[8],[0,\"\\n    \"],[1,[25,\"event-logs-view-container\",null,[[\"fullProduct\"],[[20,[\"fullProduct\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/events.hbs"
    }
  });

  _exports.default = _default;
});