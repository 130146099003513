define("pconsole/components/eventlogs/stream-util", ["exports", "pconsole/config/environment", "particle-api-js", "pconsole/components/eventlogs/events-util"], function (_exports, _environment, _particleApiJs, _eventsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StreamUtil {
    constructor({
      auth,
      onEvent
    }) {
      this.particle = new _particleApiJs.default({
        baseUrl: _environment.default.APP.API_ENDPOINT
      });
      this.auth = auth;
      this.onEvent = onEvent;
      this.eventStream = null;
    }

    defaultStreamOptions() {
      return {
        deviceId: 'mine'
      };
    }

    getFilteredEvents({
      events,
      filter,
      availableOptions
    }) {
      return events && events.filter(event => _eventsUtil.default.eventPassesFiltering(event, filter, availableOptions));
    }

    getFilteredEvent({
      availableOptions,
      event,
      filter,
      paused
    }) {
      const eventPassesFiltering = _eventsUtil.default.eventPassesFiltering(event, filter, availableOptions);

      event.id = _eventsUtil.default.randomEventId(event);
      event.queued = paused;
      return eventPassesFiltering && event;
    }

    abort() {
      this.isAborted = true;

      this._closeStream();
    }

    _closeStream() {
      if (this.eventStream) {
        this.eventStream.abort();
        this.eventStream = null;
      }
    }

    stream(options) {
      const streamOptions = Object.assign({}, options, {
        auth: this.auth,
        onEvent: this.onEvent
      });

      this._closeStream();

      return this.particle.getEventStream(streamOptions).then(stream => {
        if (this.isAborted) {
          this._closeStream();

          return;
        }

        stream.on('event', this.onEvent);
        this.eventStream = stream;
      }).catch(() => {
        this.eventStream = null;
      });
    }

    streamOptionsFromQuery(query, availableOptions) {
      const options = query && query.split(' ').map(item => item.indexOf(':') === -1 ? "match:".concat(item) : item).filter(item => item.indexOf(':') !== -1).map(item => item.split(':')).filter(item => availableOptions[item[0]] && availableOptions[item[0]].usable !== false).reduce((acc, cur) => {
        const field = availableOptions[cur[0]].map;
        const streamOrFilter = availableOptions[cur[0]].stream ? 'stream' : 'filter';
        const isStreamFilter = availableOptions[cur[0]].stream;
        const value = cur[1];

        if (field === 'match' && acc[streamOrFilter][field]) {
          acc[streamOrFilter][field] = "".concat(acc[streamOrFilter][field], " ").concat(value);
        } else if (isStreamFilter) {
          acc.stream[field] = value;
          acc.filter[field] = value;
        } else {
          acc.filter[field] = value;
        }

        return acc;
      }, {
        stream: {
          deviceId: 'mine'
        },
        filter: {}
      });
      return options ? Object.assign({}, options, {
        hasStream: options.stream && Object.keys(options.stream).length > 0,
        hasFilter: options.filter && Object.keys(options.filter).length > 0
      }) : {};
    }

    streamOptionsFromProps({
      device,
      product
    }) {
      const options = {
        deviceId: device,
        product
      };

      if (!options.deviceId && !options.product) {
        return null;
      }

      return options;
    }

  }

  var _default = StreamUtil;
  _exports.default = _default;
});