define("pconsole/routes/product/location/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController(controller, isExiting) {
      this._super(...arguments);

      const mapController = this.controllerFor('product.location');

      if (isExiting) {
        Ember.set(mapController, 'highlightedMapFeature', null);
      }
    }

  });

  _exports.default = _default;
});