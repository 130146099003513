define("pconsole/templates/partials/create-or-edit-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A/x9G4IW",
    "block": "{\"symbols\":[\"color\",\"name\"],\"statements\":[[4,\"form-wrapper\",null,null,{\"statements\":[[0,\"  \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"placeholder\"],[\"group.name\",\"Name\",\"Device group name\"]]],false],[0,\"\\n  \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"type\",\"placeholder\",\"class\"],[\"group.description\",\"Description\",\"textarea\",\"Describe your device group\",\"group-description\"]]],false],[0,\"\\n  \"],[6,\"h5\"],[7],[0,\"Choose a group color\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"colors\"],[7],[0,\"\\n\"],[4,\"each\",[[25,\"-each-in\",[[20,[\"colors\"]]],null]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",[26,[\"color-choice \",[25,\"if\",[[25,\"is-equal\",[[20,[\"group\",\"color\"]],[19,1,[]]],null],\"chosen\"],null]]]],[3,\"action\",[[19,0,[]],\"chooseColor\",[19,1,[]]]],[7],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",[26,[\"color \",[19,2,[]]]]],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[8],[0,\"\\n  \"],[1,[25,\"form-submission\",null,[[\"saveText\",\"disabled\"],[[25,\"if\",[[20,[\"isEditing\"]],\"Save Group\",\"Create Group\"],null],[20,[\"isInvalid\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/create-or-edit-group.hbs"
    }
  });

  _exports.default = _default;
});