define("pconsole/helpers/plan-interval-shorthand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planIntervalShorthand = planIntervalShorthand;
  _exports.default = void 0;

  function planIntervalShorthand(interval) {
    const intervalLength = interval[0];

    if (intervalLength === 'month') {
      return 'Mo';
    } else if (intervalLength === 'year') {
      return 'Yr';
    }
  }

  var _default = Ember.Helper.helper(planIntervalShorthand);

  _exports.default = _default;
});