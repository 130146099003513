define("pconsole/components/eventlogs/query-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getAvailableOptions = () => {
    return {
      'product': {
        title: 'product',
        description: 'Filter by product',
        map: 'product',
        usage: 'product:my-product-name',
        stream: true,
        display: false,
        usable: false
      },
      'device': {
        title: 'device',
        description: 'Filter by device',
        map: 'deviceId',
        usage: 'device:34003d001647353236343012',
        stream: true
      },
      'devicename': {
        title: 'devicename',
        description: 'Filter by device name',
        map: 'deviceName',
        usage: 'devicename:fluffy-penguin'
      },
      'event': {
        title: 'event',
        description: 'Filter by event name',
        map: 'name',
        usage: 'event:temperature',
        stream: true
      },
      'range': {
        title: 'range',
        description: 'Show only events that have the data between min and max (range:min-max)',
        usage: 'range:10-100',
        map: 'range'
      },
      'data': {
        title: 'data',
        description: 'Filter by the contents of the event data',
        usage: 'data:ok',
        map: 'data'
      },
      'match': {
        title: 'match',
        description: 'Match data / event name / published_at',
        usage: 'match:contentshere',
        map: 'match',
        display: false
      },
      'group': {
        title: 'group',
        description: 'Group by a field (event / data)',
        usage: 'group:event or group:data',
        map: 'group',
        display: false,
        detailed: {
          'group:event': {
            title: 'group:event',
            description: 'All the events with the same name will be grouped'
          },
          'group:data': {
            title: 'group:data',
            description: 'All the events with the same data will be grouped'
          },
          'group:device': {
            title: 'group:device',
            description: 'Events published by the same device will be grouped'
          }
        }
      }
    };
  };

  var _default = {
    getAvailableOptions,
    getParsedAvailableOptions: ({
      device,
      product
    }) => {
      const availableOptions = getAvailableOptions();

      if (product) {
        delete availableOptions.product;
      }

      if (device) {
        delete availableOptions.product;
        delete availableOptions.device;
      }

      return availableOptions;
    }
  };
  _exports.default = _default;
});