define("pconsole/controllers/organization/logic/templates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    logicTemplate: Ember.inject.service(),
    description: Ember.computed('model.description', 'model.logicFunction', function () {
      var _EmberGet;

      const description = Ember.get(this, 'model.description');
      const code = (_EmberGet = Ember.get(this, 'model.logicFunction')) === null || _EmberGet === void 0 ? void 0 : _EmberGet.code;

      if (!code) {
        return description;
      }

      return "".concat(description, "\n```\n// Logic function code\n").concat(code, "\n```");
    }),
    actions: {
      setTemplate() {
        const template = Ember.get(this, 'model');
        Ember.get(this, 'logicTemplate').setTemplate(template);
        this.transitionToRoute(Ember.get(this, 'routeData.logicRoutes.create'), {
          queryParams: {
            template: Ember.get(template, 'slug')
          }
        });
      },

      closeModal() {
        const hasTemplate = Ember.get(this, 'logicTemplate').getTemplate();

        if (!hasTemplate) {
          // Modal closed by close button
          this.transitionToRoute(Ember.get(this, 'routeData.logicRoutes.templateList'));
        }
      }

    }
  });

  _exports.default = _default;
});