define("pconsole/controllers/product/devices", ["exports", "pconsole/mixins/object-filter-helpers", "pconsole/config/environment", "pconsole/utils/permissions"], function (_exports, _objectFilterHelpers, _environment, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _objectFilterHelpers.default, {
    store: Ember.inject.service(),
    lastHandshakeTooltipText: _environment.default.APP.LAST_HANDSHAKE_TOOLTIP_TEXT,
    notify: Ember.inject.service(),
    application: Ember.inject.controller(),
    isProduct: Ember.computed('routeData.hasProduct', function () {
      return this.get('routeData.hasProduct');
    }),
    planGroupsEnabled: Ember.computed('product.groupsEnabled', function () {
      return this.get('isProduct') ? this.get('product.groupsEnabled') : false;
    }),
    scopeGroupsEnabled: Ember.computed('product', function () {
      const scope = 'groups:create';
      const productModel = this.get('product');
      const store = this.get('store');
      return (0, _permissions.checkScope)({
        scope,
        productModel,
        store
      });
    }),
    hideDeniedDevices: true,
    deviceIdObserver: Ember.observer('deviceId', function () {
      this.set('hideDeniedDevices', !this.get('deviceId'));
    }),
    isLoadingDevices: Ember.observer('model.loading', function () {
      const isLoading = this.get('model.loading');

      if (isLoading) {
        Ember.$('.loading').show();
      } else {
        Ember.$('.loading').hide();
      }
    }),
    showDeviceTable: Ember.computed('application.currentRouteName', function () {
      const route = this.get('application.currentRouteName');
      return !route.includes('show');
    }),
    // setup our query params
    queryParams: Ember.computed('product.groupsEnabled', function () {
      const product = this.get('product');
      const qps = ['page', 'perPage', 'deviceId', 'deviceName', 'serialNumber', 'sortAttr', 'sortDir']; // Only add groups to the list of query params when groups are enabled
      // This will ensure that the device filter UI only contains a groups option
      // when it is relevant.

      if (product && product.get('groupsEnabled')) {
        qps.pushObject('groups');
      }

      return qps;
    }),
    dev_kit_only: false,
    hasFilter: Ember.computed('groups', 'deviceId', 'deviceName', 'serialNumber', function () {
      return Boolean(this.get('groups') || this.get('deviceId') || this.get('deviceName') || this.get('serialNumber'));
    }),
    deniedDevices: Ember.computed.filterBy('model', 'denied', true),
    quarantinedDevices: Ember.computed.filter('model', function (device) {
      return device.get('quarantined') && !device.get('denied');
    }),
    approvedDevices: Ember.computed.filter('model', function (device) {
      return !device.get('quarantined') && !device.get('denied');
    }),
    nonApprovedDevices: Ember.computed.filter('model', function (device) {
      return device.get('quarantined') || device.get('denied');
    }),
    shouldDisplayPerPage: Ember.computed('model', function () {
      return this.get('isProduct') && this.get('model.length') > 0;
    }),
    shouldDisplayPagination: Ember.computed('model.totalPages', function () {
      return this.get('model.totalPages') > 1;
    }),

    subscribe() {
      const eventStreamer = this.get('eventStream');
      eventStreamer.on('event', e => {
        // Only update the device record if it is currently in the data store
        // a.k.a. is visible to the current user
        const device = this.store.peekRecord('device', e.coreid); // avoid processing events until device is fully loaded

        if (!device || !device.get('isLoaded')) {
          return;
        }

        if (e.name === 'spark/status' || e.name === 'spark/device/app-hash') {
          device.reload();
        }
      });
      let options;

      if (this.get('isProduct')) {
        options = {
          product: this.get('routeData.product'),
          name: 'spark/'
        };
      } else {
        options = {
          deviceId: 'mine',
          name: 'spark/'
        };
      }

      eventStreamer.startListening(options);
    },

    resetParams() {
      this.set('page', null);
      this.set('perPage', null);
      this.set('dev_kit_only', false);
    },

    unsubscribe() {
      this.get('eventStream').stopListening();
    },

    sortParams: Ember.computed('sortAttr', 'sortDir', function () {
      const sortAttr = this.get('sortAttr');
      const sortDir = this.get('sortDir');
      return {
        attr: sortAttr,
        dir: sortDir
      };
    }),
    showGroupsDropdown: false,
    totalRecords: Ember.computed.alias('model.meta.total_records'),
    totalDevKits: Ember.computed.alias('model.meta.total_dev_kits'),
    totalProductDevices: Ember.computed('model.meta', function () {
      return (this.get('model.meta.total_records') || 0) - (this.get('model.meta.total_dev_kits') || 0);
    }),
    totalSandboxDevices: Ember.computed.alias('application.currentServiceAgreement.ownedDevices'),
    totalSandboxProductDevices: Ember.computed('totalSandboxDevices', 'totalDevKits', function () {
      const totalSandboxDevices = this.get('totalSandboxDevices');
      const totalDevKits = this.get('totalDevKits');

      if (!Ember.isEmpty(totalSandboxDevices)) {
        return totalSandboxDevices - totalDevKits;
      }
    }),
    deviceShowPageURL: Ember.computed('routeData.product', function () {
      return this.get('routeData.product') ? 'product.device' : 'user.device';
    }),
    actions: {
      hideGroupsDropdown() {
        this.set('showGroupsDropdown', false);
      },

      toggleGroupsDropdown() {
        this.toggleProperty('showGroupsDropdown');
      },

      changePerPage() {
        this.setProperties({
          page: 1
        });
        this.send('refreshModel');
      },

      toggleApprovedDevices() {
        this.toggleProperty('hideApprovedDevices');
      },

      sortInController(sortAttr, direction) {
        this.setProperties({
          sortAttr: sortAttr,
          sortDir: direction
        });
      },

      // https://github.com/mharris717/ember-cli-pagination/issues/146
      pageClicked(page) {
        this.setProperties({
          page: page
        });
      },

      cancelExpand(deviceId) {
        return this.store.findById('device', deviceId).then(device => {
          if (device.get('hasDirtyAttributes')) {
            return device.rollbackAttributes();
          }
        });
      },

      toggleDevKitOnlyDevices(value) {
        this.set('dev_kit_only', value || !this.get('dev_kit_only'));
      }

    }
  });

  _exports.default = _default;
});