define("pconsole/mixins/sim/import", ["exports", "pconsole/mixins/sim/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_base.default, {
    notify: Ember.inject.service(),

    handleSuccess() {
      Ember.$('.loading').hide();
      this.transitionToRoute('product.sims.index');
      this.get('notify').success({
        html: '<span class="message">Your request to import SIM cards to this product has been received successfully!<br/><br/>This can take a while, so SIM importing and activation will happen in the background. As SIM cards are imported, they will appear on this page upon refreshing.<br/></span>',
        closeAfter: 8000
      });
    },

    handleError(msg) {
      Ember.$('.loading').hide();
      this.get('notify').error(msg, {
        closeAfter: 8000
      });
    },

    importAndActivate({
      country,
      simFile,
      simsToImport,
      product
    }) {
      Ember.$('.loading').show();

      if (simFile) {
        simFile.set('country', country);
        simFile.save().then(() => {
          this.handleSuccess();
        }, response => {
          const error = response.errors[0];
          const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
          this.handleError(errorMsg);
        });
      } else {
        return this.get('apiAjax').request({
          url: "/v1/products/".concat(product, "/sims"),
          type: 'POST',
          data: JSON.stringify({
            sims: simsToImport,
            country: country,
            import_sims: true
          }),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json'
        }).then(response => {
          this.handleSuccess();
          return response;
        }, response => {
          this.handleError(response.responseJSON.error);
          return Ember.RSVP.reject(response.responseJSON.error);
        });
      }
    }

  });

  _exports.default = _default;
});