define("pconsole/components/usage-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    renderOverlay: Ember.computed('isLoading', function () {
      return this.get('isLoading');
    })
  });

  _exports.default = _default;
});