define("pconsole/controllers/product/clients/create", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "pconsole/mixins/client-scope-options"], function (_exports, _form, _clientScopeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, _clientScopeOptions.default, {
    notify: Ember.inject.service(),
    isProduct: Ember.computed('routeData.hasProduct', function () {
      return this.get('routeData.hasProduct');
    }),
    _scopeOptions: Ember.computed('scopeOptions', 'isProduct', function () {
      return this.get('isProduct') ? this.get('scopeOptions') : this.get('scopeOptions').filter(item => item.value !== 'customers:create');
    }),
    validations: {
      'model.name': {
        presence: true
      },
      'model.type': {
        presence: true
      },
      'model.redirectURI': {
        format: {
          // Match URLs like http://localhost, https://example.cool
          with: /^(?:http:\/\/localhost[^#]*|https:\/\/[^#]+)$/,
          message: 'must be a valid URL',
          'if': function _if(object) {
            return object.get('model.isWeb');
          }
        }
      },
      'model.scopes': {
        presence: true
      }
    },
    hasCopied: null,

    save() {
      const client = this.get('model');
      Ember.$('.loading').show();
      client.save().then(() => {
        Ember.$('.loading').hide();
        this.set('clientCreated', true);
      }, () => {
        Ember.$('.loading').hide();
        this.get('notify').alert('There was a problem registering your app. Please try again later.');
      });
    },

    rerunValidations: Ember.observer('model.type', function () {
      //The only way I can find to re-run conditional validations
      //See https://github.com/DockYard/ember-validations/issues/138
      this.validate().catch(() => {});
    }),
    appTypeObserver: Ember.observer('appType', function () {
      const appType = this.get('appType');
      this.set('customAppType', false);

      switch (appType) {
        case 'simple-web':
          this.set('model.type', 'web');
          this.set('model.scopes', ['customers:create']);
          break;

        case 'simple-mobile':
          this.set('model.type', 'installed');
          this.set('model.scopes', ['customers:create']);
          break;

        case 'two-legged':
          this.set('model.type', 'installed');
          this.set('model.scopes', ['*:*']);
          break;

        case 'custom':
          this.set('model.type', undefined);
          this.set('model.scopes', undefined);
          this.set('customAppType', true);
          break;
      }

      Ember.run.later(this, function () {
        Ember.$('input[name="model.name"]').focus();
      }, 0);
    }),
    actions: {
      closeModal() {
        this.set('clientCreated', false);
        this.transitionToRoute(this.get('isProduct') ? 'product.clients' : 'user.clients');
      },

      afterCopy() {
        this.set('hasCopied', true);
        this.get('notify').info('Client secret copied to clipboard!');
      },

      updateSelection: function updateSelection(selectedScopes, value, operation) {
        if (value === '*:*' && operation === 'added' || selectedScopes.length === 0) {
          this.set('model.scopes', ['*:*']);
        } else if (selectedScopes.length === 1 && value !== '*:*') {
          this.set('model.scopes', operation === 'added' ? [value] : selectedScopes);
        } else {
          this.set('model.scopes', selectedScopes.removeObject('*:*'));
        }
      }
    }
  });

  _exports.default = _default;
});