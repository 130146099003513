define("pconsole/mixins/routes/logic-functions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LogicFunctionsEditRoute = void 0;
  const LogicFunctionsEditRoute = {
    templateName: 'organization.logic-functions.create',
    controllerName: 'organization.logic-functions.create',
    session: Ember.inject.service(),
    breadCrumb: {
      title: 'Edit Logic Function',
      linkable: false
    },

    async model({
      logic_function_id: logicFunctionId
    }) {
      const owner = this.get('owner');
      const templates = await this.store.findAll('logic-template');
      let products = [];
      let product;
      let organization;

      if (owner === 'organization') {
        organization = this.modelFor('organization').organization;
        products = this.modelFor('organization').products;
      } else if (owner === 'product') {
        var _this$modelFor, _product;

        product = (_this$modelFor = this.modelFor('product')) === null || _this$modelFor === void 0 ? void 0 : _this$modelFor.product;
        const organization = (_product = product) === null || _product === void 0 ? void 0 : _product.get('organization');
        products = organization ? await this.store.query('product', {
          organization: organization.get('id')
        }) : products = await this.store.findAll('product');
      } else {
        const username = Ember.get(this, 'session.data.authenticated.username');
        products = await this.store.findAll('product');
        products = products.filterBy('user', username);
      }

      const logicFunction = await this.store.findRecord('logic-function', logicFunctionId, {
        reload: true
      });
      return {
        logicFunction,
        products,
        templates,
        product,
        organization
      };
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('isEditing', true);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        const logicFunction = Ember.get(controller, 'logicFunction');
        controller.setProperties({
          activeTab: null,
          executeRun: null,
          eventTestData: null,
          successExecute: null,
          isEditing: false,
          isExecuting: false
        }); // Revert attrs that weren't saved if the logic function wasn't deleted

        if (logicFunction) {
          logicFunction.rollbackAttributes();
        }
      }
    }

  };
  _exports.LogicFunctionsEditRoute = LogicFunctionsEditRoute;
});