define("pconsole/components/form-json-editor", ["exports", "ember-ace"], function (_exports, _emberAce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Avoid failed load of webworker
  _emberAce.default.config.set('loadWorkerFromBlob', false);

  var _default = Ember.Component.extend({
    className: 'form-json-editor',
    classNameBindings: ['className:form-json-editor'],
    currentView: 'basic',
    isCustomView: Ember.computed.equal('currentView', 'custom'),
    json: {},

    /**
     * Owner of form-json-editor can set this to true when the json data changes
     * from another source other than manual edits and trigger the form to regenerate
     */
    externalUpdate: false,

    /* ---------- Header props ---------- */

    /**
     * Text to display on the header for the 'form' based editor
     * @type {string}
     */
    basicTitle: 'Basic editor',

    /**
     * Text to display on the header for the custom JSON based editor
     * @type {string}
     */
    customTitle: 'Advanced editor',

    /**
     * Which view should be rendered by default
     * Should either be 'basic' or 'custom'
     * @type {string}
     */
    defaultView: 'basic',

    /**
     * If set to true, always start with the value of defaultView ignoring the possible
     * hasComplexFields validation
     * @type {boolean}
     */
    startOnDefault: false,

    /**
     * When set to true, the basic view will get disabled if the json value has a key with
     * complex (array, object) values.
     * When set to false, both views are always enabled.
     * @type {boolean}
     */
    blockOnComplex: false,

    /**
     * Custom class to be added to the header wrapper
     * @type {string}
     */
    headerClass: '',

    /* ---------------------------------- */

    /* ------- JSON Editor props -------- */

    /**
     * Property to set 'mode' to in the json-editor
     * @type {string}
     */
    jsonEditorMode: 'code',

    /**
     * Property to set 'name' to in the json-editor
     * @type {string}
     */
    jsonEditorName: '',

    /**
     * Method to call when the json-editor detects an error on the input
     * @type {function}
     */
    onCustomError() {},

    /**
     * Method to call when the json-editor detects a change on the input
     * @type {function}
     */
    onCustomChange() {},

    /* ---------------------------------- */
    init() {
      this._super(...arguments);

      let view = Ember.get(this, 'defaultView');
      const checkForComplex = !Ember.get(this, 'startOnDefault');

      if (checkForComplex && Ember.get(this, 'hasComplexFields')) {
        view = 'custom';
      }

      Ember.set(this, 'currentView', view);
    },

    hasExternalUpdate: Ember.observer('externalUpdate', async function () {
      const view = Ember.get(this, 'currentView');
      const hasUpdated = Ember.get(this, 'externalUpdate');

      if (hasUpdated && view === 'basic') {
        await Ember.set(this, 'currentView', 'custom');
        Ember.set(this, 'externalUpdate', 'false');
        await Ember.set(this, 'currentView', 'basic');
      }
    }),
    hasComplexFields: Ember.computed('json', function () {
      const currentJSON = Ember.get(this, 'json');

      if (!currentJSON) {
        return false;
      }

      const entries = Object.entries(currentJSON);
      return entries.any(([, value]) => {
        const valueType = typeof value;
        return valueType !== 'string' && valueType !== 'number' && valueType !== 'boolean';
      });
    }),
    disableBasicView: Ember.computed('blockOnComplex', 'hasComplexFields', function () {
      return Ember.get(this, 'blockOnComplex') && Ember.get(this, 'hasComplexFields');
    }),
    actions: {
      showCustomView() {
        Ember.set(this, 'currentView', 'custom');
        this.sendAction('showCustomView');
      },

      showBasicView() {
        Ember.set(this, 'currentView', 'basic');
        this.sendAction('showBasicView');
      }

    }
  });

  _exports.default = _default;
});