define("pconsole/controllers/organization/ledger/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    application: Ember.inject.controller(),
    isDeleting: false,
    didDelete: false,

    close() {
      const parentRoute = Ember.get(this, 'application.currentRouteName');

      if (!Ember.get(this, 'isDeleting')) {
        let target = parentRoute.replace(/.archive/, '');

        if (Ember.get(this, 'didDelete')) {
          target = Ember.get(this, 'routeData.ledgerRoutes').list;
        }

        this.transitionToRoute(target);
      }
    },

    actions: {
      async archiveLedger() {
        Ember.set(this, 'isDeleting', true);

        try {
          await this.model.destroyRecord();
          Ember.set(this, 'didDelete', true);
          Ember.get(this, 'notify').success('Ledger archived successfully');
          Ember.set(this, 'isDeleting', false);
          this.close();
        } catch (_unused) {
          Ember.get(this, 'notify').error('Sorry, there was a problem archiving your ledger');
          Ember.set(this, 'isDeleting', false);
        }
      },

      closeModal() {
        this.close();
      }

    }
  });

  _exports.default = _default;
});