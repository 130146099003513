define("pconsole/controllers/user/products/new", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "lodash/trim", "pconsole/utils/platforms"], function (_exports, _form, _trim, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _form.default, {
    validations: {
      'product.name': {
        presence: true
      },
      'platformId': {
        presence: true
      }
    },
    application: Ember.inject.controller(),
    notify: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    platforms: Ember.computed.alias('model.platforms'),
    product: Ember.computed.alias('model.product'),
    hiddenPlatforms: Ember.computed(function () {
      const launchDarkly = this.get('launchDarkly');

      try {
        // https://app.launchdarkly.com/default/production/features/unreleased-platforms-in-console/targeting
        const flag = launchDarkly.variation('unreleased-platforms-in-console', '');
        return flag.split(',').map(p => (0, _trim.default)(p));
      } catch (_ex) {
        // in case of error, default to show all platforms
        return [];
      }
    }),
    validPlatforms: Ember.computed('platforms', function () {
      const hiddenPlatforms = this.get('hiddenPlatforms');
      return this.get('platforms').filter(value => {
        return value.get('productEligible') && !hiddenPlatforms.includes(value.get('name'));
      }).map(value => {
        const id = parseInt(value && value.get('id'), 10);
        let name = '' || value && value.get('name');
        const connectivityName = (0, _platforms.connectivityTypeNameForPlatform)(id);

        if (connectivityName) {
          name = "".concat(name, " (").concat(connectivityName, ")");
        }

        value.set('nameWithConnectivity', name);
        return value;
      }).sortBy('orderInProductDeviceTypeList');
    }),
    canCreateProduct: Ember.computed('isValid', 'user.tos.accepted', function () {
      return this.get('isValid') && this.get('user.tos.accepted');
    }),
    disableForm: Ember.computed.not('canCreateProduct'),
    setPlatform: Ember.observer('platformId', function () {
      const platformId = this.get('platformId');
      const validPlatforms = this.get('validPlatforms');
      const product = this.get('product');

      if (platformId) {
        product.set('platform', validPlatforms.findBy('id', platformId));
      }
    }),
    tosAccepted: Ember.computed('', function () {
      return this.get('user.tos.accepted');
    }),

    async createProduct() {
      const user = this.get('user');
      const product = this.get('product');
      Ember.$('.loading').show();

      try {
        if (user.get('tos.hasDirtyAttributes')) {
          await user.save();
        }

        await product.save(); // The product's subscription isn't available at creation; reload the record to get it.

        await product.reload();
        await user.reload(); // Load scopes for new product

        await Ember.run(() => Ember.get(this, 'store').findRecord('access-token', 'current', {
          reload: true
        }));
        Ember.set(this, 'tosAccepted', true);
        this.transitionToRoute('product.devices.welcome', Ember.get(product, 'slug'));
      } catch (ex) {
        const error = ex.errors[0];
        const errorMsg = error ? error.detail : "We couldn't create your product. Try again later.";
        Ember.get(this, 'notify').alert(errorMsg);
      } finally {
        Ember.$('.loading').hide();
      }
    },

    actions: {
      closeModal() {
        this.set('platformId', null);

        if (this.get('product.isNew')) {
          this.transitionToRoute('user.products.index');
        }
      },

      save() {
        this.createProduct();
      }

    }
  });

  _exports.default = _default;
});