define("pconsole/routes/product/firmware/edit", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    viewName: 'product/firmware/edit',

    model(params) {
      const firmwareId = params.firmware_id;
      return this.store.find('product-firmware', firmwareId);
    }

  });

  _exports.default = _default;
});