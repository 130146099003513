define("pconsole/templates/components/enterprise-device-threshold-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Cgmm3PN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  You have used \"],[1,[20,[\"alert\",\"usagePercent\"]],false],[0,\" of your metered device count\\n\"],[8],[0,\"\\n\\n\"],[6,\"p\"],[7],[0,\"\\n  You have reached \"],[1,[20,[\"alert\",\"usagePercent\"]],false],[0,\" of your metered device count limit\"],[4,\"if\",[[20,[\"organizationName\"]]],null,{\"statements\":[[0,\" for \"],[6,\"b\"],[7],[1,[18,\"organizationName\"],false],[8]],\"parameters\":[]},null],[0,\".\\n  If your metered device count is still exceeding your contracted limits by End of Month,\\n  a migration to a higher Tier or purchase of additional Blocks will be required.\\n\"],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"\\n  Please contact your Account Manager immediately for more details.\\n\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"organization.billing\",[20,[\"organizationSlug\"]]],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/enterprise-device-threshold-alert.hbs"
    }
  });

  _exports.default = _default;
});