define("pconsole/templates/components/per-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XNSmhtM3",
    "block": "{\"symbols\":[\"numRecords\"],\"statements\":[[6,\"div\"],[9,\"class\",\"per-page input-wrapper\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"select small\"],[7],[0,\"\\n    \"],[6,\"label\"],[7],[0,\"per page\"],[8],[0,\"\\n    \"],[6,\"select\"],[9,\"class\",\"selected\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"changePerPage\"],[[\"value\"],[\"target.value\"]]],null],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"pageOptions\"]]],null,{\"statements\":[[0,\"        \"],[6,\"option\"],[10,\"value\",[19,1,[]],null],[10,\"selected\",[25,\"eq\",[[20,[\"perPage\"]],[19,1,[]]],null],null],[7],[1,[19,1,[]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/per-page.hbs"
    }
  });

  _exports.default = _default;
});