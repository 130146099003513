define("pconsole/components/delete-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    deleted: false,
    actions: {
      closeModal() {
        if (!Ember.get(this, 'deleted')) {
          const parentEntity = Ember.get(this, 'parentEntity');
          Ember.get(this, 'router').transitionTo("".concat(parentEntity, ".integrations.show"));
        }
      },

      async deleteWebhook() {
        const webhook = Ember.get(this, 'integration');
        const parentEntity = Ember.get(this, 'parentEntity');
        Ember.$('.loading').show();

        try {
          await webhook.destroyRecord();
          Ember.$('.loading').hide();
          Ember.set(this, 'deleted', true);
          Ember.get(this, 'router').transitionTo("".concat(parentEntity, ".integrations.index"));
          Ember.get(this, 'notify').success('Your integration was successfully deleted.');
        } catch (err) {
          var _error$detail;

          Ember.$('.loading').hide();
          const error = err.errors[0];
          const errorMsg = (error === null || error === void 0 ? void 0 : (_error$detail = error.detail) === null || _error$detail === void 0 ? void 0 : _error$detail.message) || "Your integration couldn't be deleted. Please try again later.";
          Ember.get(this, 'notify').alert(errorMsg);
        }
      }

    }
  });

  _exports.default = _default;
});