define("pconsole/templates/components/device-ping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "reNmM564",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"device-ping\"],[9,\"title\",\"See if this device is online and connected\"],[7],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"loading\"]]],null,{\"statements\":[[0,\"      \"],[6,\"a\"],[9,\"class\",\"ui button outline primary disabled edit-btn edit-btn--loading\"],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"ion-loading-c\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"a\"],[10,\"class\",[26,[\"ui button outline primary edit-btn \",[25,\"if\",[[20,[\"disabled\"]],\"disabled-btn u-disabled\",\"\"],null]]]],[3,\"action\",[[19,0,[]],\"pingDevice\"]],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"im-up-and-down-arrows\"],[7],[8],[0,\"\\n        Ping\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/device-ping.hbs"
    }
  });

  _exports.default = _default;
});