define("pconsole/components/event-logs-view", ["exports", "react", "react-dom", "prop-types", "pconsole/components/eventlogs/input.react", "pconsole/components/eventlogs/clear-button.react", "pconsole/components/eventlogs/pause-button.react", "pconsole/components/eventlogs/advanced-button.react", "pconsole/components/eventlogs/suggestions.react", "pconsole/components/eventlogs/events.react", "pconsole/components/eventlogs/event-data.react", "pconsole/components/eventlogs/events-util", "pconsole/components/eventlogs/stream-util", "pconsole/components/eventlogs/query-util", "pconsole/components/eventlogs/suggestions-util"], function (_exports, _react, _reactDom, _propTypes, _input, _clearButton, _pauseButton, _advancedButton, _suggestions, _events, _eventData, _eventsUtil, _streamUtil, _queryUtil, _suggestionsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_NUMBER_OF_EVENTS = 50000;
  const SYSTEM_DEVICE_NAMES = ['api', 'particle-internal'];

  class EventLogsView extends _react.default.Component {
    constructor(props) {
      super(props);
      this.eventsQueue = [];
      this.deviceNames = {};
      this.mounted = false;
      this.state = {
        events: [],
        displayedEvents: [],
        stream: null,
        filter: null,
        selectedEvent: null,
        paused: false,
        prettifyJSON: false,
        suggestionsVisible: false,
        inputValue: '',
        isFiltering: false
      };
      this.debouncedQuery = _.debounce(this.query, 800);
      this.flushQueuedEventsToState = _.throttle(this.flushQueuedEventsToState, 1000);
    }

    componentDidMount() {
      const availableOptions = _queryUtil.default.getParsedAvailableOptions(this.props);

      this.streamUtil = new _streamUtil.default({
        auth: this.props.auth,
        onEvent: this.onEvent.bind(this, availableOptions)
      });
      const streamOptions = this.streamUtil.streamOptionsFromProps(this.props) || this.streamUtil.defaultStreamOptions();
      this.availableOptions = availableOptions;
      this.mounted = true;
      this.streamUtil.stream(streamOptions, availableOptions);
      document.addEventListener('keydown', this.onKeyDown.bind(this));
    }

    componentWillUnmount() {
      this.mounted = false;
      this.streamUtil.abort();
      document.removeEventListener('keydown', this.onKeyDown.bind(this));
    }

    onInputKeyDown(event) {
      const keyCode = event.key;
      const isEnter = keyCode === 'Enter';

      if (isEnter) {
        this.forceQuery();
      }
    }

    onKeyDown(event) {
      const keyCode = event.keyCode;
      const selectedIndex = this.state.selectedEvent && this.state.selectedEvent.index;
      const isUpArrow = selectedIndex !== null && keyCode === 38;
      const isDownArrow = selectedIndex !== null && keyCode === 40;
      let selected = null;

      if (isUpArrow) {
        selected = this.state.displayedEvents[selectedIndex + 1];
        event.preventDefault();
      } else if (isDownArrow) {
        selected = this.state.displayedEvents[selectedIndex - 1];
        event.preventDefault();
      }

      if (selected) {
        this.setState({
          selectedEvent: selected
        });
      }
    }

    useSuggestion(suggestion, query = '') {
      this.setState({
        inputValue: _suggestionsUtil.default.getQueryWithSuggestion(query, suggestion)
      });
    }

    onInputChange(event) {
      event.persist();
      this.setState({
        inputValue: event.target.value,
        isFiltering: true
      }, () => {
        this.debouncedQuery(this.availableOptions, event.target.value);
      });
    }

    forceQuery() {
      this.debouncedQuery.cancel();
      this.query(this.availableOptions, this.state.inputValue);
    }

    onBlur() {
      this.forceQuery();
    } //TODO: Move some of the setStates to saveValueInState.bind(field)


    toggleSuggestions() {
      if (this.state.suggestionsVisible) {
        this.hideSuggestions();
      } else {
        this.showSuggestions();
      }
    }

    showSuggestions() {
      this.props.hidePublishEvent();
      this.setState({
        suggestionsVisible: true
      });
    }

    hideSuggestions() {
      this.setState({
        suggestionsVisible: false
      });
    }

    showPrettifiedJSON() {
      this.setState({
        prettifyJSON: true
      });
    }

    showRawJSON() {
      this.setState({
        prettifyJSON: false
      });
    }

    selectEvent(selectedEvent) {
      const alreadySelected = this.state.selectedEvent;
      let selected = selectedEvent;

      if (alreadySelected && alreadySelected.id === selectedEvent.id) {
        selected = null;
      }

      this.setState({
        selectedEvent: selected,
        prettifyJSON: true
      });
    }

    togglePause(events, paused) {
      this.setState({
        paused: !paused,
        events: paused ? _eventsUtil.default.removeEventsFromQueue(events) : events
      }, () => {
        this.filterEvents();
      });
    }

    unpause(events) {
      this.setState({
        paused: false,
        events: _eventsUtil.default.removeEventsFromQueue(events)
      }, () => {
        this.filterEvents();
      });
    }

    clearEvents() {
      this.setState({
        events: [],
        selectedEvent: null
      }, () => {
        this.eventsQueue = [];
        this.filterEvents();
      });
    }

    flushQueuedEventsToState() {
      if (this.mounted && this.eventsQueue.length > 0) {
        if (this.state.paused === false) {
          this.eventsQueue = this.eventsQueue.map(event => {
            event.queued = false;
            return event;
          });
        }

        this.setState({
          events: this.state.events.concat(this.eventsQueue).slice(MAX_NUMBER_OF_EVENTS * -1)
        }, () => {
          this.eventsQueue = [];
          this.filterEvents();
        });
      }
    }

    addEventToQueue(event, availableOptions) {
      const parsedEvent = this.streamUtil.getFilteredEvent({
        event,
        availableOptions,
        paused: this.state.paused,
        filter: this.state.filter,
        events: this.state.events
      });

      if (parsedEvent) {
        this.eventsQueue.push(Object.assign({}, parsedEvent, {
          index: this.state.events.length + this.eventsQueue.length
        }));
        this.flushQueuedEventsToState();
      }
    }

    onEvent(availableOptions, event) {
      if (SYSTEM_DEVICE_NAMES.includes(event.coreid)) {
        this.addEventToQueue(event, availableOptions);
      } else {
        if (!this.deviceNames[event.coreid]) {
          this.deviceNames[event.coreid] = _eventsUtil.default.fetchDeviceName(event.coreid, this.props.product, this.props.auth);
        }

        this.deviceNames[event.coreid].then(name => {
          event.deviceName = name;
          this.addEventToQueue(event, availableOptions);
        });
      }
    }

    query(availableOptions, query) {
      const optionsFromQuery = this.streamUtil.streamOptionsFromQuery(query, availableOptions);
      const options = optionsFromQuery.hasStream ? optionsFromQuery.stream : this.streamUtil.defaultStreamOptions();
      const streamOptionsFromProps = this.streamUtil.streamOptionsFromProps(this.props);
      const streamOptions = streamOptionsFromProps ? Object.assign({}, options, streamOptionsFromProps) : options;
      const filter = optionsFromQuery.hasFilter && optionsFromQuery.filter;
      this.streamUtil.stream(streamOptions);
      this.setState({
        filter,
        isFiltering: false
      }, () => {
        this.filterEvents();
      });
    }

    renderContent({
      selectedEvent,
      paused,
      events,
      availableOptions,
      suggestionsVisible,
      prettifyJSON
    }) {
      const inputProps = {
        onInputChange: this.onInputChange.bind(this),
        inputValue: this.state.inputValue,
        onBlur: this.onBlur.bind(this),
        onKeyPress: this.onInputKeyDown.bind(this),
        isFiltering: this.state.isFiltering
      };
      const suggestionsProps = {
        query: this.state.inputValue,
        availableOptions: availableOptions,
        useSuggestion: () => {}
      };

      const suggestions = suggestionsVisible && _react.default.createElement(_suggestions.default, suggestionsProps);

      const eventsProps = {
        events: events,
        selectedEvent: selectedEvent,
        onClick: this.selectEvent.bind(this),
        fields: _eventsUtil.default.getFields({
          device: this.props.device
        }),
        unpause: this.unpause.bind(this, events)
      };
      return _react.default.createElement('div', {
        className: '_eventlogsnew__content'
      }, _react.default.createElement('div', {
        className: '_eventlogsnew__content__left'
      }, _react.default.createElement('div', {
        className: '_eventlogsnew__content__input'
      }, _react.default.createElement(_pauseButton.default, {
        paused: this.state.paused,
        onClick: this.togglePause.bind(this, events, paused)
      }), this.props.children, _react.default.createElement(_clearButton.default, {
        onClick: this.clearEvents.bind(this)
      }), _react.default.createElement(_input.default, inputProps), _react.default.createElement(_advancedButton.default, {
        onClick: this.toggleSuggestions.bind(this),
        suggestionsVisible: suggestionsVisible
      })), suggestions, _react.default.createElement(_events.default, eventsProps)), _react.default.createElement(_eventData.default, {
        events: events,
        selectedEvent: selectedEvent,
        prettifyJSON: prettifyJSON,
        showPrettifiedJSON: this.showPrettifiedJSON.bind(this),
        showRawJSON: this.showRawJSON.bind(this),
        successNotify: this.props.successNotify.bind(this)
      }));
    }

    filterEvents() {
      const displayedEvents = (this.streamUtil && this.streamUtil.getFilteredEvents({
        events: this.state.events,
        filter: this.state.filter,
        availableOptions: this.availableOptions
      }) || []).map((event, index) => {
        event.index = index;
        return event;
      });
      this.setState({
        displayedEvents
      });
    }

    render() {
      const params = Object.assign({}, this.state, {
        availableOptions: this.availableOptions,
        events: this.state.displayedEvents
      });
      return _react.default.createElement('div', {
        className: '_eventlogsnew'
      }, this.renderContent(params));
    }

  }

  ;
  var _default = EventLogsView;
  _exports.default = _default;
});