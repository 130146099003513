define("pconsole/templates/components/integration-checklist-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gi6uvxM+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"span\"],[9,\"class\",\"step-title\"],[3,\"action\",[[19,0,[]],\"toggleExpandedContent\"]],[7],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-b\"],[7],[8],[1,[18,\"title\"],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"expanded-section\"],[7],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/integration-checklist-step.hbs"
    }
  });

  _exports.default = _default;
});