define("pconsole/routes/product/clients/create", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    model() {
      const organization = this.modelFor('product') && this.modelFor('product').product.get('organization');
      const user = this.modelFor('user').user;
      const client = Object.assign({}, organization && {
        organization
      }, user && {
        user
      });
      return this.store.createRecord('client', client);
    }

  });

  _exports.default = _default;
});