define("pconsole/components/billing-usage", ["exports", "react", "styled-components", "pconsole/components/console-theme", "@particle/react-particle-billing-usage"], function (_exports, _react, _styledComponents, _consoleTheme, _reactParticleBillingUsage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = BillingUsage;
  const StyledBillingUsage = (0, _styledComponents.default)(_reactParticleBillingUsage.default).withConfig({
    displayName: "billing-usage__StyledBillingUsage",
    componentId: "jk65fy-0"
  })(["background-color:transparent;color:var(--primary-text-color);section:first-child{background-color:var(--tertiary-background-color);svg path{fill:var(--primary-text-color);}}header{h2,h3{color:var(--primary-text-color);}}dt{color:var(--tertiary-text-color);}dd{color:var(--primary-text-color);div{color:var(--tertiary-text-color);}}a{text-decoration:underline;color:var(--secondary-text-color);}"]);

  function BillingUsage(props) {
    return _react.default.createElement(_styledComponents.ThemeProvider, {
      theme: _consoleTheme.default
    }, _react.default.createElement(StyledBillingUsage, props));
  }
});