define("pconsole/adapters/application", ["exports", "active-model-adapter", "ember-data", "pconsole/config/environment", "@sentry/browser"], function (_exports, _activeModelAdapter, _emberData, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.APP.API_ENDPOINT,
    namespace: 'v1',
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      const headers = {
        'X-API-Version': _environment.default.APP.API_VERSION
      };

      if (this.get('session.isAuthenticated')) {
        headers.Authorization = "Bearer ".concat(Ember.get(this, 'session.data.authenticated.access_token'));
      }

      return headers;
    }),

    handleResponse(status, headers, payload, requestData) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } // Normalize error response


      const error = this.normalizeErrorResponse(...arguments);
      const errors = [error]; // Add request info to Sentry context

      Sentry.addBreadcrumb({
        type: 'error',
        level: 'error',
        category: 'error.adapter',
        message: error.detail,
        data: {
          headers,
          status,
          data: requestData,
          response: payload
        }
      }); // Handle 401s

      if (status === 401 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      } // Handle 422s


      if (this.isInvalid(status, headers, payload)) {
        return new _emberData.default.InvalidError(errors);
      } // Other types


      switch (status) {
        case 403:
          return new _emberData.default.ForbiddenError(errors, error.detail);

        case 404:
          return new _emberData.default.NotFoundError(errors, error.detail);

        case 409:
          return new _emberData.default.ConflictError(errors, error.detail);

        default:
          if (status >= 500) {
            return new _emberData.default.ServerError(errors, error.detail);
          }

      }

      return new _emberData.default.AdapterError(errors, error.detail);
    },

    /**
     * Normalize to JSON-API error format
     * @see https://jsonapi.org/format/#error-objects
     * @param {Number} status Status code
     * @param {Object} headers Headers
     * @param {Object} payload Payload
     * @returns {Object} Error object
     */
    normalizeErrorResponse(status, headers, payload) {
      let {
        error,
        error_description
      } = payload;

      if (error === 'invalid_scope') {
        error_description = 'Your role does not allow you to perform this action';
      }

      return {
        code: error,
        detail: error_description || error,
        status
      };
    },

    shouldReloadAll() {
      return true;
    }

  });

  _exports.default = _default;
});