define("pconsole/controllers/product/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isDeleteEnabled: false,
    isOwner: false,
    product: Ember.computed.readOnly('model.product'),
    actions: {
      confirmDeleteProduct() {
        this.transitionToRoute('product.settings.delete');
      }

    }
  });

  _exports.default = _default;
});