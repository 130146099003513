define("pconsole/templates/components/unhandled-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Fwb7nFD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h1\"],[9,\"class\",\"ui center aligned header\"],[7],[0,\"\\n  Something went wrong\\n  \"],[6,\"div\"],[9,\"class\",\"sub header\"],[7],[1,[18,\"error\"],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ui center aligned segment margin-top-double\"],[7],[0,\"\\n  \"],[6,\"a\"],[9,\"class\",\"ui primary button\"],[9,\"href\",\"#\"],[9,\"onclick\",\"javascript:history.back()\"],[7],[0,\"Go back\"],[8],[0,\"\\n  \"],[4,\"link-to\",[\"user.devices\"],[[\"class\"],[\"ui primary button\"]],{\"statements\":[[0,\"Head home\"]],\"parameters\":[]},null],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/unhandled-error.hbs"
    }
  });

  _exports.default = _default;
});