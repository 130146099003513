define("pconsole/templates/form-inputs/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W644UVWr",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"file-upload\",null,[[\"accept\",\"value\",\"action\"],[[20,[\"accept\"]],[20,[\"value\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"value\"]]],null]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/form-inputs/file-upload.hbs"
    }
  });

  _exports.default = _default;
});