define("pconsole/controllers/user/integrations/google-maps/create", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "ember-validations", "pconsole/mixins/integration-devices", "pconsole/mixins/save-integration", "pconsole/mixins/org-integration-products"], function (_exports, _form, _emberValidations, _integrationDevices, _saveIntegration, _orgIntegrationProducts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line max-len
  var _default = Ember.Controller.extend(_integrationDevices.default, _form.default, _saveIntegration.default, _orgIntegrationProducts.orgIntegrationProducts, Ember.Evented, {
    notify: Ember.inject.service(),
    // Required for save integration mixin
    integrationType: 'googleMaps',
    displayInstructions: true,
    showAdvanced: false,
    validations: {
      'integration.event': {
        presence: true,
        length: {
          maximum: 63
        }
      },
      'integration.apiKey': {
        presence: true,
        length: 39
      },
      'integration.productIds': (0, _emberValidations.validator)(function () {
        var _this$model$get;

        const organization = (_this$model$get = this.model.get('integration.organization')) === null || _this$model$get === void 0 ? void 0 : _this$model$get.content;
        const products = this.model.get('integration.productIds') || []; // Only validate products if the integration is for orgs

        if (!!organization && products.length < 1) {
          return 'must select at least one';
        }
      })
    },
    actions: {
      toggleAdvanced() {
        this.toggleProperty('showAdvanced');
      },

      displayForm() {
        this.set('displayInstructions', false);
      },

      displayInstructions() {
        this.set('displayInstructions', true);
      }

    }
  });

  _exports.default = _default;
});