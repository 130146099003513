define("pconsole/controllers/organization/ledger/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    categories: Ember.computed('model.categories', function () {
      const categories = Ember.get(this, 'model.categories');
      return categories.map(cat => cat.toJSON());
    }),
    selectedCategory: null,
    actions: {
      showCategory(category) {
        Ember.set(this, 'selectedCategory', category);
      },

      closeModal() {
        Ember.set(this, 'selectedCategory', null);
      },

      selectCategory() {
        this.transitionToRoute(Ember.get(this, 'routeData.ledgerRoutes.create'), {
          queryParams: {
            category: Ember.get(this, 'selectedCategory.slug')
          }
        });
      }

    }
  });

  _exports.default = _default;
});