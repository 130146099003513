define("pconsole/helpers/print-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.printJSON = printJSON;
  _exports.default = void 0;

  function printJSON([jsonObj]) {
    return JSON.stringify(jsonObj, undefined, 2);
  }

  var _default = Ember.Helper.helper(printJSON);

  _exports.default = _default;
});