define("pconsole/routes/user/ledger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadcrumb: {
      title: 'Ledger',
      path: 'user.ledger'
    },

    beforeModel() {
      const {
        user
      } = this.modelFor('user');
      const tosVersion = Ember.get(user, 'tos.version');

      if (!tosVersion || tosVersion < 3) {
        this.router.transitionTo('user.ledger.index.tos');
      }
    }

  });

  _exports.default = _default;
});