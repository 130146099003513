define("pconsole/validators/confirmation-to-lower", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ConfirmationToLower = _base.default.extend({
    validate(value, options, model, attribute) {
      const on = Ember.get(options, 'on');
      const allowBlank = Ember.get(options, 'allowBlank');
      (false && !(Ember.isPresent(on)) && Ember.assert("[validator:confirmation] [".concat(attribute, "] option 'on' is required"), Ember.isPresent(on)));

      if (allowBlank && Ember.isEmpty(value)) {
        return true;
      }

      if (!Ember.isEqual(value.toLowerCase(), Ember.get(model, on).toLowerCase())) {
        // return validationError('confirmation', value, options);
        return this.createErrorMessage('confirmation-to-lower', value, options);
      }

      return true;
    }

  });

  ConfirmationToLower.reopenClass({
    getDependentsFor()
    /*attribute, options*/
    {
      return [];
    }

  });
  var _default = ConfirmationToLower;
  _exports.default = _default;
});