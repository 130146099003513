define("pconsole/models/insights-dashboard", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const InsightsDashboard = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    shortDescription: _emberData.default.attr('string'),
    modeId: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string')
  });

  InsightsDashboard.reopenClass({
    FIXTURES: [{
      id: 'security-center',
      name: 'Security center',
      shortDescription: 'Stay in control with up-to-date insights into your system.',
      modeId: 'security-center',
      icon: '/assets/shield.svg'
    }, {
      id: 'fleet-vitals',
      name: 'Fleet vitals',
      shortDescription: 'Performance metrics for a healthier and more efficient fleet.',
      modeId: 'fleet-vitals',
      icon: '/assets/pie-chart.svg'
    }]
  });
  var _default = InsightsDashboard;
  _exports.default = _default;
});