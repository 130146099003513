define("pconsole/routes/product/devices/welcome", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    model() {
      return this.modelFor('product').product;
    },

    setupController(controller) {
      this._super(...arguments);

      const {
        user
      } = this.modelFor('product');
      const show2020Billing = Ember.get(user, 'planVersion') === 'Pricing 2020.1';
      Ember.set(controller, 'show2020Billing', show2020Billing);
    }

  });

  _exports.default = _default;
});