define("pconsole/components/asset-tracker-property", ["exports", "react", "prop-types", "styled-components"], function (_exports, _react, _propTypes, _styledComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AssetTrackerProperty = ({
    format = v => v,
    label,
    name,
    obj
  }) => {
    if (obj && obj.hasOwnProperty(name)) {
      return _react.default.createElement(_react.Fragment, null, _react.default.createElement(Label, null, label), _react.default.createElement(Value, null, format(obj[name])));
    }

    return null;
  };

  const Label = _styledComponents.default.dt.withConfig({
    displayName: "asset-tracker-property__Label",
    componentId: "bmrjbn-0"
  })(["color:var(--tertiary-text-color);"]);

  const Value = _styledComponents.default.dd.withConfig({
    displayName: "asset-tracker-property__Value",
    componentId: "bmrjbn-1"
  })(["margin:0;text-align:right;white-space:pre-wrap;"]);

  AssetTrackerProperty.propTypes = {
    format: _propTypes.default.func,
    label: _propTypes.default.string.isRequired,
    name: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number]).isRequired,
    obj: _propTypes.default.oneOfType([_propTypes.default.object, _propTypes.default.array]).isRequired
  };
  var _default = AssetTrackerProperty;
  _exports.default = _default;
});