define("pconsole/templates/organization/ledger/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dxXcZnqg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Archive ledger\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    Archived Ledgers are \"],[6,\"b\"],[7],[0,\"not accessible\"],[8],[0,\" from the Particle Console or from devices anymore, and\\n    all of its contents will be permanently deleted after 30 days. Create a support ticket to recover\\n    an archived Ledger.\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you want to archive the ledger \"],[6,\"b\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, cancel\"],[8],[0,\"\\n    \"],[6,\"button\"],[10,\"disabled\",[25,\"not\",[[25,\"check-scope\",[\"ledger.definitions:archive\",null,[20,[\"store\"]]],null]],null],null],[9,\"class\",\"ui button outline secondary red\"],[3,\"action\",[[19,0,[]],\"archiveLedger\"]],[7],[0,\"\\n      Yes, archive Ledger\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/ledger/archive.hbs"
    }
  });

  _exports.default = _default;
});