define("pconsole/serializers/ledger-instance", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Key must start and and with instance, with optional 's' at the end
  const RESPONSE_KEY_REGEX = /^instances?$/;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (hash) {
        hash.id = hash.scope.type === 'Owner' ? 'owner' : hash.scope.value;
        hash.definition_id = hash.name;
        hash.instance_data = hash.data;
        delete hash.data; // Cannot use data since it's a reserved word by Ember
      }

      return this._super(typeClass, hash);
    },

    serialize() {
      const hash = this._super(...arguments);

      const data = hash.instance_data || {};
      return {
        data
      }; // Only the data field in an instance is editable
    },

    updateRecord() {
      // PUT is not implemented, every call uses POST
      return this.createeRecord(...arguments);
    },

    modelNameFromPayloadKey(key) {
      return RESPONSE_KEY_REGEX.test(key) ? 'ledger-instance' : key;
    },

    payloadKeyFromModelName() {
      return 'instance';
    }

  });

  _exports.default = _default;
});