define("pconsole/components/sim/sim-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    classNames: 'status',
    classNameBindings: ['sim.isActive:active']
  });

  _exports.default = _default;
});