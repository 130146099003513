define("pconsole/components/sort-chevs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sorting'],
    classNameBindings: ['sortAttr'],

    didInsertElement() {
      this._setProperties();
    },

    didUpdateAttrs() {
      this._setProperties();
    },

    asc: false,
    desc: false,

    _setProperties() {
      const componentSortAttr = this.get('sortAttr');
      const currentSortAttr = this.get('params.attr');
      const currentSortDir = this.get('params.dir');
      Ember.run.next(() => {
        this.set('currentSortAttr', currentSortAttr);
        this.set('currentSortDir', currentSortDir);
        this.set('asc', false);
        this.set('desc', false);

        if (componentSortAttr === currentSortAttr) {
          if (currentSortDir === 'desc') {
            this.set('desc', true);
          } else {
            this.set('asc', true);
          }
        }
      });
    },

    _setDirection(curDirection, sortAttr) {
      const curAttr = this.get('currentSortAttr');

      if (!curDirection || curDirection === 'desc' || curAttr !== sortAttr) {
        return 'asc';
      } else {
        return 'desc';
      }
    },

    actions: {
      sort() {
        const curDirection = this.get('currentSortDir');
        const sortAttr = this.get('sortAttr');

        const direction = this._setDirection(curDirection, sortAttr);

        this.sendAction('sortByAttr', sortAttr, direction);
      }

    }
  });

  _exports.default = _default;
});