define("pconsole/routes/user/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    templateName: 'product.device',
    controllerName: 'product.device',
    queryParams: {
      setupStatus: {
        refreshModel: false
      }
    },
    breadCrumb: {
      title: 'View Device',
      linkable: false
    },

    model(params) {
      return Ember.RSVP.hash({
        device: this.store.findRecord('device', params.device_id, {
          reload: true
        })
      });
    },

    afterModel: function afterModel({
      device
    }) {
      return Ember.RSVP.hash({
        sim: device.get('sim'),
        subscriptions: this.store.findAll('subscription')
      }).then(hash => {
        if (hash.sim) {
          const simSubscription = hash.subscriptions.findBy('type', 'SimSubscription');
          hash.sim.set('subscription', simSubscription);
          device.set('sim', hash.sim);
        }
      }).catch(() => {// TODO: It's ok, we're showing this in the template
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.subscribe();
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.unsubscribe();
        Ember.set(controller, 'isConfigFormRendered', false);
      }
    }

  });

  _exports.default = _default;
});