define("pconsole/components/asset-tracker-device-list", ["exports", "react", "prop-types", "styled-components", "pconsole/utils/date", "pconsole/components/device-groups-tag-list"], function (_exports, _react, _propTypes, _styledComponents, _date, _deviceGroupsTagList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Lists asset tracker devices.
   *
   * @example
   * ```jsx
   * <AssetTrackerDeviceList
   *   visibleDevices={visibleDevices}
   *   highlightedDevice={deviceId}
   *   isLoading={isLoading}
   *   onItemClick={item => handleItemClick(item)}
   *   onItemHover={item => handleItemClick(item)} />
   * ```
   */
  const AssetTrackerDeviceList = ({
    product,
    visibleDevices = [],
    count = 0,
    // Total devices in product
    hasDevices = false,
    highlightedDevice = '',
    isLoading = false,
    metrics,
    onGroupClick = () => {},
    onItemClick = () => {},
    onItemHover = () => {},
    onItemMouseLeave = () => {}
  }) => {
    const items = visibleDevices.map(device => {
      const highlight = device.id && device.id === highlightedDevice;
      return _react.default.createElement(Item, {
        key: device.id,
        highlight: highlight,
        onMouseEnter: e => onItemHover(device, e),
        onMouseLeave: e => onItemMouseLeave(e)
      }, _react.default.createElement(Heading, null, _react.default.createElement(OnlineIndicator, {
        online: device.online
      }), _react.default.createElement(LinkButton, {
        type: 'button',
        disabled: !device.geometry,
        onClick: e => {
          onItemClick(device, e);
          metrics.trackEvent('opened device details');
        }
      }, device.deviceName || "Unnamed Tracker")), _react.default.createElement(DeviceID, null, device.id), device.groups && _react.default.createElement(_deviceGroupsTagList.default, {
        groups: device.groups,
        limit: 3,
        onItemClick: (group, e) => onGroupClick({
          deviceGroups: [group.name]
        }, e)
      }));
    });
    const isFiltered = Boolean(window.location.search);

    if (isLoading) {
      return _react.default.createElement(LoadingList, null);
    }

    if (visibleDevices.length === 0) {
      // List of visible devices is empty
      // True when the product is empty
      if (!hasDevices) {
        return _react.default.createElement(EmptyList, null, _react.default.createElement('div', null, 'No devices found for this product.'));
      }

      if (count > 0) {
        // Devices are in cluster or out of map
        return _react.default.createElement(EmptyList, null, _react.default.createElement('div', null, 'No individual devices can be shown in this area.'), _react.default.createElement('p', null, 'Zoom in on a cluster or pan map to see results.'));
      }

      if (isFiltered) {
        return _react.default.createElement(EmptyList, null, _react.default.createElement('div', null, 'No device locations found.'), _react.default.createElement('p', null, 'Try a different search.'));
      }

      return _react.default.createElement(EmptyList, null, _react.default.createElement('div', null, 'No device locations found.'), Ember.get(product, 'platform.isAssetTracker') && _react.default.createElement('p', null, 'Check antenna connections and reception; refer to the', ' ', _react.default.createElement('a', {
        href: 'https://docs.particle.io/tutorials/asset-tracking/setup/#hardware-setup',
        target: '_blank'
      }, 'hardware setup guide'), ' for your device.'));
    }

    return _react.default.createElement(List, null, items);
  };

  const List = _styledComponents.default.ol.withConfig({
    displayName: "asset-tracker-device-list__List",
    componentId: "sc-1nimea3-0"
  })(["list-style:none;padding:0;"]);

  const loadingKeyframes = (0, _styledComponents.keyframes)(["to{background-position:200% 0;}"]);

  const LoadingList = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-device-list__LoadingList",
    componentId: "sc-1nimea3-1"
  })(["animation:", " 2.5s infinite;background-color:var(--quaternary-background-color);background-size:50%;height:100%;mask-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGJhc2VQcm9maWxlPSJmdWxsIiB2ZXJzaW9uPSIxLjEiIHN0eWxlPSJmaWxsOiBub25lOyB3aWR0aDogNzIwcHg7IGhlaWdodDogMzc1cHg7Ij48cGF0aCBkPSIiIHN0eWxlPSJzdHJva2Utd2lkdGg6IDE1OyBzdHJva2UtbGluZWNhcDogcm91bmQ7IHN0cm9rZS1saW5lam9pbjogcm91bmQ7IHN0cm9rZTogcmdiKDEwNiwgMTA2LCAxMDYpOyBmaWxsOiBub25lOyIvPjxwYXRoIGQ9IiIgc3R5bGU9InN0cm9rZS13aWR0aDogMTU7IHN0cm9rZS1saW5lY2FwOiByb3VuZDsgc3Ryb2tlLWxpbmVqb2luOiByb3VuZDsgc3Ryb2tlOiByZ2IoNzQsIDc0LCA3NCk7IGZpbGw6IG5vbmU7Ii8+PHBhdGggZD0iTTE1LDE1IEwxNSwxNSBMMTk1LDE1IE0xNSw0NSBMMTUsNDUgTDEzNSw0NSBNMTUsNzUgTDE1LDc1IEwxMzUsNzUgTTE1LDEwNSBMMTUsMTA1IEwxMzUsMTA1ICIgc3R5bGU9InN0cm9rZS13aWR0aDogMTU7IHN0cm9rZS1saW5lY2FwOiByb3VuZDsgc3Ryb2tlLWxpbmVqb2luOiByb3VuZDsgc3Ryb2tlOiByZ2IoMTAsIDEwLCAxMCk7IGZpbGw6IG5vbmU7Ii8+PC9zdmc+'),url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGJhc2VQcm9maWxlPSJmdWxsIiB2ZXJzaW9uPSIxLjEiIHN0eWxlPSJmaWxsOiBub25lOyB3aWR0aDogNzIwcHg7IGhlaWdodDogMzc1cHg7Ij48cGF0aCBkPSIiIHN0eWxlPSJzdHJva2Utd2lkdGg6IDE1OyBzdHJva2UtbGluZWNhcDogcm91bmQ7IHN0cm9rZS1saW5lam9pbjogcm91bmQ7IHN0cm9rZTogcmdiKDEwNiwgMTA2LCAxMDYpOyBmaWxsOiBub25lOyIvPjxwYXRoIGQ9IiIgc3R5bGU9InN0cm9rZS13aWR0aDogMTU7IHN0cm9rZS1saW5lY2FwOiByb3VuZDsgc3Ryb2tlLWxpbmVqb2luOiByb3VuZDsgc3Ryb2tlOiByZ2IoNzQsIDc0LCA3NCk7IGZpbGw6IG5vbmU7Ii8+PHBhdGggZD0iTTE4MCw0NSBMMTgwLDQ1IEwzMDAsNDUgTTIxMCw3NSBMMjEwLDc1IEwzMDAsNzUgTTI0MCwxMDUgTDI0MCwxMDUgTDMwMCwxMDUgIiBzdHlsZT0ic3Ryb2tlLXdpZHRoOiAxNTsgc3Ryb2tlLWxpbmVjYXA6IHJvdW5kOyBzdHJva2UtbGluZWpvaW46IHJvdW5kOyBzdHJva2U6IHJnYigxMCwgMTAsIDEwKTsgZmlsbDogbm9uZTsiLz48L3N2Zz4=');mask-repeat:repeat-y;mask-size:320px 150px;mask-position:0.8rem 0,calc(100% - 0.4rem) 0;"], loadingKeyframes);

  const EmptyList = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-device-list__EmptyList",
    componentId: "sc-1nimea3-2"
  })(["padding:6.4rem 3.2rem;text-align:center;h2{font-size:1em;font-weight:bold;margin:0 0 1em;text-transform:uppercase;}&::before{content:\"\f100\";display:block;font-family:var(--icons-font);font-size:3.2rem;}"]); // See: https://www.telerik.com/kendo-react-ui/components/styling/styled-components/#toc-styling-over-dynamically-computed-props


  const Item = _styledComponents.default.li.withConfig({
    displayName: "asset-tracker-device-list__Item",
    componentId: "sc-1nimea3-3"
  })(["", " border-bottom:1px solid var(--primary-border-color);padding:1.6rem;&:last-child{border-bottom:none}&:hover{background-color:var(--quaternary-background-color);}"], props => props.highlight ? (0, _styledComponents.css)(["background-color:var(--quaternary-background-color);"]) : (0, _styledComponents.css)(["background-color:transparent;"]));

  const Heading = _styledComponents.default.h2.withConfig({
    displayName: "asset-tracker-device-list__Heading",
    componentId: "sc-1nimea3-4"
  })(["font-size:1em;font-weight:700;margin:0 0 0.8rem 0;"]);

  const onlineKeyframes = (0, _styledComponents.keyframes)(["0%,100%{color:var(--cyan);}50%{color:transparent;}"]);

  const OnlineIndicator = _styledComponents.default.i.withConfig({
    displayName: "asset-tracker-device-list__OnlineIndicator",
    componentId: "sc-1nimea3-5"
  })(["margin-right:1.12rem;&::before{", " color:", ";content:\"\f21b\";font-family:var(--icons-font);};"], props => props.online ? (0, _styledComponents.css)(["animation:", " 6s linear infinite;"], onlineKeyframes) : '', props => props.online ? 'var(--online-color)' : 'var(--tertiary-text-color)');

  const DeviceID = _styledComponents.default.p.withConfig({
    displayName: "asset-tracker-device-list__DeviceID",
    componentId: "sc-1nimea3-6"
  })(["margin:0 0 0.8rem 0;"]);

  const LinkButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-device-list__LinkButton",
    componentId: "sc-1nimea3-7"
  })(["background:transparent;border:none;color:var(--secondary-text-color);margin:0;padding:0;&:disabled{color:var(--disabled-text-color);}"]);

  AssetTrackerDeviceList.propTypes = {
    visibleDevices: _propTypes.default.array.isRequired,
    highlightedDevice: _propTypes.default.string,
    isLoading: _propTypes.default.bool,
    hasDevices: _propTypes.default.bool,
    product: _propTypes.default.object,
    metrics: _propTypes.default.object.isRequired,
    onGroupClick: _propTypes.default.func,
    onItemClick: _propTypes.default.func,
    onItemHover: _propTypes.default.func,
    onMouseLeave: _propTypes.default.func
  };
  var _default = AssetTrackerDeviceList;
  _exports.default = _default;
});