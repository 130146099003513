define("pconsole/components/device-firmware-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['device-firmware-selector'],
    device: null,
    firmwares: null,
    firmwaresSort: ['version:desc'],

    onChange() {},

    onFlashChange() {},

    sortedFirmwares: Ember.computed.sort('firmwares', 'firmwaresSort'),
    disableFlashImmediatelyCheckbox: Ember.computed('device.online', 'device.desiredFirmwareVersion', 'device.isRunningTargetedProductFirmwareVersion', 'device.isCellular', function () {
      const device = this.get('device'); // The checkbox should be disabled when there is no targeted firmware version selected

      if (!device.get('desiredFirmwareVersion')) {
        this.set('disabledCheckboxReason', 'Select a firmware from the dropdown to flash it to a device');
        return true;
      } // The checkbox should be disabled for non-Cellular devices that are offline


      if (!device.get('isCellular') && !device.get('online')) {
        this.set('disabledCheckboxReason', 'This device cannot be flashed immediately because it is offline');
        return true;
      } // The checkbox should be disabled when the device is already running the firmware to be flashed


      if (device.get('isRunningTargetedProductFirmwareVersion')) {
        this.set('disabledCheckboxReason', 'This device is already running the firmware version you have selected');
        return true;
      } // The checkbox should be disabled if the device has disabled accepting OTA updates


      if (device.get('firmwareUpdatesEnabled') === false && device.get('firmwareUpdatesForced') !== true) {
        this.set('disabledCheckboxReason', 'This device has disabled accepting OTA updates. If you want to flash now, re-enable updates on the device or force enable updates from the cloud');
        return true;
      }

      return false;
    }),
    hasLockedFirmware: Ember.computed('device.{desiredFirmwareVersion,hasDirtyAttributes}', function () {
      // hasLockedFirmware: notEmpty('device.desiredFirmwareVersion'),
      const desiredFirmwareVersion = Ember.get(this, 'device.desiredFirmwareVersion');
      const changedAttributes = Object.keys(Ember.get(this, 'device').changedAttributes());
      return Ember.isPresent(desiredFirmwareVersion) && !changedAttributes.includes('desiredFirmwareVersion');
    }),
    actions: {
      change(desiredFirmwareVersion) {
        this.onChange(parseInt(desiredFirmwareVersion, 10));
      }

    }
  });

  _exports.default = _default;
});