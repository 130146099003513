define("pconsole/templates/components/graph-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e9su6/iN",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"c-graph-chart \",[25,\"if\",[[20,[\"isLoading\"]],\"--isLoading\"],null]]]],[7],[0,\"\\n\\t\"],[6,\"svg\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/graph-chart.hbs"
    }
  });

  _exports.default = _default;
});