define("pconsole/mixins/export-to-csv-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),

    makeCSVRequest(url) {
      return this.get('apiAjax').getRequest({
        url: url,
        headers: {
          Accept: 'text/csv'
        },

        success(response, status, xhr) {
          // check for a filename
          let filename = '';
          const disposition = xhr.getResponseHeader('Content-Disposition');

          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);

            if (matches !== null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const type = xhr.getResponseHeader('Content-Type');
          const blob = new Blob([response], {
            type: type
          });

          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
          } else {
            const URL = window.URL || window.webkitURL;
            const downloadUrl = URL.createObjectURL(blob);

            if (filename) {
              // use HTML5 a[download] attribute to specify filename
              const a = document.createElement('a'); // safari doesn't support this yet

              if (typeof a.download === 'undefined') {
                window.location = downloadUrl;
              } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
              }
            } else {
              window.location = downloadUrl;
            }

            setTimeout(function () {
              URL.revokeObjectURL(downloadUrl);
            }, 100); // cleanup
          }
        }

      }).then(response => {
        this.get('notify').success('Your download has been successfully triggered.');
        return response;
      }, err => {
        this.get('notify').error('There was a problem exporting the data.');
        return Ember.RSVP.reject(err);
      });
    }

  });

  _exports.default = _default;
});