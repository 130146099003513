define("pconsole/templates/components/gallery-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gY3HMv7g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"gallery-card__name hint--top\"],[10,\"data-hint\",[25,\"if\",[[20,[\"nameHint\"]],[20,[\"name\"]]],null],null],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[6,\"img\"],[9,\"class\",\"gallery-card__img\"],[10,\"alt\",[26,[[18,\"name\"],\" logo\"]]],[10,\"src\",[18,\"icon\"],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"span\"],[9,\"class\",\"gallery-card__name-text\"],[7],[1,[18,\"name\"],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"gallery-card__desc\"],[7],[1,[18,\"shortDescription\"],false],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"gallery-card__actions\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"or\",[[20,[\"buttonText\"]],[20,[\"disabledText\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[9,\"class\",\"ui button outline primary\"],[10,\"disabled\",[25,\"if\",[[20,[\"disabled\"]],true,false],null],null],[10,\"onclick\",[18,\"onSelect\"],null],[7],[0,\"\\n\"],[4,\"unless\",[[20,[\"disabled\"]]],null,{\"statements\":[[0,\"        \"],[1,[18,\"buttonText\"],false],[0,\" \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-a\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[18,\"disabledText\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/gallery-card.hbs"
    }
  });

  _exports.default = _default;
});