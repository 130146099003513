define("pconsole/components/webhook-history-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['executions-history-graph'],

    didInsertElement() {
      this.renderChart();
    },

    didUpdateAttrs() {
      this.renderChart();
    },

    renderChart() {
      this._super(...arguments);

      const moment = window.moment;
      const d3 = window.d3;
      const successKey = this.get('successKey') || 'success';
      const errorKey = this.get('errorKey') || 'error';
      const sleepKey = this.get('sleepKey') || 'sleep';
      const counters = this.get('counters');

      const byDate = _.keyBy(counters, 'date');

      const today = moment().utc().startOf('day');
      const data = [];
      const dataDay = moment(today).subtract(29, 'days');

      while (dataDay.isSameOrBefore(today)) {
        const dayString = dataDay.format('YYYYMMDD');
        const dayData = byDate[dayString] || {};
        data.push({
          date: dataDay.valueOf(),
          success: dayData[successKey] || 0,
          failure: dayData[errorKey] || 0,
          skipped: dayData[sleepKey] || 0,
          ratelimit: dayData.ratelimit || 0
        });
        dataDay.add(1, 'days');
      }

      const axisExcess = 10;
      const baseWidth = this.get('baseWidth') || 992;
      const margin = {
        top: 20 + axisExcess,
        right: 20,
        bottom: 40,
        left: 40
      },
            width = baseWidth - margin.left - margin.right,
            height = 200 - margin.top - margin.bottom;
      const x = d3.scale.ordinal().rangeRoundBands([0, width], 0.3);
      const y = d3.scale.linear().rangeRound([height, 0]);
      const color = d3.scale.ordinal().range(['var(--online-color)', 'var(--danger-color)', 'var(--timeout-color)']).domain(['success', 'failure', 'skipped']);
      data.forEach(function (d) {
        let y0 = 0;
        d.results = color.domain().map(function (type) {
          const result = {
            type: type,
            y0: y0,
            y1: y0 += +d[type]
          };
          return result;
        });
        d.total = d.results[d.results.length - 1].y1;
      });
      const maxY = d3.max(data, function (d) {
        return d.total;
      });
      x.domain(data.map(function (d) {
        return d.date;
      }));
      y.domain([0, maxY]);
      const xAxis = d3.svg.axis().scale(x).orient('bottom').innerTickSize(-(height + 2 * axisExcess)).outerTickSize(0).tickFormat(function (d, i) {
        const parsedDate = moment.utc(d);

        if (i === 0 || parsedDate.date() === 1) {
          return parsedDate.format('MMM');
        }

        return parsedDate.date();
      });
      const yAxis = d3.svg.axis().scale(y).orient('left').innerTickSize(-width).outerTickSize(0).tickFormat(d3.format('s'));

      if (maxY < 5) {
        yAxis.tickValues(d3.range(0, 5));
      } else {
        yAxis.ticks(6);
      }

      const tip = d3.tip().attr('class', 'd3-tip').offset([-10, 0]).html(function (d) {
        let html = '<div class="day-tip">' + moment.utc(d.date).format('MMM D') + '</div><table class="day-stats">';
        ['success', 'failure', 'skipped'].forEach(function (type) {
          if (!d[type]) {
            return;
          }

          html += "<tr><td class='day-type'>" + type + "</td><td class='day-value' style='color:" + color(type) + "'>" + d[type] + '</td></tr>';
        });
        html += '</table>';
        return html;
      });
      d3.select(this.element).select('svg').remove(); // Delete any previously existing SVG

      const rootSvg = d3.select(this.element).append('svg').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
      rootSvg.append('rect').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom).attr('class', 'graph');
      const svg = rootSvg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      svg.call(tip);
      svg.append('g').attr('class', 'y axis').call(yAxis);
      const xAxisElem = svg.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);
      xAxisElem.selectAll('text').style('text-anchor', 'middle').attr({
        dx: 0,
        dy: '0.7em',
        transform: 'translate(0,' + axisExcess + ')'
      });
      xAxisElem.selectAll('line').attr('transform', 'translate(0,' + axisExcess + ')');
      const day = svg.selectAll('.day').data(data).enter().append('g').attr('class', 'g').attr('transform', function (d) {
        return 'translate(' + x(d.date) + ',0)';
      }).on('mouseover', function (d) {
        const hasData = ['success', 'failure', 'skipped'].some(function (type) {
          return !!d[type];
        });

        if (hasData) {
          tip.show(d);
        }
      }).on('mouseout', tip.hide);
      day.selectAll('rect').data(function (d) {
        return d.results;
      }).enter().append('rect').attr('width', x.rangeBand()).attr('y', function (d) {
        return y(d.y1);
      }).attr('height', function (d) {
        return y(d.y0) - y(d.y1);
      }).style('fill', function (d) {
        return color(d.type);
      });
    }

  });

  _exports.default = _default;
});