define("pconsole/controllers/organization/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, {
    applicationController: Ember.inject.controller('application'),
    currentPath: Ember.computed.alias('applicationController.currentPath'),
    products: Ember.computed.alias('model.products'),
    org: Ember.computed.alias('model.organization')
  });

  _exports.default = _default;
});