define("pconsole/adapters/product-firmware", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'firmware';
    },

    // TODO: namespace isn't intended to be used this way; it's better define associations with
    // links in the serializer
    namespace: Ember.computed('routeData.product', function () {
      return 'v1/products/' + this.get('routeData.product');
    }),
    formDataTypes: ['POST'],

    // overwrite this from FormDataAdapterMixin because fhey had a bad way of naming the keys
    ajaxOptions(url, type, options) {
      let data;

      if (options && 'data' in options) {
        data = options.data;
      }

      const hash = this._super.apply(this, arguments);

      if (typeof FormData !== 'undefined' && data && this.formDataTypes.contains(type)) {
        const formData = new FormData();
        Object.keys(data).forEach(function (key) {
          if (typeof data[key] !== 'undefined') {
            const val = data[key];

            if (val !== undefined && val !== null) {
              formData.append(key, val);
            }
          }
        });
        hash.processData = false;
        hash.contentType = false;
        hash.data = formData;
      }

      return hash;
    }

  });

  _exports.default = _default;
});