define("pconsole/serializers/membership", ["exports", "ember-data", "pconsole/utils/hash-code", "pconsole/serializers/application"], function (_exports, _emberData, _hashCode, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      role: {
        embedded: 'always'
      },
      organization: {
        embedded: 'always'
      },
      product: {
        embedded: 'always'
      }
    },

    normalize(typeClass, hash) {
      if (hash) {
        hash.id = (0, _hashCode.default)(JSON.stringify(hash));

        if (hash.org) {
          // Map the response's `org` field to `organization` expected by the model
          hash.organization = hash.org;
          hash.team_name = hash.org.name;
          delete hash.org;
        }

        if (hash.product) {
          hash.team_name = hash.product.name;
          hash.product = hash.product.id;
        }
      }

      return this._super(...arguments);
    },

    serialize(snapshot) {
      return {
        role: snapshot.record.get('role').get('id')
      };
    }

  });

  _exports.default = _default;
});