define("pconsole/controllers/login", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    model: {},
    actions: {
      async authenticate() {
        Ember.$('.loading').show();
        const {
          identification,
          password,
          otp,
          token
        } = this.getProperties('identification', 'password', 'otp', 'token');
        const session = this.get('session');

        try {
          await session.authenticate('authenticator:oauth2-n', identification, password, otp, {
            impersonate: token
          });

          if (Ember.get(this, 'session.isAuthenticated')) {
            this.transitionToRoute('user.index');
          }
        } catch (reason) {
          if (reason && reason.error_description === 'User credentials are invalid') {
            this.get('notify').alert('The username and password did not match our records. Try again');
          } else {
            this.get('notify').alert('There was a problem with your login');
          }
        } finally {
          Ember.$('.loading').hide();
        }
      }

    },
    buildUrl: _environment.default.APP.BUILD_URL
  });

  _exports.default = _default;
});