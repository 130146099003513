define("pconsole/routes/user/clients/create", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    templateName: 'product.clients.create',
    controllerName: 'product.clients.create',

    model() {
      return this.store.createRecord('client');
    }

  });

  _exports.default = _default;
});