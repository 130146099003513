define("pconsole/routes/user/logic-functions/details/delete", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    templateName: 'organization.logic-functions.details.delete',
    controllerName: 'organization.logic-functions.details.delete',
    breadCrumb: null,

    model() {
      return this.modelFor('user.logic-functions.details').logicFunction;
    }

  });

  _exports.default = _default;
});