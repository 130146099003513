define("pconsole/models/logic-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const LogicTemplate = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    // md format
    shortDescription: _emberData.default.attr('string'),
    eventTestData: _emberData.default.attr('string'),
    instructions: _emberData.default.attr('string'),
    // md format
    // category: DS.attr('string'), pending
    logicFunction: _emberData.default.attr() // Values with same keys as LogicFunction

  });
  /* eslint-disable indent */


  LogicTemplate.reopenClass({
    FIXTURES: [{
      id: 'decode-base64',
      slug: 'decode-base64',
      name: 'Decode Base64',
      shortDescription: 'Decodes an event data payload that is encoded using Base64 back into a byte array.',
      description: "In order to transfer binary data over a Particle event, an application can encode the byte array into an ASCII string. [Base64](https://en.wikipedia.org/wiki/Base64) uses 33% more bytes than the original array. See also the [Decode Base85 template](/logic/templates/decode-base85) for a version that uses only 25% more bytes than the original.\nUse this Logic Function to take a Base64 encoded event and publish back to the event stream a decoded version.",
      eventTestData: 'AQIDBAU=',
      instructions: "### Publish an encoded event from your application\n\nAdd the [Base64RK](https://github.com/rickkas7/Base64RK) library to your firmware.\n\n```\nparticle library add Base64RK\n```\n\nIn your application, encode the data to send and publish it.\n\n```\n// at the top\n#include \"Base64RK.h\"\n\n// in your loop\nuint8_t data[] = { 1, 2, 3, 4, 5 }; // data to publish\nchar buffer[256]; // must be 33% larger than the size of the data\nBase64::encode(data, sizeof(data), buffer, sizeof(buffer), true);\nParticle.publish(\"data-b64\", buffer); // will publish data AQIDBAU=\n```\n\n### Decode using a Logic Function\n\nDecode the event using this template.\n\nTo test, execute the Function using the data `AQIDBAU=`. This will decode to the data bytes 1, 2, 3, 4, 5.",
      logicFunction: {
        name: 'Decode Base64',
        description: 'Decodes an event data payload that is encoded using Base64 back into a byte array.',
        templateSlug: 'decode-base64',
        logicTriggers: [{
          type: 'Event',
          enabled: true
        }],
        code: "import Particle from 'particle:core';\nimport { base64Decode } from 'particle:encoding';\n\nexport default function decode({ event }) {\n  const encoded = event.eventData;\n  try {\n    const decoded = base64Decode(encoded);\n    Particle.publish(\"data-decoded\", Array.from(decoded), { productId: event.productId });\n  } catch (err) {\n    console.error(\"Invalid Base64 data\", encoded);\n    throw err;\n  }\n}"
      }
    }, {
      id: 'decode-base85',
      slug: 'decode-base85',
      name: 'Decode Base85',
      shortDescription: 'Decodes an event data payload that is encoded using Base85 back into a byte array.',
      description: "In order to transfer binary data over a Particle event, an application can encode the byte array into an ASCII string. [Base85](https://en.wikipedia.org/wiki/Ascii85) uses 25% more bytes than the original array. See also the [Decode Base64 template](/logic/templates/decode-base64) for a version that uses 33% more bytes than the original but uses a more common encoding.\n\nUse this Logic Function to take a Base85 encoded event that uses the RFC 1924 character set and publish back to the event stream a decoded version.",
      eventTestData: '0RjUA1p',
      instructions: "### Publish an encoded event from your application\n\nAdd the [Base85](https://github.com/particle-iot/base85) library to your firmware.\n\n```\nparticle library add Base85\n```\n\nIn your application, encode the data to send and publish it.\n\n```\n// at the top\n#include \"Base85.h\"\n\n// in your loop\nuint8_t data[] = { 1, 2, 3, 4, 5 }; // data to publish\nchar buffer[256]; // must be 25% larger than the size of the data\nbintob85(buffer, data, sizeof(data));\nString encoded = String(buffer, unpaddedB85Size(sizeof(data)));\nParticle.publish(\"data-b85\", encoded); // will publish data 0RjUA1p\n```\n\n### Decode using a Logic Function\n\nDecode the event using this template.\n\nTo test, execute the Function using the data `0RjUA1p`. This will decode to the data bytes 1, 2, 3, 4, 5.",
      logicFunction: {
        name: 'Decode Base85',
        description: 'Decodes an event data payload that is encoded using Base85 back into a byte array.',
        templateSlug: 'decode-base85',
        logicTriggers: [{
          type: 'Event',
          enabled: true
        }],
        code: "import Particle from 'particle:core';\nimport { base85Decode } from 'particle:encoding';\n\nexport default function decode({ event }) {\n  const encoded = event.eventData;\n  try {\n    const decoded = base85Decode(encoded);\n    Particle.publish(\"data-decoded\", Array.from(decoded), { productId: event.productId });\n  } catch (err) {\n    console.error(\"Invalid Base85 data\", encoded);\n    throw err;\n  }\n}"
      }
    }, {
      id: 'reformat-json',
      slug: 'reformat-json',
      name: 'Reformat JSON data',
      shortDescription: 'Reformat and add fields to a JSON event to make it compatible with an external service.',
      description: "Take data published by a Particle device as JSON, then reformat it to fit the requirements of an external service, then publish it back as an event so it can be delivered using a [Particle integration](https://docs.particle.io/integrations/integrations/).\n\nThe template contains an example format. Customize it to fit the service you are integrating with.",
      eventTestData: '{"i":"1234","d":42}',
      instructions: "### Publish data as JSON from the device\n\nUse the built-in [JSON library](https://docs.particle.io/reference/device-os/firmware/#json) to encode your data as JSON and publish it.\n\n```\nchar buf[256]; // must be big enough for your data\nJSONBufferWriter writer(buf, sizeof(buf));\nwriter.beginObject();\n    writer.name(\"d\").value(sensorData);\n    writer.name(\"i\").value(sensorId);\nwriter.endObject();\n\nParticle.publish(\"sensor\", buf);\n```\n\n### Parse and reformat the data in Logic\n\nUse the Logic Function to extract the fields from your event and reformat it with the format expected by the external service.\n\nIf the service expects data like this:\n```\n{\n  \"id\": \"123456\",\n  \"data\": {\n    \"value\": 42,\n    \"type\": \"Sensor\"\n  }\n}\n```\n\nYou could reformat it using this Logic code:\n```\nconst data = JSON.parse(event.eventData);\nconst reformatted = {\n  id: data.i,\n  data: {\n    value: data.d,\n    type: 'Sensor'\n  }\n};\n```\n\nTo test, use this data: `{\"i\":\"1234\",\"d\":42}`",
      logicFunction: {
        name: 'Reformat JSON data',
        description: 'Reformat and add fields to a JSON event to make it compatible with an external service.',
        templateSlug: 'reformat-json',
        logicTriggers: [{
          type: 'Event',
          enabled: true
        }],
        code: "import Particle from 'particle:core';\n\nexport default function reformat({ event }) {\n  let data;\n  try {\n\t  data = JSON.parse(event.eventData);\n  } catch (err) {\n    console.error(\"Invalid JSON\", event.eventData);\n    throw err;\n  }\n  const reformatted = {\n    id: data.i,\n    data: {\n      value: data.d,\n      type: 'Sensor'\n    }\n  };\n  Particle.publish(\"data-reformatted\", reformatted, { productId: event.productId });\n}"
      }
    }, // Insert additional templates above this comment
    // Keep blank templates at the end of the list
    {
      id: 'event-function',
      slug: 'event-function',
      name: 'Event-triggered Function',
      shortDescription: 'Start with a blank Logic Function that triggers from an event.',
      description: "Write your own Logic Function from scratch to perform operations when a device publishes an event, such as:\n\n* Data transformation\n* Cloud protocol translation\n* Device management automation\n* Moving logic from firmware to cloud\n* Alerting on abnormal conditions",
      logicFunction: {
        name: 'Event-triggered Function',
        templateSlug: 'event-function',
        logicTriggers: [{
          type: 'Event',
          enabled: true
        }],
        code: "import Particle from 'particle:core';\n\nexport default function process({ functionInfo, trigger, event }) {\n  // Add your code here\n}"
      }
    }, {
      id: 'event-scheduled-function',
      slug: 'event-scheduled-function',
      name: 'Scheduled Function',
      shortDescription: 'Start with a blank Logic Function that triggers on a schedule.',
      description: "Write your own Logic Function from scratch to perform operations on a regular interval (hourly, daily, etc), such as:\n\n* Fleet monitoring\n\nSpecify the schedule in [cron format](https://en.wikipedia.org/wiki/Cron).",
      logicFunction: {
        name: 'Scheduled Function',
        templateSlug: 'event-scheduled-function',
        logicTriggers: [{
          type: 'Scheduled',
          enabled: true
        }],
        code: "import Particle from 'particle:core';\n\nexport default function job({ functionInfo, trigger, scheduled }) {\n  // Add your code here\n}"
      } // Don't insert additional templates here

    }]
  });
  /* eslint-enable */

  var _default = LogicTemplate;
  _exports.default = _default;
});