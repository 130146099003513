define("pconsole/components/eventlogs/helper-message.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const HelperMessage = ({
    events,
    selectedEvent
  }) => {
    let content = null;

    if (selectedEvent) {
      content = _react.default.createElement('div', {
        className: '_eventlogsnew__helpMessage'
      }, _react.default.createElement('h5', {
        className: 'bold'
      }, selectedEvent.name), _react.default.createElement('h5', null, 'Published by ', _react.default.createElement('span', {
        className: 'bold'
      }, selectedEvent.coreid), ' on ', _react.default.createElement('span', {
        className: 'bold smaller'
      }, selectedEvent.published_at)), selectedEvent.version ? _react.default.createElement('h5', null, 'Firmware version: ', selectedEvent.version) : null, typeof selectedEvent.public !== 'undefined' && _react.default.createElement('div', {
        className: '_eventlogsnew__helpMessage__eventPublicPrivate'
      }, _react.default.createElement('h5', null, selectedEvent.public ? 'Public' : 'Private', ' Event'), _react.default.createElement('div', {
        className: 'hint--top',
        'data-hint': 'If this event is publicly visible'
      }, _react.default.createElement('i', {
        className: 'im-info-icon help-icon'
      }))));
    } else if (events && events.length > 0) {
      content = _react.default.createElement('div', {
        className: '_eventlogsnew__helpMessage'
      }, _react.default.createElement('h5', null, 'Select an event to see more details about it'));
    } else {
      content = _react.default.createElement('div', {
        className: '_eventlogsnew__helpMessage'
      }, _react.default.createElement('h5', null, 'Get events to appear in the stream by using'), _react.default.createElement('h5', null, _react.default.createElement('span', {
        className: 'publish-code'
      }, 'Particle.publish()'), ' in your firmware (', _react.default.createElement('a', {
        href: 'https://docs.particle.io/reference/device-os/firmware/#particle-publish-',
        target: '_blank'
      }, 'docs'), ')'));
    }

    return content;
  };

  var _default = HelperMessage;
  _exports.default = _default;
});