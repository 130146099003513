define("pconsole/templates/components/batch-actions-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E8ZKTJ+f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"showBatch\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"non-batch-buttons\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"batch-action-buttons\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"product.devices.batch.editGroups\"],[[\"class\",\"scope\",\"product\"],[\"ui button outline primary\",\"groups:update\",[20,[\"product\"]]]],{\"statements\":[[0,\"      \"],[6,\"i\"],[9,\"class\",\"im-group\"],[7],[8],[6,\"span\"],[7],[0,\"Edit groups\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary cancel-btn\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[0,\"Cancel\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/batch-actions-buttons.hbs"
    }
  });

  _exports.default = _default;
});