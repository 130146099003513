define("pconsole/utils/average-group-color", ["exports", "pconsole/lib/average-rgb-colors"], function (_exports, _averageRgbColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = averageGroupColor;

  function averageGroupColor(groups, defaultColor = '#00334f') {
    if (!groups || !groups.length) {
      return defaultColor;
    }

    return (0, _averageRgbColors.default)(...groups.map(g => g.color));
  }
});