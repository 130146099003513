define("pconsole/templates/partials/integration/create-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F6qlXqsH",
    "block": "{\"symbols\":[\"link\"],\"statements\":[[6,\"div\"],[9,\"class\",\"integration-editor__sidebar info-sidebar\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"info-sidebar__title\"],[7],[0,\"\\n    \"],[6,\"img\"],[10,\"alt\",[26,[[20,[\"model\",\"template\",\"name\"]],\" logo\"]]],[10,\"src\",[20,[\"model\",\"template\",\"logo\"]],null],[7],[8],[0,\"\\n    \"],[1,[20,[\"model\",\"template\",\"name\"]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    \"],[1,[25,\"markdown-to-html\",null,[[\"markdown\",\"openLinksInNewWindow\",\"extensions\"],[[25,\"unbound\",[[20,[\"model\",\"template\",\"shortDescription\"]]],null],true,\"xssfilter\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"h4\"],[9,\"class\",\"instructions\"],[7],[0,\"Instructions\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    \"],[1,[25,\"markdown-to-html\",null,[[\"markdown\",\"openLinksInNewWindow\",\"extensions\"],[[25,\"unbound\",[[20,[\"model\",\"template\",\"instructions\"]]],null],true,\"xssfilter\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"template\",\"links\"]]],null,{\"statements\":[[0,\"    \"],[6,\"a\"],[9,\"class\",\"info-sidebar__link\"],[10,\"href\",[19,1,[\"link\"]],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n      \"],[1,[19,1,[\"title\"]],false],[0,\" \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-a\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/integration/create-sidebar.hbs"
    }
  });

  _exports.default = _default;
});