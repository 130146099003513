define("pconsole/templates/components/form-json-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t130rZQz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[[18,\"className\"],\"__header \",[18,\"headerClass\"]]]],[7],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",[26,[\"hint-bottom header-tab \",[25,\"if\",[[20,[\"isCustomView\"]],\"inactive\",\"\"],null]]]],[10,\"data-hint\",[26,[[25,\"if\",[[20,[\"disableBasicView\"]],\"The Default view is disabled because the content in your JSON is too complex.\"],null]]]],[7],[0,\"\\n    \"],[6,\"h4\"],[10,\"class\",[26,[\"uppercase \",[25,\"if\",[[20,[\"disableBasicView\"]],\"disabled\"],null]]]],[3,\"action\",[[19,0,[]],\"showBasicView\"]],[7],[0,\"\\n      \"],[6,\"span\"],[7],[1,[18,\"basicTitle\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"h4\"],[10,\"class\",[26,[\"header-tab uppercase \",[25,\"if\",[[20,[\"isCustomView\"]],\"\",\"inactive\"],null]]]],[3,\"action\",[[19,0,[]],\"showCustomView\"]],[7],[0,\"\\n    \"],[6,\"span\"],[7],[1,[18,\"customTitle\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"isCustomView\"]]],null,{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"view\",\"header\"],[\"custom\",[25,\"component\",[\"yield-if-block\"],null]]]]]],[0,\"\\n  \"],[1,[25,\"json-editor\",null,[[\"json\",\"mode\",\"name\",\"onError\",\"onChange\"],[[20,[\"json\"]],[20,[\"jsonEditorMode\"]],[20,[\"jsonEditorName\"]],[20,[\"onCustomError\"]],[20,[\"onCustomChange\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[11,1,[[25,\"hash\",null,[[\"view\",\"header\",\"form\"],[\"basic\",[25,\"component\",[\"yield-if-block\"],null],[25,\"component\",[\"yield-if-block\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/form-json-editor.hbs"
    }
  });

  _exports.default = _default;
});