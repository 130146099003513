define("pconsole/templates/product/usage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kYiovhiQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[0,\"Usage\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/usage.hbs"
    }
  });

  _exports.default = _default;
});