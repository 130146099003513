define("pconsole/templates/components/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HBy3Dh0z",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[9,\"type\",\"file\"],[9,\"id\",\"upload-file\"],[10,\"accept\",[26,[[25,\"if\",[[20,[\"accept\"]],[20,[\"accept\"]],\"*\"],null]]]],[7],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"file-name\"]]],null,{\"statements\":[[0,\"  \"],[6,\"p\"],[9,\"class\",\"upload-text\"],[7],[6,\"i\"],[9,\"class\",\"upload-icon ion-paperclip\"],[7],[8],[0,\"Drag and drop a \"],[1,[25,\"if\",[[20,[\"accept\"]],[20,[\"accept\"]]],null],false],[0,\" file here or \"],[6,\"a\"],[3,\"action\",[[19,0,[]],\"selectFile\"]],[7],[0,\"choose one\"],[8],[0,\".\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"firmware-selected\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[6,\"i\"],[9,\"class\",\"upload-icon ion-document-text\"],[7],[8],[1,[18,\"file-name\"],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"pending-file-kbs\"],[7],[0,\"(\"],[1,[25,\"file-size\",[[20,[\"file-size\"]]],null],false],[0,\")\"],[8],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"id\",\"remove-file\"],[3,\"action\",[[19,0,[]],\"removeFile\"]],[7],[6,\"i\"],[9,\"class\",\"icon ion-close\"],[7],[8],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/file-upload.hbs"
    }
  });

  _exports.default = _default;
});