define("pconsole/components/vitals-history", ["exports", "d3", "moment"], function (_exports, _d, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    segment: Ember.inject.service(),

    updateGraphs() {
      const vitals = {
        'signal-strength': 'signalStrength',
        'signal-quality': 'signalQuality',
        'round-trip-time': 'roundTripTime',
        'memory-usage': 'memoryUsed',
        'battery-charge': 'batteryCharge'
      };

      for (const id of Object.keys(vitals)) {
        this.resetGraph(id);
        this.drawAreaGraph(id, vitals[id]);
      }
    },

    resetGraph(id) {
      const svg = _d.default.select("#".concat(id));

      const parentWidth = svg.node().parentNode.offsetWidth;
      const margin = {
        top: 10,
        right: 5,
        bottom: 20,
        left: 37
      };
      const width = parentWidth - margin.left - margin.right;
      const height = 200 - margin.top - margin.bottom;
      svg.selectAll('*').remove();
      svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
      this.setProperties({
        width,
        height,
        margin
      });
    },

    drawAreaGraph(id, vital) {
      const data = this.get('vitals');

      const svg = _d.default.select("#".concat(id));

      const width = this.get('width');
      const height = this.get('height');
      const margin = this.get('margin');
      const domains = {
        'round-trip-time': _d.default.extent(data, d => d.roundTripTime)
      };
      const tickSuffix = {
        'round-trip-time': 's'
      };

      const curveStyle = (() => {
        switch (id) {
          case 'signal-strength':
            return _d.default.curveMonotoneX;

          case 'signal-quality':
            return _d.default.curveMonotoneX;

          case 'round-trip-time':
            return _d.default.curveStep;

          case 'memory-usage':
            return _d.default.curveStep;

          case 'battery-charge':
            return _d.default.curveMonotoneX;
        }

        return _d.default.curveLinear;
      })();

      const vitalsForId = (() => {
        switch (id) {
          case 'signal-strength':
            return ['signalStrength', 'signalStrengthRaw'];

          case 'signal-quality':
            return ['signalQuality', 'signalQualityRaw'];

          case 'round-trip-time':
            return ['roundTripTime'];

          case 'memory-usage':
            return ['memoryUsed', 'memoryUsedRaw', 'memoryAvailableRaw'];

          case 'battery-charge':
            return ['batteryCharge', 'batteryState'];
        }

        return [];
      })();

      function vitalName(vitalId) {
        switch (vitalId) {
          case 'signalStrength':
            return 'Signal strength';

          case 'signalStrengthRaw':
            return 'Raw strength';

          case 'signalQuality':
            return 'Signal quality';

          case 'signalQualityRaw':
            return 'Raw quality';

          case 'roundTripTime':
            return 'Round trip time';

          case 'memoryUsed':
            return 'Memory used';

          case 'memoryUsedRaw':
            return 'Raw memory used';

          case 'memoryAvailableRaw':
            return 'Raw memory available';

          case 'batteryCharge':
            return 'Battery charge';

          case 'batteryState':
            return 'Battery state';
        }

        return id;
      }

      function formatValue(vitalId, d) {
        if (!d[vitalId]) {
          return 'no data';
        }

        switch (vitalId) {
          case 'roundTripTime':
            return d[vitalId].toFixed(1) + 's';

          case 'signalStrengthRaw':
            return d[vitalId].toFixed(0) + 'dBm';

          case 'signalQualityRaw':
            return d[vitalId].toFixed(0) + 'dBm';

          case 'memoryUsedRaw':
            return (d[vitalId] / 1024).toFixed(0) + 'kB';

          case 'memoryAvailableRaw':
            return (d[vitalId] / 1024).toFixed(0) + 'kB';

          case 'batteryState':
            switch (d[vitalId]) {
              case 'charged':
                return 'Charged';

              case 'charging':
                return 'Charging';

              case 'discharging':
                return 'Discharging';

              case 'disconnected':
                return 'Disconnected';

              case 'not_charging':
                return 'Not charging';
            }

            return 'Unknown';
        }

        return d[vitalId].toFixed(0) + (tickSuffix[id] || '%');
      }

      const startDate = new Date(this.get('start_date'));
      const endDate = new Date(this.get('end_date'));

      const x = _d.default.scaleTime().domain([startDate, endDate]).range([0, width]);

      const xAxis = g => g.attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top + height, ")")).call(_d.default.axisBottom(x).tickSizeOuter(0)); // .call(d3.axisBottom(x).tickSizeOuter(0).tickFormat(d3.timeFormat('%H:%M')));


      const y = _d.default.scaleLinear().domain((() => {
        if (domains[id]) {
          return [Math.floor(domains[id][0]) || 0, Math.ceil(domains[id][1]) || 100];
        }

        return [0, 100];
      })()).range([height, 0]);

      const yAxis = g => g.attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).call(_d.default.axisLeft(y).tickSizeOuter(0).ticks(6).tickFormat(d => d + (tickSuffix[id] || '%')));

      if (id === 'battery-charge') {
        svg.append('defs').html("\n\t\t\t\t\t<pattern id=\"pattern-stripe\"\n\t\t\t\t\t\twidth=\"4\" height=\"4\"\n\t\t\t\t\t\tpatternUnits=\"userSpaceOnUse\"\n\t\t\t\t\t\tpatternTransform=\"rotate(45)\">\n\t\t\t\t\t\t<rect width=\"2\" height=\"4\" transform=\"translate(0,0)\" fill=\"white\"></rect>\n\t\t\t\t\t</pattern>\n\t\t\t\t\t<mask id=\"mask-stripe\">\n\t\t\t\t\t\t<rect x=\"0\" y=\"0\" width=\"100%\" height=\"100%\" fill=\"url(#pattern-stripe)\" />\n\t\t\t\t\t</mask>\n\t\t\t\t");
        const times = [];
        let tStart = false;
        let tPrev = data[0] && data[0].date;

        for (const d of data) {
          if (d.batteryState === 'charging' && !tStart) {
            tStart = tPrev;
          }

          if (d.batteryState !== 'charging' && tStart) {
            times.push({
              start: tStart,
              end: tPrev
            });
            tStart = false;
          }

          tPrev = d.date;
        }

        if (tStart) {
          times.push({
            start: tStart,
            end: endDate
          });
        }

        for (const t of times) {
          svg.append('rect').attr('class', 'diagonalRect').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).attr('x', x(t.start)).attr('y', y(100)).attr('width', x(t.end) - x(t.start)).attr('height', y(0) - y(100)).style('fill', 'green');
        }
      }

      const area = _d.default.area().defined(d => !isNaN(d[vital])).curve(curveStyle).x(d => x(d.date)) // .y0(y(0))
      .y0((() => {
        if (domains[id]) {
          return y(Math.floor(domains[id][0]));
        }

        return y(0);
      })()).y1(d => y(d[vital]));

      svg.append('path').classed('area', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).datum(data).attr('d', area);

      const line = _d.default.line().defined(d => !isNaN(d[vital])).curve(curveStyle).x(d => x(d.date)).y(d => y(d[vital]));

      svg.append('path').classed('area-top-border', true).attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).datum(data).attr('d', line);
      svg.append('g').classed('axis', true).call(xAxis);
      svg.append('g').classed('axis', true).call(yAxis);
      const focus = svg.append('g').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).style('display', 'none');
      focus.append('circle').attr('class', 'y').style('fill', 'var(--primary-text-color)').style('stroke', 'var(--online-color)').attr('r', 4);
      svg.append('path').attr('class', 'mouseLine').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")")).style('stroke', 'black').style('stroke-width', '1px').style('opacity', '0');

      const bisectDate = _d.default.bisector(function (d) {
        return d.date;
      }).left;

      const dateFormatter = _d.default.timeFormat('%a %b %e at %H:%M');

      const tooltip = _d.default.select('.tooltip--main');

      svg.on('mouseover', function () {
        focus.style('display', null);
        tooltip.style('display', 'inline-block');

        _d.default.selectAll('.mouseLine').style('opacity', '.2');
      }).on('mouseout', function () {
        focus.style('display', 'none');
        tooltip.style('display', 'none');

        _d.default.selectAll('.mouseLine').style('opacity', '0');
      }).on('mousemove', function () {
        const x0 = x.invert(_d.default.mouse(this)[0] - margin.left);
        const i = bisectDate(data, x0, 1);
        const d0 = data[i - 1] || {};
        const d1 = data[i] || {};
        const d = x0 - d0.date > d1.date - x0 ? d1 : d0;

        if (d[vital]) {
          _d.default.select('.tooltip--date').html(dateFormatter(d.date));

          _d.default.select('.tooltip--vitals').selectAll('*').remove();

          for (const vitalId of vitalsForId) {
            _d.default.select('.tooltip--vitals').append('p').html("".concat(vitalName(vitalId), ": <b>").concat(formatValue(vitalId, d), "</b>"));
          }

          focus.select('circle.y').attr('transform', 'translate(' + x(d.date) + ',' + y(d[vital]) + ')'); // const rect = focus.select('circle.y').node().getBoundingClientRect();
          // tooltip
          // 	.style('left', (rect.x + 8) + 'px')
          // 	.style('top', (rect.y - 4) + 'px');

          tooltip.style('left', _d.default.event.clientX + 16 + 'px').style('top', _d.default.event.clientY + 'px');

          _d.default.selectAll('.mouseLine').attr('d', 'M' + x(d.date) + ',' + y.range()[0] + 'L' + x(d.date) + ',' + y.range()[1]);
        }
      });
    },

    doRequest(url) {
      const timePeriod = this.get('timePeriod');
      let start_date;
      let bucket_size;
      const end_date = new Date().toISOString();

      switch (timePeriod) {
        case 'hour':
          start_date = (0, _moment.default)().subtract(1, 'hour').toISOString();
          bucket_size = 60000; // 1 min = 60 intervals

          break;

        case 'day':
          start_date = (0, _moment.default)().subtract(1, 'day').toISOString();
          bucket_size = 900000; // 15 min = 96 intervals

          break;

        case '7-days':
          start_date = (0, _moment.default)().subtract(7, 'days').toISOString();
          bucket_size = 7200000; // 2 hours = 84 intervals

          break;

        case '15-days':
          start_date = (0, _moment.default)().subtract(15, 'days').toISOString();
          bucket_size = 14400000; // 4 hours = 90 intervals

          break;

        case '30-days':
          start_date = (0, _moment.default)().subtract(30, 'days').toISOString();
          bucket_size = 21600000; // 6 hours = 120 intervals

          break;

        default:
          start_date = (0, _moment.default)().subtract(1, 'hour').toISOString();
          bucket_size = 60000;
        // 1 min = 60 intervals
      }

      this.setProperties({
        start_date,
        end_date
      });
      Ember.$('.loading').show();
      return this.get('apiAjax').getRequest({
        url: "".concat(url, "?start_date=").concat(start_date, "&end_date=").concat(end_date, "&bucket_size=").concat(bucket_size)
      }).then(response => {
        const vitals = [];
        const hasBattery = Boolean(response.hasBattery);
        const memoryAvailable = Number(response.memoryAvailable);
        let batteryState = null;

        for (const sample of response.samples) {
          var _sample$roundTripTime;

          if (hasBattery) {
            batteryState = sample.batteryState;
          }

          vitals.push({
            date: new Date(sample.timestamp),
            signalStrength: sample.signalStrength && Number(sample.signalStrength.avg),
            signalQuality: sample.signalQuality && Number(sample.signalQuality.avg),
            roundTripTime: Number((_sample$roundTripTime = sample.roundTripTime) === null || _sample$roundTripTime === void 0 ? void 0 : _sample$roundTripTime.avg) / 1000,
            memoryUsed: sample.memoryUsed && Number(sample.memoryUsed.avg) / memoryAvailable * 100,
            batteryCharge: hasBattery && sample.batteryCharge && Number(sample.batteryCharge.avg),
            signalStrengthRaw: sample.signalStrengthValue && Number(sample.signalStrengthValue.avg),
            signalQualityRaw: sample.signalQualityValue && Number(sample.signalQualityValue.avg),
            memoryUsedRaw: sample.memoryUsed && Number(sample.memoryUsed.avg),
            memoryAvailableRaw: memoryAvailable,
            batteryState: batteryState
          });
        }

        vitals.sort((a, b) => new Date(a.date) - new Date(b.date));
        this.set('vitals', vitals);
        this.updateGraphs();
        let noSignalStrength = true;
        let noSignalQuality = true;
        let noRoundTripTime = true;
        let noMemoryUsage = true;
        let noBatteryCharge = true;

        for (const vital of vitals) {
          if (vital.signalStrength) {
            noSignalStrength = false;
          }

          if (vital.signalQuality) {
            noSignalQuality = false;
          }

          if (vital.roundTripTime) {
            noRoundTripTime = false;
          }

          if (vital.memoryUsed) {
            noMemoryUsage = false;
          }

          if (vital.batteryCharge) {
            noBatteryCharge = false;
          }
        }

        this.setProperties({
          noSignalStrength,
          noSignalQuality,
          noRoundTripTime,
          noMemoryUsage,
          noBatteryCharge
        });
        Ember.$('.loading').hide();
      }).catch(error => {
        console.error(error);
        this.get('notify').error('Problem with fetching vitals history.');
        Ember.$('.loading').hide();
      });
    },

    trackEngagement(name, value) {
      const product = this.get('routeData.product');
      const deviceId = this.get('device.id');

      if (name === 'refresh') {
        this.get('segment').trackEvent('device-vitals-refresh-clicked', {
          product,
          deviceId
        });
      } else {
        this.get('segment').trackEvent('device-vitals-filter-set', {
          product,
          deviceId,
          filter: name,
          value: value
        });
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.send('getDiagnostics');
    },

    actions: {
      getDiagnostics() {
        const product = this.get('routeData.product');
        const deviceId = this.get('device').get('id');
        const url = product ? "/v1/products/".concat(product, "/diagnostics/").concat(deviceId, "/summary") : "/v1/diagnostics/".concat(deviceId, "/summary");
        this.doRequest(url);
      },

      onRefresh() {
        this.send('getDiagnostics');
        this.trackEngagement('refresh');
      },

      onTimeSelect(event) {
        this.set('timePeriod', event.target.value);
        this.send('getDiagnostics');
        this.trackEngagement('time-period', event.target.value);
      }

    }
  });

  _exports.default = _default;
});