define("pconsole/routes/product/sims/reactivate", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return Ember.RSVP.hash({
        platform: this.modelFor('product.sims').product.get('platform'),
        sim: this.store.find('sim', params.iccid),
        sims: this.modelFor('product.sims').sims,
        subscription: this.modelFor('product.sims').subscription
      });
    }

  });

  _exports.default = _default;
});