define("pconsole/components/asset-tracker-map/highlighted-device-layer", ["exports", "react", "prop-types", "pconsole/utils/average-group-color", "react-mapbox-gl"], function (_exports, _react, _propTypes, _averageGroupColor, _reactMapboxGl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const HighlightedDeviceLayer = ({
    devices,
    highlightedDevice
  }) => {
    const highlightedDevicesItem = devices && devices.length && devices.find(d => d.id === highlightedDevice);
    let sourceOpts;

    if (!highlightedDevicesItem) {
      return null;
    }

    if (highlightedDevicesItem) {
      const {
        foldedGeometry,
        geometry,
        groups
      } = highlightedDevicesItem;
      const geometryType = geometry.type;
      const coordinates = geometryType === 'Point' ? geometry.coordinates : foldedGeometry.coordinates.slice().pop();
      sourceOpts = {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates
          },
          properties: {
            color: (0, _averageGroupColor.default)(groups)
          }
        }
      };
    } // Does not support CSS variables colors added manually


    const paintOpts = {
      'circle-color': ['coalesce', ['get', 'color'], '#00d2e6'],
      // Online color
      'circle-radius': 12,
      'circle-stroke-color': '#565a56',
      // Primary border color
      'circle-stroke-width': 2
    };
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactMapboxGl.Source, {
      id: 'highlightedDevice',
      geoJsonSource: sourceOpts
    }), _react.default.createElement(_reactMapboxGl.Layer, {
      type: 'circle',
      sourceId: 'highlightedDevice',
      paint: paintOpts
    }));
  };

  HighlightedDeviceLayer.propTypes = {
    devices: _propTypes.default.array,
    highlightedDevice: _propTypes.default.string
  };
  var _default = HighlightedDeviceLayer;
  _exports.default = _default;
});