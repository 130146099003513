define("pconsole/templates/components/asset-tracker-consent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HE4SbV1h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"product\",\"platform\",\"isAssetTracker\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui field\"],[7],[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"outside\"],[7],[0,\"\\n      \"],[6,\"input\"],[9,\"type\",\"checkbox\"],[10,\"checked\",[20,[\"product\",\"settings\",\"location\",\"opt_in\"]],null],[10,\"onchange\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"product\",\"settings\",\"location\",\"opt_in\"]]],null]],[[\"value\"],[\"target.checked\"]]],null],[7],[8],[0,\"\\n      I consent to the storage of Asset Tracker device geolocation data in the\\n      Particle Device Cloud. For more information on how Particle protects your\\n      data, please review our\\n      \"],[6,\"a\"],[9,\"href\",\"https://www.particle.io/legal/privacy/\"],[9,\"target\",\"_blank\"],[7],[0,\"Privacy Policy\"],[8],[0,\".\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/asset-tracker-consent.hbs"
    }
  });

  _exports.default = _default;
});