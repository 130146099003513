define("pconsole/initializers/register-showdown-externsions", ["exports", "showdown", "showdown-xss-filter"], function (_exports, _showdown, _showdownXssFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _showdown.default.extension('xssfilter', _showdownXssFilter.default);
  }

  var _default = {
    name: 'register-showdown-externsions',
    initialize
  };
  _exports.default = _default;
});