define("pconsole/initializers/route-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = _exports.RouteData = void 0;
  const RouteData = Ember.Object.extend({
    product: '',
    network: '',
    org: '',
    ledger: '',
    isLogic: false,
    hasProduct: Ember.computed('product', function () {
      const product = Ember.get(this, 'product');
      return !!product;
    }),
    hasOrg: Ember.computed('org', function () {
      return !!Ember.get(this, 'org');
    }),
    logicRoutes: Ember.computed('hasOrg', 'hasProduct', function () {
      const hasProduct = Ember.get(this, 'hasProduct');
      const hasOrg = Ember.get(this, 'hasOrg');
      let basePath = '';

      if (hasProduct) {
        basePath = 'product';
      } else if (hasOrg) {
        basePath = 'organization';
      } else {
        basePath = 'user';
      }

      return {
        list: "".concat(basePath, ".logic.index"),
        create: "".concat(basePath, ".logic-functions.create"),
        edit: "".concat(basePath, ".logic-functions.edit"),
        details: "".concat(basePath, ".logic-functions.details"),
        delete: "".concat(basePath, ".logic-functions.details.delete"),
        templateList: "".concat(basePath, ".logic.templates"),
        templateShow: "".concat(basePath, ".logic.templates.show")
      };
    }),
    ledgerRoutes: Ember.computed('hasOrg', function () {
      const hasOrg = Ember.get(this, 'hasOrg');
      const basePath = hasOrg ? 'organization' : 'user';
      return {
        list: "".concat(basePath, ".ledger"),
        create: "".concat(basePath, ".ledger.create"),
        categories: "".concat(basePath, ".ledger.categories"),
        show: "".concat(basePath, ".ledger.show"),
        archive: "".concat(basePath, ".ledger.index.archive"),
        showArchive: "".concat(basePath, ".ledger.show.archive"),
        instance: "".concat(basePath, ".ledger.show.instance"),
        createInstance: "".concat(basePath, ".ledger.show.new-instance"),
        deleteInstance: "".concat(basePath, ".ledger.show.delete-instance")
      };
    }),

    assignRouteData(transition) {
      const params = transition.params;
      const product = params.product;
      const org = params.organization;
      const network = params['user.networks.network'];
      const logic = params['organization.logic'] || params['user.logic'];
      const logicFunctions = params['organization.logic-functions'] || params['user.logic-functions'];
      const ledger = params['organization.ledger.show'] || params['user.ledger.show'];

      if (product) {
        this.set('product', product.product_slug);
      } else {
        this.set('product', undefined);
      }

      if (org) {
        this.set('org', org.org_id);
      } else {
        this.set('org', undefined);
      }

      if (network) {
        this.set('network', network.network_name);
      } else {
        this.set('network', undefined);
      }

      this.set('ledger', ledger === null || ledger === void 0 ? void 0 : ledger.ledger_name);
      this.set('isLogic', !!(logic || logicFunctions));
    }

  });
  _exports.RouteData = RouteData;

  function initialize(application) {
    const routeData = RouteData.create(); // Register the routeData factory on the container

    application.register('routeData:main', routeData, {
      instantiate: false
    }); // Inject the routeData factory throughout the app

    application.inject('route', 'routeData', 'routeData:main');
    application.inject('controller', 'routeData', 'routeData:main');
    application.inject('component', 'routeData', 'routeData:main');
    application.inject('adapter', 'routeData', 'routeData:main');
    application.inject('serializer', 'routeData', 'routeData:main');
    application.inject('service', 'routeData', 'routeData:main');
    application.inject('ember-uploader', 'routeData', 'routeData:main');
  }

  var _default = {
    name: 'routeData',
    initialize: initialize
  };
  _exports.default = _default;
});