define("pconsole/mixins/integration-devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    integrationDeviceSelection: null,
    integrationDeviceSelectionObserver: Ember.observer('integrationDeviceSelection', function () {
      const integrationDeviceSelection = this.get('integrationDeviceSelection');

      if (integrationDeviceSelection === 'any-user-owned-device') {
        this.set('integration.deviceID', undefined);
      } else {
        this.set('integration.deviceID', integrationDeviceSelection);
      }
    }),
    integrationDeviceSelectionInitializer: Ember.observer('integration.deviceID', function () {
      const deviceID = this.get('integration.deviceID');

      if (deviceID) {
        this.set('integrationDeviceSelection', deviceID);
      } else {
        this.set('integrationDeviceSelection', 'any-user-owned-device');
      }
    }),
    integrationDevices: Ember.computed('model.devices', function () {
      const modelDevices = this.get('model.devices');
      let devicesArray = [];

      if (modelDevices) {
        devicesArray = this.get('model.devices').toArray();
      }

      const anyDevice = {
        'name': 'Any',
        'id': 'any-user-owned-device'
      };
      devicesArray.insertAt(0, anyDevice);
      return devicesArray;
    })
  });

  _exports.default = _default;
});