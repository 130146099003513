define("pconsole/components/per-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    pageOptions: ['25', '50', '100', '200'],
    supportCustomPerPageQueries: function () {
      const perPage = this.get('perPage');
      const pageOptions = this.get('pageOptions');

      if (perPage && !pageOptions.includes(perPage)) {
        pageOptions.pushObject(perPage);
        this.set('perPageCustom', perPage);
      }
    }.on('init'),
    actions: {
      changePerPage(recordsPerPage) {
        this.set('perPage', recordsPerPage);
        this.sendAction('action', recordsPerPage);
      }

    },

    willDestroyElement() {
      const pageOptions = this.get('pageOptions');
      const perPageCustom = this.get('perPageCustom');

      if (perPageCustom) {
        pageOptions.popObject(perPageCustom);
      }

      this._super();
    }

  });

  _exports.default = _default;
});