define("pconsole/adapters/logic-run", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('routeData.org', function () {
      const org = this.get('routeData.org');

      if (org) {
        return "v1/orgs/".concat(org);
      }

      return 'v1';
    }),

    urlForQuery(query) {
      const {
        logicFunctionId
      } = query;
      delete query.logicFunctionId;
      return "".concat(this.urlPrefix(), "/logic/functions/").concat(logicFunctionId, "/runs");
    }

  });

  _exports.default = _default;
});