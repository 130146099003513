define("pconsole/helpers/is-current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isCurrentUser = isCurrentUser;
  _exports.default = void 0;

  function isCurrentUser(params
  /*, hash*/
  ) {
    const user = params[0];
    const session = params[1];

    if (!user || !session) {
      return false;
    }

    const currentUsername = user.get('username');
    const sessionUsername = session.get('data.authenticated.username');
    return currentUsername === sessionUsername;
  }

  var _default = Ember.Helper.helper(isCurrentUser);

  _exports.default = _default;
});