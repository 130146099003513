define("pconsole/controllers/product/map/index/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSaving: false,
    parentRoute: null,
    apiAjax: Ember.inject.service(),
    configuration: Ember.computed.oneWay('model.configuration'),
    product: Ember.computed.readOnly('model.product'),
    notify: Ember.inject.service(),
    schema: Ember.computed.readOnly('model.schema'),
    metaschema: Ember.computed.readOnly('model.metaschema'),
    segment: Ember.inject.service(),
    actions: {
      closeModal() {
        this.transitionToRoute(Ember.get(this, 'parentRoute'));
      },

      saveAssetTrackerFleetSettings({
        formData
      }) {
        const apiAjax = Ember.get(this, 'apiAjax');
        const notify = Ember.get(this, 'notify');
        const productId = Ember.get(this, 'product.id');
        const configUrl = "/v1/products/".concat(productId, "/config");
        const config = formData; // FIXME (@mossymaker): a config key appears in the formData object with
        // a value that duplicates the other fields

        delete config.config;
        Ember.set(this, 'isSaving', true);
        Ember.set(this, 'model.configuration', formData);
        Ember.get(this, 'segment').trackEvent('configuration saved');
        apiAjax.putRequest({
          url: configUrl,
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(config)
        }).done(() => {
          notify.success('Fleet configuration updated');
          this.transitionToRoute(Ember.get(this, 'parentRoute'));
        }).fail(xhr => {
          const {
            error_description
          } = xhr.responseJSON;

          if (error_description) {
            notify.error(error_description);
          } else {
            notify.error('There was a problem saving that fleet configuration');
          }
        }).always(() => {
          Ember.set(this, 'isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});