define("pconsole/components/device-groups-input", ["exports", "react", "prop-types", "pconsole/components/color-swatch", "react-power-select", "react-power-select/dist/react-power-select.css"], function (_exports, _react, _propTypes, _colorSwatch, _reactPowerSelect, _reactPowerSelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  const DeviceGroupsInput = props => {
    const {
      groups,
      placeholder = "Filter by groups"
    } = props;
    return _react.default.createElement(_reactPowerSelect.PowerSelectMultiple, _extends({
      options: groups,
      placeholder: placeholder,
      optionComponent: ({
        option
      }) => _react.default.createElement('div', null, _react.default.createElement(_colorSwatch.default, {
        color: option.color
      }), ' ', option.name),
      selectedOptionComponent: ({
        option
      }) => _react.default.createElement('span', null, _react.default.createElement(_colorSwatch.default, {
        color: option.color
      }), ' ', option.name)
    }, props));
  };

  DeviceGroupsInput.propTypes = {
    groups: _propTypes.default.array.isRequired,
    onChange: _propTypes.default.func.isRequired,
    placeholder: _propTypes.default.string
  };
  var _default = DeviceGroupsInput;
  _exports.default = _default;
});