define("pconsole/controllers/user/integrations/google-maps/show", ["exports", "pconsole/mixins/grouped-integration"], function (_exports, _groupedIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_groupedIntegration.groupedIntegrationNotification, {});

  _exports.default = _default;
});