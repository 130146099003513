define("pconsole/validators/local/existing-model", ["exports", "ember-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    call() {
      const id = this.model.get(this.property);

      if (!id) {
        return null;
      }

      const existingModel = this.get('store').peekRecord(this.options.modelName, id);

      if (existingModel && existingModel !== this.model) {
        this.errors.pushObject(this.options.message);
      }
    }

  });

  _exports.default = _default;
});