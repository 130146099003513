define("pconsole/models/section", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Section = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    questions: _emberData.default.hasMany('question', {
      async: true
    }),
    isReadOnly: _emberData.default.attr('boolean'),
    isViewMode: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isExpanded: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isDone: Ember.computed('questions.@each.isDone', function () {
      const questions = this.get('questions');
      return questions.isEvery('isDone', true) && questions.get('length') > 0;
    }),
    numberDone: Ember.computed('questions.@each.isDone', function () {
      return this.get('questions').filterBy('isDone', true).length;
    })
  });

  Section.reopenClass({
    FIXTURES: [{
      id: 1,
      title: 'Overview',
      description: 'General information about your product',
      questions: ['name', 'description', 'id', 'platformId', 'hardwareVersion', 'type'],
      isReadOnly: true,
      isViewMode: true
    }, {
      id: 2,
      title: 'Working with Particle',
      description: 'Determine if and how much Particle will be visible to your customers',
      questions: ['whitelabel', 'authentication', 'loginReuse', 'dataHosting']
    }, {
      id: 3,
      title: 'Customers',
      description: 'Choose the level of control your customers have over their devices',
      questions: ['privateBeta', 'customerSharing', 'sharingAccess', 'customerAPIAccess', 'customerReprogrammable', 'thirdPartyAPIAccess']
    }, {
      id: 4,
      title: 'Firmware',
      description: 'Decide how firmware will be handled during manufacturing and beyond',
      questions: ['manufacturingFirmware', 'unrecognizedDevice'] // {
      //   id: 5,
      //   title: 'Mobile',
      //   description: 'Set preferences for companion mobile applications'
      // }

    }]
  });
  var _default = Section;
  _exports.default = _default;
});