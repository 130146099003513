define("pconsole/components/firmware-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: 'lock-component',
    hovering: false,

    mouseEnter() {
      this.set('hovering', true);
    },

    mouseLeave() {
      this.set('hovering', false);
    },

    actions: {
      unlock(device) {
        this.sendAction('unlock', device);
      }

    }
  });

  _exports.default = _default;
});