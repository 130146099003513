define("pconsole/mixins/sim/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // This displays the loading spinner
    // while the decently long data usage
    // query is sent to the server
    beforeModel() {
      Ember.$('.loading').show();
    },

    afterModel() {
      Ember.$('.loading').hide();
    },

    model(params) {
      return Ember.RSVP.hash({
        sim: this.store.find('sim', params.iccid),
        sims: this.modelFor('product.sims').sims
      });
    },

    rollbackDirtyAttributes: function () {
      this.currentModel.sim.rollbackAttributes();
    }.on('deactivate')
  });

  _exports.default = _default;
});