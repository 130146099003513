define("pconsole/components/asset-tracker-configuration-form", ["exports", "react", "prop-types", "@rjsf/core", "styled-components", "pconsole/components/toast"], function (_exports, _react, _propTypes, _core, _styledComponents, _toast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  /**
   * Generates a form from the given fleet configuration schema and accepts
   * input to submit to the cloud
   *
   * @example
   * ```jsx
   * <AssetTrackerConfigurationForm schema={schema}/>
   * ```
   */
  const AssetTrackerConfigurationForm = props => {
    const {
      docsURL,
      formData,
      metaschema,
      schema: {
        properties
      }
    } = props;
    const propertyKeys = Object.keys(properties);
    const [activeTab, setActiveTab] = (0, _react.useState)(propertyKeys[0]);
    const configuration = categorizeSettings(props.schema, formData);
    const uiSchema = {}; // some configuration modules require the device to have a certain firmware version

    let activeTabProps = properties[activeTab] || {};
    let firmwareVersionUnmet = false,
        firmwareVersionMessage;

    if (!props.fleetSettings && props.firmwareVersion) {
      if (activeTabProps.minimumFirmwareVersion && props.firmwareVersion < activeTabProps.minimumFirmwareVersion) {
        firmwareVersionUnmet = true;
        firmwareVersionMessage = _react.default.createElement('div', null, 'Upgrade device to firmware v$', activeTabProps.minimumFirmwareVersion, ' or higher to use this feature', _react.default.createElement('br', null), 'See ', _react.default.createElement('a', {
          href: 'https://docs.particle.io/tutorials/asset-tracking/tracker-edge-firmware/',
          target: '_blank'
        }, 'docs'), ' for how to update');
      }

      if (activeTabProps.maximumFirmwareVersion && props.firmwareVersion > activeTabProps.maximumFirmwareVersion) {
        firmwareVersionUnmet = true;
        firmwareVersionMessage = "This feature is no longer available on devices with firmware v".concat(activeTabProps.maximumFirmwareVersion, " or higher");
      }
    }

    let disabled;

    if (props.fleetSettings) {
      disabled = activeTabProps.deviceLevelOnly;
    } else {
      disabled = !activeTabProps.deviceLevelOnly && !props.isDevelopment || firmwareVersionUnmet;
    }

    propertyKeys.filter(k => k !== activeTab).forEach(key => {
      uiSchema[key] = {
        classNames: 'hidden'
      };
    });

    function renderTab(key) {
      if (props.fleetSettings && properties[key].deviceLevelOnly) {
        return;
      }

      return _react.default.createElement(TabButton, {
        key: key,
        role: 'tab',
        'aria-controls': "root_".concat(key),
        'aria-selected': activeTab === key,
        tabindex: activeTab === key ? 0 : -1,
        onClick: () => setActiveTab(key)
      }, properties[key].title || key, properties[key].deviceLevelOnly && _react.default.createElement(StyledTag, null, 'Device'));
    }

    function renderTabLink() {
      return _react.default.createElement(TabButton, {
        as: 'a',
        tabindex: '-1',
        target: '_blank',
        rel: 'noopener',
        href: docsURL
      }, 'Docs');
    }

    function renderDisallowedConfigsAlert(disallowedConfigs) {
      const keys = disallowedConfigs ? Object.keys(disallowedConfigs) : [];

      if (!keys.length) {
        return false;
      }

      return _react.default.createElement(ToastContainer, null, _react.default.createElement(_toast.default, {
        type: 'warning',
        subject: 'Detected invalid settings',
        message: "The following settings modules were sent by this device but are not supported by your schema: ".concat(keys.join(', '))
      }));
    }

    function filterDisallowedFields({
      formData
    }) {
      const {
        allowed
      } = categorizeSettings(props.schema, formData);
      props.onSubmit({
        formData: allowed
      });
    }

    function renderFirmwareVersionmessage() {
      if (!firmwareVersionUnmet) {
        return false;
      }

      return _react.default.createElement(ToastContainer, null, _react.default.createElement(_toast.default, {
        type: 'warning',
        subject: 'Firmware version not compatible',
        message: firmwareVersionMessage
      }));
    }

    return _react.default.createElement(_react.Fragment, null, propertyKeys.map(renderTab), docsURL && renderTabLink(), renderDisallowedConfigsAlert(configuration.disallowed), renderFirmwareVersionmessage(), _react.default.createElement(StyledForm, _extends({
      showErrorList: false,
      uiSchema: uiSchema,
      additionalMetaSchemas: [metaschema]
    }, props, {
      disabled: disabled,
      onSubmit: filterDisallowedFields
    }), !props.fleetSettings ? _react.default.createElement('button', {
      type: 'submit',
      className: "ui primary outline button ".concat(props.isSaving ? 'loading' : ''),
      disabled: disabled
    }, 'Save') : props.children));
  };

  function categorizeSettings(schema, formData) {
    const configuration = {
      allowed: {},
      disallowed: {}
    };
    const properties = schema.properties;

    for (const key in formData) {
      if (properties[key] && formData[key]) {
        configuration.allowed[key] = formData[key];
      } else {
        configuration.disallowed[key] = formData[key];
      }
    }

    return configuration;
  }

  const StyledTag = _styledComponents.default.span.withConfig({
    displayName: "asset-tracker-configuration-form__StyledTag",
    componentId: "sc-7aauil-0"
  })(["margin-left:10px;padding:0 6px;border-radius:3px;font-size:12px;background-color:var(--quaternary-background-color);border:1px solid var(--quaternary-border-color);text-transform:none;"]);

  const TabButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-configuration-form__TabButton",
    componentId: "sc-7aauil-1"
  })(["background-color:transparent;border:none;font-weight:bold;margin-right:2.4rem;padding:0.4rem 0;text-transform:uppercase;&[aria-selected=\"true\"]{border-bottom:3px solid var(--primary-border-color);}&:not([aria-selected=true]):hover{border-bottom 3px solid var(--tertiary-border-color);}"]);

  const ToastContainer = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-configuration-form__ToastContainer",
    componentId: "sc-7aauil-2"
  })(["padding-top:2.4rem;> div[type=\"error\"]{max-width:100%;}"]);

  const StyledForm = (0, _styledComponents.default)(_core.default).withConfig({
    displayName: "asset-tracker-configuration-form__StyledForm",
    componentId: "sc-7aauil-3"
  })(["margin-top:2.4rem !important;.hidden{height:0;visibility:hidden;width:0;}fieldset{background-color:transparent;border:1px solid var(--primary-border-color);border-radius:4px;}> .form-group > fieldset,> .form-group > fieldset > .form-group > fieldset{border:none;margin:0;padding:0;}legend{font-size:1.2em;}> .form-group > fieldset > legend{display:none;}> .form-group > fieldset > div > fieldset > legend{height:0;visibility:hidden;}input:disabled,select:disabled,textarea:disabled{background-color:var(--disabled-background-color);};select{border-radius:4px border:1px solid var(--primary-border-color);height:50px;background:transparent;padding-bottom:0;padding-left:8px;width:100%;}.error-detail{color:var(--danger-color);list-style-type:none;margin-bottom:1em;margin-top:-1em;padding-left:0;}"]);
  AssetTrackerConfigurationForm.propTypes = {
    fleetSettings: _propTypes.default.bool,
    firmwareVersion: _propTypes.default.number,
    isDevelopment: _propTypes.default.bool,
    isSaving: _propTypes.default.bool,
    schema: _propTypes.default.object,
    metaschema: _propTypes.default.object,
    formData: _propTypes.default.object,
    docsURL: _propTypes.default.string,
    onSubmit: _propTypes.default.func
  };
  var _default = AssetTrackerConfigurationForm;
  _exports.default = _default;
});