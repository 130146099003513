define("pconsole/templates/organization/logic/templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vj/0NVzM",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[1,[25,\"bread-crumbs\",null,[[\"tagName\",\"linkable\"],[\"ul\",true]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"logic-templates-page\"],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[0,\"Get started with Logic Functions\"],[8],[0,\"\\n  \"],[6,\"h5\"],[9,\"class\",\"dashboard-subtitle\"],[7],[0,\"\\n    Pick a template that matches your cloud-side Function use case, customize it, execute it and deploy it.\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"template-list\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"gallery-card\",null,[[\"name\",\"shortDescription\",\"buttonText\",\"onSelect\"],[[19,1,[\"name\"]],[19,1,[\"shortDescription\"]],\"Customize\",[25,\"action\",[[19,0,[]],\"showTemplate\",[19,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[25,\"feedback-overlay\",null,[[\"surveyName\"],[\"Logic Feedback\"]]],false],[0,\"\\n\"],[1,[18,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/logic/templates.hbs"
    }
  });

  _exports.default = _default;
});