define("pconsole/routes/user/billing", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'user/sandbox-billing',
    controllerName: 'user/sandbox-billing',
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    usageAlerts: Ember.inject.service(),

    async model() {
      const applicationController = this.controllerFor('application'); // hide usage alerts for this service agreement because they are
      // shown on this page as well

      const usageAlerts = Ember.get(this, 'usageAlerts');
      usageAlerts.markShownForServiceAgreement(Ember.get(applicationController, 'currentServiceAgreement')); // When the user has a `developer` type service agreement, query products

      if (Ember.get(applicationController, 'hasDeveloperServiceAgreement')) {
        const products = await this.store.query('product', {});
        const username = this.get('session.data.authenticated.username');
        return {
          products: products.toArray().filter(p => Ember.get(p, 'user') === username).map(p => ({
            id: p.id,
            name: Ember.get(p, 'name'),
            slug: Ember.get(p, 'slug')
          }))
        };
      } else {
        // redirect back to devices page and log an error in Sentry
        throw new Error('User is missing Sandbox service agreement');
      }
    },

    actions: {
      refreshModel() {
        this.refresh();
      },

      error(err) {
        console.error(err);
        Sentry.captureException(err);
        this.transitionTo('user.devices').then(() => {
          this.get('notify').warning('Your billing info is not available at this time. Please check back in a few moments.');
        });
      }

    }
  });

  _exports.default = _default;
});