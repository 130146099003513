define("pconsole/helpers/cron-description", ["exports", "cronstrue"], function (_exports, _cronstrue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cronDescription = cronDescription;
  _exports.default = void 0;

  function cronDescription([cron]) {
    try {
      return _cronstrue.default.toString("0 ".concat(cron));
    } catch (_unused) {
      return '...';
    }
  }

  var _default = Ember.Helper.helper(cronDescription);

  _exports.default = _default;
});