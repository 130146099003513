define("pconsole/helpers/date-age", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateAge = dateAge;
  _exports.default = void 0;

  function dateAge(params) {
    const moment = window.moment;
    const date = params[0];
    return moment(date).fromNow();
  }

  var _default = Ember.Helper.helper(dateAge);

  _exports.default = _default;
});