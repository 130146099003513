define("pconsole/components/logic-runs-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apiAjax: Ember.inject.service(),
    classNameBindings: ['isLoading:is-loading'],
    counters: [],
    isLoading: true,
    isEmpty: undefined,
    classNames: ['history-graph', 'logic-runs-graph'],

    async didInsertElement() {
      this._super(...arguments);

      const logicFunction = Ember.get(this, 'logicFunction');
      const org = Ember.get(this, 'organization');
      const product = Ember.get(this, 'product');
      this.set('isLoading', true);
      let parentPath = '';

      if (product) {
        parentPath = "/products/".concat(product.get('slug'));
      } else if (org) {
        parentPath = "/orgs/".concat(org.get('slug'));
      }

      const url = "/v1".concat(parentPath, "/logic/functions/").concat(logicFunction.id, "/stats");
      const {
        logic_function_stats: stats
      } = await this.get('apiAjax').request({
        url
      });
      this.set('isLoading', false);
      this.set('isEmpty', stats.length === 0);
      this.set('counters', stats);
    }

  });

  _exports.default = _default;
});