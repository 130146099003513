define("pconsole/mixins/routes/logic-functions/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LogicFunctionsDetailsRoute = void 0;
  const LogicFunctionsDetailsRoute = {
    templateName: 'organization.logic-functions.details',
    controllerName: 'organization.logic-functions.details',
    breadCrumb: {
      title: 'View Logic Function',
      linkable: false
    },

    async model({
      logic_function_id: logicFunctionId
    }) {
      const owner = Ember.get(this, 'owner');
      const templates = await this.store.findAll('logic-template');
      let products = [];
      let product;
      let organization;

      if (owner === 'organization') {
        organization = this.modelFor('organization').organization;
        products = this.modelFor('organization').products;
      } else if (owner === 'product') {
        product = this.modelFor('product').product;
        organization = product.get('organization');
        products = organization ? await this.store.query('product', {
          organization: organization.get('id')
        }) : products = await this.store.findAll('product');
      } else {
        products = await this.store.findAll('product');
      }

      const logicFunction = await this.store.findRecord('logic-function', logicFunctionId);
      const logicRuns = await this.store.query('logic-run', {
        logicFunctionId
      });
      return {
        logicFunction,
        logicRuns,
        organization,
        products,
        product,
        templates,
        owner
      };
    }

  };
  _exports.LogicFunctionsDetailsRoute = LogicFunctionsDetailsRoute;
});