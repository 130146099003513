define("pconsole/components/batch-actions-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Checkbox.extend({
    options: [],
    selection: Ember.computed.filterBy('options', 'isChecked', true),
    checked: Ember.computed('options.@each.isChecked', function () {
      const options = this.get('options');
      const selection = this.get('selection');

      if (!selection.length) {
        return false;
      } else if (options.length === selection.length) {
        return true;
      } else {
        return false;
      }
    }),
    indeterminate: Ember.computed('options.@each.isChecked', function () {
      const options = this.get('options');
      const selection = this.get('selection');

      if (selection.length > 0 && selection.length !== options.length) {
        return true;
      } else {
        return false;
      }
    }),

    change() {
      const options = this.get('options');

      if (this.get('checked')) {
        // Deselect everything
        options.setEach('isChecked', false);
      } else {
        // Select everything
        options.setEach('isChecked', true);
      }
    }

  });

  _exports.default = _default;
});