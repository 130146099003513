define("pconsole/templates/components/organization-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VgPoWLFY",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select\",null,[[\"triggerId\",\"tagName\",\"class\",\"selected\",\"onchange\",\"options\",\"renderInPlace\",\"searchEnabled\",\"noMatchesMessageComponent\",\"noMatchesMessage\"],[\"organization-selector\",\"div\",[25,\"concat\",[\"ui field power-select organization-selector \",[25,\"if\",[[25,\"eq\",[[20,[\"orgs\",\"length\"]],0],null],\"organization-selector--empty\"],null]],null],[20,[\"currentSpace\"]],[25,\"action\",[[19,0,[]],\"select\"],null],[20,[\"orgOptions\"]],true,false,\"partials/no-orgs-option\",\"partials/no-orgs-option\"]],{\"statements\":[[4,\"if\",[[25,\"eq\",[[19,1,[]],\"noOrgs\"],null]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"no-orgs\"],[7],[0,\"\\n      \"],[6,\"span\"],[7],[0,\"\\n        Seamlessly collaborate across many projects at once, with enhanced team management and administrative features.\\n      \"],[8],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"ui button outline primary\"],[7],[0,\"Request a demo\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"if\",[[19,1,[\"name\"]],[19,1,[\"name\"]],[19,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/organization-selector.hbs"
    }
  });

  _exports.default = _default;
});