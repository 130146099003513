define("pconsole/templates/components/logic-run-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BI6vmIBu",
    "block": "{\"symbols\":[\"log\",\"part\",\"index\"],\"statements\":[[6,\"div\"],[7],[0,\"\\n\"],[6,\"pre\"],[9,\"class\",\"logic-run-logs__container\"],[7],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"logic-run-logs__error\"],[7],[1,[20,[\"runLog\",\"err\"]],false],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"runLog\",\"logs\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"showTimestamps\"]]],null,{\"statements\":[[6,\"span\"],[9,\"class\",\"logic-run-log__timestamp\"],[7],[1,[25,\"format-date\",[[19,1,[\"timestamp\"]],\"h:mm:ss.SSS A\"],null],false],[8],[0,\" \"]],\"parameters\":[]},null],[4,\"each\",[[19,1,[\"args\"]]],null,{\"statements\":[[4,\"if\",[[19,3,[]]],null,{\"statements\":[[0,\" \"]],\"parameters\":[]},null],[1,[19,2,[]],false]],\"parameters\":[2,3]},null],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,[18,\"noLogsMessage\"],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[6,\"label\"],[9,\"class\",\"for-checkbox\"],[7],[0,\"\\n\"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showTimestamps\"]]]]],false],[0,\"\\n\"],[6,\"span\"],[7],[0,\"Show timestamps\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/logic-run-logs.hbs"
    }
  });

  _exports.default = _default;
});