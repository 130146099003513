define("pconsole/components/integration-form-top-rail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tab-container'],
    actions: {
      displayInstructions() {
        this.sendAction();
      }

    }
  });

  _exports.default = _default;
});