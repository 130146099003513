define("pconsole/routes/user/device/unclaim-device", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductUnclaimDeviceRoute = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    templateName: 'product/devices/unclaim-device',
    controllerName: 'product/devices/unclaim-device',
    breadCrumb: null,

    model() {
      return this.modelFor('user.device').device;
    }

  });
  var _default = ProductUnclaimDeviceRoute;
  _exports.default = _default;
});