define("pconsole/templates/organization/team/invite-team-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S91pqJlb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title\"],[7],[0,\"Invite Organization Team Member\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Before inviting a new team member, please \"],[6,\"strong\"],[7],[0,\"make sure they already have a Particle account\"],[8],[0,\". An invitation will be sent to the email address entered below. For additional info, \"],[6,\"a\"],[9,\"href\",\"https://docs.particle.io/tutorials/device-cloud/console/#adding-team-members\"],[9,\"target\",\"_blank\"],[7],[0,\"check out our docs\"],[8],[8],[0,\"\\n\"],[4,\"form-wrapper\",null,null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"invite-with-role\"],[7],[0,\"\\n      \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"placeholder\"],[\"model.username\",\"Email address\",\"name@example.com\"]]],false],[0,\"\\n      \"],[1,[25,\"role-dropdown\",null,[[\"product\",\"roles\",\"model\",\"onUpdate\"],[[20,[\"product\"]],[20,[\"roles\"]],[20,[\"model\"]],\"updateOrgUserRole\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[1,[25,\"form-submission\",null,[[\"saveText\",\"cancel\",\"disabled\"],[\"Invite Team Member\",false,[20,[\"isInvalid\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/team/invite-team-member.hbs"
    }
  });

  _exports.default = _default;
});