define("pconsole/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X/3lPrm7",
    "block": "{\"symbols\":[\"message\",\"close\"],\"statements\":[[6,\"main\"],[10,\"class\",[18,\"mainTagClass\"],null],[7],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"usage-alerts\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"shouldShowUsageAlerts\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"usage-alert\",[[20,[\"usageAlerts\",\"alert\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[1,[25,\"outlet\",[\"modal\"],null],false],[0,\"\\n\"],[1,[18,\"loading-pane\"],false],[0,\"\\n\"],[4,\"ember-notify\",null,[[\"closeAfter\"],[5000]],{\"statements\":[[0,\"\\t\"],[4,\"component\",[\"toast\"],[[\"type\",\"message\",\"onClose\"],[[19,1,[\"type\"]],[19,1,[\"text\"]],[25,\"action\",[[19,0,[]],[19,2,[]]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[19,1,[\"html\"]],true],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/application.hbs"
    }
  });

  _exports.default = _default;
});