define("pconsole/controllers/product/devices/unmark-development", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    actions: {
      closeModal() {
        this.transitionToRoute(this.get('previousRoute'));
      },

      unmarkDevelopment() {
        Ember.$('.loading').show();
        const device = this.get('model');
        device.set('development', false);
        device.save().then(d => {
          d.reload();
          Ember.$('.loading').hide();
          this.transitionToRoute(this.get('previousRoute'));
          this.get('notify').success('The device has been unmarked as a development device successfully!');
        }, response => {
          Ember.$('.loading').hide();
          const error = response.errors[0];
          const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
          this.get('notify').alert(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});