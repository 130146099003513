define("pconsole/components/logic-function-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GET_STARTED_TAB = {
    id: 'getting-started',
    title: 'Get started now'
  };
  const EDIT_TAB = {
    id: 'getting-started',
    title: 'Edit Logic Function'
  };
  const CUSTOMIZE_TAB = {
    id: 'customize-template',
    title: 'Customize template'
  };
  const DOCS_TAB = {
    id: 'libraries-and-docs',
    title: 'Libraries & docs'
  };

  var _default = Ember.Component.extend({
    classNames: ['logic-function__sidebar'],
    isEditing: null,
    // Provided by the editor template
    hasInstructions: Ember.computed.bool('logicFunction.template.instructions'),
    tabs: Ember.computed('hasInstructions', 'isEditing', function () {
      const hasInstructions = Ember.get(this, 'hasInstructions');
      const isEditing = Ember.get(this, 'isEditing');
      let tabs = [];

      if (hasInstructions) {
        tabs.push(CUSTOMIZE_TAB);
      }

      if (isEditing) {
        tabs.push(EDIT_TAB);
      } else {
        tabs.push(GET_STARTED_TAB);
      }

      tabs.push(DOCS_TAB);
      return tabs;
    })
  });

  _exports.default = _default;
});