define("pconsole/components/export-to-csv", ["exports", "pconsole/mixins/export-to-csv-request", "pconsole/mixins/permissions"], function (_exports, _exportToCsvRequest, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, _exportToCsvRequest.default, {
    product: null,
    scope: 'devices:list',
    tagName: 'a',
    classNames: ['ui', 'button', 'outline', 'primary'],

    click() {
      this.send('exportToCSV');
    },

    actions: {
      exportToCSV() {
        const model = this.get('modelName') + 's';
        const url = '/v1/products/' + this.get('routeData.product') + '/' + model;
        this.makeCSVRequest(url);
      }

    }
  });

  _exports.default = _default;
});