define("pconsole/templates/product/map/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ac/lwZmg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"asset-tracker-page__search\"],[7],[0,\"\\n  \"],[4,\"link-to\",[\"product.map\"],[[\"class\"],[\"asset-tracker-page__back-button\"]],{\"statements\":[[6,\"i\"],[9,\"class\",\"ion-ios7-arrow-back\"],[7],[8],[0,\" Back to results\"]],\"parameters\":[]},null],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"asset-tracker-page__device\"],[7],[0,\"\\n  \"],[1,[25,\"asset-tracker-device\",null,[[\"device\",\"onExportClick\",\"devicePageUrl\"],[[20,[\"device\"]],[25,\"action\",[[19,0,[]],\"export\"],null],[20,[\"devicePageUrl\"]]]]],false],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/map/device.hbs"
    }
  });

  _exports.default = _default;
});