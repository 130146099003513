define("pconsole/mixins/models/clone-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    clone(overrides) {
      const model = this,
            attrs = model.toJSON();
      const modelName = model.constructor.modelName;
      /*
       * Need to replace the belongsTo association ( id ) with the
       * actual model instance.
       *
       * For example if belongsTo association is project, the
       * json value for project will be:  ( project: "project_id_1" )
       * and this needs to be converted to ( project: [projectInstance] )
       */

      this.eachRelationship((key, relationship) => {
        if (relationship.kind === 'belongsTo') {
          attrs[key] = model.get(key);
        }
      });
      /*
      * Need to dissociate the new record from the old.
      */

      delete attrs.id;
      /*
      * Apply overrides if provided.
      */

      if (Ember.typeOf(overrides) === 'object') {
        Ember.setProperties(attrs, overrides);
      }

      return this.store.createRecord(modelName, attrs);
    }

  });

  _exports.default = _default;
});