define("pconsole/components/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    attributeBindings: ['type'],
    content: null,
    _value: Ember.computed.reads('value'),

    didInsertElement() {
      const $fileUploadContainer = Ember.$('.file-upload');
      $fileUploadContainer.on('dragover', function () {
        $fileUploadContainer.css('border', '2px dashed var(--online-color)');
      });
      $fileUploadContainer.on('dragleave', function () {
        $fileUploadContainer.css('border', '2px dashed var(--primary-border-color)');
      });
      $fileUploadContainer.on('drop', function () {
        $fileUploadContainer.css('border', '2px dashed var(--primary-border-color)');
      });
    },

    willDestroyElement() {
      this.set('_value', null);
      this.set('file-name', null);
      this.set('file-size', null);
    },

    change(event) {
      this._updateFile(event.target);
    },

    /* eslint no-bitwise: 0 */
    _updateFile(fileInput) {
      const accept = this.get('accept');

      if (fileInput.files.length > 0) {
        const file = fileInput.files[0];
        const fileExt = file.name.substr((~-file.name.lastIndexOf('.') >>> 0) + 2);
        const validFileExt = accept.indexOf(fileExt) > -1;

        if (validFileExt) {
          this.set('_value', file);
          const action = this.get('action');

          if (action) {
            action(file);
          }

          this.set('file-name', file.name);
          this.set('file-size', file.size);
        } else {
          this.get('notify').alert('Please use a file with the following extension(s): ' + accept);
          this.send('removeFile');
        }
      } else {
        this.set('_value', null);
        const action = this.get('action');

        if (action) {
          action(null);
        }

        this.set('file-name', null);
        this.set('file-size', null);
      }
    },

    actions: {
      removeFile() {
        const $fileInput = Ember.$('#upload-file');
        $fileInput.wrap('<form>').closest('form').get(0).reset();
        $fileInput.unwrap();

        this._updateFile($fileInput.get(0));
      },

      selectFile() {
        const $fileInput = Ember.$('#upload-file');
        $fileInput.click();
      }

    }
  });

  _exports.default = _default;
});