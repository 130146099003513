define("pconsole/mixins/sim/reactivate", ["exports", "pconsole/mixins/sim/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_base.default, {
    application: Ember.inject.controller(),
    hasServiceAgreement: Ember.computed.readOnly('application.hasServiceAgreement'),
    actions: {
      reactivateSim() {
        this.updateSim({
          action: 'reactivate'
        }, 'Your SIM has been successfully reactivated.', 'Reactivating');
      }

    }
  });

  _exports.default = _default;
});