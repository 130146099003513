define("pconsole/models/new-device", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FileReaderResult = Ember.Object.extend(Ember.PromiseProxyMixin);

  var _default = _emberData.default.Model.extend({
    updated: _emberData.default.attr('number'),
    nonmemberDeviceIds: _emberData.default.attr(),
    invalidDeviceIds: _emberData.default.attr(),
    importMethod: _emberData.default.attr('string'),
    file: _emberData.default.attr('file'),
    identifier: _emberData.default.attr('string'),
    accepted: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    deviceCount: Ember.computed('file', function () {
      const file = Ember.get(this, 'file');

      if (!file) {
        return;
      }

      const reader = new FileReader();
      const promise = new Ember.RSVP.Promise(function (resolve, reject) {
        reader.onerror = reject;

        reader.onload = event => resolve(event.target.result);

        reader.readAsText(file);
      }).then(text => text.split('\n').filter(Boolean).length);
      return FileReaderResult.create({
        promise
      });
    })
  });

  _exports.default = _default;
});