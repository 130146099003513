define("pconsole/templates/components/product-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gc7uCF89",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[6,\"label\"],[9,\"for\",\"id\"],[7],[1,[18,\"label\"],false],[8],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"id\",\"class\",\"closeOnSelect\",\"onchange\",\"options\",\"placeholder\",\"renderInPlace\",\"searchField\",\"selected\"],[[20,[\"inputId\"]],\"power-select power-select--with-label\",false,[25,\"action\",[[19,0,[]],[20,[\"onChange\"]]],null],[20,[\"productNames\"]],\"Search for a product\",true,\"name\",[20,[\"selectedProducts\"]]]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"group-info\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"group-name\"],[7],[1,[25,\"unbound\",[[19,1,[\"name\"]]],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/product-selector.hbs"
    }
  });

  _exports.default = _default;
});