define("pconsole/templates/components/x-times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ETEK8sf0",
    "block": "{\"symbols\":[\"index\",\"&default\"],\"statements\":[[4,\"each\",[[20,[\"timesArray\"]]],null,{\"statements\":[[0,\"  \"],[11,2,[[19,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/x-times.hbs"
    }
  });

  _exports.default = _default;
});