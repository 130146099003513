define("pconsole/templates/product/devices/create-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d6IWZ/Gf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"modal-title\"],[7],[0,\"New Device Group\"],[8],[0,\"\\n  \"],[12,\"partials/create-or-edit-group\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/create-group.hbs"
    }
  });

  _exports.default = _default;
});