define("pconsole/mixins/device-type", ["exports", "pconsole/utils/platforms"], function (_exports, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deviceType: Ember.computed('product', 'platformId', function () {
      let platformID = this.get('platformId');

      if (!platformID && platformID !== 0) {
        platformID = this.get('product.platformId');
      }

      const platforms = (0, _platforms.getPlatformSpecs)();
      const platform = Object.values(platforms).find(p => p.id === platformID);
      return platform ? platform.displayName : 'Other';
    })
  });

  _exports.default = _default;
});