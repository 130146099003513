define("pconsole/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const DeviceEvent = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    publishedAt: _emberData.default.attr('date'),
    eventData: _emberData.default.attr('string'),
    coreid: _emberData.default.attr('string'),
    paused: _emberData.default.attr('boolean'),
    raw: _emberData.default.attr('string'),
    expandedEvent: _emberData.default.attr('boolean'),
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    humanName: Ember.computed('name', function () {
      const name = this.get('name');

      if (this.get('name') === 'spark/status') {
        const data = this.get('eventData');

        if (data === 'online') {
          return 'device came online';
        } else if (data === 'offline') {
          return 'device went offline';
        }
      }

      return name;
    }),
    filteredData: Ember.computed('eventData', function () {
      const name = this.get('name');
      const data = this.get('eventData');

      if (name === 'spark/status') {
        switch (data) {
          case 'online':
          case 'offline':
            return null;

          default:
            return data;
        }
      }

      return data;
    }),
    coreId: Ember.computed('coreid', function () {
      const id = this.get('coreid');
      return id === '001' ? 'cloud' : id;
    }),
    type: Ember.computed('name', function () {
      return this.get('name') === 'spark/status' ? 'system' : 'user';
    })
  });

  DeviceEvent.reopenClass({
    FIXTURES: []
  });
  var _default = DeviceEvent;
  _exports.default = _default;
});