define("pconsole/routes/product/location/device", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    queryParams: {
      dates: {
        refreshModel: true
      }
    },

    deactivate() {
      const mapController = this.controllerFor('product.location');
      Ember.set(mapController, 'activeDevice', null);
      Ember.set(mapController, 'visibleDevices', []);
      Ember.set(mapController, 'visibleDevicesInCluster', []);
      Ember.get(mapController, 'model.devices').update();
      const controller = this.controllerFor('product.location.device');
      Ember.get(controller, 'eventStream').stopListening();
      Ember.set(controller, 'dates', null);
    },

    async model(params) {
      const {
        device_location_id: id,
        dates
      } = params;
      const {
        product
      } = this.modelFor('product');
      const productId = Ember.get(product, 'id');
      const deviceLocationRecord = await this.store.queryRecord('device-location', {
        date_range: dates && dates.join(','),
        id,
        productId
      });
      const mapController = this.controllerFor('product.location');
      await deviceLocationRecord.hasMany('groups').load();
      Ember.set(mapController, 'activeDevice', id);
      return deviceLocationRecord;
    },

    afterModel(model) {
      // Redirect to the full list of devices if the current one has no coordinates
      if (!model.get('hasLocation')) {
        this.router.transitionTo('product.location');
      }
    },

    setupController(controller) {
      this._super(...arguments); // When loc events come in, `controller.device` is recomputed if the
      // record's `lastHeard` field changes. Also recompute it when the model hooks are
      // called during normal transitions.


      controller.notifyPropertyChange('device');
    },

    actions: {
      loading(transition) {
        var _transition$queryPara;

        const hasDates = !!((_transition$queryPara = transition.queryParams) === null || _transition$queryPara === void 0 ? void 0 : _transition$queryPara.dates);

        if (hasDates) {
          Ember.$('.loading').show();
          transition.finally(() => Ember.$('.loading').hide());
        }
      },

      exportCSV() {
        const {
          groups,
          name
        } = this.paramsFor('product.location');
        const {
          dates
        } = this.paramsFor('product.location.device');
        const model = this.modelFor('product.location.device');
        const {
          product
        } = this.modelFor('product');
        const id = Ember.get(model, 'id');
        const productId = Ember.get(product, 'id');
        const headers = {
          Accept: 'text/csv'
        };
        const notify = Ember.get(this, 'notify');
        const url = new URL("".concat(_environment.default.APP.API_ENDPOINT, "/v1/products/").concat(productId, "/locations/").concat(id));

        if (dates) {
          url.searchParams.append('date_range', dates.join(','));
        }

        if (groups) {
          url.searchParams.append('groups', groups);
        }

        if (name) {
          url.searchParams.append('device_name', name);
        }

        Ember.get(this, 'apiAjax').getRequest({
          url: url.href,
          headers,
          dataType: 'text'
        }).done(csv => {
          const a = document.createElement('a');
          const objectUrl = window.URL.createObjectURL(new Blob([csv], {
            type: 'text/csv'
          }));
          a.href = objectUrl;
          a.download = "".concat(id, ".csv");
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(objectUrl);
        }).fail(xhr => {
          try {
            const {
              error_description
            } = JSON.parse(xhr.responseText);

            if (error_description) {
              notify.error(error_description);
              return;
            }
          } catch (e) {// error message was not JSON, fall through
          }

          notify.error('There was a problem exporting the device\'s data');
        });
      }

    }
  });

  _exports.default = _default;
});