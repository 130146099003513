define("pconsole/controllers/product/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    productTeam: Ember.computed.filter('model.productTeam', m => !m.get('isNew') && !m.get('isProgrammatic')),
    // On this page, we don't need to filter out new records (isNew) because
    // new team members will only be created for product teams. But we do need
    // to filter out invited users who have not yet accepted their invitation.
    orgTeam: Ember.computed.filter('model.orgTeam', m => !m.get('invited') && !m.get('isProgrammatic')),
    programmaticUsers: Ember.computed.filterBy('model.productTeam', 'isProgrammatic', true),
    roles: Ember.computed.alias('model.roles'),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    hasOrgTeam: Ember.computed('orgTeam', function () {
      return this.get('orgTeam').length > 0;
    }),
    hasProgrammaticUsers: Ember.computed('programmaticUsers', function () {
      return this.get('programmaticUsers').length > 0;
    }),
    actions: {
      updateProductTeamMemberRole(product, user, event) {
        const roleId = event.target.value;
        const role = this.store.peekRecord('role', roleId);
        user.set('role', role);
        return user.save().then(() => {
          this.get('notify').success('The user role has been successfully updated');
        }, response => {
          const error = response.errors[0];
          const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
          this.get('notify').alert(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});