define("pconsole/controllers/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, {
    applicationController: Ember.inject.controller('application'),
    currentPath: Ember.computed.alias('applicationController.currentPath'),
    product: Ember.computed.alias('model.product'),
    user: Ember.computed.alias('model.user')
  });

  _exports.default = _default;
});