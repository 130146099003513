define("pconsole/routes/organization/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    apiAjax: Ember.inject.service(),
    breadCrumb: {
      title: 'Insights',
      path: 'organization.insights'
    },

    async model() {
      const dashboards = await this.store.findAll('insights-dashboard');
      return {
        dashboards
      };
    }

  });

  _exports.default = _default;
});