define("pconsole/routes/user/ledger/show/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'organization/ledger/archive',
    controllerName: 'organization/ledger/archive',

    model() {
      return this.modelFor('user.ledger.show').ledgerDefinition;
    }

  });

  _exports.default = _default;
});