define("pconsole/mixins/block-transition-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BlockTransitionRoute = void 0;
  const BlockTransitionRoute = {
    // This key should be provided by any route implementing this "mixin"
    // it should be a string with the path to the model field to be checked for blocking/enabling transitions
    dirtyKey: null,

    listener(event) {
      const key = Ember.get(this, 'dirtyKey');
      const isDirty = Ember.get(this, key);

      if (isDirty) {
        event.preventDefault();
      }
    },

    activate() {
      const boundListener = this.listener.bind(this);
      window.addEventListener('beforeunload', boundListener);
      Ember.set(this, 'boundListener', boundListener);
    },

    actions: {
      willTransition(transition) {
        this._super();

        const key = Ember.get(this, 'dirtyKey');
        const isDirty = Ember.get(this, key);

        if (isDirty && !confirm('You have unsaved changes. Any progress you made will not be saved\nAre you sure you want to leave this page?')) {
          transition.abort();
        }

        const boundListener = Ember.get(this, 'boundListener');
        window.removeEventListener('beforeunload', boundListener);
        return true;
      }

    }
  };
  _exports.BlockTransitionRoute = BlockTransitionRoute;
});