define("pconsole/templates/organization/logic/templates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ILTa6dDp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"class\",\"close\"],[\"logic-template-view no-styles\",\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"body\"],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"template-description\"],[7],[1,[25,\"markdown-to-html\",null,[[\"markdown\",\"extensions\"],[[25,\"unbound\",[[20,[\"description\"]]],null],\"xssfilter\"]]],false],[8],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"setTemplate\"]],[7],[0,\"\\n    Start with this Template\\n  \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/logic/templates/show.hbs"
    }
  });

  _exports.default = _default;
});