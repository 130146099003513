define("pconsole/helpers/last-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.last4 = last4;
  _exports.default = void 0;

  function last4(args) {
    const str = args[0];

    if (str.length <= 4) {
      return str;
    }

    return new Ember.Handlebars.SafeString("<span class=\"safe dots\">...</span>".concat(str.slice(-4)));
  }

  var _default = Ember.Helper.helper(last4);

  _exports.default = _default;
});