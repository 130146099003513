define("pconsole/models/logic-function", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    triggerType: (0, _emberCpValidations.validator)('presence', true),
    code: (0, _emberCpValidations.validator)('presence', true)
  }); // This is the model for the /logic/functions API routes

  const LogicFunction = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    enabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    updatedAt: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    logicTriggers: _emberData.default.hasMany('logic-trigger'),
    todayStats: _emberData.default.attr(),
    code: _emberData.default.attr('string'),
    apiUsername: _emberData.default.attr('string'),
    source: _emberData.default.attr(),
    templateSlug: _emberData.default.attr('string', {
      defaultValue: 'empty-template'
    }),
    template: Ember.computed('templateSlug', function () {
      const slug = Ember.get(this, 'templateSlug') || 'empty-template';
      const templates = this.store.peekAll('logic-template');
      return templates.filterBy('slug', slug)[0];
    }),
    triggerType: Ember.computed('logicTriggers', {
      get() {
        return Ember.get(this, 'logicTriggers.firstObject.type') || null;
      }

    }),
    status: Ember.computed('enabled', {
      get() {
        return Ember.get(this, 'enabled') ? 'Enabled' : 'Disabled';
      },

      set(key, value) {
        Ember.set(this, 'enabled', value === 'Enabled');
        return value;
      }

    }),
    singleTrigger: Ember.computed('logicTriggers', function () {
      const triggers = Ember.get(this, 'logicTriggers').toArray();

      if (triggers.length === 1) {
        return triggers[0];
      }

      return null;
    })
  });

  var _default = LogicFunction;
  _exports.default = _default;
});