define("pconsole/serializers/product", ["exports", "pconsole/config/environment", "pconsole/serializers/application", "pconsole/utils/platforms"], function (_exports, _environment, _application, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      const platform_id = parseInt(json.platform_id, 10);
      json.platform_id = platform_id; // Determine what Device Cloud plan the product should be on
      // based on its platform type
      // Only set the plan slug for personal products

      if (!json.organization_id) {
        if ((0, _platforms.isAssetTrackerPlatform)(platform_id)) {
          // Set to the asset tracker plan
          json.plan_slug = _environment.default.APP.ASSET_TRACKER_CLOUD_SELF_SERVICE_PLAN_SLUG;
        } else if ((0, _platforms.isCellularPlatform)(platform_id)) {
          // Set to the cellular Device Cloud type
          json.plan_slug = _environment.default.APP.CELLULAR_DEVICE_CLOUD_SELF_SERVICE_PLAN_SLUG;
        } else {
          // For all other platforms, set to the Wi-Fi device cloud plans
          json.plan_slug = _environment.default.APP.WIFI_DEVICE_CLOUD_SELF_SERVICE_PLAN_SLUG;
        }
      }

      if (Ember.isPresent(snapshot.changedAttributes().quarantine)) {
        json.settings = _objectSpread({}, json.settings, {
          quarantine: json.quarantine
        });
      } // Delete attributes unrelated to creating or updating product records


      delete json.groups_enable_override;
      delete json.latest_firmware_version;
      delete json.subscription_id;
      delete json.user_id;
      delete json.organization_id;
      delete json.type;
      delete json.config_id;
      delete json.quarantine;
      delete json.slug;

      if (!json.hardware_version) {
        delete json.hardware_version;
      }

      return json;
    },

    normalize(typeClass, hash) {
      if (hash.settings && typeof hash.settings.quarantine !== 'undefined') {
        hash.quarantine = hash.settings.quarantine;
      }

      return this._super(...arguments);
    },

    normalizeResponse(store, type, payload, id, requestType) {
      delete payload.ok;
      return this._super(store, type, payload, id, requestType);
    }

  });

  _exports.default = _default;
});