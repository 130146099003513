define("pconsole/templates/organization/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R6VOxUOw",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[6,\"div\"],[9,\"class\",\"organizations-products-wrapper\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"top-rail clearfix\"],[7],[0,\"\\n    \"],[6,\"h3\"],[9,\"class\",\"dashboard-title\"],[7],[1,[20,[\"org\",\"name\"]],false],[0,\"'s Products\"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"btn-container\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"organization.products.new\"],[[\"class\",\"scope\"],[\"add-product-btn ui button outline primary\",\"products:create\"]],{\"statements\":[[0,\"+ New Product\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"product-cards\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"products\"]]],null,{\"statements\":[[4,\"link-to\",[\"product\",[19,1,[\"slug\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"product-card\",null,[[\"product\"],[[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"no-product\"],[7],[0,\"\\n        \"],[6,\"p\"],[7],[0,\"Create a Product to unlock fleet management tools for scaling deployments\"],[8],[0,\"\\n        \"],[4,\"link-to\",[\"organization.products.new\"],[[\"class\"],[\"ui button outline primary create-product-btn\"]],{\"statements\":[[0,\"+ New Product\"]],\"parameters\":[]},null],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/organization/products.hbs"
    }
  });

  _exports.default = _default;
});