define("pconsole/controllers/product/firmware/release", ["exports", "pconsole/mixins/get-group-device-count"], function (_exports, _getGroupDeviceCount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_getGroupDeviceCount.default, {
    notify: Ember.inject.service(),
    apiAjax: Ember.inject.service(),
    firmware: Ember.computed.alias('model.firmware'),
    product: Ember.computed.alias('model.product'),
    selectedGroups: null,
    segment: Ember.inject.service(),
    impactedFirmwareVersionsVisible: false,
    productDefaultGroup: Ember.computed('productDefaultName', function () {
      return {
        name: this.get('productDefaultName')
      };
    }),
    sortedOptions: Ember.computed.sort('groups', function (a, b) {
      const firmwareGroups = this.get('originalSelectedGroups');
      const groupsIncludeA = firmwareGroups.includes(a);
      const groupsIncludeB = firmwareGroups.includes(b); // Put product default on the top, regardless

      if (b.name === this.get('productDefaultName')) {
        return 1;
      } else if (groupsIncludeA && groupsIncludeB) {
        return 0;
      } else if (groupsIncludeA) {
        return -1;
      } else {
        return 1;
      }
    }),

    setupSelectedGroups() {
      const firmware = this.get('firmware');
      const firmwareGroups = firmware.get('groups').toArray();

      if (firmware.get('productDefault')) {
        firmwareGroups.unshiftObject(this.get('productDefaultGroup'));
      }

      return firmwareGroups;
    },

    reset() {
      this.set('confirmRelease', false);
      this.set('showPrecedenceRules', false);
      this.set('numberOfImpactedDevices', false);
      this.set('originalSelectedGroups', this.setupSelectedGroups());
      this.set('originalImmediateUpdate', this.get('firmware').get('immediate'));
      this.set('immediateUpdate', this.get('firmware').get('immediate'));
      this.set('selectedGroups', this.setupSelectedGroups());
    },

    queuedRelease: Ember.computed('originalSelectedGroups', 'selectedGroups', 'originalImmediateUpdate', 'immediateUpdate', function () {
      // todo - there has to be a better way to compute if the modal has changed state or not :-)
      const originalSelectedGroups = this.get('originalSelectedGroups');
      const selectedGroups = this.get('selectedGroups');
      const originalImmediate = this.get('originalImmediateUpdate');
      const immediate = this.get('immediateUpdate');
      return originalSelectedGroups.toString() !== selectedGroups.toString() || immediate !== originalImmediate;
    }),
    unreleaseToAll: Ember.computed('selectedGroups', 'originalSelectedGroups', function () {
      return (this.get('selectedGroups') || []).length === 0 && (this.get('originalSelectedGroups') || []).length > 0;
    }),
    disableNextButton: Ember.computed('queuedRelease', function () {
      const originalSelectedGroups = this.get('originalSelectedGroups') || [];
      return !this.get('queuedRelease') || originalSelectedGroups.length < 1 && this.get('selectedGroups.length') === 0;
    }),
    planGroupsEnabled: Ember.computed.alias('model.product.groupsEnabled'),
    confirmRelease: false,
    showPrecedenceRules: false,
    productDefaultName: 'Product default',
    numberOfImpactedDevices: null,
    releaseAsProductDefault: Ember.computed('selectedGroups', 'planGroupsEnabled', function () {
      const groups = this.get('selectedGroups');
      const productDefault = groups.findBy('name', this.get('productDefaultName'));
      return !this.get('planGroupsEnabled') || !!productDefault;
    }),
    immediateUpdate: Ember.computed('model.firmware.immediate', function () {
      return false;
    }),
    noFirmwareDevices: Ember.computed('firmware.deviceCount', function () {
      const firmwareDeviceCount = this.get('firmware.deviceCount');
      return firmwareDeviceCount === 0;
    }),
    firstRelease: Ember.computed.not('firmware.firstReleasedAt'),
    customFirmware: Ember.computed.not('firmware.knownApplicationId'),
    disableReleaseBtn: Ember.computed('noFirmwareDevices', 'firstRelease', 'customFirmware', 'immediateUpdate', 'immediateUpdateConfirmed', function () {
      const noFirmwareDevices = this.get('noFirmwareDevices');
      const firstRelease = this.get('firstRelease');
      const customFirmware = this.get('customFirmware');

      if (noFirmwareDevices && firstRelease && customFirmware) {
        return true;
      }

      const immediateUpdate = this.get('immediateUpdate');
      const immediateUpdateConfirmed = this.get('immediateUpdateConfirmed');

      if (immediateUpdate) {
        return !immediateUpdateConfirmed;
      }

      return false;
    }),
    gradualReleaseTooltip: 'This firmware will be delivered to target devices the next time they start a new secure session with the Device Cloud.',
    gradualReleaseTooltipEnterprise: Ember.computed('gradualReleaseTooltip', function () {
      let text = this.get('gradualReleaseTooltip');
      text += '\n\nIf you’d like to deploy this firmware as target devices are ready to accept it, you can opt-in to an “Intelligent Release” on the previous screen.';
      return text;
    }),
    groups: Ember.computed('model.groups', function () {
      const groupsArray = this.get('model.groups').toArray();
      groupsArray.unshiftObject(this.get('productDefaultGroup'));
      return groupsArray;
    }),
    firmwareRouteActive: true,
    groupChosen: Ember.computed('selectedGroups', function () {
      const selectedGroups = this.get('selectedGroups');

      if (!selectedGroups) {
        return false;
      } else {
        return selectedGroups.get('length') > 0;
      }
    }),
    multipleGroupsChosen: Ember.computed('selectedGroups', function () {
      const selectedGroups = this.get('selectedGroups');

      if (!selectedGroups) {
        return false;
      } else {
        return selectedGroups.get('length') > 1;
      }
    }),
    groupsClean: Ember.computed('selectedGroups', function () {
      // The multi-select includes real groups + product default. Let's remove the product default
      // "group" before we send the request off to the API
      const selectedGroups = this.get('selectedGroups');
      return selectedGroups.mapBy('name').removeObject(this.get('productDefaultName'));
    }),

    _constructReleaseFirmwareRequestBody() {
      const version = this.get('firmware.version');
      const groups = this.get('groupsClean');
      const productDefault = this.get('releaseAsProductDefault');
      const body = {
        version: version
      };

      if (groups.length > 0) {
        body.groups = groups;
      }

      if (productDefault) {
        body.product_default = true;
      }

      if (this.get('unreleaseToAll')) {
        delete body.groups;
        body.product_default = false;
      }

      let trackEventName;

      if (this.get('immediateUpdate')) {
        body.intelligent = true;
        trackEventName = 'intelligent-firmware-release';
      } else {
        trackEventName = 'standard-firmware-release';
      } // Track the standard/intelligent release for analytics purposes.


      const routeData = this.get('routeData');
      const product = routeData.product;
      this.get('segment').trackEvent(trackEventName, {
        product: product,
        version: version,
        groups: groups
      });
      return body;
    },

    proceedToRelease() {
      const groupsClean = this.get('groupsClean');
      const productDefault = this.get('releaseAsProductDefault');
      const version = this.get('firmware.version');
      this.set('confirmRelease', true);

      if (groupsClean || productDefault) {
        this.getImpact('edit_groups_for_firmware', {
          version: version,
          groups: groupsClean.join(','),
          productDefault: productDefault
        });
      }
    },

    actions: {
      contactSalesIFR() {
        // Track the click to contact sales for analityics purposes.
        const routeData = this.get('routeData');
        const product = routeData.product;
        this.get('segment').trackEvent('intelligent-firmware-sales-cta-click', {
          product: product
        });
        window.open('https://www.particle.io/sales/?utm_source=console&utm_content=intelligent-firmware-releases');
      },

      toggleImpactedFirmwareVersions() {
        this.toggleProperty('impactedFirmwareVersionsVisible');
      },

      closeModal() {
        this.set('immediateUpdateConfirmed', false);
        Ember.run.later(this, () => {
          if (this.get('firmwareRouteActive')) {
            this.transitionToRoute('product.firmware');
          }
        }, 50);
      },

      togglePrecedenceRules() {
        this.toggleProperty('showPrecedenceRules');
      },

      releaseFirmware() {
        const routeData = this.get('routeData');
        const product = routeData.product;

        const requestBody = this._constructReleaseFirmwareRequestBody();

        return this.get('apiAjax').request({
          url: '/v1/products/' + product + '/firmware/release',
          type: 'PUT',
          data: JSON.stringify(requestBody),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json'
        }).then(response => {
          return this.transitionToRoute('product.firmware').then(() => {
            this.send('refreshModel');
            this.get('notify').success('The current firmware release has been updated successfully!');
            return response;
          });
        }, err => {
          this.get('notify').error('There was a problem changing the current firmware release.');
          return Ember.RSVP.reject(err);
        });
      },

      proceedToRelease() {
        this.proceedToRelease();
      },

      back() {
        this.set('confirmRelease', false);
      }

    }
  });

  _exports.default = _default;
});