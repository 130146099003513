define("pconsole/templates/components/logic-function-today-traffic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yIUIo4Kr",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"logic-function-today-stats\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"stats\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"traffic-stat success-stats\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"im-check-circle\"],[7],[8],[4,\"if\",[[20,[\"stats\",\"success\"]]],null,{\"statements\":[[1,[25,\"large-number-shortener\",[[20,[\"stats\",\"success\"]]],null],false]],\"parameters\":[]},{\"statements\":[[0,\"0\"]],\"parameters\":[]}],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"traffic-stat failure-stats\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"ion-close\"],[7],[8],[4,\"if\",[[20,[\"stats\",\"failure\"]]],null,{\"statements\":[[1,[25,\"large-number-shortener\",[[20,[\"stats\",\"failure\"]]],null],false]],\"parameters\":[]},{\"statements\":[[0,\"0\"]],\"parameters\":[]}],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"traffic-stat timeout-stats\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"im-history\"],[7],[8],[4,\"if\",[[20,[\"stats\",\"timeout\"]]],null,{\"statements\":[[1,[25,\"large-number-shortener\",[[20,[\"stats\",\"timeout\"]]],null],false]],\"parameters\":[]},{\"statements\":[[0,\"0\"]],\"parameters\":[]}],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"undefined-attr empty-stats\"],[7],[0,\"none\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/logic-function-today-traffic.hbs"
    }
  });

  _exports.default = _default;
});