define("pconsole/templates/form-inputs/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DEduYOFR",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"form-select\",null,[[\"id\",\"invalid\",\"content\",\"name\",\"value\",\"selection\",\"prompt\",\"disabled\",\"required\",\"optionValuePath\",\"optionLabelPath\",\"autocomplete\",\"action\",\"showError\"],[[20,[\"inputId\"]],[20,[\"isInvalid\"]],[20,[\"content\"]],[20,[\"name\"]],[20,[\"value\"]],[20,[\"selection\"]],[20,[\"prompt\"]],[20,[\"disabled\"]],[20,[\"required\"]],[20,[\"optionValuePath\"]],[20,[\"optionLabelPath\"]],[20,[\"autocomplete\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"value\"]]],null]],null],\"showError\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/form-inputs/select.hbs"
    }
  });

  _exports.default = _default;
});