define("pconsole/components/eventlogs/suggestions-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    filterVisibleSuggestions: suggestions => {
      return suggestions.filter(suggestion => suggestion.display !== false);
    },
    getSuggestionsThatMatchQuery: (suggestions, query) => {
      let _suggestions = suggestions;

      if (query && _suggestions) {
        let _query = query.split(' ');

        _query = _query[_query.length - 1];
        _suggestions = _suggestions.filter(suggestion => suggestion.title.indexOf(_query) === 0);
      }

      return _suggestions;
    },
    getDetailedSuggestions: suggestions => {
      let _suggestions = suggestions;

      if (_suggestions && _suggestions.length === 1 && _suggestions[0].detailed) {
        const detailed = Object.keys(_suggestions[0].detailed).map(key => _suggestions[0].detailed[key]).map(item => Object.assign({}, item, {
          detailedQuery: true,
          display: false
        }));
        _suggestions = _suggestions.concat(detailed);
      }

      return _suggestions;
    },
    getQueryWithSuggestion: (query, suggestion) => {
      const colon = suggestion.detailedQuery ? ' ' : ':';

      let _query = query.split(' ');

      _query[_query.length - 1] = "".concat(suggestion.title).concat(colon);
      _query = _query.join(' ');
      return _query;
    }
  };
  _exports.default = _default;
});