define("pconsole/templates/components/logic-trigger-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JKE2vYIX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"is-equal\",[[20,[\"details\"]],null],null]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"class\",\"undefined-attr empty-stats\"],[7],[0,\"none\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"i\"],[10,\"class\",[26,[\"icon \",[20,[\"details\",\"icon\"]],\" type-\",[20,[\"details\",\"type\"]]]]],[7],[8],[6,\"span\"],[7],[1,[20,[\"details\",\"text\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/logic-trigger-type.hbs"
    }
  });

  _exports.default = _default;
});