define("pconsole/serializers/card", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      const finalJSON = {
        stripe_token: json.stripe_token
      };
      Ember.merge(hash, finalJSON);
    },

    normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload.card.id = 0;
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});