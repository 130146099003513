define("pconsole/helpers/just-topic-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.justTopicName = justTopicName;
  _exports.default = void 0;

  function justTopicName([topic]) {
    return topic.split('/topics/')[1];
  }

  var _default = Ember.Helper.helper(justTopicName);

  _exports.default = _default;
});