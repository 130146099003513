define("pconsole/controllers/user/integrations/google-cloud-platform/create", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "pconsole/mixins/integration-devices", "pconsole/mixins/save-integration", "pconsole/mixins/include-device-id-in-integration-response", "pconsole/config/environment", "ember-validations", "pconsole/mixins/org-integration-products"], function (_exports, _form, _integrationDevices, _saveIntegration, _includeDeviceIdInIntegrationResponse, _environment, _emberValidations, _orgIntegrationProducts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line max-len
  var _default = Ember.Controller.extend(_integrationDevices.default, _saveIntegration.default, _orgIntegrationProducts.orgIntegrationProducts, _includeDeviceIdInIntegrationResponse.default, _form.default, Ember.Evented, {
    notify: Ember.inject.service(),
    displayInstructions: true,
    // Required for save integration mixin
    //integration: Ember.computed.alias('googleCloudPubSubIntegration'),
    integrationType: 'googleCloudPlatform',
    validations: {
      'integration.event': {
        presence: true,
        length: {
          maximum: 63
        }
      },
      'integration.topic': {
        length: {
          minimum: 3,
          maximum: 255
        },
        inline: (0, _emberValidations.validator)(function () {
          const topic = this.model.get('integration.topic');
          const regex = new RegExp('projects/[^/]+/topics/([a-zA-Z][-a-zA-Z0-9_.~+%]{2,254})');
          const regexResult = regex.exec(topic);

          if (!regexResult) {
            // The following line includes a zero-width space to work around a poor implementation in ember-easy-form-extensions
            // error-field.hbs that capitalizes errors using Ember.String.capitalize which capitalizes the first letter of the
            // string and every letter following a slash. It's meant to handle path-like strings not render error strings.
            // Without a workaround, the error hint says the format is "projects/{project}/Topics/{topic}" which is misleading.
            return 'must have the format "projects/{project}/​topics/{topic}"';
          }

          if (regexResult[1].startsWith('goog')) {
            return 'name cannot start with "goog"';
          }
        })
      },
      // TODO (carlos h): Move validations to shared file
      'integration.productIds': (0, _emberValidations.validator)(function () {
        var _this$model$get;

        const organization = (_this$model$get = this.model.get('integration.organization')) === null || _this$model$get === void 0 ? void 0 : _this$model$get.content;
        const products = this.model.get('integration.productIds') || []; // Only validate products if the integration is for orgs

        if (!!organization && products.length < 1) {
          return 'must select at least one';
        }
      })
    },
    googleCloudPubSubServiceAccountEmail: Ember.computed('ENV.googleCloudPubSubServiceAccountEmail', function () {
      const googleCloudPubSubServiceAccountEmail = _environment.default.APP.GOOGLE_CLOUD_PUB_SUB_SERVICE_ACCOUNT_EMAIL;
      return googleCloudPubSubServiceAccountEmail;
    }),
    actions: {
      displayForm() {
        this.set('displayInstructions', false);
      },

      displayInstructions() {
        this.set('displayInstructions', true);
      },

      afterCopy() {
        this.get('notify').info('Service account name copied to clipboard!');
      }

    }
  });

  _exports.default = _default;
});