define("pconsole/templates/components/tab-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "16Ng/rn3",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"tab-panel__tabs\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"internalTabs\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[10,\"class\",[19,1,[\"className\"]],null],[3,\"action\",[[19,0,[]],\"setActiveTab\",[19,1,[]]]],[7],[0,\"\\n      \"],[4,\"if\",[[20,[\"controlled\"]]],null,{\"statements\":[[6,\"span\"],[9,\"class\",\"tab-panel__order\"],[7],[1,[19,1,[\"order\"]],false],[8]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[19,1,[\"title\"]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"],[6,\"section\"],[9,\"class\",\"tab-panel__content\"],[7],[0,\"\\n  \"],[11,2,[[20,[\"activeTab\"]]]],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"controlled\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"tab-panel__footer\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"activeTab\",\"order\"]],1],null]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"button\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"goBack\"]],[7],[0,\"Back\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[6,\"button\"],[10,\"class\",[26,[\"ui button outline primary \",[25,\"unless\",[[20,[\"canContinue\"]],\"disabled\"],null]]]],[3,\"action\",[[19,0,[]],\"goNext\"]],[7],[0,\"\\n      \"],[1,[25,\"if\",[[20,[\"isLastStep\"]],[20,[\"lastText\"]],\"Next\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/tab-panel.hbs"
    }
  });

  _exports.default = _default;
});