define("pconsole/controllers/product/clients/edit", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "pconsole/mixins/client-scope-options"], function (_exports, _form, _clientScopeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, _clientScopeOptions.default, {
    notify: Ember.inject.service(),
    validations: {
      'model.name': {
        presence: true
      }
    },
    isProduct: Ember.computed('routeData.hasProduct', function () {
      return this.get('routeData.hasProduct');
    }),
    _scopeOptions: Ember.computed('scopeOptions', 'isProduct', function () {
      return this.get('isProduct') ? this.get('scopeOptions') : this.get('scopeOptions').filter(item => item.value !== 'customers:create');
    }),

    save() {
      const client = this.get('model');
      this.store.find('client', client.id).then(emberDataClient => {
        emberDataClient.set('name', client.name);
        emberDataClient.set('scopes', client.scopes);
        Ember.$('.loading').show();
        emberDataClient.save().then(() => {
          Ember.$('.loading').hide();
          this.send('closeModal');
          this.get('notify').success('Your client was updated successfully');
        }, () => {
          Ember.$('.loading').hide();
          this.get('notify').alert('There was a problem updating your OAuth client. Please try again later.');
        });
      });
    },

    actions: {
      closeModal() {
        this.transitionToRoute(this.get('isProduct') ? 'product.clients' : 'user.clients');
      },

      updateSelection: function updateSelection(selectedScopes, value, operation) {
        if (value.endsWith('*:*') && operation === 'added' || selectedScopes.length === 0) {
          this.set('model.scopes', ['*:*']);
        } else if (selectedScopes.length === 1 && !value.endsWith('*:*')) {
          this.set('model.scopes', operation === 'added' ? [value] : selectedScopes);
        } else {
          const oldScope = selectedScopes.find(scope => scope.endsWith('*:*'));
          this.set('model.scopes', selectedScopes.removeObject(oldScope));
        }

        this.set('model.selectedScopes', this.get('model.scopes'));
      }
    }
  });

  _exports.default = _default;
});