define("pconsole/models/card", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    number: _emberData.default.attr('string'),
    stripeToken: _emberData.default.attr('string'),
    cvc: _emberData.default.attr('number'),
    brand: _emberData.default.attr('string'),
    last4: _emberData.default.attr('string'),
    expMonth: _emberData.default.attr('number'),
    expYear: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    addressLine1: _emberData.default.attr('string'),
    addressLine2: _emberData.default.attr('string'),
    postal: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    billed_through_account: _emberData.default.attr('boolean'),
    user: _emberData.default.belongsTo('user'),
    onFile: Ember.computed('last4', function () {
      const last4 = this.get('last4');
      return !!last4;
    }),
    isComplete: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});