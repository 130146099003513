define("pconsole/templates/partials/release-sim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PR9WVvZu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Release SIM Card Ownership\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Would you like to release ownership of the SIM card with ICCID ending in \"],[6,\"strong\"],[7],[1,[25,\"last-4\",[[20,[\"sim\",\"id\"]]],null],false],[8],[0,\"?\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"sim\",\"isActive\"]]],null,{\"statements\":[[0,\"      This will deactivate this SIM Card, and remove it from your account.\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      This will remove the SIM Card from your account.\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"releaseSim\"]],[7],[0,\"Release SIM\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"Don't release\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"sim\",\"isActive\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"helper-message\"],[7],[0,\"Releasing a SIM  may take up to 60 seconds. Please be patient.\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/release-sim.hbs"
    }
  });

  _exports.default = _default;
});