define("pconsole/controllers/organization/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    team: Ember.computed.filter('model.team', m => !m.get('isNew') && !m.get('isProgrammatic')),
    programmaticUsers: Ember.computed.filterBy('model.team', 'isProgrammatic', true),
    roles: Ember.computed.alias('model.roles'),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    hasProgrammaticUsers: Ember.computed('programmaticUsers', function () {
      return this.get('programmaticUsers').length > 0;
    }),
    actions: {
      updateOrgTeamMemberRole(product, user, event) {
        const roleId = event.target.value;
        const role = this.store.peekRecord('role', roleId);
        user.set('role', role);
        return user.save().then(() => {
          this.get('notify').success('The user role has been successfully updated');
        }, response => {
          const error = response.errors[0];
          const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
          this.get('notify').alert(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});