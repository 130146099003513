define("pconsole/components/expandable-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    expandableRow: Ember.inject.service(),
    tagName: 'tr',
    classNames: ['main-col'],
    isExpanded: false,
    classNameBindings: ['isExpanded:row-expanded'],
    parentController: undefined,
    listen: function () {
      this.get('expandableRow').on('expand', this, 'determineExpandRow');
    }.on('init'),
    cleanup: function () {
      this.get('expandableRow').off('expand', this, 'determineExpandRow');
    }.on('willDestroyElement'),

    determineExpandRow({
      rowId
    }) {
      const thisRowId = this.get('dataId');

      if (rowId !== thisRowId) {
        this.set('isExpanded', false); // Close any previously open row
      }
    },

    click(e) {
      var _this$get;

      const isSafeClick = Ember.$(e.target).is('.safe');

      if (isSafeClick) {
        return;
      }

      const rowId = this.get('dataId');
      this.get('expandableRow').trigger('expand', {
        rowId
      });
      this.toggleProperty('isExpanded');
      (_this$get = this.get('parentController')) === null || _this$get === void 0 ? void 0 : _this$get.onExpandRow({
        rowId,
        isExpanded: this.get('isExpanded')
      });
    }

  });

  _exports.default = _default;
});