define("pconsole/routes/product/settings/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('product').product;
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'redirect', false);
        Ember.set(controller, 'productName', '');
      }
    }

  });

  _exports.default = _default;
});