define("pconsole/adapters/access-token", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id) {
      return this.get('host') + '/' + this.get('namespace') + '/' + this.pathForType() + '/' + id;
    },

    pathForType() {
      return 'access_tokens';
    },

    namespace: 'v1'
  });

  _exports.default = _default;
});