define("pconsole/templates/components/simple-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tJOf2wgq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"search-box\"],[7],[0,\"\\n  \"],[6,\"input\"],[10,\"class\",[26,[\"search-filter \",[18,\"inputClass\"]]]],[9,\"placeholder\",\"Search\"],[10,\"value\",[18,\"searchValue\"],null],[10,\"oninput\",[25,\"action\",[[19,0,[]],\"filterItems\"],null],null],[7],[8],[0,\"\\n  \"],[6,\"i\"],[10,\"class\",[26,[\"icon ion-close \",[25,\"if\",[[20,[\"searchValue\"]],\"\",\"invisible\"],null]]]],[3,\"action\",[[19,0,[]],\"resetSearch\"]],[7],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/simple-filter.hbs"
    }
  });

  _exports.default = _default;
});