define("pconsole/templates/product/clients/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HOSXZ3ZC",
    "block": "{\"symbols\":[\"scope\",\"isSelected\"],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Edit Client\"],[8],[0,\"\\n\"],[4,\"form-wrapper\",null,null,{\"statements\":[[0,\"      \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"placeholder\"],[\"model.name\",\"name\",\"Client Name\"]]],false],[0,\"\\n\"],[4,\"if\",[[25,\"gt\",[[20,[\"_scopeOptions\",\"length\"]],1],null]],null,{\"statements\":[[4,\"multiselect-checkboxes\",null,[[\"options\",\"labelProperty\",\"valueProperty\",\"selection\",\"onchange\"],[[20,[\"_scopeOptions\"]],\"name\",\"value\",[20,[\"model\",\"selectedScopes\"]],[25,\"action\",[[19,0,[]],\"updateSelection\"],null]]],{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[0,\"\\n          \"],[6,\"label\"],[7],[0,\"\\n            \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[19,2,[]]]]],false],[0,\"\\n            \"],[1,[19,1,[\"value\"]],false],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"token-scope-description\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[0,\"      \"],[1,[25,\"form-submission\",null,[[\"saveText\",\"cancel\",\"disabled\"],[\"Save Client\",false,[20,[\"isInvalid\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/clients/edit.hbs"
    }
  });

  _exports.default = _default;
});