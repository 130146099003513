define("pconsole/controllers/organization/ledger/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    queryParams: ['category'],
    category: 'cloud-ledger',
    scopes: ['Product', 'Device', 'Owner'],
    ledgerDefinition: Ember.computed.alias('model.ledgerDefinition'),
    validForm: Ember.computed.readOnly('ledgerDefinition.validations.isValid'),
    actions: {
      blockSubmit(e) {
        e.preventDefault();
      },

      async save() {
        Ember.$('.loading').show();

        try {
          const ledger = Ember.get(this, 'ledgerDefinition');
          await ledger.save();
          Ember.get(this, 'notify').success('The ledger definition has been created');
          this.transitionToRoute(Ember.get(this, 'routeData.ledgerRoutes').show, ledger.get('name'));
        } catch (_unused) {
          const errorMsg = "We couldn't define your ledger. Try again later.";
          Ember.get(this, 'notify').alert(errorMsg);
        } finally {
          Ember.$('.loading').hide();
        }
      }

    }
  });

  _exports.default = _default;
});