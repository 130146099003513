define("pconsole/models/logic-run", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is the model for the logic/functions/:logicFunctionId/runs API routes
  const LogicRun = _emberData.default.Model.extend({
    logicFunction: _emberData.default.belongsTo('logicFunction'),
    logicFunctionId: _emberData.default.attr('string'),
    logicTriggerType: _emberData.default.attr('string'),
    logicTriggerId: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('date'),
    finishedAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    logFilename: _emberData.default.attr('string'),
    // TODO (carlos h): Change once trigger json is provided
    logicTrigger: Ember.computed('logicFunction.logicTriggers', 'logicTriggerId', function () {
      const triggerId = Ember.get(this, 'logicTriggerId');
      const trigger = Ember.get(this, 'logicFunction.logicTriggers').find(m => m.get('id') === triggerId);
      return trigger;
    }),
    isSuccess: Ember.computed.equal('status', 'Success'),
    runDuration: Ember.computed('startedAt', 'finishedAt', function () {
      const start = Ember.get(this, 'startedAt');
      const end = Ember.get(this, 'finishedAt');
      return "".concat(((end - start) / 1000).toFixed(2), " seconds");
    })
  });

  var _default = LogicRun;
  _exports.default = _default;
});