define("pconsole/components/product-settings", ["exports", "pconsole/mixins/permissions", "ember-cp-validations"], function (_exports, _permissions, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'product.name': (0, _emberCpValidations.validator)('presence', {
      description: 'Product name',
      presence: true
    }),
    'product.quarantine': (0, _emberCpValidations.validator)('presence', true)
  });
  /**
   * Product Settings Form
   *
   * @example
   * ```hbs
   * {{product-settings product=product}}
   * ```
   */

  var _default = Ember.Component.extend(Validations, _permissions.default, {
    classNames: ['product-settings'],
    isDeleteEnabled: false,
    isLoading: false,
    isOwner: false,

    /**
     * Action to be called when deleting a product. First argument is `product`.
     * @returns {void}
     */
    onDeleteClick() {},

    /**
     * Given product
     * @type {Product}
     */
    product: null,
    scope: 'products:update',
    showLocationOptOutConfirmation: false,
    tagName: 'form',
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    showAssetTrackerSettings: Ember.computed('product.platform', function () {
      const isAssetTracker = Ember.get(this, 'product.platform.isAssetTracker');
      return isAssetTracker;
    }),

    async submit(e) {
      const notify = this.get('notify');
      const product = this.get('product');
      e.preventDefault();

      try {
        this.set('isLoading', true);
        await this.validate();

        if (Ember.get(this, 'validations.isTruelyValid')) {
          await product.save();
          notify.success('Product updated successfully!');
          Ember.set(this, 'showLocationOptOutConfirmation', false);
        }
      } catch ({
        errors
      }) {
        const detail = errors && errors[0] && errors[0].detail;
        const message = "There was a problem updating your product; please try again later ".concat(detail ? ':' + detail : '.');
        notify.alert(message);
      } finally {
        this.set('isLoading', false);
      }
    },

    actions: {
      cancelLocationOptOut() {
        if (Ember.get(this, 'product.settings.location.opt_in') === false) {
          Ember.set(this, 'product.settings.location.opt_in', true);
          Ember.set(this, 'showLocationOptOutConfirmation', false);
        }
      },

      handleLocationOptInChange(value) {
        Ember.set(this, 'product.settings.location.opt_in', value);

        if (value === false) {
          Ember.set(this, 'showLocationOptOutConfirmation', true);
        }
      }

    }
  }).reopenClass({
    positionalParams: ['product']
  });

  _exports.default = _default;
});