define("pconsole/components/alert-message", ["exports", "react", "styled-components", "pconsole/components/console-theme", "@particle/react-particle-alert-message"], function (_exports, _react, _styledComponents, _consoleTheme, _reactParticleAlertMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = AlertMessage;

  function AlertMessage(props) {
    return _react.default.createElement(_styledComponents.ThemeProvider, {
      theme: _consoleTheme.default
    }, _react.default.createElement(StyledAlert, props));
  }

  const StyledAlert = (0, _styledComponents.default)(_reactParticleAlertMessage.default).withConfig({
    displayName: "alert-message__StyledAlert",
    componentId: "upn890-0"
  })(["background:transparent;border:1px solid var(--primary-border-color);> svg path{fill:var(--danger-color);}> div{color:var(--danger-color);}> p{color:var(--primary-text-color);}> a{color:var(--secondary-text-color);}"]);
});