define("pconsole/templates/components/sandbox-paused-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kyDd36rK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  Your Sandbox service has been paused\\n\"],[8],[0,\"\\n\\n\"],[6,\"p\"],[7],[0,\"You exceeded the monthly usage limit for your free Sandbox.\"],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"\\n  Service was paused on \\n  \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"devicePauseDate\"]],\"MMM D\",\"utc\"],null],false],[0,\"\\n  and will remain paused until the next usage period, starting\\n  \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"nextBillingPeriodStart\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n\"],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"Please add your devices to an existing paid plan or upgrade to a Growth or Enterprise plan to reactivate your service immediately.\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"user.billing\"],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/sandbox-paused-alert.hbs"
    }
  });

  _exports.default = _default;
});