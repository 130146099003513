define("pconsole/helpers/read-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.readPath = readPath;
  _exports.default = void 0;

  function readPath([object, path]) {
    if (path === 'content') {
      return object;
    }

    return Ember.get(object, path);
  }

  var _default = Ember.Helper.helper(readPath);

  _exports.default = _default;
});