define("pconsole/serializers/network", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      const normalized = {
        'network': payload
      };
      return this._super(store, primaryModelClass, normalized, id, requestType);
    }

  });

  _exports.default = _default;
});