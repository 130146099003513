define("pconsole/helpers/date-from-now", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFromNow = dateFromNow;
  _exports.default = void 0;

  function dateFromNow([ISODate]) {
    if (!ISODate) {
      return '';
    }

    const moment = window.moment;
    return moment(ISODate).fromNow();
  }

  var _default = Ember.Helper.helper(dateFromNow);

  _exports.default = _default;
});