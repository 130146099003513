define("pconsole/templates/components/integration-form-top-rail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8bWy1+gb",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[7],[0,\"\\n  \"],[6,\"a\"],[9,\"class\",\"hooks-docs-link\"],[10,\"href\",[26,[[18,\"docsLink\"]]]],[9,\"target\",\"_blank\"],[7],[6,\"i\"],[9,\"class\",\"ion-document\"],[7],[8],[6,\"span\"],[7],[0,\"Docs\"],[8],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"back\"],[3,\"action\",[[19,0,[]],\"displayInstructions\"]],[7],[6,\"i\"],[9,\"class\",\"ion-wrench\"],[7],[8],[0,\"Setup\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/integration-form-top-rail.hbs"
    }
  });

  _exports.default = _default;
});