define("pconsole/templates/partials/home/products-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J6E+HUpq",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"home-products\"],[7],[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"My products\"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"home-products__list\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"topProducts\"]]],null,{\"statements\":[[4,\"link-to\",[\"product\",[19,1,[\"slug\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"product-card\",null,[[\"product\",\"simpleView\"],[[19,1,[]],\"true\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"no-products\"],[7],[0,\"\\n          \"],[6,\"i\"],[9,\"class\",\"im-product-icon\"],[7],[8],[0,\"\\n          \"],[6,\"p\"],[7],[0,\"Products manage fleets of devices that share a common hardware platform.\"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"model\",\"products\",\"length\"]],0],null]],null,{\"statements\":[[4,\"link-to\",[[25,\"concat\",[[20,[\"parentEntity\"]],\".products.new\"],null]],[[\"class\"],[\"ui button outline primary\"]],{\"statements\":[[0,\"          Create product \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-a\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[25,\"concat\",[[20,[\"parentEntity\"]],\".products\"],null]],[[\"class\"],[\"ui button outline primary\"]],{\"statements\":[[0,\"          See all products \"],[6,\"i\"],[9,\"class\",\"ion-arrow-right-a\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/home/products-list.hbs"
    }
  });

  _exports.default = _default;
});