define("pconsole/components/collapsible-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isExpanded: Ember.computed.alias('section.isExpanded'),

    click(e) {
      const target = Ember.$(e.target);
      const header = '.collapsible-section-heading';
      const isHeader = (target.is(header) || target.parents(header).is(header)) && !target.is('.edit');

      if (isHeader) {
        this.toggleProperty('isExpanded');
      }
    },

    toggleExpand() {
      const _this = this;

      Ember.run.later(this, function () {
        _this.sendAction('expandNextSection', _this.get('section'));
      }, 100);
    },

    uniqueClass: Ember.computed('section.id', function () {
      const section = this.get('section');
      return 'section-' + section.get('id');
    }),
    expandNextOnLoad: Ember.on('init', function () {
      Ember.run.later(this, function () {
        const isDone = this.get('section.isDone');

        if (isDone) {
          this.set('isExpanded', false);
          this.sendAction('expandNextSection', this.get('section'));
        }
      }, 500);
    }),
    isDone: Ember.observer('section.isDone', function () {
      const isDone = this.get('section.isDone');

      if (isDone) {
        Ember.run.debounce(this, this.toggleExpand, 1000, true);
      }
    }),
    isViewMode: Ember.computed.alias('section.isViewMode'),
    isReadOnly: Ember.computed.alias('section.isReadOnly'),
    classNames: ['collapsible-section'],
    classNameBindings: ['isExpanded', 'isViewMode', 'uniqueClass'],
    tagName: 'section',
    actions: {
      edit() {
        const section = this.get('section');
        section.set('isViewMode', false);
        section.save();

        if (!this.get('isExpanded')) {
          this.toggleProperty('isExpanded');
        }
      },

      doneEditing() {
        const section = this.get('section');
        section.set('isViewMode', true);
        section.save();
      }

    }
  });

  _exports.default = _default;
});