define("pconsole/routes/organization/ledger/index/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'organization/ledger/archive',
    controllerName: 'organization/ledger/archive',

    model({
      ledger_name
    }) {
      return this.store.find('ledgerDefinition', ledger_name);
    }

  });

  _exports.default = _default;
});