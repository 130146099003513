define("pconsole/validators/confirmation", ["exports", "ember-cp-validations/validators/confirmation"], function (_exports, _confirmation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _confirmation.default;
    }
  });
});