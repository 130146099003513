define("pconsole/components/toast", ["exports", "react", "prop-types", "@particle/react-particle-toast", "styled-components"], function (_exports, _react, _propTypes, _reactParticleToast, _styledComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  const ToastContainer = _styledComponents.default.div.withConfig({
    displayName: "toast__ToastContainer",
    componentId: "p0o674-0"
  })(["> div{padding-left:14px;color:var(--dark-text-color);&[type=\"info\"]{background-color:var(--info-color-light);border-left-color:var(--info-color);}&[type=\"success\"]{background-color:var(--success-color-light);border-left-color:var(--success-color);}&[type=\"error\"]{background-color:var(--danger-color-light);border-left-color:var(--danger-color);}> div:first-child{display:none;}span.code{background-color:transparent;}}"]);

  function Toast(props) {
    let {
      type
    } = props; // Map ember-notify's `alert` type to `error`

    if (type === 'alert') {
      type = 'error';
    }

    return _react.default.createElement(ToastContainer, null, _react.default.createElement(_reactParticleToast.default, _extends({}, props, {
      type: type
    })));
  }

  Toast.propTypes = {
    type: _propTypes.default.string
  };
  var _default = Toast;
  _exports.default = _default;
});