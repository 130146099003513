define("pconsole/routes/product/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        integrations: this.store.findAll('integration'),
        integrationTypes: this.store.findAll('integrationType').then(types => {
          return types.filter(t => t.get('enabled'));
        }),
        templates: this.store.findAll('integrationTemplate'),
        product: this.modelFor('product').product
      });
    },

    setupController(controller, model) {
      this.controllerFor('user.integrations').setProperties({
        model
      });
    },

    renderTemplate: function renderTemplate() {
      this.render('user.integrations');
    },

    deactivate() {
      const productIntegrations = this.store.peekAll('integration');
      productIntegrations.forEach(i => {
        i.unloadRecord();
      });
    }

  });

  _exports.default = _default;
});