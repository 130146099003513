define("pconsole/templates/product/devices/approve-quarantined-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I0MKG61z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Approve Quarantined Device\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you would like to approve the quarantined device with ID \"],[6,\"strong\"],[7],[1,[20,[\"model\",\"id\"]],false],[8],[0,\"?\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"This will immediately import the device into your product, and give the device the following privileges:\"],[8],[0,\"\\n  \"],[12,\"quarantined-devices-permissions\",[]],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"If you have a released firmware version for this product, this device may immediately update if necessary.\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"approveDevice\"]],[7],[0,\"Approve Device\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, cancel\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/approve-quarantined-device.hbs"
    }
  });

  _exports.default = _default;
});