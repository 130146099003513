define("pconsole/templates/components/event-logs-view-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Wv3mD6F",
    "block": "{\"symbols\":[],\"statements\":[[4,\"event-logs-view\",null,[[\"device\",\"product\",\"auth\",\"hidePublishEvent\",\"successNotify\"],[[20,[\"deviceId\"]],[20,[\"product\"]],[20,[\"authToken\"]],[20,[\"hidePublishEvent\"]],[20,[\"successNotify\"]]]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"_eventlogsnew__content\"],[7],[0,\"\\n\"],[4,\"unless\",[[20,[\"deviceId\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"hint--top\"],[9,\"data-hint\",\"Publish an event\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"toggleShowPublishEvent\"]],[7],[0,\"\\n          \"],[6,\"i\"],[9,\"class\",\"icon ion-ios7-paperplane\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"showPublishEvent\"]]],null,{\"statements\":[[4,\"click-outside\",null,[[\"action\"],[[25,\"action\",[[19,0,[]],\"hidePublishEvent\"],null]]],{\"statements\":[[0,\"          \"],[6,\"div\"],[9,\"class\",\"publish-event-container\"],[7],[0,\"\\n            \"],[1,[25,\"publish-event\",null,[[\"product\"],[[20,[\"fullProduct\"]]]]],false],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/event-logs-view-container.hbs"
    }
  });

  _exports.default = _default;
});