define("pconsole/controllers/user/integrations/webhooks/create", ["exports", "ember-easy-form-extensions/mixins/controllers/form", "pconsole/mixins/integration-devices", "pconsole/mixins/include-device-id-in-integration-response", "pconsole/mixins/webhook-default-fields", "pconsole/mixins/save-integration", "pconsole/mixins/org-integration-products", "ember-validations", "validator"], function (_exports, _form, _integrationDevices, _includeDeviceIdInIntegrationResponse, _webhookDefaultFields, _saveIntegration, _orgIntegrationProducts, _emberValidations, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  // eslint-disable-next-line max-len
  var _default = Ember.Controller.extend(_integrationDevices.default, _saveIntegration.default, _orgIntegrationProducts.orgIntegrationProducts, _webhookDefaultFields.default, _includeDeviceIdInIntegrationResponse.default, _form.default, Ember.Evented, {
    notify: Ember.inject.service(),
    showAdvanced: false,
    customTemplateJSON: {},
    httpBasicAuthUsername: null,
    httpBasicAuthPassword: null,
    jsonEditor: '{}',
    webhook: Ember.computed.alias('integration'),
    // Required for save integration mixin
    integrationType: 'webhooks',
    // reset the form when changing integration
    formInitializer: Ember.observer('webhook', function () {
      this.set('showAdvanced', false);
    }),
    dataFormatOptions: Ember.computed('webhook.requestType', function () {
      const canSendData = this.get('webhook.canSendData');

      if (canSendData) {
        return ['Web Form', 'JSON', 'Custom Body'];
      } else {
        return ['Query Parameters'];
      }
    }),
    dataFormatObserver: Ember.observer('webhook.requestType', function () {
      const dataFormat = this.get('webhook.dataFormat');
      const canSendData = this.get('webhook.canSendData');
      let newDataFormat;

      if (!canSendData && dataFormat !== 'Query Parameters') {
        newDataFormat = 'Query Parameters';
      } else if (canSendData && dataFormat === 'Query Parameters') {
        newDataFormat = 'Web Form';
      } else {
        return;
      }

      this.set('webhook.dataFormat', newDataFormat);
    }),
    webhookAuthObserver: Ember.observer('httpBasicAuthUsername', 'httpBasicAuthPassword', function () {
      const username = this.get('httpBasicAuthUsername');
      const password = this.get('httpBasicAuthPassword');
      const auth = {
        username,
        password
      };

      if (username || password) {
        if (JSON.stringify(this.get('webhook.auth')) !== JSON.stringify(auth)) {
          this.set('webhook.auth', {
            username: username,
            password: password
          });
        }
      } else {
        this.set('webhook.auth', undefined);
      }
    }),
    webhookInitializer: Ember.observer('webhook', function () {
      const auth = this.get('webhook.auth');

      if (auth) {
        this.setProperties({
          httpBasicAuthUsername: auth.username,
          httpBasicAuthPassword: auth.password
        });
      }

      const amazonToken = this.get('webhook.amazonToken');

      if (amazonToken) {
        this.setProperties({
          amazonAccessKeyId: amazonToken.accessKeyId,
          amazonSecretAccessKey: amazonToken.secretAccessKey
        });
      }
    }),

    populateJsonEditor() {
      const json = this.get('webhook.json');
      const defaultFields = this.get('defaultFields');
      let jsonEditor;
      let newNoDefaults = null;
      let newJson = null;

      switch (Ember.typeOf(json)) {
        case 'object':
          newJson = Ember.assign({}, defaultFields, json);
          jsonEditor = JSON.stringify(newJson, true, 2);
          newNoDefaults = true;
          break;

        case 'array':
          jsonEditor = JSON.stringify(json, true, 2);
          newNoDefaults = true;
          break;

        case 'boolean':
          jsonEditor = '{}';
          newNoDefaults = false;
          break;

        case 'string':
          jsonEditor = json;
          newNoDefaults = true;
          break;

        default:
          jsonEditor = '{}';
          break;
      }

      this.set('jsonEditor', jsonEditor);

      if (newNoDefaults !== null && this.get('webhook.noDefaults') !== newNoDefaults) {
        this.set('webhook.noDefaults', newNoDefaults);
      }

      if (newJson !== null && JSON.stringify(this.get('webhook.json')) !== JSON.stringify(newJson)) {
        this.set('webhook.json', newJson);
      }
    },

    jsonEditorInitializer: Ember.observer('webhook', function () {
      this.populateJsonEditor();
    }),

    /*QUERY PARAMS FOR AUTOPOPULATING THE WEBHOOK FORM*/
    queryParams: ['event', 'url', 'requestType', 'deviceID'],
    queryParamsObserver: Ember.observer('event', 'url', 'webhook', 'requestType', 'deviceID', function () {
      const webhook = this.get('webhook');
      const queryParams = this.get('queryParams');

      if (webhook) {
        queryParams.forEach(param => {
          const paramValue = this.get(param);

          if (paramValue) {
            webhook.set(param, paramValue);
          }
        });
      }
    }),

    /*END QUERY PARAMS */

    /**** AWS specific fields ***/
    hasAmazonToken: Ember.computed.notEmpty('webhook.amazonToken'),
    amazonAccessKeyId: null,
    amazonSecretAccessKey: null,
    // When 'virtual' values from inputs change, update the actual field in the webhook
    amazonAuthObserver: Ember.observer('amazonAccessKeyId', 'amazonSecretAccessKey', function () {
      const accessKeyId = this.get('amazonAccessKeyId');
      const secretAccessKey = this.get('amazonSecretAccessKey');
      const amazonToken = {
        accessKeyId,
        secretAccessKey
      };

      if (JSON.stringify(this.get('webhook.amazonToken')) !== JSON.stringify(amazonToken)) {
        this.set('webhook.amazonToken', amazonToken);
      }
    }),

    /**** END AWS specific fields ***/

    /**** Parametrized form ***/
    simpleForm: Ember.computed.alias('webhook.fullTemplate.simpleForm'),
    hasSimpleForm: Ember.computed.notEmpty('simpleForm'),
    displaySimpleForm: false,
    validParameters: Ember.computed('webhook.variables.@each.value', function () {
      if (!this.get('hasSimpleForm')) {
        return true; // If template does not include parameters, they are always valid
      }

      const variables = this.get('webhook.variables') || [];
      return this.get('simpleForm.parameters').every(function (param) {
        const variable = variables.findBy('templateName', param.variableName); // Sensitive variables can be empty as type REFERENCE

        return (variable === null || variable === void 0 ? void 0 : variable.type) === 'REFERENCE' || (variable === null || variable === void 0 ? void 0 : variable.value);
      });
    }),

    /**** END parametrized form ***/
    validations: {
      'webhook.event': {
        presence: true,
        length: {
          maximum: 63
        }
      },
      'webhook.url': (0, _emberValidations.validator)(function () {
        const url = this.model.get('webhook.url');

        if (!url) {
          return "can't be blank";
        } // skip validating the URL when there are template markers


        if (url.match(/{{*[^}]+}}*/)) {
          return;
        }

        if (!_validator.default.isURL(url, {
          allow_underscores: true,
          protocols: ['http', 'https'],
          require_protocol: true
        })) {
          return 'must be a valid HTTP or HTTPS endpoint';
        }
      }),
      'webhook.productIds': (0, _emberValidations.validator)(function () {
        var _this$model$get;

        const organization = (_this$model$get = this.model.get('integration.organization')) === null || _this$model$get === void 0 ? void 0 : _this$model$get.content;
        const products = this.model.get('integration.productIds.length') || 0; // Only validate products if the integration is for orgs

        if (!!organization && products < 1) {
          return 'must select at least one';
        }
      })
    },

    serializeWebhookToCustomTemplate() {
      const webhook = this.get('webhook');
      const json = webhook.serialize();
      delete json.integration_type;

      const cleanJson = _.omitBy(json, _.isNil);

      this.set('customTemplateJSON', cleanJson);
    },

    updateWebhookFromCustomTemplate() {
      var _this$get, _customTemplateJSON$v;

      const customTemplateJSON = this.get('customTemplateJSON');
      const webhook = this.get('webhook');
      const selectedProducts = (_this$get = this.get('model.products')) === null || _this$get === void 0 ? void 0 : _this$get.filter(p => {
        var _customTemplateJSON$p;

        return (_customTemplateJSON$p = customTemplateJSON.product_ids) === null || _customTemplateJSON$p === void 0 ? void 0 : _customTemplateJSON$p.includes(Number(p.id));
      }); // Normalize variables for the webhook model

      const variables = (_customTemplateJSON$v = customTemplateJSON.variables) === null || _customTemplateJSON$v === void 0 ? void 0 : _customTemplateJSON$v.map(variable => {
        const {
          template_name: templateName
        } = variable,
              rest = _objectWithoutProperties(variable, ["template_name"]);

        return _objectSpread({}, rest, {
          templateName
        });
      });
      webhook.setProperties({
        disabled: customTemplateJSON.disabled,
        name: customTemplateJSON.name,
        productIds: selectedProducts || [],
        event: customTemplateJSON.eventName || customTemplateJSON.event,
        url: customTemplateJSON.url,
        requestType: customTemplateJSON.requestType,
        deviceID: customTemplateJSON.deviceID,
        json: customTemplateJSON.json,
        form: customTemplateJSON.form,
        body: customTemplateJSON.body,
        query: customTemplateJSON.query,
        auth: customTemplateJSON.auth,
        headers: customTemplateJSON.headers,
        variables: variables || [],
        noDefaults: customTemplateJSON.noDefaults,
        rejectUnauthorized: customTemplateJSON.rejectUnauthorized,
        azureSasToken: customTemplateJSON.azure_sas_token,
        googleToken: customTemplateJSON.google_token,
        amazonToken: customTemplateJSON.amazon_token,
        responseTemplate: customTemplateJSON.responseTemplate,
        responseTopic: customTemplateJSON.responseTopic,
        errorResponseTopic: customTemplateJSON.errorResponseTopic
      });

      if (customTemplateJSON.auth) {
        this.setProperties({
          httpBasicAuthUsername: customTemplateJSON.auth.username,
          httpBasicAuthPassword: customTemplateJSON.auth.password
        });
      }

      if (customTemplateJSON.amazonToken) {
        this.setProperties({
          amazonAccessKeyId: customTemplateJSON.amazonToken.accessKeyId,
          amazonSecretAccessKey: customTemplateJSON.amazonToken.secretAccessKey
        });
      }

      webhook.setDataFormatFromFields();
      this.populateJsonEditor();
    },

    actions: {
      toggleAdvanced() {
        this.toggleProperty('showAdvanced');
      },

      toggleSimpleForm() {
        this.toggleProperty('displaySimpleForm');
      },

      showCustomTemplate() {
        this.serializeWebhookToCustomTemplate();
      },

      onJSONChange() {
        const jsonEditor = this.get('jsonEditor');

        try {
          const json = JSON.parse(jsonEditor);
          this.set('webhook.json', json);
        } catch (e) {
          this.set('webhook.json', jsonEditor);
        }
      },

      isEditable(editable) {
        return editable;
      },

      onCustomTemplateError() {},

      onCustomTemplateChange() {
        this.updateWebhookFromCustomTemplate();
      }

    }
  });

  _exports.default = _default;
});