define("pconsole/templates/form-inputs/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nq7jSlbl",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",[[25,\"-input-type\",[[20,[\"type\"]]],null]],[[\"id\",\"placeholder\",\"name\",\"type\",\"checked\",\"disabled\",\"tabindex\",\"indeterminate\",\"form\"],[[20,[\"inputId\"]],[20,[\"placeholder\"]],[20,[\"name\"]],[20,[\"type\"]],[20,[\"value\"]],[20,[\"disabled\"]],[20,[\"tabindex\"]],[20,[\"indeterminate\"]],[20,[\"form\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/form-inputs/checkbox.hbs"
    }
  });

  _exports.default = _default;
});