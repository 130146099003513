define("pconsole/components/asset-tracker-popover", ["exports", "react", "prop-types", "styled-components", "formatcoords", "use-error-boundary", "pconsole/utils/date", "react-mapbox-gl", "pconsole/components/device-groups-tag-list", "pconsole/components/asset-tracker-property"], function (_exports, _react, _propTypes, _styledComponents, _formatcoords, _useErrorBoundary, _date, _reactMapboxGl, _deviceGroupsTagList, _assetTrackerProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AssetTrackerPopover = ({
    device,
    isActive = false,
    metadataIndex = 0
  }) => {
    const {
      ErrorBoundary,
      didCatch
      /*, error */

    } = (0, _useErrorBoundary.default)();
    const isHistoric = device.geometry.type === 'LineString';
    const isFolded = isActive && device.foldedCounts && device.foldedCounts[metadataIndex] > 0;
    const properties = Array.isArray(device.foldedProperties) && device.foldedProperties[metadataIndex];
    const coordinates = isHistoric ? device.foldedGeometry.coordinates[metadataIndex] : device.geometry.coordinates;

    function renderCoordinates() {
      const [lng, lat] = coordinates;
      return _react.default.createElement(_react.Fragment, null, _react.default.createElement('dt', null, 'GPS coordinates'), _react.default.createElement('dd', null, (0, _formatcoords.default)(lng, lat, true).format('FFf', {
        decimalPlaces: 2,
        latLonSeparator: '\n'
      })));
    }

    function renderParticleProperties() {
      if (isFolded) {
        return renderCoordinates();
      }

      if (isActive && properties) {
        return _react.default.createElement(_react.Fragment, null, renderCoordinates(), _react.default.createElement(_assetTrackerProperty.default, {
          label: 'GPS accuracy',
          name: 'h_acc',
          obj: properties,
          format: v => "".concat(v, " meters")
        }), _react.default.createElement(_assetTrackerProperty.default, {
          label: 'Heading',
          name: 'hd',
          obj: properties,
          format: v => "".concat(v, "\xB0")
        }), _react.default.createElement(_assetTrackerProperty.default, {
          label: 'Speed',
          name: 'speed',
          obj: properties,
          format: v => "".concat(v, " mph")
        }), _react.default.createElement(_assetTrackerProperty.default, {
          label: 'Altitude',
          name: '2',
          obj: coordinates,
          format: v => "".concat(v, " meters")
        }), _react.default.createElement(_assetTrackerProperty.default, {
          label: 'Cellular signal',
          name: 'cell',
          obj: properties,
          format: v => "".concat(v, "%")
        }), _react.default.createElement(_assetTrackerProperty.default, {
          label: 'Battery charge',
          name: 'batt',
          obj: properties,
          format: v => "".concat(v, "%")
        }));
      }
    }

    function renderCustomProperties() {
      if (isFolded) {
        return _react.default.createElement('span', null, '<multiple>');
      }

      const customProperties = properties && Object.keys(properties).filter(key => !particleProperties.includes(key)).slice(0, 10);

      if (isActive && customProperties && customProperties.length) {
        return _react.default.createElement(_react.Fragment, null, _react.default.createElement(Divider, null), _react.default.createElement(PropertiesList, null, customProperties.map(key => _react.default.createElement(_assetTrackerProperty.default, {
          label: key,
          name: key,
          obj: properties,
          format: v => v.toString(),
          key: key
        }))));
      }
    }

    return _react.default.createElement(_react.Fragment, null, didCatch ? _react.default.createElement(_reactMapboxGl.Popup, {
      coordinates: coordinates,
      offset: 16
    }, _react.default.createElement('p', null, 'There was a problem getting info for this location')) : _react.default.createElement(ErrorBoundary, null, _react.default.createElement(_reactMapboxGl.Popup, {
      coordinates: coordinates,
      offset: 16
    }, _react.default.createElement(Heading, null, device.deviceName), _react.default.createElement(PropertiesList, null, _react.default.createElement(_assetTrackerProperty.default, {
      label: 'Timestamp',
      name: metadataIndex,
      obj: isHistoric ? device.foldedTimestamps : device.timestamps,
      format: v => isHistoric ? "".concat((0, _date.formatDate)(new Date(v.start))).concat(v.end ? ' to ' : '').concat(v.end ? (0, _date.formatDate)(new Date(v.end)) : '') : (0, _date.formatDate)(new Date(v))
    }), _react.default.createElement(_assetTrackerProperty.default, {
      label: 'ID',
      name: 'id',
      obj: device
    }), !isActive && _react.default.createElement(_assetTrackerProperty.default, {
      label: 'Groups',
      name: 'groups',
      obj: device,
      format: v => _react.default.createElement(_deviceGroupsTagList.default, {
        right: true,
        groups: v,
        limit: 3
      })
    }), renderParticleProperties(), !device.gpsLock && device.lastHeard && _react.default.createElement(_assetTrackerProperty.default, {
      label: 'Last heard',
      name: 'lastHeard',
      obj: device,
      format: v => (0, _date.formatDate)(new Date(v))
    })), renderCustomProperties())));
  };

  const particleProperties = ['batt', 'cell', 'gps_src', 'h_acc', 'hd', 'lck', 'speed', 'v_acc'];

  const Heading = _styledComponents.default.h2.withConfig({
    displayName: "asset-tracker-popover__Heading",
    componentId: "g1w3h7-0"
  })(["font-size:1.3712rem;font-weight:bold;margin-bottom:1.12rem;margin-top:0;"]);

  const PropertiesList = _styledComponents.default.dl.withConfig({
    displayName: "asset-tracker-popover__PropertiesList",
    componentId: "g1w3h7-1"
  })(["display:grid;font-size:1.3712rem;grid-template-columns:max-content 1fr;grid-gap:1.12rem 1.6rem;margin-bottom:0;margin-top:0;dt{color:var(--tertiary-text-color);}dd{margin:0;text-align:right;white-space:pre-wrap;}"]);

  const Divider = _styledComponents.default.hr.withConfig({
    displayName: "asset-tracker-popover__Divider",
    componentId: "g1w3h7-2"
  })(["border:none;border-top:1px solid var(--primary-border-color);margin-bottom:1.6rem;margin-top:1.6rem;"]);

  AssetTrackerPopover.propTypes = {
    device: _propTypes.default.object.isRequired,
    isActive: _propTypes.default.bool,
    metadataIndex: _propTypes.default.number
  };
  var _default = AssetTrackerPopover;
  _exports.default = _default;
});