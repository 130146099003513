define("pconsole/components/eventlogs/advanced-button.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AdvancedButton = ({
    onClick,
    suggestionsVisible
  }) => _react.default.createElement('button', {
    className: "ui button outline primary advanced-button ".concat(suggestionsVisible ? 'advanced-button--triangle' : ''),
    onClick: onClick
  }, _react.default.createElement('span', null, 'Advanced'));

  var _default = AdvancedButton;
  _exports.default = _default;
});