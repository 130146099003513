define("pconsole/controllers/user/sims/reactivate", ["exports", "pconsole/mixins/sim/reactivate"], function (_exports, _reactivate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reactivate.default, {
    sim: Ember.computed.alias('model'),
    nextBillDate: Ember.computed('sim.subscription', function () {
      return this.get('sim.subscription.currentPeriodEnd');
    })
  });

  _exports.default = _default;
});