define("pconsole/mixins/routes/ledger/show/instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LedgerInstanceRoute = void 0;
  const LedgerInstanceRoute = {
    templateName: 'organization.ledger.show.instance',
    controllerName: 'organization.ledger.show.instance',

    async model({
      scope_value
    }) {
      const parentRoute = Ember.get(this, 'routeData.ledgerRoutes.show');
      const {
        ledgerDefinition
      } = this.modelFor(parentRoute);
      const ledgerInstance = await this.store.findRecord('ledger-instance', scope_value, {
        reload: true
      });
      return {
        ledgerInstance,
        ledgerDefinition
      };
    },

    resetController(controller) {
      Ember.get(controller, 'ledgerInstance').rollbackAttributes();
      controller.setProperties({
        hasError: false,
        isSaving: false,
        skipClose: false
      });
    }

  };
  _exports.LedgerInstanceRoute = LedgerInstanceRoute;
});