define("pconsole/routes/organization/ledger/create", ["exports", "pconsole/mixins/routes/ledger/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_create.LedgerCreateRoute);

  _exports.default = _default;
});