define("pconsole/templates/components/abbreviated-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+8JHUURW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"span\"],[10,\"class\",[26,[\"object-id safe \",[25,\"if\",[[20,[\"expandId\"]],\"expanded-id\"],null]]]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"expandId\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"id safe full\"],[10,\"title\",[26,[\"Collapse the \",[18,\"idName\"]]]],[3,\"action\",[[19,0,[]],\"toggleExpand\"]],[7],[1,[18,\"fullId\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"id safe abbreviated\"],[10,\"title\",[26,[\"Expand the \",[18,\"idName\"]]]],[3,\"action\",[[19,0,[]],\"toggleExpand\"]],[7],[1,[18,\"abbreviatedId\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"abbrev-actions\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"expandId\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"copy-icon\"],[10,\"title\",[26,[\"Copy the full \",[18,\"idName\"],\" to the clipboard\"]]],[7],[0,\"\\n\"],[4,\"copy-button\",null,[[\"clipboardText\",\"class\",\"success\"],[[20,[\"fullId\"]],\"clipboard\",[25,\"action\",[[19,0,[]],\"afterCopy\"],null]]],{\"statements\":[[0,\"      \"],[6,\"i\"],[9,\"class\",\"ion-clipboard safe\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[2,\"This is used to pass in links to the object in a different view\"],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/abbreviated-id.hbs"
    }
  });

  _exports.default = _default;
});