define("pconsole/controllers/product/location", ["exports", "@sentry/browser", "mapbox-gl"], function (_exports, Sentry, _mapboxGl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = Ember.Controller.extend({
    queryParams: ['groups', 'id', 'name'],
    activeDevice: null,
    deviceUpdates: null,
    forceBounds: null,
    pendingDeviceUpdates: {},
    groups: '',
    highlightedDeviceItem: null,
    highlightedMapFeature: null,
    id: '',
    name: '',
    product: Ember.computed.alias('model.product'),
    groupOptions: Ember.computed.alias('model.groupOptions'),
    hasDevices: Ember.computed.alias('model.hasDevices'),
    mapableDevices: Ember.computed.filter('devices', function (device) {
      return !!device.geometry;
    }),
    segment: Ember.inject.service(),
    visibleDevices: [],
    visibleDevicesInCluster: [],
    flushInterval: null,
    devices: Ember.computed('model.devices.@each.{lastHeard,geometry.type}', 'activeDevice', 'timestamps.[]', function () {
      return Ember.get(this, 'model.devices').map(d => d.toObject());
    }),
    count: Ember.computed('mapableDevices', function () {
      return Ember.get(this, 'mapableDevices').length;
    }),
    // dependencies are intentionally model.devices and forceBounds only
    // so that the map bounds are only re-computed when the search completes
    fitBounds: Ember.computed('model.devices', 'forceBounds', function () {
      const devices = Ember.get(this, 'mapableDevices');
      const activeDeviceId = Ember.get(this, 'activeDevice'); // if no devices, center map on the USA

      if (devices.length === 0) {
        return [[-122.87763214, 26.935339], [-70.565102, 47.251812]];
      }

      const coords = [];

      for (const device of devices) {
        if (activeDeviceId && device.id !== activeDeviceId) {
          continue;
        }

        if (device.geometry.type === 'LineString') {
          for (const lineStringCoords of device.geometry.coordinates) {
            coords.push(lineStringCoords);
          }
        } else {
          coords.push(device.geometry.coordinates);
        }
      }

      if (coords.length === 0) {
        return [[-122.87763214, 26.935339], [-70.565102, 47.251812]];
      }

      return coords.reduce((lngLatBounds, coord) => lngLatBounds.extend(coord), new _mapboxGl.LngLatBounds()).toArray();
    }),

    init() {
      Ember.set(this, 'deviceUpdates', []);
      Ember.set(this, 'pendingDeviceUpdates', {});
      const flush = setInterval(() => {
        // Convert object to array of values to push
        const updates = Ember.get(this, 'pendingDeviceUpdates'); // Update the records on the devices store

        this.store.pushPayload({
          'device-location': Object.values(updates)
        }); // Trigger a device update animation

        Ember.set(this, 'deviceUpdates', Object.keys(updates)); // Clear the pending object

        Ember.set(this, 'pendingDeviceUpdates', {});
      }, 5000);
      Ember.set(this, 'flushInterval', flush);
    },

    willDestroy() {
      // This is only called in tests
      this.clearController();
    },

    clearController() {
      clearInterval(Ember.get(this, 'flushInterval'));
      Ember.get(this, 'eventStream').stopListening();
      Ember.setProperties(this, {
        pendingDeviceUpdates: {},
        visibleDevices: [],
        visibleDevicesInCluster: [],
        groups: '',
        id: '',
        name: ''
      });
    },

    updatesObserver: Ember.observer('product.id', function () {
      const product = Ember.get(this, 'product.id');
      let end, start; // eventStream is injected by the event-stream-wrapper initializer

      const eventStream = Ember.get(this, 'eventStream'); // Set up event stream

      eventStream.startListening({
        name: 'loc',
        product
      });
      eventStream.on('event', event => {
        var _loc;

        const {
          data,
          coreid: coreId
        } = event;
        const devices = Ember.get(this, 'model.devices');
        const deviceLocation = devices.findBy('id', coreId);
        let loc;

        try {
          ({
            loc
          } = JSON.parse(data));
        } catch (ex) {
          Sentry.captureException(ex);
          return;
        }

        if (!((_loc = loc) === null || _loc === void 0 ? void 0 : _loc.lck)) {
          // Toss out updates with no lock
          return;
        }

        const timestamp = new Date(loc.time * 1000);
        const timestampISO = timestamp.toISOString();
        const isInSearch = deviceLocation && (!start && !end || start <= timestamp && timestamp <= end);

        if (isInSearch) {
          const deviceLocationPayload = deviceLocation.serialize({
            includeId: true
          });

          if (!deviceLocationPayload.geometry) {
            return;
          }

          const {
            alt,
            lat,
            lon
          } = loc,
                properties = _objectWithoutProperties(loc, ["alt", "lat", "lon"]);

          delete properties.time;
          const coord = [lon, lat];

          if (alt) {
            coord.push(alt);
          }

          if (deviceLocationPayload.geometry.type === 'LineString') {
            // Properties is not populated for search
            if (!deviceLocationPayload.properties) {
              deviceLocationPayload.properties = Array(deviceLocationPayload.timestamps.length);
            }

            deviceLocationPayload.geometry.coordinates.push(coord);
            deviceLocationPayload.properties.push(properties);
            deviceLocationPayload.timestamps.push(timestampISO);
          } else {
            Ember.setProperties(deviceLocationPayload, {
              updated: true,
              properties: [properties],
              timestamps: [timestampISO]
            });
            Ember.set(deviceLocationPayload, 'geometry.coordinates', coord);
          }

          deviceLocationPayload.last_heard = timestampISO; // Set the new info on pending location updates

          Ember.set(this, 'pendingDeviceUpdates', _objectSpread({}, Ember.get(this, 'pendingDeviceUpdates'), {
            [coreId]: deviceLocationPayload
          }));
        }
      });
    }),
    actions: {
      clear() {
        // was reset clicked without any existing filters?
        if (Object.values(Ember.getProperties(this, 'groups', 'id', 'name')).join('') === '') {
          Ember.set(this, 'forceBounds', Date.now());
        }

        Ember.setProperties(this, {
          groups: '',
          id: '',
          name: ''
        });
      },

      async listItemClick(item) {
        await this.transitionToRoute('product.location.device', item.id);
      },

      search({
        deviceId: id,
        deviceName: name,
        deviceGroups: groups
      }) {
        this.transitionToRoute({
          queryParams: {
            groups,
            id,
            name
          }
        });
      },

      setVisiblePoints(list, inCluster) {
        const visibleDevices = list.map(d => {
          var _d$properties;

          return JSON.parse((d === null || d === void 0 ? void 0 : (_d$properties = d.properties) === null || _d$properties === void 0 ? void 0 : _d$properties.device) || '{}');
        });
        Ember.set(this, 'visibleDevices', visibleDevices);
        Ember.set(this, 'visibleDevicesInCluster', inCluster);
      },

      onDeviceClick(id) {
        this.transitionToRoute('product.location.device', id);
      }

    }
  });

  _exports.default = _default;
});