define("pconsole/components/usage-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsageAlert = Ember.Component.extend({
    /**
     * Given UsageAlert
     * @type {UsageAlert}
     */
    alert: null,
    store: Ember.inject.service(),

    /**
     * Computes the organization slug for the service agreement's owner
     * @type {string}
     */
    organizationSlug: Ember.computed('alert.serviceAgreement.organizationId', function () {
      const organizationId = Ember.get(this, 'alert.serviceAgreement.organizationId');

      if (organizationId) {
        const org = Ember.get(this, 'store').peekRecord('organization', organizationId);
        return Ember.get(org, 'slug');
      }
    }),
    organizationName: Ember.computed('alert.serviceAgreement.organizationId', function () {
      const organizationId = Ember.get(this, 'alert.serviceAgreement.organizationId');

      if (organizationId) {
        const org = Ember.get(this, 'store').peekRecord('organization', organizationId);
        return Ember.get(org, 'name');
      }
    }),
    actions: {
      markShown() {
        Ember.get(this, 'alert').markShown();
      }

    }
  });
  UsageAlert.reopenClass({
    positionalParams: ['alert']
  });
  var _default = UsageAlert;
  _exports.default = _default;
});