define("pconsole/templates/components/sandbox-unpaused-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WRw0axLt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  Your Sandbox service has been unpaused\\n\"],[8],[0,\"\\n\\n\"],[6,\"p\"],[7],[0,\"Last month, your free Sandbox device(s) were paused due to exceeding the monthly usage limit.\"],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"\\n  Service was resumed on the start of this usage period,\\n  \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"devicePauseDate\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n\"],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"Please add your devices to an existing paid plan or upgrade to a Growth or Enterprise plan to avoid further service disruption.\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"user.billing\"],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/sandbox-unpaused-alert.hbs"
    }
  });

  _exports.default = _default;
});