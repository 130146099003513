define("pconsole/models/ledger-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const LedgerCategory = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    // md format
    shortDescription: _emberData.default.attr('string'),
    ledgerDefinition: _emberData.default.attr() // Values of the LedgerDefinition model that are defined by the category

  });

  LedgerCategory.reopenClass({
    FIXTURES: [{
      id: 'cloud-ledger',
      slug: 'cloud-ledger',
      name: 'Cloud Ledger',
      shortDescription: 'Stores information in the cloud without syncing to the edge.',
      description: "### General Information\n* Stores information about a device, product or your entire fleet in the cloud.\n* Data can be read and written by your applications through the Particle API and Logic Functions.\n* Cloud Ledgers cannot be read by a device.\n### Common Use Cases\n* Aggregate data about devices using a Logic Function.\n* Managing device lifecycle data.\n* Parameters for processing in Logic Functions.\n",
      ledgerDefinition: {
        name: 'cloud-ledger',
        direction: 'CloudOnly'
      }
    }, {
      id: 'device-to-cloud',
      slug: 'device-to-cloud',
      name: 'Device to Cloud Ledger',
      shortDescription: 'Syncs device information automatically to the cloud.',
      description: "### General Information\n* Store information on a device in a structured manner.\n* Device OS reliably sends the data to the cloud automatically when device is online.\n* Data can be read by your applications through the Particle API and Logic Functions.\n### Common Use Cases\n* Make latest device state accessible whether device is online or not.\n* Record anomalous events for maintenance and diagnostics.\n* Aggregate data on the edge and make a summary available to the cloud.\n",
      ledgerDefinition: {
        name: 'device-to-cloud',
        direction: 'DeviceToCloud',
        scope: 'Device'
      }
    }, {
      id: 'cloud-to-device',
      slug: 'cloud-to-device',
      name: 'Cloud to Device Ledger',
      shortDescription: 'Syncs down to devices from set data in the cloud.',
      description: "### General Information\n* Stores information about a device, product or your entire fleet in the cloud.\n* Cloud reliably sends latest data to devices as soon as devices get online.\n* Data can be scoped to an individual device, to the devices in a product, or to all your devices.\n* Data can be read and written by your applications through the Particle API and Logic Functions.\n### Common Use Cases\n* Send commands to devices regardless if device is online or not.\n* Setting individual device thresholds or parameters.\n* Implement policies for a fleet of devices.\n",
      ledgerDefinition: {
        name: 'cloud-to-device',
        direction: 'CloudToDevice'
      }
    }]
  });
  var _default = LedgerCategory;
  _exports.default = _default;
});