define("pconsole/controllers/product/sims", ["exports", "pconsole/mixins/object-filter-helpers"], function (_exports, _objectFilterHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_objectFilterHelpers.default, {
    queryParams: ['page', 'perPage', 'iccid', 'deviceId', 'deviceName'],
    application: Ember.inject.controller(),
    sims: Ember.computed.alias('model.sims'),
    hasFilter: Ember.computed('iccid', 'deviceId', 'deviceName', function () {
      return Boolean(this.get('iccid') || this.get('deviceId') || this.get('deviceName'));
    }),
    isProduct: Ember.computed('routeData.hasProduct', function () {
      return this.get('routeData.hasProduct');
    }),
    shouldDisplayPagination: Ember.computed('model.sims.totalPages', function () {
      return this.get('model.sims.totalPages') > 1;
    }),
    shouldDisplayPerPage: Ember.computed('model.sims', function () {
      return this.get('model.sims.length') > 0;
    }),
    showSimTable: Ember.computed('application.currentRouteName', function () {
      const route = this.get('application.currentRouteName');
      return !route.includes('import');
    }),
    actions: {
      pageClicked(page) {
        this.setProperties({
          page: page
        });
      },

      changePerPage() {
        this.setProperties({
          page: 1
        });
        this.send('refreshModel');
      }

    }
  });

  _exports.default = _default;
});