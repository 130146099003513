define("pconsole/components/asset-tracker-datetime-search", ["exports", "react", "prop-types", "styled-components", "moment", "pconsole/lib/hooks", "pconsole/components/asset-tracker-datetime-range-picker"], function (_exports, _react, _propTypes, _styledComponents, _moment, _hooks, _assetTrackerDatetimeRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const local = {
    'format': 'MM/DD/YYYY HH:mm',
    sundayFirst: true
  };

  const AssetTrackerDatetimeSearch = ({
    onSearch,
    metrics,
    dateRange
  }) => {
    const dateRangeStart = dateRange && (0, _moment.default)(dateRange[0]);
    const dateRangeEnd = dateRange && (0, _moment.default)(dateRange[1]);
    const [dates, setDates] = (0, _hooks.useStateCallback)({
      start: dateRangeStart,
      end: dateRangeEnd
    });
    const formattedDateRangeStart = dates.start && dates.start.format(local.format) || '';
    const formattedDateRangeEnd = dates.end && dates.end.format(local.format) || '';
    (0, _react.useEffect)(() => {
      setDates({
        start: dateRangeStart,
        end: dateRangeEnd
      });
    }, [dateRange]);

    const search = params => {
      metrics.trackEvent('search submitted');

      if (!params.dateRange[0]) {
        onSearch({});
      } else {
        onSearch({
          dateRange: [params.dateRange[0], params.dateRange[1]]
        });
      }
    };

    return _react.default.createElement(_assetTrackerDatetimeRangePicker.default, {
      end: dates.end,
      start: dates.start,
      local: local,
      applyCallback: (start, end) => {
        // Use a magic number to represent "no date"
        if (start && start.isSame('1969-07-20')) {
          setDates({
            start: null,
            end: null
          }, () => search({
            dateRange: [null, null]
          }));
        } else {
          setDates({
            start,
            end
          }, () => search({
            dateRange: [start, end]
          }));
        }
      }
    }, _react.default.createElement(DatePickerTrigger, null, _react.default.createElement('input', {
      type: 'text',
      readOnly: true,
      value: formattedDateRangeStart ? "".concat(formattedDateRangeStart, " - ").concat(formattedDateRangeEnd) : 'Last Known'
    }), formattedDateRangeStart && _react.default.createElement(ClearButton, {
      type: 'button',
      onClick: e => {
        e.preventDefault();
        setDates({
          start: null,
          end: null
        }, () => search({
          dateRange: [null, null]
        }));
      }
    }, _react.default.createElement('i', {
      className: 'ion-close'
    }))));
  };

  AssetTrackerDatetimeSearch.propTypes = {
    onSearch: _propTypes.default.func.isRequired,
    metrics: _propTypes.default.object.isRequired,
    dateRange: _propTypes.default.array
  };

  const ClearButton = _styledComponents.default.button.withConfig({
    displayName: "asset-tracker-datetime-search__ClearButton",
    componentId: "sc-1uzwh7m-0"
  })(["background:transparent;border:none;"]);

  const DatePickerTrigger = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-datetime-search__DatePickerTrigger",
    componentId: "sc-1uzwh7m-1"
  })(["position:relative;&::before{content:\"\f162\";display:block;font-family:var(--icons-font);font-size:2.8rem;height:100%;left:0;line-height:3.6rem;position:absolute;text-align:center;top:0;width:4.4rem;}input{margin-bottom:0;padding:1.04rem 3.2rem 1.04rem 4.4rem;}", "{height:100%;position:absolute;right:0;top:0;width:3.2rem;}"], ClearButton);

  var _default = AssetTrackerDatetimeSearch;
  _exports.default = _default;
});