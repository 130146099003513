define("pconsole/components/logic-trigger-editor", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = Ember.Component.extend(_form.default, {
    store: Ember.inject.service(),
    classNames: ['trigger-editor'],
    tag: 'section',
    isExpanded: true,
    isExpandable: true,
    readOnly: false,
    typeOptions: ['Event', 'Scheduled', 'Ledger Change'],

    save() {},

    actions: {
      copyTrigger() {
        const logicFunction = Ember.get(this, 'logicFunction');

        const _EmberGet$toJSON = Ember.get(this, 'logicTrigger').toJSON(),
              {
          id
        } = _EmberGet$toJSON,
              logicTrigger = _objectWithoutProperties(_EmberGet$toJSON, ["id"]);

        Ember.get(this, 'store').createRecord('logic-trigger', _objectSpread({}, logicTrigger, {
          logicFunction
        }));
      },

      deleteTrigger() {
        const triggers = Ember.get(this, 'logicFunction.logicTriggers');
        const trigger = Ember.get(this, 'logicTrigger');
        triggers.removeObject(trigger);
        trigger.deleteRecord();
      },

      toggleExpanded() {
        this.toggleProperty('isExpanded');
      }

    }
  });

  _exports.default = _default;
});