define("pconsole/services/import-devices-response", ["exports", "lodash/escape"], function (_exports, _escape) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notify: Ember.inject.service(),

    handleResponse(controller, importedDevicesResult) {
      Ember.$('.loading').hide();
      const addedDocuments = importedDevicesResult.get('updated');
      const alreadyOwnedDevices = importedDevicesResult.get('nonmemberDeviceIds');
      const areAlreadyOwnedDevices = alreadyOwnedDevices.get('length') > 0;
      const invalidDevices = importedDevicesResult.get('invalidDeviceIds');
      const areInvalidDevices = invalidDevices.get('length') > 0;
      const invalidDevicesElements = [];
      invalidDevices.forEach(function (deviceId) {
        invalidDevicesElements.push('<strong>' + (0, _escape.default)(deviceId) + '</strong>');
      });
      controller.transitionToRoute('product.devices').then(() => {
        controller.send('refreshModel');

        if (areAlreadyOwnedDevices || areInvalidDevices) {
          const intro = 'Some devices could not be imported. ';
          const alreadyOwnedMessage = 'The following devices belong to someone outside of your organization: <strong>' + alreadyOwnedDevices + '</strong>';
          const areInvalidMessage = 'The following devices had invalid IDs:' + invalidDevicesElements.join('');
          const end = 'Please double check your device IDs and try again.';
          let message = intro;

          if (areAlreadyOwnedDevices && areInvalidDevices) {
            message += alreadyOwnedMessage + areInvalidMessage;
          } else if (!areInvalidDevices) {
            message += alreadyOwnedMessage;
          } else if (!areAlreadyOwnedDevices) {
            message += areInvalidMessage;
          }

          message += end;
          const options = {
            html: "<div class='import-notify'>" + message + '</div>',
            closeAfter: 15000
          };

          if (addedDocuments === 0) {
            this.get('notify').alert(options);
          } else {
            this.get('notify').warning(options);
          }
        } else {
          this.get('notify').success('The device(s) have been added to your product successfully!');
        }

        controller.get('store').unloadRecord(importedDevicesResult);
      });
    }

  });

  _exports.default = _default;
});