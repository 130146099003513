define("pconsole/mixins/embed-listeners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmbedListeners = void 0;
  const EMBED_TIMEOUT = 120000; // 2 minutes

  const EmbedListeners = {
    /**
     * Needs to be provided by the route that implements this extension
     * Should be the name of the controller attached to the route
     * @type string
     */
    controllerRoute: null,

    /**
     * Needs to be provided by the route that implements this extension
     * QuerySelector to identify the iframe in case a height update message is provided
     * @type string
     */
    iframeSelector: null,
    timer: null,
    listener: function listener(event) {
      // Accept production and testing Mode domains only
      if (!(event.origin === 'https://app.mode.com' || event.origin === 'https://modesandbox.com')) {
        return;
      }

      const controller = this.controllerFor(Ember.get(this, 'controllerRoute'));

      if (event.data.ready) {
        Ember.set(controller, 'loading', false); // Stop timeout counter

        clearTimeout(Ember.get(this, 'timer'));
      } // When report loads and height is updated


      if (event.data.height) {
        Ember.$(Ember.get(this, 'iframeClass')).height(event.data.height);
      }
    },

    activate() {
      const controller = this.controllerFor(Ember.get(this, 'controllerRoute')); // Set controller as loading and add listener

      Ember.set(controller, 'loading', true);
      const boundListener = this.listener.bind(this);
      Ember.set(this, 'boundListener', boundListener);
      window.addEventListener('message', boundListener); // NOTE: This implementation is flawed since the timeout is just an estimantion
      // but iframes have no 'onerror' handling so our best guess is to just assume a time for loading
      // Start timer

      Ember.set(this, 'timer', setTimeout(function () {
        // If time runs out remove loader and display error
        Ember.set(controller, 'error', true);
      }, EMBED_TIMEOUT));
    },

    deactivate() {
      // Remove listener and timer
      clearTimeout(Ember.get(this, 'timer'));
      window.removeEventListener('message', Ember.get(this, 'boundListener'));
    }

  };
  _exports.EmbedListeners = EmbedListeners;
});