define("pconsole/components/device-diagnostics", ["exports", "pconsole/mixins/export-to-csv-request", "pconsole/utils/permissions"], function (_exports, _exportToCsvRequest, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const moment = window.moment;
  const vitalsFields = [{
    path: 'device.network.signal.strength',
    units: '%',
    label: 'cellular signal strength',
    description: 'The strength of the device’s connection to the network, measured in decibels of received signal power. The raw signal strength is {secondary}. Both signal strength and quality impact the health of the connection.',
    round: true,
    secondary: {
      path: 'device.network.signal.strengthv',
      units: 'dBm'
    }
  }, {
    path: 'device.network.signal.quality',
    units: '%',
    label: 'cellular signal quality',
    description: 'The quality of the device’s connection to the network, measured in decibels. The raw signal quality is {secondary}. Both signal strength and quality impact the health of the connection.',
    round: true,
    secondary: {
      path: 'device.network.signal.qualityv',
      units: 'dB'
    }
  }, {
    path: 'device.network.cellular' // custom display in device-diagnostics-diagnostic.hbs

  }, {
    path: 'service.coap.round_trip',
    units: 'ms',
    label: 'round-trip time',
    description: 'The amount of time it takes for the device to successfully respond to a CoAP message sent by the Particle Cloud in milliseconds.'
  }, {
    path: 'device.system.memory.used',
    units: 'B',
    // the K prefix will be added by the large-number-shortener helper
    label: 'of {secondary} RAM used',
    description: 'The amount of memory used by the device, combining the heap and the user application’s static RAM in bytes',
    secondary: {
      path: 'device.system.memory.total',
      units: 'B'
    }
  }, {
    path: 'device.power.battery.charge',
    units: '%',
    label: 'battery charge',
    description: 'The state of charge of the device’s connected battery, represented as a percentage.',
    round: true,
    secondary: {
      path: 'device.power.battery.state'
    }
  }, {
    path: 'device.cloud.connection.disconnects',
    label: 'cloud disconnect events',
    description: 'The number of times the device disconnected unexpectedly from the Particle Cloud since its last reset.'
  }, {
    path: 'device.cloud.publish.rate_limited',
    label: 'rate-limited publishes',
    description: 'Particle devices are allowed to publish an average of 1 event per second in application firmware. Publishing at a rate higher than this will result in rate limiting of events.'
  }];

  var _default = Ember.Component.extend(_exportToCsvRequest.default, {
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    device: null,
    vitals: [],
    vitalsFirmware: 0,
    vitalsLoaded: false,
    vitalsUpdatedAt: null,
    signalType: null,
    downloadInProgress: false,
    partial: false,
    updateDisabled: Ember.computed('device.product', function () {
      // Don't allow update requests for partial diagnostics
      if (this.get('partial')) {
        return true;
      }

      const scope = 'devices.diagnostics:update';
      const productModel = this.get('device.product');
      const store = this.get('store');
      return this.get('routeData.hasProduct') && !(0, _permissions.checkScope)({
        scope,
        productModel,
        store
      });
    }),
    preCheckDiagnostics: function () {
      this.checkFirmwareVersionForVitals();
      this.get('eventStream').on('event', this, this.handleDiagnosticEvent);
      return this.fetchLatest();
    }.on('init'),
    checkFirmwareVersionForVitals: function checkFirmwareVersionForVitals() {
      if (this.get('device.canSupportDeviceVitals')) {
        this.set('vitalsFirmware', 1);
      } else {
        this.set('vitalsFirmware', -1);
      }
    },

    willDestroyElement() {
      this.get('eventStream').off('event', this, this.handleDiagnosticEvent);
    },

    handleDiagnosticEvent(event) {
      if (event.name === 'spark/device/diagnostics/update') {
        this.setVitalsFromResponse(JSON.parse(event.data), event.published_at);
        this.set('vitalsLoaded', true);
      }
    },

    async fetchLatest() {
      // Load the latest reported diagnostics without going down to the device
      this.set('vitalsLoaded', false);
      const deviceId = this.get('device.id');
      const lastUrl = "/v1/".concat(this.getDiagnosticsFragment(), "/").concat(deviceId, "/last");

      try {
        const response = await this.get('apiAjax').getRequest({
          url: lastUrl,
          timeout: 5000
        });
        const {
          diagnostics: {
            payload,
            updated_at: updatedAt
          }
        } = response;
        this.setVitalsFromResponse(payload, updatedAt);
      } catch (error) {// Ignore errors here because if the device has no vitals, we'll display "No recorded vitals for this device."
      }

      this.set('vitalsLoaded', true);
    },

    async refreshDiagnostics() {
      // Go down to the device to refresh diagnostics now
      this.set('vitalsLoaded', false);
      const deviceId = this.get('device.id');
      const lastUrl = "/v1/".concat(this.getDiagnosticsFragment(), "/").concat(deviceId, "/update");

      try {
        await this.get('apiAjax').postRequest({
          url: lastUrl,
          timeout: 30000
        }); // The actual vitals will be updated from the event stream
        // That will also set vitalsLoaded
      } catch (error) {
        // Device is offline so no vitals refresh will be sent to the device
        if (error.status === 408) {
          this.notifyDeviceUnreachable();
          this.set('vitalsLoaded', true);
        } // Ignore other errors here because if the device is not reacheable, this will be posted to the event stream

      }
    },

    setVitalsFromResponse(payload, updatedAt) {
      if (Ember.get(payload, 'service.device.status') === 'unreachable') {
        this.notifyDeviceUnreachable();
        return;
      }

      const vitals = [];
      const signalType = Ember.get(payload, 'device.network.signal.at');

      for (const vitalField of vitalsFields) {
        const {
          path
        } = vitalField;
        let rawValue = Ember.get(payload, path);

        if ( // Don't display fields without a value in the diagnostic payload or fields reporting an error
        rawValue === null || rawValue === undefined || rawValue.err || // Don't display battery charge if it is reported to be 0%.
        // This often happens when there is an onboard power regulator on a device like the Electron, but no battery is attached.
        path === 'device.power.battery.charge' && rawValue === 0 // Only show signal quality and cellular operator, access technology, and locaiton for cellular devices
        || (path === 'device.network.signal.quality' || path === 'device.network.cellular') && signalType === 'Wi-Fi') {
          continue;
        } // discard decimals for vitals like signal strength and quality percentage


        if (vitalField.round) {
          rawValue = Math.round(rawValue);
        }

        const value = "".concat(rawValue).concat(vitalField.units || '');
        const rawSecondary = vitalField.secondary && Ember.get(payload, vitalField.secondary.path);
        const secondary = rawSecondary && "".concat(rawSecondary).concat(vitalField.secondary.units || '');
        const description = vitalField.description && vitalField.description.replace(new RegExp('{secondary}', 'g'), secondary);
        const label = vitalField.label && vitalField.label.replace(new RegExp('{secondary}', 'g'), secondary);
        const vital = {
          path,
          value,
          label,
          description,
          secondary,
          rawValue,
          rawSecondary
        };
        vitals.push(vital);
      }

      this.set('signalType', signalType);
      this.set('vitalsUpdatedAt', moment(updatedAt).format('MMM Do, YYYY, hh:mmA'));
      this.set('vitals', vitals);
    },

    notifyDeviceUnreachable() {
      this.get('notify').alert('This device is currently unreachable by the Device Cloud. Displaying last recorded device vitals.');
    },

    actions: {
      async downloadHistory() {
        if (this.get('downloadInProgress')) {
          return;
        }

        const product = this.get('routeData.product');
        const deviceId = this.get('device').get('id');
        const url = product ? "/v1/products/".concat(product, "/").concat(this.getDiagnosticsFragment(), "/").concat(deviceId) : "/v1/".concat(this.getDiagnosticsFragment(), "/").concat(deviceId);
        this.set('downloadInProgress', true);

        try {
          await this.makeCSVRequest(url);
        } finally {
          this.set('downloadInProgress', false);
        }
      },

      refreshDiagnosticsFromVitals() {
        this.refreshDiagnostics();
      }

    },

    getDiagnosticsFragment() {
      if (this.partial) {
        return 'partialdiagnostics';
      }

      return 'diagnostics';
    }

  });

  _exports.default = _default;
});