define("pconsole/templates/product/map/index/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3sMeO+CN",
    "block": "{\"symbols\":[\"close\"],\"statements\":[[4,\"fleet-modal\",null,[[\"class\",\"close\"],[\"asset-tracker-fleet-config-modal\",[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[7],[0,\"Gateway fleet settings\"],[8],[0,\"\\n  \"],[6,\"hr\"],[7],[8],[0,\"\\n  \"],[6,\"br\"],[7],[8],[0,\"\\n  \"],[4,\"asset-tracker-configuration-form\",null,[[\"fleetSettings\",\"isDevelopment\",\"formData\",\"schema\",\"metaschema\",\"onChange\",\"onSubmit\",\"docsURL\"],[true,true,[20,[\"configuration\"]],[20,[\"schema\"]],[20,[\"metaschema\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"configuration\"]]],null]],[[\"value\"],[\"formData\"]]],[25,\"action\",[[19,0,[]],\"saveAssetTrackerFleetSettings\"],null],\"https://docs.particle.io/tutorials/device-cloud/console/#device-fleet-settings\"]],{\"statements\":[[0,\"\\n    \"],[4,\"component\",[\"grid\"],[[\"columns\"],[\"3\"]],{\"statements\":[[0,\"\\n      \"],[4,\"grid-column\",null,null,{\"statements\":[[0,\"\\n        \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"ui outline secondary fluid button\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"Cancel\"],[8],[0,\"\\n      \"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"grid-column\",null,[[\"width\"],[\"2\"]],{\"statements\":[[0,\"\\n        \"],[6,\"button\"],[9,\"type\",\"submit\"],[10,\"class\",[26,[\"ui primary outline fluid button \",[25,\"if\",[[20,[\"isSaving\"]],\"loading\",\"\"],null]]]],[7],[0,\"\\n          Save and apply\\n        \"],[8],[0,\"\\n      \"]],\"parameters\":[]},null],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[1,[18,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/map/index/config.hbs"
    }
  });

  _exports.default = _default;
});