define("pconsole/components/asset-tracker-v2-sources", ["exports", "react", "@turf/circle", "@turf/union", "react-mapbox-gl", "pconsole/utils/average-group-color"], function (_exports, _react, _circle, _union, _reactMapboxGl, _averageGroupColor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const baseSourceObj = {
    type: 'geojson',
    cluster: false,
    clusterMaxZoom: 9,
    clusterRadius: 30,
    data: {
      type: 'FeatureCollection',
      features: []
    }
  };
  const baseFoldedSource = {
    tolerance: 0,
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  };

  const getDeviceProperties = device => ({
    device,
    id: device.id,
    color: (0, _averageGroupColor.default)(device.groups, '#00d2e6')
  });

  const getAccuracyPolygon = (device, properties) => {
    const accuracyKm = Math.min((device.horizontalAccuracy || 0) / 1000, 5);
    const polygon = (0, _circle.default)(device.geometry.coordinates, accuracyKm);
    polygon.properties = properties;
    return polygon;
  };
  /*
   * MapboxGL does not work well with adding/removing Sources from the map, so
   * they are always rendered and their configuration is updated
   */


  const AssetTrackerV2Sources = ({
    devices = [],
    visibleDevices = [],
    activeDevice
  }) => {
    const [devicesSource, setDevicesSource] = (0, _react.useState)(Object.assign({}, baseSourceObj, {
      cluster: true
    }));
    const [accuracySource, setAccuracySource] = (0, _react.useState)(baseSourceObj);
    const [deviceSource, setDeviceSource] = (0, _react.useState)(baseSourceObj);
    const [foldedPointsOpts, setFoldedPointsOpts] = (0, _react.useState)(baseFoldedSource); // Update devices source

    (0, _react.useEffect)(() => {
      const deviceFeatures = devices.map((device, idx) => {
        const properties = getDeviceProperties(device);
        return {
          type: 'Feature',
          id: idx,
          geometry: device.geometry,
          properties
        };
      });
      setDevicesSource(Object.assign({}, devicesSource, {
        data: {
          type: 'FeatureCollection',
          features: deviceFeatures
        }
      }));
    }, [devices]); // Update accuracy source

    (0, _react.useEffect)(() => {
      const accuracyFeatures = [];
      visibleDevices.forEach((device, idx) => {
        const properties = getDeviceProperties(device);
        accuracyFeatures.push(getAccuracyPolygon(device, properties));
      });
      let feature = {
        type: 'FeatureCollection',
        features: accuracyFeatures
      };

      if (accuracyFeatures.length > 2) {
        feature = (0, _union.default)(feature);
      }

      setAccuracySource(Object.assign({}, accuracySource, {
        data: feature
      }));
    }, [visibleDevices]); // Update single device source

    (0, _react.useEffect)(() => {
      if (!activeDevice) {
        setDeviceSource(baseSourceObj);
      } else {
        const {
          geometry,
          horizontalAccuracy,
          id,
          foldedGeometry,
          foldedAccuracies,
          groups
        } = activeDevice;
        const properties = getDeviceProperties(activeDevice);
        const data = {
          type: 'FeatureCollection',
          features: [{
            id,
            type: 'Feature',
            geometry: geometry.type === 'LineString' ? foldedGeometry : geometry,
            properties
          }]
        };
        setDeviceSource(Object.assign({}, deviceSource, {
          data
        }));
        let feature;
        const isLine = activeDevice.geometry.type === 'LineString' && activeDevice.foldedCounts;

        if (isLine) {
          const features = [];
          foldedGeometry.coordinates.forEach((coordinates, idx) => {
            features.push(getAccuracyPolygon({
              horizontalAccuracy: foldedAccuracies[idx],
              geometry: {
                coordinates
              }
            }, properties));
          });
          feature = (0, _union.default)({
            type: 'FeatureCollection',
            features
          });
        } else {
          feature = {
            type: 'FeatureCollection',
            features: [getAccuracyPolygon(activeDevice, properties)]
          };
        }

        setAccuracySource(Object.assign({}, accuracySource, {
          data: feature
        }));
      }
    }, [activeDevice]); // Update folded points source 

    (0, _react.useEffect)(() => {
      if (!activeDevice) {
        return setFoldedPointsOpts(baseFoldedSource);
      }

      const isLine = activeDevice.geometry.type === 'LineString' && activeDevice.foldedCounts;

      if (isLine) {
        const features = activeDevice.foldedCounts.map((count, i) => count > 0 && {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: activeDevice.foldedGeometry.coordinates[i]
          },
          properties: {
            // Number of points folded is folds + 1
            count: activeDevice.foldedCounts[i] + 1
          }
        }).filter(Boolean);
        setFoldedPointsOpts(Object.assign({}, baseFoldedSource, {
          data: {
            type: 'FeatureCollection',
            features
          }
        }));
      } else {
        setFoldedPointsOpts(baseFoldedSource);
      }
    }, [activeDevice, devices]);
    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_reactMapboxGl.Source, {
      id: 'devices',
      geoJsonSource: devicesSource
    }), _react.default.createElement(_reactMapboxGl.Source, {
      id: 'device',
      geoJsonSource: deviceSource
    }), _react.default.createElement(_reactMapboxGl.Source, {
      id: 'accuracy',
      geoJsonSource: accuracySource
    }), _react.default.createElement(_reactMapboxGl.Source, {
      id: 'foldedPoints',
      geoJsonSource: foldedPointsOpts
    }));
  };

  var _default = AssetTrackerV2Sources;
  _exports.default = _default;
});