define("pconsole/components/eventlogs/event-loader.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const EventLoader = () => _react.default.createElement('div', {
    className: '_eventlogsnew__content__events__loader'
  }, _react.default.createElement('div', {
    className: '_eventlogsnew__content__events__loader__content'
  }, _react.default.createElement('i', {
    className: 'ion-loading-c'
  }), ' Waiting for events'));

  var _default = EventLoader;
  _exports.default = _default;
});