define("pconsole/mixins/get-group-device-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    apiAjax: Ember.inject.service(),

    getImpact(action, options) {
      this.set('numberOfImpactedDevices', null);
      this.set('impactedFirmwareVersions', null);
      this.set('impactedFirmwareVersionsFetched', false);
      const product = this.get('routeData').product;
      return this.get('apiAjax').request({
        url: "/v1/products/".concat(product, "/impact?action=").concat(action, "&firmware_version=").concat(options.version, "&groups=").concat(options.groups, "&product_default=").concat(options.productDefault),
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
      }).then(response => {
        const impactedDevices = response.devices.firmware_update;
        this.set('numberOfImpactedDevices', impactedDevices.total.toString());
        this.set('impactedFirmwareVersions', response.devices.firmware_update.by_version);
        this.set('impactedFirmwareVersionsFetched', true);
      });
    }

  });

  _exports.default = _default;
});