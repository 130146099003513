define("pconsole/adapters/ledger-instance", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('routeData.org', 'routeData.ledger', function () {
      const org = Ember.get(this, 'routeData.org');
      const ledger = Ember.get(this, 'routeData.ledger');
      let orgParam = '';

      if (org) {
        orgParam = "/orgs/".concat(org);
      }

      return "v1".concat(orgParam, "/ledgers/").concat(ledger);
    }),

    urlForUpdateRecord(id) {
      return "".concat(this.urlPrefix(), "/instances/").concat(id, "?set_mode=replace");
    },

    urlForCreateRecord(model, snapshot) {
      const scope = snapshot.attr('scope');
      const isOwner = !scope || scope.type === 'Owner';
      const id = isOwner ? 'owner' : scope.value;
      return "".concat(this.urlPrefix(), "/instances/").concat(id, "?set_mode=replace");
    },

    urlForDeleteRecord(id) {
      return "".concat(this.urlPrefix(), "/instances/").concat(id);
    },

    urlForFindRecord(id) {
      return "".concat(this.urlPrefix(), "/instances/").concat(id);
    },

    createRecord(store, type, snapshot) {
      let data = {};
      const serializer = store.serializerFor(type.modelName);
      const url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      return this.ajax(url, 'PUT', {
        data
      });
    },

    pathForType() {
      return 'instances';
    }

  });

  _exports.default = _default;
});