define("pconsole/routes/product/choose-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO (carlos h): Remove
  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        plans: this.store.query('plan', {
          billable_area: 'platform'
        }),
        planGroups: this.store.findAll('planGroup'),
        planCommonFeatures: this.store.findAll('planCommonFeature'),
        subscription: this.modelFor('product').subscription
      });
    },

    afterModel(model) {
      const plans = model.plans;
      const planGroups = model.planGroups;
      const subscription = model.subscription;
      plans.forEach(p => {
        // Get the slug of the plan
        const planSlug = p.get('name'); // See if the name of the plan groups is included in the slug

        const matchingPlanGroup = planGroups.find(function nameMatchesSlug(planGroup) {
          return planSlug.search(planGroup.get('name')) !== -1;
        }); // Add the plan to the plan groups list of related plans

        matchingPlanGroup.get('plans').pushObject(p);

        if (p.get('slug') === subscription.get('plan.slug')) {
          planGroups.setEach('current', false);
          matchingPlanGroup.set('current', true);
        } // Set the plan groups attriubtes that should be data driven


        if (p.get('interval') === 'year') {
          matchingPlanGroup.set('amount', p.get('monthlyAmount'));
        }

        if (p.get('interval') === 'month') {
          matchingPlanGroup.set('monthlyIntervalAmount', p.get('monthlyAmount'));
        }

        if (!matchingPlanGroup.get('deviceLimit')) {
          matchingPlanGroup.set('deviceLimit', p.get('deviceLimit'));
        }

        if (!matchingPlanGroup.get('outboundEventLimit')) {
          matchingPlanGroup.set('outboundEventLimit', p.get('outboundEventLimit'));
        }

        if (!matchingPlanGroup.get('seatLimit')) {
          matchingPlanGroup.set('seatLimit', p.get('seatLimit'));
        }

        if (!matchingPlanGroup.get('groupLimit')) {
          matchingPlanGroup.set('groupLimit', p.get('groupLimit'));
        }
      });
    },

    actions: {
      refresh() {
        this.refresh();
      },

      error(error, transition) {
        transition.abort();
        this.transitionTo('product.devices').then(() => {
          this.get('notify').warning({
            html: '<span>We were unable to redirect you to the "Change Product Plan" page.<br/><br/>You most likely were redirected there because you tried to take an action that exceeded your plan\'s limit. Only the product owner can change your plan.<br/><br/>To complete this action, ask your product owner to upgrade your product plan and try again.</span>',
            closeAfter: 15000
          });
        });
      }

    }
  });

  _exports.default = _default;
});