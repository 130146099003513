define("pconsole/components/device-functions", ["exports", "pconsole/mixins/device-variables-functions-helpers", "pconsole/mixins/permissions"], function (_exports, _deviceVariablesFunctionsHelpers, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, _deviceVariablesFunctionsHelpers.default, {
    functions: null,
    scope: 'devices.function:call',

    _fetchDeviceFunctions() {
      this.set('loadingFunctions', true);
      return this.get('apiAjax').getRequest({
        url: this._constructUrl()
      }).then(response => response, this._error.bind(this, 'Failed to fetch device functions'));
    },

    _saveDeviceFunctions(response) {
      if (this.get('isDestroyed')) {
        return;
      }

      this.set('loadingFunctions', false);

      this._updateDeviceAttributes(response);

      if (response && response.functions) {
        const functions = response.functions;
        let fn = {};
        functions.forEach(item => {
          fn[item] = {
            name: item,
            argument: '',
            result: null
          };
        });

        if (functions.length < 1) {
          fn = null;
        }

        this.set('functions', fn);
      }
    },

    _updateDeviceAttributes(response) {
      const device = this.get('device');

      if (response && response.serial_number) {
        device.set('serialNumber', response.serial_number);
      }

      if (response && response.imei) {
        device.set('imei', response.imei);
      }
    },

    _updateFunctionProperty(name, property, value) {
      const functions = this.get('functions');
      Ember.set(functions[name], property, value);
      this.set('functions', functions);
    },

    _showLoader(fn) {
      this._updateFunctionProperty(fn, 'loading', true);
    },

    _hideLoader(fn) {
      this._updateFunctionProperty(fn, 'loading', false);
    },

    _getDeviceFunctions: function () {
      return this._fetchDeviceFunctions().then(this._saveDeviceFunctions.bind(this));
    }.on('init'),
    actions: {
      getDeviceFunctions() {
        return this._getDeviceFunctions();
      },

      callFunction(name, argument) {
        this._updateFunctionProperty(name, 'result', null);

        this._showLoader(name);

        return this.get('apiAjax').postRequest({
          url: "".concat(this._constructUrl(), "/").concat(name),
          data: {
            arg: argument
          },
          timeout: 30000
        }).then(response => {
          const result = this._parseResponse(response, 'return_value');

          this._updateFunctionProperty(name, 'result', result.result);

          this._hideLoader(name);
        }, () => {
          this._error("Failed to call ".concat(name));

          this._hideLoader(name);
        });
      }

    }
  });

  _exports.default = _default;
});