define("pconsole/controllers/product/sims/import/scan", ["exports", "quagga", "fast-luhn", "ember-easy-form-extensions/mixins/controllers/form", "pconsole/mixins/sim/import", "ember-validations", "pconsole/mixins/browser-info"], function (_exports, _quagga, _fastLuhn, _form, _import, _emberValidations, _browserInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Twilio SIMs have an extra "F" printed on the end of their
  // barcode that's not actually a part of the ICCID
  const cleanICCID = iccid => {
    let clean;

    if (iccid && iccid.endsWith('F')) {
      clean = iccid.slice(0, -1);
    } else {
      clean = iccid;
    }

    return clean;
  };

  var _default = Ember.Controller.extend(_form.default, _browserInfo.default, _import.default, {
    notify: Ember.inject.service(),
    apiAjax: Ember.inject.service(),
    iccidList: [],
    selectedIccids: [],
    hasIccids: Ember.computed('selectedIccids.@each', function () {
      const iccids = this.get('selectedIccids');
      return iccids && iccids.length > 0;
    }),
    product: Ember.computed.alias('model'),

    /* FORM-BASED ENTRY */
    queryParams: ['type'],
    type: null,
    ICCID: null,
    validations: {
      'ICCID': {
        presence: true,
        length: [19, 20],
        inline: (0, _emberValidations.validator)(function () {
          const iccid = cleanICCID(this.model.get('ICCID'));

          if (iccid && !(0, _fastLuhn.default)(iccid)) {
            return 'is invalid';
          }
        })
      }
    },

    save() {
      Ember.$('.loading').show();
      const iccid = this.get('ICCID');
      return this.addIccidToList(iccid).then(() => {
        this.set('ICCID', null);
        this.set('errors', []);
      });
    },

    /* END FORM-BASED ENTRY */
    // These browser checks are only used for text-based
    // instructions for how to enable webcam access.
    // Right now, the getUserMedia API which this relies on
    // is only available in Chrome, FF, or Opera. See http://caniuse.com/#feat=stream
    // for details.
    displayInstructions: true,
    quaggaInitialized: false,

    createQuaggaResultCollector() {
      // A result collector helps you identify
      // false positive matches
      const resultCollector = _quagga.default.ResultCollector.create({
        capture: false,
        capacity: 100,
        filter: codeResult => {
          const iccid = cleanICCID(codeResult.code);
          const iccidList = this.get('iccidList');

          if (iccid.length < 19 || iccid.length > 20) {
            return false;
          }

          if (iccidList.contains(iccid)) {
            return false;
          }

          if (!(0, _fastLuhn.default)(iccid)) {
            return false;
          }

          if (iccid.charAt(0) !== '8') {
            return false;
          }

          return true;
        }
      });

      _quagga.default.registerResultCollector(resultCollector);

      this.set('resultCollector', resultCollector);
    },

    setIccidList(results) {
      const addingIccidToList = this.get('addingIccidToList');

      if (!addingIccidToList) {
        results.forEach(r => {
          const iccidList = this.get('iccidList');
          const iccid = cleanICCID(r.codeResult.code);

          if (!iccidList.contains(iccid)) {
            return this.addIccidToList(iccid, true);
          }
        });
      }
    },

    addIccidToList(iccid, scanning) {
      this.set('addingIccidToList', true);
      const iccidList = this.get('iccidList');
      const selectedIccids = this.get('selectedIccids');
      const cleanedICCID = cleanICCID(iccid); // Check the SIM card to see if it can be added to a product

      return this.get('apiAjax').request({
        url: '/v1/sims/' + cleanedICCID,
        type: 'HEAD'
      }).then(response => {
        this.set('addingIccidToList', false);
        Ember.$('.loading').hide();

        if (iccidList.contains(cleanedICCID)) {
          this.get('notify').info({
            html: '<span class="message">You have already queued this SIM for importing. Try a different SIM Card</span>'
          });

          if (scanning) {
            this.removeFromQuaggaResults(cleanedICCID);
          }
        } else {
          this.get('notify').success({
            html: '<span class="message">The ICCID of <strong>' + cleanedICCID + '</strong> has been queued for addition into your product successfuly!<br/><br/>You may continue to add more SIM Cards, or click the "Import" button to begin the import process.</span>'
          });
          iccidList.addObject(cleanedICCID);
          selectedIccids.addObject(cleanedICCID);
        }

        return response;
      }, err => {
        const status = err.status;
        Ember.$('.loading').hide();
        this.set('addingIccidToList', false);

        if (status === 403) {
          this.get('notify').error('This SIM card is already owned by someone else. Try a different SIM Card.');
        } else {
          this.get('notify').error('This is not a valid Particle SIM Card ICCID. Please double check the number and try again.');
        }

        if (scanning) {
          this.removeFromQuaggaResults(cleanedICCID);
        }

        return Ember.RSVP.reject(err);
      });
    },

    isImportingSimsOwnedByCurrentUser: Ember.computed.intersect('selectedIccids', 'userOwnedSims'),

    removeFromQuaggaResults(iccid) {
      const resultCollector = this.get('resultCollector');
      const results = resultCollector.getResults();
      const result = results.findBy('codeResult.code', iccid);
      results.removeObject(result);
    },

    initializeQuagga() {
      _quagga.default.init({
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: '#webcam-video-scanner',
          locate: true,
          constraints: {
            width: 600,
            height: 350
          }
        },
        locator: {
          patchSize: 'medium'
        },
        decoder: {
          readers: ['code_128_reader']
        }
      }, err => {
        if (err) {
          console.log(err);
          return;
        }

        this.createQuaggaResultCollector();

        _quagga.default.start();

        this.drawRectangle();
        this.set('quaggaInitialized', true);

        _quagga.default.onDetected(result => {
          this.barcodeDetected(result);
        });
      });
    },

    barcodeDetected() {
      const resultCollector = this.get('resultCollector');
      const results = resultCollector.getResults();
      this.setIccidList(results);
    },

    drawRectangle() {
      const ctx = _quagga.default.canvas.ctx.overlay;
      ctx.strokeStyle = '#00334f';
      ctx.rect(10, 135, 575, 100);
      ctx.stroke();
    },

    disableQuagga() {
      if (this.get('quaggaInitialized')) {
        _quagga.default.stop();
      }
    },

    actions: {
      switchToType() {
        if (this.get('quaggaInitialized')) {
          this.disableQuagga();
          this.set('quaggaInitialized', false);
        }

        this.set('displayInstructions', true);
        this.set('type', true);
      },

      switchToScan() {
        this.set('type', null);
      },

      initializeQuagga() {
        this.set('displayInstructions', false);
        this.initializeQuagga();
      },

      updateSelection(selectedIccids) {
        this.set('selectedIccids', selectedIccids);
      },

      importAndActivate() {
        return this.importAndActivate({
          country: 'US',
          simsToImport: this.get('selectedIccids'),
          product: this.get('routeData.product')
        });
      }

    }
  });

  _exports.default = _default;
});