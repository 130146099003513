define("pconsole/components/device-firmware", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['device-firmware'],
    notify: Ember.inject.service(),
    hovering: false,

    mouseEnter() {
      this.set('hovering', true);
    },

    mouseLeave() {
      this.set('hovering', false);
    },

    unmarkDevelopmentRoute: Ember.computed('deviceShow', function () {
      const deviceShow = this.get('deviceShow');
      return deviceShow ? 'product.device.unmarkDevelopment' : 'product.devices.unmarkDevelopment';
    }),
    actions: {
      unlock() {
        const device = this.get('device');
        device.set('desiredFirmwareVersion', null);
        device.save().then(d => {
          d.reload();
          this.get('notify').success('Desired firmware version unlocked');
        }, err => {
          this.get('notify').alert('Error unlocking device firmware version: ' + err);
        });
        return false;
      }

    }
  });

  _exports.default = _default;
});