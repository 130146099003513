define("pconsole/models/membership", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    scopes: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization', {
      async: true
    }),
    product: _emberData.default.belongsTo('product', {
      async: true
    }),
    role: _emberData.default.belongsTo('role', {
      async: true
    }),
    // New membership attrs included on GET /v1/user
    metadata: _emberData.default.attr(),
    invited: _emberData.default.attr('boolean'),
    invitedBy: Ember.computed('metadata', function () {
      const metadata = this.get('metadata');
      return metadata && metadata.invited_by;
    }),
    isOrg: Ember.computed('metadata', function () {
      return !!this.belongsTo('organization').id();
    }),
    teamIdentifier: Ember.computed('product', 'organization', function () {
      const product = this.belongsTo('product').id();
      const organization = this.get('organization.slug');
      return organization || product;
    }),
    teamName: _emberData.default.attr('string')
  });

  _exports.default = _default;
});