define("pconsole/adapters/product", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query, modelName) {
      const baseUrl = this.buildURL();
      const pathForType = this.pathForType(modelName);
      const {
        organization
      } = query;

      if (organization) {
        delete query.organization;
        return "".concat(baseUrl, "/orgs/").concat(organization, "/").concat(pathForType);
      }

      return "".concat(baseUrl, "/user/").concat(pathForType);
    },

    urlForQueryRecord(query, modelName) {
      const baseUrl = this.buildURL();
      const pathForType = this.pathForType(modelName);
      const {
        organization,
        slug
      } = query;
      delete query.slug;

      if (organization) {
        delete query.organization;
        return "".concat(baseUrl, "/orgs/").concat(organization, "/").concat(pathForType, "/").concat(encodeURIComponent(slug));
      }

      return "".concat(baseUrl, "/user/").concat(pathForType, "/").concat(encodeURIComponent(slug));
    },

    urlForFindAll(modelName) {
      const baseUrl = this.buildURL();
      const pathForType = this.pathForType(modelName);
      return "".concat(baseUrl, "/user/").concat(pathForType);
    },

    urlForFindRecord(id, modelName
    /*, snapshot*/
    ) {
      const baseUrl = this.buildURL();
      const pathForType = this.pathForType(modelName);
      return "".concat(baseUrl, "/user/").concat(pathForType, "/").concat(id);
    },

    urlForCreateRecord(modelName, snapshot) {
      const baseUrl = this.buildURL();
      const pathForType = this.pathForType(modelName);
      const organization = snapshot.belongsTo && snapshot.belongsTo('organization');

      if (organization) {
        return "".concat(baseUrl, "/orgs/").concat(organization.id, "/").concat(pathForType);
      }

      return "".concat(baseUrl, "/user/").concat(pathForType);
    }

  });

  _exports.default = _default;
});