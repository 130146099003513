define("pconsole/routes/user/team-invite", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return this.store.peekRecord('membership', params.membership_id);
    },

    afterModel(model) {
      // Required by modal-route mixin
      this._super(...arguments);

      if (!model) {
        this.transitionTo('user');
      }
    }

  });

  _exports.default = _default;
});