define("pconsole/helpers/boolean-to-human-speak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.booleanToHumanSpeak = booleanToHumanSpeak;
  _exports.default = void 0;

  function booleanToHumanSpeak([val]) {
    if (!!val === true) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  var _default = Ember.Helper.helper(booleanToHumanSpeak);

  _exports.default = _default;
});