define("pconsole/controllers/organization/ledger/show/delete-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ledgerInstance: Ember.computed.alias('model.ledgerInstance'),
    ledgerDefinition: Ember.computed.alias('ledgerInstance.definition'),

    close() {
      this.transitionToRoute(Ember.get(this, 'routeData.ledgerRoutes').show);
    },

    actions: {
      async deleteInstance() {
        try {
          await Ember.get(this, 'ledgerInstance').destroyRecord();
          Ember.get(this, 'notify').success('Ledger instance deleted successfully');
          this.close();
        } catch (_unused) {
          Ember.get(this, 'notify').error('Sorry, there was a problem deleting your ledger instance');
        }
      },

      closeModal() {
        this.close();
      }

    }
  });

  _exports.default = _default;
});