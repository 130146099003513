define("pconsole/helpers/generate-webhook-response-firmware-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.firmwareSubscribeCode = firmwareSubscribeCode;
  _exports.default = void 0;

  function firmwareSubscribeCode([responseTemplate, eventName]) {
    const cleansedResponseTemplate = responseTemplate.replace('{{PARTICLE_DEVICE_ID}}', 'System.deviceID() + "').replace('{{SPARK_CORE_ID}}', 'System.deviceID() + "').replace('{{PARTICLE_EVENT_NAME}}', eventName + '/"').replace('{{SPARK_EVENT_NAME}}', eventName + '/"');
    return cleansedResponseTemplate;
  }

  var _default = Ember.Helper.helper(firmwareSubscribeCode);

  _exports.default = _default;
});