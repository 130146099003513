define("pconsole/templates/components/self-service-device-threshold-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aW9LdCTV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  A new \"],[1,[20,[\"alert\",\"connectivityType\"]],false],[0,\" block will be added to your Growth plan\\n\"],[8],[0,\"\\n\\n\"],[6,\"p\"],[7],[0,\"\\n  You’ve exceeded your current plan’s \"],[1,[20,[\"alert\",\"connectivityType\"]],false],[0,\" device\\n  count\"],[4,\"if\",[[20,[\"organizationName\"]]],null,{\"statements\":[[0,\" for \"],[6,\"b\"],[7],[1,[18,\"organizationName\"],false],[8]],\"parameters\":[]},null],[0,\". This will not impact your service during this usage period.\\n\"],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"\\n  Your account will be charged for this new block at the start of the next\\n  usage period,\\n  \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"nextBillingPeriodStart\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"organization.billing\",[20,[\"organizationSlug\"]]],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/self-service-device-threshold-alert.hbs"
    }
  });

  _exports.default = _default;
});