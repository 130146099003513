define("pconsole/templates/user/team-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9v4fABle",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"backdrop\"],[\"static\"]],{\"statements\":[[0,\"    \"],[6,\"h3\"],[9,\"class\",\"title\"],[7],[0,\"Team Invitation\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"org-invite\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"You have been invited to join the \"],[6,\"strong\"],[7],[1,[20,[\"membership\",\"teamName\"]],false],[8],[0,\" \"],[4,\"if\",[[20,[\"membership\",\"isOrg\"]]],null,{\"statements\":[[0,\"organization\"]],\"parameters\":[]},{\"statements\":[[0,\"product\"]],\"parameters\":[]}],[0,\" team by \"],[6,\"strong\"],[7],[1,[20,[\"membership\",\"invitedBy\"]],false],[8],[0,\". What would you like to do?\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"invite-action-btns button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button filled primary success primary-btn\"],[10,\"disabled\",[18,\"isLoading\"],null],[3,\"action\",[[19,0,[]],\"respondToInvite\",[20,[\"membership\"]],true]],[7],[6,\"i\"],[9,\"class\",\"ion-checkmark\"],[7],[8],[0,\"Accept invitation\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button filled primary danger cancel-btn\"],[10,\"disabled\",[18,\"isLoading\"],null],[3,\"action\",[[19,0,[]],\"respondToInvite\",[20,[\"membership\"]],false]],[7],[6,\"i\"],[9,\"class\",\"ion-close\"],[7],[8],[0,\"Decline\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/user/team-invite.hbs"
    }
  });

  _exports.default = _default;
});