define("pconsole/components/eventlogs/event.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Event = ({
    event,
    style,
    onClick: _onClick,
    fields
  }) => _react.default.createElement('tr', {
    className: "_eventlogsnew__content__events__event ".concat(event.selected ? 'selected' : ''),
    onClick: () => _onClick(event),
    style: style
  }, fields.map((field, index) => _react.default.createElement('td', {
    key: "event-field-".concat(field.title, "-").concat(index)
  }, event[field.property] || event[field.secondProperty])));

  var _default = Event;
  _exports.default = _default;
});