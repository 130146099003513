define("pconsole/components/publish-event", ["exports", "pconsole/mixins/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, {
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    scope: 'events:send',
    loading: false,
    eventName: '',
    eventData: '',
    noEventName: Ember.computed('eventName', function () {
      return !this.get('eventName');
    }),
    isDisabled: Ember.computed('disabled', 'noEventName', function () {
      return this.get('disabled') || this.get('noEventName');
    }),
    hasWarning: Ember.computed('eventData', function () {
      const data = Ember.get(this, 'eventData');
      return data.length > 622;
    }),

    _constructUrl() {
      const product = this.get('routeData.product');
      let url = '/v1/';

      if (product) {
        url += "products/".concat(product, "/events");
      } else {
        url += "devices/events";
      }

      return url;
    },

    _showLoader() {
      this.set('loading', true);
    },

    _hideLoader() {
      this.set('loading', false);
    },

    actions: {
      publishEvent(name, data, _private) {
        this._showLoader();

        return this.get('apiAjax').postRequest({
          url: this._constructUrl(),
          data: {
            name: name,
            data: data,
            private: _private
          },
          timeout: 10000
        }).then(response => {
          if (response.ok && response.ok === true) {
            this.get('notify').info("Event published!");
          } else {
            this.get('notify').alert("Failed to publish the event");
          }
        }).catch(() => {
          this.get('notify').alert("Failed to publish the event");

          this._hideLoader();
        });
      }

    }
  });

  _exports.default = _default;
});