define("pconsole/services/session", ["exports", "ember-simple-auth/services/session", "ember-data"], function (_exports, _session, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    store: Ember.inject.service(),
    user: Ember.computed('data.authenticated.username', function () {
      const username = this.get('data.authenticated.username');

      if (!Ember.isEmpty(username)) {
        return _emberData.default.PromiseObject.create({
          promise: this.get('store').find('user', username)
        });
      }
    })
  });

  _exports.default = _default;
});