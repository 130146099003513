define("pconsole/components/device-firmware-updates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    isProduct: Ember.computed.alias('routeData.hasProduct'),
    supportsForceUpdate: Ember.computed('device.firmwareUpdatesForced', function () {
      const firmwareUpdatesForced = this.get('device.firmwareUpdatesForced');
      return typeof firmwareUpdatesForced === 'boolean';
    }),
    actions: {
      toggle() {
        const isFirmwareUpdatesForced = this.get('device.firmwareUpdatesForced');
        const isTargetedFirmwareImmediate = this.get('device.isTargetedFirmwareImmediate');

        if (isFirmwareUpdatesForced) {
          this.send('disableForceUpdate');
        } else {
          if (isTargetedFirmwareImmediate) {
            this.get('openForceUpdateModal')();
          } else {
            this.send('enableForceUpdate');
          }
        }
      },

      enableForceUpdate() {
        const device = this.get('device');
        device.set('firmwareUpdatesForced', true);
        device.save().then(() => {
          this.get('notify').success('Force enabled OTA updates for this device successfully.');
        }, response => {
          device.set('firmwareUpdatesForced', false);
          const error = response.errors[0];
          const errorMsg = error ? error.detail : 'Server error. Please contact our support team.';
          this.get('notify').alert(errorMsg);
        });
      },

      disableForceUpdate() {
        const device = this.get('device');
        device.set('firmwareUpdatesForced', false);
        device.save().then(() => {
          this.get('notify').success('Disabled forced OTA updates for this device successfully.');
        }, response => {
          device.set('firmwareUpdatesForced', true);
          const error = response.errors[0];
          const errorMsg = error ? error.detail : 'Server error. Please contact our support team.';
          this.get('notify').alert(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});