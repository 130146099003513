define("pconsole/models/client", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    secret: _emberData.default.attr('string'),
    scopes: _emberData.default.attr(),
    redirectURI: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization'),
    user: _emberData.default.belongsTo('user'),
    isWeb: Ember.computed('type', function () {
      const type = this.get('type');
      return type === 'web';
    }),
    hasFullPermissions: Ember.computed('scopes', function () {
      const scopes = this.get('scopes') || []; // FIXME: Remove the legacy scopes after migration

      return /\*:\*/.test(scopes[0]) || /none/.test(scopes[0]);
    }),
    scopeFriendlyName: Ember.computed('scopes', function () {
      let scopes = this.get('scopes') || []; // Only show the first scope

      scopes = scopes.slice(0, 1);
      const friendlyScopes = [];
      scopes.forEach(scope => {
        switch (true) {
          case /\*:\*$/.test(scope):
            friendlyScopes.push('full permissions');
            break;

          case /customers:create$/.test(scope):
            friendlyScopes.push('create customer');
            break;
          // FIXME: Remove the legacy scopes after migration

          case /^none$/.test(scope):
            friendlyScopes.push('full permissions');
            break;

          case /^create_customer$/.test(scope):
            friendlyScopes.push('create customer');
            break;
        }
      });
      return friendlyScopes;
    }),
    selectedScopes: Ember.computed('scopes', function () {
      const scopes = this.get('scopes') || [];
      const scope = scopes[0]; // FIXME: Remove the legacy scopes after migration

      if (/\*:\*/.test(scope) || /^none$/.test(scope)) {
        return ['*:*'];
      }

      if (/customers:create$/.test(scope) || /^create_customer$/.test(scope)) {
        return ['customers:create'];
      }

      return [];
    })
  });

  _exports.default = _default;
});