define("pconsole/components/webhook-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['logVisible'],
    classNames: ['webhook-log'],
    logVisible: false,
    actions: {
      toggleVisible() {
        this.toggleProperty('logVisible');
      }

    }
  });

  _exports.default = _default;
});