define("pconsole/routes/product/firmware/release", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return Ember.RSVP.hash({
        firmware: this.store.find('product-firmware', params.firmware_id),
        groups: this.store.findAll('group'),
        product: this.modelFor('product').product
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'immediateUpdateConfirmed', false);
      }
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.reset();

      if (!model.product.get('groupsEnabled')) {
        controller.set('confirmRelease', true);
        controller.set('selectedGroups', [controller.get('productDefaultGroup')]);
        controller.proceedToRelease();
      }
    }

  });

  _exports.default = _default;
});