define("pconsole/controllers/product/location/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    area: Ember.computed.readOnly('mapController.area'),
    devices: Ember.computed.readOnly('mapController.devices'),
    count: Ember.computed.readOnly('mapController.count'),
    visibleDevices: Ember.computed.readOnly('mapController.visibleDevices'),
    visibleDevicesInCluster: Ember.computed.readOnly('mapController.visibleDevicesInCluster'),
    groups: Ember.computed.readOnly('mapController.groups'),
    groupOptions: Ember.computed.readOnly('mapController.groupOptions'),
    hasDevices: Ember.computed.readOnly('mapController.hasDevices'),
    highlightedDeviceItem: Ember.computed.readOnly('mapController.highlightedDeviceItem'),
    id: Ember.computed.readOnly('mapController.id'),
    isLoading: Ember.computed.readOnly('mapController.isLoading'),
    location: Ember.computed.readOnly('mapController.location'),
    name: Ember.computed.readOnly('mapController.name'),
    product: Ember.computed.readOnly('mapController.product'),
    segment: Ember.inject.service(),
    mapController: Ember.inject.controller('product.location')
  });

  _exports.default = _default;
});