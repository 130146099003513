define("pconsole/routes/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductRoute = Ember.Route.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    beforeModel(transition) {
      // ember-simple-auth
      this._super(transition); // ember-simple-auth validate auth or redirect to login


      this.get('session').requireAuthentication(transition, 'login');
      const routeData = this.get('routeData');
      routeData.assignRouteData(transition);
    },

    model(params) {
      const product_slug = params.product_slug;
      return Ember.RSVP.hash({
        product: this.store.queryRecord('product', {
          slug: product_slug
        }),
        user: this.store.findRecord('user', this.get('session.data.authenticated.username')),
        organizations: this.store.findAll('organization')
      });
    },

    afterModel(model, transition) {
      const {
        product
      } = model;
      const subscriptionId = product.belongsTo('subscription').id();

      if (!subscriptionId) {
        return;
      } // Legacy billing with per-product subscription
      // There are multiple cases in which access to the product's plan and subscription are desired
      // within the product scope. This logic ensures that it is loaded once, as soon as you enter
      // into the product route. These objects will be available to any child route of the product


      const subscription = this.store.findRecord('subscription', subscriptionId); // We return promises here to ensure the transition pauses and waits for the calls to the API
      // to resolve. See https://www.emberjs.com/api/ember/2.14/classes/Ember.Route/methods/afterModel?anchor=afterModel
      // for details.

      return subscription.then(s => {
        product.set('subscription', s);
        const plan = this.store.find('plan', s.belongsTo('plan').id());
        return plan.then(p => {
          product.set('plan', p);

          this._super(model, transition);
        });
      });
    },

    serialize({
      product
    }) {
      return {
        product_slug: product.get('slug')
      };
    },

    deactivate() {
      const devices = this.store.peekAll('device');
      const productFirmwares = this.store.peekAll('product-firmware');
      devices.forEach(d => {
        d.unloadRecord();
      });
      productFirmwares.forEach(f => {
        f.unloadRecord();
      }); // Reset product configuration

      this.store.unloadAll('productConfiguration');
      const questions = this.store.peekAll('question');
      const sections = this.store.peekAll('section');
      questions.forEach(q => {
        q.set('answer', q.get('defaultChoice'));
        q.set('isDone', false);
        q.save();
      });
      sections.forEach(s => {
        s.set('isExpanded', null);
        s.set('isViewMode', null);
        s.save();
      }); // Reset groups

      const groups = this.store.peekAll('group');
      groups.forEach(g => {
        g.unloadRecord();
      }); // This is needed to correctly reset the device object filter when switching
      // from one product to another. The UI will default back to "Device ID" as the
      // auto-selected attribute to search by

      const productDevicesController = this.controllerFor('product.devices');
      productDevicesController.set('resetQueryParam', undefined);
    },

    actions: {
      error(error, transition) {
        if (transition) {
          transition.abort();
        }

        console.error(error);
        this.transitionTo('user.products').then(() => {
          this.get('notify').warning('There was a problem accessing your product. Please try again later.');
        });
        return true;
      }

    }
  });
  var _default = ProductRoute;
  _exports.default = _default;
});