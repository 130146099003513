define("pconsole/controllers/product/devices/approve-quarantined-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    importDevicesResponse: Ember.inject.service(),
    productDevicesController: Ember.inject.controller('product/devices'),
    actions: {
      closeModal() {
        Ember.run.later(this, () => {
          if (this.get('productDevicesController.deviceRouteActive')) {
            this.transitionToRoute('product.devices');
          }
        }, 50);
      },

      approveDevice() {
        const quarantinedDevice = this.get('model');
        const store = this.get('store');
        const approvedDevice = store.createRecord('new-device', {
          identifier: quarantinedDevice.get('id')
        });
        Ember.$('.loading').show();
        approvedDevice.save().then(importedDevicesResult => {
          this.get('importDevicesResponse').handleResponse(this, importedDevicesResult);
        }, response => {
          Ember.$('.loading').hide();
          approvedDevice.rollbackAttributes();
          const error = response.errors[0];
          const errorMsg = error ? error.detail : 'Please try again later.';
          this.get('notify').alert('Your device could not be approved. ' + errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});