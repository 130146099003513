define("pconsole/routes/organization/products/new", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model() {
      return Ember.RSVP.hash({
        product: this.store.createRecord('product', {
          organization: this.modelFor('organization').organization,
          settings: {
            location: {}
          }
        }),
        platforms: this.store.findAll('platform')
      });
    },

    actions: {
      willTransition: function willTransition() {
        const controller = this.get('controller');
        const newProduct = controller.get('product');

        if (newProduct && newProduct.get('hasDirtyAttributes')) {
          if (newProduct.get('id')) {
            newProduct.rollbackAttributes();
          } else {
            newProduct.deleteRecord();
          }
        }
      }
    }
  });

  _exports.default = _default;
});