define("pconsole/routes/product/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        devices: this.store.query('device', {}),
        integrations: this.store.query('integration', {}),
        product: this.modelFor('product').product,
        sims: this.store.query('sim', {})
      });
    },

    async afterModel(model) {
      const {
        product
      } = model;
      const orgId = product.belongsTo('organization').id();
      model.team = await this.store.query('team', {
        orgId: orgId
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      const {
        devices,
        integrations,
        sims,
        team
      } = model;
      const username = Ember.get(this, 'session.data.authenticated.username');
      const teamUser = team.findBy('username', username);
      const isOwner = teamUser && Ember.get(teamUser, 'isOwner');
      const approvedDevices = devices.filter(device => !device.get('quarantined'));
      const isDeleteEnabled = isOwner && Ember.isEmpty(approvedDevices) && Ember.isEmpty(integrations) && Ember.isEmpty(sims);
      Ember.set(controller, 'isDeleteEnabled', isDeleteEnabled);
      Ember.set(controller, 'isOwner', isOwner);
    }

  });

  _exports.default = _default;
});