define("pconsole/helpers/stringify-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringifyJson = stringifyJson;
  _exports.default = void 0;

  function stringifyJson([value]) {
    if (typeof value === 'string') {
      return value;
    }

    return JSON.stringify(value);
  }

  var _default = Ember.Helper.helper(stringifyJson);

  _exports.default = _default;
});