define("pconsole/templates/form-inputs/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mZUb84VK",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"textarea\",null,[[\"id\",\"invalid\",\"placeholder\",\"name\",\"type\",\"value\",\"rows\",\"cols\",\"disabled\",\"maxlength\",\"tabindex\",\"selectionEnd\",\"selectionStart\",\"selectionDirection\",\"wrap\",\"readonly\",\"autofocus\",\"form\",\"spellcheck\",\"required\"],[[20,[\"inputId\"]],[20,[\"isInvalid\"]],[20,[\"placeholder\"]],[20,[\"name\"]],[20,[\"type\"]],[20,[\"value\"]],[20,[\"rows\"]],[20,[\"cols\"]],[20,[\"disabled\"]],[20,[\"maxLength\"]],[20,[\"tabIndex\"]],[20,[\"selectionEnd\"]],[20,[\"selectionStart\"]],[20,[\"selectionDirection\"]],[20,[\"wrap\"]],[20,[\"readonly\"]],[20,[\"autofocus\"]],[20,[\"form\"]],[20,[\"spellcheck\"]],[20,[\"required\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/form-inputs/textarea.hbs"
    }
  });

  _exports.default = _default;
});