define("pconsole/router", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('user', {
      path: '/',
      resetNamespace: true
    }, function () {
      this.route('home'), this.route('networks', function () {
        this.route('network', {
          path: ':network_name'
        });
      });
      this.route('clients', {
        path: 'authentication'
      }, function () {
        this.route('create', {
          path: 'create-client'
        });
        this.route('edit', {
          path: '/edit-client/:client_id'
        });
        this.route('delete', {
          path: '/delete-client/:client_id'
        });
      });
      this.route('logs');
      this.route('events');
      this.route('devices', function () {
        this.route('unclaimDevice', {
          path: 'unclaim-device/:device_id'
        });
      });
      this.route('device', {
        path: 'devices/:device_id'
      }, function () {
        this.route('unclaimDevice', {
          path: 'unclaim-device'
        });
        this.route('enableForceUpdate', {
          path: 'enable-force-update'
        });
      });
      this.route('billing');
      this.route('teamInvite', {
        path: '/invite/:membership_id'
      });
      this.route('integrations', function () {
        this.route('gallery');
        this.route('create');
        this.route('edit', {
          path: 'edit/:integration_id'
        });
        this.route('show', {
          path: '/:integration_id'
        }, function () {
          this.route('delete');
        });
      });
      this.route('products', function () {
        this.route('new');
      });
      this.route('sims', function () {
        this.route('import');
        this.route('deactivate', {
          path: '/deactivate/:iccid'
        });
        this.route('reactivate', {
          path: '/reactivate/:iccid'
        });
        this.route('release', {
          path: '/release/:iccid'
        });
      });
      this.route('logic', function () {
        this.route('templates', function () {
          this.route('show', {
            path: '/:template_slug'
          });
        });
        this.route('index', {
          path: '/'
        });
      }); // TODO (carlos h): Consolidate under the logic route

      this.route('logic-functions', {
        path: 'logic/functions'
      }, function () {
        this.route('create');
        this.route('edit', {
          path: '/:logic_function_id'
        });
        this.route('details', {
          path: '/:logic_function_id/details'
        }, function () {
          this.route('delete');
        });
      });
      this.route('ledger', function () {
        this.route('create');
        this.route('categories');
        this.route('show', {
          path: '/:ledger_name'
        }, function () {
          this.route('archive', {
            path: 'archive'
          });
          this.route('new-instance', {
            path: 'instance/new'
          });
          this.route('delete-instance', {
            path: 'delete/:scope_value'
          });
          this.route('instance', {
            path: '/:scope_value'
          });
        });
        this.route('index', {
          path: '/'
        }, function () {
          this.route('tos', {
            path: 'terms'
          });
          this.route('archive', {
            path: 'archive/:ledger_name'
          });
        });
      });
    });
    this.route('organization', {
      path: 'orgs/:org_id',
      resetNamespace: true
    }, function () {
      this.route('products', function () {
        this.route('new');
      });
      this.route('enterprise-resources');
      this.route('team', function () {
        this.route('inviteTeamMember', {
          path: 'invite-team-member'
        });
        this.route('removeTeamMember', {
          path: 'remove-team-member/:username'
        });
      });
      this.route('billing');
      this.route('logic', function () {
        this.route('templates', function () {
          this.route('show', {
            path: '/:template_slug'
          });
        });
        this.route('index', {
          path: '/'
        });
      }); // TODO (carlos h): Consolidate under the logic route

      this.route('logic-functions', {
        path: 'logic/functions'
      }, function () {
        this.route('create');
        this.route('edit', {
          path: '/:logic_function_id'
        });
        this.route('details', {
          path: '/:logic_function_id/details'
        }, function () {
          this.route('delete');
        });
      });
      this.route('ledger', function () {
        this.route('create');
        this.route('categories');
        this.route('show', {
          path: '/:ledger_name'
        }, function () {
          this.route('archive', {
            path: 'archive'
          });
          this.route('new-instance', {
            path: 'instance/new'
          });
          this.route('delete-instance', {
            path: 'delete/:scope_value'
          });
          this.route('instance', {
            path: '/:scope_value'
          });
        });
        this.route('index', {
          path: '/'
        }, function () {
          this.route('archive', {
            path: 'archive/:ledger_name'
          });
        });
      });
      this.route('insights', function () {
        this.route('report', {
          path: '/:dashboard_id'
        });
        this.route('index', {
          path: '/'
        });
      });
      this.route('integrations', function () {
        this.route('gallery');
        this.route('create');
        this.route('edit', {
          path: 'edit/:integration_id'
        });
        this.route('show', {
          path: '/:integration_id'
        }, function () {
          this.route('delete');
        });
        this.route('index', {
          path: '/'
        });
      });
      this.route('lake');
    });
    this.route('product', {
      path: ':product_slug',
      resetNamespace: true
    }, function () {
      this.route('team', function () {
        this.route('inviteTeamMember', {
          path: 'invite-team-member'
        });
        this.route('removeTeamMember', {
          path: 'remove-team-member/:username'
        });
      });
      this.route('clients', {
        path: 'authentication'
      }, function () {
        this.route('create', {
          path: 'create-client'
        });
        this.route('edit', {
          path: '/edit-client/:client_id'
        });
        this.route('delete', {
          path: '/delete-client/:client_id'
        });
      });
      this.route('fleet-health');
      this.route('devices', function () {
        this.route('importDevices', {
          path: 'import'
        });
        this.route('removeProductDevice', {
          path: 'remove-device/:device_id'
        });
        this.route('unclaimDevice', {
          path: 'unclaim-device/:device_id'
        });
        this.route('approveQuarantinedDevice', {
          path: 'approve/:device_id'
        });
        this.route('denyQuarantinedDevice', {
          path: 'deny/:device_id'
        });
        this.route('welcome');
        this.route('markDevelopment', {
          path: 'mark-development/:device_id'
        });
        this.route('unmarkDevelopment', {
          path: 'unmark-development/:device_id'
        });
        this.route('createGroup', {
          path: 'create-group'
        });
        this.route('editGroup', {
          path: 'edit-group'
        });
        this.route('deleteGroup', {
          path: 'delete-group'
        });
        this.route('batch', {
          path: 'b'
        }, function () {
          this.route('editGroups', {
            path: 'edit-groups'
          });
        });
      });
      this.route('device', {
        path: 'devices/:device_id'
      }, function () {
        this.route('markDevelopment', {
          path: 'mark-development'
        });
        this.route('unmarkDevelopment', {
          path: 'unmark-development'
        });
        this.route('unclaimDevice', {
          path: 'unclaim-device'
        });
        this.route('removeProductDevice', {
          path: 'remove-device'
        });
        this.route('enableForceUpdate', {
          path: 'enable-force-update'
        });
      });
      this.route('customers', function () {
        this.route('import');
      });
      this.route('firmware', function () {
        this.route('upload');
        this.route('edit', {
          path: '/edit/:firmware_id'
        });
        this.route('release', {
          path: '/release/:firmware_id'
        });
      });
      this.route('logs');
      this.route('events');
      this.route('settings', function () {
        this.route('delete');
      });
      this.route('integrations', function () {
        this.route('gallery');
        this.route('create');
        this.route('edit', {
          path: 'edit/:integration_id'
        });
        this.route('show', {
          path: '/:integration_id'
        }, function () {
          this.route('delete');
        });
      });
      this.route('logic', function () {
        this.route('templates', function () {
          this.route('show', {
            path: '/:template_slug'
          });
        });
        this.route('index', {
          path: '/'
        });
      });
      this.route('logic-functions', {
        path: 'logic/functions'
      }, function () {
        this.route('create');
        this.route('edit', {
          path: '/:logic_function_id'
        });
        this.route('details', {
          path: '/:logic_function_id/details'
        }, function () {
          this.route('delete');
        });
      });
      this.route('sims', function () {
        this.route('import', function () {
          this.route('scan', {
            path: 'importer'
          });
          this.route('upload');
        });
        this.route('deactivate', {
          path: 'deactivate/:iccid'
        });
        this.route('reactivate', {
          path: 'reactivate/:iccid'
        });
        this.route('release', {
          path: 'release/:iccid'
        });
      });
      this.route('map', function () {
        this.route('device', {
          path: ':device_location_id'
        });
        this.route('index', {
          path: '/'
        }, function () {
          this.route('config');
        });
      });
      this.route('location', function () {
        this.route('device', {
          path: ':device_location_id'
        });
        this.route('index', {
          path: '/'
        }, function () {
          this.route('config');
        });
      });
    });
    this.route('not-found', {
      path: '*'
    });
  });
  var _default = Router;
  _exports.default = _default;
});