define("pconsole/components/global-bar", ["exports", "react", "@particle/react-particle-global-bar", "styled-components", "pconsole/components/console-theme"], function (_exports, _react, _reactParticleGlobalBar, _styledComponents, _consoleTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = GlobalBar;
  const StyledGlobalBar = (0, _styledComponents.default)(_reactParticleGlobalBar.default).withConfig({
    displayName: "global-bar__StyledGlobalBar",
    componentId: "sc-1y6j65u-0"
  })(["background-color:var(--danger-color-light);border-top-color:var(--danger-color);color:var(--dark-text-color);> svg path{fill:var(--danger-color);> div:first-child{color:var(--danger-color);}> a{color:var(--secondary-text-color);}"]);

  function GlobalBar(props) {
    return _react.default.createElement(_styledComponents.ThemeProvider, {
      theme: _consoleTheme.default
    }, _react.default.createElement(StyledGlobalBar, props));
  }
});