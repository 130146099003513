define("pconsole/routes/product/device/remove-product-device", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductRemoveDeviceRoute = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    breadCrumb: null,
    templateName: 'product/devices/remove-product-device',
    controllerName: 'product/devices/remove-product-device',

    model() {
      return this.modelFor('product.device').device;
    }

  });
  var _default = ProductRemoveDeviceRoute;
  _exports.default = _default;
});