define("pconsole/components/device-online-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['title'],
    classNameBindings: ['icon', 'device.online:online:offline', 'device.inSafeMode:safe-mode'],
    classNames: ['device-online-indicator', 'icon'],
    device: null,
    tagName: 'i',
    icon: Ember.computed('device.{isPaused,online}', function () {
      const device = Ember.get(this, 'device');
      let icon = 'ion-record';

      if (device && Ember.get(device, 'isPaused')) {
        icon = 'ion-pause';
      }

      return icon;
    }),
    title: Ember.computed('device.isPaused', function () {
      const device = Ember.get(this, 'device');
      let title = '';

      if (device && Ember.get(device, 'isPaused')) {
        title = 'Service paused';
      }

      return title;
    })
  });

  _exports.default = _default;
});