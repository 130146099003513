define("pconsole/controllers/organization/logic-functions/create", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_form.default, {
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    apiAjax: Ember.inject.service(),
    logicFunction: Ember.computed.readOnly('model.logicFunction'),
    products: Ember.computed.readOnly('model.products'),
    product: Ember.computed.alias('model.product'),
    isEditing: false,
    // Set by the route
    queryParams: ['template'],
    template: 'event-function',
    steps: [{
      id: 'define',
      title: 'Define'
    }, {
      id: 'execute',
      title: 'Execute'
    }, {
      id: 'deploy',
      title: 'Deploy'
    }],
    changeTypeOptions: ['set', 'delete'],
    isSharedLogicFunction: Ember.computed('logicFunction.affectedProducts', function () {
      const affectedProducts = this.affectedProducts();
      return affectedProducts.length > 1;
    }),
    activeTab: null,
    disabledSteps: Ember.computed('activeTab', 'isEditing', 'successExecute', 'logicFunction.{code,hasDirtyAttributes}', function () {
      const isEditing = Ember.get(this, 'isEditing');
      const logicFunction = Ember.get(this, 'logicFunction');

      if (isEditing) {
        const changedKeys = Object.keys(logicFunction.changedAttributes());
        const successExecute = Ember.get(this, 'successExecute');

        if (changedKeys.includes('code') && !successExecute) {
          return ['deploy'];
        }

        return [];
      } // Creating


      const activeTab = Ember.get(this, 'activeTab');

      if (!activeTab || activeTab.id === 'define') {
        return ['execute', 'deploy'];
      }

      if (activeTab.id === 'execute') {
        return ['deploy'];
      }

      return [];
    }),
    canContinue: Ember.computed('activeTab', 'isEditing', 'logicFunction.{name,code,hasDirtyAttributes,validations.isValid}', 'successExecute', 'validTriggers', function () {
      const activeTab = Ember.get(this, 'activeTab');
      const logicFunction = Ember.get(this, 'logicFunction');
      const validations = Ember.get(logicFunction, 'validations');

      if (!activeTab || activeTab.id === 'define') {
        return Ember.get(validations, 'attrs.name.isValid');
      }

      if (activeTab.id === 'execute') {
        const isEditing = Ember.get(this, 'isEditing');
        const changedKeys = Object.keys(logicFunction.changedAttributes());
        const codeChanged = changedKeys.includes('code');
        const testedCode = isEditing && !codeChanged || Ember.get(this, 'successExecute');
        return Ember.get(validations, 'attrs.code.isValid') && testedCode;
      }

      if (activeTab.id === 'deploy') {
        return Ember.get(this, 'validTriggers') && Ember.get(logicFunction, 'validations.isValid');
      }
    }),

    /* ----- Execute step ----- */
    eventTestData: null,
    testProductId: null,
    testDeviceId: '',
    executeRun: null,
    successExecute: null,
    // null = never run
    isExecuting: false,
    showToast: Ember.computed('isEditing', 'logicFunction.{code,hasDirtyAttributes}', function () {
      const isEditing = Ember.get(this, 'isEditing');

      if (!isEditing) {
        return true;
      }

      const logicFunction = Ember.get(this, 'logicFunction');
      const changedKeys = Object.keys(logicFunction.changedAttributes());
      return changedKeys.includes('code');
    }),
    disabledExecute: Ember.computed('isExecuting', function () {
      // add additional execute pre-checks here
      return Ember.get(this, 'isExecuting');
    }),
    toastType: Ember.computed('successExecute', function () {
      const success = Ember.get(this, 'successExecute');

      if (success === null) {
        return 'info';
      }

      if (success) {
        return 'success';
      }

      return 'error';
    }),

    executeData() {
      const logicFunction = Ember.get(this, 'logicFunction');
      const type = logicFunction.get('triggerType');
      const data = {
        source: {
          type: 'JavaScript',
          code: logicFunction.get('code') || ''
        },
        api_username: logicFunction.get('apiUsername')
      };

      if (type === 'Event') {
        data.event = {
          event_data: Ember.get(this, 'eventTestData'),
          event_name: 'test_event',
          device_id: Ember.get(this, 'testDeviceId') || 'test-device-id',
          product_id: Number(Ember.get(this, 'testProductId')) || 0
        };
      } else if (type === 'Scheduled') {
        data.scheduled = {};
      } else if (type === 'LedgerChange') {
        data.ledger_change = {
          change_type: 'set',
          name: 'test_ledger',
          scope: {
            type: 'Owner'
          }
        };
      }

      return data;
    },

    /* ----------------------------- */
    productNames: Ember.computed.sort('products', function (a, b) {
      const aName = Ember.get(a, 'name');
      const bName = Ember.get(b, 'name');

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      }

      return 0;
    }),
    validTriggers: Ember.computed('logicFunction.logicTriggers.@each.validModel', function () {
      return Ember.get(this, 'logicFunction.logicTriggers').every(trigger => trigger.get('validModel'));
    }),

    affectedProducts() {
      if (Ember.get(this, 'logicFunction.triggerType') === 'Event') {
        const logicTriggers = Ember.get(this, 'logicFunction.logicTriggers');
        const triggerProductsSet = new Set(logicTriggers === null || logicTriggers === void 0 ? void 0 : logicTriggers.map(trigger => {
          var _trigger$get;

          return (_trigger$get = trigger.get('productId')) === null || _trigger$get === void 0 ? void 0 : _trigger$get.toString();
        }));
        return Array.from(triggerProductsSet);
      }

      return [];
    },

    async save() {
      const logicFunction = Ember.get(this, 'logicFunction');
      Ember.set(logicFunction, 'enabled', true);
      Ember.$('.loading').show();

      try {
        Ember.set(logicFunction, 'name', Ember.get(logicFunction, 'name').trim());
        await logicFunction.save(); // clean up triggers with null ids since the API returned a copy of the trigger with an id

        Ember.get(logicFunction, 'logicTriggers').filter(trigger => !Ember.get(trigger, 'id')).map(trigger => {
          trigger.deleteRecord();
        });
        Ember.$('.loading').hide();

        if (Ember.get(this, 'isEditing')) {
          Ember.get(this, 'notify').success('Your Logic Function has been saved');
        } else {
          Ember.get(this, 'notify').success('Your Logic Function has been created');
        }

        const product = Ember.get(this, 'product');
        const triggerType = Ember.get(logicFunction, 'triggerType');

        if (triggerType === 'Event' && (product === null || product === void 0 ? void 0 : product.id)) {
          const affectedProducts = this.affectedProducts();

          if (affectedProducts.includes(product.id)) {
            this.transitionToRoute(Ember.get(this, 'routeData.logicRoutes').details, Ember.get(logicFunction, 'id'));
          } else {
            this.transitionToRoute('product.logic');
          }
        } else {
          this.transitionToRoute(Ember.get(this, 'routeData.logicRoutes').details, Ember.get(logicFunction, 'id'));
        }
      } catch (error) {
        Ember.$('.loading').hide();
        const statusCode = Ember.get(error, 'errors.0.status');
        const errorDetail = Ember.get(error, 'errors.0.detail');
        let message = 'Your Logic Function is not valid. ' + (errorDetail || 'Check the configuration and try again later.');

        if (statusCode >= 500) {
          message = 'There was a problem creating your Logic Function. Please try again later';
        }

        Ember.get(this, 'notify').alert(message);
      }
    },

    actions: {
      blockSubmit(ev) {
        ev.preventDefault();
      },

      changeActiveTab(tab) {
        Ember.set(this, 'activeTab', tab);
      },

      saveAction() {
        this.save();
      },

      addTrigger() {
        const logicFunction = Ember.get(this, 'logicFunction');
        Ember.get(this, 'store').createRecord('logic-trigger', {
          logicFunction,
          productId: Ember.get(this, 'product.id'),
          type: Ember.get(logicFunction, 'triggerType')
        });
      },

      async execute() {
        const api = Ember.get(this, 'apiAjax');
        const org = Ember.get(this, 'routeData.org');
        let baseUrl = '/v1';

        if (org) {
          baseUrl = "/v1/orgs/".concat(org);
        }

        const data = this.executeData();

        try {
          this.toggleProperty('isExecuting');
          Ember.set(this, 'executeRun', null);
          const response = await api.postRequest({
            url: "".concat(baseUrl, "/logic/execute"),
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(data)
          });
          Ember.set(this, 'executeRun', response.result);
          Ember.set(this, 'successExecute', response.result.status === 'Success');
        } catch (e) {
          Ember.set(this, 'successExecute', false); // TODO (carlos h): Get feedback from error

          Ember.set(this, 'executeRun', {
            err: 'Unexpected error running your code, please try again'
          });
        } finally {
          this.toggleProperty('isExecuting');
        }
      }

    }
  });

  _exports.default = _default;
});