define("pconsole/mixins/routes/logic-functions/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LogicFunctionsCreateRoute = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  const LogicFunctionsCreateRoute = {
    templateName: 'organization.logic-functions.create',
    controllerName: 'organization.logic-functions.create',
    logicTemplate: Ember.inject.service(),
    session: Ember.inject.service(),
    breadCrumb: {
      title: 'Create Logic Function',
      linkable: false
    },

    setupController(controller, model) {
      Ember.set(controller, 'eventTestData', model.template.get('eventTestData'));

      this._super(...arguments);
    },

    async model(params) {
      const owner = this.get('owner');
      const logicFunctions = await this.store.findAll('logic-function');
      const templates = await this.store.findAll('logic-template', {
        reload: true
      }); // TODO: add a Ledger change template, check the ledger LaunchDarkly flag and filter templates to only show the Ledger change to users with the LD flag

      const template = Ember.get(this, 'logicTemplate').getTemplate() || templates.filterBy('slug', params.template)[0];
      let products = [];
      let organization;
      let product;

      if (owner === 'organization') {
        organization = this.modelFor('organization').organization;
        products = this.modelFor('organization').products;
      } else if (owner === 'product') {
        product = this.modelFor('product').product;
        const organization = product.get('organization');
        products = organization ? await this.store.query('product', {
          organization: organization.get('id')
        }) : products = await this.store.findAll('product');
      } else {
        const username = Ember.get(this, 'session.data.authenticated.username');
        products = await this.store.findAll('product');
        products = products.filterBy('user', username);
      }

      return {
        logicFunctions,
        logicFunction: this.store.createRecord('logic-function'),
        templates,
        template,
        products,
        organization,
        product
      };
    },

    afterModel(model) {
      const functions = model.logicFunctions.toArray();
      const product = model.product;

      const _model$template$toJSO = model.template.toJSON().logicFunction,
            {
        logicTriggers = []
      } = _model$template$toJSO,
            logicFunction = _objectWithoutProperties(_model$template$toJSO, ["logicTriggers"]);

      logicTriggers.map(logicTrigger => {
        this.store.createRecord('logic-trigger', _objectSpread({}, logicTrigger, {
          productId: product === null || product === void 0 ? void 0 : product.id,
          logicFunction: model.logicFunction
        }));
      });
      let n = 1;
      let {
        name
      } = logicFunction;

      while (functions.find(lf => lf.get('name') === name)) {
        n++;
        name = "".concat(logicFunction.name, " - ").concat(n);
      }

      logicFunction.name = name;
      model.logicFunction.setProperties(logicFunction);
    },

    resetController(controller) {
      Ember.get(this, 'logicTemplate').setTemplate(null);
      Ember.get(controller, 'logicFunction').rollbackAttributes();
      controller.setProperties({
        activeTab: null,
        executeRun: null,
        eventTestData: null,
        testProductId: null,
        testDeviceId: null,
        successExecute: null,
        isExecuting: false
      });
    }

  };
  _exports.LogicFunctionsCreateRoute = LogicFunctionsCreateRoute;
});