define("pconsole/routes/organization/integrations/edit", ["exports", "pconsole/mixins/edit-integration-route"], function (_exports, _editIntegrationRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_editIntegrationRoute.editIntegrationRoute, {
    parentEntity: 'organization',

    setupController(controller) {
      this._super(...arguments);

      const products = this.modelFor('organization').products;
      controller.set('model.products', products);
    }

  });

  _exports.default = _default;
});