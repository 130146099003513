define("pconsole/helpers/cents-to-dollars-decimals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.centsToDollarsDecimals = centsToDollarsDecimals;
  _exports.default = void 0;

  function centsToDollarsDecimals([value]) {
    const dollars = value ? (value / 100).toFixed(2) : '0.00';
    return '$' + dollars;
  }

  var _default = Ember.Helper.helper(centsToDollarsDecimals);

  _exports.default = _default;
});