define("pconsole/controllers/product/devices/import-devices", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _form.default, {
    importManyDevices: false,
    importMethodChosen: false,
    importSingleDevice: false,
    isCellular: false,
    needsToUpgradeProductPlan: false,
    product: null,
    shouldShowBulkDeviceLimitModal: false,
    showGrowthPlanSingleDeviceConfirmation: false,
    showGrowthPlanBulkDeviceConfirmation: false,
    validations: {
      'model.file': {
        presence: {
          'if': function _if(object) {
            return object.get('model.importMethod') === 'many';
          }
        }
      },
      'model.identifier': {
        presence: {
          'if': function _if(object) {
            return object.get('model.importMethod') === 'one';
          }
        }
      }
    },
    application: Ember.inject.controller(),
    importDevicesResponse: Ember.inject.service(),
    notify: Ember.inject.service(),
    productDevicesController: Ember.inject.controller('product/devices'),
    segment: Ember.inject.service(),
    shouldShowSingleDeviceLimitModal: Ember.computed.and('application.currentServiceAgreement.hasReachedDeviceLimit', 'application.currentServiceAgreement.isDeveloper'),
    isFormInvalid: Ember.computed('isInvalid', 'model.accepted', function () {
      return Ember.get(this, 'isInvalid') || !this.get('model.accepted');
    }),
    rerunValidations: Ember.observer('model.importMethod', function () {
      //The only way I can find to re-run conditional validations
      //See https://github.com/DockYard/ember-validations/issues/138
      this.validate().catch(() => {});
    }),
    hasReachedGrowthPlanDeviceLimit: Ember.computed('application.currentServiceAgreement.{isGrowth,currentUsageSummary,pricingTerms}', 'product', function () {
      const product = Ember.get(this, 'product');
      const serviceAgreement = Ember.get(this, 'application.currentServiceAgreement');

      if (!serviceAgreement) {
        return false;
      }

      const isGrowth = Ember.get(serviceAgreement, 'isGrowth');
      const usage = Ember.get(serviceAgreement, 'currentUsageSummary');
      const terms = Ember.get(serviceAgreement, 'pricingTerms');

      if (!usage || !terms) {
        return false;
      }

      const platformConnectivityType = Ember.get(product, 'connectivityType');
      const maxDevices = Ember.get(terms, "device.".concat(platformConnectivityType, ".max_devices"));
      const billableDevices = Ember.get(usage, "connectivity.".concat(platformConnectivityType, ".billable_devices"));
      return isGrowth && billableDevices >= maxDevices;
    }),
    isImportGreaterThanGrowthPlanDeviceLimit: Ember.computed('application.currentServiceAgreement.{isGrowth,currentUsageSummary,pricingTerms}', 'model.deviceCount.content', 'product', function () {
      const product = Ember.get(this, 'product');
      const serviceAgreement = Ember.get(this, 'application.currentServiceAgreement');

      if (!serviceAgreement) {
        return false;
      }

      const isGrowth = Ember.get(serviceAgreement, 'isGrowth');
      const usage = Ember.get(serviceAgreement, 'currentUsageSummary');
      const terms = Ember.get(serviceAgreement, 'pricingTerms');

      if (!usage || !terms) {
        return false;
      }

      const platformConnectivityType = Ember.get(product, 'connectivityType');
      const maxDevices = Ember.get(terms, "device.".concat(platformConnectivityType, ".max_devices"));
      const billableDevices = Ember.get(usage, "connectivity.".concat(platformConnectivityType, ".billable_devices"));
      const deviceCount = Ember.get(this, 'model.deviceCount.content');
      return isGrowth && billableDevices + deviceCount > maxDevices;
    }),

    resetUI() {
      this.set('importMethodChosen', false);
      this.set('importManyDevices', false);
      this.set('importSingleDevice', false);
      this.set('needsToUpgradeProductPlan', false);
    },

    async save() {
      const bulkDeviceCount = (await Ember.get(this, 'model.deviceCount')) || 1;
      const isSandbox = Ember.get(this, 'application.currentServiceAgreement.isDeveloper') === true;
      const sandboxRemainingDevices = Ember.get(this, 'application.currentServiceAgreement.remainingDevices');
      const shouldShowBulkDeviceLimitModal = isSandbox && bulkDeviceCount > sandboxRemainingDevices;

      if (shouldShowBulkDeviceLimitModal) {
        Ember.set(this, 'shouldShowBulkDeviceLimitModal', true);
        return;
      }

      Ember.$('.loading').show();
      return this.model.save().then(importedDevicesResult => {
        Ember.$('.loading').hide();
        this.resetUI();
        this.get('importDevicesResponse').handleResponse(this, importedDevicesResult);
        const currentDeveloperServiceAgreement = Ember.get(this, 'application.currentDeveloperServiceAgreement');

        if (currentDeveloperServiceAgreement) {
          currentDeveloperServiceAgreement.reload();
        }
      }, response => {
        Ember.$('.loading').hide();
        const error = response.errors && response.errors[0];
        const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
        this.get('notify').alert('Your devices could not be imported. ' + errorMsg);
      });
    },

    actions: {
      closeModal() {
        //Reset the UI when leaving the modal
        this.resetUI();
        Ember.run.later(this, () => {
          if (this.get('productDevicesController.deviceRouteActive')) {
            this.transitionToRoute('product.devices');
          }
        }, 50);
      },

      chooseImportMethod(method) {
        this.set('importMethodChosen', true);
        this.store.findAll('product').then(products => {
          const product = products.findBy('slug', this.get('routeData.product'));
          const isCellular = product ? product.get('isCellular') : false;
          this.set('isCellular', isCellular);
        });

        if (method === 'one') {
          // Show the single device importer
          this.set('importManyDevices', false);
          this.set('importSingleDevice', true); //Reset the model

          this.set('model.importMethod', 'one');
          this.set('model.file', undefined);
        } else {
          // Show the file uploader
          this.set('importSingleDevice', false);
          this.set('importManyDevices', true); //Reset the model

          this.set('model.importMethod', 'many');
          this.set('model.identifier', undefined);
        }
      },

      save() {
        this.save();
      },

      trackDeviceLimitModalClosed() {
        Ember.get(this, 'segment').trackEvent('device limit modal closed');
        this.send('closeModal');
      },

      trackDeviceLimitModalShown() {
        Ember.get(this, 'segment').trackEvent('device limit modal shown');
      },

      trackDeviceLimitModalCTA() {
        Ember.get(this, 'segment').trackEvent('device limit modal cta clicked');
      },

      trackSingleDeviceLimitModalClosed() {
        Ember.get(this, 'segment').trackEvent('single device limit modal closed');
        this.send('closeModal');
      },

      trackSingleDeviceLimitModalShown() {
        Ember.get(this, 'segment').trackEvent('single device limit modal shown');
      },

      trackSingleDeviceLimitModalCTA() {
        Ember.get(this, 'segment').trackEvent('single device limit modal cta clicked');
      },

      goToBillingPage(event) {
        this.transitionToRoute('user.billing');
        this.send(event);
      }

    }
  });

  _exports.default = _default;
});