define("pconsole/components/integration-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    showTest: false,
    parentEntity: Ember.computed('router', function () {
      const route = Ember.get(this, 'router.currentRouteName');
      const [baseRoute] = route.split('.');
      return baseRoute;
    }),
    editPath: Ember.computed('parentEntity', function () {
      return "".concat(Ember.get(this, 'parentEntity'), ".integrations.edit");
    }),
    deletePath: Ember.computed('parentEntity', function () {
      return "".concat(Ember.get(this, 'parentEntity'), ".integrations.show.delete");
    }),
    actions: {
      openTestModal() {
        Ember.set(this, 'showTest', true);
      },

      closeModal() {
        Ember.$('.modal').modal('hide');
        Ember.set(this, 'showTest', false);
      }

    }
  });

  _exports.default = _default;
});