define("pconsole/controllers/product/customers/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      closeModal() {
        let prevRoute = this.get('previousRoute');

        if (!prevRoute || prevRoute.slice(-7) === 'loading') {
          prevRoute = 'product.customers';
        }

        this.transitionToRoute(prevRoute);
      }

    }
  });

  _exports.default = _default;
});