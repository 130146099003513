define("pconsole/mixins/device-grouping-power-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      handleKeypress(dropdown, e) {
        const key = e.key; // Allow letters, numbers, "-", and "_"

        if (key.match(/[^a-z,A-Z,0-9,-_]/)) {
          return false;
        } //Enter key


        if (e.keyCode === 13 && dropdown.isOpen && !Ember.isBlank(dropdown.searchText)) {
          if (dropdown.resultsCount === 0) {
            this.send('handleEnter', dropdown);
          } else {
            return false;
          }
        }
      }

    }
  });

  _exports.default = _default;
});