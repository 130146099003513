define("pconsole/templates/product/devices/enable-force-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ENBbQ7/F",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Force Enable OTA\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Would you like to force this device to accept OTA firmware updates from the Device Cloud? This will override the local value of \"],[6,\"span\"],[9,\"class\",\"code\"],[7],[0,\"System.updatesEnabled()\"],[8],[0,\" set in application firmware for this device.\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[6,\"b\"],[7],[0,\"As a result of this action, the Device Cloud will attempt to flash \"],[6,\"em\"],[9,\"class\",\"targeted-firmware-version\"],[7],[0,\"v\"],[1,[18,\"targetedProductFirmwareVersion\"],false],[8],[0,\" of firmware to this device immediately.\"],[8],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary cancel-btn\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, cancel\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"enableForceUpdate\"]],[7],[0,\"Yes, force enable OTA\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/devices/enable-force-update.hbs"
    }
  });

  _exports.default = _default;
});