define("pconsole/templates/components/device-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PR2vxKT0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h4\"],[9,\"class\",\"uppercase section-title\"],[7],[0,\"Notes\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"device-notes\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isEditing\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"textarea\"],[7],[0,\"\\n      \"],[6,\"textarea\"],[9,\"id\",\"device-notes\"],[9,\"placeholder\",\"Keep notes on this device here\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[20,[\"onInput\"]]],[[\"value\"],[\"target.value\"]]],null],[7],[1,[18,\"notes\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"notes\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"multiline-attr\"],[7],[1,[18,\"notes\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"undefined-attr\"],[7],[0,\"Click the edit button to keep notes on this device, like 'Deployed to customer site'.\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/device-notes.hbs"
    }
  });

  _exports.default = _default;
});