define("pconsole/components/asset-tracker-map-v2", ["exports", "react", "prop-types", "use-error-boundary", "pconsole/config/environment", "pconsole/components/asset-tracker-popover", "pconsole/components/asset-tracker-map/updated-devices-layers", "pconsole/components/asset-tracker-v2-sources", "pconsole/components/asset-tracker-v2-list-components", "pconsole/components/asset-tracker-v2-device-components", "lodash/throttle", "lodash/uniqBy", "styled-components", "react-mapbox-gl", "@sentry/browser"], function (_exports, _react, _propTypes, _useErrorBoundary, _environment, _assetTrackerPopover, _updatedDevicesLayers, _assetTrackerV2Sources, _assetTrackerV2ListComponents, _assetTrackerV2DeviceComponents, _throttle, _uniqBy, _styledComponents, _reactMapboxGl, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Plots asset tracker device locations on a map.
   *
   * @example
   * ```jsx
   * <AssetTrackerMap devices={devices} />
   * ```
   * @returns {ReactElement} ReactElement
   */
  const AssetTrackerMapV2 = props => {
    const {
      activeDevice = null,
      devices = [],
      visibleDevices = [],
      fitBounds = [],
      fitMaxZoom = 11,
      fitPadding = 150,
      forceBounds = null,
      noZoom = false,
      deviceCount = 1,
      deviceUpdates = null,
      highlightedDevice = '',
      containerStyle = {
        width: '100%',
        height: 'calc(100vh - 13.1rem)'
      },
      onDeviceClick = () => {},
      onFeatureMouseEnter = () => {},
      onFeatureMouseExit = () => {},
      setVisiblePoints = () => {}
    } = props;
    const {
      ErrorBoundary,
      didCatch
    } = (0, _useErrorBoundary.default)();
    const [updatedDevices, setUpdatedDevices] = (0, _react.useState)([]);
    const [hoveredDevice, setHoveredDevice] = (0, _react.useState)();
    const [hoveredFeatureIndex, setHoveredFeatureIndex] = (0, _react.useState)(); // Only skip animation on the first fitBounds

    const [initialBounds, setInitialBounds] = (0, _react.useState)(true);
    (0, _react.useEffect)(() => setInitialBounds(false), []);
    const transaction = Sentry.startTransaction({
      name: 'map-render'
    });
    const span = transaction.startChild({
      op: 'onRender',
      description: "map rendered"
    });
    const computeVisiblePoints = (0, _react.useCallback)((0, _throttle.default)(map => {
      const circlesLayer = map.getLayer('circles');
      const clustersLayer = map.getLayer('clusters'); // Skip if the circles and cluster layers are not rendered

      if (circlesLayer && clustersLayer) {
        // Filter the rendered layers for those outside of clusters
        // Per https://docs.mapbox.com/mapbox-gl-js/api/map/#map#queryrenderedfeatures,
        // duplicate features may be returned
        const visiblePoints = (0, _uniqBy.default)(map.queryRenderedFeatures(null, {
          filter: ['!', ['has', 'point_count']],
          layers: ['circles']
        }), p => p.properties.id);
        const devicesInCluster = map.queryRenderedFeatures(null, {
          layers: ['clusters']
        }).reduce((acc, {
          properties
        }) => properties.point_count + acc, 0);
        const ids = visiblePoints.map(p => p.properties.id);
        setVisiblePoints(visiblePoints, devicesInCluster);
      }
    }, 500, {
      trailing: true
    }), [setVisiblePoints]);

    const handleMove = map => {
      computeVisiblePoints(map);
    };

    const handleIdle = map => {
      computeVisiblePoints(map);
      computeVisiblePoints.flush();
    };

    const handleStyleLoad = map => {
      map.on('idle', handleIdle.bind(null, map));
    };

    (0, _react.useEffect)(() => {
      if (deviceUpdates) {
        setUpdatedDevices(devices.filter(d => deviceUpdates.includes(d.id)));
      }
    }, [devices, deviceUpdates]);

    const onMouseEnter = (device, featureIndex) => {
      setHoveredDevice(device);
      setHoveredFeatureIndex(featureIndex);
      onFeatureMouseEnter(device);
    };

    const onMouseLeave = (device, featureIndex) => {
      setHoveredDevice(null);
      setHoveredFeatureIndex(0);
      onFeatureMouseExit();
    };

    return _react.default.createElement(MapContainer, null, didCatch ? _react.default.createElement(MapError, null, _react.default.createElement('div', null, _react.default.createElement('h2', null, 'We can\'t show that map right now'), _react.default.createElement('p', null, 'Try reloading the page.'))) : _react.default.createElement(ErrorBoundary, null, _react.default.createElement(Map, {
      containerStyle: containerStyle,
      fitBounds: fitBounds,
      fitBoundsOptions: {
        duration: initialBounds ? 0 : 1500,
        maxZoom: fitMaxZoom,
        padding: fitPadding,
        forceBounds
      },
      style: 'mapbox://styles/mapbox/streets-v11',
      onStyleLoad: handleStyleLoad,
      onMove: handleMove,
      onRender: () => {
        span.finish();
        transaction.finish();
      }
    }, _react.default.createElement(_assetTrackerV2Sources.default, {
      devices: devices,
      visibleDevices: visibleDevices,
      activeDevice: devices.findBy('id', activeDevice)
    }), activeDevice && _react.default.createElement(_assetTrackerV2DeviceComponents.default, {
      activeDevice: activeDevice,
      device: devices.findBy('id', activeDevice),
      highlightedDevice: highlightedDevice,
      deviceCount: deviceCount,
      onDeviceClick: onDeviceClick,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave
    }), !activeDevice && _react.default.createElement(_assetTrackerV2ListComponents.default, {
      devices: devices,
      highlightedDevice: highlightedDevice,
      deviceCount: deviceCount,
      onDeviceClick: onDeviceClick,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave
    }), _react.default.createElement(_updatedDevicesLayers.default, {
      activeDevice: activeDevice,
      devices: updatedDevices
    }), !noZoom && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactMapboxGl.ScaleControl, {
      measurement: navigator.language === 'en-US' ? 'mi' : 'km',
      className: 'scale-control',
      style: {
        color: '#1331517',
        bottom: '3.2rem'
      }
    }), _react.default.createElement(_reactMapboxGl.ZoomControl, null)), hoveredDevice && _react.default.createElement(_assetTrackerPopover.default, {
      device: hoveredDevice,
      metadataIndex: hoveredFeatureIndex,
      isActive: hoveredDevice.id === activeDevice
    }))));
  };

  AssetTrackerMapV2.propTypes = {
    activeDevice: _propTypes.default.string,
    containerStyle: _propTypes.default.object,
    center: _propTypes.default.array,
    devices: _propTypes.default.array,
    visibleDevices: _propTypes.default.array,
    fitBounds: _propTypes.default.array,
    fitMaxZoom: _propTypes.default.number,
    fitPadding: _propTypes.default.number,
    forceBounds: _propTypes.default.number,
    noZoom: _propTypes.default.bool,
    deviceCount: _propTypes.default.number,
    deviceUpdates: _propTypes.default.array,
    highlightedDevice: _propTypes.default.string,
    onDeviceClick: _propTypes.default.func,
    onFeatureMouseEnter: _propTypes.default.func,
    onFeatureMouseExit: _propTypes.default.func,
    setVisiblePoints: _propTypes.default.func
  };
  const {
    accessToken
  } = _environment.default.mapbox; // eslint-disable-next-line new-cap

  const Map = (0, _reactMapboxGl.default)({
    accessToken,
    dragRotate: false
  });

  const MapContainer = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-map-v2__MapContainer",
    componentId: "pe5hvp-0"
  })(["height:100%;width:100%;position:relative;.scale-control{color:var(--dark-text-color);}.mapboxgl-popup-content{font-family:var(--base-font);padding:1.6rem;}"]); // TODO: check the rendering of MapError. It appeared wonky


  const MapError = _styledComponents.default.div.withConfig({
    displayName: "asset-tracker-map-v2__MapError",
    componentId: "pe5hvp-1"
  })(["display:flex;flex-direction:column;align-items:center;justify-content:center;position:absolute;top:0;right:0;bottom:0;left:0;div{backdrop-filter:blur(2px);background-color:var(--quaternary-background-color);border-radius:2px;padding:1.6rem 2.4rem;}h2{margin:0;}"]);

  var _default = AssetTrackerMapV2;
  _exports.default = _default;
});