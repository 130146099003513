define("pconsole/routes/product/clients/edit", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model(params) {
      return this.store.find('client', params.client_id).then(client => {
        // We're serializing to JSON because the checkboxes automatically
        // update the model but we want to do it only when submitted
        const clientJSON = client.toJSON();
        clientJSON.id = client.id;
        clientJSON.selectedScopes = client.get('selectedScopes'); // Make selectedScopes not mutable for multiselect-checkboxes

        clientJSON.selectedScopes.addObject = undefined;
        return clientJSON;
      });
    }

  });

  _exports.default = _default;
});