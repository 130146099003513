define("pconsole/serializers/group", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIntoHash(hash, typeClass, snapshot, options) {
      // The API expects a payload without a root key
      // See: https://github.com/emberjs/data/blob/v2.18.0/addon/serializers/rest.js#L695
      const json = this.serialize(snapshot, options);
      Ember.merge(hash, json);
    },

    normalize(typeClass, payload) {
      // Copy name into id field
      payload.id = payload.name;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});