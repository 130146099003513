define("pconsole/adapters/logic-function", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('routeData.{org,product,hasProduct}', function () {
      if (this.get('routeData.hasProduct')) {
        return "v1/products/".concat(this.get('routeData.product'));
      }

      const org = this.get('routeData.org');

      if (org) {
        return "v1/orgs/".concat(org);
      }

      return 'v1';
    }),

    pathForType() {
      return 'logic/functions';
    }

  });

  _exports.default = _default;
});