define("pconsole/components/eventlogs/event-list.react", ["exports", "react", "react-virtualized"], function (_exports, _react, _reactVirtualized) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AutoSizer = _reactVirtualized.default.AutoSizer;
  const List = _reactVirtualized.default.List;

  const EventList = ({
    events,
    rowRenderer
  }) => _react.default.createElement(AutoSizer, null, ({
    width,
    height
  }) => _react.default.createElement(List, {
    width: width,
    height: height,
    rowHeight: 37,
    rowCount: events.length,
    rowRenderer: rowRenderer
  }));

  var _default = EventList;
  _exports.default = _default;
});