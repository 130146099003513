define("pconsole/components/eventlogs/suggestion.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Suggestion = ({
    suggestion,
    onClick
  }) => _react.default.createElement('tr', {
    onMouseDown: onClick,
    key: "suggestion-".concat(suggestion.title)
  }, _react.default.createElement('td', null, suggestion.title), _react.default.createElement('td', null, suggestion.description), _react.default.createElement('td', null, suggestion.usage));

  var _default = Suggestion;
  _exports.default = _default;
});