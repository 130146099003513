define("pconsole/routes/user/sims/deactivate", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserBillingSimDeactivateRoute = Ember.Route.extend(_modalRoute.default, {
    viewName: 'user/billing/sim/deactivate',

    model(params) {
      return this.store.find('sim', params.iccid);
    }

  });
  var _default = UserBillingSimDeactivateRoute;
  _exports.default = _default;
});