define("pconsole/mixins/client-scope-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scopeOptions: Ember.computed(function () {
      return [{
        value: '*:*',
        name: 'Full control'
      }, {
        value: 'customers:create',
        name: 'Customer creation only'
      }];
    })
  });

  _exports.default = _default;
});