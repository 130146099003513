define("pconsole/components/feedback-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    marketo: Ember.inject.service(),
    surveyName: '',
    actions: {
      async provideFeedback() {
        await Ember.get(this, 'marketo').showDynamicChat('logic-feedback');
      }

    }
  });

  _exports.default = _default;
});