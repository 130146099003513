define("pconsole/components/side-bar", ["exports", "ember-data", "pconsole/config/environment", "pconsole/utils/platforms", "pconsole/utils/permissions"], function (_exports, _emberData, _environment, _platforms, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['sidebar user-side-bar'],
    store: Ember.inject.service(),
    applicationController: null,
    buildUrl: _environment.default.APP.BUILD_URL,
    product: null,
    hasTeamsScope: Ember.computed('product', function () {
      return _emberData.default.PromiseObject.create({
        promise: (async () => {
          const scope = 'teams.users:list';
          const store = this.get('store');
          const productModel = this.get('product');

          if (this.get('isProduct')) {
            return await (0, _permissions.checkScope)({
              scope,
              productModel,
              store
            });
          }

          const orgSlug = this.routeData.org;
          return await (0, _permissions.checkScope)({
            scope,
            orgSlug,
            store
          });
        })()
      });
    }),
    productId: Ember.computed('product', function () {
      if (this.get('isProduct')) {
        return this.get('product.id');
      }
    }),
    shouldDisplaySim: Ember.computed('product', function () {
      if (this.get('isProduct')) {
        const platformId = this.get('product.platform.id');
        return (0, _platforms.isCellularPlatform)(platformId);
      } else {
        return true;
      }
    }),
    shouldDisplayMesh: Ember.computed('networks.[]', function () {
      const networks = this.get('networks');
      return networks && networks.get('length') > 0;
    }),
    shouldDisplayEnterpriseHub: Ember.computed('applicationController', function () {
      return !this.get('applicationController.currentServiceAgreement.isGrowth');
    }),
    productLocationEnabled: Ember.computed('product', function () {
      return this.get('isProduct') && this.get('product.settings.location.opt_in');
    }),
    isDevicesRoute: Ember.computed('applicationController.currentRouteName', function () {
      const route = this.get('applicationController.currentRouteName');
      return /\.devices?\./.test(route);
    })
  });

  _exports.default = _default;
});