define("pconsole/adapters/sim", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: Ember.computed('routeData.product', function () {
      const isProduct = this.get('routeData.hasProduct');

      if (isProduct) {
        // HIT THE PRODUCT DEVICE ENDPOINT
        return 'v1/products/' + this.get('routeData.product');
      } else {
        // HIT THE NORMAL DEVICE ENDPOINT
        return 'v1';
      }
    }),

    handleResponse(status, headers, payload, requestData) {
      if (requestData.method === 'DELETE' && this.isSuccess(status, headers, payload)) {
        // Billing returns 204 when the sim release is completed and 202 when it
        // is running in the background
        // When status is 202 we set deletePending attribute to true to display
        // different message in the UI
        payload = {
          delete_pending: status === 202
        };
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});