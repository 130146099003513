define("pconsole/serializers/integration", ["exports", "ember-data", "pconsole/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    session: Ember.inject.service(),
    isNewSerializerAPI: true,

    normalizeResponse(store, type, payload, id, requestType) {
      if (Array.isArray(payload)) {
        // don't load Logic integrations in Ember
        payload = payload.filter(integration => integration.integration_type !== 'Logic');
      }

      let normalized;

      if (id) {
        normalized = {
          'integrations': payload.integration
        };
      } else {
        normalized = {
          'integrations': payload
        };
      }

      return this._super(store, type, normalized, id, requestType);
    },

    attrs: {
      user: {
        serialize: false
      },
      product: {
        serialize: false
      },
      device: {
        serialize: false
      },
      organization: {
        serialize: false
      },
      createdAt: {
        key: 'created_at',
        serialize: false
      },
      updatedAt: {
        key: 'updated_at',
        serialize: false
      },
      integrationType: {
        key: 'integration_type'
      },
      productIds: {
        serialize: 'ids',
        key: 'product_ids'
      },
      productIntegrations: {
        serialize: false,
        key: 'product_integrations'
      },
      orgIntegrationId: {
        serialize: false,
        key: 'org_integration_id'
      },
      orgIntegrationCount: {
        serialize: false,
        key: 'org_integration_count'
      },
      logs: {
        serialize: false
      },
      errors: {
        serialize: false
      },
      counters: {
        serialize: false
      },
      todayCounters: {
        serialize: false
      },
      hubName: {
        key: 'hub_name'
      },
      policyName: {
        key: 'policy_name'
      },
      policyKey: {
        key: 'policy_key'
      },
      apiKey: {
        key: 'api_key'
      },
      azureSasToken: {
        key: 'azure_sas_token'
      },
      googleToken: {
        key: 'google_token'
      },
      amazonToken: {
        key: 'amazon_token'
      }
    },

    /**
      Don't convert camelCased attributes to underscored when serializing.
      Overrides the base method in ActiveModelSerializer
      @method keyForAttribute
      @param {String} attr attribute name
      @return {String} the same name
    */
    keyForAttribute: function keyForAttribute(attr) {
      return attr;
    },

    normalize(typeClass, hash) {
      const user = this.get('session.user');

      if (hash) {
        if (!hash.product_id) {
          // sets the user property
          hash.user_id = user.get('id');
        }

        if (hash.product_ids) {
          hash.organization_id = this.store.peekAll('organization').findBy('slug', this.routeData.org).id;
        }

        if (hash.variables && Array.isArray(hash.variables)) {
          hash.variables = hash.variables.map(variable => {
            const {
              template_name: templateName
            } = variable,
                  rest = _objectWithoutProperties(variable, ["template_name"]);

            return _objectSpread({
              type: 'REFERENCE'
            }, rest, {
              templateName
            });
          });
        }
      }

      return this._super(...arguments);
    },

    serialize(snapshot) {
      var _hash$product_ids;

      const hash = this._super(...arguments);

      const integration = snapshot.record;

      if (integration.get('isWebhook')) {
        // Delete mutually exclusive arguments
        if (!integration.get('isDataFormatWebForm')) {
          delete hash.form;
        }

        if (!integration.get('isDataFormatCustom')) {
          delete hash.body;
        }

        if (!integration.get('isDataFormatJSON')) {
          delete hash.json;
        } // Default data with JSON


        if (integration.get('isDataFormatJSON')) {
          if (!integration.get('noDefaults')) {
            hash.json = true;
          } else if (!integration.get('json')) {
            hash.json = {};
          }
        }
      }

      if (((_hash$product_ids = hash.product_ids) === null || _hash$product_ids === void 0 ? void 0 : _hash$product_ids.length) > 0) {
        hash.product_ids = hash.product_ids.map(id => Number(id));
      }

      if (hash.variables && Array.isArray(hash.variables)) {
        hash.variables = hash.variables.map(variable => {
          const {
            templateName
          } = variable,
                rest = _objectWithoutProperties(variable, ["templateName"]);

          return _objectSpread({}, rest, {
            template_name: templateName
          });
        });
      }

      return hash;
    },

    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options); // This removes null values from the object
      // to avoid sending incorrect integration type
      // data to the back-end

      const cleansedJSON = _.omitBy(json, _.isNil);

      Ember.merge(hash, cleansedJSON);
    }

  });

  _exports.default = _default;
});