define("pconsole/models/configuration-metaschema", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    current: (0, _model.attr)()
  });

  _exports.default = _default;
});