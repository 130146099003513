define("pconsole/templates/components/product-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rS+rwF9t",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"product-card__content\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"product-card__heading\"],[7],[1,[20,[\"product\",\"name\"]],false],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"simpleView\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"product-card__description\"],[7],[1,[20,[\"product\",\"description\"]],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"product-card__owner\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"product\",\"user\"]]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[9,\"class\",\"ion-person\"],[7],[8],[0,\" \"],[1,[20,[\"product\",\"user\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"product\",\"org\"]],[25,\"not\",[[20,[\"product\",\"organization\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[9,\"class\",\"ion-briefcase\"],[7],[8],[0,\" \"],[1,[20,[\"product\",\"org\"]],false],[0,\"\\n      \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"product-card__extra\"],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"current-count\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"im-devices-icon\"],[7],[8],[0,\"\\n    \"],[1,[25,\"number-with-commas\",[[20,[\"product\",\"deviceCount\"]]],null],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"product-id\"],[7],[6,\"i\"],[9,\"class\",\"im-key-icon\"],[7],[8],[1,[20,[\"product\",\"id\"]],false],[8],[0,\"\\n  \"],[6,\"span\"],[7],[1,[25,\"device-type\",null,[[\"device\"],[[20,[\"product\"]]]]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/product-card.hbs"
    }
  });

  _exports.default = _default;
});