define("pconsole/helpers/just-domain-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.justDomainName = justDomainName;
  _exports.default = void 0;

  function justDomainName([url]) {
    try {
      return new URL(url).hostname;
    } catch (error) {
      // invalid URL
      return '';
    }
  }

  var _default = Ember.Helper.helper(justDomainName);

  _exports.default = _default;
});