define("pconsole/components/device-and-customer-filter", ["exports", "pconsole/mixins/object-filter-helpers"], function (_exports, _objectFilterHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_objectFilterHelpers.default, {
    deviceIdTemp: Ember.computed.oneWay('deviceId'),
    customerEmailTemp: Ember.computed.oneWay('customerEmail'),
    actions: {
      resetCustomers() {
        this.set('customerEmail', '');
        const deviceId = this.get('deviceId');
        this.sendAction('triggerModelRefresh', deviceId, '');
      },

      filterItems() {
        this.sendAction('triggerModelRefresh', this.get('deviceIdTemp'), this.get('customerEmailTemp'));
        return false;
      }

    }
  });

  _exports.default = _default;
});