define("pconsole/components/test-integration", ["exports", "pconsole/mixins/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, {
    scope: 'integrations:test',
    product: null,
    eventData: null,
    deviceId: null,
    classNameBindings: ['isTesting'],
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    isTesting: false,
    isProductRequired: Ember.computed('routeData.org', function () {
      return Ember.get(this, 'routeData.org');
    }),
    disableTest: Ember.computed('isProductRequired', 'product', function () {
      if (!Ember.get(this, 'isProductRequired')) {
        return false;
      }

      return !Ember.get(this, 'product');
    }),

    init() {
      this._super(...arguments); // Set the initial value from the integration


      Ember.set(this, 'deviceId', Ember.get(this, 'integration.deviceID'));
    },

    close() {
      Ember.get(this, 'close')();
    },

    actions: {
      async testIntegration() {
        if (Ember.get(this, 'integration.disabled')) {
          Ember.get(this, 'notify').warning({
            html: '<span class="message">This integration is disabled.<br/><br/>Please re-enable the integration before testing.</span>',
            closeAfter: 8000
          });
          return;
        }

        Ember.set(this, 'isTesting', true);
        const notify = Ember.get(this, 'notify'); // Hack to get the url

        const adapter = this.get('store').adapterFor('integration');
        const url = "".concat(adapter.buildURL(), "/integrations/").concat(Ember.get(this, 'integration.id'), "/test");
        const requestBody = {
          data: Ember.get(this, 'eventData'),
          device_id: Ember.get(this, 'deviceId'),
          product_id: Number(Ember.get(this, 'product.id'))
        };

        try {
          const response = await Ember.get(this, 'apiAjax').request({
            url,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(requestBody)
          });
          Ember.set(this, 'isTesting', false);

          if (response.pass) {
            notify.success({
              html: '<span class="message">Success! Your integration is behaving as expected.<br/><br/>You successfully sent a message from Particle to the requested external service.</span>'
            });
          } else {
            const error = response.error || response.data;
            notify.warning({
              html: '<span class="message">The test of your integration was unsuccessful.<br/><br/>The attempt failed with the following error message:<br/><br/><span class="code">' + error + '</span><br/>Double check that you configured your integration correctly. You may need to re-create it if this failure persists.</span>',
              closeAfter: 8000
            });
          }

          this.close();
        } catch (err) {
          var _err$responseJSON;

          const message = (err === null || err === void 0 ? void 0 : (_err$responseJSON = err.responseJSON) === null || _err$responseJSON === void 0 ? void 0 : _err$responseJSON.error_description) || 'There was a problem testing your integration. Please try again later';
          Ember.set(this, 'isTesting', false);
          notify.alert(message);
        }
      },

      selectProduct(id) {
        Ember.set(this, 'product', id);
      },

      closeModal() {
        this.close();
      }

    }
  });

  _exports.default = _default;
});