define("pconsole/templates/components/device-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5FiHTdMk",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[6,\"div\"],[9,\"class\",\"input-wrapper\"],[7],[0,\"\\n  \"],[4,\"validated-field\",null,[[\"model\",\"field\"],[[20,[\"device\"]],\"name\"]],{\"statements\":[[0,\"\\n    \"],[6,\"label\"],[9,\"for\",\"device-name\"],[7],[0,\"Device Name\"],[8],[0,\"\\n    \"],[6,\"input\"],[9,\"id\",\"device-name\"],[9,\"type\",\"text\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[20,[\"onInput\"]]],[[\"value\"],[\"target.value\"]]],null],[9,\"placeholder\",\"Add a name\"],[10,\"value\",[20,[\"device\",\"name\"]],null],[7],[8],[0,\"\\n    \"],[1,[25,\"component\",[[19,1,[\"help-text\"]]],null],false],[0,\"\\n  \"]],\"parameters\":[1]},null],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/device-name.hbs"
    }
  });

  _exports.default = _default;
});