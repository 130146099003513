define("pconsole/components/asset-tracker-v2-list-components", ["exports", "react", "react-mapbox-gl", "pconsole/components/asset-tracker-map/circles-layer", "pconsole/utils/average-group-color"], function (_exports, _react, _reactMapboxGl, _circlesLayer, _averageGroupColor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AssetTrackerV2ListComponents = ({
    devices = [],
    highlightedDevice,
    onDeviceClick,
    onMouseEnter,
    onMouseLeave
  }) => {
    const map = (0, _react.useContext)(_reactMapboxGl.MapContext);

    const onClusterClick = ev => {
      const {
        properties,
        geometry
      } = ev.features[0]; // Ease the camera to the next cluster expansion

      map.getSource('devices').getClusterExpansionZoom(properties.cluster_id, (err, zoom) => {
        if (!err) {
          map.easeTo({
            center: geometry.coordinates,
            zoom
          });
        }
      });
    };

    const clusterLayer = {
      id: 'clusters',
      type: 'circle',
      sourceId: 'devices',
      onClick: onClusterClick,
      filter: ['has', 'point_count'],
      // Property added by Mapbox with clusters
      paint: {
        'circle-color': '#00a3b2',
        'circle-radius': 16
      }
    };
    const clusterCountLayer = {
      id: 'cluster-count',
      type: 'symbol',
      sourceId: 'devices',
      filter: ['has', 'point_count'],
      layout: {
        'text-allow-overlap': true,
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 16
      },
      paint: {
        'text-color': '#f5f6fa' // Primary text color

      }
    };
    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_reactMapboxGl.Layer, clusterLayer), _react.default.createElement(_reactMapboxGl.Layer, clusterCountLayer), _react.default.createElement(_circlesLayer.default, {
      id: 'circles',
      devices: devices,
      highlightedDevice: highlightedDevice,
      sourceId: 'devices',
      onDeviceClick: onDeviceClick,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave
    }));
  };

  var _default = AssetTrackerV2ListComponents;
  _exports.default = _default;
});