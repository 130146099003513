define("pconsole/templates/partials/integration/grouped-integration-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bI1xx7V6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"showGrouped\"]]],null,{\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeNotif\"]],{\"statements\":[[0,\"    \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Integration for multiple products\"],[8],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"\\n      We detected that \"],[6,\"b\"],[7],[0,\"all properties of the integration you just saved match with the properties of another integration in your organization so we’ve linkked them together.\"],[8],[0,\"\\n      \"],[6,\"br\"],[7],[8],[0,\"\\n      You can access the details of this \"],[6,\"b\"],[7],[0,\"multiple product integration\"],[8],[0,\" from the integrations page of each product where it’s executing, or from your\\n\"],[4,\"link-to\",[\"organization.integrations.show\",[20,[\"model\",\"product\",\"organization\",\"slug\"]],[20,[\"model\",\"orgIntegrationId\"]]],null,{\"statements\":[[0,\"        organization integrations page.\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"ui button primary outline\"],[3,\"action\",[[19,0,[]],\"closeNotif\"]],[7],[0,\"Ok, got it\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/integration/grouped-integration-modal.hbs"
    }
  });

  _exports.default = _default;
});