define("pconsole/components/organization-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSpace: '',
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    _getOrganizations: function () {
      const store = Ember.get(this, 'store');
      const orgs = store.peekAll('organization');
      Ember.set(this, 'orgs', orgs);

      this._setSpace();
    }.on('init'),

    _setSpace() {
      const store = Ember.get(this, 'store');
      const orgRouteId = Ember.get(this, 'routeData.org');
      const product = Ember.get(this, 'product');

      if (orgRouteId) {
        const org = store.peekAll('organization').filterBy('slug', orgRouteId)[0];
        Ember.set(this, 'currentSpace', Ember.get(org, 'name'));
      } else if (product && !!product.belongsTo('organization').id()) {
        Ember.set(this, 'currentSpace', product.get('organization.name'));
      } else {
        Ember.set(this, 'currentSpace', 'Sandbox');
      }
    },

    orgOptions: Ember.computed('orgs', function () {
      const orgs = Ember.get(this, 'orgs');
      let options = orgs.toArray().map(o => Ember.get(o, 'name'));

      if (options.length === 0) {
        // Hack to render an empty organizations option
        options = ['noOrgs'];
      }

      return ['Sandbox', {
        groupName: 'Organizations',
        options
      }];
    }),
    actions: {
      select(option) {
        const router = Ember.get(this, 'router');

        if (option === 'noOrgs') {
          window.open('https://particle.io/sales', '_blank').focus();
          return;
        }

        if (option === 'Sandbox') {
          router.transitionTo('user.index');
        } else {
          const org = Ember.get(this, 'orgs').findBy('name', option);
          router.transitionTo('organization.index', org.get('slug'));
        }
      }

    }
  });

  _exports.default = _default;
});