define("pconsole/components/eventlogs/suggestions.react", ["exports", "react", "pconsole/components/eventlogs/suggestions-table.react", "pconsole/components/eventlogs/suggestions-util"], function (_exports, _react, _suggestionsTable, _suggestionsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Suggestions = ({
    availableOptions,
    query,
    useSuggestion
  }) => {
    let rendered = null;
    let suggestions = Object.keys(availableOptions).map(key => availableOptions[key]);
    return suggestions.length > 0 && _react.default.createElement(_suggestionsTable.default, {
      suggestions: suggestions,
      onSuggestionClick: useSuggestion,
      query: query
    });
  };

  var _default = Suggestions;
  _exports.default = _default;
});