define("pconsole/templates/components/manual-paused-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oeW7ZrLD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  Your \"],[1,[20,[\"alert\",\"serviceAgreement\",\"name\"]],false],[0,\" service has been paused\\n\"],[8],[0,\"\\n\\n\"],[6,\"p\"],[7],[0,\"\\n  The Particle team has paused the service for your device(s)\"],[4,\"if\",[[20,[\"organizationName\"]]],null,{\"statements\":[[0,\" for \"],[6,\"b\"],[7],[1,[18,\"organizationName\"],false],[8]],\"parameters\":[]},null],[0,\".\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"alert\",\"devicePauseDate\"]]],null,{\"statements\":[[0,\"  \"],[6,\"p\"],[7],[0,\"\\n    Service was paused on \\n    \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"devicePauseDate\"]],\"MMM D\",\"utc\"],null],false],[0,\"\\n    and will remain paused until the next usage period, starting\\n    \"],[1,[25,\"formatted-date\",[[20,[\"alert\",\"nextBillingPeriodStart\"]],\"MMM D\",\"utc\"],null],false],[0,\".\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"p\"],[7],[0,\"For more information,\\n  \"],[6,\"a\"],[9,\"href\",\"https://support.particle.io/hc/en-us/requests/new\"],[9,\"target\",\"_blank\"],[7],[0,\"contact support\"],[8],[0,\".\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"organizationSlug\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"organization.billing\",[20,[\"organizationSlug\"]]],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"user.billing\"],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/manual-paused-alert.hbs"
    }
  });

  _exports.default = _default;
});