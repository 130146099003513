define("pconsole/templates/components/sort-chevs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7x6ePQoK",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[3,\"action\",[[19,0,[]],\"sort\"]],[7],[0,\"\\n  \"],[6,\"i\"],[10,\"class\",[26,[\"icon ion-chevron-up sort-icon \",[25,\"if\",[[20,[\"asc\"]],\"active\"],null]]]],[7],[8],[0,\"\\n  \"],[6,\"i\"],[10,\"class\",[26,[\"icon ion-chevron-down sort-icon \",[25,\"if\",[[20,[\"desc\"]],\"active\"],null]]]],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/sort-chevs.hbs"
    }
  });

  _exports.default = _default;
});