define("pconsole/services/logic-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    template: null,

    setTemplate(template) {
      Ember.set(this, 'template', template);
    },

    getTemplate() {
      return Ember.get(this, 'template');
    }

  });

  _exports.default = _default;
});