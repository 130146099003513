define("pconsole/routes/organization/team/invite-team-member", ["exports", "pconsole/mixins/modal-route", "ember-easy-form-extensions/mixins/routes/dirty-record-handler"], function (_exports, _modalRoute, _dirtyRecordHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _dirtyRecordHandler.default, {
    model() {
      return Ember.RSVP.hash({
        roles: this.store.peekAll('role'),
        newTeamMember: this.store.createRecord('team', {
          //newlyCreated: true,
          invited: true,
          organization: this.modelFor('organization').organization
        })
      });
    },

    afterModel(model) {
      const defaultRole = model.roles.findBy('default', true);

      if (defaultRole) {
        model.newTeamMember.set('role', defaultRole);
      } else {
        model.newTeamMember.set('role', model.roles.get('firstObject'));
      }

      this._super(...arguments);
    },

    setupController(controller, model) {
      controller.set('roles', model.roles);
      controller.set('model', model.newTeamMember);
    }

  });

  _exports.default = _default;
});