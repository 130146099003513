define("pconsole/templates/partials/logic/define", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DzcCyNdk",
    "block": "{\"symbols\":[\"field\",\"field\"],\"statements\":[[6,\"p\"],[7],[0,\"\\n  Define the name and description for your Logic Function.\\n\"],[8],[0,\"\\n\\n\\n\"],[1,[25,\"logic-function-info-toast\",null,[[\"class\",\"visible\"],[\"logic-function-toast-container\",[20,[\"isSharedLogicFunction\"]]]]],false],[0,\"\\n\\n\"],[4,\"validated-field\",[[20,[\"logicFunction\"]],\"name\"],null,{\"statements\":[[0,\"  \"],[6,\"label\"],[9,\"for\",\"function-name\"],[7],[0,\"Logic Function name\"],[8],[0,\"\\n  \"],[6,\"input\"],[9,\"id\",\"definition-name\"],[9,\"type\",\"text\"],[9,\"placeholder\",\"Transform event data\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"logicFunction\",\"name\"]]],null]],[[\"value\"],[\"target.value\"]]],null],[10,\"value\",[20,[\"logicFunction\",\"name\"]],null],[7],[8],[0,\"\\n  \"],[1,[19,2,[\"help-text\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[4,\"validated-field\",[[20,[\"logicFunction\"]],\"description\"],null,{\"statements\":[[0,\"  \"],[6,\"label\"],[9,\"for\",\"function-description\"],[7],[0,\"Description\"],[8],[0,\"\\n  \"],[6,\"textarea\"],[9,\"id\",\"function-description\"],[9,\"placeholder\",\"User friendly description of the Logic Function\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"logicFunction\",\"description\"]]],null]],[[\"value\"],[\"target.value\"]]],null],[10,\"value\",[20,[\"logicFunction\",\"description\"]],null],[7],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/logic/define.hbs"
    }
  });

  _exports.default = _default;
});