define("pconsole/controllers/product/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    notify: Ember.inject.service(),
    apiAjax: Ember.inject.service(),
    // setup our query params
    queryParams: ['page', 'perPage', 'deviceId', 'customerEmail', 'sortAttr', 'sortDir'],
    sortParams: Ember.computed('sortAttr', 'sortDir', function () {
      const sortAttr = this.get('sortAttr');
      const sortDir = this.get('sortDir');
      return {
        attr: sortAttr,
        dir: sortDir
      };
    }),
    shouldDisplayPagination: Ember.computed('model.totalPages', function () {
      return this.get('model.totalPages') > 1;
    }),
    shouldDisplayPerPage: Ember.computed('model', function () {
      return this.get('model.length') > 0;
    }),
    isLoadingCustomers: Ember.observer('model.loading', function () {
      const isLoading = this.get('model.loading');

      if (isLoading) {
        Ember.$('.loading').show();
      } else {
        Ember.$('.loading').hide();
      }
    }),
    isPrivateBeta: Ember.computed('productConfig.@each.privateBeta', function () {
      const productConfig = this.get('productConfig'); // TODO: Handle the organizations more gracefully in the organization route

      const organization = this.get('product.organization');
      const allowsUnauthenticatedCustomerRegister = organization.get('allowsUnauthenticatedCustomerRegister');

      if (productConfig) {
        return productConfig.get('privateBeta') === 'Yes' || allowsUnauthenticatedCustomerRegister;
      } else {
        return allowsUnauthenticatedCustomerRegister;
      }
    }),
    hasSmtpSettings: Ember.computed('product.organization.smtpHostname', 'product.organization.smtpEmailFrom', 'product.organization.smtpEmailChangePasswordURL', function () {
      const smtpProps = ['product.organization.smtpHostname', 'product.organization.smtpEmailFrom', 'product.organization.smtpEmailChangePasswordURL'];
      return smtpProps.every(p => {
        return !!this.get(p);
      });
    }),
    actions: {
      updateQueryParams(deviceId, customerEmail) {
        if (deviceId) {
          this.set('deviceId', deviceId);
        }

        if (customerEmail) {
          this.set('customerEmail', customerEmail);
        }

        this.set('page', 1);
      },

      sortInController(sortAttr, direction) {
        this.set('sortAttr', sortAttr);
        this.set('sortDir', direction);
        this.send('refreshModel');
      },

      // https://github.com/mharris717/ember-cli-pagination/issues/146
      pageClicked(page) {
        this.setProperties({
          page: page
        });
      },

      changePerPage() {
        this.setProperties({
          page: 1
        });
        this.send('refreshModel');
      },

      resetPassword(customer) {
        if (!this.get('hasSmtpSettings')) {
          return false;
        }

        Ember.$('.loading').show();
        const customerEmail = customer.get('username');
        return this.get('apiAjax').request({
          url: '/v1/products/' + this.get('routeData.product') + '/customers/reset_password',
          type: 'POST',
          data: {
            email: customerEmail
          },
          dataType: 'json'
        }).then(response => {
          Ember.$('.loading').hide();
          this.get('notify').success('The password reset email was sent successfully!');
          return response;
        }, err => {
          Ember.$('.loading').hide();
          this.get('notify').error('The password reset email could not be sent.');
          return Ember.RSVP.reject(err);
        });
      },

      cancelExpand() {
        return false;
      }

    }
  });

  _exports.default = _default;
});