define("pconsole/models/plan-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PlanGroup = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    amount: _emberData.default.attr(),
    monthlyIntervalAmount: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    requireCreditCard: _emberData.default.attr('boolean'),
    isEnterprise: _emberData.default.attr('boolean'),
    deviceLimit: _emberData.default.attr('number'),
    outboundEventLimit: _emberData.default.attr('number'),
    seatLimit: _emberData.default.attr('number'),
    groupLimit: _emberData.default.attr('number'),
    supportSLA: _emberData.default.attr('string'),
    guaranteedUptimeSLA: _emberData.default.attr('string'),
    current: _emberData.default.attr('boolean'),
    plans: _emberData.default.hasMany('plan'),
    visible: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    canChangePlan: _emberData.default.attr('boolean', {
      defaultValue: true
    })
  });

  PlanGroup.reopenClass({
    FIXTURES: [{
      'id': 1,
      'name': 'Prototype',
      'amount': 'Free',
      'requireCreditCard': false,
      'description': 'For rapid prototyping and piloting of IoT products'
    }, {
      'id': 2,
      'name': 'Pilot',
      'requireCreditCard': true,
      'visible': false
    }, {
      'id': 3,
      'name': 'Rollout',
      'requireCreditCard': true,
      'supportSLA': '1 business day',
      'visible': false
    }, {
      'id': 4,
      'name': 'Scale',
      'requireCreditCard': true,
      'supportSLA': '4 business hours',
      'guaranteedUptimeSLA': '99.90%',
      'visible': false
    }, {
      'id': 5,
      'name': 'Production',
      'requireCreditCard': false,
      'isEnterprise': true,
      'supportSLA': 'Dedicated CSM',
      'guaranteedUptimeSLA': '99.9%',
      'canChangePlan': false,
      'description': 'For professional IoT products, deployed at scale'
    }, {
      'id': 6,
      'name': 'Internal',
      'requireCreditCard': false,
      'visible': false,
      'canChangePlan': false
    }]
  });
  var _default = PlanGroup;
  _exports.default = _default;
});