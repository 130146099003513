define("pconsole/models/ledger-instance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    definition: _emberData.default.belongsTo('ledgerDefinition'),
    name: _emberData.default.attr('string'),
    instanceData: _emberData.default.attr(),
    scope: _emberData.default.attr(),
    sizeBytes: _emberData.default.attr('number'),
    updatedAt: _emberData.default.attr('string'),
    stats: _emberData.default.attr(),
    isOwnerScope: Ember.computed('scope.type', function () {
      return Ember.get(this, 'scope.type') === 'Owner';
    }),
    isProductScope: Ember.computed('scope.type', function () {
      return Ember.get(this, 'scope.type') === 'Product';
    }),
    isDeviceScope: Ember.computed('scope.type', function () {
      return Ember.get(this, 'scope.type') === 'Device';
    })
  });

  _exports.default = _default;
});