define("pconsole/mixins/browser-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    browserSupportsGetUserMedia: Ember.computed('', function () {
      return !!(navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia);
    }),
    isChromeOrOpera: Ember.computed('', function () {
      const isOpera = !!window.opr && !!window.opr.addons || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
      const isChrome = !!window.chrome && !!window.chrome.webstore;
      return isChrome || isOpera;
    }),
    isFirefox: Ember.computed('', function () {
      const isFirefox = typeof InstallTrigger !== 'undefined';
      return isFirefox;
    })
  });

  _exports.default = _default;
});