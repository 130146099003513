define("pconsole/components/console-theme", ["exports", "@particle/react-particle-ui-theme"], function (_exports, _reactParticleUiTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // setup fonts for Console
  _reactParticleUiTheme.default.baseFont = '"Gotham SSm A", "Gotham SSm B", proxima-nova, "Helvetica Neue", helvetica, arial, sans-serif;';
  _reactParticleUiTheme.default.headerFont = '"Gotham A", "Gotham B", proxima-nova, "Helvetica Neue", helvetica, arial, sans-serif';
  var _default = _reactParticleUiTheme.default;
  _exports.default = _default;
});