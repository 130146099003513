define("pconsole/components/tab-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tab-panel'],
    classNameBindings: ['className'],
    className: '',
    tabs: [],
    activeTab: null,

    /**
     * Disable interactions with the indicated tab ids
     * @type {string[]}
     */
    disabledTabs: [],

    /**
     * If true, indicates that the interactions with the tabs have extra rules
     * @type {boolean}
     */
    controlled: false,
    internalTabs: Ember.computed('tabs', 'activeTab', 'disabledTabs', 'controlled', function () {
      const tabs = Ember.get(this, 'tabs');
      const activeTab = Ember.get(this, 'activeTab');
      const disabledTabs = Ember.get(this, 'disabledTabs');
      const controlled = Ember.get(this, 'controlled');
      const internalTabs = tabs.map(({
        id,
        title
      }, idx) => {
        const className = ['tab-panel__tab'];

        if (!activeTab && idx === 0 || (activeTab === null || activeTab === void 0 ? void 0 : activeTab.id) === id) {
          className.push('tab-panel__tab--active');
        }

        if (controlled && (activeTab === null || activeTab === void 0 ? void 0 : activeTab.order) > idx + 1) {
          className.push(' tab-panel__tab--complete');
        }

        if (disabledTabs.includes(id)) {
          className.push(' tab-panel__tab--disabled');
        }

        return {
          id,
          title,
          className: className.join(' '),
          order: idx + 1
        };
      });

      if (!activeTab) {
        Ember.set(this, 'activeTab', internalTabs[0]);
      }

      return internalTabs;
    }),

    /* ----- Only for controlled tabs ----- */

    /**
     * The implementing component should provide this flag based on the logic to allow moving to the next step
     * @type {boolean}
     */
    canContinue: true,

    /**
     * Text to display on the 'next' button once reached the final step
     * @type {boolean}
     */
    lastText: 'End',
    isLastStep: Ember.computed('activeTab', 'internalTabs', function () {
      const activeTab = Ember.get(this, 'activeTab');
      const tabs = Ember.get(this, 'internalTabs').length;
      return activeTab.order === tabs;
    }),

    /* ------------------------------------ */
    updateTab(tab) {
      Ember.set(this, 'activeTab', tab);
      this.sendAction('changeActiveTab', tab); // For containers to respond to change
    },

    actions: {
      setActiveTab(tab) {
        this.updateTab(tab);
      },

      goNext() {
        const currentActive = Ember.get(this, 'activeTab');
        const newActive = Ember.get(this, 'internalTabs')[currentActive.order];

        if (newActive) {
          this.updateTab(newActive);
        } else {
          // Reached to the final step and clicked end
          this.sendAction('endTabs');
        }
      },

      goBack() {
        const currentActive = Ember.get(this, 'activeTab');
        const newActive = Ember.get(this, 'internalTabs')[currentActive.order - 2];
        this.updateTab(newActive);
      }

    }
  });

  _exports.default = _default;
});