define("pconsole/mixins/permissions", ["exports", "pconsole/utils/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    disabled: false,
    store: Ember.inject.service(),

    init() {
      this._super(...arguments); // FIXME: it would be more efficient to set `disabled` in didReceiveAttrs, but the mixin isn't always
      // applied to a component; update `disabled` in the next render for now


      Ember.run.schedule('afterRender', this, async function () {
        const store = this.get('store');
        const scope = this.get('scope');
        const productSlug = this.get('routeData.product');
        const productModel = store.peekAll('product').findBy('slug', productSlug);
        const orgSlug = this.get('routeData.org');
        const orgModel = store.peekAll('organization').findBy('slug', orgSlug);

        if (scope && (productModel || orgSlug)) {
          const hasScope = await (0, _permissions.checkScope)({
            scope,
            productModel,
            orgModel,
            store
          });
          this.set('disabled', !hasScope);
        }
      });
    }

  });

  _exports.default = _default;
});