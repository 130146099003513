define("pconsole/helpers/is-not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNot = isNot;
  _exports.default = void 0;

  function isNot([value]) {
    return !value;
  }

  var _default = Ember.Helper.helper(isNot);

  _exports.default = _default;
});