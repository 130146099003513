define("pconsole/routes/product/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'View Device',
      linkable: false
    },
    queryParams: {
      setupStatus: {
        refreshModel: false
      }
    },
    notify: Ember.inject.service(),

    async model({
      device_id
    }) {
      let deviceLocation = null;

      try {
        const device = await this.store.queryRecord('device-location', {
          id: device_id
        });
        deviceLocation = device.toObject();
      } catch (_unused) {// Avoid error handling from Ember, this means no location loaded
      }

      const model = await Ember.RSVP.hash({
        device: this.store.findRecord('device', device_id, {
          reload: true
        }),
        firmwares: this.store.findAll('product-firmware'),
        deviceGroups: this.store.findAll('group'),
        deviceLocation
      });
      const product = this.modelFor('product').product;
      const isAssetTracker = Ember.get(product, 'platform.isAssetTracker');
      const notify = Ember.get(this, 'notify');
      let configuration, schema, metaschema;

      if (isAssetTracker) {
        try {
          metaschema = await this.store.queryRecord('configuration-metaschema', {
            deviceId: device_id
          });
        } catch (ex) {
          notify.warning("Couldn't load device configuration meta-schema");
        }

        try {
          schema = await this.store.queryRecord('configuration-schema', {
            deviceId: device_id
          });
        } catch (ex) {
          notify.warning("Couldn't load device configuration schema");
        }

        try {
          configuration = await this.store.queryRecord('device-configuration', {
            deviceId: device_id
          });
        } catch (ex) {
          // Ignore not found response; indicates that no configuration exists yet
          if (!ex.isAdapterError) {
            notify.warning("Couldn't load device configuration values");
          }
        }

        if (!configuration) {
          configuration = this.store.createRecord('device-configuration');
        }
      }

      return _objectSpread({}, model, {
        configuration,
        schema,
        metaschema
      });
    },

    afterModel({
      device
    }, transition) {
      if (device.get('quarantined') || device.get('denied')) {
        this.transitionTo('product.devices');
        this.get('notify').warning('You cannot view device details for a device that is either quarantined or denied from this product. Approve the device to access this page.');
      } else {
        const {
          product
        } = this.modelFor('product');
        const targetedFirmwareVersion = device.get('targetedFirmwareReleaseVersion');
        return Ember.RSVP.hash({
          sim: device.get('sim'),
          targetedFirmware: targetedFirmwareVersion && this.store.findRecord('product-firmware', targetedFirmwareVersion),
          subscription: product.get('subscription')
        }).then(hash => {
          if (hash.targetedFirmware) {
            device.set('isTargetedFirmwareImmediate', hash.targetedFirmware.get('immediate'));
          }

          if (hash.sim) {
            hash.sim.set('subscription', hash.subscription);
            this.set('sim', hash.sim);
            device.set('sim', hash.sim);
          }

          return this._super(device, transition);
        }).catch(() => {
          this._super(device, transition);
        });
      }
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'isEditing', false);
        const device = Ember.get(controller, 'model.device'); // Revert attrs that weren't saved if the device wasn't deleted

        if (device) {
          device.rollbackAttributes();
          controller.rollbackDeviceGroups();
        }

        controller.unsubscribe();
      }
    },

    setupController(controller
    /*, model*/
    ) {
      this._super(...arguments);

      controller.subscribe();
      controller.createDeviceGroupsSnapshot();
    },

    deactivate() {
      const sim = this.get('sim');
      this.get('eventStream').stopListening();

      if (sim) {
        sim.unloadRecord();
      }
    }

  });

  _exports.default = _default;
});