define("pconsole/templates/partials/reactivate-sim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WXMK9CZW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Reactivate SIM Card\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"We're so glad you'd like to reactivate your SIM with an ICCID ending in \"],[6,\"strong\"],[7],[1,[25,\"last-4\",[[20,[\"sim\",\"iccid\"]]],null],false],[8],[0,\"! This will resume cellular data transfer and immediately allow devices using this SIM to connect to the Internet.\"],[8],[0,\"\\n\\n  \"],[6,\"a\"],[10,\"class\",[26,[\"ui button outline primary full-width \",[25,\"if\",[[20,[\"disabled\"]],\"disabled-btn\",\"\"],null]]]],[3,\"action\",[[19,0,[]],\"reactivateSim\"]],[7],[0,\"Reactivate SIM\"],[8],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"helper-message\"],[7],[0,\"Reactivation may take up to 60 seconds. Please be patient.\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/reactivate-sim.hbs"
    }
  });

  _exports.default = _default;
});