define("pconsole/controllers/product/device", ["exports", "pconsole/config/environment", "pconsole/mixins/permissions", "pconsole/utils/permissions", "lodash/throttle"], function (_exports, _environment, _permissions, _permissions2, _throttle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const DEVICE_RELOAD_INTERVAL = 5000; // don't reload the device object more than this often

  var _default = Ember.Controller.extend(_permissions.default, {
    _deviceGroupsSnapshot: null,
    setupStatus: null,
    activeTab: 'logs',
    isConfigFormRendered: false,
    isEditing: false,
    isSaving: false,
    lastHandshakeTooltipText: _environment.default.APP.LAST_HANDSHAKE_TOOLTIP_TEXT,
    waitingToConnectTooltipText: _environment.default.APP.WAITING_TO_CONNECT_TOOLTIP,
    userBillingUrl: '',
    throttledDeviceReload: null,
    // set in init
    apiAjax: Ember.inject.service(),
    application: Ember.inject.controller(),
    configuration: Ember.computed.alias('model.configuration'),
    device: Ember.computed.alias('model.device'),
    firmwares: Ember.computed.alias('model.firmwares'),
    isProduct: Ember.computed.alias('routeData.hasProduct'),
    notify: Ember.inject.service(),
    product: Ember.computed.alias('device.product'),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    schema: Ember.computed.readOnly('model.schema'),
    metaschema: Ember.computed.readOnly('model.metaschema'),
    segment: Ember.inject.service(),
    queryParams: ['setupStatus'],

    /** Minimap variables */
    containerStyle: {
      width: '100%',
      height: '296px'
    },
    locationEnabled: Ember.computed('product.settings.location', function () {
      return Ember.get(this, 'product.settings.location.opt_in');
    }),
    hasLocation: Ember.computed('locationEnabled', 'model.deviceLocation.hasLocation', function () {
      return Ember.get(this, 'locationEnabled') && Ember.get(this, 'model.deviceLocation.hasLocation');
    }),
    // Asset tracker map needs an array of devices even when rendering just one
    deviceLocationList: Ember.computed('model.deviceLocation', function () {
      const device = Ember.get(this, 'model.deviceLocation');
      return device ? [device] : [];
    }),
    deviceFitBounds: Ember.computed('model.deviceLocation', function () {
      var _device$geometry;

      const device = Ember.get(this, 'model.deviceLocation');
      const coords = device === null || device === void 0 ? void 0 : (_device$geometry = device.geometry) === null || _device$geometry === void 0 ? void 0 : _device$geometry.coordinates;

      if (coords) {
        return [coords, coords];
      } else {
        return [[-122.87763214, 26.935339], [-70.565102, 47.251812]];
      }
    }),

    /***/
    productId: Ember.computed('device', function () {
      const device = this.get('device');
      return device.belongsTo('product').id();
    }),
    planGroupsDisabled: Ember.computed('device.product.groupsEnabled', function () {
      return !(this.get('isProduct') ? this.get('device.product.groupsEnabled') : false);
    }),
    showSetupStatus: Ember.computed('setupStatus', function () {
      return !!this.get('setupStatus');
    }),
    editButtonDisabled: Ember.computed('disabled', 'product', 'routeData.hasProduct', function () {
      const disabled = this.get('disabled');
      const hasProduct = this.get('routeData.hasProduct');
      const scope = 'devices:update';
      const productModel = this.get('product');
      const store = this.get('store');
      return disabled || hasProduct && !(0, _permissions2.checkScope)({
        scope,
        productModel,
        store
      });
    }),

    createDeviceGroupsSnapshot() {
      // Create a snapshot of the device's groups (Ember Data doesn't rollback relationships)
      const device = Ember.get(this, 'device');
      const groups = device.hasMany('groups').value();

      if (groups) {
        Ember.set(this, '_deviceGroupsSnapshot', groups.toArray());
      }
    },

    init() {
      this._super(...arguments);

      Ember.set(this, 'userBillingUrl', Ember.get(this, 'router').urlFor('user.billing'));
      Ember.set(this, 'throttledDeviceReload', (0, _throttle.default)(() => Ember.get(this, 'device').reload(), DEVICE_RELOAD_INTERVAL, {
        leading: true,
        trailing: true
      }));
    },

    rollbackDeviceGroups() {
      // Revert device's groups to snapshot
      const groups = Ember.get(this, '_deviceGroupsSnapshot');
      Ember.get(this, 'device.groups').setObjects(groups);
      Ember.set(this, '_deviceGroupsSnapshot', null);
    },

    subscribe() {
      const device = this.get('device');
      const eventStreamer = this.get('eventStream');
      const isProduct = Ember.get(this, 'isProduct');
      eventStreamer.on('event', e => {
        const isSaving = Ember.get(this, 'isSaving'); // avoid processing events until device is fully loaded

        if (!device.get('isLoaded')) {
          return;
        }

        if (e.name === 'spark/status' || e.name === 'spark/device/app-hash' || e.name.startsWith('particle/device/updates/')) {
          this.get('throttledDeviceReload')();
        }

        if (isProduct && e.name === 'cfg' && !isSaving) {
          const currentConfiguration = Ember.get(this, 'configuration.current');
          let cfg;

          try {
            ({
              cfg
            } = JSON.parse(e.data));
          } catch (_ex) {
            return;
          }

          const updatedConfiguration = _objectSpread({}, currentConfiguration, {}, cfg);

          const isDirty = Ember.get(this, 'configuration.hasDirtyAttributes');

          if (!isDirty || window.confirm('There’s a newer configuration; overwrite your changes?')) {
            Ember.get(this, 'configuration').rollbackAttributes();
            this.store.pushPayload('device-configuration', {
              deviceConfiguration: {
                id: e.coreid,
                current: updatedConfiguration
              }
            });
          }
        }
      });
      const options = {
        deviceId: device.id
      };

      if (isProduct) {
        options.product = this.get('routeData.product');
      }

      eventStreamer.startListening(options);
    },

    unsubscribe() {
      this.get('eventStream').stopListening();
      this.get('throttledDeviceReload').cancel();
    },

    actions: {
      async createGroup(groupName) {
        const device = Ember.get(this, 'device');
        const store = Ember.get(this, 'store');
        const group = store.createRecord('group', {
          name: groupName,
          product: Ember.get(device, 'product')
        });

        try {
          await group.save();
          Ember.get(device, 'groups').pushObject(group);
        } catch (ex) {
          const error = ex.errors[0];
          const errorMsg = error ? error.detail : 'Please try again later.';
          this.get('notify').alert('Your group could not be created. ' + errorMsg);
        }
      },

      openForceUpdateModal() {
        const isProduct = this.get('isProduct');

        if (isProduct) {
          this.transitionToRoute('product.device.enableForceUpdate');
        } else {
          this.transitionToRoute('user.device.enableForceUpdate');
        }
      },

      async saveAssetTrackerDeviceSettings({
        formData
      }) {
        const notify = Ember.get(this, 'notify');
        const deviceConfiguration = formData;
        const configuration = Ember.get(this, 'configuration'); // FIXME (@mossymaker): a config key appears in the formData object with
        // a value that duplicates the other fields

        delete deviceConfiguration.config;

        if (Ember.get(configuration, 'isNew')) {
          Ember.set(configuration, 'id', Ember.get(this, 'device.id'));
        }

        Ember.set(this, 'isSaving', true);
        Ember.set(configuration, 'current', formData);
        Ember.get(this, 'segment').trackEvent('configuration saved');

        try {
          await configuration.save();
          notify.success('Device configuration saved');
        } catch (ex) {
          const {
            message
          } = ex;

          if (message) {
            notify.error(message);
          } else {
            notify.error('There was a problem saving that configuration');
          }
        } finally {
          Ember.set(this, 'isSaving', false);
        }
      },

      setActiveTab(tab) {
        this.set('activeTab', tab);
        const product = this.get('routeData.product');
        const deviceId = this.get('device.id');

        if (tab === 'vitals-history') {
          this.get('segment').trackEvent('vitals-histry-tab-opened', {
            product,
            deviceId
          });
        }

        if (tab !== 'asset-tracker-settings') {
          Ember.set(this, 'isConfigFormRendered', false);
        }
      },

      async save() {
        const device = Ember.get(this, 'device');
        Ember.set(this, 'isSaving', true);

        try {
          await device.validate();

          if (Ember.get(device, 'validations.isTruelyValid')) {
            await device.save();
            this.createDeviceGroupsSnapshot();
            Ember.set(this, 'isEditing', false);
            Ember.get(this, 'notify').success("Device info was updated");
          }
        } catch (ex) {
          Ember.get(this, 'notify').alert("There was a problem updating the device's info");
        } finally {
          Ember.set(this, 'isSaving', false);
        }
      },

      unlockDevice(device) {
        device.set('desiredFirmwareVersion', null);
        device.save().then(() => {
          this.send('refreshModel');
          this.get('notify').success('Desired firmware version unlocked');
        }, err => {
          this.get('notify').alert('Error unlocking device firmware version: ' + err);
        });
        return false;
      },

      updateAssetTrackerDeviceConfiguration({
        formData
      }) {
        const isConfigFormRendered = Ember.get(this, 'isConfigFormRendered'); // Ignore the first onChange event because of
        // https://github.com/rjsf-team/react-jsonschema-form/issues/1648

        if (isConfigFormRendered) {
          Ember.set(this, 'configuration.current', formData);
        } else {
          Ember.set(this, 'isConfigFormRendered', true);
        }
      }

    }
  });

  _exports.default = _default;
});