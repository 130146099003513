define("pconsole/controllers/user/ledger/index/tos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    acceptedTOS: false,

    close() {
      let path = '/';

      if (Ember.get(this, 'acceptedTOS')) {
        path = '/ledger';
      }

      this.transitionToRoute(path);
    },

    actions: {
      closeModal() {
        this.close();
      },

      async acceptTOS() {
        const {
          user
        } = Ember.get(this, 'model');
        const tos = this.store.peekRecord('tos', user.get('tos.id'));
        tos.setProperties({
          accepted: true,
          version: null
        });
        user.set('tos', tos);

        try {
          await user.save();
          this.set('acceptedTOS', true);
          this.close();
        } catch (e) {
          Ember.get(this, 'notify').alert(e.error || 'Something wen\'t wrong, please try again');
        }
      }

    }
  });

  _exports.default = _default;
});