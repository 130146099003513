define("pconsole/components/change-plan-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO (carlos h): unused, remove
  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: [''],
    classNameBindings: ['isUnavailable:unavailable'],
    attributeBindings: ['title'],

    click() {
      if (this.get('isUnavailable')) {
        return false;
      }

      this.sendAction('action', this.get('newPlan'));
    },

    isUnavailable: Ember.computed('currentPlan.interval', 'newPlan.interval', function () {
      const currentPlanInterval = this.get('currentPlan.interval');
      const newPlanInterval = this.get('newPlan.interval');

      if (currentPlanInterval === 'year' && newPlanInterval !== 'year') {
        return true;
      } else {
        return false;
      }
    }),
    title: Ember.computed('isUnavailable', function () {
      if (this.get('isUnavailable')) {
        return 'Because you have already committed to an annual plan, you canot switch to a plan that bills monthly';
      }
    })
  });

  _exports.default = _default;
});