define("pconsole/serializers/new-sims", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIntoHash(hash, typeClass, snapshot, options) {
      const json = this.serialize(snapshot, options);
      Ember.merge(hash, json);
    },

    normalizeSaveResponse(store, typeClass, payload, id, requestType) {
      payload.id = 1;
      const normalized = {
        'new_sims': payload
      };
      return this.normalizeSingleResponse(store, typeClass, normalized, id, requestType);
    },

    modelNameFromPayloadKey() {
      return 'new_sims';
    }

  });

  _exports.default = _default;
});