define("pconsole/templates/components/device-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fVIwVxwi",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"device-type-circle \",[18,\"platformClass\"]]]],[7],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"device-type\"],[7],[1,[20,[\"device\",\"platform\",\"name\"]],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/device-type.hbs"
    }
  });

  _exports.default = _default;
});