define("pconsole/templates/components/usage-sparkline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "57s1z4jE",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"id\",[26,[\"usage-sparkline-\",[20,[\"sim\",\"id\"]]]]],[10,\"class\",[26,[\"usage-sparkline \",[25,\"if\",[[20,[\"isLoading\"]],\"is-loading\"],null]]]],[7],[0,\"\\n  \"],[1,[25,\"usage-overlay\",null,[[\"sim\",\"isLoading\"],[[20,[\"sim\"]],[20,[\"isLoading\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[10,\"class\",[26,[\"mb-used table-entry \",[25,\"if\",[[20,[\"isLoading\"]],\"is-loading\"],null]]]],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"callout\"],[7],[1,[25,\"round-to-decimals\",[[20,[\"sim\",\"currentUsage\"]]],null],false],[0,\" MB\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"sim\",\"subscription\",\"currentPeriodStart\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"subtext\"],[7],[0,\"used since \"],[1,[25,\"formatted-date\",[[20,[\"sim\",\"subscription\",\"currentPeriodStart\"]],\"MMM Do\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"subtext\"],[7],[0,\"used this period\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/usage-sparkline.hbs"
    }
  });

  _exports.default = _default;
});