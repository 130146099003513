define("pconsole/controllers/user/integrations/index", ["exports", "pconsole/mixins/object-filter-helpers"], function (_exports, _objectFilterHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_objectFilterHelpers.default, {
    notify: Ember.inject.service(),
    segment: Ember.inject.service(),
    sortProps: ['name:asc'],
    queryParams: ['name', 'event', 'integrationType', 'targetUrl', 'sortAttr', 'sortDir'],
    filterName: undefined,
    filterValue: undefined,
    // Org and product integrations page must update this value
    parentEntity: 'user',
    galleryLink: Ember.computed('parentEntity', function () {
      return "".concat(Ember.get(this, 'parentEntity'), ".integrations.gallery");
    }),
    editLink: Ember.computed('parentEntity', function () {
      return "".concat(Ember.get(this, 'parentEntity'), ".integrations.edit");
    }),
    showLink: Ember.computed('parentEntity', function () {
      return "".concat(Ember.get(this, 'parentEntity'), ".integrations.show");
    }),
    sortParams: Ember.computed('sortProps', function () {
      const [sortValue] = this.get('sortProps');
      let [sortAttr, sortDir] = sortValue.split(':');

      if (sortAttr.includes('todayCounters')) {
        sortAttr = 'todayCounters';
      }

      return {
        attr: sortAttr,
        dir: sortDir
      };
    }),
    filteredIntegrations: Ember.computed('model.integrations', 'filterName', 'filterValue', function () {
      const integrations = this.get('model.integrations');
      let filterName = this.get('filterName');
      const filterValue = this.get('filterValue');

      if (!filterValue) {
        return integrations;
      }

      if (filterName === 'integrationType') {
        filterName = 'integrationType.name';
      }

      if (filterName === 'targetUrl') {
        filterName = 'targetUrl.full';
      }

      const filter = new RegExp(filterValue, 'i');
      return integrations.filter(integration => {
        return filter.test(integration.get(filterName));
      });
    }),
    sortedIntegrations: Ember.computed.sort('filteredIntegrations', 'sortProps'),
    isProduct: Ember.computed('routeData.hasProduct', function () {
      return this.get('routeData.hasProduct');
    }),

    resetParams() {
      this.set('filterName', undefined);
      this.set('filterValue', undefined);
    },

    onExpandRow({
      isExpanded
    }) {
      if (isExpanded) {
        this.get('segment').trackEvent('integrations-list-expanded-row');
      }
    },

    actions: {
      onNameClick() {
        this.get('segment').trackEvent('integrations-list-name-click');
      },

      onDetailsClick() {
        this.get('segment').trackEvent('integrations-list-details-click');
      },

      onEditClick() {
        this.get('segment').trackEvent('integrations-list-edit-click');
      },

      triggerFilter(name, value) {
        if (name) {
          this.get('segment').trackEvent('filtered-integrations-list', {
            filterName: name
          });
        }

        this.set('filterName', name);
        this.set('filterValue', value);
      },

      sortInController(sortAttr, direction) {
        const [prevSort] = this.get('sortProps');
        const [prevAttr] = prevSort.split(':');

        if (sortAttr === 'todayCounters') {
          let finalAttr = sortAttr;
          let finalDir = direction;

          if (!prevAttr.includes('todayCounters') || prevSort === 'todayCounters.success:asc') {
            // First sort of today's traffic or restart the loop
            finalAttr = 'todayCounters.error';
            finalDir = 'desc';
          } else if (finalDir === 'asc') {
            // Second or 4th click
            finalAttr = prevAttr;
          } else {
            finalAttr = 'todayCounters.success';
          }

          this.get('segment').trackEvent('sorted-integrations-list', {
            column: finalAttr
          });
          this.set('sortProps', ["todayCounters:".concat(finalDir), "".concat(finalAttr, ":").concat(finalDir)]);
          return;
        }

        this.get('segment').trackEvent('sorted-integrations-list', {
          column: sortAttr
        });
        this.set('sortProps', ["".concat(sortAttr, ":").concat(direction)]);
      },

      async toggleEnable(integration) {
        const newDisabled = !integration.get('disabled');
        const segment = this.get('segment');

        try {
          integration.set('disabled', newDisabled);
          await integration.save();
          segment.trackEvent("integrations-list-".concat(newDisabled ? 'disabled' : 'enabled', "-integration"));
          this.get('notify').success("Your integration was ".concat(newDisabled ? 'disabled' : 'enabled', " successfully!"));
        } catch (error) {
          const errorMsg = error ? error.detail : "Your integration couldn't be updated. Please try again later.";
          this.get('notify').alert(errorMsg);
        }
      }

    }
  });

  _exports.default = _default;
});