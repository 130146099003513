define("pconsole/routes/product/customers/import", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductCustomersImportRoute = Ember.Route.extend(_modalRoute.default, {
    viewName: 'product/customers/import'
  });
  var _default = ProductCustomersImportRoute;
  _exports.default = _default;
});