define("pconsole/serializers/device-location", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'device_id',

    modelNameFromPayloadKey() {
      return 'device-location';
    },

    payloadKeyFromModelName() {
      return 'location';
    }

  });

  _exports.default = _default;
});