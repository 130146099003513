define("pconsole/helpers/plan-interval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInterval = planInterval;
  _exports.default = void 0;

  function planInterval(interval) {
    const intervalLength = interval[0];

    if (intervalLength === 'month') {
      return 'monthly';
    } else if (intervalLength === 'year') {
      return 'annually';
    }
  }

  var _default = Ember.Helper.helper(planInterval);

  _exports.default = _default;
});