define("pconsole/adapters/organization", ["exports", "pconsole/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'orgs';
    },

    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(snapshot.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(this.buildURL(snapshot.modelName, snapshot.record.get('slug'), snapshot), 'PUT', {
        data: data
      });
    }

  });

  _exports.default = _default;
});