define("pconsole/templates/components/logic-function-status-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bp+QcXgJ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"i\"],[10,\"title\",[18,\"title\"],null],[10,\"class\",[26,[\"icon ion-record \",[25,\"if\",[[20,[\"logicFunction\",\"enabled\"]],\"status-enabled\",\"status-disabled\"],null]]]],[7],[8],[0,\"\\n\"],[1,[18,\"text\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/logic-function-status-indicator.hbs"
    }
  });

  _exports.default = _default;
});