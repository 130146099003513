define("pconsole/templates/partials/icons/lake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OG/4XDn+",
    "block": "{\"symbols\":[],\"statements\":[[6,\"svg\"],[9,\"width\",\"28\"],[9,\"height\",\"28\"],[9,\"viewBox\",\"0 0 18 18\"],[9,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[7],[0,\"\\n  \"],[6,\"g\"],[7],[0,\"\\n    \"],[6,\"path\"],[9,\"d\",\"M3.50006 15.75C3.15631 15.75 2.862 15.6276 2.61713 15.3829C2.37238 15.138 2.25 14.8437 2.25 14.4999V4.76869C2.25 4.62806 2.27188 4.49244 2.31563 4.36181C2.35938 4.23119 2.425 4.11056 2.5125 3.99994L3.47494 2.73131C3.58744 2.58131 3.732 2.46356 3.90863 2.37806C4.08525 2.29269 4.26988 2.25 4.4625 2.25H13.5249C13.7177 2.25 13.9023 2.29269 14.0788 2.37806C14.2554 2.46356 14.4 2.58131 14.5125 2.73131L15.4875 3.99994C15.575 4.11056 15.6406 4.23119 15.6844 4.36181C15.7281 4.49244 15.75 4.62806 15.75 4.76869V14.4999C15.75 14.8437 15.6276 15.138 15.3829 15.3829C15.138 15.6276 14.8437 15.75 14.4999 15.75H3.50006ZM3.81244 4.32506H14.175L13.5073 3.50006H4.47506L3.81244 4.32506ZM8.99775 7.2375C8.82013 7.2375 8.67194 7.29738 8.55319 7.41713C8.43444 7.537 8.37506 7.68544 8.37506 7.86244V10.8L7.32506 9.75C7.21394 9.6375 7.07263 9.58125 6.90112 9.58125C6.7295 9.58125 6.58744 9.63688 6.47494 9.74813C6.36244 9.8595 6.30619 10.0012 6.30619 10.1732C6.30619 10.3452 6.36244 10.4874 6.47494 10.5999L8.56256 12.6876C8.68756 12.8126 8.83337 12.8751 9 12.8751C9.16663 12.8751 9.31244 12.8126 9.43744 12.6876L11.5251 10.5999C11.6376 10.4889 11.6938 10.3477 11.6938 10.1762C11.6938 10.0046 11.6381 9.8625 11.5268 9.75C11.4155 9.6375 11.2739 9.58125 11.1019 9.58125C10.9297 9.58125 10.7874 9.6375 10.6749 9.75L9.62494 10.8V7.86244C9.62494 7.68544 9.56488 7.537 9.44475 7.41713C9.3245 7.29738 9.1755 7.2375 8.99775 7.2375Z\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/icons/lake.hbs"
    }
  });

  _exports.default = _default;
});