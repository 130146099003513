define("pconsole/routes/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OrgRoute = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel(transition) {
      const routeData = this.get('routeData'); // ember-simple-auth validate auth or redirect to login

      this.get('session').requireAuthentication(transition, 'login');
      routeData.assignRouteData(transition);

      this._super(transition);
    },

    model(params) {
      const org_id = params.org_id;
      return Ember.RSVP.hash({
        organization: this.store.findRecord('organization', org_id),
        subscriptions: this.store.findAll('subscription'),
        products: this.store.query('product', {
          organization: org_id
        })
      });
    },

    deactivate() {
      this.store.peekAll('ledgerDefinition').forEach(ld => ld.unloadRecord());
      this.store.peekAll('product').forEach(p => p.unloadRecord());
    }

  });
  var _default = OrgRoute;
  _exports.default = _default;
});