define("pconsole/mixins/edit-integration-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.editIntegrationRoute = void 0;
  const editIntegrationRoute = {
    segment: Ember.inject.service(),
    breadCrumb: {
      title: 'Edit integration',
      linkable: false
    },

    async model(params) {
      const integration = await this.store.findRecord('integration', params.integration_id, {
        reload: true
      });
      const templateSlug = Ember.get(integration, 'template') || 'webhook';
      return Ember.RSVP.hash({
        integration,
        template: this.store.peekRecord('integrationTemplate', templateSlug),
        devices: this.store.findAll('device')
      });
    },

    afterModel(model) {
      const emberRouteName = Ember.get(model.integration, 'integrationType.emberRouteName');
      Ember.set(this, 'controllerName', "user.integrations.".concat(emberRouteName, ".create"));
    },

    setupController(controller, model) {
      this._super(...arguments);

      const integration = model.integration;
      const route = "".concat(Ember.get(this, 'parentEntity'), ".integrations.show");

      if (Ember.get(integration, 'isMultiProduct')) {
        Ember.set(this, 'breadCrumb.title', 'Edit and separate integration');
        controller.set('saveText', 'Save changes and separate integration');
      } else {
        Ember.set(this, 'breadCrumb.title', 'Edit integration');
        controller.set('saveText', 'Save');
      }

      controller.set('integration', integration);
      controller.set('parentEntity', Ember.get(this, 'parentEntity'));
      controller.set('isEditing', true);
      controller.set('displayInstructions', false);

      if (controller.get('hasSimpleForm')) {
        controller.set('displaySimpleForm', true);
      }

      controller.set('cancel', () => {
        integration.rollbackAttributes();
        this.controller.transitionToRoute(route, integration.get('id'));
      });
      Ember.get(this, 'segment').trackEvent('edit integration', {
        tool: {
          name: 'console'
        },
        integration: {
          id: integration.get('id')
        }
      });
    },

    renderTemplate() {
      const emberRouteName = this.currentModel.integration.get('integrationType.emberRouteName');
      this.render("user.integrations.".concat(emberRouteName, ".create"));
    },

    activate: function activate() {
      this._super();

      Ember.$('.dashboard').scrollTop(0);
    },

    deactivate() {
      this.controller.set('saveText', undefined);
      this.controller.set('isEditing', false);
      this.controller.set('displayInstructions', true);
      this.currentModel.integration.rollbackAttributes();
    },

    resetController(controller, isExiting) {
      const initialProducts = Ember.get(controller, 'initialProducts');

      if (isExiting && initialProducts) {
        // Rollback product ids
        this.currentModel.integration.set('productIds', initialProducts);
      }
    }

  };
  _exports.editIntegrationRoute = editIntegrationRoute;
});