define("pconsole/templates/product/clients/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dmXkeLqd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[[25,\"action\",[[19,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title modal-title\"],[7],[0,\"Delete Client\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Are you sure you want to delete the client \"],[6,\"strong\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"? If you are currently using this OAuth client in an application that interacts with the Particle cloud, it will cease to function immediately.\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"button-row\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline primary\"],[3,\"action\",[[19,0,[]],\"closeModal\"]],[7],[0,\"No, cancel\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"deleteClient\"]],[7],[0,\"Yes, delete client\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/product/clients/delete.hbs"
    }
  });

  _exports.default = _default;
});