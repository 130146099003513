define("pconsole/routes/product/devices/edit-group", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model() {
      return Ember.RSVP.hash({
        groups: this.store.findAll('group')
      });
    },

    activate() {
      const editGroupController = this.controllerFor('product.devices.editGroup');
      editGroupController.set('editGroupRouteActive', true);
    },

    deactivate() {
      const editGroupController = this.controllerFor('product.devices.editGroup');
      editGroupController.set('editGroupRouteActive', false);
      this.controller.set('editableGroup', null);
    },

    actions: {
      willTransition: function willTransition() {
        const group = this.controller.get('editableGroup');

        if (group && group.get('hasDirtyAttributes')) {
          if (group.get('id')) {
            group.rollbackAttributes();
          } else {
            group.deleteRecord();
          }
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});