define("pconsole/mixins/routes/integration-index-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationIndexRoute = void 0;
  const integrationIndexRoute = {
    templateName: 'user.integrations.index',
    controllerName: 'user.integrations.index',

    model() {
      return this.modelFor("".concat(Ember.get(this, 'parentEntity'), ".integrations"));
    },

    afterModel(model, transition) {
      const size = Ember.get(model, 'integrations.length');

      if (size === 0) {
        transition.router.transitionTo("".concat(Ember.get(this, 'parentEntity'), ".integrations.gallery"));
      }
    },

    setupController(controller, model) {
      controller.setProperties({
        model,
        parentEntity: Ember.get(this, 'parentEntity')
      });
    },

    resetController(controller, isExiting) {
      const integrationController = this.controllerFor('user.integrations.index');

      if (isExiting) {
        integrationController.resetParams();
      }
    }

  };
  _exports.integrationIndexRoute = integrationIndexRoute;
});