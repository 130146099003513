define("pconsole/components/integration-counters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['webhook-counters', 'webhook-info-container'],
    activeTab: 'logs',
    timeZone: 'local',
    expandedLogs: false,
    actions: {
      setActiveTab(tab) {
        this.set('activeTab', tab);
      },

      setTimeZone(tz) {
        this.set('timeZone', tz);
      },

      toggleLogs() {
        this.toggleProperty('expandedLogs');
      },

      async refreshIntegration() {
        if (this.get('refreshing')) {
          return;
        }

        this.set('refreshing', true);

        try {
          await this.get('model').reload();
        } finally {
          this.set('refreshing', false);
        }
      }

    }
  });

  _exports.default = _default;
});