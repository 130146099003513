define("pconsole/controllers/product/sims/deactivate", ["exports", "pconsole/mixins/sim/deactivate"], function (_exports, _deactivate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_deactivate.default, {
    sim: Ember.computed.alias('model.sim')
  });

  _exports.default = _default;
});