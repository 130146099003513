define("pconsole/components/integration-checklist-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['expanded:is-expanded'],
    expanded: false,
    actions: {
      toggleExpandedContent() {
        this.toggleProperty('expanded');
      }

    }
  });

  _exports.default = _default;
});