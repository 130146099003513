define("pconsole/routes/user/ledger/show/instance", ["exports", "pconsole/mixins/routes/ledger/show/instance"], function (_exports, _instance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_instance.LedgerInstanceRoute);

  _exports.default = _default;
});