define("pconsole/routes/product/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductEventsRoute = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        product: this.modelFor('product').product
      });
    },

    setupController(controller, model) {
      controller.set('fullProduct', model.product);
    }

  });
  var _default = ProductEventsRoute;
  _exports.default = _default;
});