define("pconsole/controllers/product/sims/release", ["exports", "pconsole/mixins/sim/release"], function (_exports, _release) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_release.default, {});

  _exports.default = _default;
});