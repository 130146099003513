define("pconsole/lib/updated-point-image", ["exports", "pconsole/lib/average-rgb-colors"], function (_exports, _averageRgbColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const duration = 1000;
  /**
   * Renders a "ping" animation of the given size and color
   * @see https://docs.mapbox.com/mapbox-gl-js/api/#styleimageinterface
   */

  class UpdatedPointImage {
    constructor(size, color) {
      this.data = new Uint8Array(size * size * 4);
      this.height = size;
      this.size = size;
      this.width = size;
      this.color = color;
    }

    onAdd(map
    /*, gl*/
    ) {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
      this.map = map;
    }

    render() {
      const now = performance.now();

      if (this.start === undefined) {
        this.start = now;
      }

      const elapsed = Math.min(now - this.start, duration);
      const t = elapsed % duration / duration;
      const radius = this.size / 2 * 0.3;
      const outerRadius = this.size / 2 * 0.7 * t + radius;
      const context = this.context; // Draw outer circle

      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      const [r, g, b] = (0, _averageRgbColors.toBase16Array)(this.color);
      context.fillStyle = "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(1 - t, ")");
      context.fill(); // Draw inner circle

      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = this.color;
      context.strokeStyle = 'white';
      context.lineWidth = 2;
      context.fill();
      context.stroke(); // Update this image's data with data from the canvas

      this.data = context.getImageData(0, 0, this.width, this.height).data; // Continuously repaint the map, resulting in the smooth animation of the dot

      this.map.triggerRepaint(); // Return `true` to let the map know that the image was updated

      return true;
    }

  }

  _exports.default = UpdatedPointImage;
});