define("pconsole/components/logic-product-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['logic-product-names'],
    store: Ember.inject.service(),
    details: Ember.computed('logicFunction', function () {
      var _triggers$;

      let text = '';
      const triggers = Ember.get(this, 'triggers').toArray();
      const productIds = triggers.mapBy('productId').uniq().removeObject(undefined).map(id => id.toString());
      const isEvent = (_triggers$ = triggers[0]) === null || _triggers$ === void 0 ? void 0 : _triggers$.get('isEvent');
      const affectedProducts = [];

      if (productIds.length === 0 && !isEvent) {
        text = 'Global';
      } else if (productIds.length === 1) {
        const product = Ember.get(this, 'store').peekRecord('product', productIds[0]);
        text = (product === null || product === void 0 ? void 0 : product.get('name')) || productIds[0];
      } else {
        affectedProducts.pushObjects(productIds.map(id => {
          var _EmberGet$peekRecord;

          return ((_EmberGet$peekRecord = Ember.get(this, 'store').peekRecord('product', id)) === null || _EmberGet$peekRecord === void 0 ? void 0 : _EmberGet$peekRecord.get('name')) || '';
        }));
        text = isEvent ? "".concat(productIds.length, " products") : 'Global';
      }

      return {
        text,
        showProductIcon: productIds.length > 1,
        affectedProducts: affectedProducts.length > 2 ? [...affectedProducts.slice(0, 2), '...'] : affectedProducts
      };
    })
  });

  _exports.default = _default;
});