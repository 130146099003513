define("pconsole/models/ledger-definition", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LedgerDefinitionValidations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Ledger name',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 32
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-z0-9-_]*$/,
        message: 'Only lower-case alphanumeric characters allowed'
      }), (0, _emberCpValidations.validator)('unique-ledger-name')]
    },
    scope: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Scope'
    }),
    direction: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Direction'
    })
  });
  const DISPLAY_DIRECTIONS = {
    CloudOnly: 'Cloud only',
    CloudToDevice: 'Cloud to Device',
    DeviceToCloud: 'Device to Cloud'
  };

  var _default = _emberData.default.Model.extend(LedgerDefinitionValidations, {
    scope: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    direction: _emberData.default.attr('string'),
    instances: _emberData.default.hasMany('ledgerInstance', {
      async: true
    }),
    stats: _emberData.default.attr(''),
    updatedAt: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    displayDirection: Ember.computed('direction', {
      get() {
        return DISPLAY_DIRECTIONS[Ember.get(this, 'direction')];
      }

    }),
    isOwnerScope: Ember.computed.equal('scope', 'Owner'),
    isDeviceToCloud: Ember.computed.equal('direction', 'DeviceToCloud')
  });

  _exports.default = _default;
});