define("pconsole/session-stores/application", ["exports", "pconsole/config/environment", "ember-simple-auth/session-stores/cookie"], function (_exports, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP
  } = Ember;

  var _default = _cookie.default.extend({
    cookieDomain: _environment.default.APP.COOKIE_DOMAIN,
    cookieName: 'ember_simple_auth_session',

    restore() {
      const allCookies = document.cookie.split('; ').map(x => decodeURIComponent(x).split('='));
      const sessionCookies = allCookies.filter(c => {
        return c[0] === this.cookieName;
      });
      let data;
      sessionCookies.forEach(cookie => {
        const cookieData = JSON.parse(cookie[1]);

        if (this.isValidCookie(cookieData)) {
          data = cookieData;
        }
      });

      if (Ember.isEmpty(data)) {
        return RSVP.resolve({});
      } else {
        if (data.access_token && !data.authenticated) {
          data.authenticator = 'authenticator:oauth2-n';
          const newData = {
            authenticated: data
          };

          this._write(JSON.stringify(newData));

          return RSVP.resolve(newData);
        }

        return RSVP.resolve(data);
      }
    },

    isValidCookie(cookie) {
      const userInfo = cookie.authenticated;
      return userInfo && userInfo.username && this.isValidDomain(cookie);
    },

    isValidDomain(cookie) {
      if (this.cookieDomain === '.particle.io' || !_environment.default.APP.USE_SINGLE_SIGN_ON) {
        // Only check domain on non-prod environments
        return true;
      } else {
        return cookie.domain === this.cookieDomain;
      }
    }

  });

  _exports.default = _default;
});