define("pconsole/components/grid-column", ["exports", "prop-types", "styled-components"], function (_exports, _propTypes, _styledComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Column = (0, _styledComponents.default)('div').withConfig({
    // Pass the `width` prop on
    shouldForwardProp: (prop, defaultValidatorFn) => !['width'].includes(prop) && defaultValidatorFn(prop)
  }).withConfig({
    displayName: "grid-column__Column",
    componentId: "qobcno-0"
  })(["grid-column:auto / span ", ";"], props => props.width);
  Column.propTypes = {
    width: _propTypes.default.string
  };
  Column.defaultProps = {
    width: '1'
  };
  var _default = Column;
  _exports.default = _default;
});