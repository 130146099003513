define("pconsole/components/x-times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const xTimesComponent = Ember.Component.extend({
    times: 0,
    timesArray: Ember.computed('times', function () {
      const times = this.get('times');
      const timesArray = [];

      for (let i = 0; i < times; i++) {
        timesArray.push(i);
      }

      return timesArray;
    })
  });
  xTimesComponent.reopenClass({
    positionalParams: ['times']
  });
  var _default = xTimesComponent;
  _exports.default = _default;
});