define("pconsole/routes/user/products/new", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model() {
      return Ember.RSVP.hash({
        product: this.store.createRecord('product', {
          settings: {
            location: {}
          }
        }),
        platforms: this.store.findAll('platform')
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'platformId', null);
        Ember.get(controller, 'product').rollbackAttributes();
      }
    },

    setupController(controller) {
      const {
        user
      } = this.modelFor('user');
      controller.set('user', user);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});