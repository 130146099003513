define("pconsole/components/write-only-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Custom input-wrapper that adds functionality to a basic <input />
  // It must receive a block content that has an HTML input on it
  var _default = Ember.Component.extend({
    classNames: ['write-only-input'],
    isEditing: false,
    actions: {
      toggleIsEditing() {
        if (Ember.get(this, 'isEditing')) {
          this.sendAction('clearEditing'); // So the parent can clear the value
        }

        this.toggleProperty('isEditing');
      }

    }
  });

  _exports.default = _default;
});