define("pconsole/adapters/network-type", ["exports", "ember-data-fixture-adapter"], function (_exports, _emberDataFixtureAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataFixtureAdapter.default.extend({
    shouldReloadAll() {
      return true;
    },

    shouldBackgroundReloadRecord() {
      return false;
    }

  });

  _exports.default = _default;
});