define("pconsole/routes/user/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserEventsRoute = Ember.Route.extend({
    templateName: 'product/events',
    controllerName: 'product/events'
  });
  var _default = UserEventsRoute;
  _exports.default = _default;
});