define("pconsole/routes/organization/ledger/show/delete-instance", ["exports", "pconsole/mixins/routes/ledger/show/delete-instance", "pconsole/mixins/modal-route"], function (_exports, _deleteInstance, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, _deleteInstance.DeleteLedgerInstanceRoute);

  _exports.default = _default;
});