define("pconsole/routes/product/logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProductLogsRoute = Ember.Route.extend({
    beforeModel() {
      this.transitionTo('product.events');
    }

  });
  var _default = ProductLogsRoute;
  _exports.default = _default;
});