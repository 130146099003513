define("pconsole/components/eventlogs/input.react", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Input = ({
    inputValue,
    onInputChange,
    onBlur,
    onKeyPress,
    isFiltering
  }) => {
    const inputProps = {
      id: 'input',
      value: inputValue,
      type: 'text',
      placeholder: 'Search for events',
      onChange: onInputChange,
      onBlur: onBlur,
      onKeyPress: onKeyPress
    };
    const loader = isFiltering ? _react.default.createElement('div', {
      className: '_eventlogsnew__content__input__inputcontainer__loader'
    }, _react.default.createElement('i', {
      className: 'ion-loading-c'
    })) : null;
    return _react.default.createElement('div', {
      className: '_eventlogsnew__content__input__inputcontainer'
    }, _react.default.createElement('input', inputProps), loader);
  };

  var _default = Input;
  _exports.default = _default;
});