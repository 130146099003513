define("pconsole/routes/application", ["exports", "pconsole/config/environment", "@sentry/apm", "@sentry/ember", "@sentry/browser"], function (_exports, _environment, _apm, _ember, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ApplicationRoute = Ember.Route.extend({
    apiAjax: Ember.inject.service(),
    session: Ember.inject.service(),
    segment: Ember.inject.service(),

    loadSentry() {
      const {
        sentry
      } = _environment.default['@sentry/ember'];
      const isSentryEnabled = // When not in dev or test environments
      !['test', 'development'].includes(_environment.default.environment);

      const config = _objectSpread({}, sentry, {
        beforeSend(event, hint) {
          var _originalException$er;

          const {
            originalException
          } = hint;

          if (!originalException) {
            return event; // TODO (carlos h): Clean once we get more info on errors with no original exeption
          } // Don't report particle-api-js errors that have a normal status code


          if ((originalException === null || originalException === void 0 ? void 0 : originalException.statusCode) < 500) {
            return null;
          } // Don't report adapter errors that have a normal status code


          if (originalException.isAdapterError && (originalException === null || originalException === void 0 ? void 0 : (_originalException$er = originalException.errors[0]) === null || _originalException$er === void 0 ? void 0 : _originalException$er.status) < 500) {
            return null;
          } // Don't report jquery ajax errors that have a normal status code


          if ((originalException === null || originalException === void 0 ? void 0 : originalException.status) < 500) {
            return null;
          }

          return event;
        },

        integrations: [new _apm.Integrations.Tracing()]
      });

      if (isSentryEnabled) {
        (0, _ember.InitSentryForEmber)(config);
      }
    },

    async beforeModel(transition) {
      const routeData = this.get('routeData');
      const session = this.get('session');
      routeData.assignRouteData(transition);

      this._super(transition);

      this.loadSentry();

      session.handleAuthentication = () => this.sessionAuthenticated();

      session.handleInvalidation = () => this.sessionInvalidated();

      if (session.get('isAuthenticated')) {
        const accessToken = session.get('data.authenticated.access_token'); // Set the Access token on the event stream object

        const eventStreamer = this.get('eventStream');
        eventStreamer.setAccessToken(accessToken);
        const username = this.get('session.data.authenticated.username');
        Sentry.configureScope(function (scope) {
          scope.setUser({
            email: username
          });
        });
      }
    },

    // Segment hook; called on page changes
    identifyUser() {
      const session = Ember.get(this, 'session');
      const segment = Ember.get(this, 'segment'); // Segment recommends calling identify after loading any pages that are
      // accessible by a logged in user; see
      // https://segment.com/docs/connections/spec/best-practices-identify/#when-to-call-identify

      if (Ember.get(session, 'isAuthenticated')) {
        const username = Ember.get(this, 'session.data.authenticated.username');

        if (username && Ember.get(this, 'segmentUsername') !== username) {
          segment.identifyUser(username, {
            email: username
          }, undefined);
          Ember.set(this, 'segmentUsername', username);
        }
      }
    },

    async model() {
      const session = this.get('session');
      const accessToken = session.get('data.authenticated.access_token');

      if (!accessToken) {
        return {};
      }

      const organizations = await this.store.findAll('organization');
      const user = this.store.findRecord('user', Ember.get(session, 'data.authenticated.username')); // query user service agreements
      // for each org, query org service agreements

      await Ember.RSVP.allSettled([this.store.query('service-agreement', {}), ...organizations.map(org => this.store.query('service-agreement', {
        org: org.get('slug')
      }))]); // fetch notifications for all service agreements
      // use allSettled to ignore request failures when the user doesn't have permissions to view notifications for this service agreement

      await Ember.RSVP.allSettled(this.store.peekAll('service-agreement').map(sa => {
        return this.store.query('notification', {
          serviceAgreementId: sa.get('id'),
          org: sa.get('organizationId')
        });
      }));
      return Ember.RSVP.hash({
        organizations,
        user,
        serviceAgreements: this.store.peekAll('service-agreement')
      });
    },

    afterModel(model) {
      const memberships = Ember.get(model, 'user.memberships') || [];
      const invites = memberships.filterBy('invited', true);

      if (invites && invites.length) {
        // After responding to the invite, return to the intended route
        // transitionTo with queryParams fails when done at the app's top level
        // https://github.com/emberjs/ember.js/issues/17118
        // const redirectTo = get(transition.handlerInfos, 'lastObject.name');
        return this.transitionTo('user.teamInvite', invites[0].id);
      }

      this.goToHome(model);
    },

    goToHome(model) {
      const isAuthenticated = Ember.get(this, 'session.isAuthenticated');
      const org = Ember.get(model, 'organizations.firstObject'); // Can't use router.currentPath since it's not set by Ember at this point

      const isBaseRoute = Ember.get(this, 'router.location.location.pathname') === '/';

      if (isAuthenticated && org && isBaseRoute) {
        this.transitionTo('organization.index', Ember.get(org, 'slug'));
      }
    },

    sessionAuthenticated() {
      Ember.$('.loading').hide(); // Get the access token from the session

      const session = this.get('session');
      const accessToken = session.get('data.authenticated.access_token'); // Set the Access token on the event stream object

      const eventStreamer = this.get('eventStream');
      eventStreamer.setAccessToken(accessToken);
      const attemptedTransition = this.get('session.attemptedTransition');

      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else {
        this.goToHome(this.modelFor('application'));
      }
    },

    sessionInvalidated() {
      Sentry.configureScope(function (scope) {
        scope.setUser(null);
      });
      Ember.get(this, 'segment').reset();
      this.store.unloadAll();
      this.transitionTo('login');
    },

    actions: {
      error(error) {
        Ember.$('.loading').hide();
        Sentry.captureException(error);
        return true;
      },

      showModal(name, content) {
        this.controllerFor(name).set('content', content);
        this.render(name, {
          into: 'application',
          outlet: 'modal'
        });
      },

      removeModal() {
        Ember.$('.modal').modal('hide');
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },

      willTransition(transition) {
        const routeData = this.get('routeData');
        routeData.assignRouteData(transition);
        this.send('removeModal');
      }

    }
  });
  var _default = ApplicationRoute;
  _exports.default = _default;
});