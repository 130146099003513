define("pconsole/components/asset-tracker-map/circles-layer", ["exports", "react", "prop-types", "@turf/meta", "@turf/distance", "react-mapbox-gl"], function (_exports, _react, _propTypes, _meta, _distance, _reactMapboxGl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CirclesLayer = ({
    devices,
    id,
    sourceId,
    highlightedDevice = '',
    activeDevice,
    noAccuracy = false,
    onDeviceClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {}
  }) => {
    const map = (0, _react.useContext)(_reactMapboxGl.MapContext);
    const [hoveredFeatureId, setHoveredFeatureId] = (0, _react.useState)(null); // TODO (@mossymaker): Something in react-mapbox-gl's Layer component prevents
    // the mouseleave event from being emitted after a mapmove event; work around
    // that for now by adding event handlers to the map directly

    (0, _react.useEffect)(function () {
      function mouseEnter(e) {
        const {
          lngLat: {
            lng,
            lat
          }
        } = e;
        const feature = e.features[0];
        const device = JSON.parse(feature.properties.device);
        const isHistoric = device.geometry.type === 'LineString';
        let hoveredFeatureIndex = 0;

        if (hoveredFeatureId !== null) {
          map.removeFeatureState({
            id: hoveredFeatureId,
            source: sourceId
          }, 'hover');
        }

        if (feature.geometry.type === 'Point') {
          setHoveredFeatureId(feature.properties.id);
          map.setFeatureState({
            id: feature.properties.id,
            source: sourceId
          }, {
            hover: true
          });
        }

        if (['MultiLineString', 'LineString'].includes(feature.geometry.type)) {
          // find closest point to the geometry
          let minDistance = Infinity;
          (0, _meta.coordEach)(isHistoric ? device.foldedGeometry : device.geometry, (point, index) => {
            const distanceFromPoint = (0, _distance.default)([lng, lat], point);

            if (distanceFromPoint < minDistance) {
              hoveredFeatureIndex = index;
              minDistance = distanceFromPoint;
            }
          });
        }

        onMouseEnter(device, hoveredFeatureIndex);
      }

      map.on('mouseenter', id, mouseEnter);
      return function () {
        map.off('mouseenter', mouseEnter);
      };
    }, []);
    (0, _react.useEffect)(function () {
      function mouseLeave() {
        if (hoveredFeatureId !== null) {
          map.removeFeatureState({
            id: hoveredFeatureId,
            source: sourceId
          }, 'hover');
          setHoveredFeatureId(null);
        }

        onMouseLeave();
      }

      map.on('mouseleave', id, mouseLeave);
      return function () {
        map.off('mouseleave', mouseLeave);
      };
    }, [hoveredFeatureId, map]); // Does not support CSS variables colors added manually

    const paintOpts = {
      'circle-color': ['case', ['boolean', ['get', 'isMuted'], false], '#24292f', // Quaternary background color
      ['has', 'color'], ['get', 'color'], '#00d2e6' // Cyan color
      ],
      'circle-radius': ['case', ['==', ['get', 'id'], highlightedDevice], 12, ['boolean', ['feature-state', 'hover'], false], 12, 8],
      'circle-stroke-color': '#565a65',
      // primary border color
      'circle-stroke-width': 2
    };
    const filter = ['!has', 'point_count'];
    const accuracyLayer = {
      id: "".concat(id, "-accuracyLayer"),
      type: 'fill',
      sourceId: 'accuracy',
      paint: {
        'fill-opacity': 0.4,
        'fill-color': '#131517' // Primary background color

      }
    };

    const onClick = ev => onDeviceClick(ev.features[0].properties.id);

    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_reactMapboxGl.Layer, {
      id: id,
      type: 'circle',
      sourceId: sourceId,
      filter: filter,
      paint: paintOpts,
      onClick: onClick
    }), !noAccuracy && _react.default.createElement(_reactMapboxGl.Layer, accuracyLayer));
  };

  CirclesLayer.propTypes = {
    devices: _propTypes.default.array.isRequired,
    id: _propTypes.default.string.isRequired,
    sourceId: _propTypes.default.string.isRequired,
    onDeviceClick: _propTypes.default.func,
    onMouseEnter: _propTypes.default.func,
    onMouseLeave: _propTypes.default.func
  };
  var _default = CirclesLayer;
  _exports.default = _default;
});