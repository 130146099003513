define("pconsole/templates/components/integration-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aIdKZBza",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"test-integration webhook-action ui button outline primary\"],[3,\"action\",[[19,0,[]],\"openTestModal\"]],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"ion-ios7-pulse-strong\"],[7],[8],[6,\"span\"],[9,\"class\",\"test\"],[7],[0,\"test\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"integration\",\"isMultiProduct\"]]],null,{\"statements\":[[4,\"link-to\",[[20,[\"editPath\"]],[20,[\"integration\",\"id\"]]],[[\"class\",\"scope\"],[\"ui button outline primary webhook-action\",\"integrations:update\"]],{\"statements\":[[0,\"        \"],[6,\"i\"],[9,\"class\",\"ion-edit\"],[7],[8],[6,\"span\"],[7],[0,\"Edit\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[[20,[\"deletePath\"]],[20,[\"integration\",\"id\"]]],[[\"class\",\"scope\"],[\"ui button filled primary danger webhook-action\",\"integrations:remove\"]],{\"statements\":[[0,\"      \"],[6,\"i\"],[9,\"class\",\"ion-trash-b\"],[7],[8],[6,\"span\"],[7],[0,\"Delete\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"showTest\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"test-integration\",null,[[\"integration\",\"close\"],[[20,[\"integration\"]],[25,\"action\",[[19,0,[]],\"closeModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/integration-actions.hbs"
    }
  });

  _exports.default = _default;
});