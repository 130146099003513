define("pconsole/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const User = _emberData.default.Model.extend({
    cellularDeviceCount: _emberData.default.attr('number'),
    // If this is true, the user has a failing credit card
    delinquent: _emberData.default.attr('boolean'),
    planVersion: _emberData.default.attr('string'),
    subIds: _emberData.default.attr(''),
    username: _emberData.default.attr('string'),
    wifiDeviceCount: _emberData.default.attr('number'),
    card: _emberData.default.belongsTo('card'),
    devices: _emberData.default.hasMany('device', {
      async: false
    }),
    memberships: _emberData.default.hasMany('membership', {
      async: false
    }),
    organization: _emberData.default.hasMany('organization'),
    serviceAgreements: _emberData.default.hasMany(),
    sims: _emberData.default.hasMany('sim', {
      async: false
    }),
    subscriptions: _emberData.default.hasMany('subscription'),
    tos: _emberData.default.belongsTo('tos', {
      async: false
    })
  });

  var _default = User;
  _exports.default = _default;
});