define("pconsole/routes/product/devices/delete-group", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    model() {
      return Ember.RSVP.hash({
        groups: this.store.findAll('group')
      });
    },

    deactivate() {
      this.controller.set('chosenGroup', null);
    }

  });

  _exports.default = _default;
});