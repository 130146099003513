define("pconsole/templates/components/enterprise-usage-threshold-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oh7XKlHO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h3\"],[9,\"class\",\"modal-title title\"],[7],[0,\"\\n  You have used \"],[1,[20,[\"alert\",\"usagePercent\"]],false],[0,\" of your contracted \"],[1,[20,[\"alert\",\"thresholdCategory\"]],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"alert\",\"isOver100Percent\"]]],null,{\"statements\":[[0,\"  \"],[6,\"p\"],[7],[0,\"\\n    You have used \"],[1,[20,[\"alert\",\"usagePercent\"]],false],[0,\" of your contracted \"],[1,[20,[\"alert\",\"thresholdCategory\"]],false],[0,\" allowance\"],[4,\"if\",[[20,[\"organizationName\"]]],null,{\"statements\":[[0,\" for \"],[6,\"b\"],[7],[1,[18,\"organizationName\"],false],[8]],\"parameters\":[]},null],[0,\".\\n    There will be no interruption in service, however,\\n    \"],[6,\"b\"],[7],[0,\"a migration to a higher Tier or Package is required. Please contact your Account Manager immediately for more details.\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"p\"],[7],[0,\"\\n    You have used \"],[1,[20,[\"alert\",\"usagePercent\"]],false],[0,\" of your \"],[1,[20,[\"alert\",\"thresholdCategory\"]],false],[0,\" allowance\"],[4,\"if\",[[20,[\"organizationName\"]]],null,{\"statements\":[[0,\" for \"],[6,\"b\"],[7],[1,[18,\"organizationName\"],false],[8]],\"parameters\":[]},null],[0,\".\\n    There will be no interruption in service, however, should you exceed your allowance during this contract period, a migration to a higher\\n    Tier or Package will be required.\\n    Please contact your Account Manager for more details.\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[4,\"link-to\",[\"organization.billing\",[20,[\"organizationSlug\"]]],[[\"class\"],[\"ui fluid primary button\"]],{\"statements\":[[0,\"Go to billing page\"]],\"parameters\":[]},null],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/enterprise-usage-threshold-alert.hbs"
    }
  });

  _exports.default = _default;
});