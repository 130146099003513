define("pconsole/routes/product/device/enable-force-update", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    breadCrumb: null,
    templateName: 'product/devices/enable-force-update',
    controllerName: 'product/devices/enable-force-update',

    model() {
      return this.modelFor('product.device').device;
    }

  });

  _exports.default = _default;
});