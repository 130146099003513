define("pconsole/controllers/product/firmware/edit", ["exports", "ember-easy-form-extensions/mixins/controllers/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _form.default, {
    notify: Ember.inject.service(),
    validations: {
      'model.title': {
        presence: {
          message: 'is required for your firmware binary'
        }
      }
    },
    disableDeleteButton: Ember.computed.bool('model.firstReleasedAt'),
    deleting: false,
    actions: {
      closeModal() {
        this.transitionToRoute('product.firmware');
        Ember.set(this, 'deleting', false);
      },

      returnToEdit() {
        Ember.set(this, 'deleting', false);
      },

      async doDelete() {
        try {
          await this.model.destroyRecord();
          this.model.unloadRecord();
          this.transitionToRoute('product.firmware'); // workaround to allow re-uploading same firmware version without reloading Ember
          // https://github.com/emberjs/data/issues/5014#issuecomment-310860397
          // this.store._removeFromIdMap(this.model._internalModel);

          this.get('notify').success('Your firmware version has been deleted.');
        } catch (_unused) {
          this.get('notify').alert('Your firmware could not be deleted.');
        }
      }

    },

    save() {
      return this.model.save().then(() => {
        this.get('notify').success('Your firmware version has been saved successfully!');
        this.transitionToRoute('product.firmware');
        this.send('refreshModel');
      }, () => {
        this.get('notify').alert('Your firmware could not be created. Try again.');
      });
    },

    delete() {
      Ember.set(this, 'deleting', true);
    }

  });

  _exports.default = _default;
});