define("pconsole/services/usage-alerts", ["exports", "pconsole/utils/platforms"], function (_exports, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UsageAlert = _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const THRESHOLD_CATEGORY_MAP = {
    max_messages: 'Data Operations',
    max_data: 'Wireless Data',
    max_devices: 'Devices'
  };
  const ENTERPRISE_THRESHOLD_CATEGORY_MAP = {
    max_messages: 'Data Operations',
    max_data: 'Cellular data usage'
  };
  /**
   * Represents a usage alert for one service agreement that will be
   * displayed through the usage-alert component
   */

  const UsageAlert = Ember.Object.extend({
    /**
     * Reference to the usage alerts service
     * @type {UsageAlerts}
     */
    usageAlerts: null,

    /**
     * Service agreement
     * @type {ServiceAgreement}
     */
    serviceAgreement: null,

    /**
     * The component that usage-alert will use for display
     * @type {string}
     */
    componentName: '',
    eventName: '',
    usagePercent: 0,
    nextBillingPeriodStart: null,
    devicePauseDate: null,
    isOver100Percent: false,
    thresholdCategory: '',
    connectivityType: '',

    /*
     * The alerts shown to a Console user are stored for each service agreement
     * in a JSON object where each key is a notification event type.
     */
    cookieName() {
      const serviceAgreementId = Ember.get(this, 'serviceAgreement.id');
      return "alerts-shown-".concat(serviceAgreementId);
    },

    cookieValue() {
      try {
        return JSON.parse($.cookie(this.cookieName()));
      } catch (e) {
        return {};
      }
    },

    hasShown() {
      const eventName = Ember.get(this, 'eventName');
      return this.cookieValue()[eventName];
    },

    markShown() {
      const eventName = Ember.get(this, 'eventName');
      const expires = Ember.get(this, 'serviceAgreement.nextBillingPeriodStart');
      $.cookie(this.cookieName(), JSON.stringify(_objectSpread({}, this.cookieValue(), {
        [eventName]: true
      })), {
        path: '/',
        expires
      }); // Recalculate the usage alerts to show the next popup

      Ember.get(this, 'usageAlerts').refresh();
    }

  });
  _exports.UsageAlert = UsageAlert;

  var _default = Ember.Service.extend({
    /**
     * Record array of service agreements
     * @type {ServiceAgreement[]}
     */
    serviceAgreements: null,

    /**
     * List of usage limit objects
     * @type {Object[]}
     */
    usageLimits: null,
    store: Ember.inject.service(),

    /**
     * Evaluates usage for each service agreement against the list of usage limits
     * and returns a list of usage alerts
     *
     * @type {UsageAlert[]}
     */
    allAlerts: Ember.computed('serviceAgreements', 'usageLimits', function () {
      const serviceAgreements = Ember.get(this, 'serviceAgreements');
      const usageLimits = Ember.get(this, 'usageLimits');
      const usageAlerts = [];
      serviceAgreements.forEach(sa => {
        // get the most important notification (lowest priority number) for the service agreement
        const notifications = Ember.get(sa, 'notifications').filterBy('details.hierarchy_info.priority').sortBy('details.hierarchy_info.priority', 'createdAt');
        let notification = notifications[0]; // Special case for when service agreement is paused but there's no notification
        // This might happen on subsequent months of manually pausing a Growth account

        if (!notification && Ember.get(sa, 'devicesPaused')) {
          notification = {
            eventName: 'persistent_service_paused',
            details: {}
          };
        }

        if (notification) {
          // Find the usage limit that matches this notification
          usageLimits.forEach(limit => {
            if (limit.eventNamePattern.exec(Ember.get(notification, 'eventName'))) {
              usageAlerts.push(this.createAlert({
                serviceAgreement: sa,
                notification,
                usageAlerts: this,
                componentName: limit.componentName
              }));
            }
          });
        }
      });
      return usageAlerts;
    }),

    /**
     * Create an alert object that will be rendered by the usage-alert
     * component. It contains a reference to the service agreement and all
     * the details needed by the template
     */
    createAlert({
      serviceAgreement,
      notification,
      usageAlerts,
      componentName
    }) {
      const eventName = Ember.get(notification, 'eventName');
      const details = Ember.get(notification, 'details');
      const agreementType = Ember.get(serviceAgreement, 'agreementType');
      const map = agreementType === 'enterprise' ? ENTERPRISE_THRESHOLD_CATEGORY_MAP : THRESHOLD_CATEGORY_MAP;
      return UsageAlert.create({
        usageAlerts,
        componentName,
        serviceAgreement,
        eventName,
        nextBillingPeriodStart: details['next_billing_period_start'],
        devicePauseDate: details['device_pause_date'],
        usagePercent: details['percent_usage'],
        isOver100Percent: parseInt(details['percent_usage'], 10) >= 100,
        thresholdCategory: map[details['threshold_category']],
        connectivityType: _platforms.connectivityTypeNameMap[details['connectivity_type']]
      });
    },

    /**
     * Alerts not yet shown
     * @type {UsageAlert[]}
     */
    visibleAlerts: Ember.computed('allAlerts', function () {
      return Ember.get(this, 'allAlerts').filter(alert => !alert.hasShown());
    }),

    /**
     * The first alert will be shown to the user.
     *
     * @type {UsageAlert}
     */
    alert: Ember.computed.readOnly('visibleAlerts.firstObject'),

    init() {
      this._super(...arguments);

      const serviceAgreements = Ember.get(this, 'store').peekAll('service-agreement');
      Ember.set(this, 'serviceAgreements', serviceAgreements);
      Ember.set(this, 'usageLimits', [// Sandbox data operations and cellular data
      {
        componentName: 'sandbox-usage-threshold-alert',
        eventNamePattern: /developer:usage_activity:max_messages|developer:usage_activity:max_data/
      }, // Sandbox service paused
      {
        componentName: 'sandbox-paused-alert',
        eventNamePattern: /developer:usage_activity:automatic_pause/
      }, // Sandbox service unpaused
      {
        componentName: 'sandbox-unpaused-alert',
        eventNamePattern: /developer:usage_activity:automatic_unpause/
      }, // Manual pausing (all types)
      {
        componentName: 'manual-paused-alert',
        eventNamePattern: /usage_activity:manual_pause/
      }, // Manual unpausing (all types)
      {
        componentName: 'manual-unpaused-alert',
        eventNamePattern: /usage_activity:manual_unpause/
      }, // Self service data operations and cellular data
      {
        componentName: 'self-service-usage-threshold-alert',
        eventNamePattern: /self_service:usage_activity:.*:max_messages|self_service:usage_activity:.*:max_data/
      }, // Self service devices
      {
        componentName: 'self-service-device-threshold-alert',
        eventNamePattern: /self_service:usage_activity:.*:max_devices/
      }, // Enterprise devices
      {
        componentName: 'enterprise-device-threshold-alert',
        eventNamePattern: /enterprise:usage_activity:.*:max_devices/
      }, // Enterprise data operations and cellular data
      {
        componentName: 'enterprise-usage-threshold-alert',
        eventNamePattern: /enterprise:usage_activity:.*:max_messages|enterprise:usage_activity:.*:max_data/
      }, // Paused without a notification
      {
        componentName: 'manual-paused-alert',
        eventNamePattern: /persistent_service_paused/
      }]);
    },

    /**
     * Mark usage limits as shown for the given service agreement
     * This is called by the billing page to avoid showing popups and
     * notices on the page itself
     * @param {ServiceAgreement} serviceAgreement the service agreement for the current page
     */
    markShownForServiceAgreement(serviceAgreement) {
      if (!serviceAgreement) {
        return;
      }

      const alerts = Ember.get(this, 'allAlerts').filterBy('serviceAgreement.id', Ember.get(serviceAgreement, 'id'));
      alerts.forEach(a => a.markShown());

      if (alerts.length) {
        this.refresh();
      }
    },

    refresh() {
      this.notifyPropertyChange('usageLimits');
    }

  });

  _exports.default = _default;
});