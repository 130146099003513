define("pconsole/routes/organization/integrations/create", ["exports", "pconsole/mixins/new-integration-route", "pconsole/config/environment"], function (_exports, _newIntegrationRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = Ember.Route.extend(_newIntegrationRoute.newIntegrationRoute, {
    parentEntity: 'organization',

    setupController(controller) {
      this._super(...arguments);

      const products = this.modelFor('organization').products;
      controller.set('model.products', products);
    },

    createIntegration(fullTemplate = {}) {
      const _ref = fullTemplate === null || fullTemplate === void 0 ? void 0 : fullTemplate.template,
            {
        integrationType = 'Webhook'
      } = _ref,
            template = _objectWithoutProperties(_ref, ["integrationType"]);

      if (integrationType === 'Webhook') {
        return this.store.createRecord('integration', _objectSpread({
          organization: this.modelFor('organization').organization,
          integrationType: this.store.peekRecord('integrationType', 'Webhook'),
          responseTopic: '{{PARTICLE_DEVICE_ID}}/hook-response/{{PARTICLE_EVENT_NAME}}',
          template: fullTemplate.id
        }, template));
      }

      if (integrationType === 'GoogleCloudPubSub') {
        return this.store.createRecord('integration', _objectSpread({
          organization: this.modelFor('organization').organization,
          integrationType: this.store.peekRecord('integrationType', 'GoogleCloudPubSub'),
          responseTopic: '{{PARTICLE_DEVICE_ID}}/hook-response/{{PARTICLE_EVENT_NAME}}',
          template: fullTemplate.id
        }, template));
      }

      if (integrationType === 'AzureIotHub') {
        return this.store.createRecord('integration', _objectSpread({
          organization: this.modelFor('organization').organization,
          integrationType: this.store.peekRecord('integrationType', 'AzureIotHub'),
          template: fullTemplate.id
        }, template));
      }

      if (integrationType === 'GoogleMaps') {
        return this.store.createRecord('integration', _objectSpread({
          organization: this.modelFor('organization').organization,
          event: _environment.default.APP.GOOGLE_MAPS_INTEGRATION_DEFAULT_EVENT_NAME,
          integrationType: this.store.peekRecord('integrationType', 'GoogleMaps'),
          template: fullTemplate.id
        }, template));
      }
    }

  });

  _exports.default = _default;
});