define("pconsole/templates/components/integration-todays-traffic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "scxkqJj1",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"integration-todays-traffic\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"traffic\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"traffic-stat success-stats\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"im-check-circle\"],[7],[8],[4,\"if\",[[20,[\"traffic\",\"success\"]]],null,{\"statements\":[[1,[25,\"large-number-shortener\",[[20,[\"traffic\",\"success\"]]],null],false]],\"parameters\":[]},{\"statements\":[[0,\"0\"]],\"parameters\":[]}],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"traffic-stat failure-stats\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"ion-close\"],[7],[8],[4,\"if\",[[20,[\"traffic\",\"error\"]]],null,{\"statements\":[[1,[25,\"large-number-shortener\",[[20,[\"traffic\",\"error\"]]],null],false]],\"parameters\":[]},{\"statements\":[[0,\"0\"]],\"parameters\":[]}],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"traffic-stat skipped-stats\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"im-arrow-up\"],[7],[8],[4,\"if\",[[20,[\"traffic\",\"sleep\"]]],null,{\"statements\":[[1,[25,\"large-number-shortener\",[[20,[\"traffic\",\"sleep\"]]],null],false]],\"parameters\":[]},{\"statements\":[[0,\"0\"]],\"parameters\":[]}],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"undefined-attr empty-stats\"],[7],[0,\"none\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/components/integration-todays-traffic.hbs"
    }
  });

  _exports.default = _default;
});