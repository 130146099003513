define("pconsole/models/network-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NetworkType = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string')
  });

  NetworkType.reopenClass({
    FIXTURES: [{
      id: 'micro_wifi',
      name: 'Micro Network (Wi-Fi)',
      description: 'A starter network that includes 10 total devices and up to 1 gateway'
    }, {
      id: 'micro_cellular',
      name: 'Micro Network (Cellular)',
      description: 'A starter cellular network that includes 5 MB of data per month, 10 total devices and up to 1 gateway'
    }, {
      id: 'high_availability',
      name: 'High Availability Network',
      description: 'A network with up to 3 gateways to ensure reliable connection to the internet of up to 30 total devices'
    }, {
      id: 'large_site',
      name: 'Large Site Network',
      description: 'A large network with multiple gateways and many devices'
    }]
  });
  var _default = NetworkType;
  _exports.default = _default;
});