define("pconsole/templates/partials/integration/product-integrations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sbN9f7Wi",
    "block": "{\"symbols\":[\"product\",\"index\"],\"statements\":[[4,\"if\",[[20,[\"model\",\"productIntegrations\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"webhook-info\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"info-left\"],[7],[0,\"\\n      \"],[6,\"h4\"],[9,\"class\",\"webhook-attr-title\"],[7],[0,\"Products\"],[8],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"webhook-attr-desc\"],[7],[0,\"The products on which this integration is being executed.\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"info-right\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"productIntegrations\"]]],null,{\"statements\":[[4,\"link-to\",[\"product.integrations.show\",[19,1,[\"product_id\"]],[19,1,[\"integration_id\"]]],null,{\"statements\":[[0,\"          \"],[1,[19,1,[\"product_name\"]],false],[1,[25,\"unless\",[[25,\"eq\",[[19,1,[]],[20,[\"model\",\"productIntegrations\",\"lastObject\"]]],null],\", \"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/integration/product-integrations-list.hbs"
    }
  });

  _exports.default = _default;
});