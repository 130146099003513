define("pconsole/helpers/round-to-decimals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundToDecimals = roundToDecimals;
  _exports.default = void 0;

  function roundToDecimals(args) {
    const value = args[0];
    const decimals = typeof args[1] === 'undefined' ? 2 : args[1];
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
  }

  var _default = Ember.Helper.helper(roundToDecimals);

  _exports.default = _default;
});