define("pconsole/components/device-ping", ["exports", "pconsole/mixins/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, {
    scope: 'devices:ping',
    deviceId: Ember.computed('device', function () {
      return this.get('device.id');
    }),
    devicePlatform: Ember.computed('device', function () {
      return (this.get('device') ? this.get('device.platform.name') : '').toLowerCase();
    }),
    loading: false,
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),

    _error(message) {
      this.get('notify').alert(message);
    },

    _success(message) {
      this.get('notify').success(message);
    },

    _info(message) {
      this.get('notify').info(message);
    },

    _constructUrl() {
      const product = this.get('routeData.product');
      let url = '/v1/';

      if (product) {
        url += "products/".concat(product, "/");
      }

      url += "devices/".concat(this.get('deviceId'), "/ping");
      return url;
    },

    _showLoader() {
      this.set('loading', true);
    },

    _hideLoader() {
      this.set('loading', false);
    },

    actions: {
      pingDevice() {
        if (this.get('deviceId')) {
          this._showLoader();

          setTimeout(() => {
            if (this.get('loading') === true) {
              this._info('The Particle Cloud has sent a ping to this device. It will wait up to 15 seconds to hear back.');
            }
          }, 1500);
          return this.get('apiAjax').putRequest({
            url: this._constructUrl(),
            timeout: 15000
          }).then(response => {
            if (response.ok && response.online) {
              this._success('This device is online and connected!');
            }

            this._hideLoader(name);
          }, () => {
            this._error({
              html: "This device was unreachable by the Particle cloud within 15 seconds. The device may be powered off, or may be having trouble connecting to the Particle Cloud.<br/><br/>Please check out our documentation on <a href=\"https://docs.particle.io/support/troubleshooting/common-issues/".concat(this.get('devicePlatform'), "\" target=\"_blank\">common issues</a> for reasons why this device may be offline."),
              closeAfter: 15000
            });

            this._hideLoader(name);
          });
        }
      }

    }
  });

  _exports.default = _default;
});