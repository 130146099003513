define("pconsole/routes/login", ["exports", "pconsole/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel() {
      // ember-simple-auth show only if the user is not authenticated
      this.get('session').prohibitAuthentication('user.index');
    },

    activate() {
      // We use Single Sign-on for staging and prod, but use the Ember login page
      // internally
      if (_environment.default.APP.USE_SINGLE_SIGN_ON) {
        window.location.replace("".concat(_environment.default.APP.SINGLE_SIGN_ON_URL, "/login?redirect=").concat(window.location.href));
      }
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('errorMessage', null);
      }
    },

    renderTemplate: function renderTemplate() {
      if (_environment.default.APP.USE_SINGLE_SIGN_ON) {
        this.render('loading');
      } else {
        this._super();
      }
    }
  });

  _exports.default = _default;
});