define("pconsole/serializers/plan", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindRecordResponse(store, type, payload) {
      const plan = payload.plan;

      switch (plan.slug) {
        case 'PlatformPrototypePlan':
          plan.plan_group = 1;
          break;

        case 'PlatformPilotMonthlyPlan':
        case 'PlatformPilotAnnualPlan':
        case 'PlatformPilotGrandfatheredPlan':
          plan.plan_group = 2;
          break;

        case 'PlatformRolloutMonthlyPlan':
        case 'PlatformRolloutAnnualPlan':
        case 'PlatformGrandfatheredRolloutPlan':
          plan.plan_group = 3;
          break;

        case 'PlatformScaleMonthlyPlan':
        case 'PlatformScaleAnnualPlan':
        case 'PlatformGrandfatheredScalePlan':
          plan.plan_group = 4;
          break;

        case 'PlatformEnterprisePlan':
          // Assign to the enterprise plan
          plan.plan_group = 5;
          break;

        case 'PlatformInternalNoBillingPlan':
          // Assign to the internal plan
          plan.plan_group = 6;
          break;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});