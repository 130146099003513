define("pconsole/lib/average-rgb-colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.toHex = _exports.toBase16Array = void 0;

  /**
   * Gets the red bits of a hex number
   * @param {number} number Hex number
   * @return {number} Hex number
   */
  const getRedChannel = number => number >> 16 & 0xff; //eslint-disable-line no-bitwise

  /**
   * Gets the green bits of a hex number
   * @param {number} number Hex number
   * @return {number} Hex number
   */


  const getGreenChannel = number => number >> 8 & 0xff; //eslint-disable-line no-bitwise

  /**
   * Gets the blue bits of a hex number
   * @param {number} number Hex number
   * @return {number} Hex number
   */


  const getBlueChannel = number => number & 0xff; //eslint-disable-line no-bitwise

  /**
   * Gets an array of hex numbers for the given RGB hex color string
   * @param {string} color Hex color string
   * @return {number[]} Array of red, green, and blue hex numbers
   */


  const toBase16Array = color => {
    const hex = parseInt(color.replace('#', ''), 16);
    return [getRedChannel(hex), getGreenChannel(hex), getBlueChannel(hex)];
  };
  /**
   * Gets a zero padded, two digit hex string for the given hex number; rounds down
   * @param {number} number Hex number
   * @return {string} Hex string
   */


  _exports.toBase16Array = toBase16Array;

  const toHex = number => (number & 0xff).toString(16).padStart(2, '0'); //eslint-disable-line no-bitwise

  /**
   * Averages the given RGB hex color strings
   * @param  {...string} colors Colors
   * @return {string} Hex color string for the average
   */


  _exports.toHex = toHex;

  const averageRgbColors = (...colors) => {
    const n = colors.length;
    const hexColors = colors.map(toBase16Array);
    const average = hexColors.reduce(([rN, gN, bN], [r, g, b]) => [rN + r / n, gN + g / n, bN + b / n], [0, 0, 0]);
    return "#".concat(toHex(average[0])).concat(toHex(average[1])).concat(toHex(average[2]));
  };

  var _default = averageRgbColors;
  _exports.default = _default;
});