define("pconsole/helpers/check-scope", ["exports", "pconsole/utils/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkScope = checkScope;
  _exports.default = void 0;

  function checkScope([scope, product, store]
  /*, hash*/
  ) {
    return (0, _permissions.checkScope)({
      scope,
      productModel: product,
      store
    });
  }

  var _default = Ember.Helper.helper(checkScope);

  _exports.default = _default;
});