define("pconsole/models/logic-trigger", ["exports", "ember-data", "lodash/invert", "ember-cp-validations", "pconsole/helpers/cron-description"], function (_exports, _emberData, _invert, _emberCpValidations, _cronDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPE_API_TO_DISPLAY = {
    Event: 'Event',
    Scheduled: 'Scheduled',
    LedgerChange: 'Ledger Change'
  };
  const TYPE_DISPLAY_TO_API = (0, _invert.default)(TYPE_API_TO_DISPLAY);
  const TYPE_ICONS = {
    Event: 'im-message-icon',
    Scheduled: 'im-gradual',
    LedgerChange: 'im-notebook'
  };
  const Validations = (0, _emberCpValidations.buildValidations)({
    'type': (0, _emberCpValidations.validator)('presence', true),
    'productId': (0, _emberCpValidations.validator)('inline', {
      validate(value, options, model) {
        return model.get('type') === 'Event' ? !!value : true;
      },

      dependentKeys: ['model.type', 'model.productId']
    }),
    'eventName': (0, _emberCpValidations.validator)('inline', {
      validate(value, options, model) {
        return model.get('type') === 'Event' ? !!value : true;
      },

      dependentKeys: ['model.type', 'model.eventName']
    }),
    'cron': (0, _emberCpValidations.validator)('inline', {
      validate(value, options, model) {
        if (model.get('type') === 'Scheduled') {
          return (0, _cronDescription.cronDescription)([value]) !== '...';
        }

        return true;
      },

      dependentKeys: ['model.type', 'model.cron']
    })
  }); // This is the model for the triggers embedded inside of Logic Functions

  const LogicTrigger = _emberData.default.Model.extend(Validations, {
    logicFunction: _emberData.default.belongsTo('logicFunction'),
    type: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    validModel: Ember.computed.readOnly('validations.isValid'),
    status: Ember.computed('enabled', {
      get() {
        return Ember.get(this, 'enabled') ? 'Enabled' : 'Disabled';
      },

      set(key, value) {
        Ember.set(this, 'enabled', value === 'Enabled');
        return value;
      }

    }),
    isScheduled: Ember.computed('type', function () {
      return Ember.get(this, 'type') === 'Scheduled';
    }),
    isEvent: Ember.computed('type', function () {
      return Ember.get(this, 'type') === 'Event';
    }),
    isLedgerChange: Ember.computed('type', function () {
      return Ember.get(this, 'type') === 'LedgerChange';
    }),
    typeDisplay: Ember.computed('type', {
      get() {
        return TYPE_API_TO_DISPLAY[Ember.get(this, 'type')];
      },

      set(key, value) {
        Ember.set(this, 'type', TYPE_DISPLAY_TO_API[value]);
        return value;
      }

    }),
    typeIcon: Ember.computed('type', function () {
      return TYPE_ICONS[Ember.get(this, 'type')];
    }),
    // Event triggers
    eventName: _emberData.default.attr('string'),
    productId: _emberData.default.attr('number'),
    productValue: Ember.computed('productId', {
      get() {
        return "".concat(Ember.get(this, 'productId'));
      },

      set(key, value) {
        Ember.set(this, 'productId', Number(value));
        return value;
      }

    }),
    // Scheduled triggers
    cron: _emberData.default.attr('string'),
    // Ledger change triggers
    changeType: _emberData.default.attr('string'),
    ledgerName: _emberData.default.attr('string'),
    instanceScope: _emberData.default.attr('string')
  });

  var _default = LogicTrigger;
  _exports.default = _default;
});