define("pconsole/components/logic-runs-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apiAjax: Ember.inject.service(),
    runs: null,
    loadingLogs: false,
    logs: {},
    expandedRun: undefined,
    emptyLogs: Ember.computed.empty('logs'),
    openLogs: Ember.computed('logs', 'expandedRun', function () {
      const run = Ember.get(this, 'expandedRun');

      if (run) {
        return Ember.get(this, "logs.".concat(run));
      }

      return null;
    }),
    onExpandRow: async function onExpandRow({
      rowId,
      isExpanded
    }) {
      Ember.set(this, 'expandedRun', rowId);

      if (!isExpanded || Ember.get(this, "logs.".concat(rowId))) {
        return;
      }

      try {
        Ember.set(this, 'loadingLogs', true);
        const org = Ember.get(this, 'routeData.org');
        let baseUrl = '/v1';

        if (org) {
          baseUrl = "/v1/orgs/".concat(org);
        }

        const logicFunctionId = Ember.get(this, 'logicFunction.id');
        const url = "".concat(baseUrl, "/logic/functions/").concat(logicFunctionId, "/runs/").concat(rowId, "/logs");
        const {
          logic_run_logs
        } = await Ember.get(this, 'apiAjax').request({
          url
        });
        Ember.set(this, "logs.".concat(rowId), logic_run_logs);
        Ember.set(this, 'loadingLogs', false);
      } catch (_unused) {
        Ember.set(this, 'loadingLogs', false);
      }
    },
    actions: {
      refresh() {
        const runs = Ember.get(this, 'runs');
        Ember.set(runs, 'query', {
          logicFunctionId: Ember.get(this, 'logicFunction.id')
        });
        runs.update();
      }

    }
  });

  _exports.default = _default;
});