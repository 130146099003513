define("pconsole/routes/product/logic-functions/details", ["exports", "pconsole/mixins/routes/logic-functions/details"], function (_exports, _details) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_details.LogicFunctionsDetailsRoute, {
    owner: 'product'
  });

  _exports.default = _default;
});