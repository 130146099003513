define("pconsole/validators/unique-ledger-name", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UniqueLedgerName = _base.default.extend({
    store: Ember.inject.service(),

    async validate(value) {
      const store = Ember.get(this, 'store');
      const ledgerDefinitions = await store.findAll('ledger-definition', {
        backgroundReload: false,
        reload: false
      });
      const repeatedName = ledgerDefinitions.toArray().some(ledger => {
        const name = Ember.get(ledger, 'name');
        return ledger.id && name === value;
      });

      if (repeatedName) {
        return 'This name is already taken by another Ledger. Please type in a different name.';
      }

      return true;
    }

  });

  UniqueLedgerName.reopenClass({
    getDependentsFor() {
      return ['name'];
    }

  });
  var _default = UniqueLedgerName;
  _exports.default = _default;
});