define("pconsole/controllers/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    isIntegrationsIndexRoute: Ember.computed('application.currentRouteName', function () {
      const route = this.get('application.currentRouteName');
      return route === 'user.integrations.index' || route === 'product.integrations.index';
    })
  });

  _exports.default = _default;
});