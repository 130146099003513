define("pconsole/controllers/organization/logic", ["exports", "pconsole/mixins/object-filter-helpers"], function (_exports, _objectFilterHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_objectFilterHelpers.default, {
    notify: Ember.inject.service(),
    queryParams: ['name', 'logicTrigger'],
    sortProps: ['name:asc'],
    filterName: undefined,
    filterValue: undefined,
    organization: null,
    sortParams: Ember.computed('sortProps', function () {
      const [sortValue] = Ember.get(this, 'sortProps');
      let [sortAttr, sortDir] = sortValue.split(':');

      if (sortAttr.includes('todayStats')) {
        sortAttr = 'todayStats';
      }

      return {
        attr: sortAttr,
        dir: sortDir
      };
    }),
    noLogicFunctions: Ember.computed.empty('model.logicFunctions'),
    filteredLogicFunctions: Ember.computed('model.logicFunctions', 'filterName', 'filterValue', function () {
      const logicFunctions = Ember.get(this, 'model.logicFunctions');
      let filterName = Ember.get(this, 'filterName');
      const filterValue = Ember.get(this, 'filterValue');

      if (!filterValue) {
        return logicFunctions;
      }

      if (filterName === 'logicTrigger') {
        return logicFunctions.filter(logicFunction => {
          const filter = new RegExp(filterValue, 'i');
          return logicFunction.get('logicTriggers').any(trigger => filter.test(trigger.get('typeDisplay')));
        });
      }

      const filter = new RegExp(filterValue, 'i');
      return logicFunctions.filter(logicFunction => filter.test(logicFunction.get(filterName)));
    }),
    sortedLogicFunctions: Ember.computed.sort('filteredLogicFunctions', 'sortProps'),
    actions: {
      sortInController(sortAttr, direction) {
        const [prevSort] = Ember.get(this, 'sortProps');
        const [prevAttr] = prevSort.split(':');

        if (sortAttr === 'todayStats') {
          let finalAttr = sortAttr;
          let finalDir = direction;

          if (!prevAttr.includes('todayStats') || prevSort === 'todayStats.success:asc') {
            // First sort of today's traffic or restart the loop
            finalAttr = 'todayStats.failure';
            finalDir = 'desc';
          } else if (finalDir === 'asc') {
            // Second or 4th click
            finalAttr = prevAttr;
          } else {
            finalAttr = 'todayStats.success';
          }

          Ember.set(this, 'sortProps', ["".concat(finalAttr, ":").concat(finalDir)]);
          return;
        }

        Ember.set(this, 'sortProps', ["".concat(sortAttr, ":").concat(direction)]);
      },

      triggerFilter(name, value) {
        Ember.set(this, 'filterName', name);
        Ember.set(this, 'filterValue', value);
      },

      async toggleEnable(logicFunction) {
        try {
          await logicFunction.reload();
          const newStatus = !logicFunction.get('enabled');
          logicFunction.set('enabled', newStatus);
          await logicFunction.save();
          Ember.get(this, 'notify').success("Your logic function  has been ".concat(newStatus ? 'enabled' : 'disabled'));
        } catch (_unused) {
          Ember.get(this, 'notify').alert('Your logic function could not be updated. Please try again later');
        }
      }

    }
  });

  _exports.default = _default;
});