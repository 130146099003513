define("pconsole/components/batch-actions-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['top-right-actions'],
    classNameBindings: ['showBatchActionButtons'],
    options: [],
    selection: Ember.computed.filterBy('options', 'isChecked', true),
    showBatchActionButtons: Ember.computed('selection.length', function () {
      return this.get('selection.length') > 0;
    }),
    actions: {
      cancel() {
        const options = this.get('options');
        options.setEach('isChecked', false);
      }

    }
  });

  _exports.default = _default;
});