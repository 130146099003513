define("pconsole/controllers/product/sims/import/index", ["exports", "pconsole/mixins/browser-info"], function (_exports, _browserInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_browserInfo.default, {
    actions: {
      closeModal() {
        this.transitionToRoute('product.sims');
      },

      goToScan() {
        this.send('closeModal');
        this.transitionToRoute('product.sims.import.scan', {
          queryParams: {
            type: null
          }
        });
      },

      goToFileUpload() {
        this.send('closeModal');
        this.transitionToRoute('product.sims.import.upload');
      },

      goToType() {
        this.send('closeModal');
        this.transitionToRoute('product.sims.import.scan', {
          queryParams: {
            type: true
          }
        });
      }

    }
  });

  _exports.default = _default;
});