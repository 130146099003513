define("pconsole/services/marketo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Conversation Flows in Marketo Dynamic Chat
  const conversationIds = {
    'logic-feedback': '61c2c01d-2316-4ab4-a6a1-67fe975c5b59'
  }; // The domain alias that Particle uses for Marketo

  const marketoBaseUrl = '//pages.particle.io'; // The Marketo JS client id (Munchkin ID)

  const munchkinId = '079-NUZ-391'; // A minimal Marketo form used to link the Munchkin cookie to the Marketo user

  const formId = 2080;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    didIdentify: false,

    /**
     * Show a Marketo Dynamic Chat conversation.
     * @param {string} conversation - the conversation to show. Must be one of the keys in conversationIds
     * @returns {Promise} - resolves when the chat is shown
     */
    async showDynamicChat(conversation) {
      const conversationId = conversationIds[conversation];

      if (!conversationId) {
        throw new Error('invalid conversation');
      }

      if (!window.MktoForms2 || !window.AdobeDX) {
        Ember.get(this, 'notify').error('Could not load the feedback form. If you have an ad blocker enabled, please disable it and try again.');
        return;
      }

      await this.identify();
      window.AdobeDX.conversations({
        id: conversationId,
        type: 'popup'
      });
    },

    /**
     * Identify the user once to Marketo so the conversation response is associated with the user.
     * Munchkin, the Marketo JS client, adds a cookie to the user browser. Submitting a Marketo form in the background
     * links the cookie to the Lead in Marketo.
     * @returns {Promise} - resolves when the user is identified
     */
    async identify() {
      if (Ember.get(this, 'didIdentify')) {
        return;
      }

      const email = Ember.get(this, 'session.data.authenticated.username');
      const form = await new Promise(resolve => {
        window.MktoForms2.loadForm(marketoBaseUrl, munchkinId, formId, form => {
          resolve(form);
        });
      });
      await new Promise(resolve => {
        form.onSuccess(() => {
          resolve(); // don't navigate away from the page

          return false;
        });
        form.addHiddenFields({
          'Email': email
        });
        form.submit();
      });
      Ember.set(this, 'didIdentify', true);
    }

  });

  _exports.default = _default;
});