define("pconsole/routes/product/sims", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,
    queryParams: {
      iccid: {
        refreshModel: true,
        replace: true
      },
      deviceId: {
        refreshModel: true,
        replace: true
      },
      deviceName: {
        refreshModel: true,
        replace: true
      }
    },
    breadCrumb: {
      title: 'Sim Cards'
    },

    model(params) {
      const {
        product
      } = this.modelFor('product');
      return Ember.RSVP.hash({
        sims: this.findPaged('sim', params),
        product,
        subscription: product.get('subscription')
      });
    },

    afterModel(model) {
      const sims = model.sims;
      const subscription = model.subscription;
      sims.forEach(sim => {
        sim.set('subscription', subscription);
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', null);
        controller.set('perPage', null);
      }
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});