define("pconsole/controllers/user/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    card: Ember.computed.alias('model.card'),
    wifiProducts: Ember.computed.alias('model.wifiProducts'),
    cellularProducts: Ember.computed.alias('model.cellularProducts'),
    show2020Billing: Ember.computed.equal('user.planVersion', 'Pricing 2020.1'),
    user: Ember.computed.alias('model.user'),
    orgs: Ember.computed.alias('model.orgs')
  });

  _exports.default = _default;
});