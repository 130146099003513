define("pconsole/mixins/routes/ledger/show/new-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LedgerNewInstanceRoute = void 0;
  const LedgerNewInstanceRoute = Ember.Mixin.create({
    templateName: 'organization.ledger.show.instance',
    controllerName: 'organization.ledger.show.instance',
    session: Ember.inject.service(),

    async model() {
      const parentRoute = Ember.get(this, 'routeData.ledgerRoutes.show');
      const {
        ledgerDefinition
      } = this.modelFor(parentRoute);
      const org = this.routeData.org;
      let products;

      if (org) {
        products = await this.modelFor('organization').products;
      } else {
        const username = Ember.get(this, 'session.data.authenticated.username');
        products = await this.store.findAll('product');
        products = products.filterBy('user', username);
      }

      const ledgerInstance = this.store.createRecord('ledger-instance', {
        ledgerDefinition
      });
      return {
        ledgerDefinition,
        ledgerInstance,
        ledgerInstances: this.store.peekAll('ledger-instance'),
        products
      };
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('isCreating', true);
    },

    resetController(controller) {
      Ember.get(controller, 'ledgerInstance').rollbackAttributes();
      controller.setProperties({
        isCreating: false,
        selectedScope: null
      });
    }

  });
  _exports.LedgerNewInstanceRoute = LedgerNewInstanceRoute;
});