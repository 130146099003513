define("pconsole/components/eventlogs/number-of-queued-events.react", ["exports", "react", "pconsole/components/eventlogs/events-util"], function (_exports, _react, _eventsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NumberOfQueuedEvents = ({
    events,
    unpause
  }) => {
    const numberOfQueuedEvents = _eventsUtil.default.numberOfQueuedEvents(events);

    return numberOfQueuedEvents > 0 && _react.default.createElement('tr', {
      className: '_eventlogsnew__content__events__numberOfQueuedEvents'
    }, _react.default.createElement('td', {
      colSpan: '3'
    }, _react.default.createElement('span', {
      className: '_eventlogsnew__content__events__numberOfQueuedEvents__action',
      onClick: unpause
    }, 'Unpause'), ' to reveal ', numberOfQueuedEvents, ' queued event', numberOfQueuedEvents > 1 && 's', '.'));
  };

  var _default = NumberOfQueuedEvents;
  _exports.default = _default;
});