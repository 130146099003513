define("pconsole/components/device-groups-tag-list", ["exports", "react", "prop-types", "pconsole/components/color-swatch", "styled-components"], function (_exports, _react, _propTypes, _colorSwatch, _styledComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const DeviceGroupsTagList = ({
    groups = [],
    limit,
    right,
    onItemClick = () => {}
  }) => {
    function renderGroups() {
      if (groups.length) {
        const truncatedGroups = groups.slice(0, limit);

        const moreText = groups.length > limit && _react.default.createElement(MoreText, null, '\u2026', groups.length - limit, ' more');

        const group = g => _react.default.createElement(GroupTagButton, {
          key: g.name,
          type: 'button',
          onClick: e => onItemClick(g, e)
        }, _react.default.createElement(_colorSwatch.default, {
          color: g.color
        }), ' ', g.name);

        return _react.default.createElement(_react.Fragment, null, truncatedGroups.map(group), moreText);
      }

      return _react.default.createElement(NoGroups, null, 'No groups');
    }

    return _react.default.createElement(GroupTagList, {
      right: right
    }, renderGroups());
  };

  const GroupTagList = _styledComponents.default.div.withConfig({
    displayName: "device-groups-tag-list__GroupTagList",
    componentId: "sc-3owf7p-0"
  })(["display:flex;flex-wrap:wrap;justify-content:", ";margin:-0.4rem 0 0 -0.4rem;"], props => props.right ? 'flex-end' : 'flex-start');

  const GroupTagButton = _styledComponents.default.button.withConfig({
    displayName: "device-groups-tag-list__GroupTagButton",
    componentId: "sc-3owf7p-1"
  })(["background-color:var(--tertiary-background-color);border:1px solid var(--primary-border-color);border-radius:4px;display:inline-block;margin:0.4rem 0 0 0.4rem;padding:0.2rem 0.8rem;&:last-of-type{margin-right:0;}"]);

  const MoreText = _styledComponents.default.span.withConfig({
    displayName: "device-groups-tag-list__MoreText",
    componentId: "sc-3owf7p-2"
  })(["color:var(--primary-text-color);margin-left:0.8rem;margin-top:0.72rem;"]);

  const NoGroups = _styledComponents.default.p.withConfig({
    displayName: "device-groups-tag-list__NoGroups",
    componentId: "sc-3owf7p-3"
  })(["margin:0 0 0 0.4rem;"]);

  DeviceGroupsTagList.propTypes = {
    groups: _propTypes.default.array,
    limit: _propTypes.default.number,
    right: _propTypes.default.bool,
    onItemClick: _propTypes.default.func
  };
  var _default = DeviceGroupsTagList;
  _exports.default = _default;
});