define("pconsole/controllers/organization/logic/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      showTemplate(template) {
        const route = Ember.get(this, 'routeData.logicRoutes.templateShow');
        this.transitionToRoute(route, Ember.get(template, 'slug'));
      }

    }
  });

  _exports.default = _default;
});