define("pconsole/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HuO52uiL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui honeycomb segment\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"login-wrapper\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"class\",\"particle-login-logo\"],[9,\"src\",\"/assets/particle-logo-horizontal.png\"],[9,\"width\",\"200\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"h1\"],[9,\"class\",\"ui inverted center aligned header margin-top-none\"],[7],[0,\"\\n    Not Found\\n    \"],[6,\"div\"],[9,\"class\",\"sub header\"],[7],[0,\"Looks like you got lost\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui inverted center aligned segment margin-top-double\"],[7],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui secondary button\"],[9,\"href\",\"#\"],[9,\"onclick\",\"javascript:history.back()\"],[7],[0,\"Go back\"],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"ui secondary button\"],[9,\"href\",\"https://particle.io\"],[7],[0,\"Head to the homepage\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});