define("pconsole/models/organization", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Organization = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    enterprise: _emberData.default.attr('boolean'),
    maintenanceMode: _emberData.default.attr('boolean'),
    // Attributes for password resetting email
    smtpHostname: _emberData.default.attr('string'),
    smtpUsername: _emberData.default.attr('string'),
    smtpPassword: _emberData.default.attr('string'),
    smtpIsTls: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    smtpPort: _emberData.default.attr('number', {
      defaultValue: 587
    }),
    smtpEmailFrom: _emberData.default.attr('string'),
    smtpEmailSubject: _emberData.default.attr('string', {
      defaultValue: 'Reset your password'
    }),
    smtpEmailBody: _emberData.default.attr('string', {
      defaultValue: 'Follow the link below to reset your password'
    }),
    smtpEmailChangePasswordURL: _emberData.default.attr('string'),
    // Associations
    products: _emberData.default.hasMany('product', {
      async: true
    }),
    orgUsers: _emberData.default.hasMany('org-user', {
      async: true
    }),
    allowsUnauthenticatedCustomerRegister: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    user: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  var _default = Organization;
  _exports.default = _default;
});