define("pconsole/routes/product/map/index/config", ["exports", "pconsole/mixins/modal-route"], function (_exports, _modalRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modalRoute.default, {
    controllerName: 'product.map.index.config',
    apiAjax: Ember.inject.service(),
    notify: Ember.inject.service(),

    async model() {
      const notify = Ember.get(this, 'notify');
      const product = this.modelFor('product').product;
      const productId = Ember.get(product, 'id');
      const apiAjax = Ember.get(this, 'apiAjax');
      const configUrl = "/v1/products/".concat(productId, "/config");
      const configuration = await apiAjax.getRequest({
        url: configUrl
      }).then(({
        configuration
      }) => configuration).catch(() => {});
      const metaschema = await apiAjax.getRequest({
        url: configUrl,
        headers: {
          accept: 'application/metaschema+json'
        }
      }).catch(() => {
        notify.warning("Couldn't load fleet configuration metaschema");
      });
      const schema = await apiAjax.getRequest({
        url: configUrl,
        headers: {
          accept: 'application/schema+json'
        }
      }).catch(() => {
        notify.warning("Couldn't load fleet configuration schema");
      });
      return {
        configuration,
        schema,
        product,
        metaschema
      };
    },

    activate() {
      const controller = this.controllerFor(this.controllerName);
      controller.set('parentRoute', 'product.map');
    }

  });

  _exports.default = _default;
});