define("pconsole/mixins/sim/release", ["exports", "pconsole/mixins/sim/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_base.default, {
    sim: Ember.computed.alias('model.sim'),
    userOwnsMultipleSims: Ember.computed('sims', function () {
      const sims = this.get('model.sims');
      return sims.get('length') > 1;
    }),
    actions: {
      releaseSimSuccess() {
        const isProduct = this.get('routeData.product');
        const transitionRoute = isProduct ? 'product.sims' : 'user.sims';
        return this.transitionToRoute(transitionRoute).then(() => {
          this.send('refreshModel');
          Ember.$('.loading').hide();
          let message;

          if (this.get('sim.deletePending')) {
            message = 'Your SIM is being released. ';
          } else {
            message = 'Your SIM has been successfully released. ';
          }

          message += 'You can always reclaim ownership by ';

          if (isProduct) {
            message += 're-importing the SIM card into your product on this page.';
          } else {
            message += 'visiting <a href="https://setup.particle.io" target="_blank">setup.particle.io</a>.';
          }

          this.get('notify').success({
            html: "<span>".concat(message, "</span>")
          });
        });
      },

      releaseSimFailure(response, sim) {
        const isProduct = this.get('routeData.product');
        sim.transitionTo('loaded.saved');
        Ember.$('.loading').hide();
        const errorStatus = response.errors && parseInt(response.errors[0].status, 10);

        if (errorStatus === 504 || errorStatus === 408) {
          this.send('refreshModel');
          this.get('notify').warning({
            html: "Releasing your SIM is in progress.",
            closeAfter: 10000
          });
          const transitionRoute = isProduct ? 'product.sims' : 'user.sims';
          return this.transitionToRoute(transitionRoute);
        } else {
          this.get('notify').error('There was a problem releasing your SIM. Try again later.');
        }
      },

      releaseSim() {
        const sim = this.get('sim');
        Ember.$('.loading').show();
        sim.destroyRecord().then(() => {
          this.send('releaseSimSuccess');
        }, response => {
          this.send('releaseSimFailure', response, sim);
        });
      }

    }
  });

  _exports.default = _default;
});