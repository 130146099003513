define("pconsole/routes/user/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: null,

    activate() {
      Ember.set(this, 'breadCrumb', {
        title: 'My personal sandbox'
      });
    },

    deactivate() {
      Ember.set(this, 'breadCrumb', null);
    },

    async model() {
      const products = await this.store.query('product', {});
      return {
        products,
        organizations: this.store.peekAll('organization')
      };
    },

    setupController(controller) {
      this._super(...arguments);

      controller.reportBlankSandbox();
    }

  });

  _exports.default = _default;
});