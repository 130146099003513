define("pconsole/mixins/sim/deactivate", ["exports", "pconsole/mixins/sim/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_base.default, {
    actions: {
      deactivateSim() {
        this.updateSim({
          action: 'deactivate'
        }, 'Your SIM has been successfully deactivated.', 'Deactivating');
      }

    }
  });

  _exports.default = _default;
});