define("pconsole/routes/user/networks/network", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,
    breadCrumb: {
      title: 'View Network'
    },

    beforeModel(transition) {
      this.get('routeData').assignRouteData(transition);
    },

    model(params) {
      const gatewayParams = {
        page: params.gatewayPage,
        perPage: params.gatewayPerPage,
        role: 'gateway'
      };
      const nodeParams = {
        page: params.nodePage,
        perPage: params.nodePerPage,
        role: 'node'
      };
      return Ember.RSVP.hash({
        network: this.store.find('network', params.network_name),
        gateways: this.findPaged('device', gatewayParams),
        nodes: this.findPaged('device', nodeParams),
        networkTypes: this.store.findAll('networkType')
      });
    },

    deactivate() {
      const controller = this.get('controller');
      controller.set('gatewayPage', undefined);
      controller.set('nodePage', undefined);
    }

  });

  _exports.default = _default;
});