define("pconsole/templates/partials/logic/deploy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yR03yzAF",
    "block": "{\"symbols\":[\"trigger\",\"index\"],\"statements\":[[6,\"p\"],[7],[0,\"\\n  Add the trigger that will make your Logic Function run and deploy it when you are ready.\\n\"],[8],[0,\"\\n\"],[6,\"label\"],[9,\"class\",\"title\"],[7],[0,\"Add triggers\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"logicFunction\",\"logicTriggers\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"logic-trigger-editor\",null,[[\"index\",\"logicFunction\",\"logicTrigger\",\"products\",\"currentProduct\",\"controller\"],[[19,2,[]],[20,[\"logicFunction\"]],[19,1,[]],[20,[\"productNames\"]],[20,[\"product\"]],[19,0,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[6,\"button\"],[9,\"class\",\"ui button outline secondary\"],[3,\"action\",[[19,0,[]],\"addTrigger\"]],[7],[0,\"+ Add trigger\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/partials/logic/deploy.hbs"
    }
  });

  _exports.default = _default;
});