define("pconsole/controllers/user/team-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isLoading: false,
    queryParams: ['redirectTo'],
    redirectTo: '',
    apiAjax: Ember.inject.service(),
    membership: Ember.computed.readOnly('model'),
    notify: Ember.inject.service(),
    actions: {
      async respondToInvite(membership, accept) {
        const teamIdentifier = Ember.get(membership, 'teamIdentifier');
        const isOrg = Ember.get(membership, 'isOrg');
        const notify = Ember.get(this, 'notify');
        const redirectTo = Ember.get(this, 'redirectTo');
        const data = {
          accept: accept
        };
        data[isOrg ? 'organization' : 'product'] = teamIdentifier;

        try {
          this.set('isLoading', true);
          await Ember.get(this, 'apiAjax').request({
            url: '/v1/user',
            type: 'PUT',
            data: JSON.stringify(data),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json'
          });
          await Ember.run(() => Ember.set(membership, 'invited', false));

          if (accept) {
            if (isOrg) {
              await this.replaceRoute('organization', teamIdentifier).followRedirects();
            } else {
              await this.replaceRoute('user.products').followRedirects();
            }

            notify.success('You have successfully joined the team');
            /*
            // TODO: THIS PART OF THE CODE CURRENTLY FAILS FOR ACCEPTING PRODUCT INVITATIONS. THIS IS BECAUSE
            // THE API DOES NOT CURRENTLY ALLOW THE USER TO SUCCESSFULLY RETRIEVE DATA ABOUT AN ORG-OWNED PRODUCT AFTER
            // ACCEPTING AN INVITATION.
            const findTeamRecord = this.store.findRecord(isOrg ? 'organization' : 'product', teamIdentifier);
             findTeamRecord.then((record) => {
              if(isOrg) {
                this.replaceRoute('organization', record.get('id')).finally(() => {
                  this.get('notify').success('You have successfully joined the organization team.');
                });
              } else {
                this.replaceRoute('product', record.get('slug')).finally(() => {
                  this.get('notify').success('You have successfully joined the product team');
                });
              }
            });*/
          } else {
            const organization = membership.belongsTo('organization').value();

            if (organization) {
              // Remove the org record that came along with the invite
              this.store.unloadRecord(organization);
            }

            await this.replaceRoute(redirectTo ? redirectTo : 'user.index').followRedirects();
            notify.success('You have declined the invite.');
          }
        } catch (ex) {
          const error = ex.errors[0];
          const errorMsg = error ? error.detail : "We couldn't complete your request. Please try again later.";
          notify.alert(errorMsg);
        } finally {
          this.set('isLoading', false);
        }
      }

    }
  });

  _exports.default = _default;
});