define("pconsole/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.intersperse = intersperse;

  /**
   * Return an array with the separator interspersed between
   * each element of the input array.
   *
   * @author @thomasjonas
   * @param {array} arr Array
   * @param {function|string} sep Separator; can be a function, e.g. to give a `key`
   * @returns {array} Interspersed array
   * @see https://gist.github.com/thomasjonas/f99f48e278fd2dfe82edb2c6f7d6c365
   * @example
   * ```js
   * [1,2,3].intersperse(0)
   * // [1,0,2,0,3]
   * ```
   */
  function intersperse(arr, sep) {
    if (arr.length === 0) {
      return [];
    }

    return arr.slice(1).reduce((xs, x, idx) => {
      const separator = typeof sep === 'function' ? sep(idx) : sep;
      return xs.concat([separator, x]);
    }, [arr[0]]);
  }
});