define("pconsole/controllers/product/devices/enable-force-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    targetedProductFirmwareVersion: Ember.computed.alias('model.targetedProductFirmwareVersion'),
    actions: {
      closeModal() {
        const product = this.get('routeData.product');

        if (product) {
          this.transitionToRoute('product.device');
        } else {
          this.transitionToRoute('user.device');
        }
      },

      enableForceUpdate() {
        const device = this.get('model');
        Ember.$('.loading').show();
        device.set('firmwareUpdatesForced', true);
        device.save().then(() => {
          Ember.$('.loading').hide();
          this.send('closeModal');
          this.get('notify').success('Force enabled OTA updates for this device successfully.');
        }, response => {
          device.set('firmwareUpdatesForced', false);
          Ember.$('.loading').hide();
          const error = response.errors[0];
          const errorMsg = error ? error.detail : 'Server error. Please contact our support team.';
          this.get('notify').alert(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});