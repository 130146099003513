define("pconsole/templates/user/products/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t/YbH2Mz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"fleet-modal\",null,[[\"close\"],[\"closeModal\"]],{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"title\"],[7],[0,\"Create New Product\"],[8],[0,\"\\n\"],[4,\"form-wrapper\",null,null,{\"statements\":[[0,\"    \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"placeholder\"],[\"product.name\",\"Product Name\",\"Enter product name\"]]],false],[0,\"\\n    \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"type\",\"placeholder\"],[\"product.description\",\"Brief Description\",\"textarea\",\"Enter a short description\"]]],false],[0,\"\\n    \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"help\",\"content\",\"optionLabelPath\",\"optionValuePath\",\"prompt\"],[\"platformId\",\"Device Type\",\"What device will be inside your product?\",[20,[\"validPlatforms\"]],\"nameWithConnectivity\",\"id\",\"Select device\"]]],false],[0,\"\\n    \"],[1,[25,\"asset-tracker-consent\",null,[[\"product\"],[[20,[\"product\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[20,[\"tosAccepted\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"terms-of-service\"],[7],[0,\"\\n        \"],[1,[25,\"input-group\",null,[[\"property\",\"label\",\"type\",\"classNames\"],[\"user.tos.accepted\",\"I have read and agreed to Particle's\",\"checkbox\",\"terms-checkbox clearfix\"]]],false],[6,\"a\"],[9,\"href\",\"https://www.particle.io/tos\"],[9,\"target\",\"_blank\"],[9,\"class\",\"tos-link\"],[7],[0,\"terms of service\"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"button\"],[9,\"class\",\"full-width ui button outline primary\"],[9,\"type\",\"button\"],[10,\"disabled\",[18,\"disableForm\"],null],[3,\"action\",[[19,0,[]],\"save\"]],[7],[0,\"Create\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pconsole/templates/user/products/new.hbs"
    }
  });

  _exports.default = _default;
});