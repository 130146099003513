define("pconsole/adapters/device-location", ["exports", "pconsole/adapters/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, _id, snapshot, requestType, query) {
      let id;
      const productSlug = Ember.get(this, 'routeData.product');

      if (query) {
        ({
          id
        } = query);
        delete query.id;
      } else {
        id = _id;
      }

      const prefix = this.urlPrefix();

      if (!productSlug) {
        // eslint-disable-next-line new-cap
        throw _emberData.default.AdapterError('Product ID is required');
      }

      const endpoint = (query === null || query === void 0 ? void 0 : query.endpoint) || 'locations';
      return "".concat(prefix, "/products/").concat(productSlug, "/").concat(endpoint).concat(id ? '/' + id : '');
    }

  });

  _exports.default = _default;
});