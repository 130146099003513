define("pconsole/serializers/configuration-schema", ["exports", "pconsole/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,

    normalizeResponse(store, type, payload, id, requestType) {
      const normalized = {
        configurationSchema: {
          id: payload.id,
          current: payload
        }
      };
      return this._super(store, type, normalized, id, requestType);
    }

  });

  _exports.default = _default;
});